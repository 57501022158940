import OtpView from '../../Pages/OTP/OtpView';
import OtpSubmitHook from '../../hooks/OtpHook/otpSubmitHook';

export default function otpScreen() {
  const {
    handleLanguageSelect,
    onLanguageModalToggle,
    handleLanguageChange,
    selectedLanguage,
    isLanguageModalOpen,
    isLanguageSelectedInProgress,
    userOtp,
    getUserOtpFromInput,
    setDisableResend,
    disableResend,
    resendOtp,
    userMobileNumber,
    onClickValidateOtpProceed,
    error,
    onPressBack,
    language,
    onContactUs,
    loading,
    otpScreenAssets,
    languageData,
    initialScreenLoading,
  } = OtpSubmitHook();
  return (
    <OtpView
      handleLanguageSelect={handleLanguageSelect}
      onLanguageModalToggle={onLanguageModalToggle}
      handleLanguageChange={handleLanguageChange}
      selectedLanguage={selectedLanguage}
      isLanguageModalOpen={isLanguageModalOpen}
      isLanguageSelectedInProgress={isLanguageSelectedInProgress}
      userOtp={userOtp}
      getUserOtpFromInput={getUserOtpFromInput}
      setDisableResend={setDisableResend}
      disableResend={disableResend}
      resendOtp={resendOtp}
      userMobileNumber={userMobileNumber}
      onClickValidateOtpProceed={onClickValidateOtpProceed}
      error={error}
      onPressBack={onPressBack}
      language={language}
      onContactUs={onContactUs}
      loading={loading}
      otpScreenAssets={otpScreenAssets}
      languageData={languageData}
      initialScreenLoading={initialScreenLoading}
    />
  );
}
