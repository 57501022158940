import React from 'react';
import Styles from './style.module.css';
import Button from '@mono-farmart-web/farmart-web-common/ComponentV3/MuiButton';
interface ActionButtonProps {
  onClickValidateOtpProceed: () => void;
  language: { [key: string]: any };
  loading: boolean;
}
export default function Actionbutton(props: ActionButtonProps) {
  const { onClickValidateOtpProceed, language, loading } = props;
  return (
    <div className={Styles?.actionContainer}>
      <div className={Styles.proceedButtonContainer}>
        <Button
          size="lg"
          onClick={onClickValidateOtpProceed}
          id="ProceedButton"
          loading={loading}
        >
          {language?.proceed}
        </Button>
      </div>
    </div>
  );
}
