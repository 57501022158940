import { useMemo } from 'react';
import Styles from './style.module.css';
import Header from './Header/Header';
import SmsBalance from './SmsBalance/SmsBalance';
import HisoryList from './SmsHistoryList/HisoryList';
import { SmsHisoryProps } from '../../hooks/SmsHistoryHook/types';
import { Box } from '@mui/joy';
import ShopPromotionModal from '@mono-farmart-web/farmart-web-common/ComponentV2/ShopPromotionModal';
import InfiniteScroll from '@mono-farmart-web/farmart-web-common/Components/InfinityScrollV2';
import Loader from '@mono-farmart-web/farmart-web-common/ComponentV2/Loading';
import MuiSkeleton from '@mono-farmart-web/farmart-web-common/MuiComponents/Skeleton';

export default function AddFarmerView(props: SmsHisoryProps) {
  const {
    onPressBack,
    language,
    onContactUs,
    smsHistoryData,
    onClickInfo,
    onClickSms,
    smsBalance,
    initialLoading,
    onCloseModal,
    showShopPromotionModal,
    fetchMoreData,
    historyLength,
  } = props;


  const totalLoadedItems = useMemo(() => 
    Object.values(smsHistoryData || {}).reduce(
      (acc, monthData) => acc + monthData.length, 
      0
    ),
    [smsHistoryData]
  );

  function renderLoader() {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        padding="16px"
        sx={{ 
          position: 'relative',
          zIndex: 1
        }}
      >
        <Loader size={30} color="grey" />
      </Box>
    );
  }

  return (
    <Box
      className={Styles.container}
      bgcolor={'background.body'}
      id="smsHistoryScroll"
    >
      <Header
        onContactUs={onContactUs}
        onPressBack={onPressBack}
        language={language}
        onClickInfo={onClickInfo}
      />

      {initialLoading ? (
        <Box>
          <Box
            margin={4}
            display={'flex'}
            alignItems={'center'}
            flexDirection={'column'}
            gap={'12px'}
          >
            <MuiSkeleton
              variant="rectangular"
              sx={{
                height: '20px',
                width: '40%',
                borderRadius: '8px',
              }}
            />
            <MuiSkeleton
              variant="rectangular"
              sx={{
                height: '30px',
                width: '20%',
                borderRadius: '8px',
              }}
            />
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            margin={4}
            flexDirection="column"
            gap={'12px'}
          >
            <MuiSkeleton
              itemNumber={8}
              variant="rectangular"
              sx={{
                height: '70px',
                width: '100%',
                borderRadius: '8px',
              }}
            />
          </Box>
        </Box>
      ) : (
        <>
          <SmsBalance language={language} smsBalance={smsBalance} />

          <ShopPromotionModal
            onClose={onCloseModal}
            openModal={showShopPromotionModal}
          />

          <InfiniteScroll
            dataLength={totalLoadedItems}
            next={fetchMoreData}
            hasMore={historyLength}
            loader={renderLoader()}
            scrollableTarget="smsHistoryScroll"
          >
            <HisoryList
              smsHistoryData={smsHistoryData}
              onClickSms={onClickSms}
              language={language}
              fetchMoreData={fetchMoreData}
            />
          </InfiniteScroll>
        </>
      )}
    </Box>
  );
}
