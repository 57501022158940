import { Box } from '@mui/joy';
import TransactionCard from './transactionCard';
import InfiniteScroll from '@mono-farmart-web/farmart-web-common/Components/InfinityScrollV2';
import { KhataListData } from '../../../hooks/BahiKhataLanding/types';
import timeAgoLang from '@mono-farmart-web/farmart-web-common/utils/timeAgo/date/Language.js';
import useAppLanguage from '@mono-farmart-web/farmart-web-common/utils/applanguage/index.js';
import { languageUrls } from '@mono-farmart-web/farmart-web-common/constant/languageUrls/index';
import { noop } from '@mono-farmart-web/farmart-web-common/utils/common';

interface TransactionDataProps {
  khataList: KhataListData[];
  featureLanguage: { [key: string]: string };
  onClickKhata: (id: number) => void;
}

export default function TransactionList(props: TransactionDataProps) {
  const { khataList, featureLanguage, onClickKhata } = props;

  const { language } = useAppLanguage(languageUrls?.timeAgo, timeAgoLang);

  const list = khataList?.map((item, index) => (
    <Box key={index} margin={'0px 16px'}>
      <TransactionCard
        index={index}
        item={item}
        timeAgoLang={language}
        onClickKhata={onClickKhata}
        language={featureLanguage}
      />
    </Box>
  ));
  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        boxSizing: 'border-box',
        marginBottom: '16px',
        marginTop: '8px',
        display: 'flex',
        flexDirection: 'column',
      }}
      id="transactionListContainer"
    >
      <InfiniteScroll
        dataLength={khataList?.length || 0}
        hasMore={false}
        next={noop}
        loader={<>...</>}
        scrollableTarget="transactionListContainer"
        style={{
          height: '100%',
          width: '100%',
          overflowY: 'auto',
          paddingBottom: '16px',
          scrollbarWidth: 'none',
        }}
      >
        {list}
      </InfiniteScroll>
    </Box>
  );
}
