import React from 'react';
import Modal from '../Modal';
import MuiTypography from '../../ComponentV3/Typography';
import { Box } from '@mui/joy';
import { ArrowDownwardRounded, ArrowUpwardRounded } from '@mui/icons-material';
import ShopPromotionBannerEnglish from '../../Images/ShopPromotionBannerEnglish.png';
import ShopPromotionBannerHindi from '../../Images/ShopPromotionBannerHindi.png';
import ShopPromotionBannerMarathi from '../../Images/ShopPromotionBannerMarathi.png';
import getLang from '../../languages/shopPromotionModal';
import { DEFAULT_LANGUAGE } from '../../constant/language';
import { getLanguageService } from './../../utils/getLanguageService';
import { languageUrls } from '../../constant/languageUrls/index';
import { useState, useEffect } from 'react';
import { getLanguageData } from '../../service/languageService';

// Define an interface for the component props
interface ShopPromotionModalProps {
  openModal: boolean; // Prop indicating whether the modal should be open or closed
  onClose: () => void; // Function to close the modal
}

interface LanguageContentType {
  shopPromotionModalTitle: string;
  moreAboutBalance: string;
  freeSMSCreditTitle: string;
  freeSMSCreditText: string;
  smsExpiryTitle: string;
  smsExpiryText: string;
}

export default function ShopPromotionModal({
  openModal,
  onClose,
}: ShopPromotionModalProps) {
  // Get the active system language from the user's browser settings
  const languageData = getLanguageData();
  const activeSystemLang = Intl.DateTimeFormat().resolvedOptions().locale;
  // Determine the system language code and backend language code based on whether the language is English
  const systemLanguage = activeSystemLang?.includes('en')
    ? languageData && languageData?.[1]?.langCode
    : activeSystemLang?.includes('mr')
    ? languageData[2].langCode
    : languageData && languageData?.[0]?.langCode;

  const [languageFile, setLanguageFile] = useState<LanguageContentType>(null); // Language file state

  // Retrieve user's selected language from local storage, defaulting to system language if not found
  const langId = JSON.parse(localStorage.getItem('userLanguage'));
  const selectedLanguage = langId?.langCode ? langId?.langCode : systemLanguage;
  // Get the language translation object based on the user's selected language or default system language

  const language = languageFile ?? getLang(DEFAULT_LANGUAGE);

  useEffect(() => {
    getLanguageJson();
  }, []);

  async function getLanguageJson() {
    try {
      const result = await getLanguageService(
        languageUrls?.shopPromotionalModalScreenLangageUrl,
      );
      setLanguageFile(result);
    } catch (error) {
      console.log(error);
    } finally {
    }
  }

  const [bannerSrc, setBannerSrc] = useState(ShopPromotionBannerEnglish);

  useEffect(() => {
    if (languageData) {
      setBannerSrc(
        selectedLanguage === languageData?.[0]?.langCode
          ? (ShopPromotionBannerHindi as unknown as string) // Show Hindi banner if selected language is Hindi
          : selectedLanguage === languageData?.[2]?.langCode
          ? (ShopPromotionBannerMarathi as unknown as string) // Show Marathi banner if selected language is Marathi
          : (ShopPromotionBannerEnglish as unknown as string),
      ); // Otherwise, show English banner)
    }
  }, [languageData]);

  // Content to be displayed inside the modal
  const modalContent = (
    <Box padding={4}>
      <img
        height={133}
        src={bannerSrc}
        alt={'shopPromotionBanner'}
        style={{
          width: '100%', // Make the image width responsive
        }}
        loading="eager"
      />
      <Box paddingTop={4}>
        <MuiTypography level={'body-xs'}>
          {language?.moreAboutBalance}
        </MuiTypography>
        <Box marginTop={4}>
          <Box
            display={'flex'}
            paddingBottom={4}
            borderBottom={1}
            borderColor={'#CDD7E1'}
          >
            <Box
              height={32}
              width={36}
              borderRadius={8}
              alignItems={'center'}
              justifyContent={'center'}
              display={'flex'}
              marginRight={2}
              style={{ backgroundColor: '#E3FBE3' }} // Background color for the box
            >
              <ArrowDownwardRounded color={'success'} />
            </Box>
            <Box>
              <MuiTypography level={'title-sm'}>
                {language?.freeSMSCreditTitle}
              </MuiTypography>
              <MuiTypography level={'body-xxs'}>
                {language?.freeSMSCreditText}
              </MuiTypography>
            </Box>
          </Box>
          <Box display={'flex'} paddingTop={4}>
            <Box
              height={32}
              width={36}
              borderRadius={8}
              alignItems={'center'}
              justifyContent={'center'}
              display={'flex'}
              marginRight={2}
              style={{ backgroundColor: '#FCE4E4' }}
            >
              <ArrowUpwardRounded style={{ color: '#C41C1C' }} />
            </Box>
            <Box>
              <MuiTypography level={'title-sm'}>
                {language?.smsExpiryTitle}
              </MuiTypography>
              <MuiTypography level={'body-xxs'}>
                {language?.smsExpiryText}
              </MuiTypography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );

  return (
    <Modal
      isOpen={openModal} // Control modal visibility
      onClose={onClose} // Function to close the modal
      children={modalContent}
      title={language?.shopPromotionModalTitle} // Set the title of the modal
      id={'ShopPromotionModalId'} // Unique ID for the modal
      isCloseButtonOption={true} // Option to show the close button in the modal
    />
  );
}
