import { Box } from '@mui/joy';
import MuiTypography from '@mono-farmart-web/farmart-web-common/ComponentV3/Typography';
import DistributorCard from '../DistributorCard';
import Styles from './style.module.css';
import distributorType from '@mono-farmart-web/farmart-web-common/constant/enums/distributorType';
import SkeletonLoading from '@mono-farmart-web/farmart-web-common/MuiComponents/Skeleton';
import InfiniteScroll from '@mono-farmart-web/farmart-web-common/Components/InfinityScrollV2';

const CATEGORY_COLORS = {
  [distributorType.seed]: '#FCE4E4', // Seeds - Light pink
  [distributorType.fertiliser]: '#FDF0E1', // Fertiliser - Light orange
  [distributorType.pesticide]: '#E3FBE3', // Pesticides - Light green
};

interface Distributor {
  agency_name: string;
  mobile_number: number;
  full_address: string;
  district_name: string;
  state_name: string;
  category: number[];
}

interface DistributorListProps {
  distributors: Distributor[];
  totalCount: number;
  language: any;
  categoryNameFilter: (id: number) => string;
  distributorLoading: boolean;
  loadMoreDistributors: () => void;
  distributorAssets: { [key: string]: string };
  handleCallClick: (name: string, address: string, category: string, mobileNumber: number) => void;
}

export default function DistributorList({
  distributors,
  totalCount,
  language,
  categoryNameFilter,
  distributorLoading,
  loadMoreDistributors,
  distributorAssets,
  handleCallClick,
}: DistributorListProps) {
  return (
    <Box className={Styles.container}>
      {
        <Box className={Styles.countContainer}>
          <Box className={Styles.line} />
          <MuiTypography
            level="body-md"
            sx={{
              color: 'var(--Light-Neutrals-N-600, #64748B)',
              fontSize: '14px',
              lineHeight: '20px',
              padding: '0 16px',
            }}
          >
            {`${totalCount} ${language.distributorFound}`}
          </MuiTypography>
          <Box className={Styles.line} />
        </Box>
      }

      <Box className={Styles.listContainer} id="distributorScrollContainer">
        {!distributorLoading && distributors.length === 0 ? (
          <Box
            className={Styles.noResults}
            id="DistributorNoResults"
            data-testid="DistributorNoResults"
          >
            <Box className={Styles.noResultsContent}>
              <img
                src={distributorAssets.noDistributorImg}
                alt="No distributors"
                className={Styles.noResultsImage}
              />
              <MuiTypography
                level="body-md"
                id="DistributorNoResultsText"
                data-testid="DistributorNoResultsText"
                sx={{
                  color: 'var(--Light-Neutrals-N-600, #475569)',
                  textAlign: 'center',
                  fontSize: '16px',
                  fontWeight: 400,
                  lineHeight: '24px',
                }}
              >
                {language.noDistributorFound}
              </MuiTypography>
            </Box>
          </Box>
        ) : (
          <InfiniteScroll
            dataLength={distributors.length}
            next={loadMoreDistributors}
            hasMore={distributors.length < totalCount}
            loader={
              <SkeletonLoading
                itemNumber={6}
                sx={{
                  minHeight: 150,
                  borderRadius: 12,
                  alignSelf: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 16,
                }}
              />
            }
            scrollableTarget="distributorScrollContainer"
            className={Styles?.infinityScrollerStyle}
          >
            {distributors.map((distributor, index) => (
              <DistributorCard
                key={`${distributor.agency_name}-${index}`}
                agency_name={distributor.agency_name}
                address={`${distributor.full_address}, ${distributor.district_name}, ${distributor.state_name}`}
                categories={distributor.category.map((catId) => ({
                  name: categoryNameFilter(catId),
                  background: CATEGORY_COLORS[catId] || '#E2E8F0',
                }))}
                index={index}
                mobileNumber={distributor.mobile_number}
                handleCallClick={handleCallClick}
              />
            ))}
          </InfiniteScroll>
        )}
      </Box>
    </Box>
  );
}
