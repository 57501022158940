import Modal from '@mono-farmart-web/farmart-web-common/ComponentV2/Modal';
import { Box } from '@mui/joy';
import MuiTypography from '@mono-farmart-web/farmart-web-common/ComponentV3/Typography';
import { FilterModalData } from '../../../hooks/BahiKhataLanding/types';
import bahiKhataFilterLangEnums from '@mono-farmart-web/farmart-web-common/constant/enums/bahiKhataFilterLangEnums';
interface FilterModalProps {
  onClickApplyFilter: (id: number) => void;
  language: { [key: string]: string };
  filterModalVisible: boolean;
  toggleFilterModal: () => void;
  filterValues: FilterModalData[];
  selectedFiler: number;
}

export default function FilterModal(props: FilterModalProps) {
  const {
    onClickApplyFilter,
    language,
    filterModalVisible,
    toggleFilterModal,
    filterValues,
    selectedFiler,
  } = props;

  // Content of the delete modal
  const modalContent = (
    <Box>
      {filterValues.map((item, index) => (
        <Box padding={'12px'} onClick={() => onClickApplyFilter(item.id)}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding="12px"
            bgcolor={item?.id === selectedFiler ? 'primary.softBg' : 'background.level1'}
            borderRadius={'8px'}
            border={1}
            borderColor={
              item?.id === selectedFiler
                ? 'primary.outlinedBorder'
                : 'neutral.outlinedDisabledBorder'
            }
          >
            <MuiTypography
              level="body-xs"
              textColor={
                item?.id === selectedFiler ? 'text.primary' : 'text.secondary'
              }
            >
              {language[bahiKhataFilterLangEnums.get(item.id)]}
            </MuiTypography>
            <MuiTypography level="body-xxs">{item.count}</MuiTypography>
          </Box>
        </Box>
      ))}
    </Box>
  );

  return (
    <Modal
      isOpen={filterModalVisible} // Flag to determine if delete modal is open
      // eslint-disable-next-line react/no-children-prop
      children={modalContent} // Pass modal content as children
      title={'Filters'} // Display delete modal title
      isCloseButtonOption={true} // Enable close button option
      onClose={toggleFilterModal}
    />
  );
}
