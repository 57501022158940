import React from 'react';
import Styles from './style.module.css';
import Button from '@mono-farmart-web/farmart-web-common/ComponentV3/MuiButton';
import MuiTypography from '@mono-farmart-web/farmart-web-common/ComponentV3/Typography';
import { PrivacyPolicy } from '@mono-farmart-web/farmart-web-common/constant/PrivacyPolicyUrl';
import { Box } from '@mui/joy';
import MuiButton from '@mono-farmart-web/farmart-web-common/ComponentV3/MuiButton';

interface ActionButtonProps {
  onClickLoginProceed?: () => void;
  loading: boolean;
  userMobileNumber: string;
  onClickPrivacyPolicy: (item: string) => () => void;
  language: { [key: string]: any };
  onContactUs: () => void;
  whatsAppIconUrl: string;
  isKeyboardOpen?: boolean;
}

export default function Actionbutton(props: ActionButtonProps) {
  const {
    onClickLoginProceed,
    loading,
    userMobileNumber,
    onClickPrivacyPolicy,
    language,
    onContactUs,
    whatsAppIconUrl,
    isKeyboardOpen,
  } = props;
  const renderTextWithColors = (text) => {
    if (!text) {
      return;
    }
    const splitText = text?.split(/{|}/);

    return splitText?.map((item, index) => {
      if (item.startsWith('(') && item.endsWith(')')) {
        const innerText = item.slice(1, -1);
        return (
          <a
            itemID=""
            key={index}
            href={PrivacyPolicy}
            className={Styles.highlightedText}
            id={`TermsAndCondition${index}`}
            onClick={onClickPrivacyPolicy(item)}
          >
            {innerText}
          </a>
        );
      }
      return (
        <MuiTypography
          level="label-sm"
          key={index}
          className={Styles.termAndCondition}
        >
          {item}
        </MuiTypography>
      );
    });
  };
  return (
    <Box
      className={Styles?.actionContainer}
      flex={1}
      display={'flex'}
      justifyContent={'flex-end'}
      paddingTop={10}
      bgcolor={'background.surface'}
    >
      {!isKeyboardOpen && (
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'}
        marginBottom={'8px'}
        >
          <Box className={Styles.helpButtonContainer} onClick={onContactUs} id='HelpButton'>
            <img
              className={Styles.whatsAppIconStyle}
              src={whatsAppIconUrl}
              alt="WhatsApp Icon"
              height={16}
              width={16}
              loading="eager"
            />
            <div className={Styles.helpTextStyle}>{language?.help}</div>
          </Box>

          <Box className={Styles.messageText}>
          {renderTextWithColors(language?.termsAndCondtions)}
          </Box>
        </Box>
      )}

      <Box className={Styles.proceedButtonContainer}>
        <MuiButton
          id="ProceedButton"
          size="lg"
          onClick={onClickLoginProceed}
          loading={loading}
          disabled={userMobileNumber.length < 10 ? true : false}
        >
          {language?.proceed}
        </MuiButton>
      </Box>
    </Box>
  );
}
