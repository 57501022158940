import type React from "react";
import { useState, useEffect } from "react";
import type { PopDataInterface, SaudaAcknowledgement } from "./types";
import { useLocation, useNavigate } from "react-router-dom";
import GetRequest from "@mono-farmart-web/farmart-web-common/utils/apiCaller/getRequest";
import { apiEndPoints } from "@mono-farmart-web/farmart-web-common/apis/endpoints/index";
import createSauda from "@mono-farmart-web/farmart-web-common/modal/mySauda/models/createSauda";
import Events from "@mono-farmart-web/farmart-web-common/constant/MoengageEvents/index";
import { usePlatfromData } from "@mono-farmart-web/farmart-web-common/context/CrossPlatformActionHandler/platformChecker";
import { trackEvent } from "@mono-farmart-web/farmart-web-common/utils/moengage/index";
import BrokerCodeState from "@mono-farmart-web/farmart-web-common/constant/enums/brokerCodeState";
import { helpLineNumber } from "@mono-farmart-web/farmart-web-common/constant/HelpLineNumber";
import popEnums from "@mono-farmart-web/farmart-web-common/constant/enums/popEnums";
import { languageUrls } from "@mono-farmart-web/farmart-web-common/constant/languageUrls/index";
import { DEFAULT_LANGUAGE } from "@mono-farmart-web/farmart-web-common/constant/language";
import { getLanguageService } from "@mono-farmart-web/farmart-web-common/utils/getLanguageService/index";
import getLang from "@mono-farmart-web/farmart-web-common/languages/saudaAcknowledgement";
import formatPrice from '@mono-farmart-web/farmart-web-common/utils/formatPrice';
import { createBridge } from "@mono-farmart-web/rpc-bridge";
import bridgeFunctions from "@mono-farmart-web/rpc-bridge/utility";
import { getSaudaDetailsAssets } from '@mono-farmart-web/farmart-web-common/constant/imageUrls';

export default function UseSaudaAcknowledgement(): SaudaAcknowledgement {
	const navigate = useNavigate();
	const platformData = usePlatfromData();
	const { state } = useLocation();
	const [quantity, setQuantity] = useState(state?.quantity);
	const [quantityUnit, setQuantityUnit] = useState(state?.quantity_unit);
	const [cropName, setCropName] = useState(state?.priceBreakUp?.crop_name);
	const [paymentTerms, setPaymentTerms] = useState(state?.paymentTerms);
	const [buyerName, setBuyerName] = useState(state?.priceBreakUp?.buyer_name);
	const [buyerAddress, setBuyerAddress] = useState(
	  state.priceBreakUp.buyer_address,
	);
	const [priceBreakup, setPriceBreakup] = useState(state?.priceBreakUp);
	const [netPrice, setNetPrice] = useState(
	  paymentTerms === 'grns-term'
		? state?.priceBreakUp?.price_breakup_grn?.net_price
		: state?.priceBreakUp?.price_breakup_buyer?.net_price,
	);
	const [netPriceUnit, setNetPriceUnit] = useState(
	  paymentTerms === 'grns-term'
		? state?.priceBreakUp.price_breakup_grn?.net_price_unit
		: state?.priceBreakUp.price_breakup_buyer?.net_price_unit,
	);
	const [stateName, setStateName] = useState(state?.priceBreakUp?.state_name);
	const [districtName, setDistrictName] = useState(
	  state?.priceBreakUp?.district_name,
	);
	const [confirmSaudaLoading, setConfirmSaudaLoading] =
	  useState<boolean>(false);

	const [language, setLanguage] = useState({
	  brokerage: '',
	  brokeragePopUpSubHeading: '',
	  popUpOkayButton: '',
	  farMartFee: '',
	  financePopUpSubHeading: '',
	  netForPrice: '',
	  netPricePopUpSubHeading: '',
	  netPricePopUpSubHeading2: '',
	});

	const langId = JSON.parse(localStorage.getItem("userLanguage"));
	const saudaDetailsAssets = getSaudaDetailsAssets(langId?.langCode || 'HI');

	const [isError, setIsError] = useState<boolean>(false);
	const [brokerCode, setBrokerCode] = useState<string>('');
	const [brokerCodeButtonLoading, setBrokerCodeButtonLoading] = useState(false);
	const [brokerCodeVerified, setBrokerCodeVerified] = useState(false);
	const [brokerCodeState, setBrokerCodeState] = useState(0);
	const [showBrokerCodeInputText, setShowBrokerCodeInputText] = useState(false);
	const [showInvalidBrokeCodeAlert, setShowInvalidBrokerCodeAlert] =
	  useState(false);
	const [brokerId, setBrokerId] = useState<number>();
	const [brokerFirmName, setBrokerFirmName] = useState<string>('');
	const [popupVisible, setPopupVisible] = useState(false);
	const [popupData, setPopData] = useState<PopDataInterface>({
	  title: '',
	  message: '',
	  message2: '',
	  buttonTitle: '',
	});
	const bridge = createBridge();


	useEffect(() => {
	  getLanguageJson();
	}, []);

	async function getLanguageJson() {
	  try {
		const result = await getLanguageService(
		  languageUrls?.saudaAcknowledgement,
		);
		setLanguage(result ?? getLang(DEFAULT_LANGUAGE));
	  } catch (error) {
		console.log(error);
	  } finally {
	  }
	}

	// useEffect to set broker Code state
	useEffect(() => {
	  if (brokerCode?.length === 0) {
		setBrokerCodeState(BrokerCodeState.get('empty')); // Set broker code state to empty
	  } else {
		setBrokerCodeState(BrokerCodeState.get('unverified')); // Set broker code state to unverified
	  }
	  setShowBrokerCodeInputText(false); // Hide broker code input text
	  setBrokerCodeVerified(false); // Set broker code verification state to false
	  setBrokerId(null); //set broker id to null
	  setBrokerFirmName(''); //set broker name to empty string
	}, [brokerCode]);

	// Function to handle help button click
	const onClickHelpSaudaAcknowledgement = () => {
	  trackEvent(Events?.onHelpButtonClick, {
		From: 'Sauda_confirmation',
	  });
	  // URL for WhatsApp support message
	  const url = `https://wa.me/${helpLineNumber}?text=Hey%20Farmart%20%0A%0AWas%20checking%20out%20your%20trade%20section%20on%20the%20farmart%20app.%20%0A%0AI%20need%20Help`;
	  // Open URL in new tab if on WEB platform, otherwise open in WhatsApp for mobile
	  if (platformData.platform === 'WEB') {
		const win = window?.open(url, '_blank');
		win?.focus();
	  } else {
		const text = `Hey Farmart\n I Was checking out your trade section on the farmart app.\n I need Help &phone=${helpLineNumber}`;
		const link = `whatsapp://send?text=${text}`;
		// Post message to React Native WebView for mobile
		bridge.sendRequestToNative(
			"OpenUrl",
			{
				link: link,
			},
			(response) => {
				bridgeFunctions.PrintLog(response);
			},
		);
	  }
	};

	// Function to handle back button click
	const onClickBackSaudaAcknowledgement = () => {
	  trackEvent(Events?.onBackButtonClick, {
		From: 'Sauda_confirmation',
	  });
	  // Navigate back to trades with sauda request details
	  navigate(
		`/trades/${priceBreakup?.price_breakup_buyer?.trade_price_id},${priceBreakup?.price_breakup_grn?.trade_price_id}`,
		{
		  state: {
			requestSauda: true,
			quantity: quantity,
			paymentTerms: paymentTerms,
		  },
		},
	  );
	};

	// Function to show invalid broker code alert
	function showInvalidBrokerCodeAlert() {
	  if (brokerCodeState === 2) {
		setShowInvalidBrokerCodeAlert(true);
	  }
	}

	// Function to confirm sauda
	async function confirmSauda() {
	  let timeoutId: NodeJS.Timeout | undefined;
	  setConfirmSaudaLoading(true); // Set loading state
	  try {
		if (brokerCode?.length === 0 || brokerCodeVerified) {
		  // Create sauda with provided details
		  const createSaudaResp = await createSauda({
			quantity: quantity,
			quantity_unit_id: priceBreakup?.supplier_quantity_unit_id,
			trade_price_id:
			  paymentTerms === 'grns-term'
				? priceBreakup?.price_breakup_grn?.trade_price_id
				: priceBreakup?.price_breakup_buyer?.trade_price_id,
			broker_id:
			  brokerCode.length > 0 && brokerCodeVerified && brokerId
				? brokerId
				: null, //brokerId added along with personalised brokerage discount changes
		  });
		  // Navigate to sauda details page if sauda created successfully
		  if (createSaudaResp?.sauda_id) {
			navigate(`/trades/mySauda/${createSaudaResp?.sauda_id}`);
		  }
		} else if (brokerCode?.length && !brokerCodeVerified) {
		  setShowBrokerCodeInputText(true); // Show broker code input text
		  showInvalidBrokerCodeAlert(); // Show invalid broker code alert
		}
	  } catch (error) {
		// Handle errors
		if (error?.code === 401 || error?.code === 426) {
		  navigate('/trades'); // Navigate to trades on specific errors
		} else {
		  setIsError(true); // Set error state
		  timeoutId = setTimeout(() => {
			navigate('/trades'); // Navigate to trades after 5 seconds on error
		  }, 5000);
		}
		setConfirmSaudaLoading(false); // Reset loading state
		console.log(error);
	  } finally {
		setConfirmSaudaLoading(false); // Reset loading state
		if (timeoutId) {
		  clearTimeout(timeoutId); // Clear the timeout if it was set
		}
	  }
	}

	// useEffect for handling back button functionality
	useEffect(() => {
	  window.onpopstate = () => {
		onClickBackSaudaAcknowledgement();
	  };

	  return () => {
		window.onpopstate = null; // Remove listener on unmount
	  };
	}, []);

	// Function to handle confirming sauda request
	const confirmSaudaRequest = () => {
	  if (state.paymentTerms === 'buyers-term') {
		trackEvent(Events?.onConfirmSaudaRequestSubmissionButtonClick, {
		  Crop_name: state?.priceBreakUp?.crop_name,
		  Buyer_name: state?.priceBreakUp?.buyer_name,
		  Payment_terms: 'Buyer_terms',
		});
	  } else {
		trackEvent(Events?.onConfirmSaudaRequestSubmissionButtonClick, {
		  Crop_name: state?.priceBreakUp?.crop_name,
		  Buyer_name: state?.priceBreakUp?.buyer_name,
		  Payment_terms: 'GRN_terms',
		});
	  }
	  confirmSauda();
	};

	function handleBrokerCodeInputChange(
	  event: React.ChangeEvent<HTMLInputElement>,
	) {
	  const newValue = event.target.value;
	  //regex to check and allow only alpha numeric value
	  if (/^[a-zA-Z0-9]*$/.test(newValue)) {
		setBrokerCode(newValue);
	  }
	}

	function handleOnClickBrokerCodeInput() {
	  trackEvent(Events?.onBrokerCodeInputBoxButttonClick, {}); //trigger MoEngage event to track onClick event for input field
	}

	function clearBrokerCodeInput() {
	  // function to clear broker code input field
	  trackEvent(Events?.onBrokerCodeCloseButtonClick, {}); //trigger MoEngage event to track onClick for close icon in broke code input
	  setBrokerCode('');
	  setShowBrokerCodeInputText(false);
	  setBrokerCodeVerified(false);
	  setBrokerCodeState(BrokerCodeState.get('empty'));
	  setBrokerId(null);
	  setBrokerFirmName('');
	}

	// request to verify broker code
	async function verifyBrokerCode() {
	  trackEvent(Events?.onBrokerCodeApplyButtonClick, {}); //trigger MoEngage Event on click of verify button for broker code
	  setBrokerCodeButtonLoading(true);
	  try {
		const verifyBrokerCodePayload = {
		  broker_code: brokerCode, //sending brokerCode
		};
		const response = await GetRequest({
		  payload: verifyBrokerCodePayload,
		  uri: apiEndPoints.verifyBrokerCode, //api end point stored as contant
		});
		if (response?.data?.status) {
		  //broker code verification successful
		  if (response?.data?.data?.broker_code_exist) {
			setBrokerCodeVerified(true); //set broker code verified to true
			setShowBrokerCodeInputText(true);
			setBrokerCodeState(BrokerCodeState.get('verificationSuccess'));
			const brokerDetails = response?.data?.data?.broker_details; //get Broker Details and set broker Name and broker Id
			if (brokerDetails) {
			  setBrokerFirmName(brokerDetails?.firm_name || '');
			  setBrokerId(brokerDetails?.id || null);
			}
		  } else {
			//broker code verification failed
			setBrokerCodeVerified(false); //set broker code verified to false
			setShowBrokerCodeInputText(true);
			setBrokerCodeState(BrokerCodeState.get('verificationFailed'));
		  }
		}
	  } catch (error) {
		setBrokerCodeVerified(false);
		setShowBrokerCodeInputText(false);
		setBrokerCodeState(BrokerCodeState.get('unverified')); //setting broker code state to unverified in case of error
		setIsError(true);
		setTimeout(() => setIsError(false), 4000);
	  } finally {
		setBrokerCodeButtonLoading(false);
	  }
	}

	//function to handle popup disable and enable
	function onClickPopUpDisable(id?: number) {
	  if (id === popEnums?.get('brokerage')) {
		setPopData({
		  title: language?.brokerage,
		  message: renderTextWithColors({
			text: language?.brokeragePopUpSubHeading,
			replaceText:
			  paymentTerms === 'grns-term'
				? formatPrice({
					prefix:
					  state?.priceBreakUp?.price_breakup_grn
						?.brokerage_price_unit_prefix,
					suffix:
					  state?.priceBreakUp?.price_breakup_grn
						?.brokerage_price_unit_suffix,
					value:
					  state?.priceBreakUp?.price_breakup_grn?.brokerage_price,
				  })
				: formatPrice({
					prefix:
					  state?.priceBreakUp?.price_breakup_buyer
						?.brokerage_price_unit_prefix,
					suffix:
					  state?.priceBreakUp?.price_breakup_buyer
						?.brokerage_price_unit_suffix,
					value:
					  state?.priceBreakUp?.price_breakup_buyer?.brokerage_price,
				  }),
		  }),
		  message2: '',
		  buttonTitle: language?.popUpOkayButton,
		});
	  } else if (id === popEnums?.get('farmartFee')) {
		setPopData({
		  title: language?.farMartFee,
		  message: renderTextWithColors({
			text: language?.financePopUpSubHeading,
			replaceText:
			  paymentTerms === 'grns-term'
				? state?.priceBreakUp?.price_breakup_grn?.farmart_fee
				: state?.priceBreakUp?.price_breakup_buyer?.farmart_fee,
		  }),
		  message2: '',
		  buttonTitle: language?.popUpOkayButton,
		});
	  } else {
		setPopData({
		  title: language?.netForPrice,
		  message: language?.netPricePopUpSubHeading,
		  message2: language?.netPricePopUpSubHeading2,
		  buttonTitle: language?.popUpOkayButton,
		});
	  }
	  setPopupVisible(!popupVisible);
	}

	//function to render text with colors
	function renderTextWithColors({ text, replaceText }) {
	  const splitText = text?.split(/{|}/);

	  return splitText.map((item, index) => {
		if (item.startsWith('(') && item.endsWith(')')) {
		  return (
			<span key={'saudaAcknowledgementPopUpModalRenderText' + index}>
			  {replaceText}
			</span>
		  );
		}
		return <>{item}</>;
	  });
	}

	return {
	  quantity,
	  quantityUnit,
	  cropName,
	  paymentTerms,
	  buyerName,
	  buyerAddress,
	  priceBreakup,
	  netPrice,
	  netPriceUnit,
	  confirmSaudaRequest,
	  confirmSaudaLoading,
	  language,
	  onClickHelpSaudaAcknowledgement,
	  onClickBackSaudaAcknowledgement,
	  districtName,
	  stateName,
	  isError,
	  setIsError,
	  brokerCode,
	  brokerFirmName,
	  brokerCodeButtonLoading,
	  brokerCodeState,
	  brokerCodeVerified,
	  handleBrokerCodeInputChange,
	  verifyBrokerCode,
	  showBrokerCodeInputText,
	  clearBrokerCodeInput,
	  showInvalidBrokeCodeAlert,
	  setShowInvalidBrokerCodeAlert,
	  handleOnClickBrokerCodeInput,
	  onClickPopUpDisable,
	  popupVisible,
	  popupData,
	  saudaDetailsAssets,
	};
  }
