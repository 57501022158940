import Login from '../../Pages/Login/Login';
import MobileSubmitHooks from '../../hooks/Login/mobileSubmitHook';

export default function trader({}) {
  const {
    error,
    getMobileNumber,
    userBlocked,
    userMobileNumber,
    onClickLoginProceed,
    loading,
    initialScreenLoading,
    handleLanguageSelect,
    onLanguageModalToggle,
    handleLanguageChange,
    selectedLanguage,
    isLanguageModalOpen,
    isLanguageSelectedInProgress,
    onClickPrivacyPolicy,
    language,
    onContactUs,
    loginScreenAssets,
    languageData,
    onClickBack,
    useKeyboardStatus,
    onClickInputClear
  } = MobileSubmitHooks();
  return (
    <Login
      error={error}
      getMobileNumber={getMobileNumber}
      userBlocked={userBlocked}
      userMobileNumber={userMobileNumber}
      onClickLoginProceed={onClickLoginProceed}
      loading={loading}
      initialScreenLoading={initialScreenLoading}
      handleLanguageSelect={handleLanguageSelect}
      onLanguageModalToggle={onLanguageModalToggle}
      handleLanguageChange={handleLanguageChange}
      selectedLanguage={selectedLanguage}
      isLanguageModalOpen={isLanguageModalOpen}
      isLanguageSelectedInProgress={isLanguageSelectedInProgress}
      onClickPrivacyPolicy={onClickPrivacyPolicy}
      language={language}
      onContactUs={onContactUs}
      loginScreenAssets={loginScreenAssets}
      languageData={languageData}
      onClickBack={onClickBack}
      useKeyboardStatus={useKeyboardStatus}
      onClickInputClear={onClickInputClear}
    />
  );
}
