import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { apiEndPoints } from "@mono-farmart-web/farmart-web-common/apis/endpoints/index.js";
import useAuthenticationChecker from "@mono-farmart-web/farmart-web-common/utils/authCheckerV2";
import type {
	BahiKhataLandingProps,
	BahiKhataBalanceProps,
	KhataListData,
} from "./types.js";
import { trackEvent } from "@mono-farmart-web/farmart-web-common/utils/moengage/index";
import Events from "@mono-farmart-web/farmart-web-common/constant/MoengageEvents/index"; 
import useAppLanguage from "@mono-farmart-web/farmart-web-common/utils/applanguage/index.js";
import { languageUrls } from "@mono-farmart-web/farmart-web-common/constant/languageUrls/index";
import getLang from "@mono-farmart-web/farmart-web-common/languages/bahiKhataLanding.js";
import { getBahiKhataLandingAssets } from "@mono-farmart-web/farmart-web-common/constant/imageUrls";
import bahiKhataFilterEnums from "@mono-farmart-web/farmart-web-common/constant/enums/bahiKhataFilterEnums";
import bahiKhataSortEnums from "@mono-farmart-web/farmart-web-common/constant/enums/bahiKhataSortEnums";

export default function BahiKhataLanding(): BahiKhataLandingProps {
	const navigate = useNavigate();

	const { pathname, state } = useLocation();

	const { AuthenticationCheckerApiCaller } = useAuthenticationChecker();

	const [alert, setAlert] = useState({
		message: "",
		openClose: false,
	});

	const { language } = useAppLanguage(
		languageUrls?.bahiKhataLandingLangUrl,
		getLang,
	);

	const langId = JSON.parse(localStorage.getItem("userLanguage"));
	const bahiKhataLandingAssets = getBahiKhataLandingAssets(langId?.langCode);

	const [khataList, setKhataList] = useState<KhataListData[]>([]);
	const [initialCount, setInitialCount] = useState(0);
	const [initialLoading, setInitialLoading] = useState(true);
	const [balanceLoading, setBalaceLoading] = useState(true);
	const [userBalance, setUserBalance] = useState<BahiKhataBalanceProps>({
		total_credit_amount: 0,
		amount_to_give: 0,
		amount_to_take: 0,
		khatas_created_since: "0",
	});
	const [selectedFiler, setSelectedFilter] = useState(1);
	const [selectedSorting, setSelectedSorting] = useState(1);

	const [currentPage, setCurrentPage] = useState(1);
	const [totalCount, setTotalCount] = useState(0);
	const [filterModalVisible, setFilterModalVisible] = useState(false);
	const [sortingModalVisible, setSortingModalVisible] = useState(false);
	const [userGuideVisible, setUserGuideVisible] = useState(
		localStorage.getItem("bahiKhataGuide") === null ? true : false,
	);

	const [bahiKhataError, setBahiKhataError] = useState(false);
	const [refreshPage, setRefreshPage] = useState(false);

	const [filterValues, setFilterValues] = useState([]);
	const initialFilterData = [
		{ id: 1, filterName: "all_khata" },
		{ id: 2, filterName: "you_will_get" },
		{ id: 3, filterName: "you_will_give" },
	];

	function appendFilterValues(languages, counts) {
		return languages.map((lang) => {
			// Get the count from filter_counts, converting to a number if necessary
			const count = Number(counts[lang.filterName]) || 0; // Default to 0 if not found
			return { ...lang, count }; // Return a new object with the count
		});
	}

	function onClickCloseGuide() {
		localStorage.setItem("bahiKhataGuide", "false");
		setUserGuideVisible(!userGuideVisible);
		trackEvent(Events?.onBahiKhataModalOkeyButtonClick, {});
	}

	function toggleSortingModal() {
		if (!sortingModalVisible) {
			trackEvent(Events?.onBahiKhataHomeScreenSortButtonClick, {});
		} else {
			trackEvent(Events?.onBahiKhataSortbyModalCrossButtonClick, {});
		}
		setSortingModalVisible(!sortingModalVisible);
	}

	function toggleFilterModal() {
		if (!filterModalVisible) {
			trackEvent(Events?.onBahiKhataHomeScreenFilterButtonClick, {});
		} else {
			trackEvent(Events?.onBahiKhataFilterModalCrossButtonClick, {});
		}
		setFilterModalVisible(!filterModalVisible);
	}

	function onClickApplyFilter(id: number) {
		trackEvent(Events?.onBahiKhataFilterOptionSelected, {
			Option_selected: bahiKhataFilterEnums.get(id),
		});
		setSelectedFilter(id);
		setFilterModalVisible(!filterModalVisible);
		setCurrentPage(1);
	}

	function onClicApplySorting(id: number) {
		trackEvent(Events?.onBahiKhataSortbyOptionSelected, {
			Option_selected: bahiKhataSortEnums.get(id),
		});
		setSelectedSorting(id);
		setSortingModalVisible(!sortingModalVisible);
		setCurrentPage(1);
	}

	function onClickSeachKhata() {
		navigate("/searchKhata");
		trackEvent(Events?.onBahiKhataHomeScreenInputBoxClick, {});
	}

	useEffect(() => {
		if (currentPage === 1) {
			getKhataList(true);
		} else if (currentPage !== 1) {
			getKhataList(false);
		}
	}, [currentPage, selectedFiler, selectedSorting, refreshPage]);

	async function getKhataList(isInitialLoad: boolean) {
		setBahiKhataError(false);
		if (isInitialLoad) {
			setInitialLoading(true);
		}

		try {
			const fetchMyFarmersParams = {
				page_no: currentPage,
				limit: 100,
				search_param: "",
				filter: selectedFiler,
				sort_by: selectedSorting,
			};
			const farmersResp = await AuthenticationCheckerApiCaller({
				payload: fetchMyFarmersParams,
				uri: apiEndPoints.fetchBahiKhataList,
				requestType: "get",
			});

			if (farmersResp?.data?.status) {
				if (isInitialLoad) {
					setKhataList(farmersResp?.data.data.transaction_list.results);
					const filterCounts = appendFilterValues(
						initialFilterData,
						farmersResp?.data.data.filter_counts,
					);
					setFilterValues(filterCounts);
					if (initialCount === 0) {
						setInitialCount(farmersResp?.data.data.transaction_list.count);
					}
				} else {
					setKhataList((prevFarmers) => [
						...prevFarmers,
						...farmersResp.data.data.transaction_list.results,
					]);
				}
				setTotalCount(farmersResp.data.data.transaction_list.count);
			}
		} catch (error) {
			setBahiKhataError(true);
			console.log("Error in fetching My Farmers", error);
		} finally {
			setInitialLoading(false);
		}
	}

	useEffect(() => {
		getUserBalance();
	}, [refreshPage]);

	async function getUserBalance() {
		setBahiKhataError(false);
		setBalaceLoading(true);
		try {
			const userBalaceResp = await AuthenticationCheckerApiCaller({
				payload: {},
				uri: apiEndPoints?.fetchBahiKhataBalance,
				requestType: "get",
			});

			if (userBalaceResp?.data?.status) {
				setUserBalance(userBalaceResp?.data.data);
			}
		} catch (error) {
			setBahiKhataError(true);
			console.log("Error in fetching My Farmers", error);
		} finally {
			setBalaceLoading(false);
		}
	}

	function loadMoreFarmers() {
		if (totalCount !== khataList?.length) {
			setCurrentPage((prevPage) => prevPage + 1);
		}
	}

	// Function to handle back button press
	function onPressBack() {
		navigate(-1);
	}

	function onContactUs() {
		navigate("/faq/questions");
	}

	const onClickKhata = (farmerId: number) => {
		navigate(`/bahiKhata/${farmerId}`);
	};

	function onClickRecordNewTransaction() {
		//navigate('/recordNewTransaction');
		trackEvent(Events?.onRecordNewTransactionButtonClick, {});

		const URL = "/bahiKhata/contacts";
		navigate(URL);
	}

	function onClickRetry() {
		setCurrentPage(1);
		setRefreshPage(!refreshPage);
	}

	function onClickBack() {
		navigate("/", {
			replace: true,
		});
	}

	useEffect(() => {
		window.addEventListener("popstate", onClickBack);
		return () => {
			window.removeEventListener("popstate", onClickBack);
		};
	}, [navigate]);

	useEffect(() => {
		if (state?.khataDeleted) {
			setAlert({
				message: `${state?.khataName} ${language?.khataDeleted}`,
				openClose: true,
			});
			const timeoutId = setTimeout(() => {
				setAlert({
					message: "",
					openClose: false,
				});
				navigate(pathname, { replace: true, state: null });
			}, 3000);
			// Cleanup function to clear the timeout if the component unmounts
			return () => clearTimeout(timeoutId);
		}
	}, []);

	return {
		onPressBack,
		language,
		onContactUs,
		khataList,
		initialLoading,
		currentPage,
		loadMoreFarmers,
		onClickKhata,
		totalCount,
		toggleSortingModal,
		toggleFilterModal,
		onClickApplyFilter,
		onClicApplySorting,
		onClickSeachKhata,
		balanceLoading,
		userBalance,
		userGuideVisible,
		onClickCloseGuide,
		filterModalVisible,
		sortingModalVisible,
		filterValues,
		selectedFiler,
		selectedSorting,
		bahiKhataLandingAssets,
		onClickRecordNewTransaction,
		onClickRetry,
		bahiKhataError,
		initialCount,
		alert,
	};
}
