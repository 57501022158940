import { useState, useEffect } from 'react';
import type { createDispatchTypes } from './types';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import getDispatchDetailsApi from '@mono-farmart-web/farmart-web-common/modal/mySauda/models/getDispatchDetails';
import type {
  DispatchDetailsResponse,
  Folder,
} from '@mono-farmart-web/farmart-web-common/modal/mySauda/types';
import Events from '@mono-farmart-web/farmart-web-common/constant/MoengageEvents/index';
import { trackEvent } from '@mono-farmart-web/farmart-web-common/utils/moengage';
import DispatchDocumentEnums from '@mono-farmart-web/farmart-web-common/constant/enums/dispatchDocumentEnums';
import UnloadingDocumentEnums from '@mono-farmart-web/farmart-web-common/constant/enums/unloadingDocumentEnums';
import CreateDispatchApi from '@mono-farmart-web/farmart-web-common/modal/mySauda/models/createDispatch';
import UploadUnloadingDocuments from '@mono-farmart-web/farmart-web-common/modal/mySauda/models/uploadUnloadingDocs';
import ReuploadUnloadingDocuments from '@mono-farmart-web/farmart-web-common/modal/mySauda/models/reuploadUnloadingDocs';
import UpdateDispatchApi from '@mono-farmart-web/farmart-web-common/modal/mySauda/models/updateDispatch';
import DocumentType from '@mono-farmart-web/farmart-web-common/constant/enums/docType';
import { usePlatfromData } from '@mono-farmart-web/farmart-web-common/context/CrossPlatformActionHandler/platformChecker';
import { unVerifiedRoutes } from '@mono-farmart-web/farmart-web-common/constant/unVerifiedRoutes';
import { loginAsGuest } from '@mono-farmart-web/farmart-web-common/modal/auth/auth.model';
import { useAuth } from '@mono-farmart-web/farmart-web-common/context/login';
import saudaTypeEnum from '@mono-farmart-web/farmart-web-common/constant/enums/saudaTypeEnum';
import { getLanguageService } from '@mono-farmart-web/farmart-web-common/utils/getLanguageService';
import { languageUrls } from '@mono-farmart-web/farmart-web-common/constant/languageUrls';
import tourGuideEnums from '@mono-farmart-web/farmart-web-common/constant/enums/tourGuideEnums';
import { apiEndPoints } from '@mono-farmart-web/farmart-web-common/apis/endpoints';
import GetRequest from '@mono-farmart-web/farmart-web-common/utils/apiCaller/getRequest';
import PostRequest from '@mono-farmart-web/farmart-web-common/utils/apiCaller/postRequest';
import getLang from '@mono-farmart-web/farmart-web-common/languages/trackConsent.js';
import useAppLanguage from '@mono-farmart-web/farmart-web-common/utils/applanguage';
import tripTrackingStatus from '@mono-farmart-web/farmart-web-common/constant/enums/tripTrackingStatus';

export default function useCreateDispatch(): createDispatchTypes {
  const navigate = useNavigate();
  const platformData = usePlatfromData();
  const auth = useAuth();
  const userLogedIn = localStorage.getItem('loged');
  const langId = JSON.parse(localStorage.getItem('userLanguage'));
  const { dispatchId, saudaCreationType } = useParams();
  const { state } = useLocation();
  const POID = state?.poID;
  const dispatchID = dispatchId || state?.dispatchId;
  const saudaId = state?.saudaId;
  const saudaDetailData = {...state?.saudaDetailData, poID: POID};

  const [dispatchDetails, setDispatchDetails] =
    useState<DispatchDetailsResponse>(null);
  const [truckId, setTruckId] = useState(null);
  const [showTourGuide, setShowTourGuide] = useState(false);

  const docTypeEnums = DocumentType;

  const [dispatchWeight, setDispatchWeight] = useState<string>(
    sessionStorage.getItem('dispatchWeight')
      ? sessionStorage.getItem('dispatchWeight')
      : '',
  );
  const [truckNumber, setTruckNumber] = useState<string>(
    sessionStorage.getItem('truckNumber')
      ? sessionStorage.getItem('truckNumber')
      : '',
  );
  const [noOfBags, setNoOfBags] = useState<string>(
    sessionStorage.getItem('noOfBags')
      ? sessionStorage.getItem('noOfBags')
      : '',
  );
  const [truckDriverNumber, setTruckDriverNumber] = useState<string>(
    sessionStorage.getItem('truckDriverNumber')
      ? sessionStorage.getItem('truckDriverNumber')
      : '',
  );
  const [dispatchDetailLoading, setDispatchDetailLoading] =
    useState<boolean>(false);
  const [createDispatchToastPopup, setCreateDispatchToastPopup] =
    useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [mandiToolTipVisible, setMandiToolTipVisible] =
    useState<boolean>(false);

  const [invoiceDocument, setInvoiceDocument] = useState<Folder>(
    getDocuments(1) ? { docs: getDocuments(1) } : null,
  );
  const [weightSlipDocument, setWeightSlipDocument] = useState<Folder>(
    getDocuments(2) ? { docs: getDocuments(2) } : null,
  );
  const [mandiDoument, setMandiDocument] = useState<Folder>(
    getDocuments(3) ? { docs: getDocuments(3) } : null,
  );
  const [buyerGRNDocument, setBuyerGRNDocument] = useState<Folder>(
    getDocuments(4) ? { docs: getDocuments(4) } : null,
  );
  const [unloadingWeightSlipDocument, setUnloadingWeightSlipDocument] =
    useState<Folder>(getDocuments(5) ? { docs: getDocuments(5) } : null);

  const [error, setError] = useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [showCancelDispatchModal, setShowCancelDispatchModal] =
    useState<boolean>(false);
  const [guestLoginAttempts, setGuestLoginAttempts] = useState<number>(0);
  const [truckErrorMessage, setTruckErrorMessage] = useState<boolean>(false);
  const [language, setLanguageFile] = useState({});
  const [tripDetails, setTripDetails] = useState<any>({});
  const [uploadedByFarMart, setUploadedByFarMart] = useState(false);
  let backConfirmationText = '';
  const { language: trackConsentLanguage } = useAppLanguage(
    languageUrls?.trackConsent,
    getLang,
  );
  const [createDispatchError, setCreateDispatchError] = useState(false);

  useEffect(() => {
    getLanguageJson();
  }, []);

  useEffect(() => {
    fetchTourGuideDetails();
  }, []);

  useEffect(() => {
    if (saudaDetailData?.sauda_creation_type) {
      sessionStorage.setItem(
        'saudaCreationType',
        saudaDetailData?.sauda_creation_type,
      );
    }
  }, []);

  async function fetchTourGuideDetails() {
    try {
      const tourGuideResp = await GetRequest({
        payload: {},
        uri: `${apiEndPoints?.userGuide}/${tourGuideEnums.get(
          'dispatch_detail',
        )}`,
      });
      if (tourGuideResp?.data?.status) {
        setShowTourGuide(!tourGuideResp?.data?.data?.is_completed);
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  }

  async function updateTourGuide() {
    try {
      const tourGuideData = await PostRequest({
        payload: {
          guide_id: tourGuideEnums.get('dispatch_detail'),
          is_completed: true,
        },
        uri: apiEndPoints?.userGuide,
      });
      if (tourGuideData?.data?.status) {
        setShowTourGuide(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  }

  async function getLanguageJson() {
    try {
      const url = languageUrls?.createDispatchUrl;
      const result = await getLanguageService(url);
      setLanguageFile(result);
      backConfirmationText = result?.backConfirmationText;
    } catch (error) {
      console.log(error);
    } finally {
    }
  }

  function clearSessionStorage() {
    sessionStorage.removeItem('dispatchWeight');
    sessionStorage.removeItem('truckNumber');
    sessionStorage.removeItem('noOfBags');
    sessionStorage.removeItem('truckDriverNumber');
    sessionStorage.removeItem('invoice_docs');
    sessionStorage.removeItem('mandi_tax');
    sessionStorage.removeItem('weight_slip');
    sessionStorage.removeItem('buyerGRN');
    sessionStorage.removeItem('unloadingWeightSlip');
    sessionStorage.removeItem('createDispatchAttempt');
    sessionStorage.removeItem('documentUploadAttempt');
    sessionStorage.removeItem('saudaCreationType');
  }

  function getDocuments(docType: number) {
    if (sessionStorage.getItem(docTypeEnums.get(docType)) !== null) {
      const docs = JSON.parse(
        sessionStorage.getItem(docTypeEnums.get(docType)),
      );
      return [...docs?.oldDocs, ...docs?.newDocs, ...docs?.updatedDocs];
    } else {
      return null;
    }
  }

  function getUpdatedDocs(docType: number) {
    if (sessionStorage.getItem(docTypeEnums.get(docType)) !== null) {
      const docs = JSON.parse(
        sessionStorage?.getItem(docTypeEnums.get(docType)),
      );
      return [...docs?.newDocs, ...docs?.updatedDocs];
    }
    return null;
  }

  const controlBrowserNavigation = () => {
    if (
      JSON.parse(sessionStorage.getItem('createDispatchAttempt')) ||
      JSON.parse(sessionStorage.getItem('documentUploadAttempt'))
    ) {
      window.alert(backConfirmationText);
      clearSessionStorage();
      navigate(`/trades/mySauda/${saudaId}`);
    } else {
      clearSessionStorage();
    }
  };

  const onClickInput = (value: string) => {
    trackEvent(
      value === 'dispatchWeight'
        ? Events?.onDispatchedWeightInputBoxClick
        : value === 'truckNumber'
        ? Events?.onTruckNumberInputBoxClick
        : value === 'numberOfBag'
        ? Events?.onNumberOfBagInputBoxClick
        : value === 'truckDriverMobileNumber' &&
          Events?.onTruckDriverMobileNumberInputBoxClick,
      {},
    );
  };

  const setCreateDispatchAttempt = () => {
    sessionStorage.setItem('createDispatchAttempt', JSON.stringify(true));
  };

  const onChangeDispatchWeight = (event: any) => {
    const value = event?.target?.value;
    const sanitizedValue = value?.replace(/[^\d.]/g, '');
    const finalValue = sanitizedValue?.replace(/(\..*)\./g, '$1');
    setDispatchWeight(finalValue);
    sessionStorage.setItem('dispatchWeight', finalValue);
    setCreateDispatchAttempt();
  };

  const onChangeTruckNumber = (event: any) => {
    const value = event?.target?.value;
    const finalValue = value?.replace(/[^a-zA-Z0-9]/g, '');
    setTruckNumber(finalValue);
    sessionStorage.setItem('truckNumber', finalValue);
    setCreateDispatchAttempt();
  };

  const onChangeNoOfBags = (event: any) => {
    const value = event?.target?.value;
    const finalValue = value?.replace(/[^\d]/g, '');
    setNoOfBags(finalValue);
    sessionStorage.setItem('noOfBags', finalValue);
    setCreateDispatchAttempt();
  };

  const onChangeTruckDriverNumber = (event: any) => {
    const value = event?.target?.value;
    const finalValue = value?.replace(/[^\d]/g, '');
    setTruckDriverNumber(finalValue);
    sessionStorage.setItem('truckDriverNumber', finalValue);
    setCreateDispatchAttempt();
  };

  function onClickBackButton() {
    trackEvent(Events?.onBackButtonClick, {
      From: 'Truck Details Create Dispatch',
    });
    if (
      (sessionStorage.getItem('createDispatchAttempt') !== null &&
        JSON.parse(sessionStorage.getItem('createDispatchAttempt'))) ||
      JSON.parse(sessionStorage.getItem('documentUploadAttempt'))
    ) {
      setShowCancelDispatchModal(!showCancelDispatchModal);
    } else {
      if (platformData.platform === 'WEB' && userLogedIn) {
        auth?.setMobileNumberModalVisible(false);
      }
      if (
        (sessionStorage.getItem('createDispatchAttempt') !== null &&
          JSON.parse(sessionStorage.getItem('createDispatchAttempt'))) ||
        JSON.parse(sessionStorage.getItem('documentUploadAttempt'))
      ) {
        setShowCancelDispatchModal(!showCancelDispatchModal);
      } else {
        // navigate(-1)
        navigate(`/trades/mySauda/${saudaId}`);
        // navigate(`/trades/mySauda/createDispatch/${dispatchId}`);
        clearSessionStorage();
      }
      //please add route states and route address as per requirement
    }
  }

  async function getDispatchDetails() {
    setDispatchDetailLoading(true);
    const _saudaCreationType = JSON.parse(
      sessionStorage?.getItem('saudaCreationType'),
    );
    try {
      const DispatchDetailspayload: any = {
        dispatch_id: Number(dispatchId),
        sauda_creation_type:
          saudaDetailData?.sauda_creation_type ||
          saudaCreationType ||
          _saudaCreationType ||
          saudaTypeEnum?.online,
      };
      const dispatchDetailsResponse = await getDispatchDetailsApi(
        DispatchDetailspayload,
      );
      setTruckId(dispatchDetailsResponse?.truck_id);
      setTripDetails(dispatchDetailsResponse?.trip_details);
      setDispatchDetails(dispatchDetailsResponse);
      setDispatchWeight(dispatchDetailsResponse?.dispatched_weight.toString());
      setTruckNumber(dispatchDetailsResponse?.vehicle_number.toString());
      setNoOfBags(dispatchDetailsResponse?.number_of_bags.toString());
      setTruckDriverNumber(
        dispatchDetailsResponse?.truck_driver_mobile_number?.toString(),
      );
      setInvoiceDocument(
        dispatchDetailsResponse?.invoice_documents.docs?.length
          ? dispatchDetailsResponse?.invoice_documents
          : { docs: getDocuments(1) },
      );
      setWeightSlipDocument(
        dispatchDetailsResponse?.weight_slip_documents?.docs?.length
          ? dispatchDetailsResponse?.weight_slip_documents
          : { docs: getDocuments(2) },
      );
      setMandiDocument(
        dispatchDetailsResponse?.mandi_documents.docs?.length
          ? dispatchDetailsResponse?.mandi_documents
          : { docs: getDocuments(3) },
      );
      setBuyerGRNDocument(
        dispatchDetailsResponse?.buyer_grn_documents?.docs?.length
          ? dispatchDetailsResponse?.buyer_grn_documents
          : { docs: getDocuments(4) },
      );
      setUnloadingWeightSlipDocument(
        dispatchDetailsResponse?.unloading_weight_slip?.docs?.length
          ? dispatchDetailsResponse?.unloading_weight_slip
          : { docs: getDocuments(5) },
      );
      setUploadedByFarMart(dispatchDetailsResponse?.uploaded_by_farmart);
      sessionStorage.setItem(
        'dispatchWeight',
        dispatchDetailsResponse?.dispatched_weight.toString(),
      );
      sessionStorage.setItem(
        'noOfBags',
        dispatchDetailsResponse?.number_of_bags.toString(),
      );
      sessionStorage.setItem(
        'truckNumber',
        dispatchDetailsResponse?.vehicle_number.toString(),
      );
      sessionStorage.setItem(
        'truckDriverNumber',
        dispatchDetailsResponse?.vehicle_number.toString(),
      );
      setDispatchDetailLoading(false);
    } catch (error) {
      if (error?.code === 401 || error?.code === 426) {
        if (platformData.platform === 'WEB') {
          if (unVerifiedRoutes?.guestLogin?.includes(error?.path)) {
            await loginAsGuest();
            setGuestLoginAttempts((lastValue) => {
              return lastValue + 1;
            });
          } else {
            auth.setMobileNumberModalVisible(true);
          }
        }
      } else {
        setIsError(true);
        setTimeout(() => {
          navigate(`/trades/mySauda`);
        }, 5000);
      }
      console.log(error);
    }
  }

  const createFileList = (documentType: number) => {
    if (DispatchDocumentEnums.get(documentType) === 'invoice') {
      return invoiceDocument?.folder_status_id ? invoiceDocument?.docs : [];
    } else if (DispatchDocumentEnums.get(documentType) === 'weightSlip') {
      return weightSlipDocument?.folder_status_id
        ? weightSlipDocument?.docs
        : [];
    } else if (DispatchDocumentEnums.get(documentType) === 'mandiTax') {
      return mandiDoument?.folder_status_id ? mandiDoument?.docs : [];
    } else if (UnloadingDocumentEnums.get(documentType) === 'buyerGRN') {
      return buyerGRNDocument?.folder_status_id ? buyerGRNDocument?.docs : [];
    } else if (
      UnloadingDocumentEnums?.get(documentType) === 'unloadingWeightSlip'
    ) {
      return unloadingWeightSlipDocument?.folder_status_id
        ? unloadingWeightSlipDocument?.docs
        : [];
    }
  };

  const onClickUploadDocument = (
    value: number,
    documentStatus: string,
    documentType: string,
    folderStatusId: number,
  ) => {
    trackEvent(Events?.onUploadSaudaDocumentButtonClick, {
      'Document Status': documentStatus,
      'Document Type': documentType,
    });
    const fileList = createFileList(value);
    navigate('/trades/uploadDocument', {
      state: {
        docType: value,
        saudaId: state?.saudaId,
        dispatchID: dispatchID,
        fileList: fileList,
        folderStatusId: folderStatusId,
        saudaDetailData: saudaDetailData,
      },
    });
    sessionStorage.setItem('docType', JSON.stringify(value));
  };

  function validatetruckNumber() {
    const pattern = /^[A-Za-z]{2}[ -]?\w{1,3}[ -]?[A-Za-z]{1}[ -]?\d{4}$/;
    // Check if truck number matches the regex pattern
    if (!pattern.test(truckNumber)) {
      return true;
    } else {
      return false;
    }
  }

  function checkEmptyFields() {
    if (saudaDetailData?.sauda_creation_type === saudaTypeEnum?.online && (
      dispatchWeight === '' ||
      truckNumber === '' ||
      noOfBags === '' ||
      (noOfBags && noOfBags.charAt(0) === '0') ||
      invoiceDocument?.docs?.length === 0 ||
      invoiceDocument?.docs?.length === undefined ||
      weightSlipDocument?.docs?.length === 0 ||
      weightSlipDocument?.docs?.length === undefined ||
      // buyerGRNDocument?.docs?.length === 0 || buyerGRNDocument?.docs?.length === undefined ||
      // unloadingWeightSlipDocument?.docs?.length === 0 || unloadingWeightSlipDocument?.docs?.length === undefined ||
      truckDriverNumber?.length < 10 ||
      (truckDriverNumber && truckDriverNumber.charAt(0) === '0'))
    ) {
      console.log("Setting error to be true");
      setError(true);
    } else {
      setError(false);
    }

    if (saudaDetailData?.sauda_creation_type === saudaTypeEnum?.offline && (
      truckNumber === '' ||
      truckDriverNumber?.length < 10 ||
      (truckDriverNumber && truckDriverNumber.charAt(0) === '0'))
    ) {
      console.log("Setting error to be true");
      setError(true);
    } else {
      setError(false);
    }
  }

  const toggleCancelDispatchModal = () => {
    if (showCancelDispatchModal) {
      moengageEventHandlerCancelDisptchModal()
        ? trackEvent(Events?.onContinueDispatchButtonClick, {})
        : trackEvent(
            Events?.onUnloadingScreenCancelUnloadingBottomModalNoButtonClick,
            {},
          );
    }
    setShowCancelDispatchModal(!showCancelDispatchModal);
  };

  const onClickSubmitButton = async () => {
    trackEvent(Events?.onCreateDispatchSubmitButtonClick, {});
    const validatetruckNumberIsCorrect = validatetruckNumber();
    if (!validatetruckNumberIsCorrect) {
      setTruckErrorMessage(false);
      try {
        setSubmitLoading(true);
        if (dispatchID) {
          const payload = {
            sauda_id: Number(saudaId),
            dispatch_id: Number(dispatchID),
            invoice_documents:
              getUpdatedDocs(1) === null ? [] : getUpdatedDocs(1),
            mandi_documents:
              getUpdatedDocs(3) === null ? [] : getUpdatedDocs(3),
            weight_slip_documents:
              getUpdatedDocs(2) === null ? [] : getUpdatedDocs(2),
          };
          const response = await UpdateDispatchApi(payload);
          clearSessionStorage();
          setGuestLoginAttempts(0);
          navigate(`/trades/mySauda/${saudaId}`);
        } else {
          const payload = {
            sauda_id: Number(saudaId),
            vehicle_number: truckNumber.toString(),
            dispatched_weight: Number(dispatchWeight),
            number_of_bags: Number(noOfBags),
            truck_driver_mobile_number: Number(truckDriverNumber),
            invoice_documents:
              invoiceDocument?.docs === null ? [] : invoiceDocument?.docs,
            weight_slip_documents:
              weightSlipDocument?.docs === null ? [] : weightSlipDocument?.docs,
            mandi_documents:
              mandiDoument?.docs === null ? [] : mandiDoument?.docs,
          };
          const response = await CreateDispatchApi(payload);
          clearSessionStorage();
          setGuestLoginAttempts(0);
          setSubmitLoading(false);
          navigate(`/trades/mySauda/${saudaId}`);
        }
      } catch (error) {
        setSubmitLoading(false);
        if (error?.code === 401 || error?.code === 426) {
          if (platformData?.platform === 'WEB') {
            if (unVerifiedRoutes?.guestLogin?.includes(error?.path)) {
              await loginAsGuest();
              setGuestLoginAttempts((lastValue) => {
                return lastValue + 1;
              });
            } else {
              auth.setMobileNumberModalVisible(true);
            }
          }
        } else {
          //   setCreateDispatchError(true);
          handleCreateDispatchToastPopup();
        }
        console.log(error);
      } finally {
        setSubmitLoading(false);
      }
    } else {
      setTruckErrorMessage(true);
      setIsError(true);
    }
  };

  const onUploadUnloadingDocs = async () => {
    try {
      let unloadingWeightSlipDoc = JSON.parse(
        sessionStorage?.getItem('unloadingWeightSlip'),
      );
      let buyerGrnDoc = JSON.parse(sessionStorage?.getItem('buyerGRN'));

      const buyerGrnDocObj = {};
      const unloadingWeightSlipDocObj = {};

      if (
        unloadingWeightSlipDoc &&
        unloadingWeightSlipDoc?.updatedDocs?.length > 0
      ) {
        unloadingWeightSlipDoc = unloadingWeightSlipDoc?.updatedDocs;
        unloadingWeightSlipDocObj['unloading_weight_slip'] =
          unloadingWeightSlipDoc;
      }

      if (buyerGrnDoc && buyerGrnDoc?.updatedDocs?.length > 0) {
        buyerGrnDoc = buyerGrnDoc?.updatedDocs;
        buyerGrnDocObj['buyer_grn_doc'] = buyerGrnDoc;
      }

      if (
        Object.keys(buyerGrnDocObj)?.length > 0 ||
        Object?.keys(unloadingWeightSlipDocObj)?.length > 0
      ) {
        const payload = {
          sauda_id: Number(saudaId),
          po_id: truckId,
          ...buyerGrnDocObj,
          ...unloadingWeightSlipDocObj,
        };
        const response = await ReuploadUnloadingDocuments(payload);
        clearSessionStorage();
        setGuestLoginAttempts(0);
        setSubmitLoading(false);
        navigate(`/trades/mySauda/${saudaId}`);
      } else {
        const payload = {
          sauda_id: Number(saudaId),
          po_id: truckId,
          buyer_grn_doc:
            buyerGRNDocument?.docs === null
              ? []
              : [buyerGRNDocument?.docs?.[0]]?.map((item) => {
                  return {
                    ext: item?.ext,
                    file_name: item?.file_name,
                    url: item?.url,
                  };
                }),
          unloading_weight_slip:
            unloadingWeightSlipDocument?.docs === null
              ? []
              : [unloadingWeightSlipDocument?.docs?.[0]]?.map((item) => {
                  return {
                    ext: item?.ext,
                    file_name: item?.file_name,
                    url: item?.url,
                  };
                }),
        };
        const response = await UploadUnloadingDocuments(payload);
        clearSessionStorage();
        setGuestLoginAttempts(0);
        setSubmitLoading(false);
        navigate(`/trades/mySauda/${saudaId}`);
      }
    } catch (error) {
      setSubmitLoading(false);
      if (error?.code === 401 || error?.code === 426) {
        if (platformData?.platform === 'WEB') {
          if (unVerifiedRoutes?.guestLogin?.includes(error?.path)) {
            await loginAsGuest();
            setGuestLoginAttempts((lastValue) => {
              return lastValue + 1;
            });
          } else {
            auth.setMobileNumberModalVisible(true);
          }
        }
      } else {
        handleCreateDispatchToastPopup();
      }
      console.log(error);
    } finally {
      setSubmitLoading(false);
    }
  };

  function moengageEventHandlerCancelDisptchModal() {
    if (
      (JSON.parse(sessionStorage.getItem('createDispatchAttempt')) ||
        JSON.parse(sessionStorage.getItem('documentUploadAttempt'))) &&
      !JSON.parse(sessionStorage.getItem('buyerGRN')) &&
      !JSON.parse(sessionStorage.getItem('unloadingWeightSlip'))
    ) {
      return true;
    } else if (
      (JSON.parse(sessionStorage.getItem('createDispatchAttempt')) ||
        JSON.parse(sessionStorage.getItem('documentUploadAttempt'))) &&
      (JSON.parse(sessionStorage.getItem('buyerGRN')) ||
        JSON.parse(sessionStorage.getItem('unloadingWeightSlip')))
    ) {
      return false;
    } else {
      return true;
    }
  }

  const onClickCancelDispatch = () => {
    moengageEventHandlerCancelDisptchModal()
      ? trackEvent(Events?.onCancelDispatchButtonClick, {})
      : trackEvent(
          Events?.onUnloadingScreenCancelUnloadingBottomModalCancelButtonClick,
          {},
        );
    setShowCancelDispatchModal(!showCancelDispatchModal);
    // navigate(-1);
    navigate(`/trades/mySauda/${saudaId}`);
    clearSessionStorage();
  };

  const handleCreateDispatchToastPopup = (from?: string) => {
    setCreateDispatchToastPopup(true);
    // clearSessionStorage();
    setTimeout(() => {
      //   if (from === 'getDispatchDetails') {
      //     navigate('/trades');
      //   } else {
      //     navigate('/trades/mySauda');
      //   }
      setCreateDispatchToastPopup(false);
    }, 5000);
  };

  function onClickMandiToolTip(event) {
    event.stopPropagation();
    setMandiToolTipVisible(!mandiToolTipVisible);
  }

  function onPopStateHandler() {
    controlBrowserNavigation();
    onClickBackButton();
  }

  const onClickTripTrackingCard = () => {
    updateTourGuide();

    // IF THE TRIP DETAILS OBJECT IS EMPTY,
    // THEN RETURN WIHTOUT DOING ANYTHING;
    if (Object?.keys(tripDetails)?.length === 0) {
      return;
    }

    if (
      tripDetails?.trip_status_id === tripTrackingStatus?.get('CONSENT_PENDING')
    ) {
      const langCode = JSON.parse(
        localStorage?.getItem('userLanguage') || '{}',
      )?.langCode;

      navigate('/trades/takeconsent', {
        state: {
          language: trackConsentLanguage,
          activeLanguage: langCode?.toLowerCase(),
          truck_id: truckId,
          trip_id: tripDetails?.trip_id,
          saudaDetailData: saudaDetailData,
          saudaId: state?.saudaId,
          dispatchId: dispatchID,
        },
      });
    } else {
      const tripId= tripDetails?.trip_tracking_url?.split("/")?.pop();
      navigate(
        `/triptracking/${tripId}/tripdetail`
      )
    }
  };

  function onPressHelp() {
    navigate('/faq/questions');
  }

  useEffect(() => {
    window.onpopstate = onPopStateHandler;
  }, []);

  useEffect(() => {
    if (dispatchID) {
      getDispatchDetails();
    }
  }, [userLogedIn]);

  useEffect(() => {
    checkEmptyFields();
  }, [
    dispatchWeight,
    truckNumber,
    noOfBags,
    invoiceDocument,
    weightSlipDocument,
    truckDriverNumber,
  ]);

  useEffect(() => {
    if (platformData.platform === 'web') {
      !userLogedIn && auth?.setMobileNumberModalVisible(true);
    }
  }, [userLogedIn]);

  return {
    language,
    onClickBackButton,
    dispatchDetails,
    dispatchWeight,
    truckNumber,
    noOfBags,
    onChangeDispatchWeight,
    onChangeTruckNumber,
    onChangeNoOfBags,
    dispatchDetailLoading,
    onClickInput,
    onClickUploadDocument,
    onClickSubmitButton,
    error,
    setError,
    invoiceDocument,
    weightSlipDocument,
    mandiDoument,
    showCancelDispatchModal,
    toggleCancelDispatchModal,
    onClickCancelDispatch,
    createDispatchToastPopup,
    submitLoading,
    setIsError,
    isError,
    onChangeTruckDriverNumber,
    truckDriverNumber,
    saudaDetailData,
    onClickMandiToolTip,
    mandiToolTipVisible,
    truckErrorMessage,
    tripDetails,
    truckId,
    onClickTripTrackingCard,
    showTourGuide,
    uploadedByFarMart,
    buyerGRNDocument,
    unloadingWeightSlipDocument,
    dispatchId,
    onUploadUnloadingDocs,
    getDispatchDetails,
    onPressHelp,
    createDispatchError,
  };
}
