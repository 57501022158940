import { useState, useEffect, useRef } from 'react';
import { createBridge } from '@mono-farmart-web/rpc-bridge';
import isAndroid from '@mono-farmart-web/farmart-web-common/utils/operatingSystemType';
import bridgeFunctions from '@mono-farmart-web/rpc-bridge/utility';

export default function useCameraHooks (imageInputRef) {

  const bridge = createBridge();

  const [isCameraPermissionModalVisible, setIsCameraPermissionModalVisible] =
    useState(false);
  const [runUploadImageFlow, setRunUploadImageFlow] = useState(false);
  const [cameraPermissionModalChecked, setCameraPermissionModalChecked] =
    useState(false);
  
    const [callImageFunction, setCallImageFunction] = useState(false);

  function checkCameraPermission() {
    bridge.sendRequestToNative(
      'checkCameraPermission',
      null,
      (response: any) => {
        console.log(response);
      },
    );

    (window as any).listenMessagesFromApp = (request: any) => {
      const cameraPermission = request;
      if (cameraPermission?.permission === true) {
        setRunUploadImageFlow(true);
      } else if (cameraPermission?.permission === false) {
        setRunUploadImageFlow(false);
      }
    };
  }

  useEffect(() => {
    if (isAndroid()) {
      checkCameraPermission();
    }
  }, []);

  const handleOpenPermissionModal = () => {
    setIsCameraPermissionModalVisible(true);
  };

  const handleClosePermissionModal = () => {
    setIsCameraPermissionModalVisible(false);
  };

    useEffect(() => {
    if (callImageFunction===true) {	
    imageInputRef.current.click()
    }
    }, [callImageFunction]);

  function requestCameraPermission() {
    return () => {
      bridge.sendRequestToNative(
        'requestCameraPermission',
        null,
        (response: any) => {
          console.log(response);
        },
      );

      (window as any).listenMessagesFromApp = (request: any) => {
        if (request) {
          const cameraPermission = request;

          if (cameraPermission?.permission === true) {
            // Use the ref to trigger click
            setCallImageFunction(true)
            setIsCameraPermissionModalVisible(false);
            setRunUploadImageFlow(true);
            setCameraPermissionModalChecked(true);
          } else if (cameraPermission?.permission === false) {
            setCameraPermissionModalChecked(false);
            setIsCameraPermissionModalVisible(true);
            setRunUploadImageFlow(true);
          }
        }
      };
    };
  }

  function onClickOpenSetting() {
		bridge.sendRequestToNative("OpenAppSettings", null, (response: unknown) => {
			bridgeFunctions.PrintLog(response);
		});
    setIsCameraPermissionModalVisible(false);
	}

  return {
    isCameraPermissionModalVisible,
    handleOpenPermissionModal,
    handleClosePermissionModal,
    requestCameraPermission,
    runUploadImageFlow,
    cameraPermissionModalChecked,
    callImageFunction,
    onClickOpenSetting
  };
};

