import * as AppLanguages from '../constant/appLanguages';
//language will be added here
const languages = {
  [AppLanguages.appLanguagesIndex.english]: {
    searchKhataPlaceholder: 'Search for Khata',
    searchKhataLable: 'Search Khata',
    noKhataFound: 'No khata found!',
  },
  [AppLanguages.appLanguagesIndex.hindi]: {
    searchKhataPlaceholder: 'खाता खोजें',
    searchKhataLable: 'बही खाता खोजें ',
    noKhataFound: 'कोई खाता नहीं मिला!',
  },
};

export default (language) => languages[language];
