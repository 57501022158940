import React from 'react';
import Modal from '@mono-farmart-web/farmart-web-common/ComponentV2/Modal';
import {
  Box,
  Input,
  ListItem,
  ListItemButton,
  ListItemContent,
  ListItemDecorator,
} from '@mui/joy';
import { SearchRounded } from '@mui/icons-material';
import Typography from '@mono-farmart-web/farmart-web-common/ComponentV3/Typography';
import InfiniteScroll from '@mono-farmart-web/farmart-web-common/Components/InfinityScrollV2';
import smsTemplateType from '@mono-farmart-web/farmart-web-common/constant/enums/smsTemplateType';

export default function SingleSelectModal({
  showSingleSelectModal,
  smsTypeId,
  singleSelectLoading,
  handleSelectedItem,
  handleModalClose,
  searchTerm,
  setSearchTerm,
  filteredItems,
  language,
  setPage,
  loadedCount,
  totalCount,
}) {
  const getModalHeadings = () => {
    switch (Number(smsTypeId)) {
      case smsTemplateType.get('fertilizerSmsId'):
        return {
          title: language?.fertilizerName,
          searchPlaceholder: language?.searchFertilizer,
        };
      case smsTemplateType.get('pesticideSmsId'):
      case smsTemplateType.get('buyCropSmsId'):
      case smsTemplateType.get('seedSmsId'):
        return {
          title: language?.cropName,
          searchPlaceholder: language?.searchCrop,
        };
      default:
        return {
          title: language?.fertilizerName,
          searchPlaceholder: language?.searchFertilizer,
        };
    }
  };

  const modalHeadings = getModalHeadings();

  const handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const maxLength = 30;
    const inputValue = e.target.value;
    if (inputValue.length > maxLength) {
      setSearchTerm(inputValue.substring(0, maxLength));
    } else {
      setSearchTerm(inputValue);
    }
  };

  const modalContent = (
    <Box padding={4} sx={{ 
      maxWidth: '100%', 
      overflowX: 'hidden',
      height: '70vh'
    }}>
      <Box>
        <Input
          type="search"
          value={searchTerm}
          onChange={handleSearchTermChange}
          placeholder={modalHeadings?.searchPlaceholder}
          sx={{
            input: {
              '&:focus': {
                border: 'none',
              },
              //placeholder text style
              '&::placeholder': {
                opacity: 0.64,
                color: '#9FA6AD',
              },
            },
            width: '100%',
            marginBottom: 2,
            borderRadius: 8,
          }}
          startDecorator={<SearchRounded />}
          id={'smsTemplateSearchInputId'}
        />
      </Box>
      <Box 
        id="singleSelectModalScroll"
        sx={{
          width: '100%',
          height: 'calc(100% - 60px)',
          overflowY: 'auto',
        }}
      >
        <InfiniteScroll
          dataLength={filteredItems.length}
          next={() => setPage((page) => page + 1)}
          hasMore={loadedCount < totalCount}
          loader={<Box sx={{ textAlign: 'center', padding: '10px' }}></Box>}
          scrollableTarget="singleSelectModalScroll"
          style={{
            width: '100%',
            paddingTop: 3,
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
          }}
        >
          {filteredItems.map((item) => (
            <ListItem
              key={item?.id}
              sx={{
                display: 'flex',
                width: '100%',
                paddingBottom: '12px',
              }}
            >
              <ListItemButton
                onClick={() => handleSelectedItem(item)}
                id={`${item.id}ListIemButtonSMSTemplateId`}
              >
                <ListItemDecorator sx={{}}>
                  <img
                    src={item?.image_url}
                    alt={item?.label}
                    style={{ height: 52, width: 52, borderRadius: 8 }}
                    loading="lazy"
                  />
                </ListItemDecorator>
                <ListItemContent>
                  <Typography
                    level={'body-sm'}
                    fontWeight={400}
                    color="neutral"
                    sx={{
                      paddingLeft: 3,
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      maxWidth: 284,
                    }}
                  >
                    {item?.label}
                  </Typography>
                </ListItemContent>
              </ListItemButton>
            </ListItem>
          ))}
        </InfiniteScroll>
      </Box>
    </Box>
  );

  return (
    <Modal
      isOpen={showSingleSelectModal}
      onClose={handleModalClose}
      children={modalContent}
      title={modalHeadings?.title}
      id={'singleSelectModalSMSTemplateId'}
      isCloseButtonOption={true}
    />
  );
}
