import React from 'react';
import { useRef, useEffect, useState } from 'react';

interface InfiniteScrollProps {
  children: React.ReactNode;
  onScrollToEnd: () => void;
  horizontal?: boolean;
  vertical?: boolean;
  className?: string;
  loading?: boolean;
  loadingElement?: React.ReactNode;
  style?: React.CSSProperties;
  inverse?: boolean;
  bottomPadding?: number; // New prop for additional bottom padding
}

function InfiniteScroll({
  children,
  onScrollToEnd,
  horizontal,
  vertical,
  className,
  loading = false,
  loadingElement = <div>Loading...</div>,
  style,
  inverse = false,
  bottomPadding = 0,
}: InfiniteScrollProps) {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const sentinelRef = useRef<HTMLDivElement | null>(null);
  const [previousHeight, setPreviousHeight] = useState<number>(0);
  const initialisedRef = React.useRef({ value: false });

  const handleIntersection = (entries: IntersectionObserverEntry[]) => {
    if (entries[0].isIntersecting && entries[0].intersectionRatio > 0) {
      onScrollToEnd();
    }
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '10px',
      threshold: 0.1,
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    const sentinel = sentinelRef.current;
    if (sentinel && !loading) {
      observer.observe(sentinel);
    }

    return () => {
      if (sentinel) {
        observer.unobserve(sentinel);
      }
    };
  }, [loading]);

  // Maintain scroll position when new content is loaded at the top
  useEffect(() => {
    if (inverse && containerRef.current) {
      const container = containerRef.current;
      const currentHeight = container.scrollHeight;

      if (previousHeight > 0) {
        const scrollDiff = currentHeight - previousHeight;
        container.scrollTop += scrollDiff;
      }

      setPreviousHeight(currentHeight);
    }
  }, [children, inverse]);

  useEffect(() => {
    if (!initialisedRef.current.value && containerRef.current) {
      const timer = setTimeout(() => {
        if (containerRef.current) {
          if (inverse) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
          } else {
            containerRef.current.scrollTop = 1;
            containerRef.current.scrollTop = 0;
          }
          initialisedRef.current.value = true;
        }
      }, 100);

      return () => clearTimeout(timer);
    }
  }, [initialisedRef.current.value, inverse, children]);

  const containerStyle: React.CSSProperties = {
    display: horizontal ? 'flex' : 'block',
    flexDirection: horizontal
      ? inverse
        ? 'row-reverse'
        : 'row'
      : inverse
      ? 'column-reverse'
      : 'column',
    overflowX: horizontal ? 'auto' : 'hidden',
    overflowY: vertical ? 'auto' : 'hidden',
    height: '100%',

    ...(inverse
      ? { paddingTop: bottomPadding }
      : { paddingBottom: bottomPadding }),
    ...style,
  };

  return (
    <div ref={containerRef} className={className} style={containerStyle}>
      {inverse && loading && loadingElement}
      {inverse && <div ref={sentinelRef}></div>}
      {children}
      {!inverse && <div ref={sentinelRef}></div>}
      {!inverse && loading && loadingElement}
    </div>
  );
}

export default InfiniteScroll;
