import React, { useEffect, useState } from "react";
import { Box } from "@mui/joy";
import Header from "@mono-farmart-web/farmart-web-common/MuiComponents/pages/common/Header";
import { ArrowBackRounded, PersonAddAlt1Rounded } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Column } from "@mono-farmart-web/farmart-web-common/MuiComponents/atoms/layout";
import { Text } from "@mono-farmart-web/farmart-web-common/MuiComponents/atoms/typography";
import ButtonC from "@mono-farmart-web/farmart-web-common/MuiComponents/atoms/button/Button";
import AddFilledIcon from "@mono-farmart-web/farmart-web-common/assets/svgComponet/AddFilledIcon";
import { getBahiKhataLandingAssets } from "@mono-farmart-web/farmart-web-common/constant/imageUrls";
import Styles from "./style.module.css";
import { createBridge } from "@mono-farmart-web/rpc-bridge";
import bridgeFunctions from "@mono-farmart-web/rpc-bridge/utility";
import SkeletalLoader from "../../SkeletalLoader";

const NativeView = ({ handleSettingsPermission, language, handleAddUserClick }) => {
  const navigate = useNavigate();
  const langId = JSON.parse(localStorage.getItem("userLanguage"));
  const bahiKhataLandingAssets = getBahiKhataLandingAssets(langId?.langCode);
  const bridge = createBridge();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    bridge.sendRequestToNative(
      "handleContactPermission",
      {
        screen: "Record Transaction"
      },
      (response: any) => {
        bridgeFunctions.PrintLog(response);
      },
    );

    (window as any).listenMessagesFromApp = (request: any) => {
      const contactPermissionGiven = JSON.parse(request);
      // PLEASE PASS COMPARISON FOR BOOLEAN VALUE;
      // FOR SOME REASON, IT IS NOT WORKING WITHOUT SUCH COMPARISON;
      if(contactPermissionGiven === true) {
        setIsLoading(false);
        navigate("/bahiKhata/contacts", {
          state: {
            contactPermissionGiven: contactPermissionGiven
          }
        })
      } else {
        setIsLoading(false);
      }
    }
  }, []);

  if(isLoading) {
    return <SkeletalLoader />
  }

  return (
    <Box
    display={"flex"}
    flexDirection={"column"}
    height={"100dvh"}
    className={Styles.container}>
      {/* HEADER */}
      <Header
        leftIcon={
          <ArrowBackRounded
            id="bahikhata_backButton"
            data-testid="bahikhata_backButton"
            content-desc="bahikhata_backButton"
            sx={{
              width: "24px",
              height: "24px"
            }}
            onClick={() => navigate(-1)} />
          }
        title={language?.title}
        rightIcon={
          <PersonAddAlt1Rounded
            id="bahikhata_addmanually2"
            data-testid="bahikhata_addmanually2"
            content-desc="bahikhata_addmanually2"
            sx={{
              width: "24px",
              height: "24px"
            }}
            onClick={() => handleAddUserClick({
              Screen_name: "Record Transaction - Top Header Button",
            })} />
        }/>

        {/* BODY */}
        <Column
          py={9}
          px={4}
          boxSizing={"border-box"}>
          <Column
            gap={5}>
            {/* NOTICE */}
            <Text
              textAlign={"center"}
              level={"body-xs"}
              fontWeight={600}
              textColor={"text.primary"}>{language?.settingPermissionInfo}</Text>

            {/* WALKTHROUGH */}
            <Column
              justifyContent={"center"}
              alignItems={"center"}
              gap={2}>
              <img src={bahiKhataLandingAssets?.bahiKhataContactImg} alt="Contact Permission" style={{
                width: "236px",
                height: "236px",
                objectFit: "contain"
              }} />
              <Text
                level={"body-xxs"}
                fontWeight={500}
                textColor={"text.secondary"}
                textAlign={"center"}
                width={"236px"}>
                {language?.settingPermissionSteps}
              </Text>
            </Column>

            {/* BUTTON SET */}
            <Column
              gap={3}
              boxSizing={"border-box"}>
              <ButtonC
                color={"primary"}
                sx={{
                  borderRadius: "8px",
                  paddingTop: "6px",
                  paddingRight: "24px",
                  paddingBottom: "6px",
                  paddingLeft: "24px"
                }}
                size={"lg"}
                id="bahikhata_systemSetting"
                data-testid="bahikhata_systemSetting"
                content-desc="bahikhata_systemSetting"
                loading={false}
                onClick={() => {
                  handleSettingsPermission();
                  navigate("/bahikhata");
                }}>
                  {language?.settingPermissionButton}
              </ButtonC>

              <ButtonC
                startDecorator={<AddFilledIcon height="28" width="28" fillColor="#019881" />}
                color={"primary"}
                sx={{
                  background: "white",
                  color: "#019881",
                  border: "1px solid",
                  borderColor: "#4EB8A6",
                  borderRadius: "8px",
                  paddingTop: "6px",
                  paddingRight: "24px",
                  paddingBottom: "6px",
                  paddingLeft: "24px"
                }}
                id="bahikhata_addManually"
                data-testid="bahikhata_addManually"
                content-desc="bahikhata_addManually"
                size={"lg"}
                loading={false}
                onClick={() => handleAddUserClick({
                  Screen_name: "Record Transaction - Native Bottom Button",
                })}>
                {language?.addManuallyButton}
              </ButtonC>
            </Column>
          </Column>
        </Column>

    </Box>
  )
}

export default NativeView;
