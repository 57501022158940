import React from 'react';
import Styles from './style.module.css';
import { WalkthroughProps } from '../../hooks/AppWalkthroghHooks/types';
import LanguageSelection from '@mono-farmart-web/farmart-web-common/ComponentsV4/LanguageSelection';
import { Box } from '@mui/material';
import WalkthroughBanners from './WalkthroughBanners';
import SkeletonLoading from '@mono-farmart-web/farmart-web-common/ComponentsV4/SkeletonLoading';

export default function Login(props: WalkthroughProps) {
  const {
    handleLanguageSelect,
    onLanguageModalToggle,
    handleLanguageChange,
    selectedLanguage,
    isLanguageModalOpen,
    isLanguageSelectedInProgress,
    language,
    languageData,
    onClickGotIt,
    walkthroughAssets,
    slidedData,
    initialScreenLoading
  } = props;
  return (
    <Box className={Styles.container} bgcolor={'warning.50'}>
      {initialScreenLoading ? (
        <SkeletonLoading className={Styles.loading} itemNumber={6} />
      ) : (
        <>
          <WalkthroughBanners
            onLanguageModalToggle={onLanguageModalToggle}
            onClickGotIt={onClickGotIt}
            walkthroughAssets={walkthroughAssets}
            language={language}
            slidedData={slidedData}
          />
          <LanguageSelection
            handleLanguageChange={handleLanguageChange}
            selectedLanguage={selectedLanguage}
            isLanguageModalOpen={isLanguageModalOpen}
            isLanguageSelectedInProgress={isLanguageSelectedInProgress}
            handleLanguageSelect={handleLanguageSelect}
            onLanguageModalToggle={onLanguageModalToggle}
            language={language}
            languageData={languageData}
          />
        </>
      )}
    </Box>
  );
}
