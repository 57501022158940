import BahiKhataDetailView from '../../Pages/BahiKhataDetail';
import useBahiKhataDetail from '../../hooks/BahiKhataDetail/useBahiKhataDetail';

export default function BahiKhataDetailMain() {
  const {
    transactionHistory,
    calluser,
    onClickBack,
    onClickReminderBell,
    reminderModalOpenClose,
    onClickEditProfile,
    editProfileModalOpenClose,
    onClickDeleteKhata,
    deleteKhataModalOpenClose,
    bahiKhataDetailLoading,
    remindeUserBalance,
    deleteKhata,
    onchangeFarmerName,
    editFarmerName,
    UpdateFarmerName,
    onClickTransactionLongPress,
    trasactionDeleteData,
    unSelectLongPress,
    deleteTransaction,
    onClickDeleteTransactionModal,
    deleteTransactionModalOpenClose,
    loadMoreTransaction,
    moreTrasactionLoading,
    onClickPaymentDetailModal,
    paymentDetailModalOpen,
    slectedTransactionDetail,
    alert,
    language,
    bahiKhataDetailAsset,
    onHoverTransaction,
    onClickMenuButton,
    handleCredit,
    onClickEditAmountModal,
    editAmountModalOpenClose,
    onChangeAmount,
    editAmount,
    saveEditedAmount,
    noteModalOpenClose,
    onClicknoteModal,
    note,
    onChangeNote,
    noteCreateUpdate,
    deleteNoteOpenClose,
    onClickDeleteNoteModal,
    deleteNoteModal,
    handleOpenService,
    uploadedImage,
    preViewDocument,
    selectedDocument,
    onPressClosePreview,
    deleteImageModalVisible,
    onClickDeleteImage,
    toggleDeleteImageModal,
    onClickEditProfileInputs,
    onClickAmountModalInput,
    onClickInputEditNote,
    error,
    onRetryScreen,
    onPressHelp,
    disableDeleteTransactionButton,
    disableDeleteKhataButton,
    toggleReplaceImageModal,
    toggleReplaceImageModalWithFlag,
    openReplaceImageModal,
    handleCameraPermission
  } = useBahiKhataDetail();

  return (
    <BahiKhataDetailView
      handleCameraPermission={handleCameraPermission}
      transactionHistory={transactionHistory}
      calluser={calluser}
      onClickBack={onClickBack}
      onClickReminderBell={onClickReminderBell}
      reminderModalOpenClose={reminderModalOpenClose}
      onClickEditProfile={onClickEditProfile}
      editProfileModalOpenClose={editProfileModalOpenClose}
      onClickDeleteKhata={onClickDeleteKhata}
      deleteKhataModalOpenClose={deleteKhataModalOpenClose}
      bahiKhataDetailLoading={bahiKhataDetailLoading}
      remindeUserBalance={remindeUserBalance}
      deleteKhata={deleteKhata}
      onchangeFarmerName={onchangeFarmerName}
      editFarmerName={editFarmerName}
      UpdateFarmerName={UpdateFarmerName}
      onClickTransactionLongPress={onClickTransactionLongPress}
      trasactionDeleteData={trasactionDeleteData}
      unSelectLongPress={unSelectLongPress}
      deleteTransaction={deleteTransaction}
      disableDeleteTransactionButton={disableDeleteTransactionButton}
      disableDeleteKhataButton={disableDeleteKhataButton}
      onClickDeleteTransactionModal={onClickDeleteTransactionModal}
      deleteTransactionModalOpenClose={deleteTransactionModalOpenClose}
      loadMoreTransaction={loadMoreTransaction}
      moreTrasactionLoading={moreTrasactionLoading}
      onClickPaymentDetailModal={onClickPaymentDetailModal}
      paymentDetailModalOpen={paymentDetailModalOpen}
      slectedTransactionDetail={slectedTransactionDetail}
      alert={alert}
      language={language}
      bahiKhataDetailAsset={bahiKhataDetailAsset}
      onHoverTransaction={onHoverTransaction}
      onClickMenuButton={onClickMenuButton}
      handleCredit={handleCredit}
      onClickEditAmountModal={onClickEditAmountModal}
      editAmountModalOpenClose={editAmountModalOpenClose}
      onChangeAmount={onChangeAmount}
      editAmount={editAmount}
      saveEditedAmount={saveEditedAmount}
      onClicknoteModal={onClicknoteModal}
      noteModalOpenClose={noteModalOpenClose}
      onChangeNote={onChangeNote}
      note={note}
      noteCreateUpdate={noteCreateUpdate}
      deleteNoteOpenClose={deleteNoteOpenClose}
      onClickDeleteNoteModal={onClickDeleteNoteModal}
      deleteNoteModal={deleteNoteModal}
      uploadedImage={uploadedImage}
      handleOpenService={handleOpenService}
      selectedDocument={selectedDocument}
      preViewDocument={preViewDocument}
      onPressClosePreview={onPressClosePreview}
      deleteImageModalVisible={deleteImageModalVisible}
      onClickDeleteImage={onClickDeleteImage}
      toggleDeleteImageModal={toggleDeleteImageModal}
      onClickEditProfileInputs={onClickEditProfileInputs}
      onClickAmountModalInput={onClickAmountModalInput}
      onClickInputEditNote={onClickInputEditNote}
      error={error}
      onRetryScreen={onRetryScreen}
      onPressHelp={onPressHelp}
      toggleReplaceImageModal={toggleReplaceImageModal}
      toggleReplaceImageModalWithFlag={toggleReplaceImageModalWithFlag}
      openReplaceImageModal={openReplaceImageModal}
    />
  );
}
