import { useState, useEffect, useRef, useCallback } from "react";
import { createBridge } from "@mono-farmart-web/rpc-bridge";
import bridgeFunctions from "@mono-farmart-web/rpc-bridge/utility";
import { trackEvent } from "@mono-farmart-web/farmart-web-common/utils/moengage";
import Events from "@mono-farmart-web/farmart-web-common/constant/MoengageEvents/index";
import PlatForms from "@mono-farmart-web/farmart-web-common/constant/platFormEnums.js";
import fetchKhataList from "@mono-farmart-web/farmart-web-common/modal/bahiKhata/models/fetchKhataList.js";
import getLang from "@mono-farmart-web/farmart-web-common/languages/contactListing.js";
import useAppLanguage from "@mono-farmart-web/farmart-web-common/utils/applanguage";
import { useNavigate } from "react-router-dom";
import { languageUrls } from "@mono-farmart-web/farmart-web-common/constant/languageUrls";
import { apiEndPoints } from "@mono-farmart-web/farmart-web-common/apis/endpoints";
import useAuthenticationChecker from "@mono-farmart-web/farmart-web-common/utils/authCheckerV2";
import FeatureLocationId from "@mono-farmart-web/farmart-web-common/constant/enums/FeatureLocationId.js";
import { usePlatfromData } from "@mono-farmart-web/farmart-web-common/context/CrossPlatformActionHandler/platformChecker.js";

const SEARCH_DELAY = 500;

function uniqueItems(items) {
	const uniqueMap = new Map();

	for (const item of items) {
		const key = Number.parseInt(item?.mobile_number);

		if (!uniqueMap.has(key)) {
			uniqueMap.set(key, item);
		}
	}
	return Array.from(uniqueMap.values());
}

const useContactList = ({ initialPage = 1, initialSearch = "" }) => {
	const bridge = createBridge();
	const navigate = useNavigate();
	const { AuthenticationCheckerApiCaller } = useAuthenticationChecker();
	const platformData = usePlatfromData();

	const [apiContacts, setApiContacts] = useState([]);
	const [allNativeContacts, setAllNativeContacts] = useState([]);
	const [uniqueNativeContacts, setUniqueNativeContacts] = useState([]);
	const [contactPermission, setContactPermission] = useState(false);
	const [loading, setLoading] = useState(false);
	const [isLoadingMore, setIsLoadingMore] = useState(false);
	const [hasMore, setHasMore] = useState(true);
	const [searchQuery, setSearchQuery] = useState(initialSearch);
	const [page, setPage] = useState(initialPage);
	const [totalCount, setTotalCount] = useState(0);

	const mounted = useRef(true);
	const timeoutRef = useRef(null);
	const isLoadingRef = useRef(false);
	const currentSearchRef = useRef(searchQuery);

	const { language } = useAppLanguage(
		languageUrls?.bahiKhataContactListingLangUrl,
		getLang,
	);

	const normalizePhoneNumber = useCallback((number) => {
		return (number || "").toString().trim().replace(/\D/g, "");
	}, []);

	const normalizeText = useCallback((text) => {
		return (text || "").toLowerCase().trim();
	}, []);

	const filterDuplicateRecords = useCallback(
		(apiContacts, nativeContacts) => {
			const mobileNumberSet = new Set(
				apiContacts.map((item) => normalizePhoneNumber(item?.mobile_number)),
			);

			return nativeContacts.filter(
				(item) =>
					!mobileNumberSet.has(normalizePhoneNumber(item?.mobile_number)),
			);
		},
		[normalizePhoneNumber],
	);

	const searchContacts = useCallback(
		(contacts, query) => {
			if (!query) return contacts;

			const searchTerm = normalizeText(query);
			const seenNumbers = new Set();
			const seenNames = new Set();
			const results = [];

			for (const contact of contacts) {
				const name = normalizeText(contact?.farmer_name);
				const mobile = normalizePhoneNumber(contact?.mobile_number);
				const matchesName = name.includes(searchTerm);
				const matchesNumber = mobile.includes(searchTerm);

				if (
					(matchesName && !seenNames.has(name)) ||
					(matchesNumber && !seenNumbers.has(mobile))
				) {
					if (matchesName) seenNames.add(name);
					if (matchesNumber) seenNumbers.add(mobile);
					results.push(contact);
				}
			}

			return results;
		},
		[normalizeText, normalizePhoneNumber],
	);

	const getCombinedContacts = useCallback(() => {
		if (hasMore && !searchQuery) {
			return apiContacts;
		}

		const filteredNativeContacts = filterDuplicateRecords(
			apiContacts,
			allNativeContacts,
		);

		if (searchQuery) {
			const apiResults = searchContacts(apiContacts, searchQuery);
			const nativeResults = searchContacts(filteredNativeContacts, searchQuery);

			const apiMobileNumbers = new Set(
				apiResults.map((item) => normalizePhoneNumber(item?.mobile_number)),
			);

			const uniqueNativeResults = nativeResults.filter(
				(item) =>
					!apiMobileNumbers.has(normalizePhoneNumber(item?.mobile_number)),
			);

			return [...apiResults, ...uniqueNativeResults];
		}

		return [...apiContacts, ...filteredNativeContacts];
	}, [
		apiContacts,
		allNativeContacts,
		hasMore,
		searchQuery,
		filterDuplicateRecords,
		searchContacts,
		normalizePhoneNumber,
	]);

	useEffect(() => {
		const filteredContacts = filterDuplicateRecords(
			apiContacts,
			allNativeContacts,
		);
		setUniqueNativeContacts(filteredContacts);
	}, [apiContacts, allNativeContacts, filterDuplicateRecords]);

	const fetchNativeContacts = useCallback(() => {
		bridge.sendRequestToNative("GetContacts", null, (response) => {
			bridgeFunctions.PrintLog(response);
		});

		(window as any).listenMessagesFromApp = (request) => {
			if (!mounted.current) return;

			try {
				const contacts = JSON.parse(decodeURIComponent(request));

				const uniqueContacts = uniqueItems(contacts);

				if (uniqueContacts?.length) {
					setContactPermission(true);
					sessionStorage?.setItem("contactPermission", "true");

					const formattedContacts = uniqueContacts
						.map((item) => ({
							...item,
							farmer_name: item?.name,
							mobile_number: Number.parseInt(item?.mobile_number),
							nativeContact: true,
						}))
						.sort((a, b) => {
							const nameA = normalizeText(a.farmer_name);
							const nameB = normalizeText(b.farmer_name);
							return nameA.localeCompare(nameB);
						});

					setAllNativeContacts(formattedContacts);
				}
			} catch (error) {
				console.error("Error processing native contacts:", error);
			}
		};
	}, [bridge, normalizeText]);

	const loadInitialData = async () => {
		if (!mounted.current) return;

		try {
			setLoading(true);
			const response = await fetchKhataList(1, searchQuery);

			if (!mounted.current) return;

			if (!response?.status || !response?.data?.rows) {
				setHasMore(false);
				setApiContacts([]);
				return;
			}

			const newItems = response.data.rows;
			const totalItems = response.data.count;

			setTotalCount(totalItems);
			setApiContacts(newItems);
			setHasMore(totalItems > newItems.length);
		} catch (error) {
			console.error("Error loading initial data:", error);
			setHasMore(false);
		} finally {
			if (mounted.current) {
				setLoading(false);
			}
		}
	};

	const loadMoreItems = async () => {
		if (isLoadingRef.current || !hasMore || loading) return;

		try {
			isLoadingRef.current = true;
			setIsLoadingMore(true);

			const nextPage = Math.floor(apiContacts.length / 50) + 1;
			const response = await fetchKhataList(nextPage, searchQuery);

			if (!mounted.current) return;

			if (!response?.status || !response?.data?.rows) {
				setHasMore(false);
				return;
			}

			const newItems = response.data.rows;
			const totalItems = response.data.count;

			const updatedContacts = [...apiContacts, ...newItems];
			setApiContacts(updatedContacts);
			setTotalCount(totalItems);
			setHasMore(updatedContacts.length < totalItems);
		} catch (error) {
			console.error("Error loading more items:", error);
			setHasMore(false);
		} finally {
			isLoadingRef.current = false;
			if (mounted.current) {
				setIsLoadingMore(false);
			}
		}
	};

	const performSearch = async (query) => {
		if (!mounted.current || query !== currentSearchRef.current) return;

		try {
			setLoading(true);
			setPage(1);
			setHasMore(true);
			setApiContacts([]);

			const response = await fetchKhataList(1, query);

			if (!mounted.current || query !== currentSearchRef.current) return;

			if (!response?.status || !response?.data?.rows) {
				setHasMore(false);
				setApiContacts([]);
				setTotalCount(0);
				return;
			}

			const newItems = response.data.rows;
			const totalItems = response.data.count;

			setTotalCount(totalItems);
			setApiContacts(newItems);
			setPage(2);
			setHasMore(newItems.length < totalItems);
		} catch (error) {
			console.error("Error in search:", error);
			if (mounted.current) {
				setApiContacts([]);
				setTotalCount(0);
				setHasMore(false);
			}
		} finally {
			if (mounted.current) {
				setLoading(false);
			}
		}
	};

	const handleSearchChange = useCallback((query) => {
		setSearchQuery(query);
		currentSearchRef.current = query;

		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
		}

		timeoutRef.current = setTimeout(() => {
			performSearch(query);
		}, SEARCH_DELAY);
	}, []);

	const handleCreateNewTransaction = async (event, userDetails) => {
		trackEvent(Events?.onBahiKhataSaveNewKhataButtonClick, {
			Screen_name: "Record New Transaction",
		});

    const payload = {
      feature_id: FeatureLocationId?.bahi_khata,
      farmer_to_add: [
        {
          name: userDetails?.farmer_name,
          mobile_number: userDetails?.mobile_number
        }
      ]
    };

		try {
			const response = await AuthenticationCheckerApiCaller({
				uri: apiEndPoints?.createNewFarmer,
				requestType: "post",
				payload: payload,
			});

			if (response?.data?.data?.farmers_added?.length) {
				const farmerData = response?.data?.data?.farmers_added?.[0];
				navigate(`/bahiKhata/${farmerData?.id}`);
			}
		} catch (error) {
			console.error("Error creating transaction:", error);
		}
	};

	const handleAddUserClick = useCallback(() => {
		trackEvent(Events?.onAddUserButtonClick, {
			Screen_name: "Contact Listing",
		});
		navigate("/bahiKhata/recordTransaction/addNewKhata");
	}, [navigate]);

	const downloadAppEvent = useCallback(() => {
		trackEvent(Events?.onBahiKhataDownloadAppButtonClick, {});
	}, []);

	useEffect(() => {
		return () => {
			mounted.current = false;
			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current);
			}
		};
	}, []);

	useEffect(() => {
		loadInitialData();

		if (platformData?.platform === PlatForms?.get("application")) {
			fetchNativeContacts();
		}
	}, []);

	return {
		contacts: getCombinedContacts(),
		loading,
		isLoadingMore,
		hasMore,
		searchQuery,
		handleSearchChange,
		loadMoreItems,
		totalCount,
		contactPermission,
		language,
		handleAddUserClick,
		handleCreateNewTransaction,
		downloadAppEvent,
		nativeContacts: uniqueNativeContacts,
	};
};

export default useContactList;
