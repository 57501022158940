import View from '../../Pages/Community/Community';
import useFetchCommunity from '../../hooks/community/useFetchCommunity';
import usePostActions from '../../hooks/community/usePostActions';
import usePages from '../../hooks/community/usePages';
import usePWA from '../../hooks/community/pwa';
import useVisitedPostHistory from '../../hooks/community/useVisitedPostHistory';
import ScreenType from '@mono-farmart-web/farmart-web-common/constant/screenEnums';
export default function Community() {
  const {
    isFetchingAllPages,
    pages,
    onClickPage,
    selectedPage,
    fetchMorePages,
  } = usePages();
  const { isFetchingAllPosts, posts, loadMorePost } =
    useFetchCommunity(selectedPage);
  useVisitedPostHistory({ screen: ScreenType.get('homeScreen') });
  const { onClap, onComment, onShare, onViewPost, language, communityAssets } =
    usePostActions();
  const {
    deferredPrompt,
    handleInstallClick,
    isInstalled,
    isFirstLaunch,
    onClose,
  } = usePWA();
  return (
    <View
      posts={posts.rows}
      totalPosts={posts.count}
      isFetchingAllPosts={isFetchingAllPosts}
      onClap={onClap}
      onComment={onComment}
      onShare={onShare}
      onViewPost={onViewPost}
      pages={pages.pages}
      isFetchingAllPages={isFetchingAllPages}
      onClickPage={onClickPage}
      selectedPage={selectedPage}
      loadMorePost={loadMorePost}
      fetchMorePages={fetchMorePages}
      handleInstallClick={handleInstallClick}
      deferredPrompt={deferredPrompt}
      isInstalled={isInstalled}
      isFirstLaunch={isFirstLaunch}
      onClose={onClose}
      language={language}
      communityAssets={communityAssets}
    />
  );
}
