import { useLocation, useNavigate } from "react-router-dom";
import CreditEntry from "../../Pages/CreditEntry";
import ErrorScreen from '@mono-farmart-web/farmart-web-common/ComponentsV4/CommonErrorState';
import { useLentCredit } from "../../hooks/CreditEntryHooks";

const CreditEntryView = (props) => {

  const location = useLocation();
  const navigate = useNavigate();

  if(!location?.state) {
    return <ErrorScreen onPressRetry={() => navigate("/homescreen")} hideHelpButton={true} onPressHelp={() => {}} />
  }

  const {
      username,
      mobile_number,
      farmer_detail_id
  } = location.state;

  const transactionId = location.state.transaction_id ?? 1;

  const {
    userName,
    creditDate,
    creditAmount,
    noteValue,
    handleCreditAmount,
    handleNoteValue,
    handleCreditDate,
    handleOpenService,
    uploadedImage,
    clearCreditDoc,
    handleCreditDetailSubmission,
    language,
    handleCreditImageUpload,
    handleAmountInputClickEvent,
    handleNoteInputClickEvent,
    handleKeyDownCreditAmount,
    handleReuploadState,
    isImageReUpload,
    handleActiveParentToggle,
    handleDeleteIconClickEvent,
    handleDateSelectionEvent,
    isOpenCalender,
    isActiveParent,
    onChangeInput,
    onCompleteUserInfo,
    onCloseProgressiveModal,
    progressiveModalVisible,
    merchantData,
    merchantDetail,
    isRetailer,
    handleOnClickSaveButton,
    isCreditReminderModalVisible,
    getTemplateDataArray,

    onPressClosePreview,
    toggleDeleteImageModal,
    deleteImageModalVisible,
    onClickDeleteImage,
    viewImagePreview,
    isImagePreview,
    deleteModalLang,
    isTransactionCreated,
    disableSaveButton
  } = useLentCredit({
    transactionId: transactionId,
    username,
    farmer_detail_id,
    mobile_number
});

  return (
    <CreditEntry
      isTransactionCreated={isTransactionCreated}
      viewImagePreview={viewImagePreview}
      isImagePreview={isImagePreview}
      disableSaveButton={disableSaveButton}
      getTemplateDataArray={getTemplateDataArray}
      handleDateSelectionEvent={handleDateSelectionEvent}
      isOpenCalender={isOpenCalender}
      handleOnClickSaveButton={handleOnClickSaveButton}
      handleDeleteIconClickEvent={handleDeleteIconClickEvent}
      handleActiveParentToggle={handleActiveParentToggle}
      isActiveParent={isActiveParent}
      handleCreditImageUpload={handleCreditImageUpload}
      handleNoteInputClickEvent={handleNoteInputClickEvent}
      handleAmountInputClickEvent={handleAmountInputClickEvent}
      userName={userName}
      creditDate={creditDate}
      amountValue={creditAmount}
      noteValue={noteValue}
      handleCreditAmount={handleCreditAmount}
      handleNoteValue={handleNoteValue}
      handleCreditDate={handleCreditDate}
      handleOpenService={handleOpenService}
      uploadedImage={uploadedImage}
      clearCreditDoc={clearCreditDoc}
      transactionId={transactionId}
      language={language}
      deleteModalLang={deleteModalLang}
      handleCreditDetailSubmission={handleCreditDetailSubmission}
      handleReuploadState={handleReuploadState}
      isImageReUpload={isImageReUpload}
      onChangeInput={onChangeInput}
      onCompleteUserInfo={onCompleteUserInfo}
      onCloseProgressiveModal={onCloseProgressiveModal}
      progressiveModalVisible={progressiveModalVisible}
      merchantData={merchantData}
      merchantDetail={merchantDetail}
      isRetailer={isRetailer}
      isCreditReminderModalVisible={isCreditReminderModalVisible}
      handleKeyDownCreditAmount={handleKeyDownCreditAmount}
      onPressClosePreview={onPressClosePreview}
      toggleDeleteImageModal={toggleDeleteImageModal}
      deleteImageModalVisible={deleteImageModalVisible}
      onClickDeleteImage={onClickDeleteImage}
    />
  )
}

export default CreditEntryView;
