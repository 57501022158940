import React from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import {
  Box,
  Input,
  FormControl,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Textarea,
  Typography,
} from '@mui/joy';
import { ExpandMoreRounded } from '@mui/icons-material';
import smsTypeIdEnums from '@mono-farmart-web/farmart-web-common/constant/enums/smsTypeIdEnums';
import 'react-calendar/dist/Calendar.css';
import Styles from './style.module.css';

export default function ActionContainer({
  inputList,
  inputValues,
  handleInputChange,
  handleInputClick,
  smsTypeID,
  handleDateChange,
  handleRadioButtonSelection,
  handleTextareaChange,
  radioselectedValue,
  dateStates,
  setCalendarVisibility,
  calendarVisibility,
}) {

  // Extracted tileClassName function
  const getTileClassName = ({ date, view, dateStates, inputName }) => {
    let tileClass = Styles.reactTile;

    const today = new Date();
    const currentYear = today.getFullYear();
    const currentDecadeStart = Math.floor(currentYear / 10) * 10;
    const selectedDecadeStart = Math.floor(dateStates[inputName] / 10) * 10; // Replace with your logic
    const selectedMonth = new Date(dateStates[inputName]).getMonth(); // Replace with your logic
    const currentMonth = today.getMonth();
    const todayDate = today.setHours(0, 0, 0, 0); // Reset time to midnight
    const dateDate = date.setHours(0, 0, 0, 0); // Reset time to midnight

    if (view === 'decade') {
      const decadeStart = Math.floor(date.getFullYear() / 10) * 10;

      if (decadeStart === currentDecadeStart) {
        tileClass += ` ${Styles.currentDecade}`;
      }
      if (decadeStart === selectedDecadeStart) {
        tileClass += ` ${Styles.decadeViewTile}`;
      }
      if (date.getFullYear() === currentYear) {
        tileClass += ` ${Styles.currentYear}`;
      }
    }
    if (view === 'year') {
      if (date.getMonth() === currentMonth) {
        tileClass += ` ${Styles.yearViewTile}`;
      }
    }
    if (view === 'month' && dateDate === todayDate) {
      tileClass += ` ${Styles.now}`;
    }
    if (
      dateStates[inputName] &&
      dateDate === new Date(dateStates[inputName]).setHours(0, 0, 0, 0)
    ) {
      tileClass += ` ${Styles.active}`;
    }
    if (view === 'century') {
      const decadeStart = Math.floor(date.getFullYear() / 10) * 10;
      const currentDecadeStart = Math.floor(currentYear / 10) * 10;

      // Check if the current decade is part of the century view
      if (decadeStart === currentDecadeStart) {
        tileClass += ` ${Styles.currentCentury}`;
      }
    }

    return tileClass;
  };
  return (
    <Box marginTop={15} marginX={4}>
      <FormControl>
        {inputList.map(
          (input, index) =>
            input?.type && (
              <Box key={index} marginBottom={4}>
                <FormLabel>{input?.heading}</FormLabel>
                {input.type === 'textarea' ? (
                  <>
                    <Textarea
                      value={
                        inputValues[input?.name] || input?.default_value || ''
                      }
                      placeholder={input?.placeholder}
                      onChange={(event) =>
                        handleInputChange(
                          input,
                          handleTextareaChange(input, event),
                        )
                      }
                      maxRows={3}
                      sx={{
                        width: '100%',
                        borderRadius: 8,
                        padding: 2,
                        fontSize: '16px',
                        fontFamily: 'inherit',
                        height: 100,
                      }}
                      id={`${input.name}textAreaInputId`}
                    />
                    <FormHelperText>{`${
                      inputValues[input?.name]?.length || 0
                    } / ${input?.char_length}`}</FormHelperText>
                  </>
                ) : input.type === 'radio_button' ? (
                  <RadioGroup
                    aria-label={input.heading}
                    value={radioselectedValue}
                    onChange={(event) =>
                      handleRadioButtonSelection(event, input)
                    }
                    sx={{ flexDirection: 'row' }}
                  >
                    {input.options.map((option, index) => (
                      <Stack
                        key={index}
                        spacing={1}
                        flexDirection={'row'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        paddingRight={6}
                      >
                        <Radio value={option.value} />
                        <Box sx={{ marginLeft: 1 }}>
                          <Typography
                            fontSize={14}
                            fontWeight={400}
                            paddingLeft={2}
                            sx={{ color: '#171A1C' }}
                          >
                            {option.label}
                          </Typography>
                        </Box>
                      </Stack>
                    ))}
                  </RadioGroup>
                ) : input.type === 'date' ? (
                  <Box
                    sx={{
                      position: 'relative',
                      display: 'inline-block',
                      width: '100%',
                    }}
                  >
                    {/* MUI Joy Input */}
                    <Input
                      placeholder="Pick a Date"
                      value={
                        dateStates[input.name]
                          ? dateStates[input.name].toLocaleDateString()
                          : ''
                      }
                      onClick={() => {
                        // Close all calendars before opening a new one
                        setCalendarVisibility((prevVisibility) => {
                          const newVisibility = { ...prevVisibility };
                          Object.keys(newVisibility).forEach((key) => {
                            if (key !== input.name) {
                              newVisibility[key] = false;
                            }
                          });
                          newVisibility[input.name] = true;
                          return newVisibility;
                        });
                      }} // Toggle calendar visibility on click
                      readOnly // Make the input read-only to prevent manual typing
                      fullWidth
                      sx={{
                        input: {
                          '&:focus': {
                            border: 'none',
                          },
                          // placeholder text style
                          '&::placeholder': {
                            opacity: 0.64,
                            color: '#9FA6AD',
                          },
                        },
                        width: '100%',
                        borderRadius: 8,
                      }}
                      id={`${input?.name}DateInputTextId`}
                    />

                    {/* Calendar */}
                    {calendarVisibility[input.name] && (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                        className={Styles.calendarContainer}
                      >
                        <Calendar
                          minDate={
                            input?.name === 'select_date'
                              ? new Date()
                              : input?.name === 'dateRange1'
                              ? new Date()
                              : input?.name === 'dateRange2' &&
                                dateStates.dateRange1
                              ? new Date(
                                  dateStates.dateRange1.getTime() + 86400000,
                                )
                              : new Date()
                          }
                          maxDate={
                            input?.name === 'dateRange1' &&
                            dateStates?.dateRange2
                              ? new Date(
                                  dateStates?.dateRange2.getTime() - 86400000,
                                )
                              : input?.name === 'dateRange2'
                              ? undefined
                              : undefined
                          }
                          onChange={(date) => {
                            if (
                              input?.name === 'dateRange1' &&
                              dateStates?.dateRange2 &&
                              date > dateStates?.dateRange2
                            ) {
                              // handle the case where the start date is after the end date
                            } else {
                              handleDateChange(input?.name, date);
                            }
                          }}
                          value={dateStates[input.name] || new Date()}
                          className={Styles.reactCalendar}
                          tileClassName={(props) =>
                            getTileClassName({
                              ...props,
                              dateStates,
                              inputName: input.name,
                            })
                          }
                        />
                      </Box>
                    )}
                  </Box>
                ) : (
                  <Input
                    value={
                      inputValues[input?.name] ||
                      (Number(smsTypeID) !==
                      smsTypeIdEnums.get('machinSmsMessage')
                        ? input?.default_value
                        : '') ||
                      ''
                    }
                    placeholder={input?.placeholder}
                    endDecorator={
                      input?.input_type === 'dropdown-api' ||
                      input?.input_type === 'numpad-dropdown' ||
                      (input?.type === 'numpad' &&
                        Number(smsTypeID) ===
                          smsTypeIdEnums.get('buyCropMessage')) ? (
                        <ExpandMoreRounded />
                      ) : null
                    }
                    onClick={() => handleInputClick(input)}
                    onChange={(event) =>
                      handleInputChange(input, event.target.value)
                    }
                    readOnly={
                      input.input_type === 'dropdown-api' ||
                      input.input_type === 'numpad-dropdown'
                    }
                    sx={{
                      input: {
                        '&:focus': {
                          border: 'none',
                        },
                        //placeholder text style
                        '&::placeholder': {
                          opacity: 0.64,
                          color: '#9FA6AD',
                        },
                      },
                      width: '100%',
                      borderRadius: 8,
                    }}
                    inputMode={input?.type === 'numpad' ? 'numeric' : 'text'}
                    id={`${input?.name}inputTestId`}
                    data-testid={`${input?.name}inputTestId`}
                  />
                )}
              </Box>
            ),
        )}
      </FormControl>
    </Box>
  );
}
