import {
  Box,
  Avatar,
  Dropdown,
  MenuButton,
  Menu,
  MenuItem,
  Alert,
} from '@mui/joy';
import MuiTypography from '@mono-farmart-web/farmart-web-common/ComponentV3/Typography';
import {
  ArrowBackRounded,
  CallRounded,
  MoreVertRounded,
  PersonRounded,
  DeleteRounded,
  CheckCircleRounded,
  DeleteOutlined,
} from '@mui/icons-material';
import isAndroid from '@mono-farmart-web/farmart-web-common/utils/platFormCheckerV2';
import { trackEvent } from '@mono-farmart-web/farmart-web-common/utils/moengage';
import Events from '@mono-farmart-web/farmart-web-common/constant/MoengageEvents/index';

export default function BahiKhataDetailHeader({
  calluser,
  onClickBack,
  onClickEditProfile,
  onClickDeleteKhata,
  profileName,
  trasactionDeleteData,
  onClickDeleteTransactionModal,
  alert,
  language,
  onClickMenuButton,
  farmerMobileNumber,
}) {
  const name = profileName?.replace(/\s+/g, '');
  let avatarName = '';
  if (name === String(farmerMobileNumber)) {
    avatarName = '#';
  } else if (name === '') {
    avatarName = '#';
  } else {
    avatarName = checkAvatarName();
  }
  function checkAvatarName() {
    const words = profileName?.split(' ');
    let initials = '';
    if (words.length > 1) {
      const firstName = words[0];
      const lastName = words[words.length - 1];
      initials = `${firstName.charAt(0).toUpperCase()}${lastName
        .charAt(0)
        .toUpperCase()}`;
    } else {
      initials = words[0].charAt(0).toUpperCase();
    }
    return initials;
  }
  return (
    <Box
      padding={'16px'}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'space-between'}
      boxSizing={'border-box'}
    >
      {trasactionDeleteData && isAndroid() ? (
        <Box
          display={'flex'}
          gap="8px"
          alignItems={'center'}
          justifyContent={'space-between'}
          width={'100%'}
        >
          <ArrowBackRounded
            sx={{ height: '24px', width: '24px', color: '#32383E' }}
            onClick={onClickBack}
          />
          <DeleteOutlined
            sx={{ height: '24px', width: '24px', color: '#32383E' }}
            onClick={onClickDeleteTransactionModal}
          />
        </Box>
      ) : (
        <Box
          boxSizing={'border-box'}
          width={'100%'}
          display={'flex'}
          overflow={'hidden'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Box display={'flex'} gap="8px" alignItems={'center'} boxSizing={'border-box'} flex={5} overflow={'hidden'}>
            <ArrowBackRounded
              sx={{ height: '24px', width: '24px', color: '#32383E' }}
              onClick={onClickBack}
            />
            <Avatar color="primary" size="sm" sx={{ fontFamily: 'NotoSans' }}>
              {avatarName}
            </Avatar>
            <MuiTypography
              level="title-md"
              sx={{
                fontFamily: 'NotoSans',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width:"100%",
              }}
            >
              {profileName?.trim() || farmerMobileNumber}
            </MuiTypography>
          </Box>
          <Box display={'flex'} gap="28px" alignItems={'center'} flex={1} justifyContent={'flex-end'}>
            <CallRounded
              sx={{ height: '24px', width: '24px', color: '#32383E' }}
              onClick={calluser}
              id="bahikhata_callReminder"
              data-testid="bahikhata_callReminder"
            />
            <Dropdown>
              <MenuButton
                sx={{ border: 'none', padding: '0' }}
                onClick={onClickMenuButton}
                id="bahikhata_userInfo"
                data-testid="bahikhata_userInfo"
              >
                <MoreVertRounded
                  sx={{ height: '24px', width: '24px', color: '#32383E' }}
                />
              </MenuButton>
              <Menu
                sx={{
                  padding: '0px',
                }}
                color="neutral"
                size="md"
                variant="soft"
                placement="bottom-end"
              >
                <MenuItem
                  sx={{
                    display: 'flex',
                    minHeight: '36px',
                    padding: '4px 12px',
                    alignItems: 'center',
                    gap: 'var(--2, 8px)',
                  }}
                  onClick={onClickEditProfile}
                  id="bahikhata_EditProfileButton"
                  data-tesyid="bahikhata_EditProfileButton"
                  onClickCapture={() =>
                    trackEvent(Events?.onBahiKhataEditProfileButtonClick, {
                      Option_selected: 'Edit Profile',
                    })
                  }
                >
                  <PersonRounded
                    sx={{ height: '24px', width: '24px', color: '#636B74' }}
                  />

                  <MuiTypography level="title-md" color="neutral">
                    {language?.editProifle}
                  </MuiTypography>
                </MenuItem>
                <MenuItem
                  sx={{
                    display: 'flex',
                    minHeight: '36px',
                    padding: '4px 12px',
                    alignItems: 'center',
                    gap: 'var(--2, 8px)',
                  }}
                  onClick={onClickDeleteKhata}
                  id="bahikhata_DeleteKhataButton"
                  data-testid="bahikhata_DeleteKhataButton"
                  onClickCapture={() =>
                    trackEvent(Events?.onDetailedTransactionScreenDeleteButtonClick, {
                      Option_selected: 'Delete Khata',
                    })
                  }
                >
                  <DeleteRounded
                    sx={{ height: '24px', width: '24px', color: '#C41C1C' }}
                  />
                  <MuiTypography level="title-md" color="danger">
                    {language?.deleteKhata}
                  </MuiTypography>
                </MenuItem>
              </Menu>
            </Dropdown>
          </Box>
          {alert?.openClose ? (
            <Alert
              title="Success!"
              color="success"
              size="md"
              sx={{
                zIndex: 10000,
                boxSizing: 'border-box',
                position: 'absolute',
                '@media (min-width: 1025px) and (max-width: 1280px)': {
                  width: 'calc(30vw - 32px)',
                  alignSelf: 'center',
                },
                '@media (min-width: 1281px)': {
                  width: 'calc(30vw - 32px)',
                  alignSelf: 'center',
                },
                width: 'calc(100% - 32px)',
                borderRadius: '8px',
                marginTop: '16px',
                padding: '8px',
                gap: '8px',
              }}
              variant="solid"
            >
              <CheckCircleRounded
                sx={{ height: '24px', width: '24px', color: '#FFFFFF' }}
              />
              <MuiTypography level="body-xxs" sx={{ color: '#FFFFFF' }}>
                {alert?.message}
              </MuiTypography>
            </Alert>
          ) : null}
        </Box>
      )}
    </Box>
  );
}
