import React, { useRef, useState, useEffect } from 'react';
import { Box, Input } from '@mui/joy';
import MuiTypography from '@mono-farmart-web/farmart-web-common/ComponentV3/Typography';
import CloseRounded from '@mui/icons-material/CloseRounded';
import MoengageEvents from "@mono-farmart-web/farmart-web-common/constant/MoengageEvents/index";
import { trackEvent } from "@mono-farmart-web/farmart-web-common/utils/moengage";

interface LoginInputProps {
  getMobileNumber?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  userBlocked?: boolean;
  userMobileNumber?: string;
  error?: boolean;
  language: { [key: string]: any };
  isKeyboardOpen?: boolean;
  horizontalLine?: string;
  onClickInputClear: () => void;
}
export default function Login(props: LoginInputProps) {
  const {
    getMobileNumber,
    userMobileNumber,
    language,
    isKeyboardOpen,
    horizontalLine,
    onClickInputClear
  } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  return (
    <Box
      padding={'0px 16px'}
      boxSizing={'border-box'}
      bgcolor={'background.surface'}
    >
      <Box
        display={'flex'}
        alignItems={'center'}
        boxSizing={'border-box'}
        marginBottom={7}
        gap={4}
      >
        <Box sx={{ flex: 1 }} display={'flex'}>
          <img
            src={horizontalLine}
            width="100%"
            loading="lazy"
            alt="startDecorationLine"
            height={2}
          />
        </Box>
        <Box
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
          }}
        >
          <MuiTypography level="body-xs" textColor={'text.secondary'}>
            {language?.loginText}
          </MuiTypography>
        </Box>
        <Box sx={{ flex: 1 }} display={'flex'}>
          <img
            src={horizontalLine}
            width="100%"
            loading="lazy"
            alt="endDecorationLine"
            height={2}
          />
        </Box>
      </Box>
      <Input
        ref={inputRef}
        sx={{
          borderRadius: '8px',
          input: {
            '&:focus': {
              border: 'none',
            },
            //placeholder text style
            '&::placeholder': {
              opacity: 0.64,
              color: '#9FA6AD',
            },
          },
        }}
        size="lg"
        color="neutral"
        startDecorator="+91"
        placeholder={language?.numerIntputTitle}
        onChange={getMobileNumber}
        value={userMobileNumber}
        type="tel"
        id='MobileNumberInput'
        endDecorator={userMobileNumber ?<CloseRounded sx={{height:"24px",width:"24px"}} id='MobileNumberInputCross' onClick={onClickInputClear} />:null}
        onFocus={() => {
          trackEvent(MoengageEvents?.onLoginScreenPhoneNumberInputBoxClick, { })
        }
        }
      />
    </Box>
  );
}
