const SuccessFlower = () => {
  return (
    <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>Success Flower</title>
      <g id="Frame">
      <g id="_x32_0_Success">
      <g id="Group">
      <path id="Vector" d="M113.619 69.9384L113.584 69.9558C109.471 73.1102 107.728 78.4781 109.192 83.4455L109.21 83.4804C111.545 91.3755 105.759 99.3401 97.5323 99.5494H97.4977C92.3039 99.6888 87.7376 103 86.0121 107.898V107.915C83.2582 115.688 73.882 118.738 67.1023 114.049C62.8924 111.175 57.3272 111.025 52.898 114.049H52.8806C46.1011 118.72 36.7245 115.688 33.9881 107.897C32.2472 102.988 27.6877 99.6884 22.5028 99.5491H22.4679C14.2418 99.3398 8.45531 91.3753 10.7909 83.4801L10.8082 83.4452C12.2719 78.4779 10.5291 73.11 6.41625 69.9555L6.38133 69.9382C-0.154453 64.9186 -0.154453 55.0891 6.38133 50.0698L6.41625 50.0524C10.5291 46.898 12.2719 41.5298 10.7909 36.5627V36.5278C8.43773 28.6329 14.2416 20.6679 22.4679 20.4588H22.5028C27.679 20.3194 32.2627 17.0079 33.9881 12.1106V12.0933C36.7242 4.32022 46.1011 1.2703 52.8806 5.95874H52.898C57.1678 8.90413 62.8146 8.90413 67.1023 5.95874C73.9502 1.22976 83.2744 4.36687 86.0121 12.0933V12.1106C87.7376 16.9905 92.3037 20.3196 97.4977 20.4588H97.5323C105.759 20.6679 111.545 28.6329 109.21 36.5278L109.192 36.5627C107.728 41.5298 109.471 46.898 113.584 50.0524L113.619 50.0698C120.155 55.0891 120.155 64.9188 113.619 69.9384Z" fill="#3EB655"/>
      <path id="Vector_2" d="M60.0001 92.4649C77.93 92.4649 92.465 77.9298 92.465 60C92.465 42.0701 77.93 27.535 60.0001 27.535C42.0702 27.535 27.5352 42.0701 27.5352 60C27.5352 77.9298 42.0702 92.4649 60.0001 92.4649Z" fill="#8BD399"/>
      <path id="Vector_3" opacity="0.1" d="M84.9275 39.2187C79.3121 34.5991 72.1278 31.8225 64.2959 31.8225C46.3662 31.8225 31.8232 46.3655 31.8232 64.2952C31.8232 72.127 34.5999 79.3113 39.2192 84.9267C32.0846 78.9752 27.54 70.0228 27.54 59.9995C27.54 42.0696 42.0706 27.5393 60.0003 27.5393C70.0236 27.5393 78.976 32.0838 84.9275 39.2187Z" fill="black"/>
      <path id="Vector_4" d="M52.2762 72.7093L45.0971 65.0714C43.2169 63.0708 43.3139 59.9253 45.3139 58.0451C47.3138 56.1626 50.4607 56.2636 52.3392 58.2628L55.7663 61.9074L70.3334 45.2578C72.139 43.1916 75.2796 42.9821 77.3475 44.7903C79.4138 46.5985 79.6224 49.7382 77.8151 51.8044L59.6381 72.5782C57.7017 74.7891 54.2843 74.847 52.2762 72.7093Z" fill="white"/>
      </g>
      </g>
      </g>
    </svg>

  )
}

export default SuccessFlower;
