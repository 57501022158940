/* eslint-disable @typescript-eslint/no-explicit-any */
import { loginAsGuest } from '../../modal/auth/auth.model';
import GetRequest from '../apiCaller/getRequest';
import PostRequest from '../apiCaller/postRequest';
import PutRequest from '../apiCaller/putRequest';
import DeleteRequest from '../apiCaller/deleteRequest';
import PatchRequest from '../apiCaller/patchRequest';
import HttpStatusCode from '../../apis/types/httpStatusCodesEnums.type';

export interface RetryConfig {
  enabled: boolean;
  maxRetries?: number;
  retryDelay?: number;
  retryStatusCodes?: number[];
}

export interface UnifiedResponse {
  response?: Response;
  data: {
    status: number;
    message: string;
    data: any;
  };
}

export const DEFAULT_RETRY_CONFIG: RetryConfig = {
  enabled: false,
  maxRetries: 3,
  retryDelay: 1000, // 1 second
  retryStatusCodes: [408, 429, 500, 502, 503, 504] // Common retryable status codes
};

export const UN_AUTHORIZED_CODES = [
  HttpStatusCode?.Unauthorized,
  HttpStatusCode?.TokenExpired,
  HttpStatusCode?.Forbidden,
  /* HttpStatusCode?.UpgradeRequired, */
];

// Helper function to delay execution (for retry backoff)
export const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

// Function to calculate exponential backoff delay
export const getBackoffDelay = (attempt: number, baseDelay: number) => {
  return Math.min(baseDelay * Math.pow(2, attempt), 30000); // Cap at 30 seconds
};

// Base API request function with request type switching
export const apiRequestTypeChecker = async (
  payload: any,
  requestType: string,
  customUri?: string,
  uri?: string,
  signal?: AbortSignal // Accept the AbortSignal
): Promise<UnifiedResponse> => {
  switch (requestType?.toLowerCase()) {
    case 'get':
      return GetRequest({ uri, payload, customUri, signal });
    case 'post':
      return PostRequest({ uri, payload, customUri, signal });
    case 'put':
      return PutRequest({ uri, payload, customUri, signal });
    case 'delete':
      return DeleteRequest({ uri, payload, customUri, signal });
    case 'patch':
      return PatchRequest({ uri, payload, customUri, signal });
    default:
      throw new Error(`Unsupported request type: ${requestType}`);
  }
};

// Function to handle API requests with retry mechanism
export const makeRequestWithRetry = async (
  apiCall: () => Promise<UnifiedResponse>,
  retryConfig: RetryConfig = DEFAULT_RETRY_CONFIG,
  onError?: (error: any, attempt: number) => boolean | Promise<boolean>
): Promise<UnifiedResponse> => {
  // If retries are disabled, just make the call without retry logic
  if (!retryConfig.enabled) {
    return apiCall();
  }

  const { maxRetries = 3, retryDelay = 1000, retryStatusCodes = [] } = retryConfig;
  let attempts = 0;
  
  while (attempts <= maxRetries) {
    try {
      const response = await apiCall();
      return response;
    } catch (error: any) {
      attempts++;
      
      // Check if we should continue retrying by calling the onError callback
      const shouldContinue = onError ? await onError(error, attempts) : true;
      
      if (!shouldContinue || attempts > maxRetries) {
        throw error;
      }
      
      // Check if this error should be retried
      const shouldRetry = 
        retryStatusCodes.includes(error?.code) || 
        error?.message?.includes('network') || 
        error?.code >= 500;
      
      if (shouldRetry && attempts <= maxRetries) {
        console.log(`Request failed, retrying (${attempts}/${maxRetries})...`);
        // Wait with exponential backoff before retrying
        const backoffTime = getBackoffDelay(attempts - 1, retryDelay);
        await delay(backoffTime);
        continue;
      }
      
      // If we shouldn't retry, rethrow
      throw error;
    }
  }
  
  // This code should never be reached due to the while loop condition
  throw new Error('Maximum retry attempts reached');
};

// Utility function for guest user retry functionality
export const guestRetryRequest = async (
  payload: any,
  requestType: string,
  customUri?: string,
  uri?: string,
  retryConfig: RetryConfig = DEFAULT_RETRY_CONFIG,
  onMaxRetries?: () => void,
  signal?: AbortSignal // Accept the AbortSignal
) => {
  let guestLoginCalled = false;
  let retryCount = 0;
  
  const handleError = async (error: any): Promise<boolean> => {
    if (UN_AUTHORIZED_CODES?.includes(error?.code)) {
      if (!guestLoginCalled) {
        await loginAsGuest();
        guestLoginCalled = true;
      }
      
      retryCount++;
      if (retryCount < 3) {
        return true; // Continue retrying
      } else {
        if (onMaxRetries) onMaxRetries();
        return false; // Stop retrying
      }
    }
    
    return false; // Don't retry other errors
  };
  
  const apiCall = () => apiRequestTypeChecker(payload, requestType, customUri, uri, signal);
  const response = await makeRequestWithRetry(
    apiCall,
    retryConfig,
    handleError
  );
  
  if (response?.data?.status) {
    return response;
  }
  throw new Error('Invalid response');
};