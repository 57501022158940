import { Box, Divider, IconButton } from '@mui/joy';
import MuiTypography from '@mono-farmart-web/farmart-web-common/ComponentV3/Typography';
import { ChevronRightRounded, DeleteRounded } from '@mui/icons-material';
import GetIndianTimeOnly from '@mono-farmart-web/farmart-web-common/constant/GetIndianTimeOnly';
import useLongPress from '@mono-farmart-web/farmart-web-common/utils/useLongPress';
import isAndroid from '@mono-farmart-web/farmart-web-common/utils/platFormCheckerV2';

export default function TransactionRightCard({
  item,
  onClickTransactionLongPress,
  trasactionDeleteData,
  onClickPaymentDetailModal,
  language,
  onHoverTransaction,
  onClickDeleteTransactionModal,
  deleteTransactionModalOpenClose,
  unSelectLongPress
}) {
  const defaultOptions = {
    shouldPreventDefault: true,
    delay: 500,
  };
  const longPressEvent = useLongPress(
    onClickTransactionLongPress(item?.id),
    () => onClickPaymentDetailModal(item),
    defaultOptions,
  );

  const longPressProps = isAndroid() ? longPressEvent : {};
  return (
    <Box
      display={'flex'}
      padding={'var(--4, 16px)'}
      flexDirection={'column'}
      alignItems={'flex-end'}
      gap={'var(--1, 4px)'}
      bgcolor={
        trasactionDeleteData === item?.id && isAndroid()
          ? '#B2E0D8'
          : 'transparent'
      }
      sx={{
        opacity: trasactionDeleteData === item?.id && isAndroid() ? 0.8 : 1,
        transition: 'all 0.5s ease',
      }}
      onClick={isAndroid() && !deleteTransactionModalOpenClose ? unSelectLongPress :null}
    >
      <Box
        display={'flex'}
        alignItems={'center'}
        gap={'var(--2, 8px)'}
        onMouseOver={onHoverTransaction(item?.id)}
        onMouseOut={onHoverTransaction(
          deleteTransactionModalOpenClose ? item?.id : null,
        )}
      >
        {trasactionDeleteData === item?.id && !isAndroid() ? (
          <IconButton
            color="neutral"
            size="sm"
            variant="outlined"
            sx={{
              minHeight: '32px',
              minWidth: '32px',
              borderRadius: '8px',
            }}
            onClick={onClickDeleteTransactionModal}
            id="bahikhata_deleteTransactionIcon"
            data-testid="bahikhata_deleteTransactionIcon"
          >
            <DeleteRounded sx={{ color: '#32383E' }} />
          </IconButton>
        ) : null}
        <Box
          display={'flex'}
          padding={'var(--3, 12px) var(--3, 12px) var(--2, 8px) var(--3, 12px)'}
          flexDirection={'column'}
          alignItems={'flex-start'}
          borderRadius={
            'var(--radius-lg, 12px) var(--radius-lg, 12px) var(--radius-sm, 4px) var(--radius-lg, 12px)'
          }
          border={'1px solid var(--neutral-outlined-DisabledBorder, #DDE7EE)'}
          {...longPressProps}
          bgcolor={'var(--background-level1, #F0F4F8)'}
          onClick={() => onClickPaymentDetailModal(item)}
          sx={{ userSelect: 'none' }}
        >
          <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={'flex-start'}
            gap={'var(--3, 12px)'}
            width={'136px'}
          >
            <Box
              display={'flex'}
              flexDirection={'column'}
              alignItems={'flex-start'}
              gap={'var(--05, 2px)'}
              alignSelf={'stretch'}
              id="bahikhata_MoneyGivenDiv"
              data-testid="bahikhata_MoneyGivenDiv"
            >
              <Box
                display={'flex'}
                alignItems={'center'}
                gap={'var(--3, 12px)'}
                alignSelf={'stretch'}
                justifyContent={'space-between'}
              >
                <MuiTypography level="title-md" sx={{ color: '#171A1C' }}>
                  ₹{item?.amount?.toLocaleString("en-IN")}
                </MuiTypography>
                <IconButton
                  id="bahikhata_MoneyGivenDivIcon"
                  data-testid="bahikhata_MoneyGivenDivIcon"
                >
                  <ChevronRightRounded
                    sx={{ height: '20px', width: '20px', color: '#636B74' }}
                  />
                </IconButton>
              </Box>
              <MuiTypography level="label-sm" sx={{ color: '#9FA6AD' }}>
                {language?.creditGiven}
              </MuiTypography>
            </Box>
            {item?.image_proof_url || item?.comment ? (
              <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'flex-start'}
                gap={'var(--2, 8px)'}
                alignSelf={'stretch'}
              >
                {item?.image_proof_url ? (
                  <Box
                    alignSelf={'stretch'}
                    borderRadius={'8.6px'}
                    sx={{
                      backgroundImage: `url(${item?.image_proof_url})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',
                      aspectRatio: '1/1',
                      width: '136px',
                      height: '136px',
                    }}
                  />
                ) : item?.comment?.length > 0 ? (
                  <Divider
                    sx={{
                      opacity: 0.2,
                      backgroundColor:
                        'var(--divider, rgba(99, 107, 116, 0.30))',
                      alignSelf: 'stretch',
                      height: '1px',
                    }}
                  />
                ) : null}
                <MuiTypography
                  level="label-md"
                  sx={{
                    color: '#555E68',
                    fontFamily: 'NotoSans',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: 4,
                    WebkitBoxOrient: 'vertical',
                    maxWidth: '100%',
                    lineHeight: '14px',
                  }}
                >
                  {item?.comment}
                </MuiTypography>
              </Box>
            ) : null}
          </Box>
        </Box>
      </Box>
      <MuiTypography level="label-sm">
        {GetIndianTimeOnly(item?.created_at)}
      </MuiTypography>
    </Box>
  );
}
