import { Box } from '@mui/material';
import { Avatar } from '@mui/joy';
import MuiTypography from '@mono-farmart-web/farmart-web-common/ComponentV3/Typography';
import { KhataListData } from '../../../hooks/BahiKhataLanding/types';
import timeAgo from '@mono-farmart-web/farmart-web-common/utils/timeAgo/date/time_ago.js';

interface ContactListCardProps {
  index: number;
  item: KhataListData;
  timeAgoLang?: { [key: string]: string };
  onClickKhata?: (id: number) => void;
  language: { [key: string]: string };
}

function ContactListCard(props: ContactListCardProps) {
  const { timeAgoLang, item, onClickKhata, language } = props;

  const name = item?.farmer_name.replace(/\s+/g, '');
  let avatarName = '';
  if (name === String(item?.mobile_number)) {
    avatarName = '#';
  } else if (name === '') {
    avatarName = '#';
  } else {
    avatarName = checkAvatarName();
  }

  function checkAvatarName() {
    const words = item?.farmer_name.split(' ');
    let initials = '';

    if (words.length > 1) {
      const firstName = words[0];
      const lastName = words[words.length - 1];
      initials = `${firstName.charAt(0).toUpperCase()}${lastName
        .charAt(0)
        .toUpperCase()}`;
    } else {
      initials = words[0].charAt(0).toUpperCase();
    }

    return initials;
  }

  const colors = ['#F3C896', '#4EB8A6', '#F09898', '#A1E8A1'];

  const getRandomColor = () => {
    return colors[Math.floor(Math.random() * colors.length)];
  };

  const finalStyle1 =
    item.total_credit_amount === 0
      ? 'text.primary'
      : item.total_credit_amount >= 0
      ? 'success.plainColor'
      : 'danger.plainColor';

  const finalAmount =
    item.total_credit_amount < 0
      ? item.total_credit_amount * -1
      : item.total_credit_amount;

  const finalLanguage =
    item.total_credit_amount === 0
      ? language?.allSettled
      : item.total_credit_amount > 0
      ? language?.youWillGet
      : language?.youWillGive;

  return (
    <Box
      display={'flex'}
      height={35}
      marginTop={'24px'}
      onClick={() => onClickKhata(item.id)}
      flex={1}
    >
      <Box
        display={'flex'}
        alignItems={'center'}
        gap={'12px'}
        flex={4}
        overflow={'hidden'}
      >
        <Avatar
          variant="solid"
          size="sm"
          sx={{ backgroundColor: getRandomColor() }}
        >
          {avatarName}
        </Avatar>

        <Box
          display={'flex'}
          flexDirection={'column'}
          gap={'1px'}
          width={'100%'}
          overflow={'hidden'}
        >
          <MuiTypography
            level="title-sm"
            textColor={'text.primary'}
            whiteSpace={'nowrap'}
            overflow={'hidden'}
            textOverflow={'ellipsis'}
            width={'100%'}
          >
            {item.farmer_name ? item.farmer_name : item.mobile_number}
          </MuiTypography>
          <MuiTypography level="label-sm" textColor={'text.tertiary'}>
            {timeAgoLang && timeAgo(item?.updated_at, timeAgoLang)}
          </MuiTypography>
        </Box>
      </Box>
      <Box
        display={'flex'}
        flexDirection={'column'}
        gap={'2px'}
        alignItems={'flex-end'}
      >
        <MuiTypography level="title-sm" textColor={finalStyle1}>
          ₹{finalAmount.toLocaleString('en-IN')}
        </MuiTypography>
        <MuiTypography level="label-sm" textColor={'text.tertiary'}>
          {finalLanguage}
        </MuiTypography>
      </Box>
    </Box>
  );
}

export default ContactListCard;
