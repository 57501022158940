import type { LocationType, StateDistrict } from './types';
import { useEffect, useState } from 'react';
import getTradeServicibility from '@mono-farmart-web/farmart-web-common/modal/Traders/models/TraderServiceability';
import UpdateUserLocation from '@mono-farmart-web/farmart-web-common/modal/Traders/models/UpdateUserLocation';
import GetIpLocation from '@mono-farmart-web/farmart-web-common/modal/Traders/models/GetIpLocation';
import SetIpLocationLog from '@mono-farmart-web/farmart-web-common/modal/Traders/models/SetIpLocationLog';
import StateDistrictFromLatlong from '@mono-farmart-web/farmart-web-common/modal/Traders/models/StateDistrictFromLatlong';
import GetTradeList from '@mono-farmart-web/farmart-web-common/modal/Traders/models/TradeList';
import { usePlatfromData } from '@mono-farmart-web/farmart-web-common/context/CrossPlatformActionHandler/platformChecker';
import { useNavigate, useLocation } from 'react-router-dom';
import { trackEvent } from '@mono-farmart-web/farmart-web-common/utils/moengage';
import Events from '@mono-farmart-web/farmart-web-common/constant/MoengageEvents/index';
import { useAuth } from '@mono-farmart-web/farmart-web-common/context/login';
import {
  getUserProfileFromStorage,
  loginAsGuest,
} from '@mono-farmart-web/farmart-web-common/modal/auth/auth.model';
import { unVerifiedRoutes } from '@mono-farmart-web/farmart-web-common/constant/unVerifiedRoutes';
import GetRequest from '@mono-farmart-web/farmart-web-common/utils/apiCaller/getRequest';
import { apiEndPoints } from '@mono-farmart-web/farmart-web-common/apis/endpoints';
import PostRequest from '@mono-farmart-web/farmart-web-common/utils/apiCaller/postRequest';
import { helpLineNumber } from '@mono-farmart-web/farmart-web-common/constant/HelpLineNumber';
import { getAuthTokenFromStorage } from '@mono-farmart-web/farmart-web-common/modal/auth/auth.model';
import { decodeParams } from '@mono-farmart-web/farmart-web-common/utils/handleUrlParams';
import PlatForms from '@mono-farmart-web/farmart-web-common/constant/platFormEnums';
import homeScreenDownloadAppModalType from '@mono-farmart-web/farmart-web-common/constant/enums/downloadAppModalType';
import { getTradeScreenAssets } from '@mono-farmart-web/farmart-web-common/constant/imageUrls';
import {
  isFarmartWeb,
  isSaudabookPWA,
} from '@mono-farmart-web/farmart-web-common/utils/common';
import { createBridge } from "@mono-farmart-web/rpc-bridge";
import bridgeFunctions from "@mono-farmart-web/rpc-bridge/utility";

/**
 * UseLocationFetch function
 *
 * This function is designed to manage the fetching and updating of location-specific trade data
 * within a trading application. It utilizes various state-setting functions and parameters to
 * dynamically update the UI based on location changes.
 *
 * @param setMobileNumberModalVisible - Function to set the visibility of the mobile number modal.
 * @param setLocationModal - Function to set the visibility of the location modal.
 * @param activeLocations - Array of currently active locations.
 * @param tradeListPageNumber - The current page number for the trade list pagination.
 * @param setTradeListPageNumber - Function to set the current page number for the trade list pagination.
 * @param activeCrop - The currently active crop being viewed or selected.
 * @param activeVariety - The currently active crop variety being viewed or selected.
 * @param setBuyerList - Function to set the list of buyers.
 * @param buyersList - The current list of buyers.
 * @param applyFilterRefresh - Boolean indicating if location filter is applied.
 * @param askForPermission - Boolean indicating if location permission is requested.
 * @param setTotalRecords - Function to set the total number of records.
 * @param totalRecords - The current total number of records.
 * @param setActiveTradeList - Function to set the list of active trades.
 * @param activeTradeList - The current list of active trades.
 * @param setExpiredTradeList - Function to set the list of expired trades.
 * @param expiredTradeList - The current list of expired trades.
 * @param setPromotionalMediaList - Function to set the list of promotional media.
 */
export default function UseLocationFetch(
  setMobileNumberModalVisible,
  setLocationModal,
  activeLocations,
  tradeListPageNumber,
  setTradeListPageNumber,
  activeCrop,
  activeVariety,
  setBuyerList,
  buyersList,
  applyFilterRefresh,
  askForPermission,
  setTotalRecords,
  totalRecords,
  setActiveTradeList,
  activeTradeList,
  setExpiredTradeList,
  expiredTradeList,
  setPromotionalMediaList,
  resetFilters,
  reloadRefresh,
  setReloadRefresh,
): LocationType {
  const { pathname, state } = useLocation();
  const auth = useAuth();
  const [guestLoginAttempts, setGuestLoginAttempts] = useState<number>(0);
  const platformData = usePlatfromData();
  const userLogedIn: any = isFarmartWeb
    ? localStorage.getItem('loged')
    : !!localStorage.getItem('authToken');
  const [searchedBuyerDetail, setSearchedBuyerDetail] = useState(
    state?.searchedBuyerDetail || null,
  );
	const bridge = createBridge();

  const [locationToastPopup, setLocationToastPopup] = useState(false);
  const [numberCopyToast, setNumberCopyToast] = useState(false);
  const [serviciableLoading, setServiciableLoading] = useState<boolean>(true);
  const [serviciability, setServiciability] = useState<boolean>(false);
  const [isLocationPermission, setIsLocationPermission] =
    useState<boolean>(true);
  const [buyersListLoading, setBuyersListLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const startLoadTime = new Date().getTime();
  const utm = getUtm();
  const [firstTimeUser, setFirstTimeUser] = useState<boolean>(false);
  const [masterStateDistrict, setMasterStateDistrict] = useState<StateDistrict>(
    {
      master_state_id: null,
      master_district_id: null,
      master_district_name: '',
      master_state_name: '',
    },
  );
  const [locationModalLoading, setLocationModalLoading] =
    useState<boolean>(false);
  const [saudaRequestToastPopup, setSaudaRequestToastPopup] =
    useState<boolean>(false);
  const [token,setToken] = useState(getAuthTokenFromStorage())
  const userProfile = getUserProfileFromStorage() || null;
  const merchantOccupationId = userProfile?.merchant_occupation_id;

  const langId = JSON.parse(localStorage.getItem('userLanguage'));
  const tradeScreenAssets = getTradeScreenAssets(langId?.langCode);
  const [isErrorScreenVisible, setIsErrorScreenVisible] = useState(false);
  const [screenRefresh, setScreenRefresh] = useState(false);
  const [activePriceCount, setActivePriceCount] = useState<number>(0);

  function getUtm() {
    if (platformData.platform === 'WEB') {
      const urlSearchParams = new URLSearchParams(window?.location?.search);
      const paramsString = Object.fromEntries(urlSearchParams.entries());
      return paramsString?.utm_source;
    } else {
      const params = decodeParams(window?.location?.search);
      return params?.utm;
    }
  }

  const handleLocationToastPopup = () => {
    setLocationToastPopup(true);
    setTimeout(() => {
      setLocationToastPopup(false);
    }, 5000);
  };

  const handleSaudaRequestToastPopup = () => {
    setSaudaRequestToastPopup(true);
    setTimeout(() => {
      setSaudaRequestToastPopup(false);
    }, 5000);
  };

  const handleNumberCopyToast = (
    phoneNumber: number,
    cropName: string,
    buyerName: string,
  ) => {
    const contactButton = {
      Crop_name: cropName,
      Buyer_name: buyerName,
    };

    trackEvent(Events.onContactToSellButtonClick, contactButton);
    if (window?.innerWidth > 1100) {
      setNumberCopyToast(true);
      navigator.clipboard.writeText(JSON.stringify(phoneNumber));
      setTimeout(() => {
        setNumberCopyToast(false);
      }, 5000);
    } else {
      if (platformData.platform !== 'WEB') {
        bridge.sendRequestToNative(
          "DialNumber",
          {
            number: phoneNumber,
          },
          (response) => {
            bridgeFunctions.PrintLog(response);
          },
        );
      } else {
        window.location.href = `tel:${phoneNumber}`;
      }
    }
  };

  async function getIpLocationGlobalFun() {
    try {
      const IpLocationLatLong = await GetIpLocation();

      let ipAddressResp: any = null;
      if ((isSaudabookPWA && JSON.parse(userLogedIn)) || isFarmartWeb) {
        try {
          ipAddressResp = await SetIpLocationLog({
            lat_long: `${IpLocationLatLong?.location?.lat}, ${IpLocationLatLong?.location?.lng}`,
          });
        } catch (error) {
          console.log(error);
        }
      }

      const stateDistrict = await StateDistrictFromLatlong({
        lat: JSON.stringify(IpLocationLatLong?.location?.lat),
        long: JSON.stringify(IpLocationLatLong?.location?.lng),
      });
      const locationAttributes = {
        Location: 'yes',
        State: stateDistrict?.reverse_geocode?.state.toLocaleLowerCase(),
        District: stateDistrict?.reverse_geocode?.district.toLocaleLowerCase(),
        userLoggedIn: JSON.parse(userLogedIn) === true ? 'Yes' : 'No',
        locationFrom: 'IP',
        ipAddress: ipAddressResp?.ip_address,
      };
      trackEvent(Events.beforeLocationAccess, locationAttributes);

      localStorage.setItem('ipAddress', ipAddressResp?.ip_address);

      setIsLocationPermission(true);
      setMasterStateDistrict(() => {
        return {
          master_district_id:
            stateDistrict?.reverse_geocode?.master_district_id,
          master_state_id: stateDistrict?.reverse_geocode?.master_state_id,
          master_district_name:
            stateDistrict?.reverse_geocode?.master_district_name,
          master_state_name: stateDistrict?.reverse_geocode?.master_state_name,
        };
      });
      await updateServiceability({
        stateDistrict: {
          stateName: stateDistrict?.reverse_geocode?.master_state_name,
          districtName: stateDistrict?.reverse_geocode?.master_district_name,
          districtId: stateDistrict?.reverse_geocode?.master_district_id,
          stateId: stateDistrict?.reverse_geocode?.master_state_id,
        },
      });
      if (JSON.parse(userLogedIn)) {
        await UpdateUserLocation({
          feature_id: 13, //mandatory
          state_id: stateDistrict?.reverse_geocode?.master_state_id, //mandatory ,
          district_id: stateDistrict?.reverse_geocode?.master_district_id,
        });
      }

      localStorage.setItem(
        'stateId',
        JSON.stringify(stateDistrict.reverse_geocode.master_state_id),
      );
      localStorage.setItem(
        'districtId',
        JSON.stringify(stateDistrict?.reverse_geocode?.master_district_id),
      );
      localStorage.setItem(
        'stateName',
        JSON.stringify(stateDistrict.reverse_geocode.master_state_name),
      );
      localStorage.setItem(
        'districtName',
        JSON.stringify(stateDistrict?.reverse_geocode?.master_district_name),
      );
      localStorage.setItem(
        'pinCode',
        JSON.stringify(stateDistrict?.reverse_geocode?.zipcode),
      );
      localStorage.setItem(
        'talukId',
        JSON.stringify(stateDistrict?.reverse_geocode?.master_taluk_id),
      );

      if (JSON.parse(userLogedIn)) {
        const submitLocationParams = {
          pincode: stateDistrict?.reverse_geocode?.zipcode,
          master_taluk_id: stateDistrict?.reverse_geocode?.master_taluk_id,
          master_district_id:
            stateDistrict?.reverse_geocode?.master_district_id,
          master_state_id: stateDistrict?.reverse_geocode?.master_state_id,
        };

        await PostRequest({
          payload: submitLocationParams,
          uri: apiEndPoints?.updateMerchantLocation,
        });
      }
      setGuestLoginAttempts(0);
    } catch (error) {
      if (error?.code === 401 || error?.code === 426) {
        if (platformData.platform === 'WEB') {
          if (unVerifiedRoutes?.guestLogin?.includes(error?.path)) {
            if (guestLoginAttempts < 3) {
              await loginAsGuest();
              const authToken = localStorage.getItem('authToken');
            if (authToken) {
                setToken(authToken);
            }
              setGuestLoginAttempts((lastValue) => {
                return lastValue + 1;
              });
            }
          } else {
            auth.setMobileNumberModalVisible(true);
          }
        }
      }
      console.log(error);
    }
  }

  /**
   * The function `checkWebLocation` handles user location data retrieval and storage based on login
   * status and saved preferences.
   */
  async function checkWebLocation() {
    const stateId = localStorage.getItem('stateId');
    const districtId = localStorage.getItem('districtId');
    const districtName = localStorage.getItem('districtName');
    const stateName = localStorage.getItem('stateName');
    const ipAddress = localStorage.getItem('ipAddress');

    if (stateId && stateName && districtId && districtName) {
      const locationAttributes = {
        Location: 'yes',
        State: stateName?.toLocaleLowerCase(),
        District: districtName.toLocaleLowerCase(),
        userLoggedIn: JSON.parse(userLogedIn) === true ? 'Yes' : 'No',

        locationFrom: 'IP',
        ipAddress: ipAddress,
      };
      trackEvent(Events.beforeLocationAccess, locationAttributes);
      setMasterStateDistrict(() => {
        return {
          master_district_id: JSON.parse(districtId),
          master_state_id: JSON.parse(stateId),
          master_district_name: JSON.parse(districtName),
          master_state_name: JSON.parse(stateName),
        };
      });
      await updateServiceability({
        stateDistrict: {
          districtId: districtId,
          stateId: stateId,
          districtName: districtName,
          stateName: stateName,
        },
      });
      if (JSON.parse(userLogedIn)) {
        await UpdateUserLocation({
          feature_id: 13, //mandatory
          state_id: JSON.parse(stateId), //mandatory ,
          district_id: JSON.parse(districtId),
        });
      }
    } else {
      if (JSON.parse(userLogedIn)) {
        const savedLocation = await GetRequest({
          payload: {},
          uri: apiEndPoints?.updateMerchantLocation,
        });

        if (savedLocation?.data?.data?.master_district_id) {
          const locationAttributes = {
            Location: 'yes',
            State:
              savedLocation?.data?.data?.master_state_name.toLocaleLowerCase(),
            District:
              savedLocation?.data?.data?.master_district_name.toLocaleLowerCase(),
            userLoggedIn: JSON.parse(userLogedIn) === true ? 'Yes' : 'No',

            locationFrom: 'DB',
          };
          trackEvent(Events.beforeLocationAccess, locationAttributes);
          localStorage.setItem(
            'ipAddress',
            savedLocation?.data?.data?.ip_address,
          );

          localStorage.setItem(
            'stateId',
            JSON.stringify(savedLocation?.data?.data?.master_state_id),
          );
          localStorage.setItem(
            'districtId',
            JSON.stringify(savedLocation?.data?.data?.master_district_id),
          );
          localStorage.setItem(
            'stateName',
            JSON.stringify(savedLocation?.data?.data?.master_state_name),
          );
          localStorage.setItem(
            'districtName',
            JSON.stringify(savedLocation?.data?.data?.master_district_name),
          );

          localStorage.setItem(
            'lat',
            JSON.stringify(savedLocation?.data?.data?.lat),
          );
          localStorage.setItem(
            'long',
            JSON.stringify(savedLocation?.data?.data?.long),
          );
          localStorage.setItem(
            'pinCode',
            JSON.stringify(savedLocation?.data?.data?.pincode),
          );
          localStorage.setItem(
            'talukId',
            JSON.stringify(savedLocation?.data?.data?.master_taluk_id),
          );

          setMasterStateDistrict(() => {
            return {
              master_district_id: savedLocation?.data?.data?.master_district_id,
              master_state_id: savedLocation?.data?.data?.master_state_id,
              master_district_name:
                savedLocation?.data?.data?.master_district_name,
              master_state_name: savedLocation?.data?.data?.master_state_name,
            };
          });
          await updateServiceability({
            stateDistrict: {
              districtId: savedLocation?.data?.data.master_district_id,
              stateId: savedLocation?.data?.data.master_state_id,
              districtName: savedLocation?.data?.data.master_district_name,
              stateName: savedLocation?.data?.data.master_state_name,
            },
          });
          await UpdateUserLocation({
            feature_id: 13, //mandatory
            state_id: savedLocation?.data?.data.master_state_id, //mandatory ,
            district_id: savedLocation?.data?.data.master_district_id,
          });
          setIsLocationPermission(true);
        } else {
          getIpLocationGlobalFun();
        }
      } else {
        getIpLocationGlobalFun();
      }
    }
  }

  /**
   * The function handles the error or denial of location permission and performs various actions
   * accordingly.
   */

  /**
   * The function clears the last stored location and asks for high accuracy location.
   */
  function onClickAllowLocationModal(): void {
    setMasterStateDistrict(() => {
      return {
        master_district_id: null,
        master_state_id: null,
        master_district_name: '',
        master_state_name: '',
      };
    });
  }

  /**
   * The function `updateServiceability` checks if a given state and district are trade serviceable and
   * updates the serviceability status accordingly.
   * The `stateDistrict` parameter is an object that contains information about
   * the state and district. It has the following properties:
   */
  async function updateServiceability({ stateDistrict }) {
    try {
      setIsErrorScreenVisible(false);
      if (stateDistrict?.districtId) {
        const serviceResponse = await getTradeServicibility({
          supplier_district_id: stateDistrict?.districtId,
          supplier_state_id: stateDistrict?.stateId,
        });
        if (serviceResponse?.is_trade_serviceable === true) {
          setServiciability(serviceResponse.is_trade_serviceable);
          setFirstTimeUser(true);
        } else if (serviceResponse?.is_trade_serviceable === false) {
          const onTradeNonServiceablePageAttributes = {
            LoadingTime: (new Date().getTime() - startLoadTime) / 1000,
            userLoggedIn: JSON.parse(userLogedIn) === true ? 'Yes' : 'No',
          };
          trackEvent(
            Events.ontradenonserviceablepage,
            onTradeNonServiceablePageAttributes,
          );
          setServiciableLoading(false);
          setServiciability(false);
        } else {
          setServiciableLoading(false);
          const onTradeNonServiceablePageAttributes = {
            LoadingTime: (new Date().getTime() - startLoadTime) / 1000,
            userLoggedIn: JSON.parse(userLogedIn) === true ? 'Yes' : 'No',
          };
          trackEvent(
            Events.ontradenonserviceablepage,
            onTradeNonServiceablePageAttributes,
          );
          if (platformData.platform === 'WEB') {
            localStorage.setItem('loged', 'false');
            await loginAsGuest();
            const authToken = localStorage.getItem('authToken');
            if (authToken) {
                setToken(authToken);
            }
          }
          setServiciability(false);
        }
        setServiciableLoading(false);
      }
      setGuestLoginAttempts(0);
    } catch (error) {
      if (error?.code === 401 || error?.code === 426) {
        if (platformData.platform === 'WEB') {
          if (unVerifiedRoutes?.guestLogin?.includes(error?.path)) {
            if (guestLoginAttempts < 3) {
              localStorage.setItem('loged', 'false');
              await loginAsGuest();
              const authToken = localStorage.getItem('authToken');
              if (authToken) {
                  setToken(authToken);
              }
              setIsErrorScreenVisible(true);
              setGuestLoginAttempts((lastValue) => {
                return lastValue + 1;
              });
            }
          } else {
            auth.setMobileNumberModalVisible(true);
          }
        }
      } else {
        trackEvent(Events.onStartAppLevelFailureScreen, {
          Loading_time: (new Date().getTime() - startLoadTime) / 1000,
        });
        setIsErrorScreenVisible(true);
      }

      console.log(error);
    }
  }

  const getMoreTradeListData = () => {
    if (totalRecords > 0 && totalRecords > buyersList?.length) {
      setTradeListPageNumber((lastvalue) => lastvalue + 1);
      trackEvent(Events.onScrollCropViewPage, {});
    }
  };

  async function getTradeList(reloadPageNumber?: number) {
    setBuyersListLoading(true);
    setReloadRefresh(false);
    try {
      setIsErrorScreenVisible(false);
      const tradeListResponse = await GetTradeList({
        limit: 10,
        page_no: reloadPageNumber ? reloadPageNumber : tradeListPageNumber,
        supplier_district_id: masterStateDistrict?.master_district_id,
        supplier_state_id: masterStateDistrict?.master_state_id,
        crop_id: reloadPageNumber ? null : activeCrop?.id,
        variety_id: reloadPageNumber ? [] : activeVariety,
        master_state_district_map: reloadPageNumber
          ? []
          : activeLocations?.filter((element) => element !== null),
          buyer_id:reloadPageNumber || !searchedBuyerDetail?.buyer_id ? null: searchedBuyerDetail?.buyer_id,
      });

      if (firstTimeUser) {
        const onTradeServiceablePageAttributes = {
          LoadingTime: (new Date().getTime() - startLoadTime) / 1000,
          userLoggedIn: JSON.parse(userLogedIn) === true ? 'Yes' : 'No',
        };
        trackEvent(
          Events.ontradeserviceableviewpage,
          onTradeServiceablePageAttributes,
        );
        setFirstTimeUser(false);
      }

      if (
        tradeListResponse?.active_trade_list?.length ||
        tradeListResponse?.expired_trade_list?.length
      ) {
        setBuyerList((prev) => [
          ...prev,
          ...tradeListResponse.active_trade_list,
          ...tradeListResponse.expired_trade_list,
        ]);
        setActiveTradeList([
          ...activeTradeList,
          ...tradeListResponse.active_trade_list,
        ]);
        setExpiredTradeList([
          ...expiredTradeList,
          ...tradeListResponse.expired_trade_list,
        ]);
        tradeListResponse?.promotional_media &&
          setPromotionalMediaList(tradeListResponse?.promotional_media);
        setTotalRecords(tradeListResponse?.total_records);
        setGuestLoginAttempts(0);
      }
      setActivePriceCount(tradeListResponse?.active_buyers_prices);
    } catch (error) {
      setBuyersListLoading(false);
      if (error?.code === 401 || error?.code === 426) {
        if (platformData.platform === 'WEB') {
          if (unVerifiedRoutes?.guestLogin?.includes(error?.path)) {
            await loginAsGuest();
            const authToken = localStorage.getItem('authToken');
            if (authToken) {
                setToken(authToken);
            }
            setGuestLoginAttempts((lastValue) => {
              return lastValue + 1;
            });
          } else {
            auth.setMobileNumberModalVisible(true);
          }
        }
      } else {
        trackEvent(Events.onStartAppLevelFailureScreen, {
          Loading_time: (new Date().getTime() - startLoadTime) / 1000,
        });
        setIsErrorScreenVisible(true);
      }
      console.log(error);
    } finally {
      setBuyersListLoading(false);
    }
  }

  function userLogs() {
    setServiciableLoading(true);
    checkWebLocation();
  }

  function onContactUs() {
    const helpButtonAttributes = {
      Loading_time: (new Date().getTime() - startLoadTime) / 1000,
      userLoggedIn: JSON.parse(userLogedIn) === true ? 'Yes' : 'No',
    };
    trackEvent(
      Events.ontradenonserviceablehelpbuttonclicked,
      helpButtonAttributes,
    );
    const url = `https://wa.me/${helpLineNumber}?text=hey%2C+i+was+looking+at+the+trade+section+of+your+app.+But+could+not+find+any+potential+buyer%2C+want+to+suggest+a+few+buyers`;
    if (platformData.platform === 'WEB') {
      const win = window?.open(url, '_blank');
      win?.focus();
    } else {
      const text = `Hey, I was looking at the trade section of your app. But could not find any potential buyer, want to suggest a few buyers &phone=${helpLineNumber}`;
      const link = `whatsapp://send?text=${text}`;
      bridge.sendRequestToNative(
				"OpenUrl",
				{
					link: link,
				},
				(response) => {
					bridgeFunctions.PrintLog(response);
				},
			);
    }
  }

  function onClickHelp() {
    if (isErrorScreenVisible) {
      trackEvent(Events.onAppFailureScreenHelpButtonClick, {});
    }
    trackEvent(Events.onHelpButtonClick, {});
    const url = `https://wa.me/${helpLineNumber}?text=Hey%20Farmart%20%0A%0AWas%20checking%20out%20your%20trade%20section%20on%20the%20farmart%20app.%20%0A%0AI%20need%20Help`;
    if (platformData.platform === 'WEB') {
      const win = window?.open(url, '_blank');
      win?.focus();
    } else {
      const text = `Hey Farmart\n I Was checking out your trade section on the farmart app.\n I need Help &phone=${helpLineNumber}`;
      const link = `whatsapp://send?text=${text}`;
      bridge.sendRequestToNative(
				"OpenUrl",
				{
					link: link,
				},
				(response) => {
					bridgeFunctions.PrintLog(response);
				},
			);
    }
  }

  function trackClosedScreen() {
    const closedScreenAttributes = {
      Time_Spent: (new Date().getTime() - startLoadTime) / 1000,
    };
    trackEvent(Events.onEndCropViewPage, closedScreenAttributes);
  }
  useEffect(() => {
    userLogs();
    return trackClosedScreen;
  }, [askForPermission, token, screenRefresh]);

  useEffect(() => {
    if (!reloadRefresh) {
      serviciability && getTradeList();
    }
  }, [
    tradeListPageNumber,
    serviciability,
    activeCrop,
    applyFilterRefresh,
    screenRefresh,
    searchedBuyerDetail,
  ]);

  function onCLickViewDetail(
    id: string,
    cropName: string,
    buyerName: string,
    requestSauda: boolean,
  ): void {
    const viewPageAttributes = {
      LoadingTime: (new Date().getTime() - startLoadTime) / 1000,
      Crop_name: cropName,
      Buyer_name: buyerName,
      requestSauda: requestSauda,
    };
    const onClickPriceCardAttributes = {
      LoadingTime: (new Date().getTime() - startLoadTime) / 1000,
    };
    if (requestSauda) {
      trackEvent(Events.onTradeListingRequestSaudaButtonClick, {
        Crop_name: cropName,
        Buyer_name: buyerName,
        userLoggedIn: JSON.parse(userLogedIn) === true ? 'Yes' : 'No',
      });
    }
    trackEvent(Events.onTradeDetailviewpage, viewPageAttributes);
    trackEvent(Events.onclickpricecard, onClickPriceCardAttributes);
    navigate(`/trades/${id}`, { state: { requestSauda: requestSauda } });
  }

  function onClickMySaudaCard() {
    trackEvent(Events?.onMySaudasButtonClick, {});
    if (platformData?.platform === PlatForms.get('website')) {
      navigate('/trades/mySauda');
    } else {
      (window as any)?.ReactNativeWebView?.postMessage(
        JSON.stringify({
          type: 'nativeNavigation',
          navigationConstant: {
            featureId: homeScreenDownloadAppModalType.get('mySaudaList'),
            merchantOccupationId,
          },
        }),
      );
      navigate('/trades/mySauda');
    }
  }

  function onChangeLocation() {
    setLocationModal(true);
    trackEvent(Events.onChangeTradeLocationButton, {});
    trackEvent(Events.onLocationAccessPopup, {});
    setLocationModalLoading(false);
  }

  function onRefreshScreen() {
    trackEvent(Events.onAppFailureScreenRetryButtonClick, {});
    setScreenRefresh((lastValue) => !lastValue);
  }

  function onClickRemoveBuyerSearch(
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) {
    const onClickRemoveBuyerSearch = {
      screenName: 'Trade',
    };
    trackEvent(Events?.onBuyerPriceCrossButtonClick, onClickRemoveBuyerSearch);

    event.stopPropagation();
    setSearchedBuyerDetail({});
    navigate(pathname, { replace: true, state: null });
    setTradeListPageNumber(1);
    setBuyerList([]);
    setActiveTradeList([]);
    setExpiredTradeList([]);
    setTotalRecords(0);
  }

  function onClickBuyerSearch() {
    trackEvent(Events?.onBuyerPriceSearchButtonClick, {});

    navigate('/buyerSearch');
  }

  async function reloadTrades(): Promise<void> {
    return new Promise<void>((resolve) => {
      setServiciableLoading(true);
      resetFilters();
      setTradeListPageNumber(1);

      setSearchedBuyerDetail({});
      checkWebLocation().then(() => {
        serviciability && getTradeList(1).then(() => resolve());
      });
    });
  }

  return {
    onClickAllowLocationModal,
    serviciableLoading,
    serviciability,
    getMoreTradeListData,
    locationModalLoading,
    onCLickViewDetail,
    locationToastPopup,
    handleLocationToastPopup,
    numberCopyToast,
    handleNumberCopyToast,
    onContactUs,
    onClickHelp,
    buyersListLoading,
    saudaRequestToastPopup,
    handleSaudaRequestToastPopup,
    onClickMySaudaCard,
    masterStateDistrict,
    onChangeLocation,
    isLocationPermission,
    tradeScreenAssets,
    isErrorScreenVisible,
    onRefreshScreen,
    searchedBuyerDetail,
    onClickRemoveBuyerSearch,
    onClickBuyerSearch,
    reloadTrades,
    activePriceCount,
  };
}
