import UserInterestView from '../../Pages/UserInterest';
import UserInterestHooks from '../../hooks/UserInterest/UserInterestHooks';

export default function userInterestHooks({}) {
  const {
    handleLanguageSelect,
    onLanguageModalToggle,
    handleLanguageChange,
    selectedLanguage,
    isLanguageModalOpen,
    isLanguageSelectedInProgress,
    language,
    userInterestAssets,
    languageData,
    userInterestList,
    handleSelectCard,
    selectedIds,
    submitUserInterest,
    allSeleted,
    userInterestLoading,
    initialScreenLoading
  } = UserInterestHooks();
  return (
    <UserInterestView
      handleLanguageSelect={handleLanguageSelect}
      onLanguageModalToggle={onLanguageModalToggle}
      handleLanguageChange={handleLanguageChange}
      selectedLanguage={selectedLanguage}
      isLanguageModalOpen={isLanguageModalOpen}
      isLanguageSelectedInProgress={isLanguageSelectedInProgress}
      language={language}
      userInterestAssets={userInterestAssets}
      languageData={languageData}
      userInterestList={userInterestList}
      handleSelectCard={handleSelectCard}
      selectedIds={selectedIds}
      submitUserInterest={submitUserInterest}
      allSeleted={allSeleted}
      userInterestLoading={userInterestLoading}
      initialScreenLoading={initialScreenLoading}
    />
  );
}
