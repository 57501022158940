
import React, { Component, ReactNode } from 'react';
import {
    Box,
    Typography,
    Button,
    BoxProps,
    Stack
} from '@mui/joy';
import ErrorIcon from '@mui/icons-material/Error'

export interface IErrorTemplate extends BoxProps {
    errorDescription?: string
    handleRetryClick: () => void;
}
function ErrorTemplate({ errorDescription, handleRetryClick, ...props }: IErrorTemplate) {
    return (
        <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            width='100%'
            height='100%'
            textAlign='center'
            data-testid={`${props['data-testid']}-wrapper-box`}
            {...props}
        >
            <Box data-testid={`${props['data-testid']}-error-box`}>
                <Stack direction="column" alignItems='center' gap={8.5} data-testid={`${props['data-testid']}-error-box-Stack`}>
                    <Stack direction="column" alignItems='center' gap={3} data-testid={`${props['data-testid']}-error-box-Stack-internal`} >
                        <ErrorIcon sx={{ color: '#C41C1C', width: '50px', height: '50px' }} data-testid={`${props['data-testid']}-error-icon`} />
                        <Stack direction="column" gap={1} alignItems='center' data-testid={`${props['data-testid']}-error-Stack`}>
                            <Typography level='h3' textColor='text.primary' data-testid={`${props['data-testid']}-error-title`}>
                                Something went wrong
                            </Typography>
                            <Typography level='body-sm' textColor='text.tertiary' data-testid={`${props['data-testid']}-error-description`}>
                                {errorDescription || 'There was a problem processing the request. Please try again.'}
                            </Typography>
                        </Stack>
                    </Stack>
                    <Button onClick={handleRetryClick}>
                        Retry Now
                    </Button>
                </Stack>
            </Box>
        </Box>
    )
}




export const handleHardReload = (needCacheBurst = false) => {
    if(needCacheBurst){
        const url = window.location.origin + window.location.pathname;
        const cacheBuster = `?cachebuster=${new Date().getTime()}`;
        window.location.replace(url + cacheBuster);
        return;
    }
    window.location.reload()
};


export const handleHardReloadAfter = (delay = 0) => {
    setTimeout(() => handleHardReload(false), delay);
};



export interface ErrorBoundaryLogger {
    error?: (error: Error, errorInfo?: React.ErrorInfo) => void;
    info?: (message: string, details?: Record<string, unknown>) => void;
    warn?: (message: string, details?: Record<string, unknown>) => void;
}

interface CustomOptions {
    displayErrorDesc?: boolean;
    errorTemplateProps?: IErrorTemplate;
    onErrorCatched?: (error: Error, errorInfo?: React.ErrorInfo) => { autoReload: boolean, maxRetries: number };
    logger?: ErrorBoundaryLogger
}
interface ErrorBoundaryProps {
    fallback?: React.ComponentType<FallbackProps>;
    options?: CustomOptions;
    children: ReactNode;
}

interface FallbackProps {
    error: Error | null;
    errorInfo: React.ErrorInfo | null;
}

interface ErrorBoundaryState {
    hasError: boolean;
    error: Error | null;
    errorInfo: React.ErrorInfo | null;
    retryCount: number,
}

export default class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = {
            hasError: false,
            error: null,
            errorInfo: null,
            retryCount: 0,
        };
    }

    static getDerivedStateFromError(error: Error): ErrorBoundaryState {
        return { hasError: true, error, errorInfo: null, retryCount: 0 };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        this.props.options?.logger?.error?.(error, errorInfo);

        const { options } = this.props;
        const { autoReload, maxRetries } = options?.onErrorCatched
            ? options.onErrorCatched(error, errorInfo)
            : { autoReload: false, maxRetries: 0 };


        if (typeof autoReload !== 'boolean' || typeof maxRetries !== 'number' || maxRetries < 0) {
            this.props.options?.logger?.error?.(new Error('Invalid return value from onErrorCatched. Expected { autoReload: boolean, maxRetries: number }'));
            return;
        }

        if (autoReload) {
            handleHardReloadAfter(1000);
        }
        const safeMaxRetries = Math.min(maxRetries, 10);
        if (safeMaxRetries && this.state.retryCount < safeMaxRetries) {
            this.props.options?.logger?.warn?.(`Retrying... Attempt ${this.state.retryCount + 1}/${maxRetries}`);
            this.setState((prevState) => ({
                retryCount: prevState.retryCount + 1,
                error: null,
                errorInfo: null,
            }));
        } else {
            this.props.options?.logger?.warn?.('Max retries exhausted');
            this.setState({ error, errorInfo });
        }
    }

    render(): ReactNode {
        const { hasError, error, errorInfo } = this.state;
        const { fallback: FallbackComponent, children, options } = this.props;

        if (hasError) {
            if (FallbackComponent) {
                return <FallbackComponent error={error} errorInfo={errorInfo} />;
            }
            const errorDescription = `${error?.name}: ${error?.message}`
            return <ErrorTemplate errorDescription={options?.displayErrorDesc ? errorDescription : undefined} handleRetryClick={handleHardReload} {...options?.errorTemplateProps} />
        }
        return children;
    }
}



function withErrorBoundary<P extends JSX.IntrinsicAttributes>(
    WrappedComponent: React.ComponentType<P>,
    FallbackComponent?: React.ComponentType<FallbackProps>,
    options?: CustomOptions
): React.ComponentType<P> {
    const ComponentWithErrorBoundary = (props: P) => (
        <ErrorBoundary fallback={FallbackComponent} options={options}>
            <WrappedComponent {...props} />
        </ErrorBoundary>
    );

    const componentName = WrappedComponent.displayName || WrappedComponent.name;

    ComponentWithErrorBoundary.displayName = `withErrorBoundary(${componentName})`;

    return ComponentWithErrorBoundary;
}

export { withErrorBoundary };
