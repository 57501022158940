export default function getTranslatedLanguage(
  template: string,
  values: (string | null | undefined)[],
): string {
  if (typeof template !== 'string') {
    throw new Error('Template must be a string');
  }
  if (!Array.isArray(values)) {
    throw new Error('Values must be an array');
  }
  const pattern = /\{\(variable(\d+)\)\}/g; // Updated pattern to match {(variableX)}
  return template.replace(pattern, (match, index) => {
    const arrayIndex = Number.parseInt(index) - 1;
    if (arrayIndex >= 0 && arrayIndex < values.length) {
      return values[arrayIndex] ?? '';
    }
    return match;
  });
}
