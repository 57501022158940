import React from "react";
import Style from "./style.module.css";
import Button from "@mono-farmart-web/farmart-web-common/ComponentsV3/MuiButton";
import AddRounded from "@mui/icons-material/AddRounded";
import DocumentType from '@mono-farmart-web/farmart-web-common/constant/enums/docType';
import { noop } from "@mono-farmart-web/farmart-web-common/utils/common";

interface DocumentUploadFooterProps {
	doneDocumentUpload: () => void;
	handleFileChange?: (
		event: React.ChangeEvent<HTMLInputElement>,
    fileCount?: number
	) => void;
  isAddMoreDisabled: boolean;
	handleUploadButtonClick?: (from?: string, index?: number) => void;
	fileInputRef: any;
	language: { [key: string]: any };
	disableDone?: boolean;
  fileCount: number;
}

const DocumentUploadFooter = ({
  doneDocumentUpload,
  handleFileChange=noop,
  handleUploadButtonClick,
  fileInputRef,
  isAddMoreDisabled,
  language,
  fileCount,
  disableDone,
}: DocumentUploadFooterProps) => {


  return (
    <div className={Style.footer}>
      <input
        type="file"
        accept=".pdf, .jpg, .jpeg, .png"
        multiple
        onChange={(event) => {
          handleFileChange(event, fileCount)
        }}
        style={{ display: 'none' }}
        ref={fileInputRef}
      />
      {
        !isAddMoreDisabled &&
          <Button
          variant="outlined"
          startDecorator={<AddRounded />}
          onClick={() => {
            handleUploadButtonClick('addMoreFiles');
          }}
          disabled={isAddMoreDisabled}
          id="addMoreFilesButton"
          size={'lg'}
        >
          {language?.add_more_files}
        </Button>
      }
      <Button
        onClick={doneDocumentUpload}
        id="onClickDoneDocumentUploadButton"
        size={'lg'}
        disabled={disableDone}
      >
        {language?.done}
      </Button>
    </div>
  );
};

export default DocumentUploadFooter;
