import React from 'react';
import Styles from './style.module.css';
import Typography from '@mui/joy/Typography';
import { Box } from '@mui/material';

interface HeaderProps {
  onLanguageModalToggle: () => void;
  onPressBack: (from: string) => () => void;
  language: { [key: string]: any };
  languageSelectionIcon: string;
  backIcon: string;
}
const Header = (props: HeaderProps) => {
  const {
    onLanguageModalToggle,
    onPressBack,
    language,
    languageSelectionIcon,
    backIcon,
  } = props;
  return (
    <Box className={Styles.mainContainer} bgcolor={'background-surface'}>
      <Box className={Styles.locationContainer} id="otpScreenBackTestId">
        <div
          className={Styles.loactionLabelContainer}
          onClick={onPressBack('backButton')}
        >
          <img
            className={Styles.whatsAppIcon}
            src={backIcon}
            alt="Back Icon"
            height={24}
            width={24}
            loading="eager"
          />
        </div>
      </Box>

      <Box
        className={Styles.helpButtonContainer}
        onClick={onLanguageModalToggle}
        id="ChangeLanguageButton"
        data-testid="ChangeLanguageButton"
        content-desc="ChangeLanguageButton"
      >
        <img
          className={Styles.languageIconStyle}
          src={languageSelectionIcon}
          alt="Language Icon"
          height={20}
          width={20}
          loading="eager"
        />
        <Typography level="body-xs" color="primary">
          {language?.changeLanguage}
        </Typography>
      </Box>
    </Box>
  );
};

export default Header;
