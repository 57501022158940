import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface PaymentDetailModalOpen {
  isPaymentDetailModalOpen: boolean | null;
}

const initialState: PaymentDetailModalOpen = {
  isPaymentDetailModalOpen: null,
};

const paymentDeatilModal = createSlice({
  name: 'paymentDeatilModal',
  initialState,
  reducers: {
    setPaymentDetailModalOpenVisible: (state, action: PayloadAction<boolean>) => {
      state.isPaymentDetailModalOpen = action.payload;
    },
  },
});

export const { setPaymentDetailModalOpenVisible } = paymentDeatilModal.actions;

export default paymentDeatilModal.reducer;
