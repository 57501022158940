import * as AppLanguages from '../constant/appLanguages';
//language will be added here
const languages = {
  [AppLanguages.appLanguagesIndex.english]: {
    title: 'Transaction Recorded',
    paymentNoticeLine: '₹{(variable1)} lent to {(variable2)}',
    receivedNoticeLine: '₹{(variable1)} received from {(variable2)}',
    protectButton: '100% Data Secure',
  },
  [AppLanguages.appLanguagesIndex.hindi]: {
    title: 'लेन देन रिकॉर्ड किया गया ',
    paymentNoticeLine: '₹{(variable1)} {(variable2)} को दिया गया',
    receivedNoticeLine: '₹{(variable1)} {(variable2)} से प्राप्त हुए',
    protectButton: '100% डाटा सुरक्षित',
  },
};

export default (language) => languages[language];
