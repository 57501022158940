import UserRegistrationView from '../../Pages/UserRegistration/userRegistraion';
import useUserRegistrationHook from '../../hooks/UserRegistrationhooks/userRegistrationHooks';

export default function otpScreen() {
  const {
    handleLanguageSelect,
    onLanguageModalToggle,
    handleLanguageChange,
    selectedLanguage,
    isLanguageModalOpen,
    isLanguageSelectedInProgress,
    selectedPersona,
    onClickPersonaCard,
    language,
    loading,
    onClickProceedPersona,
    userRegistrationAssets,
    languageData,
  } = useUserRegistrationHook();

  return (
    <UserRegistrationView
      handleLanguageSelect={handleLanguageSelect}
      onLanguageModalToggle={onLanguageModalToggle}
      handleLanguageChange={handleLanguageChange}
      selectedLanguage={selectedLanguage}
      isLanguageModalOpen={isLanguageModalOpen}
      isLanguageSelectedInProgress={isLanguageSelectedInProgress}
      selectedPersona={selectedPersona}
      onClickPersonaCard={onClickPersonaCard}
      language={language}
      loading={loading}
      onClickProceedPersona={onClickProceedPersona}
      userRegistrationAssets={userRegistrationAssets}
      languageData={languageData}
    />
  );
}
