import * as AppLanguages from '../constant/appLanguages';
//language will be added here
const languages = {
  [AppLanguages.appLanguagesIndex.english]: {
    title: 'New Transaction',
    settingPermissionInfo:
      'Give permission to access contact book to create khata directly from your contacts.',
    settingPermissionSteps:
      'Settings > Permission > Tap on “Allow” as shown above in picture.',
    settingPermissionButton: 'Go to Settings',
    addManuallyButton: 'Add Manually',
    downloadAppInfo:
      'To add farmers directly from the your Contact Book, please download our app.',
    downloadAppButton: 'Download FarMart app',
    recordTransactionTitle: 'Record transaction with',
  },
  [AppLanguages.appLanguagesIndex.hindi]: {
    title: 'नया लेनदेन',
    settingPermissionInfo:
      'खाता सीधे अपने संपर्कों से बनाने के लिए संपर्क सूची की अनुमति दें।',
    settingPermissionSteps:
      "सेटिंग्स > अनुमति > ऊपर चित्र में दिखाए अनुसार 'अनुमति दें' पर टैप करें।",
    settingPermissionButton: 'सेटिंग्स पर जाएं',
    addManuallyButton: 'खुद से जोड़ें',
    downloadAppInfo:
      'किसानों को सीधे अपनी संपर्क सूची से जोड़ने के लिए, कृपया ऐप डाउनलोड करें।',
    downloadAppButton: 'FarMart ऐप डाउनलोड करें',
    recordTransactionTitle: 'लेनदेन रिकॉर्ड करें',
  },
};

export default (language) => languages[language];
