import { Box } from '@mui/joy';
import MuiTypography from '@mono-farmart-web/farmart-web-common/ComponentV3/Typography';

interface NoTransactionContainerProps {
  language: { [key: string]: string };
  bahiKhataLandingAssets: { [key: string]: string };
}

export default function NoTransactionContainer(
  props: NoTransactionContainerProps,
) {
  const { language, bahiKhataLandingAssets } = props;
  return (
    <Box
      display={'flex'}
      padding={'48px 16px'}
      flexDirection={'column'}
      alignItems={'center'}
      gap={'var(--4, 16px)'}
      height={'100%'}
      width={'100%'}
      boxSizing={'border-box'}
    >
      <img
        id="bahikhata_empty_placeholder"
        data-testid="no-transaction-image"
        content-desc="no-transaction-image"
        src={bahiKhataLandingAssets?.noTransactionImage}
        alt="noTransaction"
        style={{ width: '64px', height: '64px' }}
      />
      <MuiTypography level="body-xs">{language?.emptyStateMsg}</MuiTypography>
    </Box>
  );
}
