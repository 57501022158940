import { useState, useEffect } from 'react';
import type {
  UserInterestProps,
  LanguageParams,
  UserInterestData,
} from './types.js'; // Importing necessary types
import getLang from '@mono-farmart-web/farmart-web-common/languages/userInterest.js'; // Importing language translation function
import { useNavigate } from 'react-router-dom'; // Importing hooks for navigation
import { usePlatfromData } from '@mono-farmart-web/farmart-web-common/context/CrossPlatformActionHandler/platformChecker.js';
import { createBridge } from '@mono-farmart-web/rpc-bridge';
import { languageUrls } from '@mono-farmart-web/farmart-web-common/constant/languageUrls/index';
import { useLanguage } from '@mono-farmart-web/farmart-web-common/context/language';
import useAppLanguage from '@mono-farmart-web/farmart-web-common/utils/applanguage/index.js';
import { getUserInterestAssets } from '@mono-farmart-web/farmart-web-common/constant/imageUrls';

import { apiEndPoints } from '@mono-farmart-web/farmart-web-common/apis/endpoints/index.js';
import guideIdEnums from '@mono-farmart-web/farmart-web-common/constant/enums/guideIdEnums';
import PlatForms from '@mono-farmart-web/farmart-web-common/constant/platFormEnums.js';
import bridgeFunctions from '@mono-farmart-web/rpc-bridge/utility.js';
import { trackEvent } from '@mono-farmart-web/farmart-web-common/utils/moengage/index.js';
import Events from '@mono-farmart-web/farmart-web-common/constant/MoengageEvents/index.js'; // Importing constant events
import GetRequest from "@mono-farmart-web/farmart-web-common/utils/apiCaller/getRequest.js";
import PostRequest from "@mono-farmart-web/farmart-web-common/utils/apiCaller/postRequest.js";


export default function WalkthroughHooks(): UserInterestProps {
  const bridge = createBridge();
  const { languageData } = useLanguage();
  const navigate = useNavigate(); // Navigation function
  const platformData = usePlatfromData(); // Getting platform data
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [allSeleted, setAllSelected] = useState<boolean>(false);
  const token = localStorage.getItem('authToken');
  const [userInterestLoading, setUserInterestLoading] = useState<boolean>(false);
  const [userInterestIds, setUserInterestIds] = useState<number[]>([]);
  const [initialScreenLoading, setInitialScreenLoading] = useState<boolean>(true);

/**
 * The function `handleSelectCard` manages the selection of cards based on certain conditions,
 * including updating selectedIds and setting allSelected flag.
 * @param {number} id - The `id` parameter in the `handleSelectCard` function represents the unique
 * identifier of the card that is being selected or deselected. This identifier is used to determine
 * the action to be taken based on the current state of selection.
 */
  function handleSelectCard(id: number) {
    if (id === 6 || selectedIds.includes(6)) {
      if (!selectedIds.includes(6)) {
        setSelectedIds((prevSelectedIds) => [id]);
        setAllSelected(true);
      } else if (selectedIds.includes(6)) {
        setAllSelected(false);
        if (id===6) {
        setSelectedIds((prevSelectedIds) =>
          prevSelectedIds.filter(
            (selectedId) => selectedId !== 6 && selectedId !== id,
          ),
        );
        } else {
          setSelectedIds((prevSelectedIds) =>
            userInterestIds.filter(
              (selectedId) => selectedId !== 6 && selectedId !== id,
            ),
          );
        }
      }
    } else {
      setAllSelected(false);

      setSelectedIds((prevSelectedIds) => {
        if (prevSelectedIds.includes(id)) {
          // If already selected, remove it
          return prevSelectedIds.filter(
            (selectedId) => selectedId !== 6 && selectedId !== id,
          );
        } else {
          // If not selected, add it
          return [...prevSelectedIds, id];
        }
      });
    }
  }

  const { language } = useAppLanguage(
    languageUrls?.userInterestScreenLangageUrl,
    getLang,
  );

  const activeSystemLang = Intl.DateTimeFormat().resolvedOptions().locale;
  const systemLanguage = activeSystemLang?.includes('en')
    ? languageData && languageData[1]?.langCode
    : languageData && languageData[0]?.langCode;
  const systemLanguageId = activeSystemLang?.includes('en')
    ? languageData && languageData[1]?.backendLanguageCode
    : languageData && languageData[0]?.backendLanguageCode;

  const langId = JSON.parse(localStorage.getItem('userLanguage')); // Getting user's selected language

  const userInterestAssets = getUserInterestAssets(langId?.langCode || 'HI');

  const [loading, setLoading] = useState<boolean>(false); // State to track loading status
  const [userInterestList, setUserInterestList] = useState<UserInterestData[]>(
    [],
  ); // State to store user interest data

  // Storing current language or defaulting to Hindi
  const currentLanguage = langId || {
    langCode: systemLanguage,
    langId: systemLanguageId,
  };
  const [isLanguageModalOpen, toggleLanguageModal] = useState<boolean>(false); // State to toggle language modal
  const [isLanguageSelectedInProgress, setLanguageSelectedInProgeress] =
    useState<boolean>(false); // State to track if language selection is in progress

  const [selectedLanguage, setSelectedLanguage] = useState(currentLanguage); // State to store selected language

  // Function to handle language selection in language modal
  function handleLanguageSelect(languageItem: LanguageParams) {
    trackEvent(Events?.onChangeLanguageModalLanguageButtonClick, {
			'Language_Selected': languageItem.langCode,
		});
    setSelectedLanguage(languageItem);
  }

  // Function to handle language change
  function handleLanguageChange() {
		trackEvent(Events?.onChangeLanguageModalSubmitButtonClick, {});
    if (localStorage.getItem('userLanguage') !== selectedLanguage) {
      setLanguageSelectedInProgeress(!isLanguageSelectedInProgress);
      localStorage.setItem('userLanguage', JSON.stringify(selectedLanguage));
      toggleLanguageModal(!isLanguageModalOpen);
      setLanguageSelectedInProgeress(false);
      getUserInterest();
    } else {
      toggleLanguageModal(!isLanguageModalOpen);
    }
  }

  // Function to toggle language modal
  function onLanguageModalToggle() {
    setSelectedLanguage(
      langId || {
        langCode: systemLanguage,
        langId: systemLanguageId,
      },
    );
    toggleLanguageModal(!isLanguageModalOpen);
  }

/* The `useEffect` hook in the provided code snippet is used to update the selected language state
(`selectedLanguage`) based on the value stored in the `localStorage` for the key `'userLanguage'`. */
  useEffect(() => {
    setSelectedLanguage(
      langId || {
        langCode: systemLanguage,
        langId: systemLanguageId,
      },
    );
  }, [localStorage.getItem('userLanguage')]);


/* The `useEffect` hook in the code snippet is used to perform side effects in function components in
React. In this specific case: */

  useEffect(() => {
    getUserInterest();
  }, []);

/**
 * The function fetches user interest data from an API endpoint using an asynchronous request in
 * TypeScript.
 * @returns The `fetchUserInterest` function is returning the data fetched from the API endpoint
 * `apiEndPoints.userInterest` using a GET request. The data is returned as `userInterestDataFromApi`.
 */
  async function fetchUserInterest() {
    try {
      const userInterestDataFromApi = await GetRequest({
        payload: {},
        uri: apiEndPoints.userInterest,
      });
      return userInterestDataFromApi;
    } catch (error) {
      console.log(error);
    }
  }

/**
 * The function `getUserInterest` fetches user interest data and updates the user interest list if the
 * response status is true.
 */
  async function getUserInterest() {
    try {
      const userInterestResp = await fetchUserInterest();
      if (userInterestResp?.data?.status) {
        console.log(userInterestResp.data);
        setUserInterestList(userInterestResp?.data?.data?.interests);
        setUserInterestIds(userInterestResp?.data?.data?.interest_ids);
      }
    } catch (error) {
      
    } finally {
      setInitialScreenLoading(false);  // Set loading to false after data is ready
    }
  }

/**
 * The function `logUserPurpose` asynchronously sends a POST request with a payload to a specified API
 * endpoint for logging user purposes.
 */
  async function logUserPupose() {
    try {
      const payload = {
        guide_id: guideIdEnums.get('userInterest'),
        is_completed: true,
      };
      await PostRequest({
        payload: payload,
        uri: apiEndPoints?.userGuide,
      });
    } catch (error) {
      console.log(error);
    }
  }

/**
 * The function `submitUserInterest` handles the submission of user interests by sending a POST request
 * to update user purpose and performing additional actions based on the response.
 */
  async function submitUserInterest() {
    try {
      setUserInterestLoading(true);
      trackEvent(Events.onLoginScreenFeatureSelected, {
        Feature_Name: selectedIds,
      });
      const payload = {
        interests: selectedIds,
      };
      const userInterestDataFromApi = await PostRequest({
        payload: payload,
        uri: apiEndPoints?.updateUserPurpose,
      });

      if (userInterestDataFromApi?.data?.status) {
        logUserPupose();
        onClickProceedPersona();
      }
      setUserInterestLoading(true);
    } catch (error) {
      console.log(error);
    }
  }

/**
 * The function `onClickProceedPersona` makes an asynchronous request to fetch merchant profile data
 * and then sends relevant information to either a React Native WebView or stores it locally based on
 * the platform.
 */
  async function onClickProceedPersona() {
    setLoading(true);
    try {
      const getMerchantProfile = await PostRequest({
        payload: {},
        uri: apiEndPoints?.fetchMerchantProfile,
      });

      if (platformData?.platform === PlatForms?.get('application')) {
        // Post message to React Native WebView
        bridge.sendRequestToNative(
          'SetToken',
          {
            token: token,
            profile: getMerchantProfile?.data.data,
          },
          (response) => {
            bridgeFunctions.PrintLog(response);
          },
        );
        bridge.sendRequestToNative(
          'setAppLanguage',
          {
            langCode: selectedLanguage?.langCode,
          },
          (response) => {
            bridgeFunctions.PrintLog(response);
          },
        );
      } else {
        localStorage.setItem(
          'profileSelected',
          getMerchantProfile?.data?.data?.merchant_occupation_id,
        );
        localStorage.setItem('loged', 'true');
        navigate('/',{
          replace:true
        });
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }

  return {
    handleLanguageSelect,
    onLanguageModalToggle,
    handleLanguageChange,
    selectedLanguage,
    isLanguageModalOpen,
    isLanguageSelectedInProgress,
    language,
    languageData,
    userInterestAssets,
    userInterestList,
    handleSelectCard,
    selectedIds,
    submitUserInterest,
    allSeleted,
    userInterestLoading,
    initialScreenLoading,
  };
}
