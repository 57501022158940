import AspectRatio from '@mui/joy/AspectRatio';
import Skeleton from '@mui/joy/Skeleton';
import Styles from './style.module.css';
import { Box } from '@mui/joy';

const SkeletalLoader = () => {
  return (
    <Box className={Styles.container}>
      <AspectRatio ratio="21/9">
        <Skeleton animation="wave" variant="overlay">
          <img
            alt=""
            src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
          />
        </Skeleton>
      </AspectRatio>
      <br />
      <AspectRatio ratio="21/9">
        <Skeleton animation="wave" variant="overlay">
          <img
            alt=""
            src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
          />
        </Skeleton>
      </AspectRatio>
      <br />
      <AspectRatio ratio="21/9">
        <Skeleton animation="wave" variant="overlay">
          <img
            alt=""
            src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
          />
        </Skeleton>
      </AspectRatio>
      <br />
      <AspectRatio ratio="21/9">
        <Skeleton animation="wave" variant="overlay">
          <img
            alt=""
            src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
          />
        </Skeleton>
      </AspectRatio>
      <br />
      <AspectRatio ratio="21/9">
        <Skeleton animation="wave" variant="overlay">
          <img
            alt=""
            src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
          />
        </Skeleton>
      </AspectRatio>
    </Box>
  )
}

export default SkeletalLoader;
