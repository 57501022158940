import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import type { AcknowledgementProps } from "./types.js";
import { trackEvent } from "@mono-farmart-web/farmart-web-common/utils/moengage/index"; // Importing tracking functions
import Events from "@mono-farmart-web/farmart-web-common/constant/MoengageEvents/index"; // Importing constant events
import { getLanguageService } from "@mono-farmart-web/farmart-web-common/utils/getLanguageService";
import { languageUrls } from "@mono-farmart-web/farmart-web-common/constant/languageUrls/index";

export default function AddFarmerHook(): AcknowledgementProps {
	const { state } = useLocation();

	const [language, setLanguageFile] = useState({});

	useEffect(() => {
		getLanguageJson();
	}, []);

	async function getLanguageJson() {
		try {
			const url = languageUrls?.addFarmerAcknowledgementScreenLangageUrl;
			const result = await getLanguageService(url);
			setLanguageFile(result);
		} catch (error) {
			console.log("object");
		} finally {
			console.log("object");
		}
	}

	const navigate = useNavigate();

	function onPressBack() {
		trackEvent(Events.onViewMyFarmersButtonClick, {});
		if (state?.fromScreen) {
			navigate(`/${state?.fromScreen}`);
		} else {
			navigate("/myFarmers", {
				state: {
					from: "addFarmer",
				},
			});
		}
	}

	function onContactUs() {
		navigate("/faq/questions");
	}

	// Returning necessary functions and states
	return {
		onPressBack,
		language,
		onContactUs,
	};
}
