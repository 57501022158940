import * as AppLanguages from '../constant/appLanguages';
//language will be added here
const languages = {
  [AppLanguages.appLanguagesIndex.hindi]: {
    selectLanguageButton: 'आगे बढ़ें',
    changeLanguage: 'भाषा बदलें',
    proceed: 'आगे बढ़ें',
    selectLanguage: 'भाषा चुने',
    farmartMotoLabel: 'आपके व्यापार का भरोसेमंद साथी',
    heading: 'आप FarMart को किस लिए उपयोग करना चाहते हैं?',
    subHeading:
      'नीचे दिए गए विकल्पों में से उन विकल्पों को चुनें जिन्हें आप FarMart में जानना चाहते हैं!',
  },
  [AppLanguages.appLanguagesIndex.english]: {
    selectLanguageButton: 'Proceed',
    changeLanguage: 'Change Language',
    proceed: 'Continue',
    selectLanguage: 'Select language',
    farmartMotoLabel: 'Your Trusted Business Partner',
    heading: 'What brings you to FarMart?',
    subHeading:
      'Select options from below that you would like to explore in FarMart',
  },
};

export default (language) => languages[language];
