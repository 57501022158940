import { useRef, useCallback } from 'react';
import { unVerifiedRoutes } from '../../constant/unVerifiedRoutes';
import { getUserProfileFromStorage, loginAsGuest } from '../../modal/auth/auth.model';
import { useNavigate } from 'react-router-dom';
import { createBridge } from '@mono-farmart-web/rpc-bridge';
import bridgeFunctions from '@mono-farmart-web/rpc-bridge/utility';
import { useAuth } from '../../context/login';
import { isSaudabookPWA } from '../common';
import {
  UN_AUTHORIZED_CODES,
  apiRequestTypeChecker,
  makeRequestWithRetry,
  guestRetryRequest,
  RetryConfig,
  UnifiedResponse,
  DEFAULT_RETRY_CONFIG
} from '../authCheckerUtils';

interface Parameter {
  payload: any;
  requestType: 'get' | 'post' | 'put' | 'patch' | 'delete';
  loginMethod?: 'modal' | 'page';
  customUri?: string;
  uri?: string;
  retryConfig?: RetryConfig;
}

const useAuthenticationCheckerHook = () => {
  const navigate = useNavigate();
  const isRedirected = useRef(false);
  const authProps = useAuth();
  // Keep track of multiple active abort controllers
  const activeAbortControllersRef = useRef<AbortController[]>([]);

  function isAndroid() {
    try {
      return typeof (window as any)?.ReactNativeWebView !== 'undefined';
    } catch (e) {
      return false;
    }
  }

  const navigateToRootIfNotRedirected = () => {
    localStorage.setItem('refreshPage', 'true');
    if (!isRedirected.current) {
      isRedirected.current = true;
      navigate('/', {
        replace: true,
      });
    }
  };

  const handleWebAuthentication = async (
    payload: any,
    requestType: string,
    loginMethod: 'modal' | 'page' = 'page',
    customUri?: string,
    uri?: string,
    retryConfig?: RetryConfig,
    abortSignal?: AbortSignal
  ) => {
    const token = localStorage.getItem('authToken');
    const userProfile = getUserProfileFromStorage() || null;
    const merchantLogin =
      JSON.parse(localStorage.getItem('loged')) ||
      userProfile?.merchant_detail_id ||
      userProfile?.merchant_occupation_id ||
      (isSaudabookPWA && !!token);
    
    // Handle guest login routes
    if (unVerifiedRoutes?.guestLogin?.includes(uri || customUri)) {
      if (!token) {
        await loginAsGuest();
      }
      
      return guestRetryRequest(
        payload, 
        requestType, 
        customUri, 
        uri, 
        retryConfig,
        navigateToRootIfNotRedirected,
        abortSignal // Pass the abort signal
      );
    }
    
    // Handle authenticated routes
    if (merchantLogin) {
      try {
        const apiCall = () => apiRequestTypeChecker(payload, requestType, customUri, uri, abortSignal);
        return await makeRequestWithRetry(apiCall, retryConfig);
      } catch (error: any) {
        if (UN_AUTHORIZED_CODES?.includes(error?.code)) {
          localStorage?.clear();
          sessionStorage?.clear();
          
          if (!isRedirected.current) {
            isRedirected.current = true;
            if (loginMethod === 'modal') {
              authProps.setMobileNumberModalVisible(true);
            } else {
              navigate('/login', { replace: true });
            }
          }
        }
        throw error;
      }
    } else {
      // User is not logged in
      localStorage?.clear();
      sessionStorage?.clear();
      
      if (!isRedirected.current) {
        isRedirected.current = true;
        if (loginMethod === 'modal') {
          authProps.setMobileNumberModalVisible(true);
        } else {
          navigate('/login', { replace: true });
        }
      }
      
      throw new Error('User not authenticated');
    }
  };

  const handleAppAuthentication = async (
    payload: any,
    requestType: string,
    customUri?: string,
    uri?: string,
    retryConfig?: RetryConfig,
    abortSignal?: AbortSignal
  ) => {
    const bridge = createBridge();
    const token = localStorage.getItem('authToken');
    
    if (token) {
      try {
        const apiCall = () => apiRequestTypeChecker(payload, requestType, customUri, uri, abortSignal);
        return await makeRequestWithRetry(apiCall, retryConfig);
      } catch (error: any) {
        if (UN_AUTHORIZED_CODES?.includes(error?.code)) {
          // Logout logic for app
          bridge.sendRequestToNative(
            'logOutFromApp',
            null,
            (response) => {
              bridgeFunctions.PrintLog(response);
            },
          );
        }
        throw error;
      }
    } else {
      // Logout logic for app if no token
      bridge.sendRequestToNative('logOutFromApp', null, (response) => {
        bridgeFunctions.PrintLog(response);
      });
      
      throw new Error('Token not found in app');
    }
  };

  // Function to remove an AbortController from the active list
  const removeAbortController = (controller: AbortController) => {
    activeAbortControllersRef.current = activeAbortControllersRef.current.filter(
      c => c !== controller
    );
  };

  const AuthenticationCheckerApiCaller = useCallback(
    async (props: Parameter): Promise<UnifiedResponse> => {
      const { payload, requestType, loginMethod = 'page', customUri, uri, retryConfig = DEFAULT_RETRY_CONFIG } = props;

      // Create a new AbortController for this specific request
      const abortController = new AbortController();
      
      // Add it to our collection of active controllers
      activeAbortControllersRef.current.push(abortController);

      try {
        let result;
        if (isAndroid()) {
          result = await handleAppAuthentication(
            payload,
            requestType,
            customUri,
            uri,
            retryConfig,
            abortController.signal
          );
        } else {
          result = await handleWebAuthentication(
            payload,
            requestType,
            loginMethod,
            customUri,
            uri,
            retryConfig,
            abortController.signal
          );
        }
        
        // Request completed successfully, remove the controller
        removeAbortController(abortController);
        return result;
      } catch (error) {
        // Remove the controller on error as well
        removeAbortController(abortController);
        
        if (error.name === 'AbortError') {
          console.error('Request was aborted:', error);
        } else {
          console.error('An error occurred:', error);
        }
        throw error;
      }
    },
    [],
  );

  // Function to abort all ongoing requests
  const abortAllRequests = (reason: string) => {
    console.log(`Aborting ${activeAbortControllersRef.current.length} requests: ${reason}`);
    
    // Abort all active controllers
    activeAbortControllersRef.current.forEach(controller => {
      controller.abort(reason);
    });
    
    // Clear the list
    activeAbortControllersRef.current = [];
  };

  return { AuthenticationCheckerApiCaller, abortAllRequests };
};

export default useAuthenticationCheckerHook;