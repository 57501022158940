import { fetchLanguage } from '../languageApi/fetchLanguage';
import { getLanguageData } from '../../service/languageService';
export const getLanguageService = async (languageUrl: string) => {
  const getLanguageJson = await fetchLanguage(languageUrl);
  const langId = JSON.parse(localStorage.getItem('userLanguage'));
  const appLanguages = await getLanguageData();
  const activeSystemLang = Intl.DateTimeFormat().resolvedOptions().locale;
  const systemLanguage = activeSystemLang?.includes('en')
    ? appLanguages && appLanguages?.[1]?.langCode
    : appLanguages && appLanguages?.[0]?.langCode;
  const language =
    getLanguageJson[langId?.langCode ? langId?.langCode : systemLanguage];
  return language;
};
