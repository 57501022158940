import { createBridge } from "@mono-farmart-web/rpc-bridge";
import Events from "@mono-farmart-web/farmart-web-common/constant/MoengageEvents/index";
import { trackEvent } from "@mono-farmart-web/farmart-web-common/utils/moengage";
import { languageUrls } from "@mono-farmart-web/farmart-web-common/constant/languageUrls";
import getLang from "@mono-farmart-web/farmart-web-common/languages/recordTransaction.js";
import useAppLanguage from "@mono-farmart-web/farmart-web-common/utils/applanguage/index.js";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import useAuthenticationChecker from "@mono-farmart-web/farmart-web-common/utils/authCheckerV2";
import { apiEndPoints } from "@mono-farmart-web/farmart-web-common/apis/endpoints/index.js";
import bridgeFunctions from "@mono-farmart-web/rpc-bridge/utility";

const useSwitchView = () => {
	const bridge = createBridge();
	const { AuthenticationCheckerApiCaller } = useAuthenticationChecker();
	const navigate = useNavigate();
	const { language: recordTransactionLanguage } = useAppLanguage(
		languageUrls?.bahiKhataRecordTransactionLangUrl,
		getLang,
	);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isLoadContact, setIsLoadContact] = useState<boolean>(false);

	const handleSettingsPermission = async () => {
		const params = {
			openSettings: true,
		};

		// GO TO SETTINGS EVENT;
		trackEvent(Events?.onGotoSettingsButtonClick, {});

		bridge.sendRequestToNative("OpenAppSettings", params, (response: any) => {
			bridgeFunctions.PrintLog(response);
		});
	};

	const handleContactPermissionButtonClickEvent = () => {
		trackEvent(Events?.onEnableConactPermissionButtonClick, {});

		handleSettingsPermission();
	};

	const loadOrderedList = async () => {
		const response = await AuthenticationCheckerApiCaller({
			uri: apiEndPoints?.fetchBahiKhataContacts,
			requestType: "get",
			payload: {
				page_no: 1,
				limit: 5,
				search_param: "",
			},
		});

		const count = response?.data?.data?.count ?? 0;
		setIsLoading(false);
		if (count) {
			// navigate("/bahiKhata/contacts");
			setIsLoadContact(true);
		} else {
			setIsLoadContact(false);
		}
	};

	const handleAddUserClick = (payload: any) => {
		trackEvent(Events?.onAddKhataManuallyButtonClick, payload);

		navigate("/bahiKhata/recordTransaction/addNewKhata");
	};

	useEffect(() => {
		loadOrderedList();
	}, []);

	return {
		handleSettingsPermission,
		handleRecordTransactionAddUserClick: handleAddUserClick,
		handleContactPermissionButtonClickEvent,
		recordTransactionLanguage,
		isLoading,
		isLoadContact,
	};
};

export default useSwitchView;
