import { useEffect, useState } from 'react';
import type { PostDetails } from './types';
import {
  type PostDetailsById,
} from '@mono-farmart-web/farmart-web-common/modal/community/communityModel';
import { useSelector, useDispatch } from 'react-redux';
import type { RootState } from '@mono-farmart-web/farmart-web-common/store';
import { setSelectedPageId } from '@mono-farmart-web/farmart-web-common/modal/community/slice/community.slice';
import { useNavigate, useParams } from 'react-router-dom';
import { trackEvent } from '@mono-farmart-web/farmart-web-common/utils/moengage';
import Events from '@mono-farmart-web/farmart-web-common/constant/MoengageEvents/index';
import useAuthenticationCheckerHook from '@mono-farmart-web/farmart-web-common/utils/authCheckerV2';
import { apiEndPoints } from '@mono-farmart-web/farmart-web-common/apis/endpoints/index';

export default function UsePostDetail(): PostDetails {
  const navigate = useNavigate();
  const params = useParams();
  const { AuthenticationCheckerApiCaller } = useAuthenticationCheckerHook();
  const [postDetailsData, setPostDetailsData] = useState<PostDetailsById>(null);
  const [PostDetailLoading, setPostDetailLoading] = useState<boolean>(false);
  const [currentPostId, setCurrentPostId] = useState<number>(+params.id);
  const dispatch = useDispatch();

  const { selectedPageId } = useSelector((state: RootState) => {
    return {
      posts: state.community.posts,
      selectedPageId: state.community.pages.selectedPageId,
    };
  });

  async function fetchPostDetailsById() {
    setPostDetailLoading(true);
    try {
      const postsResult = await AuthenticationCheckerApiCaller({
        payload:{
          news_feed_id: currentPostId,
        },
        requestType:"get",
        uri:apiEndPoints.fetchPostById
      });

      setPostDetailsData(postsResult?.data?.data);
    } catch (error) {
      console.log(error);
      setPostDetailLoading(false);
    } finally {
      setPostDetailLoading(false);
    }
  }

  function onClickBack(): void {
    trackEvent(Events.onCommunityBackButtonClick, {
      From: 'Community_detail_screen',
    });

    navigate('/community');
    dispatch(setSelectedPageId(selectedPageId));
  }

  function onClickDisclaimer() {
    trackEvent(Events?.onCommunityDetailedDisclaimerButtonClick, {
      From: 'Post_Detail_Screen',
      Post_id: currentPostId,
    });
  }

  useEffect(() => {
    fetchPostDetailsById();
  }, [currentPostId]);

  return {
    postDetailsData,
    PostDetailLoading,
    setCurrentPostId,
    currentPostId,
    setPostDetailsData,
    onClickBack,
    onClickDisclaimer,
  };
}
