import { useSwitchView } from "../../hooks/RecordTransaction";
import ContactListView from "../../Pages/ContactList";
import isAndroid from '@mono-farmart-web/farmart-web-common/utils/operatingSystemType';
import WebView from "../../Pages/RecordTransaction/components/WebView";
import NativeView from "../../Pages/RecordTransaction/components/NativeView";
import SkeletonLoading from "@mono-farmart-web/farmart-web-common/ComponentsV4/SkeletonLoading";
import { useContactList, useRecentKhataList } from "../../hooks/ContactList";

const ContactList = () => {
  const isAndroidDevice = isAndroid();
  const isWeb = !isAndroidDevice;

  const {
    handleSettingsPermission,
    handleContactPermissionButtonClickEvent,
    recordTransactionLanguage,
    handleRecordTransactionAddUserClick,
    isLoading,
    isLoadContact
  } = useSwitchView();

  const {
    contacts,
    loading,
    hasMore,
    searchQuery,
    handleSearchChange,
    loadMoreItems,
    totalCount,
    contactPermission,
    language,
    handleAddUserClick,
    handleCreateNewTransaction,
    downloadAppEvent,
    nativeContacts,
  } = useContactList({
    initialPage: 1,
    initialSearch: "",
  });

  const {
    recentKhataList,
    error
  } = useRecentKhataList();


  return (
    <>
    {
      isLoading ? (
        <SkeletonLoading />
      ) : (
        <>
            {
            isLoadContact || nativeContacts?.length > 0 ? (
              <ContactListView
                nativeContacts={nativeContacts}
                handleCreateNewTransaction={handleCreateNewTransaction}
                recentKhataList={recentKhataList}
                language={language}
                contacts={contacts}
                loading={loading}
                hasMore={hasMore}
                searchQuery={searchQuery}
                handleSearchChange={handleSearchChange}
                loadMoreItems={loadMoreItems}
                totalCount={totalCount}
                handleSettingsPermission={handleSettingsPermission}
                handleContactPermissionButtonClickEvent={handleContactPermissionButtonClickEvent}
                handleAddUserClick={handleAddUserClick}
                contactPermission={contactPermission}
                downloadAppEvent={downloadAppEvent}/>
            ) : (
              <>
                {
                  isWeb ? (
                    <WebView language={recordTransactionLanguage} handleAddUserClick={handleRecordTransactionAddUserClick} />
                  ) : (
                    <NativeView
                      language={recordTransactionLanguage}
                      handleAddUserClick={handleRecordTransactionAddUserClick}
                      handleSettingsPermission={handleSettingsPermission} />
                  )
                }
              </>
            )
          }
        </>
      )
    }

    </>
  );
};

export default ContactList;
