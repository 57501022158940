import styles from './style.module.css';
import MuiButton from '@mono-farmart-web/farmart-web-common/ComponentV3/MuiButton';
import { Box } from '@mui/joy';
export default function AddFarmer({
  language,
  onClickAddFarmer,
  homeScreenAssets,
}) {
  return (
    <Box className={styles.mainContainer} bottom={80}>
      <MuiButton
        sx={{
          minHeight: '40px',
          padding: '4px 16px 4px 12px',
          alignContent: 'center',
          justifyContent: 'center',
          borderRadius: '8px',
          width: 'auto', // Ensure width is set to auto
          whiteSpace: 'nowrap', // Prevents text from wrapping if it's too long
          marginRight: '16px',
        }}
        size="md"
        color="primary"
        startDecorator={
          <img
            src={homeScreenAssets.addFilledIcon}
            height={24}
            width={24}
            loading="eager"
          />
        }
        onClick={onClickAddFarmer}
        id="addFarmarPwa"
      >
        {language?.addFarmer}
      </MuiButton>
    </Box>
  );
}
