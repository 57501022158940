import {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from 'react';

// Define a type for the context data
interface PlatformContextData {
  platform: string;
}

// Create a context

const PlatformContext = createContext<PlatformContextData | undefined>(
  undefined,
);
// Define the props for the PlatformContextProvider component
interface PlatformContextProviderProps {
  children: ReactNode;
}

export function usePlatfromData() {
  return useContext(PlatformContext);
}

function isAndroid() {
  try {
    return typeof (window as any)?.ReactNativeWebView !== 'undefined';
  } catch (e) {
    return false;
  }
}

export function PlatformContextProvider({
  children,
}: PlatformContextProviderProps) {
  const [platformData, setPlatformData] = useState<PlatformContextData>({
    platform: isAndroid() ? 'APP' : 'WEB'
  });

  useEffect(() => {
    setPlatformData({
      platform: isAndroid() ? 'APP' : 'WEB',
    });
  }, []);

  return (
    <PlatformContext.Provider value={platformData}>
      {children}
    </PlatformContext.Provider>
  );
}
