import  { useEffect, useState, lazy, Suspense } from 'react';
import { Link, useLocation } from 'react-router-dom';
import MuiSkeleton from '../../MuiComponents/Skeleton';
import { fetchLanguage } from '../../utils/languageApi/fetchLanguage';
import { languageUrls } from '../../constant/languageUrls';
import { useLanguage } from '../../context/language';
import correctUrl from '../urlCorrection';
import { trackEvent } from '../../utils/moengage';
import Events from '../../constant/MoengageEvents/index';

// Use React lazy for code splitting and lazy loading
const TradeIcon = lazy(() => import('../../assets/svgComponet/TradeIcon'));
const TradeIconActive = lazy(
  () => import('../../assets/svgComponet/TradeIconActive'),
);
const CommunityIcon = lazy(
  () => import('../../assets/svgComponet/CommunityIcon'),
);
const CommunityIconActive = lazy(
  () => import('../../assets/svgComponet/CommunityIconActive'),
);
const SMSIcon = lazy(() => import('../../assets/svgComponet/SMSIcon'));
const SMSIconActive = lazy(
  () => import('../../assets/svgComponet/SMSIconActive'),
);
const HomeScreenActiveIcon = lazy(
  () => import('../../assets/svgComponet/HomeScreenActiveIcon'),
);
const HomeScreenInActiveIcon = lazy(
  () => import('../../assets/svgComponet/homeScreenInActive'),
);
const ActiveTruckIcon = lazy(
  () => import('../../assets/svgComponet/ActiveTruckIcon'),
);
const InActiveTruckIcon = lazy(
  () => import('../../assets/svgComponet/InActiveTruckIcon'),
);
const BahiKhataActiveIcon = lazy(
  () => import('../../assets/svgComponet/BahiKhataActiveIcon'),
);
const BahiKhataInActiveIcon = lazy(
  () => import('../../assets/svgComponet/BahiKhataInActiveIcon'),
);

interface BottomNavigationBarProps {
  isRetailer: boolean;
  isTrader: boolean;
}

export default function BottomNavigationBar({
  isRetailer,
  isTrader,
}: BottomNavigationBarProps) {
  const location = useLocation();
  const { languageData } = useLanguage();
  const [languageFile, setLanguageFile] = useState({});
  const [currentPath, setCurrentPath] = useState(correctUrl(location.pathname));
  const [width, setWindowWidth] = useState(window.innerWidth);
  const langId = JSON.parse(localStorage.getItem('userLanguage'));
  const activeSystemLang = Intl.DateTimeFormat().resolvedOptions().locale;
  // Determine the system language code and backend language code based on whether the language is English
  const systemLanguage = activeSystemLang?.includes('en')
    ? languageData && languageData?.[1]?.langCode
    : languageData && languageData?.[0]?.langCode;

  const selectedLanguage = langId?.langCode ? langId?.langCode : systemLanguage;
  const language = languageFile[selectedLanguage || 'HI'];

  useEffect(() => {
    setCurrentPath(correctUrl(location?.pathname));
  }, [location?.pathname]);

  useEffect(() => {
    // Function to update window width state
    const handleResize = () => {
      setWindowWidth(window?.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [width]);

  const calculatedWidth = () => {
    if (width <= 480) {
      return {
        // Styles for max-width: 480px
        width: '100vw',
      };
    } else if (width >= 481 && width <= 767) {
      return {
        // Styles for min-width: 481px and max-width: 767px
        width: '100vw',
      };
    } else if (width >= 768 && width <= 1024) {
      return {
        // Styles for min-width: 768px and max-width: 1024px
        width: '100vw',
      };
    } else if (width >= 1025 && width <= 1280) {
      return {
        // Styles for min-width: 1025px and max-width: 1280px
        width: '30vw',
        margin: '0vw 35vw',
      };
    } else {
      return {
        // Styles for min-width: 1281px
        width: '30vw',
        margin: '0vw 35vw',
      };
    }
  };

  const activeIconStyle = () => {
    return {
      backgroundColor: '#008B74',
      borderRadius: '16px',
      background: 'var(--Primary-primaryGreen, #008B74)',
    };
  };

  const activeLabelStyle = () => {
    return {
      color: '#008B74',
    };
  };

  const skeleton = () => (
    <MuiSkeleton
      sx={{
        borderRadius: '16px',
        height: '32px',
        width: '64px',
      }}
    />
  );

  async function getLanguageJson() {
    try {
      const url = languageUrls?.navbarLanguageUrl;
      const result = await fetchLanguage(url);
      setLanguageFile(result);
    } catch (error) {
      console.log('object');
    } finally {
      console.log('object');
    }
  }

  useEffect(() => {
    getLanguageJson();
  }, []);

  const handleNavClick = (routeName: string) => {
    trackEvent(Events.onBottomNavigationBarClick, {
      button_name: routeName
    });
  };

  return (
    <nav
      className="bottom-navigation-bar"
      style={{
        ...calculatedWidth(),
        zIndex:1000,
      }}
    >
      <div className="bottom-navigation-bar-container">
        <Link 
          className="link-wrapper" 
          to="/"
          onClick={() => handleNavClick('home')}
        >
          <div
            className="icon-container"
            style={{
              ...(currentPath === '/' ? activeIconStyle() : {}),
            }}
          >
            <Suspense fallback={skeleton()}>
              {currentPath === '/' ? (
                <HomeScreenActiveIcon />
              ) : (
                <HomeScreenInActiveIcon />
              )}
            </Suspense>
          </div>
          <label
            className="labelInactive"
            style={{
              ...(currentPath === '/' ? activeLabelStyle() : {}),
            }}
          >
            {language?.home}
          </label>
        </Link>
        {isRetailer && (
          <Link 
            className="link-wrapper" 
            to="/shopPromotion"
            onClick={() => handleNavClick('shop_promotion')}
          >
            <div
              className="icon-container"
              style={{
                ...(currentPath === '/shopPromotion' ? activeIconStyle() : {}),
              }}
            >
              <Suspense fallback={skeleton()}>
                {currentPath === '/shopPromotion' ? (
                  <SMSIconActive />
                ) : (
                  <SMSIcon />
                )}
              </Suspense>
            </div>
            <label
              className="labelInactive"
              style={{
                ...(currentPath === '/shopPromotion' ? activeLabelStyle() : {}),
              }}
            >
              {language?.sms}
            </label>
          </Link>
        )}

        {isTrader && (
          <Link 
            className="link-wrapper" 
            to="/bahiKhata"
            onClick={() => handleNavClick('bahi_khata')}
          >
            <div
              className="icon-container"
              style={{
                ...(currentPath === '/bahiKhata' ? activeIconStyle() : {}),
              }}
            >
              <Suspense fallback={skeleton()}>
                {currentPath === '/bahiKhata' ? (
                  <BahiKhataActiveIcon />
                ) : (
                  <BahiKhataInActiveIcon />
                )}
              </Suspense>
            </div>
            <label
              className="labelInactive"
              style={{
                ...(currentPath === '/bahiKhata' ? activeLabelStyle() : {}),
              }}
            >
              {language?.bahiKhata}
            </label>
          </Link>
        )}

        <Link 
          className="link-wrapper" 
          to="/community"
          onClick={() => handleNavClick('community')}
        >
          <div
            className="icon-container"
            style={{
              ...(currentPath === '/community' ? activeIconStyle() : {}),
            }}
          >
            <Suspense fallback={skeleton()}>
              {currentPath === '/community' ? (
                <CommunityIconActive />
              ) : (
                <CommunityIcon />
              )}
            </Suspense>
          </div>
          <label
            className="labelInactive"
            style={{
              ...(currentPath === '/community' ? activeLabelStyle() : {}),
            }}
          >
            {language?.community}
          </label>
        </Link>

        <Link 
          className="link-wrapper" 
          to="/trades"
          onClick={() => handleNavClick('trades')}
        >
          <div
            className="icon-container"
            style={{
              ...(currentPath === '/trades' ? activeIconStyle() : {}),
            }}
          >
            <Suspense fallback={skeleton()}>
              {currentPath === '/trades' ? <TradeIconActive /> : <TradeIcon />}
            </Suspense>
          </div>
          <label
            className="labelInactive"
            style={{
              ...(currentPath === '/trades' ? activeLabelStyle() : {}),
            }}
          >
            {language?.trade}
          </label>
        </Link>
        <Link 
          className="link-wrapper" 
          to="/trades/mySauda"
          onClick={() => handleNavClick('my_sauda')}
        >
          <div
            className="icon-container"
            style={{
              ...(currentPath === '/trades/mySauda' ? activeIconStyle() : {}),
            }}
          >
            <Suspense fallback={skeleton()}>
              {currentPath === '/trades/mySauda' ? (
                <ActiveTruckIcon />
              ) : (
                <InActiveTruckIcon />
              )}
            </Suspense>
          </div>
          <label
            className="labelInactive"
            style={{
              ...(currentPath === '/trades/mySauda' ? activeLabelStyle() : {}),
            }}
          >
            {language?.mySauda}
          </label>
        </Link>
      </div>
    </nav>
  );
}
