import React, { lazy, Suspense } from "react";
import { getAppLanguage } from "@mono-farmart-web/farmart-web-common/utils/systemLanguage";
import { Routes, Route, useParams, useNavigate } from "react-router-dom";
import { Row } from "@mono-farmart-web/farmart-web-common/MuiComponents/atoms/layout";
import Loader from "@mono-farmart-web/farmart-web-common/ComponentV2/Loader";
const TripDetail = lazy(() => import('@mono-farmart-web/trip-tracking/viewmodal/TripDetail'));
const MapFullPage = lazy(() => import('@mono-farmart-web/trip-tracking/viewmodal/MapFullPage'));
const LocationHistory = lazy(() => import('@mono-farmart-web/trip-tracking/viewmodal/LocationHistory'));

const LazyLoader = () => (
  <Row
    style={{
      width: "100vw",
      height: "100dvh",
      backgroundColor: "white",
      justifyContent: "center",
      alignItems: "center",
    }}>
    <Loader width={"30px"} height={"30px"} />
  </Row>
)


function TripTracking() {
  const { tripId } = useParams();
  const activelang = getAppLanguage();
  const navigate = useNavigate();

  const clickHandlers = {
    navigator: ({tripId, routePage}) => () =>
      navigate(`/triptracking/${tripId}/${routePage}`)
  }

  return (
    <div>
      <Routes>
        <Route path="/tripdetail" element={
          <Suspense fallback={<LazyLoader />}>
            <TripDetail tripId={tripId} lang={activelang} clickHandlers={clickHandlers} />
          </Suspense>
        }  />
        <Route path="/mapfullpage" element={
          <Suspense fallback={<LazyLoader />}>
            <MapFullPage tripId={tripId} lang={activelang} clickHandlers={clickHandlers} />
          </Suspense>
        }  />
        <Route path="/locationhistorydetail" element={
          <Suspense fallback={<LazyLoader />}>
            <LocationHistory tripId={tripId} lang={activelang} clickHandlers={clickHandlers} />
          </Suspense>
        }  />
      </Routes>
    </div>
  );
}

export default TripTracking;
