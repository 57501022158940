import Modal from '@mono-farmart-web/farmart-web-common/ComponentV2/Modal';
import { Box } from '@mui/joy';
import MuiTypography from '@mono-farmart-web/farmart-web-common/ComponentV3/Typography';
import MuiButton from '@mono-farmart-web/farmart-web-common/ComponentV3/MuiButton';
import { Radio } from '@mui/joy';
import Styles from './style.module.css';
import DistributorType from '@mono-farmart-web/farmart-web-common/constant/enums/distributorType';
import SkeletonLoading from '@mono-farmart-web/farmart-web-common/MuiComponents/Skeleton';

interface CategoryModalProps {
  categoryModalOpenClose: boolean;
  handleCategoryModalOpenClose: () => void;
  submitCategory: () => void;
  language: { [key: string]: string };
  categoryData: any;
  currentCategory: number;
  onChangeCategory: (id: number) => () => void;
  distributorAssets: { [key: string]: string };
  categoryNameFilter: (id: number) => string;
  tempCurrentCategory: number;
}

export default function CategoryModal({
  categoryModalOpenClose,
  handleCategoryModalOpenClose,
  submitCategory,
  language,
  categoryData,
  currentCategory,
  onChangeCategory,
  distributorAssets,
  categoryNameFilter,
  tempCurrentCategory
}: CategoryModalProps) {

  function getCategoryIcon(id: number) {
    switch (id) {
      case DistributorType.fertiliser:
        return distributorAssets.fertiliserIcon;
      case DistributorType.seed:
        return distributorAssets.seedIcon;
      case DistributorType.pesticide:
        return distributorAssets.pesticideIcon;
      default:
        return distributorAssets.fertiliserIcon;
    }
  }

  const modalContent = (
    <Box className={Styles.container}>
      {categoryData ? (
        <Box className={Styles.optionsContainer}>
          {categoryData?.map((option, index) => (
            <Box
              key={option.id}
              id={`DistributorCategory${index + 1}`}
              data-testid={`DistributorCategory${index + 1}`}
              className={Styles.optionItem}
              onClick={onChangeCategory(option?.category_id)}
            >
              <Box display="flex" alignItems="center" gap={3}>
                <Box
                  sx={{
                    width: 24,
                    height: 24,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <img
                    src={getCategoryIcon(option?.category_id)}
                    alt={categoryNameFilter(option?.category_id) + 'icon'}
                    style={{ width: '100%', height: '100%' }}
                  />
                </Box>
                <Box>
                  <MuiTypography
                    level="body-md"
                    sx={{
                      color: '#1E293B',
                      fontSize: '16px',
                      fontWeight: 600,
                      display: 'inline',
                    }}
                  >
                    {categoryNameFilter(option?.category_id) || ''}
                  </MuiTypography>
                  <MuiTypography
                    level="body-md"
                    sx={{
                      color: '#64748B',
                      fontSize: '16px',
                      marginLeft: '4px',
                      display: 'inline',
                    }}
                  >
                    ({option.category_count})
                  </MuiTypography>
                </Box>
              </Box>
              <Radio
                onChange={onChangeCategory(option?.category_id)}
                checked={tempCurrentCategory === option.category_id}
                variant="outlined"
                disableIcon
                onClick={(e) => e.stopPropagation()}
                sx={{
                  width: '20px',
                  height: '20px',
                  padding: 0,
                  '--Radio-actionRadius': '50%',
                  '& .MuiRadio-action': {
                    border: '1.5px solid #CBD5E1',
                    backgroundColor: 'transparent',
                  },
                  '&.Mui-checked': {
                    '& .MuiRadio-action': {
                      border: '6px solid #059669',
                      backgroundColor: 'transparent',
                    },
                  },
                }}
              />
            </Box>
          ))}
        </Box>
      ) : (
        <SkeletonLoading
          itemNumber={3}
          sx={{
            minHeight: 40,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
            borderRadius: 8,
            margin: '12px 0',
            '& .MuiSkeleton-root': {
              borderRadius: '12px',
              height: '48px',
              width: 'auto',
              margin: '0 8px'
            }
          }}
        />
      )}

      <Box className={Styles.footer}>
        <MuiButton
          variant="solid"
          size="lg"
          fullWidth
          onClick={submitCategory}
          disabled={!(tempCurrentCategory || currentCategory)}
          id="DistributorcategoryApply"
          data-testid="DistributorcategoryApply"
          sx={{
            bgcolor: '#059669',
            color: '#FFFFFF',
            borderRadius: '8px',
            height: '44px',
            fontSize: '16px',
            fontWeight: 500,
            '&:hover': {
              bgcolor: '#047857',
            },
          }}
        >
          {language.apply}
        </MuiButton>
      </Box>
    </Box>
  );

  return (
    <Modal
      isOpen={categoryModalOpenClose}
      onClose={handleCategoryModalOpenClose}
      children={modalContent}
      title={language.selectCategory}
      id="CategoryModal"
      isCloseButtonOption={true}
      closeButtonId="DistributorCategoryCross"
      closeButtonTestId="DistributorCategoryCross"
    />
  );
}
