import {
  getAuthTokenFromStorage,
  getUserLanguageFromStorage,
  getUserProfileFromStorage,
} from '../../modal/auth/auth.model';

import { appLanguages } from '../../constant/appLanguages';

import { DEFAULT_LANGUAGE_ID } from "../../constant/language";

export const getHeaders = () => {
  const activeSystemLang = Intl.DateTimeFormat().resolvedOptions().locale;
  const systemLanguageId = activeSystemLang?.includes('en')
    ? appLanguages[1].backendLanguageCode
    : appLanguages[0].backendLanguageCode;

  const Auth = getAuthTokenFromStorage();
  let userLanguage = getUserLanguageFromStorage()?.langId ||  systemLanguageId || null;
  const userProfile = getUserProfileFromStorage() || null;

  if (!userLanguage) {
    userLanguage = DEFAULT_LANGUAGE_ID;
  }

  const headers: {
    Accept: string;
    "Content-Type": string;
    authorization?: string;
    language?: string;
    appVersion?: string;
    "fmt-installation-id"?: string;
    "fmt-device-id"?: string;
  } = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  // PLACING THE EXTRA HEADERS;
  if (userLanguage) {
    headers.language = userLanguage.toString();
  }

  if (Auth) {
    headers.authorization = `Bearer ${Auth}`;
  }

  if (userProfile?.appVersion) {
    headers.appVersion = userProfile?.appVersion;
  }

  if (userProfile?.fmtInstallationId) {
    headers["fmt-installation-id"] = userProfile.fmtInstallationId;
  }

  if (userProfile?.uniqueDeviceId) {
    headers["fmt-device-id"] = userProfile?.uniqueDeviceId;
  }

  return headers;
}

