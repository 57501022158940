const DocumentType = new Map();

DocumentType.set(1, 'invoice_docs');
DocumentType.set(2, 'weight_slip');
DocumentType.set(3, 'mandi_tax');
DocumentType.set(4, 'buyerGRN');
DocumentType.set(5, 'unloadingWeightSlip')

export const document_types = Object.freeze({
  INVOICE_DOCS: "invoice_docs",
  WEIGHT_SLIP: "weight_slip",
  MANDI_TAX: "mandi_tax",
  BUYER_GRN: "buyerGRN",
  UNLOADING_WEIGHT_SLIP: "unloadingWeightSlip"
})

export default DocumentType;
