import { Box, Alert } from '@mui/joy';
import MuiTypography from '@mono-farmart-web/farmart-web-common/ComponentV3/Typography';
import MuiButton from '@mono-farmart-web/farmart-web-common/ComponentV3/MuiButton';
import { CheckCircleRounded } from '@mui/icons-material';
interface BahiKhataTransactionHistoryHeaderProps {
  language: { [key: string]: string };
  bahiKhataLandingAssets: { [key: string]: string };
  onContactUs: () => void;
  alert: {
    message: string;
    openClose: boolean;
  };
}

export default function BahiKhataTransactionHistoryHeader(
  props: BahiKhataTransactionHistoryHeaderProps,
) {
  const { language, bahiKhataLandingAssets, onContactUs, alert } = props;
  return (
    <Box
      padding={'14.5px 16px'}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'space-between'}
      boxSizing={'border-box'}
    >
      <Box display={'flex'} alignItems={'center'}>
        <MuiTypography level="title-md" sx={{ fontFamily: 'NotoSans' }}>
          {language?.heading}
        </MuiTypography>
      </Box>
      <Box display={'flex'} gap="8px" flexDirection={'row'}>
        <Box
          display={'flex'}
          gap="4px"
          alignItems={'center'}
          padding={'4px 12px'}
          borderRadius={'100px'}
          bgcolor={'success.softBg'}
        >
          <Box>
            <img
              src={bahiKhataLandingAssets?.bahiKhataSecureIcon}
              alt="secure"
              style={{ width: '19px', height: '19px', display: 'flex' }}
            />
          </Box>
          <MuiTypography
            level="label-sm"
            color="success"
            fontFamily={'NotoSans'}
          >
            {language?.headerSecureMsg}
          </MuiTypography>
        </Box>

        <Box display={'flex'}>
          <MuiButton
            size="sm"
            color="warning"
            variant="soft"
            onClick={onContactUs}
          >
            {language?.help}
          </MuiButton>
        </Box>
      </Box>
      {alert.openClose ? (
        <Alert
          title="Success!"
          color='neutral'
          size="md"
          sx={{
            zIndex: 10000,
            boxSizing: 'border-box',
            position: 'absolute',
            '@media (min-width: 1025px) and (max-width: 1280px)': {
              width: 'calc(30vw - 32px)',
              alignSelf: 'center',
            },
            '@media (min-width: 1281px)': {
              width: 'calc(30vw - 32px)',
              alignSelf: 'center',
            },
            width: 'calc(100% - 32px)',
            borderRadius: '8px',
            marginTop: '16px',
            padding: '8px',
            gap: '8px',
          }}
          variant="solid"
        >
          <CheckCircleRounded
            sx={{ height: '24px', width: '24px', color: '#FFFFFF' }}
          />
          <MuiTypography level="body-xxs" sx={{ color: '#FFFFFF' }}>
            {alert?.message}
          </MuiTypography>
        </Alert>
      ) : null}
    </Box>
  );
}
