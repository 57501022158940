import Modal from '@mono-farmart-web/farmart-web-common/ComponentV2/Modal';
import { Box, Checkbox } from '@mui/joy';
import MuiTypography from '@mono-farmart-web/farmart-web-common/ComponentV3/Typography';
import MuiButton from '@mono-farmart-web/farmart-web-common/ComponentV3/MuiButton';
import SearchIcon from '@mui/icons-material/Search';
import Styles from './style.module.css';
import distributorType from '@mono-farmart-web/farmart-web-common/constant/enums/distributorType';
import InfiniteScroll from '@mono-farmart-web/farmart-web-common/Components/InfinityScrollV2';
import SkeletonLoading from '@mono-farmart-web/farmart-web-common/MuiComponents/Skeleton';
import SearchRounded from '@mui/icons-material/SearchRounded';

interface ProductTypeModalProps {
  language: { [key: string]: string };
  productTypeModalOpenClose: boolean;
  handleProductTypeModalOpenClose: () => void;
  productTypeData: any;
  currentCategory: number;
  onChangeProductType: (id: number) => () => void;
  fetchMoreProductData: () => void;
  productTypeLoading: boolean;
  handleSearchProductType: (query: string) => void;
  cearAllSlectedProductType: () => void;
  tempCurrentProductType: number[];
  submitProductType: () => void;
  productTypeSearchParam: string;
  distributorAssets: { [key: string]: string };
  productTypeCount: number;
}

export default function ProductTypeModal({
  language,
  productTypeModalOpenClose,
  handleProductTypeModalOpenClose,
  productTypeData,
  currentCategory,
  onChangeProductType,
  fetchMoreProductData,
  productTypeLoading,
  handleSearchProductType,
  cearAllSlectedProductType,
  tempCurrentProductType,
  submitProductType,
  productTypeSearchParam,
  distributorAssets,
  productTypeCount,
}: ProductTypeModalProps) {
  const modalContent = (
    <Box className={Styles.container}>
      {currentCategory === distributorType?.fertiliser && (
        <Box className={Styles.searchContainer}>
          <Box className={Styles.searchInputWrapper}>
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              sx={{
                width: '24px',
                height: '24px',
                marginLeft: '12px',
              }}
            >
              <SearchRounded sx={{ color: '#9FA6AD' }} />
            </Box>
            <input
              type="text"
              placeholder={language.searchByFertilizerName}
              value={productTypeSearchParam}
              onChange={(e) => handleSearchProductType(e.target.value)}
              className={Styles.searchInput}
              id="DistributorProductSearch"
              data-testid="DistributorProductSearch"
            />
          </Box>
        </Box>
      )}

      <Box
        className={Styles.optionsContainer}
        sx={{ flex: 1 }}
        id="productTypeScrollContainer"
      >
        {!productTypeLoading &&
        productTypeData &&
        productTypeData?.length === 0 ? (
          <Box
            className={Styles.noResults}
            id="DistributorProductNoResults"
            data-testid="DistributorProductNoResults"
          >
            <Box className={Styles.noResultsContent}>
              <img
                src={distributorAssets.noProductTypeModalImg}
                alt="No product types"
                className={Styles.noResultsImage}
              />
              <MuiTypography
                level="body-md"
                id="DistributorProductNoResultsText"
                data-testid="DistributorProductNoResultsText"
                sx={{
                  color: 'var(--Light-Neutrals-N-600, #475569)',
                  textAlign: 'center',
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '20px',
                }}
              >
                {language.noFertiliserTypeFound}
              </MuiTypography>
            </Box>
          </Box>
        ) : (
          <InfiniteScroll
            dataLength={productTypeData?.length || 0}
            next={fetchMoreProductData}
            hasMore={productTypeData?.length < productTypeCount}
            scrollableTarget="productTypeScrollContainer"
            loader={
              <SkeletonLoading
                itemNumber={5}
                sx={{
                  minHeight: 40,
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 8,
                  borderRadius: 8,
                  margin: '18px 0',
                  '& .MuiSkeleton-root': {
                    borderRadius: '12px',
                    height: '48px',
                    width: 'auto',
                    margin: '0 8px',
                  },
                }}
              />
            }
          >
            {productTypeData &&
              productTypeData?.map((type, index) => (
                <Box
                  key={`DistributorProduct${index + 1}`}
                  id={`DistributorProduct${index + 1}`}
                  data-testid={`DistributorProduct${index + 1}`}
                  className={Styles.optionItem}
                  onClick={onChangeProductType(type?.product_id)}
                >
                  <MuiTypography
                    level="body-md"
                    sx={{
                      color: '#1E293B',
                      fontSize: '16px',
                      fontWeight: 400,
                    }}
                  >
                    {type.product_name}
                  </MuiTypography>
                  <Box
                    display={'flex'}
                    justifyContent={'flex-end'}
                    marginTop={'4px'}
                    marginRight={'4px'}
                  >
                    <Checkbox
                      onChange={onChangeProductType(type?.product_id)}
                      checked={tempCurrentProductType.includes(
                        type?.product_id,
                      )}
                      onClick={(e) => e.stopPropagation()}
                    />
                  </Box>
                </Box>
              ))}
          </InfiniteScroll>
        )}
      </Box>
      {productTypeData?.length > 0 && (
        <Box className={Styles.footer}>
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              width: '100%',
              padding: '4px 0',
            }}
          >
            <MuiButton
              variant="outlined"
              color="neutral"
              disabled={tempCurrentProductType.length === 0}
              fullWidth
              onClick={cearAllSlectedProductType}
              id="DistributorProductClear"
              data-testid="DistributorProductClear"
              sx={{ borderRadius: '8px', height: '44px' }}
            >
              {language.clearAll}
            </MuiButton>
            <MuiButton
              variant="solid"
              size="lg"
              fullWidth
              onClick={submitProductType}
              disabled={tempCurrentProductType.length === 0}
              id="DistributorProductApply"
              data-testid="DistributorProductApply"
              sx={{
                bgcolor: '#059669',
                color: '#FFFFFF',
                borderRadius: '8px',
                height: '44px',
                fontSize: '16px',
                fontWeight: 500,
                '&:hover': { bgcolor: '#047857' },
              }}
            >
              {language.apply}
            </MuiButton>
          </Box>
        </Box>
      )}
    </Box>
  );

  return (
    <Modal
      isOpen={productTypeModalOpenClose}
      onClose={handleProductTypeModalOpenClose}
      children={modalContent}
      title={language.selectProductType}
      id="ProductTypeModal"
      isCloseButtonOption={true}
      closeButtonId="DistributorProductCross"
      closeButtonTestId="DistributorProductCross"
    />
  );
}
