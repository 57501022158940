import GenerateImageURL from "../../../apis/bahikhata/generateImageUrl";

// import type { CreateDispatchPayload, CreateDispatchResponse } from "../types";

export default async function generateImageUrl(
  payload: any,
): Promise<any> {
  const body = {
    ...payload,
  };
  try {
    const result = await GenerateImageURL(body);

    if (!result.data.status) {
      throw result;
    }

    return result.data.data;
  } catch (error) {
    if (!error.message) {
      error.message = "Something Went Wrong";
    }

    throw error;
  }
}
