import { apiEndPoints } from "@mono-farmart-web/farmart-web-common/apis/endpoints";
import { trackEvent } from "@mono-farmart-web/farmart-web-common/utils/moengage";
import Events from "@mono-farmart-web/farmart-web-common/constant/MoengageEvents/index";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import getLang from '@mono-farmart-web/farmart-web-common/languages/addNewKhata.js';
import { languageUrls } from "@mono-farmart-web/farmart-web-common/constant/languageUrls";
import useAppLanguage from "@mono-farmart-web/farmart-web-common/utils/applanguage/index.js";
import useAuthenticationChecker from "@mono-farmart-web/farmart-web-common/utils/authCheckerV2";
import FeatureLocationId from '@mono-farmart-web/farmart-web-common/constant/enums/FeatureLocationId.js';

const useAddNewKhata = (username: string | undefined) => {
  const { AuthenticationCheckerApiCaller } = useAuthenticationChecker();
  const navigate = useNavigate();
  const { language } = useAppLanguage(
    languageUrls?.bahiKhataAddKhataLangUrl,
    getLang,
  );
  const [userDetails, setUserDetails] = useState({
    username: username ?? "",
    contactNumber: ""
  });
  const [isSavingDetails, setIsSavingDetails] = useState(false);
  const [isErrorToast, setIsErrorToast] = useState(false);

  const handleNameChange = (event) => {
    const { value } = event.target;

    const sanitizedValue = value
      .replace(/[^(\u0900-\u097F\u0980-\u09FFa-zA-Z0-9\s\-'._)]/g, '')
      .slice(0, 30);
    trackEvent(Events?.onBahiKhataNameInputBoxClick, {});
    setUserDetails({
      ...userDetails,
      username: sanitizedValue
    });
  }

  const formatPhoneNumber = (input) => {

    let digitsOnly = input.replace(/\D/g, '');

    if (digitsOnly.startsWith('91') && digitsOnly.length > 2) {
      digitsOnly = digitsOnly.slice(2);
    }

    return digitsOnly;
  };

  const handleContactChange = (event) => {
    const { value } = event.target;
    const formattedNumber = formatPhoneNumber(value);

    if (value.length < 10 || formattedNumber.length <= 10) {
      const finalNumber = formattedNumber.slice(0, 10);
      setUserDetails({
        ...userDetails,
        contactNumber: finalNumber
      });
    }

    trackEvent(Events?.onBahiKhataContactNumberInputBoxClick, {})

  }

  const handleCreateNewTransaction = async () => {
    setIsSavingDetails(true);

    trackEvent(Events?.onBahiKhataSaveNewKhataButtonClick, {
      "Screen_name": "Record New Transaction"
    });

    const payload = {
      feature_id: FeatureLocationId?.bahi_khata,
      farmer_to_add: [
        {
          name: userDetails.username,
          mobile_number: userDetails.contactNumber
        }
      ]
    };

    try {
      const response = await AuthenticationCheckerApiCaller({
        uri: apiEndPoints?.createNewFarmer,
        requestType: "post",
        payload: payload,
      });

      if(response?.data?.data?.farmers_added?.length) {
        setIsSavingDetails(false);
        const farmerData = response?.data?.data?.farmers_added?.[0];
        navigate(`/bahiKhata/${farmerData?.id}`);
      } else {
        setIsSavingDetails(false);
        setIsErrorToast(true);

        setTimeout(() => {
          setIsErrorToast(false);
        }, 3000);
      }
    } catch(error) {
      console.error("Error:", error);
      setIsSavingDetails(false);
    }
  }

  return {
    userDetails,
    handleNameChange,
    handleContactChange,
    handleCreateNewTransaction,
    isSavingDetails,
    isErrorToast,
    language
  }
}

export default useAddNewKhata;
