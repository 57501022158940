import React, { useState, useEffect, useRef, useCallback } from 'react';
import { VariableSizeList as List } from 'react-window';
import debounce from 'lodash/debounce';
import { Box, Row } from "@mono-farmart-web/farmart-web-common/MuiComponents/atoms/layout";
import { Text } from "@mono-farmart-web/farmart-web-common/MuiComponents/atoms/typography";

const ListRow = ({ index, style, data }) => {
  const {
    combinedItems,
    renderItem,
    recentKhataList,
    searchQuery,
    navigate,
    colors,
    language,
    measureRef // Add ref for measurement
  } = data;

  const item = combinedItems[index];
  if (!item) return null;

  if (item.type === 'header') {
    return (
      <Box
        ref={measureRef}
        sx={{
          ...style,
          padding: '8px 0px',
          position: 'absolute', // Add absolute positioning
          width: '100%'
        }}
      >
        <Text
          level="title-sm"
          fontWeight={600}
          textColor="text.primary"
        >
          {item.content}
        </Text>
      </Box>
    );
  }

  if (item.type === 'recent') {
    return (
      <Box
        ref={measureRef}
        sx={{
          ...style,
          padding: '0',
          position: 'absolute', // Add absolute positioning
          width: '100%'
        }}
      >
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(4, minmax(76px, 1fr))',
            rowGap: 6,
            columnGap: 2,
            alignItems: 'flex-start',
            justifyContent: 'center',
            width: '100%',
            overflowX: 'hidden',
            py: 3
          }}
        >
          {item.items.map((khata, idx) => (
            <Box
              key={khata?.id}
              onClick={() => navigate(`/bahiKhata/${khata?.id}`)}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer'
              }}
            >
              <Row
                width="40px"
                height="40px"
                bgcolor={colors[idx % colors.length]}
                justifyContent="center"
                alignItems="center"
                color="primary.solidColor"
                borderRadius="50%"
                flexShrink={0}
              >
                {khata?.farmer_name?.[0]}
              </Row>
              <Text
                level="body-xs"
                fontWeight={600}
                textColor="text.secondary"
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  width: '100%',
                  textAlign: 'center'
                }}
              >
                {khata?.farmer_name}
              </Text>
            </Box>
          ))}
        </Box>
      </Box>
    );
  }

  const adjustedIndex = searchQuery || !recentKhataList?.length
    ? index
    : index - 3;

  return renderItem({
    index: adjustedIndex,
    style: {
      ...style,
      height: '72px',
      position: 'absolute', // Add absolute positioning
      width: '100%'
    }
  });
};

// Separate size calculation logic
const calculateItemSize = (item, recentItemsCount) => {
  if (!item) return 72;

  switch (item.type) {
    case "header":
      return 40; // Increased to account for padding
    case 'recent':
      // Calculate rows needed based on item count (4 items per row)
      const rows = Math.ceil(recentItemsCount / 4);
      return (rows * 80) + 32; // 80px per row + padding
    case 'contact':
    default:
      return 72;
  }
};

const DynamicContactList = ({
  items,
  renderItem,
  onScroll,
  isWeb,
  cPermission,
  searchQuery,
  bottomActionHeight = 125,
  headerHeight = 64,
  searchBarHeight = 72,
  recentKhataList = [],
  colors,
  navigate,
  language
}) => {
  const [listHeight, setListHeight] = useState(500);
  const containerRef = useRef(null);
  const listRef = useRef(null);
  const measureRef = useRef(null);
  const [itemSizes, setItemSizes] = useState(new Map());

  const calculateHeight = useCallback(() => {
    const viewportHeight = window.innerHeight;
    const totalStaticHeight = headerHeight + searchBarHeight;
    const shouldShowBottomAction = isWeb || (!isWeb && !cPermission);
    const bottomHeight = shouldShowBottomAction ? bottomActionHeight : 0;
    const availableHeight = viewportHeight - totalStaticHeight - bottomHeight;

    setListHeight(Math.max(400, availableHeight)); // Set minimum height
  }, [headerHeight, searchBarHeight, bottomActionHeight, isWeb, cPermission]);

  useEffect(() => {
    calculateHeight();
    const handleResize = debounce(calculateHeight, 100);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [calculateHeight]);

  const combinedItems = React.useMemo(() => {
    if (searchQuery || !recentKhataList?.length) {
      return items.map(item => ({ type: 'contact', ...item }));
    }

    return [
      { type: 'header', id: 'recent-header', content: language?.recentKhataHeading },
      { type: 'recent', id: 'recent-items', items: recentKhataList?.slice(0, 8) },
      { type: 'header', id: 'saved-header', content: language?.savedKhataHeading },
      ...items.map(item => ({ type: 'contact', ...item }))
    ];
  }, [items, recentKhataList, searchQuery, language]);

  // Reset list when items change
  useEffect(() => {
    if (listRef.current) {
      listRef.current.resetAfterIndex(0);
    }
  }, [combinedItems]);

  const getSize = useCallback((index) => {
    const item = combinedItems[index];
    return calculateItemSize(item, recentKhataList?.length || 0);
  }, [combinedItems, recentKhataList]);

  const debouncedScroll = useCallback(
    debounce(({ scrollOffset }) => {
      if (onScroll) onScroll({ scrollOffset });
    }, 100),
    [onScroll]
  );

  const itemData = {
    combinedItems,
    renderItem,
    recentKhataList,
    searchQuery,
    navigate,
    colors,
    language,
    measureRef
  };

  return (
    <Box
      ref={containerRef}
      sx={{
        width: '100%',
        height: listHeight,
        position: 'relative',
        '& > div': {
          '&::-webkit-scrollbar': {
            display: 'none'
          },
          '-ms-overflow-style': 'none',
          'scrollbarWidth': 'none'
        }
      }}
    >
      <List
        ref={listRef}
        height={listHeight}
        itemCount={combinedItems.length}
        itemSize={getSize}
        width="100%"
        onScroll={debouncedScroll}
        itemData={itemData}
        overscanCount={5} // Increase overscan to prevent flickering
      >
        {ListRow}
      </List>
    </Box>
  );
};

export default DynamicContactList;
