import React from 'react'
import MySaudaView from '../../Pages/mySauda/mySauda';
import getMySaudaList from '../../hooks/MySauda/getMySaudaList';

export default function mySauda() {
  const {
    onCloseSaudaStatusModal,
    saudaModalVisible,
    onSelectFilterStatus,
    selectedFilterStatus,
    mySaudaList,
    fetchMoreSaudaList,
    mySaudaLoading,
    onClickOpenCloseTab,
    selectedOpenCloseTab,
    onClickAcceptPo,
    onClickBack,
    onClickSaudaOrderCard,
    language,
    isServiceAvailable,
    mySaudaAssets,
    error,
    onClickRetry,
    onClickHelp,
    onContactUs,
    mySaudaTotalRecords,
  } = getMySaudaList();
  return (
    <MySaudaView
      onCloseSaudaStatusModal={onCloseSaudaStatusModal}
      saudaModalVisible={saudaModalVisible}
      onSelectFilterStatus={onSelectFilterStatus}
      selectedFilterStatus={selectedFilterStatus}
      mySaudaList={mySaudaList}
      fetchMoreSaudaList={fetchMoreSaudaList}
      mySaudaLoading={mySaudaLoading}
      onClickOpenCloseTab={onClickOpenCloseTab}
      selectedOpenCloseTab={selectedOpenCloseTab}
      onClickAcceptPo={onClickAcceptPo}
      onClickBack={onClickBack}
      onClickSaudaOrderCard={onClickSaudaOrderCard}
      language={language}
      isServiceAvailable={isServiceAvailable}
      mySaudaAssets={mySaudaAssets}
      error={error}
      onClickHelp={onClickHelp}
      onClickRetry={onClickRetry}
      onContactUs={onContactUs}
      mySaudaTotalRecords={mySaudaTotalRecords}
    />
  );
}
