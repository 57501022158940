import { Box, FormControl, FormLabel } from '@mui/joy';
import { AddPhotoAlternate, PersonRounded } from '@mui/icons-material';
import Header from './Header/Header'; // Importing the Header component.
import Footer from './Footer/Footer'; // Importing the Footer component.
import ModalContent from './EditProfileModal/EditProfileModaContent'; // Importing the ModalContent component for the edit profile modal.
import Modal from '@mono-farmart-web/farmart-web-common/ComponentsV4/Modal'; // Importing a generic Modal component for use in the UI.
import { EditProfileScreenProps } from '../../hooks/EditProfile/types'; // Importing type definitions for props.
import Styles from './style.module.css'; // Importing CSS module for styling.
import MuiTextarea from '@mono-farmart-web/farmart-web-common/MuiComponents/MuiTextarea'; // Importing a customized MUI Textarea component.
import Toast from '@mono-farmart-web/farmart-web-common/ComponentV2/Toast'; // Importing a Toast component for displaying error messages.
import { userType } from '@mono-farmart-web/farmart-web-common/constant/enums/userType';
import Loading from'@mono-farmart-web/farmart-web-common/ComponentV2/Loading';

const EditProfile = ({
  onClickBackEditProfile, // Function to handle back navigation.
  submitEditProfile, // Function to submit the profile form.
  onClickEditProfilePic, // Function to handle profile picture edit action.
  onChangeName, // Function to handle changes to the name field.
  onChangeShopName, // Function to handle changes to the shop name field.
  showEditPhotoModal, // Boolean to control the display of the edit photo modal.
  onCloseEditModal, // Function to close the edit photo modal.
  openCamera, // Function to open the camera for taking a new profile picture.
  openGallery, // Function to open the gallery for selecting a new profile picture.
  language, // Object containing localized strings.
  merchantName, // Current merchant's name.
  merchantShopName, // Current merchant's shop name.
  merchantImage, // URL of the current merchant's profile image.
  error, // Error object for handling errors.
  occupation, // Merchant's occupation ID.
  onClickMerchantName, // Function to handle clicks on the merchant's name input field.
  onClickMerchantShopName, // Function to handle clicks on the merchant's shop name input field.
  loading, // Loading state for form submission.
}: EditProfileScreenProps) => {
  return (
    <Box className={Styles.container}>
      {loading ? (
        <Loading size={30} color="grey" marginTop={16} />
      ) : (
        <>
          {/* Header component with back navigation and language support */}
          <Header
            onClickBackEditProfile={onClickBackEditProfile}
            language={language}
          />
          <Box className={Styles.content}>
            {/* Profile picture section with support for adding or changing the picture */}
            <Box className={Styles.profilePicContainer}>
              <Box
                className={Styles.profilePic}
                onClick={onClickEditProfilePic}
                id={'onCLickEditProfilePicId'}
                data-testid="onCLickEditProfilePicTestId"
              >
                {/* Displaying the merchant's profile picture or a default icon */}
                {merchantImage ? (
                  <img
                    src={merchantImage}
                    className={Styles.profilePic}
                    alt="merchantImage"
                    loading="eager"
                  />
                ) : (
                  <PersonRounded />
                )}
              </Box>
              <Box
                className={Styles.overlapping}
                onClick={onClickEditProfilePic}
                id={'onCLickEditProfilePicAddIconId'}
              >
                <AddPhotoAlternate />
              </Box>
            </Box>
            {/* Name input field */}
            <FormControl>
              <Box className={Styles.name}>
                <FormLabel>{language?.userName}</FormLabel>
                <MuiTextarea
                  placeholder={'Name'}
                  onChange={(event) => {
                    onChangeName(event?.target?.value);
                  }}
                  id={'onChangeNameId'}
                  data-testid="onChangeNameTestId"
                  variant="outlined"
                  value={merchantName}
                  className={Styles?.input}
                  color="neutral"
                  onClick={onClickMerchantName}
                />
              </Box>
            </FormControl>
            {/* Shop name input field */}
            {occupation !== userType['farmer'] && (
              <FormControl>
                <Box className={Styles.shopName}>
                  <FormLabel>{language?.occupationName}</FormLabel>
                  <MuiTextarea
                    placeholder={language?.occupationName}
                    onChange={(event) => {
                      onChangeShopName(event?.target?.value);
                    }}
                    id={'onChangeShopNameId'}
                    data-testid="onChangeShopNameTestId"
                    variant="outlined"
                    value={merchantShopName}
                    className={Styles?.input}
                    onClick={onClickMerchantShopName}
                  />
                </Box>
              </FormControl>
            )}
          </Box>
          {/* Modal for editing the profile picture */}
          <Modal
            isOpen={showEditPhotoModal}
            onClose={onCloseEditModal}
            id={'editProfileModalId'}
            data-testid="editProfileModalTestId"
          >
            <ModalContent
              closeModal={onCloseEditModal}
              openCamera={openCamera}
              openGallery={openGallery}
              language={language}
            />
          </Modal>
          {/* Footer component with submit functionality */}
          <Footer submitEditProfile={submitEditProfile} language={language} />
          {error?.message && (
            <Toast
              message={error?.message}
              isError={true}
              isOpen={error?.status ? false : true}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default EditProfile;
