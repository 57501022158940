import SMSLandingScreen from '../../Pages/SMSLandingScreen/SMSLandingScreen';
import SMSLandingScreenHook from '../../hooks/SMSLandingScreen/SMSLandingScreenHook';

export default function SMSLanding() {
  const {
    language,
    loading,
    totalSMSCredit,
    noOfDays,
    noOfSMSSent,
    monthlyFreeSMS,
    promotionalMedia,
    onClickViewSMSHistory,
    smsTemplateTypes,
    showShopPromotionModal,
    onCloseModal,
    onClickBackSMSLanding,
    onClickHelpSMSLanding,
    onClickInfoSMSLanding,
    onClickSMSTemplateCard,
    onClickPromtionalMediaCard,
    showMediaModal,
    onCloseMediaModal,
    onClickContactSupport,
    isRetailer,
    onChangeInput,
    onCloseProgressiveModal,
    onCompleteUserInfo,
    progressiveModalVisible,
    merchantDetail,
    merchantData,
    smsLandingScreenAssets,
  } = SMSLandingScreenHook();
  return (
    <SMSLandingScreen
      language={language}
      loading={loading}
      totalSMSCredit={totalSMSCredit}
      noOfDays={noOfDays}
      noOfSMSSent={noOfSMSSent}
      monthlyFreeSMS={monthlyFreeSMS}
      promotionalMedia={promotionalMedia}
      onClickViewSMSHistory={onClickViewSMSHistory}
      smsTemplateTypes={smsTemplateTypes}
      showShopPromotionModal={showShopPromotionModal}
      onCloseModal={onCloseModal}
      onClickBackSMSLanding={onClickBackSMSLanding}
      onClickHelpSMSLanding={onClickHelpSMSLanding}
      onClickInfoSMSLanding={onClickInfoSMSLanding}
      onClickSMSTemplateCard={onClickSMSTemplateCard}
      onClickPromtionalMediaCard={onClickPromtionalMediaCard}
      showMediaModal={showMediaModal}
      onCloseMediaModal={onCloseMediaModal}
      onClickContactSupport={onClickContactSupport}
      isRetailer={isRetailer}
      onChangeInput={onChangeInput}
      onCompleteUserInfo={onCompleteUserInfo}
      onCloseProgressiveModal={onCloseProgressiveModal}
      progressiveModalVisible={progressiveModalVisible}
      merchantDetail={merchantDetail}
      merchantData={merchantData}
      smsLandingScreenAssets={smsLandingScreenAssets}
    />
  );
}
