import { Box, Skeleton } from '@mui/joy';

export default function BalanceContainerLoading() {
  return (
    <Box
      display={'flex'}
      padding={'var(--4, 16px)'}
      flexDirection={'column'}
      alignItems={'flex-start'}
      gap={'16px'}
      alignSelf={'stretch'}
      boxSizing={'border-box'}
    >
      <Skeleton
        sx={{
          position: 'relative',
          height: '20px',
          width: '129px',
          borderRadius: 'var(--radius-sm, 4px)',
        }}

      />
      <Skeleton
        sx={{
          position: 'relative',
          height: '20px',
          width: '100%',
          borderRadius: 'var(--radius-sm, 4px)',
        }}
      />
      
    </Box>
  );
}
