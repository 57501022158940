import { Box, Drawer, Input } from '@mui/joy';
import useBottomDrawer from '../../../hooks/BottomDrawer/useBottomDrawer';
import MuiTypography from '@mono-farmart-web/farmart-web-common/ComponentV3/Typography';
import { CloseRounded } from '@mui/icons-material';
import MuiButton from '@mono-farmart-web/farmart-web-common/ComponentV3/MuiButton';

export default function EditAmountModal({
  onClickEditAmountModal,
  editAmountModalOpenClose,
  amount,
  editAmount,
  onChangeAmount,
  saveEditedAmount,
  language,
  onClickAmountModalInput,
}) {
  const { drawerProps } = useBottomDrawer();
  return (
    <Drawer
      {...drawerProps}
      sx={{ zIndex: 10000 }}
      slotProps={{
        backdrop: {
          sx: {
            backdropFilter: 'blur(0.1px)', // Optional blur effect
          },
        },
        content: {
          sx: {
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            alignItems: 'center',
            borderRadius:
              'var(--radius-xl, 16px) var(--radius-xl, 16px) 0px 0px',
            background: 'var(--background-body, #FFF)',
            height: 'auto',
            boxSizing: 'border-box',
            '@media (min-width: 1025px) and (max-width: 1280px)': {
              width: '30vw',
              margin: '0vw 35vw',
            },
            '@media (min-width: 1281px)': {
              width: '30vw',
              margin: '0vw 35vw',
            },
          },
        },
      }}
      open={editAmountModalOpenClose}
      onClose={onClickEditAmountModal}
    >
      <Box
        sx={{
          display: 'flex',
          padding: 'var(--4, 16px)',
          justifyContent: 'space-between',
          alignItems: 'center',
          alignSelf: 'stretch',
          borderBottom:
            '1px solid var(--neutral-outlined-DisabledBorder, #DDE7EE)',
          boxSizing: 'border-box',
        }}
      >
        <MuiTypography level="title-md" sx={{ color: '#171A1C' }}>
          {language?.editAmount}
        </MuiTypography>
        <CloseRounded
          onClick={onClickEditAmountModal}
          sx={{ height: '24px', width: '24px', color: '#636B74' }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          padding: 'var(--4, 16px)',
          flexDirection: 'column',
          alignItems: 'flex-end',
          gap: 'var(--1, 4px)',
          alignSelf: 'stretch',
          boxSizing: 'border-box',
        }}
      >
        <Input
          autoComplete="off"
          onClick={onClickAmountModalInput}
          id="bahikhata_EditAmountInput"
          data-testid="bahikhata_EditAmountInput"
          placeholder={amount?.toLocaleString('en-IN')}
          startDecorator={
            <MuiTypography level="title-md" sx={{ color: '#171A1C' }}>
              ₹
            </MuiTypography>
          }
          onChange={(e) => onChangeAmount(e.target.value)}
          value={editAmount?.toLocaleString('en-IN')}
          size="lg"
          color="neutral"
          sx={{
            width: '100%',
            display: 'flex',
            borderRadius: '8px',
          }}
          slotProps={{
            input: {
              sx: {
                ':focus': {
                  border: 'none',
                  outline: 'none',
                },
              },
            },
          }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          padding: 'var(--4, 16px)',
          alignItems: 'flex-start',
          gap: 'var(--5, 20px)',
          alignSelf: 'stretch',
          boxSizing: 'border-box',
        }}
      >
        <MuiButton
          size="lg"
          color="primary"
          disabled={!editAmount}
          sx={{ borderRadius: '8px' }}
          onClick={saveEditedAmount}
          id="bahikhata_EditAmountSave"
          data-testid="bahikhata_EditAmountSave"
        >
          {language?.save}
        </MuiButton>
      </Box>
    </Drawer>
  );
}
