import React from 'react';
import { Box } from '@mui/joy';
import Header from './Header';
import Loading from '@mono-farmart-web/farmart-web-common/ComponentV2/Loading';
import SMSDetailsCard from './SMSDetailsCard';
import ActionContainer from './ActionContainer';
import SingleSelectModal from './SingleSelectModal';
import RateModal from './RateModal';
import Footer from './Footer';
import Styles from './style.module.css';
import { SMSTemplateScreenProps } from '../../hooks/SMSTemplateHook/types';

export default function SMSTemplateScreen({
  language,
  smsTypeId,
  loading,
  headerTitle,
  onClickBack,
  remainingSMS,
  smsTemplateMessage,
  onClickSend,
  inputList,
  inputValues,
  setInputValues,
  showRateModal,
  showSingleSelectModal,
  singleSelectLoading,
  onCloseRateModal,
  handleInputChange,
  handleInputClick,
  allInputsFilled,
  inputVariables,
  handleDateChange,
  handleRadioButtonSelection,
  handleTextareaChange,
  radioselectedValue,
  calendarVisibility,
  setCalendarVisibility,
  dateStates,
  handleSelectedItem,
  handleModalClose,
  searchTerm,
  setSearchTerm,
  filteredItems,
  setPage,
  loadedCount,
  totalCount,
}: SMSTemplateScreenProps) {
  return (
    <Box className={Styles.container}>
      <Header headerTitle={headerTitle} onClickBack={onClickBack} />
      {loading ? (
        <Loading size={30} color="grey" marginTop={20} />
      ) : (
        <>
          <Box sx={{ overflowY: 'auto', flexGrow: 1 }} paddingBottom={45}>
            <SMSDetailsCard
              headerTitle={headerTitle}
              smsDetailText={smsTemplateMessage}
              inputValues={inputValues}
              inputVariables={inputVariables}
            />
            <ActionContainer
              inputList={inputList}
              inputValues={inputValues}
              handleInputChange={handleInputChange}
              handleInputClick={handleInputClick}
              smsTypeID={smsTypeId}
              handleDateChange={handleDateChange}
              handleRadioButtonSelection={handleRadioButtonSelection}
              handleTextareaChange={handleTextareaChange}
              radioselectedValue={radioselectedValue}
              calendarVisibility={calendarVisibility}
              setCalendarVisibility={setCalendarVisibility}
              dateStates={dateStates}
            />
            <SingleSelectModal
              showSingleSelectModal={showSingleSelectModal}
              smsTypeId={smsTypeId}
              singleSelectLoading={singleSelectLoading}
              handleSelectedItem={handleSelectedItem}
              handleModalClose={handleModalClose}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              filteredItems={filteredItems}
              language={language}
              setPage={setPage}
              loadedCount={loadedCount}
              totalCount={totalCount}
            />
            <RateModal
              showRateModal={showRateModal}
              onCloseRateModal={onCloseRateModal}
              smsTypeId={smsTypeId}
              inputValues={inputValues}
              setInputValues={setInputValues}
              language={language}
            />
          </Box>
          <Footer
            language={language}
            onClickSend={onClickSend}
            remainingSMS={remainingSMS}
            allInputsFilled={allInputsFilled}
          />
        </>
      )}
    </Box>
  );
}
