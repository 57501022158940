import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SmsAcknowledgementProps } from './types';
import { getLanguageService } from '@mono-farmart-web/farmart-web-common/utils/getLanguageService';
import { languageUrls } from '@mono-farmart-web/farmart-web-common/constant/languageUrls/index';
import { DEFAULT_LANGUAGE } from '@mono-farmart-web/farmart-web-common/constant/language';
import getLang from '@mono-farmart-web/farmart-web-common/languages/SmsAcknowledgement.js';

export default function SmsAcknowledgementHooks(): SmsAcknowledgementProps {
  const { state } = useLocation();
  const { smsSent, smsBalance, smsId } = state;
  const [languageFile, setLanguageFile] = useState({});

  const language = languageFile ?? getLang(DEFAULT_LANGUAGE); // Getting language file based on language ID

  const navigate = useNavigate();

  // Function to handle back button press
  /**
   * The onPressBack function navigates to the "/myFarmers" route with state information indicating it
   * was triggered from the "addFarmer" action.
   */
  function onPressSendNewSms() {
    navigate('/shopPromotion');
  }

  useEffect(() => {
    getLanguageJson();
  }, []);

  async function getLanguageJson() {
    try {
      const url = languageUrls?.smsAcknowledgementScreenLangageUrl;
      const result = await getLanguageService(url);
      setLanguageFile(result);
    } catch (error) {
      console.log('object');
    } finally {
      console.log('object');
    }
  }

  /**
   * The function `onContactUs` navigates to the "/faq/questions" page.
   */
  /**
   * The `onContactUs` function navigates to the "/faq/questions" page.
   */
  function onContactUs() {
    navigate('/faq/questions');
  }

  // Returning necessary functions and states
  return {
    onPressSendNewSms,
    language,
    onContactUs,
    smsSent,
    smsBalance,
    smsId,
  };
}
