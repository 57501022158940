import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import reportWebVitals from './reportWebVitals';
import ErrorBoundary from "@mono-farmart-web/farmart-web-common/Components/ErrorBoundary";
/* The `import` statement you provided is importing specific named exports from the
`"react-router-dom"` library in a single line. Here's a breakdown of what each named export is used
for: */
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import { PlatformContextProvider } from '@mono-farmart-web/farmart-web-common/context/CrossPlatformActionHandler/platformChecker';
import { CssVarsProvider } from '@mui/joy/styles';

import { store } from '@mono-farmart-web/farmart-web-common/store';
import { Provider } from 'react-redux';
import '@mono-farmart-web/farmart-web-common/fonts/NotoSansRegular.ttf';
import '@mono-farmart-web/farmart-web-common/fonts/NotoSansDevanagari.ttf';
import MuiTheme from '@mono-farmart-web/farmart-web-common/constant/MuiTheme';
import { AuthProvider } from '@mono-farmart-web/farmart-web-common/context/login';
import * as Sentry from '@sentry/react';
import { LocationProvider } from '@mono-farmart-web/farmart-web-common/context/location';
import { LanguageProvider } from '@mono-farmart-web/farmart-web-common/context/language';

Sentry.init({
  dsn: 'https://ef3c214d4b7f018751830206776ffc1e@o1094278.ingest.us.sentry.io/4507191464099840',
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 0.01,
  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.01,
  replaysOnErrorSampleRate: 0.01,
  environment: import.meta.env.VITE_ENV,
});

const onErrorCatched = (error: Error) => {
  const options = {
    autoReload: false,
    maxRetries: 1
  }
  if (error.message.includes('Failed to fetch dynamically imported module') || error.message.includes("Importing a module script failed")) {
    options.autoReload = true;
    options.maxRetries = 0;
  }

  return options;
}

/* The code snippet you provided is setting up the main entry point for a React application using
TypeScript. Let's break down what the code is doing: */
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <PlatformContextProvider>
        <CssVarsProvider theme={MuiTheme}>
          <LanguageProvider>
            <AuthProvider>
              <LocationProvider>
                <ErrorBoundary options={{onErrorCatched}} >
                  <App />
                </ErrorBoundary>
              </LocationProvider>
            </AuthProvider>
          </LanguageProvider>
        </CssVarsProvider>
      </PlatformContextProvider>
    </Provider>
  </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
