import getConfigByKeys from "../../config/config";
import { configKeys } from "../../config/types";
import handleHttpError from "../../utils/api/httpErrorMapping";
import HttpStatusCode from "../types/httpStatusCodesEnums.type";
import type UnifiedResponse from "../types/unifiedResponse.type";

import { DEFAULT_LANGUAGE_ID } from "../../constant/language";

import {
	getAuthTokenFromStorage,
	getUserLanguageFromStorage,
} from "../../modal/auth/auth.model";
import { apiEndPoints } from "../endpoints";
import { appLanguages } from "../../constant/appLanguages";

const { API_URL } = getConfigByKeys([configKeys.API_URL]);

export default async function getMerchantData(
	payload,
	token: string,
): Promise<UnifiedResponse> {
	const Auth = getAuthTokenFromStorage();

	// thrwong error because at this point of time
	// the user should be logged in
	if (!Auth) throw new Error("Auth token not found");
	const activeSystemLang = Intl.DateTimeFormat().resolvedOptions().locale;
	const systemLanguageId = activeSystemLang?.includes('en')
	  ? appLanguages[1].backendLanguageCode
	  : appLanguages[0].backendLanguageCode;
  
	const userLanguage =
	  getUserLanguageFromStorage()?.langId ||
	  systemLanguageId ||
	  DEFAULT_LANGUAGE_ID;

	const path = apiEndPoints.fetchMerchantProfile;

	const response = await fetch(`${API_URL}${path}`, {
		method: "post",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			authorization: "Bearer " + Auth,
			language: userLanguage.toString(),
		},
		body: JSON.stringify(payload),
	});

	if (response.status !== HttpStatusCode.Ok) {
		throw handleHttpError(response.status, path);
	}

	const data = await response.json();
	return { data, response };
}
