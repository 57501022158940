import { useEffect, useState } from "react";
import { getAuthTokenFromStorage } from "@mono-farmart-web/farmart-web-common/modal/auth/auth.model";
import useAuthenticationCheckerHook from "@mono-farmart-web/farmart-web-common/utils/authCheckerV2";
import { apiEndPoints } from "@mono-farmart-web/farmart-web-common/apis/endpoints";
import PlatForms from "@mono-farmart-web/farmart-web-common/constant/platFormEnums";
import { useNavigate, useParams } from "react-router-dom";
import type { UseBahiKhataInterface, BahiKhataDetailInterfaces } from "./types";
import useAppLanguage from "@mono-farmart-web/farmart-web-common/utils/applanguage";
import getLang from "@mono-farmart-web/farmart-web-common/languages/bahiKhataDetail";
import { languageUrls } from "@mono-farmart-web/farmart-web-common/constant/languageUrls";
import { bahiKhataDetailAssets } from "@mono-farmart-web/farmart-web-common/constant/imageUrls";
import { DEFAULT_LANGUAGE } from "@mono-farmart-web/farmart-web-common/constant/language";
import { trackEvent } from "@mono-farmart-web/farmart-web-common/utils/moengage";
import Events from "@mono-farmart-web/farmart-web-common/constant/MoengageEvents/index";
import TransactionType from "@mono-farmart-web/farmart-web-common/constant/enums/transactionType";
import { createBridge } from "@mono-farmart-web/rpc-bridge";
import { usePlatfromData } from "@mono-farmart-web/farmart-web-common/context/CrossPlatformActionHandler/platformChecker";
import bridgeFunctions from "@mono-farmart-web/rpc-bridge/utility";

function reverseData(array) {
	if (!Array.isArray(array)) {
		throw new TypeError("Input must be an array");
	}

	return [...array].reverse();
}

export default function useBahiKhataDetail(): UseBahiKhataInterface {
	const navigate = useNavigate();
	const { userId } = useParams();
	const bridge = createBridge();
	const authToken = getAuthTokenFromStorage();

	const [selectedDocument, setSelectedDocument] = useState<string>(null);
	const [openReplaceImageModal, setOpenReplaceImageModal] = useState(false);
	const platformData = usePlatfromData();
	const { AuthenticationCheckerApiCaller } = useAuthenticationCheckerHook();
	const parsedUserId = Number?.parseInt(userId);
	const { language } = useAppLanguage(languageUrls.bahiKhataDetail, getLang);
	const languageFromLocalStorage = localStorage.getItem("userLanguage");
	const languageId = languageFromLocalStorage
		? JSON.parse(languageFromLocalStorage)?.langCode
		: DEFAULT_LANGUAGE;
	const bahiKhataDetailAsset = bahiKhataDetailAssets(languageId);
	const [transactionHistory, setTransactionHistory] =
		useState<BahiKhataDetailInterfaces>({
			count: 0,
			farmer_mobile_number: null,
			farmer_name: "",
			transactions: [],
			balance: 0,
			khata_creation_date: "",
		});
	const [trasactionHistoryPageNo, setTrasactionHistoryPageNo] =
		useState<number>(1);
	const [reminderModalOpenClose, setReminderModalOpenClose] = useState(false);
	const [editProfileModalOpenClose, setEditProfileModalOpenClose] =
		useState(false);
	const [deleteKhataModalOpenClose, setDeleteKhataModalOpenClose] =
		useState(false);
	const [bahiKhataDetailLoading, setBahiKhataDetailLoading] = useState(false);
	const [editFarmerName, setEditFarmerName] = useState("");
	const [trasactionDeleteData, setTrasactionDeleteData] = useState(null);
	const [deleteTransactionModalOpenClose, setDeleteTransactionModalOpenClose] =
		useState(false);
	const [moreTrasactionLoading, setMoreTrasactionLoading] = useState(false);
	const [paymentDetailModalOpen, setPaymentDetailModalOpenClose] =
		useState(false);
	const [slectedTransactionDetail, setSlectedTransactionDetail] =
		useState(null);
	const [disableDeleteTransactionButton, setDisableDeleteTransactionButton] =
		useState(false);
	const [disableDeleteKhataButton, setDisableDeleteKhataButton] =
		useState(false);
	const [alert, setAlert] = useState({
		message: "",
		openClose: false,
	});

	const [uploadedImage, setUploadedImage] = useState<any>({
		title: "",
		size: null,
		type: "",
		data: "",
	});

	const [deleteImageModalVisible, setDeleteImageModalVisible] = useState(false);
	const [editAmountModalOpenClose, seteditAmountModalOpenClose] =
		useState(false);
	const [editAmount, setEditAmount] = useState(null);
	const [note, setNote] = useState("");
	const [noteModalOpenClose, setNoteModalOpenClose] = useState(false);
	const [deleteNoteOpenClose, setDeleteNoteOpenClose] = useState(false);
	const [error, setError] = useState(false);

	function onClickDeleteNoteModal() {
		setDeleteNoteOpenClose(!deleteNoteOpenClose);
	}

	function onClicknoteModal() {
		setNoteModalOpenClose(!noteModalOpenClose);
		if (noteModalOpenClose) {
			trackEvent(Events?.onEditNoteModalCrossButtonClick, {});
			setNote("");
		}
		setNote(slectedTransactionDetail?.comment);
	}

	function onClickInputEditNote() {
		trackEvent(Events?.onEditNoteModalInputBoxClick, {});
	}

	function onChangeNote(value: string) {
		setNote(value);
	}

	function onClickEditAmountModal() {
		seteditAmountModalOpenClose(!editAmountModalOpenClose);
		if (!editAmountModalOpenClose) {
			setEditAmount(slectedTransactionDetail?.amount);
		} else {
			trackEvent(Events?.onEditAmountModalCrossButtonClick, {});
		}
	}

	function toggleReplaceImageModal() {
		setOpenReplaceImageModal((c) => !c);
	}

	function toggleReplaceImageModalWithFlag(flag: boolean) {
		setOpenReplaceImageModal(flag);
	}

	function addShowDate(transactions) {
		let lastDate = null;

		return transactions.map((transaction) => {
			const currentDate = transaction.created_at.split("T")[0]; // Extract the date part
			if (currentDate !== lastDate) {
				lastDate = currentDate;
				return { ...transaction, show_date: true }; // Add `show_date` for the first instance of a new date
			}

			return { ...transaction, show_date: false };
		});
	}

	async function fetchTransactionHistory(pageNo?: number) {
		if (trasactionHistoryPageNo > 1) {
			setMoreTrasactionLoading(true);
		} else {
			setBahiKhataDetailLoading(true);
		}
		try {
			const response = await AuthenticationCheckerApiCaller({
				payload: { limit: 10, page_no: pageNo || trasactionHistoryPageNo },
				requestType: "get",
				customUri: `${apiEndPoints?.fetchFarmerTransaction}/${parsedUserId}`,
			});
			const reversedRecords = reverseData(response?.data?.data?.transactions || []);
			setTransactionHistory((prevState) => ({
				...response?.data?.data,
				transactions: addShowDate([
          ...(reversedRecords || []),
					...prevState.transactions,
				]),
			}));
			setEditFarmerName(response?.data?.data?.farmer_name);
			setTrasactionHistoryPageNo((prevState) => prevState + 1);
			setBahiKhataDetailLoading(false);
			setError(false);
			if (trasactionHistoryPageNo > 1) {
				setMoreTrasactionLoading(false);
			}
		} catch (error) {
			console.error("Error in fetching transaction history", error);
			setBahiKhataDetailLoading(false);
			setMoreTrasactionLoading(false);
			if (error?.code !== 401 || error?.code !== 426) {
				setError(true);
			}
		}
	}

	function calluser() {
		trackEvent(Events?.onDetailedKhataScreenCallButtonClick, {});
		if (platformData?.platform !== PlatForms?.get("website")) {
			bridge.sendRequestToNative(
				"DialNumber",
				{
					number: transactionHistory?.farmer_mobile_number,
				},
				(response) => {
					bridgeFunctions.PrintLog(response);
				},
			);
		} else
			window.location.href = `tel:${transactionHistory?.farmer_mobile_number}`;
	}

	function onClickBack() {
		navigate("/bahiKhata", {
			replace: true,
		});
	}

	function onClickReminderBell() {
		if (reminderModalOpenClose) {
			trackEvent(Events?.onSendReminderModalCrossButtonClick, {});
		}
		setReminderModalOpenClose(!reminderModalOpenClose);
	}

	function onClickEditProfile() {
		setEditProfileModalOpenClose(!editProfileModalOpenClose);
		if (!editProfileModalOpenClose) {
			setEditFarmerName(transactionHistory?.farmer_name);
		}
	}

	function onClickDeleteKhata() {
		setDeleteKhataModalOpenClose(!deleteKhataModalOpenClose);
	}

	async function remindeUserBalance() {
		trackEvent(Events?.onSendReminderModalConfirmationButtonClick, {
			Button_Name: "Send SMS",
		});
		try {
			await AuthenticationCheckerApiCaller({
				payload: {},
				requestType: "get",
				customUri: slectedTransactionDetail
					? `${apiEndPoints?.remindeBalance}/${parsedUserId}/${slectedTransactionDetail?.id}`
					: `${apiEndPoints?.remindeBalance}/${parsedUserId}`,
			});
			setReminderModalOpenClose(false);
			setAlert({
				message: language?.reminderSent,
				openClose: true,
			});
		} catch (error) {
			console.error("Error in reminding user balance", error);
		}
	}

	async function deleteKhata() {
		setDisableDeleteKhataButton(true);
		try {
			setDisableDeleteKhataButton(true);
			await AuthenticationCheckerApiCaller({
				payload: {},
				requestType: "delete",
				customUri: `${apiEndPoints?.deleteKhata}/${parsedUserId}`,
			});
			setDeleteKhataModalOpenClose(false);
			navigate("/bahiKhata", {
				state: {
					khataDeleted: true,
					khataName:
						transactionHistory?.farmer_name ||
						transactionHistory?.farmer_mobile_number,
				},
			});
			setDisableDeleteKhataButton(false);
		} catch (error) {
			console.error("Error in deleting user khata", error);
			setDisableDeleteKhataButton(false);
		}
	}

	async function deleteTransaction() {
		setDisableDeleteTransactionButton(true);
		trackEvent(Events?.onDeleteTransactionModalConfirmationButtonClick, {
			Button_Name: "Yes Delete",
		});
		try {
			await AuthenticationCheckerApiCaller({
				payload: {
					transaction_id: trasactionDeleteData || slectedTransactionDetail?.id,
				},
				requestType: "delete",
				uri: `${apiEndPoints?.deleteTransaction}`,
			});
			setDeleteTransactionModalOpenClose(false);
			setTrasactionDeleteData(null);
			setPaymentDetailModalOpenClose(false);
			resetTrasactionHistory().then(() => {
				fetchTransactionHistory(1);
			});
			setDisableDeleteTransactionButton(false);
		} catch (error) {
			setDisableDeleteTransactionButton(false);
			console.error("Error in deleting user balance", error);
		}
	}

	function onchangeFarmerName(name: string) {
		setEditFarmerName(name);
	}

	async function UpdateFarmerName() {
		trackEvent(Events?.onBahiKhataEditProfileUpdateDetailsButtonClick, {});
		try {
			const response = await AuthenticationCheckerApiCaller({
				payload: {
					farmer_detail_id: parsedUserId,
					farmer_name: editFarmerName || transactionHistory?.farmer_name,
				},
				requestType: "patch",
				uri: `${apiEndPoints?.deleteFarmers}`,
			});
			setTransactionHistory((prevState) => ({
				...prevState,
				farmer_name: response?.data?.data?.farmer?.farmer_name,
			}));
			setEditProfileModalOpenClose(false);
			setEditFarmerName(response?.data?.data?.farmer?.farmer_name);
			setAlert({
				message: language?.profileUpdateMessage,
				openClose: true,
			});
		} catch (error) {
			console.error("Error in updating farmer name", error);
		}
	}

	function onClickTransactionLongPress(transactionId: number) {
		return () => {
			if (platformData?.platform === PlatForms?.get("application")) {
				setTrasactionDeleteData(transactionId);
				/* setTrasactionDeleteData([...trasactionDeleteData, transactionId]); */
			}
		};
	}

	function handleCredit(transaction_code: number) {
		if (transaction_code === TransactionType?.credit) {
			trackEvent(Events?.onDetailedKhataScreenGiveCreditButtonClick, {});
		} else {
			trackEvent(Events?.onDetailedKhataScreenReceiveMoneyButtonClick, {});
		}
		navigate("/bahiKhata/creditEntry", {
			state: {
				transaction_id: transaction_code,
				username: transactionHistory?.farmer_name,
				mobile_number: transactionHistory?.farmer_mobile_number,
				farmer_detail_id: parsedUserId,
			},
		});
	}

	function unSelectLongPress() {
		setTrasactionDeleteData(null);
	}

	function onClickDeleteTransactionModal() {
		trackEvent(Events?.onDetailedTransactionScreenDeleteButtonClick, {
			Screen_Name: "Khata Detail Screen",
		});

		if (deleteTransactionModalOpenClose) {
			trackEvent(Events?.onDeleteTransactionModalCrossButtonClick, {
				Screen_Name: "Khata Detail Screen",
			});
		}
		setDeleteTransactionModalOpenClose(!deleteTransactionModalOpenClose);
	}

	function loadMoreTransaction() {
		if (transactionHistory?.count > transactionHistory?.transactions?.length) {
			fetchTransactionHistory();
		}
	}

	function onClickPaymentDetailModal(item) {
		setTrasactionDeleteData(null);
		setPaymentDetailModalOpenClose(!paymentDetailModalOpen);
		if (!paymentDetailModalOpen) {
			setSlectedTransactionDetail(item);
			setNote(item?.comment);
			trackEvent(Events?.onDetailedKhataScreenTransactionCardClick, {});
		} else {
			setSlectedTransactionDetail(null);
			setNote("");
			trackEvent(Events?.onDetailedTransactionScreenCrossButtonClick, {});
			resetTrasactionHistory().then(() => {
				fetchTransactionHistory(1);
			});
		}
	}

	async function resetTrasactionHistory() {
		setTransactionHistory({
			count: 0,
			farmer_mobile_number: null,
			farmer_name: "",
			transactions: [],
			balance: 0,
			khata_creation_date: "",
		});
		setEditFarmerName("");
		setTrasactionHistoryPageNo(1);
	}

	function onHoverTransaction(transactionId: number) {
		return () => {
			if (transactionId) {
				setTrasactionDeleteData(transactionId);
			} else if (
				!transactionId &&
				platformData?.platform === PlatForms?.get("website")
			) {
				setTrasactionDeleteData(null);
			}
		};
	}

	function onClickMenuButton() {
		/* trackEvent(Events?.onDetailedKhataScreenMoreSettingButtonClick, {}); */
	}

	function onChangeAmount(amount: number) {
		// Convert the input to a string for validation
		let inputAmount = amount.toString();

		// Allow only positive integers and decimals
		inputAmount = inputAmount.replace(/[^0-9.]/g, ""); // Remove invalid characters

		// Parse back to a number if valid
		const validAmount = inputAmount ? Number.parseFloat(inputAmount) : 0;

		setEditAmount(validAmount);
	}

	async function saveEditedAmount() {
		trackEvent(Events?.onEditAmountModalSaveButtonClick, {});
		try {
			const response = await AuthenticationCheckerApiCaller({
				payload: {
					transaction_id: slectedTransactionDetail?.id, //mandatory
					amount: editAmount, //mandatory
					comment: slectedTransactionDetail?.comment,
					image_proof_url: slectedTransactionDetail?.image_proof_url,
					transaction_date: slectedTransactionDetail?.transaction_date,
				},
				requestType: "put",
				uri: `${apiEndPoints?.editAmount}`,
			});
			setSlectedTransactionDetail((prevState) => ({
				...prevState,
				amount: response?.data?.data?.amount,
			}));
			setEditAmount(null);
			seteditAmountModalOpenClose(false);
			setAlert({
				message: language?.amountUpdate,
				openClose: true,
			});
		} catch (error) {
			console.log("error in saving edited amount", error);
		}
	}

	function onClickAmountModalInput() {
		trackEvent(Events?.onEditAmountModalInputBoxClick, {});
	}

	async function noteCreateUpdate() {
		trackEvent(Events?.onEditNoteModalSaveButtonClick, {});
		try {
			const response = await AuthenticationCheckerApiCaller({
				payload: {
					transaction_id: slectedTransactionDetail?.id, //mandatory
					amount: slectedTransactionDetail?.amount, //mandatory
					comment: note || slectedTransactionDetail?.comment,
					image_proof_url: slectedTransactionDetail?.image_proof_url,
					transaction_date: slectedTransactionDetail?.transaction_date,
				},
				requestType: "put",
				uri: `${apiEndPoints?.editAmount}`,
			});
			setNote(response?.data?.data?.comment);
			setSlectedTransactionDetail((prevState) => ({
				...prevState,
				comment: response?.data?.data?.comment,
			}));
			setNoteModalOpenClose(false);
			setAlert({
				message: language?.noteUpdateMessage,
				openClose: true,
			});
		} catch (error) {
			console.log("error in saving edited amount", error);
		}
	}

	async function deleteNoteModal() {
		try {
			await AuthenticationCheckerApiCaller({
				payload: {
					transaction_id: slectedTransactionDetail?.id, //mandatory
					amount: slectedTransactionDetail?.amount, //mandatory
					comment: "",
					image_proof_url: slectedTransactionDetail?.image_proof_url,
					transaction_date: slectedTransactionDetail?.transaction_date,
				},
				requestType: "put",
				uri: `${apiEndPoints?.editAmount}`,
			});
			setDeleteNoteOpenClose(false);
			setNoteModalOpenClose(false);
			setSlectedTransactionDetail((prevState) => ({
				...prevState,
				comment: "",
			}));
			setNote("");
		} catch (error) {
			console.log("error in saving edited amount", error);
		}
	}

	async function handleOpenService(event: any, type: string) {
		const file = event?.target?.files[0];
		setUploadedImage({
			title: file.name,
			size: file.size,
			type: file.type,
			data: "",
		});
		fileToDataURI(file)
			.then((dataURI) => {
				// SAVING THE DATA IN SESSION STORAGE FOR LATER USAGE;
				fetch(
					`${import.meta.env.VITE_API_BASE_URL}${
						apiEndPoints?.imageUploadBahiKhata
					}`,
					{
						method: "POST",
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${authToken}`,
						},
						body: JSON.stringify({ image: dataURI as string }),
					},
				)
					.then((response) => response.json())
					.then(async (data) => {
						// SAVING THE GENERATED URL INSIDE THE SESSION STORAGE;
						// WHILE KEEPING THE OLD URI IN STATE;
						// FOR THE SAKE OF DOCUMENT VIEWER;

						try {
							await AuthenticationCheckerApiCaller({
								payload: {
									transaction_id: slectedTransactionDetail?.id, //mandatory
									amount: slectedTransactionDetail?.amount, //mandatory
									comment: slectedTransactionDetail?.comment,
									image_proof_url:
										data?.data || slectedTransactionDetail?.image_proof_url,
									transaction_date: slectedTransactionDetail?.transaction_date,
								},
								requestType: "put",
								uri: `${apiEndPoints?.editAmount}`,
							});

							setSlectedTransactionDetail((prevState) => ({
								...prevState,
								image_proof_url: uploadedImage.data,
							}));
						} catch (error) {
							console.log("error in saving edited amount", error);
						}
						sessionStorage?.setItem(
							"creditDoc",
							JSON.stringify({
								title: file.name,
								size: file.size,
								type: file.type,
								data: data?.data,
							}),
						);
						setUploadedImage((file) => ({
							...file,
							data: dataURI as string,
						}));
					})
					.catch((error) => {
						console.error("Error:", error);
					});
			})
			.catch((error) => console.error("Error converting file:", error));
	}

	function fileToDataURI(file: File) {
		if (!file) return;

		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = (e) => resolve(e.target.result);
			reader.onerror = (e) => reject(e);
			reader.readAsDataURL(file);
		});
	}

	function preViewDocument(document: string) {
		setSelectedDocument(document);
	}

	function onPressClosePreview() {
		setSelectedDocument(null);
	}

	function toggleDeleteImageModal(from: string) {
		setDeleteImageModalVisible(!deleteImageModalVisible);
		if (from === "cross") {
			trackEvent(Events?.onImageAttachedScreenCrossButtonClick, {});
		} else if (from === "previewDelete") {
			trackEvent(Events?.onBahiKhataImagePreviewScreenDeleteButtonClick, {
				Screen_Name: "Khata Detail Screen",
			});
		}
	}

	async function onClickDeleteImage() {
		trackEvent(Events?.onBahiKhataImagePreviewConfirmationButtonClick, {
			Button_Name: "Delete Image",
		});
		try {
			await AuthenticationCheckerApiCaller({
				payload: {
					transaction_id: slectedTransactionDetail?.id, //mandatory
					amount: slectedTransactionDetail?.amount, //mandatory
					comment: slectedTransactionDetail?.comment,
					image_proof_url: "",
					transaction_date: slectedTransactionDetail?.transaction_date,
				},
				requestType: "put",
				uri: `${apiEndPoints?.editAmount}`,
			});

			setUploadedImage({
				title: language?.image,
				size: null,
				type: "",
				data: "",
			});
			setDeleteImageModalVisible(!deleteImageModalVisible);
			setSelectedDocument(null);
			setSlectedTransactionDetail((prevState) => ({
				...prevState,
				image_proof_url: "",
			}));
		} catch (error) {
			console.log("error in saving edited amount", error);
		}
	}

	function onClickEditProfileInputs(value: string) {
    value === "name"
      ? trackEvent(Events?.onBahiKhataEditProfileNameInputButtonClick, {})
      : trackEvent(
        Events?.onBahiKhataEditProfileMobileNumberInputButtonClick,
        {},
      );
	}

	async function onRetryScreen() {
		resetTrasactionHistory().then(() => {
			setSlectedTransactionDetail(null);
			setNote("");
			setPaymentDetailModalOpenClose(false);
			fetchTransactionHistory(1);
		});
	}

	function onPressHelp() {
		navigate("/faq/questions");
	}

	function handleCameraPermission() {
		bridge.sendRequestToNative(
			"requestCameraPermission",
			null,
			(response: any) => {
				bridgeFunctions.PrintLog(response);
			},
		);
	}

	useEffect(() => {
		if (userId) {
			fetchTransactionHistory();
		}
	}, [userId]);

	useEffect(() => {
		if (alert.openClose) {
			const timeoutId = setTimeout(() => {
				setAlert({
					message: "",
					openClose: false,
				});
			}, 3000);
			// Cleanup function to clear the timeout if the component unmounts
			return () => clearTimeout(timeoutId);
		}
	}, [alert.openClose]);

	useEffect(() => {
		window.addEventListener("popstate", onClickBack);

		return () => {
			window.removeEventListener("popstate", onClickBack);
		};
	}, [navigate]);

	return {
		transactionHistory,
		calluser,
		onClickBack,
		onClickReminderBell,
		reminderModalOpenClose,
		onClickEditProfile,
		editProfileModalOpenClose,
		onClickDeleteKhata,
		deleteKhataModalOpenClose,
		bahiKhataDetailLoading,
		remindeUserBalance,
		deleteKhata,
		onchangeFarmerName,
		editFarmerName,
		UpdateFarmerName,
		onClickTransactionLongPress,
		trasactionDeleteData,
		unSelectLongPress,
		deleteTransaction,
		onClickDeleteTransactionModal,
		deleteTransactionModalOpenClose,
		loadMoreTransaction,
		moreTrasactionLoading,
		onClickPaymentDetailModal,
		paymentDetailModalOpen,
		slectedTransactionDetail,
		alert,
		language,
		bahiKhataDetailAsset,
		onHoverTransaction,
		onClickMenuButton,
		handleCredit,
		onClickEditAmountModal,
		editAmountModalOpenClose,
		onChangeAmount,
		editAmount,
		saveEditedAmount,
		onClicknoteModal,
		noteModalOpenClose,
		onChangeNote,
		note,
		noteCreateUpdate,
		onClickDeleteNoteModal,
		deleteNoteOpenClose,
		deleteNoteModal,
		handleOpenService,
		uploadedImage,
		preViewDocument,
		selectedDocument,
		onPressClosePreview,
		onClickDeleteImage,
		toggleDeleteImageModal,
		deleteImageModalVisible,
		onClickEditProfileInputs,
		onClickAmountModalInput,
		onClickInputEditNote,
		error,
		onRetryScreen,
		onPressHelp,
		disableDeleteTransactionButton,
		disableDeleteKhataButton,
		toggleReplaceImageModal,
		toggleReplaceImageModalWithFlag,
		openReplaceImageModal,
		handleCameraPermission,
	};
}
