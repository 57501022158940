import { useState, useCallback } from "react";

interface UseBottomDrawerProps {
	defaultOpen?: boolean;
	onOpen?: () => void;
	onClose?: () => void;
}

interface UseBottomDrawerReturn {
	isOpen: boolean;
	open: () => void;
	close: () => void;
	toggle: () => void;
	drawerProps: any;
}

const useBottomDrawer = ({
	defaultOpen = false,
	onOpen,
	onClose,
}: UseBottomDrawerProps = {}): UseBottomDrawerReturn => {
	const [isOpen, setIsOpen] = useState(defaultOpen);

	const open = useCallback(() => {
		setIsOpen(true);
		onOpen?.();
	}, [onOpen]);

	const close = useCallback(() => {
		setIsOpen(false);
		onClose?.();
	}, [onClose]);

	const toggle = useCallback(() => {
		setIsOpen((prev) => !prev);
		isOpen ? onClose?.() : onOpen?.();
	}, [isOpen, onOpen, onClose]);

	const drawerProps: any = {
		anchor: "bottom",
		open: isOpen,
		onClose: close,
		variant: "solid",
    size: "sm",
		// sx: {
		// 	"& .MuiDrawer-paper": {
		// 		borderTopLeftRadius: "12px",
		// 		borderTopRightRadius: "12px",
		// 	},
		// },
	};

	return {
		isOpen,
		open,
		close,
		toggle,
		drawerProps,
	};
};

export default useBottomDrawer;
