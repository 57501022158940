export const displayWithCommas = (value) => {
  if (!value) return '';
  const cleanValue = value.toString().replace(/[^\d]/g, '');

  if (!cleanValue) return '';
  return new Intl.NumberFormat('en-IN').format(cleanValue);
};

export const removeCommas = (formattedValue) => {
  if (!formattedValue) return 0;
  return Number(formattedValue.toString().replace(/,/g, '').replace(/[^\d]/g, ''));
};
