import WalkthroughView from '../../Pages/AppWalkthrough';
import UseWalkthroughHooks from '../../hooks/AppWalkthroghHooks/walkthroughHooks';

export default function otpScreen() {
  const {
    handleLanguageSelect,
    onLanguageModalToggle,
    handleLanguageChange,
    selectedLanguage,
    isLanguageModalOpen,
    isLanguageSelectedInProgress,
    onPressBack,
    language,
    loading,
    languageData,
    onClickGotIt,
    initialScreenLoading,
    walkthroughAssets,
    slidedData
  } = UseWalkthroughHooks();
  return (
    <WalkthroughView
      handleLanguageSelect={handleLanguageSelect}
      onLanguageModalToggle={onLanguageModalToggle}
      handleLanguageChange={handleLanguageChange}
      selectedLanguage={selectedLanguage}
      isLanguageModalOpen={isLanguageModalOpen}
      isLanguageSelectedInProgress={isLanguageSelectedInProgress}
      onPressBack={onPressBack}
      language={language}
      loading={loading}
      languageData={languageData}
      onClickGotIt={onClickGotIt}
      initialScreenLoading={initialScreenLoading}
      walkthroughAssets={walkthroughAssets}
      slidedData={slidedData}
    />
  );
}
