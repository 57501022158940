import React, { lazy, Suspense, Dispatch } from 'react';
import Header from './Header';
import NameModal from './NameModal/NameModal';
import Disclaimer from './DisclaimerContainer/DisclaimerContainer';
import Styles from './style.module.css';
import CommentInput from  '@mono-farmart-web/farmart-web-common/Components/CommentInput';
import ScreenType from '@mono-farmart-web/farmart-web-common/constant/screenEnums';
import {
  actionSkeleton,
  postSkeleton,
  contentSkeleton,
  commentSkeleton,
} from '@mono-farmart-web/farmart-web-common/Components/SkeletonLoading/postDetailsSkeleton';
import {
  BaseComment,
  Post,
  PostDetailsById,
  PostList,
  ReplyComment,
} from '@mono-farmart-web/farmart-web-common/modal/community/types';
import Card from  '@mono-farmart-web/farmart-web-common/Components/Card/Card/Card';
import InfiniteScroll from '@mono-farmart-web/farmart-web-common/Components/InfinityScrollV2';
// Lazy-load components
const ContentContainer = lazy(
  () => import('@mono-farmart-web/farmart-web-common/Components/ContentContainer'),
);
const Action = lazy(() => import( '@mono-farmart-web/farmart-web-common/Components/Action'));
const Comment = lazy(() => import('./CommentContainer/Comment'));

interface PostCommunityPageProps {
  onClickBack: () => void;
  onClap: (
    id: number,
    screen: number,
    headingText: string,
    postDetailsData?: PostDetailsById,
  ) => () => void;
  onComment: (
    id: number,
    screen: number,
    postHeading?: string,
    postDetailsData?: PostDetailsById,
  ) => () => void;
  onShare: (
    id: number,
    heading: string,
    screen: number,
    headingText: string,
    postDetailsData?: PostDetailsById,
  ) => () => void;
  baseCommentList: BaseComment[];
  CreateCommentOnPost: (text: string) => void;
  postDetailsData: PostDetailsById;
  PostDetailLoading: boolean;
  onClickViewNestedReply: (id: number, index: number) => () => void;
  baseCommentLoading: boolean;
  loadMoreBaseComment: () => void;
  commentInputVisible: boolean;
  onClickReply: (replyParameter: ReplyComment) => () => void;
  baseCommentCount: number;
  onViewPost: (
    id: number,
    heading?: string,
    screen?: string,
    post?: Post,
  ) => () => void;
  setCommentInputVisible: Dispatch<React.SetStateAction<boolean>>;
  taggedName: string;
  removeTaggedName: () => void;
  focus: boolean;
  relevantPostList: PostList;
  loadMoreRelevantPostList: () => void;
  relevantPostLoading: boolean;
  nameModal: boolean;
  onChangeName: (event: React.ChangeEvent<HTMLInputElement>) => void;
  merchantName: string;
  onCompleteUserInfo: () => void;
  onCloseUpdateUserInoModal: () => void;
  userNameUploadLoading: boolean;
  onClickDisclaimer: () => void;
  language: { [key: string]: any };
  communityAssets: { [key: string]: string };
  userLogedIn: boolean;
}

export default function FeedDetailsScreen(props: PostCommunityPageProps) {
  const {
    onClickBack,
    baseCommentList,
    CreateCommentOnPost,
    postDetailsData,
    PostDetailLoading,
    onClickViewNestedReply,
    baseCommentLoading,
    loadMoreBaseComment,
    commentInputVisible,
    onClickReply,
    onClap,
    onComment,
    onShare,
    baseCommentCount,
    onViewPost,
    setCommentInputVisible,
    taggedName,
    removeTaggedName,
    focus,
    relevantPostList,
    loadMoreRelevantPostList,
    relevantPostLoading,
    nameModal,
    onChangeName,
    merchantName,
    onCompleteUserInfo,
    onCloseUpdateUserInoModal,
    userNameUploadLoading,
    onClickDisclaimer,
    language,
    communityAssets,
    userLogedIn
  } = props;


  const postList = relevantPostList.rows.map(function (post: Post) {
    // doing this because we are mutating the post object
    // to set created_by to UserDeleted if the user is deleted
    // we cant mutate the object directly as it is a readonly object
    // due to redux toolkit, reduxtoolkit uses immer under the hood
    // which makes the state object readonly
    post = { ...post };

    const thumbnail = post.image_thumbnails ? post.image_thumbnails : [];

    post.created_by = post.created_by || {
      name: 'USER DELETED',
      district: '',
      state: '',
      profile_pic_url: '',
      id: '',
      page: false,
      is_verified: false,
    };
    return (
      <Card
        name={post.created_by.name}
        state={post.created_by.state}
        heading={post.heading_text}
        clap={post.news_feed_stats.claps}
        comment={post.news_feed_stats.comment_count}
        share={post.news_feed_stats.share_count}
        profilepic={post.created_by.profile_pic_url}
        postpic={thumbnail}
        key={post.id + 'postDetailRelevant'}
        id={post.id}
        onClap={onClap(
          post.id,
          ScreenType.get('postDetailRelevant'),
          post.heading_text,
        )}
        onComment={onComment(
          post.id,
          ScreenType.get('postDetailRelevant'),
          post.heading_text,
        )}
        onShare={onShare(
          post.id,
          post.heading_text,
          ScreenType.get('postDetailRelevant'),
          post.heading_text,
        )}
        onViewPost={onViewPost(
          post.id,
          post.heading_text,
          ScreenType.get('postDetailRelevant'),
          post,
        )}
        postedAgo={post.live_from}
        verifiedPost={post.created_by.is_verified}
        postTypographyData={post.formatted_long_description}
        page={post.created_by.page}
        shortDescription={post.short_description}
        isClapped={
          post.news_feed_stats.claps || post.news_feed_stats.user_clap_count > 0
            ? true
            : false
        }
        backgroundColor={'#FFF'}
        language={language}
        companyProfileIcon={communityAssets.companyProfileIcon}
        whatsappIcon={communityAssets.whatsappIcon}
        clapIcon={communityAssets.clapIcon}
        clapDoneIcon={communityAssets.clapDoneIcon}
      />
    );
  });
  const profileStringify = localStorage.getItem('userProfile');
  let profile: any;

  if (profileStringify) {
    try {
      profile = JSON.parse(profileStringify);
    } catch (error) {
      // Handle the JSON parsing error here, e.g., log it or set a default value for profile
      console.error('Error parsing JSON:', error);
    }
  }

  return (
    <div className={Styles.container} id="postDetailsContainer">
      <Header
        onClickBack={onClickBack}
        createdBy={postDetailsData?.created_by}
        liveFrom={postDetailsData?.created_at}
        PostDetailLoading={PostDetailLoading}
        profileLoadingOnClickBackId="PostDetailProfileLoadingonClickBackId"
        profileContainerOnClickBackId="PostDetailProfileContainerClickBackId"
        userProfileIcon={communityAssets.userProfileIcon}
      />
      <div className={Styles.restOfcontainer}>
        <Suspense fallback={contentSkeleton()}>
          <ContentContainer
            PostDetailLoading={PostDetailLoading}
            formattedLongDescription={
              postDetailsData?.formatted_long_description
            }
            editor_type={postDetailsData?.editor_type}
            long_description={postDetailsData?.long_description}
          />
        </Suspense>
        <div className={Styles.actionContainer}>
          <Disclaimer
            onClickDisclaimer={onClickDisclaimer}
            id={'disclaimerToolTipId'}
          />
          <div className={Styles.actionboarder}>
            {PostDetailLoading ? (
              actionSkeleton()
            ) : (
              <Suspense fallback={actionSkeleton()}>
                <Action
                  onClap={onClap(
                    postDetailsData?.id,
                    ScreenType.get('postDetailScreen'),
                    postDetailsData?.heading_text,
                    postDetailsData,
                  )}
                  isClapped={
                    postDetailsData?.merchant_clap_count > 0 ? true : false
                  }
                  clap={postDetailsData?.total_clap_count}
                  onComment={onComment(
                    postDetailsData?.id,
                    ScreenType.get('postDetailScreen'),
                    postDetailsData?.heading_text,
                    postDetailsData,
                  )}
                  comment={postDetailsData?.comment_count}
                  onShare={onShare(
                    postDetailsData?.id,
                    postDetailsData?.heading_text,
                    ScreenType.get('postDetailScreen'),
                    postDetailsData?.heading_text,
                    postDetailsData,
                  )}
                  share={postDetailsData?.no_of_shares}
                  onClapButtonId="postDetailOnClapButtonId"
                  onShareButtonId="postDetailOnShareButtonId"
                  onCommentButtonId="postDetailOnCommentButtonId"
                  language={language}
                  whatsappIcon={communityAssets.whatsappIcon}
                  clapIcon={communityAssets.clapIcon}
                  clapDoneIcon={communityAssets.clapDoneIcon}
                />
              </Suspense>
            )}
          </div>

          <Suspense fallback={commentSkeleton(language?.comment)}>
            <Comment
              baseCommentList={baseCommentList}
              onClickViewNestedReply={onClickViewNestedReply}
              baseCommentLoading={baseCommentLoading}
              loadMoreBaseComment={loadMoreBaseComment}
              onClickReply={onClickReply}
              baseCommentCount={baseCommentCount}
              setCommentInputVisible={setCommentInputVisible}
              profileImage={profile?.profile_pic_url}
              language={language}
              chatImage={communityAssets?.chat}
            />
          </Suspense>
          <NameModal
            onCompleteUserInfo={onCompleteUserInfo}
            nameModal={nameModal}
            onChangeName={onChangeName}
            merchantName={merchantName}
            onCloseUpdateUserInoModal={onCloseUpdateUserInoModal}
            userNameUploadLoading={userNameUploadLoading}
          />

          {userLogedIn && commentInputVisible ? (
            <CommentInput
              onSubmit={CreateCommentOnPost}
              taggedName={taggedName}
              removeTaggedName={removeTaggedName}
              focus={focus}
              profileImage={profile?.profile_pic_url}
              textAreaId="commentTextInputId"
            />
          ) : null}
        </div>
        <div className={Styles.releventPostsContainer}>
          {relevantPostList.rows.length > 0 && (
            <>
              <span className={Styles.relatedPostTitle}>
                {postDetailsData?.created_by?.name} द्वारा और पोस्ट देखे
              </span>
              <Suspense fallback={postSkeleton()}>
                <InfiniteScroll
                  dataLength={relevantPostList.rows.length}
                  next={loadMoreRelevantPostList}
                  hasMore={relevantPostList.count > relevantPostList.rows.length}
                  loader={postSkeleton()}
                  scrollableTarget="postDetailsContainer"
                >
                  {postList}
                </InfiniteScroll>
              </Suspense>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
