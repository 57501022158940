import React from 'react';
import Styles from './style.module.css';
import { Box, Typography } from '@mui/joy';

interface HeaderProps {
  onLanguageModalToggle: () => void;
  language: { [key: string]: any };
  farmartLogo: string;
  languageSelectionIcon: string;
}
const Header = (props: HeaderProps) => {
  const {
    onLanguageModalToggle,
    language,
    farmartLogo,
    languageSelectionIcon,
  } = props;
  return (
    <Box className={Styles.mainContainer} bgcolor={'warning.50'}>
      <div className={Styles.locationContainer}>
        <div className={Styles.loactionLabelContainer}>
          <img
            className={Styles.whatsAppIcon}
            src={farmartLogo}
            alt="Farmart Logo"
            height={18}
            width={80}
            loading="eager"
          />
        </div>
      </div>

      <div
        id="loginScreenChangeLangTestId"
        className={Styles.helpButtonContainer}
        onClick={onLanguageModalToggle}
      >
        <img
          className={Styles.languageIconStyle}
          src={languageSelectionIcon}
          alt="Language Icon"
          height={20}
          width={20}
          loading="eager"
        />
        <Typography
          className={Styles.helpTextStyle}
          level="body-xs"
          color="primary"
          id="ChangeLanguageButton"
          data-testid="ChangeLanguageButton"
          content-desc="ChangeLanguageButton"
        >
          {language?.changeLanguage}
        </Typography>
      </div>
    </Box>
  );
};

export default Header;
