import Modal from '@mono-farmart-web/farmart-web-common/ComponentV2/Modal';
import Styles from './style.module.css';
import MuiButton from '@mono-farmart-web/farmart-web-common/ComponentV3/MuiButton';
import { Box, FormControl, FormLabel, Input } from '@mui/joy';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { trackEvent } from '@mono-farmart-web/farmart-web-common/utils/moengage';
import Events from '@mono-farmart-web/farmart-web-common/constant/MoengageEvents/index.js';

interface AddFarmerManualProps {
  onClickEditProfile: () => void;
  editProfileModalOpenClose: boolean;
  onchangeFarmerName: (name: string) => void;
  editFarmerName: string;
  profileName: string;
  UpdateFarmerName: () => void;
  farmerMobileNumber: number;
  language: { [key: string]: any };
  onClickEditProfileInputs: (value: string) => void;
}

export default function AddFarmerModal(props: AddFarmerManualProps) {
  const {
    onClickEditProfile,
    editProfileModalOpenClose,
    onchangeFarmerName,
    editFarmerName,
    profileName,
    UpdateFarmerName,
    farmerMobileNumber,
    language,
    onClickEditProfileInputs,
  } = props;
  const AddFarmerInputs = (
    <Box
      display={'flex'}
      flexDirection={'column'}
      margin={'16px 16px 20px 16px'}
      bgcolor={'#FFFFFF'}
    >
      <Box display={'flex'} flexDirection={'column'} gap={'20px'}>
        <Box flexDirection={'column'} display={'flex'}>
          <Box display={'flex'} flex={1} flexDirection={'column'}>
            <FormControl>
              <FormLabel>{language?.name}</FormLabel>
              <Box display={'flex'} flex={1}>
                <Input
                  variant="outlined"
                  size="md"
                  autoComplete="off"
                  placeholder={profileName || language?.name}
                  className={Styles.inputBoxContainer}
                  value={editFarmerName}
                  onChange={(event) => onchangeFarmerName(event?.target?.value)}
                  id="bahikhata_nameInput"
                  data-testid="bahikhata_nameInput"
                  onFocus={() => {trackEvent(Events?.onBahiKhataEditProfileNameInputButtonClick, {})}}
                  endDecorator={
                    <CloseRoundedIcon
                      sx={{
                        height: '24px',
                        width: '24px',
                        color: '#636B74',
                        opacity: '0.4',
                      }}
                      onClick={() => onchangeFarmerName('')}
                    />
                  }
                  slotProps={{
                    input: {
                      sx: {
                        ':focus': {
                          border: 'none',
                          outline: 'none',
                        },
                      },
                    },
                  }}
                  sx={{
                    width: '100%',
                    fontSize: '16px',
                    fontWeight: '400',
                    fontFamily: 'NotoSans',
                    fontStyle: 'normal',
                    lineHeight: '24px',
                    padding: '8px 12px',
                    borderColor: '#CDD7E1',
                    borderRadius: '8px',
                    backgroundColor: '#FBFCFE',
                  }}
                />
              </Box>
            </FormControl>
          </Box>
        </Box>
        <Box flexDirection={'column'} display={'flex'}>
          <Box display={'flex'} flex={1} flexDirection={'column'}>
            <FormControl>
              <FormLabel sx={{ color: '#9FA6AD' }}>
                {language?.contactNumber}
              </FormLabel>
              <Box display={'flex'} flex={1}>
                <Input
                  disabled={true}
                  inputMode="tel"
                  type="tel"
                  variant="outlined"
                  placeholder={language?.contactNumber}
                  value={farmerMobileNumber}
                  size="md"
                  color="neutral"
                  sx={{
                    width: '100%',
                    fontSize: '16px',
                    fontWeight: '400',
                    fontFamily: 'NotoSans',
                    fontStyle: 'normal',
                    lineHeight: '24px',
                    padding: '8px 12px',
                    borderColor: '#CDD7E1',
                    borderRadius: '8px',
                    backgroundColor: '#FBFCFE',
                  }}
                  className={Styles.inputBoxContainerMui}
                  id="bahikhata_numberInput"
                  data-testid="bahikhata_numberInput"
                  onFocus={()=> trackEvent(
                    Events?.onBahiKhataEditProfileMobileNumberInputButtonClick,
                    {}
                  )}
                />
              </Box>
            </FormControl>
          </Box>
        </Box>
      </Box>
      <Box display={'flex'} marginTop={'28px'}>
        <MuiButton
          variant="solid"
          size="lg"
          color="primary"
          disabled={editFarmerName?.trim() ? false : true}
          onClick={UpdateFarmerName}
          loading={false}
          id="bahikhata_updatedetailsButton"
          data-testid="bahikhata_updatedetailsButton"
        >
          {language?.updateDetails}
        </MuiButton>
      </Box>
    </Box>
  );
  return (
    <Modal
      isOpen={editProfileModalOpenClose}
      onClose={onClickEditProfile}
      // eslint-disable-next-line react/no-children-prop
      children={AddFarmerInputs}
      title={language?.editProifle}
      id="MobileNumberInputModal"
      isCloseButtonOption={true}
      modalIconTestId="bahikhata_editProfileCrossIcon"
      modalIconDataTestId="bahikhata_editProfileCrossIcon"
    />
  );
}
