import * as AppLanguages from '../constant/appLanguages';
//language will be added here
const languages = {
  [AppLanguages.appLanguagesIndex.english]: {
    noBalance: 'No balance',
    dateTenure: 'From {(variable1)} till now...',
    today: 'Today',
    noTransaction: 'No transactions recorded yet!',
    recieveMoney: 'Receive Money',
    giveCredit: 'Give Credit',
    giveCreditTo: 'Giving credit to {(variable1)}',
    youWillRecieve: 'You will receive',
    youWillGive: 'You will give',
    creditGiven: 'Credit given',
    deleteTransaction: 'Delete transaction?',
    confirmDeleteTransaction:
      'Are you sure you want to delete this transaction?',
    yesDelete: 'Yes, delete',
    no: 'No',
    remindKhataTitle: 'Remind {(variable1)}',
    remindKhataTotalMessage:
      'You are reminding {(variable1)} of ₹{(variable2)} credit',
    remindKhataMessage:
      'प्रिय {(variable1)}, आपके द्वारा {(variable2)} से की खरीदारी का कुल रु {(variable3)} शेष है | आपकी सेवा में {(variable4)} FARMART',
    remindCredit: 'Remind Credit',
    sendLater: 'Send Later',
    editProifle: 'Edit Profile',
    deleteKhata: 'Delete Khata',
    deleteKhataTitle: 'Delete {(variable1)}',
    deleteKhataMessage:
      'Are you sure you want to delete {(variable1)}? This will erase all your recorded transactions',
    name: 'Name',
    contactNumber: 'Contact number',
    updateDetails: 'Update Details',
    profileUpdateMessage: 'Profile updated successfully!',
    editAmount: 'Edit Amount',
    editNote: 'Edit Note',
    sendSmsReminder: 'Send reminder SMS',
    deleteNote: 'Delete Note',
    deleteMessage: 'Are you sure you want to delete note?',
    save: 'Save',
    noteUpdateMessage: 'Note updated!',
    addNote: 'Add a note',
    notePlaceholder: 'Type note here',
    paymentRecieved: 'Payment Recieved',
    image: 'Image',
    reminderSent: 'Reminder sent!',
    amountUpdate: 'Amount Updated',
    deleteImageModalButton:'Delete Image',
    replaceImageModalButton: 'Replace old Image',
    replaceImageModalTitle: 'You can only attach 1 picture',
    cameraPermissionHeading:'Give Permission',
    cameraPermissionDesc:'To capture photos, allow Farmart access to your camera. Tap Settings > Permissions, and turn Camera on.',
    notNow:'Not now',
    goToSettings:'Settings'
  },
  [AppLanguages.appLanguagesIndex.hindi]: {
    noBalance: 'कोई बैलेंस नहीं',
    dateTenure: '{(variable1)} से अब तक...',
    today: 'Today',
    noTransaction: 'अभी तक कोई लेन-देन दर्ज नहीं किया गया!',
    recieveMoney: 'पैसे प्राप्त करें',
    giveCredit: 'क्रेडिट दें',
    giveCreditTo: '{(variable1)} को क्रेडिट दे रहे हैं',
    youWillRecieve: 'आप प्राप्त करेंगे',
    youWillGive: 'आप देंगे',
    creditGiven: 'क्रेडिट दिया गया',
    deleteTransaction: 'लेन देन हटाएं ?',
    confirmDeleteTransaction: 'क्या आप सही में लेन देन हटाना  चाहते हैं',
    yesDelete: 'हाँ, हटाएँ',
    no: 'नहीं',
    remindKhataTitle: 'याद दिलाएं {(variable1)}',
    remindKhataTotalMessage:
      'आप ₹{(variable2)} के क्रेडिट के लिए {(variable1)} को याद दिला रहे हैं',
    remindKhataMessage:
      'प्रिय {(variable1)}, आपके द्वारा {(variable2)} से की खरीदारी का कुल रु {(variable3)} शेष है | आपकी सेवा में {(variable4)} FARMART',
    remindCredit: 'क्रेडिट  याद दिलाएं',
    sendLater: 'बाद में भेजें',
    editProifle: 'प्रोफ़ाइल संपादित करें',
    deleteKhata: 'खाता हटाएं',
    deleteKhataTitle: '{(variable1)} हटाएं',
    deleteKhataMessage:
      'क्या आप वाकई {(variable1)} हटाना चाहते हैं? यह आपकी सभी दर्ज की गई लेन-देन को हटा देगा।',
    name: 'नाम',
    contactNumber: 'संपर्क नंबर',
    updateDetails: 'विवरण अपडेट करें',
    profileUpdateMessage: 'प्रोफ़ाइल सफलतापूर्वक अपडेट की गई!',
    editAmount: 'राशि संपादित करें',
    editNote: 'नोट संपादित करें',
    sendSmsReminder: 'याद दिलाने के लिए  SMS भेजें',
    deleteNote: 'नोट हटाएं',
    deleteMessage: 'क्या आप वाकई इस नोट को हटाना चाहते हैं?',
    save: 'सेव करें',
    noteUpdateMessage: 'नोट अपडेट हो गया!',
    addNote: 'नोट जोड़ें',
    notePlaceholder: 'यहां नोट लिखें',
    paymentRecieved: 'भुगतान प्राप्त हुआ',
    image: 'फोटो',
    reminderSent: 'उधार भेजा गया!',
    amountUpdate: 'राशि अपडेटेड',
    deleteImageModalButton:'फोटो हटाएं',
    replaceImageModalTitle: 'आप केवल 1 फोटो जोड़ सकते हैं',
    replaceImageModalButton: 'पुरानी फोटो बदलें',
    cameraPermissionHeading:'अनुमति दें।',
    cameraPermissionDesc:'फोटो लेने के लिए, FarMart को आपके कैमरा उपयोग करने की अनुमति दें। सेटिंग्स > अनुमतियाँ पर जाएं और कैमरा चालू करें।',
    notNow:'अभी नहीं',
    goToSettings:'सेटिंग्स'
  },
};

export default (language) => languages[language];
