import { useState, useEffect } from 'react';
import type { SaudaDetailHooksTypes } from './types';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { usePlatfromData } from '@mono-farmart-web/farmart-web-common/context/CrossPlatformActionHandler/platformChecker';
import GetRequest from '@mono-farmart-web/farmart-web-common/utils/apiCaller/getRequest';
import PostRequest from '@mono-farmart-web/farmart-web-common/utils/apiCaller/postRequest';
import SaudaDetail from '@mono-farmart-web/farmart-web-common/modal/Traders/models/SaudaDetail';
import TruckDetail from '@mono-farmart-web/farmart-web-common/modal/Traders/models/TruckDetail';
import UpdatePoStatus from '@mono-farmart-web/farmart-web-common/modal/Traders/models/UpdatePoStatus';
import Events from '@mono-farmart-web/farmart-web-common/constant/MoengageEvents/index';
import { trackEvent } from '@mono-farmart-web/farmart-web-common/utils/moengage';
import tourGuideEnums from '@mono-farmart-web/farmart-web-common/constant/enums/tourGuideEnums';

import DocumentStatuEnums from '@mono-farmart-web/farmart-web-common/constant/enums/documentStatus';
import { languageUrls } from '@mono-farmart-web/farmart-web-common/constant/languageUrls/index';
import getLang from '@mono-farmart-web/farmart-web-common/languages/saudaDetails.js';
import truckStatusEnum from '@mono-farmart-web/farmart-web-common/constant/truckStatusEnum';
import type {
  PoDetails,
  SaudaDetailResp,
  TruckDetailResp,
  TruckTransactionDetail,
} from '@mono-farmart-web/farmart-web-common/modal/Traders/types.js';
import saudaStatusEnums from '@mono-farmart-web/farmart-web-common/constant/saudaStatusEnum';
import TruckStatus from '@mono-farmart-web/farmart-web-common/constant/truckStatusEnum';
import { useAuth } from '@mono-farmart-web/farmart-web-common/context/login';
import { loginAsGuest } from '@mono-farmart-web/farmart-web-common/modal/auth/auth.model';
import { unVerifiedRoutes } from '@mono-farmart-web/farmart-web-common/constant/unVerifiedRoutes';
import { helpLineNumber } from '@mono-farmart-web/farmart-web-common/constant/HelpLineNumber';
import { getSaudaDetailsAssets } from '@mono-farmart-web/farmart-web-common/constant/imageUrls/index.js';
import useAppLanguage from '@mono-farmart-web/farmart-web-common/utils/applanguage';
import { apiEndpoints } from '../../apis/farmart-web/index';
import { createBridge } from "@mono-farmart-web/rpc-bridge";
import bridgeFunctions from "@mono-farmart-web/rpc-bridge/utility";

export default function UseSaudaBookHooks(): SaudaDetailHooksTypes {
  const auth = useAuth();
  const userLogedIn = localStorage.getItem('loged');
  const [numberCopyToast, setNumberCopyToast] = useState<boolean>(false);
  const [utrCopyToast, setUtrCopyToast] = useState<boolean>(false);
  const [saudaPageLoading, setSaudaPageLoading] = useState<boolean>(true);
  const [isPaymentTermInfoVisible, setPaymentTermInfoVisible] =
    useState<boolean>(false);
  const [updatePoLoading, setupdatePoLoading] = useState<boolean>(false);
  const [saudaTruckLoading, setSaudaTruckLoading] = useState<boolean>(true);
  const { language } = useAppLanguage(languageUrls?.saudaDetailScreen, getLang);

  const params = useParams();
  const { id, modalName, modalId } = params;
  const langId = JSON.parse(localStorage.getItem('userLanguage'));

  const saudaDetailsAssets = getSaudaDetailsAssets(langId?.langCode);
  const [isTruckModalVisible, setTruckModalVisible] = useState<boolean>(false);
  const [truckDetailData, setTruckDetailData] = useState<TruckDetailResp>(null);
  const [saudaDetailData, setSaudaDetailData] = useState<SaudaDetailResp>(null);
  const navigate = useNavigate();
  const platformData = usePlatfromData();
  const [showCancelSaudaModal, setCancelSaudaModal] = useState(false);
  const [showAcceptPOModal, setShowAcceptPOModal] = useState(false);
  const [acceptPODisabled, setAcceptPODisabled] = useState(true);
  const [showRejectPOModal, setShowRejectPO] = useState(false);
  const [brokeragePopup, setBrokeragePopup] = useState(false);
  const [netPopup, setNetPopup] = useState(false);
  const [financingPopup, setFinancingPopup] = useState(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [showTourGuide, setShowTourGuide] = useState(true);
  const [downloadingPdf, setDownloadingPdf] = useState(false);
  const [truckDetailModalError, setTruckDetailModalError] = useState(false);
  const [poId, setPoId] = useState<number>();
  const [poDetails, setPoDetails] = useState<PoDetails>();
  const { state } = useLocation();

  const [downloadPdfModal, setDownloadPdfModal] = useState<boolean>(false);
	const bridge = createBridge();

  function toggleDownloadPdfModal() {
    setDownloadPdfModal(!downloadPdfModal);
  }

  const startLoadTime = new Date().getTime();
  let saudaStatus;

  function getTruckStatus(id) {
    let truckStatus = "Buyer Quality Report";
    switch (id) {
      case truckStatusEnum.get('truck_rejected'):
        truckStatus = "Truck Rejected";
        break;
      case truckStatusEnum.get('truck_dispatched'):
        truckStatus = "Truck Dispatched";
        break;
      case truckStatusEnum.get('truck_unloaded'):
        truckStatus = "Truck Unloaded";
        break;
      case truckStatusEnum.get('buyer_quality_report'):
        truckStatus = "Buyer Quality Report";
        break;
      case truckStatusEnum.get('document_approval_pending'):
        truckStatus = "Document Approval Pending";
        break;
      default:
        truckStatus = "Truck Dispatched";
        break;
    }

    return truckStatus;
  }

  const [selectedDocument, setSelectedDocument] = useState<string>(null);

  function onPressClosePreview() {
    setSelectedDocument(null);
  }

  function preViewDocument(document: string) {
    setSelectedDocument(document);
  }

  const handleBrokeragePopup = () => {
    if (!brokeragePopup) {
      trackEvent(Events?.onBrokerageButtonClick, {});
    }
    setBrokeragePopup(!brokeragePopup);
  };
  const handleFinancingPopup = () => {
    if (!financingPopup) {
      trackEvent(Events?.onFincostButtonClick, {});
    }
    setFinancingPopup(!financingPopup);
  };

  const handleNetPopup = () => {
    if (!netPopup) {
      trackEvent(Events?.onNetpriceButtonClick, {});
    }
    setNetPopup(!netPopup);
  };

  const toggleRejectPOModal = () => {
    showRejectPOModal
      ? trackEvent(Events?.onCancelButtonClick, {})
      : trackEvent(Events?.onRejectPOButtonClick, {});
    setShowRejectPO(!showRejectPOModal);
    setupdatePoLoading(false);
  };

  const toggleAcceptPO = () => {
    showAcceptPOModal
      ? trackEvent(Events?.onAcceptCancelButtonClick, {})
      : trackEvent(Events?.onAcceptPOButtonClick, {});
    setShowAcceptPOModal(!showAcceptPOModal);
    setupdatePoLoading(false);
  };

  const onClickTermsAndCondition = () => {
    if (acceptPODisabled) {
      trackEvent(Events?.OnAcceptPOCheckboxClick, {});
    }
    setAcceptPODisabled(!acceptPODisabled);
  };

  const toggleCancelSaudaModal = () => {
    showCancelSaudaModal
      ? trackEvent(Events?.onCancelSaudaRequestCloseButtonClick, {
          from: 'Cross',
        })
      : trackEvent(Events?.onCancelSaudaRequestButtonClick, {});
    setCancelSaudaModal(!showCancelSaudaModal);
    setupdatePoLoading(false);
  };

  function onClickBack(): void {
    trackEvent(Events?.onBackButtonClick, { From: 'Back_button' });
    if (platformData.platform === 'WEB' && userLogedIn) {
      auth.setMobileNumberModalVisible(false);
    }
    navigate('/trades/mySauda');
  }

  useEffect(() => {
    window.addEventListener('popstate', onClickBack);

    return () => {
      window.removeEventListener('popstate', onClickBack);
    };
  }, []);

  function onClickHelp(): void {
    trackEvent(Events?.onChatWithUsButtonClick, {});
    const url = `https://wa.me/${helpLineNumber}?text=Hey%20%0A%0AI%20want%20to%20connect%20with%20you%20on%20my%20booked%20sauda`;
    if (platformData.platform === 'WEB') {
      const win = window?.open(url, '_blank');
      win?.focus();
    } else {
      const text = `Hey\nI want to connect with you on my booked sauda &phone=${helpLineNumber}`;
      const link = `whatsapp://send?text=${text}`;
      bridge.sendRequestToNative(
				"OpenUrl",
				{
					link: link,
				},
				(response) => {
					bridgeFunctions.PrintLog(response);
				},
			);
    }
  }

  function onCallNow(customerNumber: string): void {
    trackEvent(Events?.onCallNowButtonClick, {});
    /* tslint:disable */
    if (window?.innerWidth > 1100) {
      setNumberCopyToast(true);
      navigator.clipboard.writeText(customerNumber);
      setTimeout(() => {
        setNumberCopyToast(false);
      }, 5000);
    } else {
      if (platformData.platform !== 'WEB') {
      	bridge.sendRequestToNative(
          "DialNumber",
          {
            number: customerNumber,
          },
          (response) => {
            bridgeFunctions.PrintLog(response);
          },
        );
      } else window.location.href = `tel:${customerNumber}`;
    }
  }

  async function fetchSaudaDetail() {
    try {
      setIsError(false);
      setSaudaPageLoading(true);
      const saudaResponse = await SaudaDetail({
        sauda_id: JSON.parse(id),
      });

      if (saudaResponse) {
        setSaudaDetailData(saudaResponse);
        setSaudaPageLoading(false);
        saudaStatus = saudaResponse?.sauda_status;
      }
      setSaudaPageLoading(false);
    } catch (error) {
      if (error?.code === 401 || error?.code === 426) {
        if (platformData.platform === 'WEB') {
          if (unVerifiedRoutes?.guestLogin?.includes(error?.path)) {
            await loginAsGuest();
          } else {
            auth.setMobileNumberModalVisible(true);
          }
        }
      } else {
        setIsError(true);
        // ToDo: Confirm if this can be removed
        // setTimeout(() => {
        //   navigate('/trades/mySauda');
        // }, 3000);
      }
    }
  }

  async function fetchTruckDetail(id: string, pDetail?: PoDetails) {
    setSaudaTruckLoading(true);
    try {
      const truckDataResp = await TruckDetail({
        truck_id: id,
      });

      if (truckDataResp) {
        trackEvent(Events?.onSaudaBookedTruckDetailPayableButtonClick, {
          "PO ID": pDetail?.po_id,
          "Sauda ID": saudaDetailData?.sauda_id,
          "Truck Status": getTruckStatus(pDetail?.po_status_id),
          "Payable Amount": `${truckDataResp?.total_payable}${truckDataResp?.total_payable_unit}`
        });
        setTruckDetailData(truckDataResp);
        setSaudaTruckLoading(false);
      }
      setSaudaTruckLoading(false);
    } catch (error) {
      if (error?.code === 401 || error?.code === 426) {
        if (platformData.platform === 'WEB') {
          if (unVerifiedRoutes?.guestLogin?.includes(error?.path)) {
            await loginAsGuest();
          } else {
            auth.setMobileNumberModalVisible(true);
          }
        }
      } else {
        setTruckDetailModalError(true);
      }
    }
  }

  async function fetchTourGuideDetails() {
    try {
      const tourGuideResp = await GetRequest({
        payload: {},
        uri: `${apiEndpoints?.userGuide}/${tourGuideEnums.get('sauda_detail')}`,
      });
      if (tourGuideResp?.data?.status) {
        setShowTourGuide(!tourGuideResp?.data?.data?.is_completed);
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  }

  async function updateTourGuide() {
    try {
      const tourGuideData = await PostRequest({
        payload: {
          guide_id: tourGuideEnums.get('sauda_detail'),
          is_completed: true,
        },
        uri: apiEndpoints?.userGuide,
      });
      if (tourGuideData?.data?.status) {
        setShowTourGuide(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  }

  function onClickTourGuide() {
    updateTourGuide();
    setPaymentTermInfoVisible(!isPaymentTermInfoVisible);
  }

  async function onDownloadPdf(link: string, from?: string) {
    setDownloadingPdf(true);
    trackEvent(Events?.OnViewPOReceiptClick, { From: from });
    if (platformData.platform === 'WEB') {
      try {
        // Replace 'your_pdf_url.pdf' with the actual URL of your PDF file
        const pdfUrl = link;
        const response = await fetch(pdfUrl);
        const blob = await response.blob();

        // Create a Blob URL for the PDF content
        const blobUrl = URL.createObjectURL(blob);

        // Create a link element
        const linkTag = document.createElement('a');
        linkTag.href = blobUrl;
        // Specify the filename for the downloaded file
        linkTag.download = 'Po Document';

        // Append the link to the document and trigger the click event
        document.body.appendChild(linkTag);
        linkTag.click();

        // Remove the link from the document
        document.body.removeChild(linkTag);
      } catch (error) {
        console.error('Error downloading PDF:', error);
      }
    } else {

      bridge.sendRequestToNative(
				"downloadPdf",
				{
          linkData: link
				},
				(response) => {
					bridgeFunctions.PrintLog(response);
				},
			);
    }
    setDownloadPdfModal(false);
    setTimeout(() => {
      setDownloadingPdf(false);
    }, 3000);
  }

  function onClickPaymentTermInfo() {
    trackEvent(Events?.onOrderDetailsNetPriceInfoButtonClick, {});
    setPaymentTermInfoVisible(!isPaymentTermInfoVisible);
  }

  useEffect(() => {
    fetchSaudaDetail();
  }, [userLogedIn]);


  useEffect(() => {
    if (modalName === 'truck' && Number(modalId)) {
      const id = JSON.parse(modalId);
      onClickViewPo(id);
    }
  }, []);

  useEffect(() => {
    fetchTourGuideDetails();
  }, []);

  function getKeyByValue(value) {
    for (const [key, val] of TruckStatus.entries() as any) {
      if (val === value) {
        return key;
      }
    }
    return null; // If value is not found in the map
  }

  function onClickViewPo(id: any, poDetail?: PoDetails, retryPoApi?: boolean) {
    setPoId(id);
    setPoDetails(poDetail);
    if (retryPoApi === true || !isTruckModalVisible) {
      fetchTruckDetail(JSON.stringify(id), poDetail);
      id !== null &&
        trackEvent(Events?.OnTruckDetailsViewPaymentDetailsCardClick, {
          Truck_Number: poDetail?.vehicle_number,
          Dispatch_Weight: poDetail?.dispatched_weight,
          Delivery_Weight: poDetail?.delivered_weight,
          Truck_Status: getKeyByValue(poDetail?.po_status_id),
        });
      trackEvent(Events?.OnTruckDetailsPaymentDetailsButtonClick, {
        Document_Status: DocumentStatuEnums.get(2),
        Payable_Amount: poDetail?.total_payable,
        "Dispatch Weight": `${poDetail?.dispatched_weight} ${poDetail?.dispatched_weight_unit}`,
        "PO ID": poDetail?.po_id,
        "Sauda ID": params?.id,
        "Is Tracking Live": poDetail?.is_tracking_live
      });
      setTruckModalVisible(true);
    } else {
      setTruckModalVisible(!isTruckModalVisible);
      if (id === null) {
        trackEvent(Events?.onPaymentDetailsCloseButtonClick, {
          Truck_Number: poDetail?.vehicle_number,
          Dispatch_Weight: poDetail?.dispatched_weight,
          Delivery_Weight: poDetail?.delivered_weight,
          Truck_Status: getKeyByValue(poDetail?.po_status_id),
        });
      }
    }
  }

  function onClickPaymentModalGotItButton() {
    trackEvent(Events?.onPaymentDetailsGotItButtonClick, {});
    setTruckModalVisible(!isTruckModalVisible);
  }

  function onCopyUtr(value: TruckTransactionDetail) {
    trackEvent(Events?.onPaymentDetailsTaptoCopyButtonClick, {
      Amount: value?.amount,
      UTR: value?.utr,
      Date: value?.paid_on,
    });
    setUtrCopyToast(true);
    navigator.clipboard.writeText(value?.utr);
    setTimeout(() => {
      setUtrCopyToast(false);
    }, 5000);
  }

  function saudaStatusTrackevent(saudaStatusId: number) {
    saudaStatusId === saudaStatusEnums.get('saudaCancelled')
      ? trackEvent(Events?.onConfirmCancelSaudaRequestButtonClick, {})
      : saudaStatusId === saudaStatusEnums.get('saudaBooked')
      ? trackEvent(Events?.onAcceptPOCancelButtonClick, {
          Sauda_Id: saudaDetailData?.sauda_id,
          View_PO_Receipt: saudaDetailData?.po_receipt_doc,
          Quantity: saudaDetailData?.sauda_quantity,
          Net_price: saudaDetailData?.sauda_payment_details?.net_price,
        })
      : saudaStatusId === saudaStatusEnums.get('poRejected') &&
        trackEvent(Events?.onRejectPOButtonClick, {
          Sauda_Id: saudaDetailData?.sauda_id,
          View_PO_Receipt: saudaDetailData?.po_receipt_doc,
          Quantity: saudaDetailData?.sauda_quantity,
          Net_price: saudaDetailData?.sauda_payment_details?.net_price,
        });
  }

  async function updatepoStatus(saudaId: number, saudaStatusId: number) {
    saudaStatusTrackevent(saudaStatusId);
    await updatePoStatus(saudaId, saudaStatusId);
  }

  async function updatePoStatus(saudaId: number, saudaStatusId: number) {
    setupdatePoLoading(true);
    try {
      const updatePosStatusResp = await UpdatePoStatus({
        sauda_id: saudaId,
        sauda_status_id: saudaStatusId,
      });

      setShowRejectPO(false);
      setShowAcceptPOModal(false);
      setCancelSaudaModal(false);
      fetchSaudaDetail();
      setupdatePoLoading(false);
    } catch (error) {
      if (error?.code === 401 || error?.code === 426) {
        if (platformData.platform === 'WEB') {
          if (unVerifiedRoutes?.guestLogin?.includes(error?.path)) {
            await loginAsGuest();
          } else {
            auth.setMobileNumberModalVisible(true);
          }
        }
      } else {
        setupdatePoLoading(false);
        setIsError(true);
        setShowRejectPO(false);
        setShowAcceptPOModal(false);
        setCancelSaudaModal(false);
      }
    } finally {
      setupdatePoLoading(false);
    }
  }

  const onClickReUploadTruckDetailsCard = (
    poDetails: PoDetails,
    saudaDetailData?: SaudaDetailResp,
  ) => {
    const dispatchId = poDetails?.dispatch_id;
    if (dispatchId) {
      navigate(`/trades/mySauda/createDispatch/${dispatchId}`, {
        state: {
          saudaId: id,
          dispatchId: dispatchId,
          saudaDetailData: saudaDetailData,
        },
      });
    } else {
      navigate(`/trades/mySauda/createDispatch`, {
        state: { saudaId: id, saudaDetailData: saudaDetailData },
      });
    }
  };

  const onClickCreateDispatch = (
    poDetails: PoDetails[],
    from?: string,
    saudaDetailData?: SaudaDetailResp,
  ) => {
    trackEvent(Events?.onDispatchTruckButtonClick, {
      From: from,
    });
    navigate(`/trades/mySauda/createDispatch`, {
      state: { saudaId: id, saudaDetailData: saudaDetailData },
    });
  };

  const onClickTruckNumberTruckDetailsCard = (
    poDetails: PoDetails,
    saudaDetailData?: SaudaDetailResp,
  ) => {
    const dispatchId = poDetails?.dispatch_id;
    trackEvent(Events?.onTruckNumberButtonClick, {
      Document_Status: poDetails?.po_doc_collective_status,
      Dispatched_Weight: poDetails?.dispatched_weight,
    });
    if (dispatchId) {
      navigate(`/trades/mySauda/createDispatch/${dispatchId}`, {
        state: {
          saudaId: id,
          dispatchId: dispatchId,
          saudaDetailData: saudaDetailData,
          poID: poDetails?.po_id
        },
      });
    } else {
      navigate(`/trades/mySauda/createDispatch`, {
        state: { saudaId: id, saudaDetailData: saudaDetailData, poID: poDetails?.po_id },
      });
    }
    // navigate(`/trades/mySauda/${dispatchId}/createDispatch`)
  };

  const triggerEventForPoCard = (poDetails: PoDetails) => {
    trackEvent(Events?.onSaudaBookedTruckDetailCardClick, {
      "Truck Staus": poDetails?.po_status,
      "Truck Number": poDetails?.vehicle_number,
      "Dispatched Weight": poDetails?.dispatched_weight,
      Payable: `${poDetails?.total_payable_unit} ${poDetails?.total_payable}`,
    });
  }

  const triggerEvent = (event: string, data: SaudaDetailResp) => {
    trackEvent(event, {
      'Commodity Name': data?.crop_name,
      Quantity: `${data?.sauda_quantity} ${data?.sauda_quantity_unit}`,
      Price: `${data?.sauda_payment_details?.gross_price_unit_prefix} ${data?.sauda_payment_details?.gross_price}`,
      Pending: data?.total_pending_quantity,
      Status: data?.sauda_status,
    });
  }

  function onPressHelpErrorState() {
    navigate('/faq/questions');
  }

  function onPressRetryErrorState() {
    fetchSaudaDetail();
  }

  function onPressRetryModalErrorState() {
    onClickViewPo(poId, poDetails, true);
  }

  return {
    onClickBack,
    onClickHelp,
    onCallNow,
    numberCopyToast,
    isTruckModalVisible,
    saudaDetailData,
    onClickViewPo,
    truckDetailData,
    utrCopyToast,
    onCopyUtr,
    saudaPageLoading,
    saudaTruckLoading,
    isPaymentTermInfoVisible,
    onClickPaymentTermInfo,
    onDownloadPdf,
    downloadingPdf,
    showCancelSaudaModal,
    toggleCancelSaudaModal,
    showAcceptPOModal,
    toggleAcceptPO,
    acceptPODisabled,
    onClickTermsAndCondition,
    showRejectPOModal,
    toggleRejectPOModal,
    updatepoStatus,
    updatePoLoading,
    language,
    brokeragePopup,
    handleBrokeragePopup,
    financingPopup,
    handleFinancingPopup,
    netPopup,
    handleNetPopup,
    onClickPaymentModalGotItButton,
    isError,
    setIsError,
    onClickReUploadTruckDetailsCard,
    onClickTruckNumberTruckDetailsCard,
    onClickCreateDispatch,
    onPressClosePreview,
    preViewDocument,
    downloadPdfModal,
    toggleDownloadPdfModal,
    selectedDocument,
    saudaDetailsAssets,
    onPressHelpErrorState,
    onPressRetryErrorState,
    showTourGuide,
    onClickTourGuide,
    truckDetailModalError,
    onPressRetryModalErrorState,
    triggerEventForPoCard,
    triggerEvent
  };
}
