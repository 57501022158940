import React from "react";
import Style from "./style.module.css";

interface ButtonProps {
	leftIcon?: React.ReactNode;
	rightIcon?: React.ReactNode;
	title: string;
	disabled?: boolean;
	onClick?: () => void;
	loading?: boolean;
	id?: string;
  sx?: React.CSSProperties
}

const Button: React.FC<ButtonProps> = ({
	leftIcon,
	rightIcon,
	title,
	disabled = false,
	onClick,
	loading,
	id,
	sx
}) => {
	return (
		<button
			id={id}
			type="button"
			disabled={disabled}
			onClick={onClick}
			className={disabled ? Style.disabledMainContainer : Style.mainContainer}
      style={{ ...sx }}
		>
			{loading ? (
				<div className={Style.loader}></div>
			) : (
				<>
					{leftIcon && <span className={Style.leftIcon}>{leftIcon}</span>}

					<span className={Style.title}>{title}</span>

					{rightIcon && <span className={Style.rightIcon}>{rightIcon}</span>}
				</>
			)}
		</button>
	);
};

export default Button;
