import { Box } from '@mui/joy';
import MuiTypography from '@mono-farmart-web/farmart-web-common/ComponentV3/Typography';
import BalanceContainerLoading from './balanceContainerLoading';
import { NotificationsActiveRounded } from '@mui/icons-material';
import stringSubstitution from '@mono-farmart-web/farmart-web-common/utils/stringSubstituteWithStyles';

export default function BalanceContainer({
  onClickReminderBell,
  balance,
  bahiKhataDetailLoading,
  khataDate,
  language,
}) {
  function balanceConditionChecker() {
    if (!balance || balance === 0) {
      return {
        balance: 0,
        message: language?.noBalance,
        color: '#FFFFFF',
      };
    } else if (balance > 0) {
      return {
        balance: balance,
        message: language?.youWillRecieve,
        color: '#C7F7C7',
      };
    } else if (balance < 0) {
      return {
        balance: Math.abs(balance),
        message: language?.youWillGive,
        color: '#F7C5C5',
      };
    } else {
      return {
        balance: 0,
        message: language?.noBalance,
        color: '#FFFFFF',
      };
    }
  }
  const balanceDetail = balanceConditionChecker();
  return (
    <Box
      padding={'16px'}
      alignItems={'center'}
      justifyContent={'center'}
      bgcolor={'var(--background-level1, #F0F4F8)'}
      boxSizing={'border-box'}
    >
      {bahiKhataDetailLoading ? (
        <BalanceContainerLoading />
      ) : (
        <Box
          display={'flex'}
          padding={'var(--4, 16px)'}
          flexDirection={'column'}
          alignItems={'flex-start'}
          gap={'10px'}
          alignSelf={'stretch'}
          borderRadius={'var(--radius-lg, 12px)'}
          boxShadow={
            '0.3px 0.8px 1.1px 0px rgba(187, 187, 187, 0.12), 1.1px 2.8px 3.9px -0.4px rgba(187, 187, 187, 0.17), 2.4px 6.1px 8.6px -0.8px rgba(187, 187, 187, 0.23), 5.3px 13.3px 18.8px -1.2px rgba(187, 187, 187, 0.29)'
          }
          sx={{
            background: 'linear-gradient(90deg, #32383E 0%, #4D4C4C 100%)',
          }}
        >
          <Box display={'flex'} justifyContent={'space-between'} width={'100%'}>
            <Box width={'100%'}>
              <MuiTypography
                level="h3"
                sx={{ color: balanceDetail?.color, fontFamily: 'NotoSans' }}
              >
                ₹{balanceDetail?.balance?.toLocaleString("en-IN")}
              </MuiTypography>
              <MuiTypography
                level="body-xs"
                sx={{ opacity: 0.6, color: '#FFFFFF', fontFamily: 'NotoSans' }}
                id="bahikhata_userTotalbalance"
                data-testid="bahikhata_userTotalbalance"
              >
                {balanceDetail?.message}
              </MuiTypography>
            </Box>
            {balance > 0 ? (
              <Box
                display={'flex'}
                minWidth={'40px'}
                height={'40px'}
                justifyContent={'center'}
                alignItems={'center'}
                borderRadius={'var(--radius-md, 8px)'}
                bgcolor={'var(--neutral-soft-Bg, #F0F4F8)'}
              >
                <NotificationsActiveRounded
                  sx={{ height: '24px', width: '24px', color: '#32383E' }}
                  onClick={onClickReminderBell}
                  id="bahikhata_RemindBellIcon"
                  data-testid="bahikhata_RemindBellIcon"
                />
              </Box>
            ) : null}
          </Box>

          <MuiTypography
            level="label-sm"
            sx={{
              opacity: 0.4,
              color: '#FFFFFF',
              fontFamily: 'NotoSans',
              fontStyle: 'italic',
            }}
          >
            {stringSubstitution(language?.dateTenure, [
              new Date(khataDate)
                .toLocaleDateString('en-IN', {
                  timeZone: 'Asia/Kolkata',
                  day: 'numeric',
                  month: 'long',
                  year: 'numeric',
                })
                .replace(/(\w+)\s(\w+)\s(\w+)/, '$1 $2, $3'),
            ], { fontStyle: 'normal' })}
          </MuiTypography>
        </Box>
      )}
    </Box>
  );
}
