import * as AppLanguages from '../constant/appLanguages';
//language will be added here
const languages = {
  [AppLanguages.appLanguagesIndex.hindi]: {
    loginText: 'लॉग इन करें',
    numerIntputTitle: 'अपना फोन नंबर डालें ',
    termsAndCondtions:
      'लॉग इन से आप {गोपनीयता नीति}, {नियमों और शर्तों} को स्वीकार करते है',
    help: 'सहायता',
    selectLanguageButton: 'आगे बढ़ें',
    changeLanguage: 'भाषा बदलें',
    proceed: 'आगे बढ़ें',
    selectLanguage: 'भाषा चुने',
    farmartMotoLabel: 'आपके व्यापार का भरोसेमंद साथी',
  },
  [AppLanguages.appLanguagesIndex.english]: {
    loginText: 'Login Now',
    loginHeading: 'Enter your 10 digits mobile number',
    numerIntputTitle: 'Enter Phone Number',
    termsAndCondtions:
      'By Login you accept our {Privacy policy} and {TnC}',
    help: 'Help',
    selectLanguageButton: 'Proceed',
    changeLanguage: 'Change Language',
    proceed: 'Continue',
    selectLanguage: 'Select language',
    farmartMotoLabel: 'Your Trusted Business Partner',
  },
};

export default (language) => languages[language];
