import React from "react";
import { Alert, Box } from "@mui/joy";
import Header from "@mono-farmart-web/farmart-web-common/MuiComponents/pages/common/Header";
import { ArrowBackRounded, InfoRounded } from "@mui/icons-material";
import { Column } from "@mono-farmart-web/farmart-web-common/MuiComponents/atoms/layout";
import ButtonC from "@mono-farmart-web/farmart-web-common/MuiComponents/atoms/button/Button";
import AddFilledIcon from "@mono-farmart-web/farmart-web-common/assets/svgComponet/AddFilledIcon";
import { Text } from "@mono-farmart-web/farmart-web-common/MuiComponents/atoms/typography";
import { useNavigate } from "react-router-dom";
import Styles from "./style.module.css";
import { PlayStoreUrl } from "@mono-farmart-web/farmart-web-common/constant/PlayStoreUrl";

const WebView = (props: any) => {
  const navigate = useNavigate();
  const { language } = props;

  return (
    <Box
    display={"flex"}
    flexDirection={"column"}
    height={"100dvh"}
    className={Styles.container}>
      {/* HEADER */}
      <Header
        leftIcon={
          <ArrowBackRounded
            sx={{
              width: "24px",
              height: "24px"
            }}
            onClick={() => navigate(-1)} />
          }
        title={language?.recordTransactionTitle} />

      {/* CONTENT */}
      <Column
        padding={4}
        gap={13}
        boxSizing={"border-box"}>

        {/* ADD MANUALLY - BUTTON OPTION */}
        <ButtonC
          startDecorator={<AddFilledIcon height="28" width="28" fillColor="#019881" />}
          color={"primary"}
          sx={{
            background: "white",
            color: "#019881",
            border: "1px solid",
            borderColor: "#4EB8A6",
            borderRadius: "8px",
            paddingTop: "6px",
            paddingRight: "24px",
            paddingBottom: "6px",
            paddingLeft: "24px"
          }}
          size={"lg"}
          loading={false}
          onClick={() => props?.handleAddUserClick({
            Screen_name: "Record Transaction - Webview Bottom Button",
          })}>
            {language?.addManuallyButton}
        </ButtonC>

        {/* DOWNLOAD FARMART APP - NOTICE & BUTTON */}
        <Column
          padding={"12px"}
          borderRadius={"8px"}
          boxSizing={"border-box"}
          gap={"12px"}
          border={"1px solid"}
          borderColor={"neutral.outlinedBorder"}
          bgcolor={"background.surface"}>
          <Alert color="warning" size="md" startDecorator={<InfoRounded sx={{ width: "24px", height: "24px" }} />}>
            <Text
              level="body-xxs"
              fontWeight={500}
              textColor="warning.softColor">{language?.downloadAppInfo}</Text>
          </Alert>

          <ButtonC
            onClick={() => {
              window.open(PlayStoreUrl, "_blank");
            }}
            color={"primary"}
            sx={{
              borderRadius: "8px",
              paddingTop: "6px",
              paddingRight: "24px",
              paddingBottom: "6px",
              paddingLeft: "24px",
            }}
            size={"lg"}
            loading={false}>
              {language?.downloadAppButton}
          </ButtonC>
        </Column>
      </Column>
    </Box>
  )
}

export default WebView;
