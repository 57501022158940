import React from 'react';
import Style from './style.module.css';
import Loader from '../Loader';
import {noop} from '../../utils/common';

interface ButtonV2Props {
  size?: string; //Props accepted by the component
  type?: string;
  loading?: boolean;
  title?: string;
  color?: string;
  disabled?: boolean;
  onClick?: () => void;
  leadingIcon?: React.ReactNode;
  trailingIcon?: React.ReactNode;
  id?: string;
}

const ButtonV2: React.FC<ButtonV2Props> = ({
  size = 'large', //'small' || 'large'(default value)
  type = 'primary', //'primary'(default value) || 'secondary' || 'tertiary' || 'link'
  loading = false, //true || false(default value)
  title,
  color, // color can be sent as prop as well, if not sent as a prop it takes the defalut colors
  disabled = false, //true || false(default value)
  onClick,
  leadingIcon,
  trailingIcon,
  id,
}) => {
  const getStyleByType = (type: string, disabled: boolean) => {
    switch (
      type //switch case used to assign different styling to button, title, loader as per the type of the button
    ) {
      case 'primary':
        return {
          buttonStyle: disabled ? Style.disabled : Style.primary,
          titleStyle: Style.title,
          loaderColor: '#ffffff',
        };
      case 'secondary':
        return {
          buttonStyle: disabled ? Style.disabledSecondary : Style.secondary,
          titleStyle: disabled ? Style.disabledTitle : Style.secondaryTitle,
          loaderColor: disabled ? '#C2C7CF' : color ? color : '#008B74',
        };
      case 'tertiary':
        return {
          buttonStyle: Style.tertiary,
          titleStyle: disabled ? Style.disabledTitle : Style.secondaryTitle,
          loaderColor: disabled ? '#C2C7CF' : color ? color : '#008B74',
        };
      case 'link':
        return {
          buttonStyle: Style.link,
          titleStyle: disabled ? Style.disabledLinkTitle : Style.linkTitle,
          loaderColor: disabled ? '#C2C7CF' : color ? color : '#008B74',
        };
      default:
        return {
          buttonStyle: disabled ? Style.disabled : Style.primary,
          titleStyle: Style.title,
          loaderColor: '#ffffff',
        };
    }
  };

  const styleOptions = getStyleByType(type, disabled);
  const buttonClass = `${styleOptions.buttonStyle} ${
    size === 'small' ? Style.smallButton : ''
  }`; //buttonClass changed as per the size of the button
  const titleClass = `${styleOptions.titleStyle} ${
    size === 'small' ? Style.smallTitle : ''
  }`; //titleClass changed as per the size of the button
  const loaderVisibility = type === 'link' ? false : true; //loaderVisibilty changed to false when Button if type link as per design

  return (
    <button
      className={buttonClass}
      style={{
        backgroundColor: type === 'primary' && color && !disabled ? color : '',
      }}
      type="button"
      disabled={disabled}
      onClick={loading ?  noop : onClick}
      id={id}
    >
      {loading ? ( //if loading is true loader shows else text shows
        <Loader color={styleOptions.loaderColor} />
      ) : (
        <>
          {leadingIcon && <span className={Style.icon}>{leadingIcon}</span>}
          {title && (
            <span
              style={{
                color: type !== 'primary' && color && !disabled ? color : '',
              }}
              className={titleClass}
            >
              {title}
            </span>
          )}
          {trailingIcon && <span className={Style.icon}>{trailingIcon}</span>}
        </>
      )}
    </button>
  );
};

export default ButtonV2;
