import { Box, ChipDelete, Chip } from '@mui/joy';
import distributorType from '@mono-farmart-web/farmart-web-common/constant/enums/distributorType';
import { ArrowDropDownRounded } from '@mui/icons-material';

interface FilterSectionProps {
  language: { [key: string]: string };
  handleCategoryModalOpenClose: () => void;
  currentCategory: number;
  categoryNameFilter: (id: number) => string;
  handleProductTypeModalOpenClose: () => void;
  clearCurrentCatgory: () => void;
  clearCurrentDistributor: () => void;
  currentProductType: number[] ;
}

export default function FilterSection({
  language,
  handleCategoryModalOpenClose,
  currentCategory,
  handleProductTypeModalOpenClose,
  clearCurrentCatgory,
  clearCurrentDistributor,
  currentProductType,
}: FilterSectionProps) {
  function categoryNameFilter(id: number) {
    if (distributorType?.fertiliser === id) {
      return language?.fertilisers;
    } else if (distributorType?.seed === id) {
      return language?.seeds;
    } else if (distributorType?.pesticide === id) {
      return language.pesticides;
    } else {
      return language?.category;
    }
  }

  const chipStyles = {
    display: 'flex',
    minHeight: '28px',
    padding: '0px 12px',
    alignItems: 'center',
    gap: '6px',
    '& .MuiChip-endDecorator': {
      padding: '6px'
    },
    '& .MuiChip-label': {
      padding: '6px 0'
    }
  };

  return (
    <Box display={'flex'}>
      <Box
        display={'flex'}
        marginTop={'16px'}
        flexDirection={'column'}
      >
        <Box
          margin={'0px 16px'}
          display={'flex'}
          alignItems={'flex-start'}
          gap={'16px'}
          color={'red'}
        >
          <Box>
            {currentCategory === null ? (
              <Chip
                onClick={handleCategoryModalOpenClose}
                size="lg"
                color="neutral"
                endDecorator={<ArrowDropDownRounded />}
                variant="outlined"
                sx={chipStyles}
              >
                {language?.category}
              </Chip>
            ) : (
              <Chip
                onClick={handleCategoryModalOpenClose}
                size="lg"
                color="primary"
                endDecorator={
                  <ChipDelete
                    onDelete={clearCurrentCatgory}
                    id="cropclosebutton_searchscreen"
                    data-testid="cropclosebutton_searchscreen"
                  />
                }
                variant="soft"
                sx={chipStyles}
              >
                {categoryNameFilter(currentCategory)}
              </Chip>
            )}
          </Box>
          <Box>
            {currentProductType.length < 1 ? (
              <Chip
                onClick={handleProductTypeModalOpenClose}
                size="lg"
                color="neutral"
                endDecorator={<ArrowDropDownRounded />}
                variant="outlined"
                disabled={!currentCategory || currentCategory === distributorType?.seed}
                sx={chipStyles}
              >
                {language.productType}
              </Chip>
            ) : (
              <Chip
                onClick={handleProductTypeModalOpenClose}
                size="lg"
                color="primary"
                endDecorator={
                  <ChipDelete
                    onDelete={clearCurrentDistributor}
                    id="varietyclosebutton_searchscreen"
                    data-testid="varietyclosebutton_searchscreen"
                  />
                }
                variant="soft"
                disabled={!currentCategory || currentCategory === distributorType?.seed}
                sx={chipStyles}
              >
                {language.productType}
              </Chip>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

