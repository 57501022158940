import React, { useRef, useState } from 'react';
import CircularProgress from '@mui/joy/CircularProgress';

// Define props interface for the component
interface PullToRefreshProps {
  onRefresh?: () => Promise<void>;  // Optional callback function for custom refresh logic
  children: React.ReactNode;         // Child components to be wrapped
}

const PullToRefresh: React.FC<PullToRefreshProps> = ({
  onRefresh,
  children,
}) => {

  const startY = useRef<number | null>(null); // Track the initial Y position when touch starts
  const [pullDistance, setPullDistance] = useState(0); // Track how far user has pulled down
  const [loading, setLoading] = useState<boolean>(false); // Track loading state for showing/hiding loader

  const threshold = 100; // Minimum pull distance required to trigger refresh
  const containerRef = useRef<HTMLDivElement>(null); // Reference to the container div for scroll position checking
  const initialTouchY = useRef<number | null>(null); // Track initial touch position to ensure pull starts from top
  const loaderHeight = 60; // Height reserved for loader when active

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    const container = containerRef.current;
    if (!container) return;

    // Store where the touch started
    initialTouchY.current = e.touches[0].clientY;

    // Only allow pull if we're at top and touch started near top of screen
    if (container.scrollTop === 0 && e.touches[0].clientY <= 150) {
      startY.current = e.touches[0].clientY;
    } else {
      startY.current = null; // Disable pull if not at top
    }
  };

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    const container = containerRef.current;
    if (!container || !initialTouchY.current) return;

    const currentY = e.touches[0].clientY;

    // Only handle pull if started at top
    if (startY.current !== null && container.scrollTop === 0) {
      const diffY = currentY - startY.current;

      if (diffY > 0) {
        // Case of downward pulls
        setPullDistance(Math.min(diffY, threshold * 2));
        e.preventDefault(); // Prevent default scroll behavior
      }
    } else if (container.scrollTop > 0) {
      // Cancel pull if user has scrolled down normally (List Cases)
      startY.current = null;
      setPullDistance(0);
    }
  };

  const handleTouchEnd = async () => {
    // Only refresh if pulled more than threshold and started from top
    if (
      pullDistance > threshold &&
      startY.current !== null &&
      initialTouchY.current &&
      initialTouchY.current <= 150
    ) {
      setLoading(true);

      // Force minimum loading for loader to display
      const minLoadingTime = new Promise((resolve) =>
        setTimeout(resolve, 1000),
      );

      try {
        if (!onRefresh) {
          await minLoadingTime;
          window.location.reload();
          return;
        }

        // Run custom refresh alongside minimum loading time
        await Promise.all([onRefresh(), minLoadingTime]);
      } finally {
        setLoading(false);
      }
    }
    // Reset all states
    setPullDistance(0);
    startY.current = null;
    initialTouchY.current = null;
  };

  return (
    // Main container that tracks touch events
    <div
      ref={containerRef}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      style={{
        height: '100%',
        overflow: 'auto',
      }}
    >
      {/* Content wrapper to handle shifting animation (Everything below loader) */}
<div
  style={{
    transform: pullDistance > 0 || loading ? `translateY(${loaderHeight}px)` : 'none', // Move content down when loading
    transition: pullDistance > 0 || loading ? 'transform 0.3s ease' : 'none',
    willChange: pullDistance > 0 || loading ? 'transform' : 'auto',
    position: 'relative',
    zIndex: 1,
  }}
>

        {/* Loading indicator */}
        {loading && (
          <div
            style={{
              position: 'absolute',
              top: -loaderHeight, // Position above content
              left: 0,
              right: 0,
              height: loaderHeight,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#fff',
              zIndex: 2, // Above main content
            }}
          >
            <CircularProgress
              sx={{
                alignSelf: 'center',
              }}
              size="sm"
              color="primary"
            />
          </div>
        )}
        {children}
      </div>
    </div>
  );
};

export default PullToRefresh;
