import FetchRecentKhataList from "../../../apis/bahikhata/fetchRecentKhataList";

// import type { CreateDispatchPayload, CreateDispatchResponse } from "../types";

export default async function fetchRecentKhataList(
	queryParams: any
): Promise<any> {
	try {
		const result = await FetchRecentKhataList(queryParams);

		if (!result.data.status) {
			throw result;
		}

		return result.data;
	} catch (error) {
		if (!error.message) {
			error.message = "Something Went Wrong";
		}

		throw error;
	}
}
