import React from 'react';
import { Box, Button } from '@mui/joy';
import Style from './style.module.css';

// Defining the interface for the component's props
interface FAQFooterProps {
  onClickNeedMoreHelp: () => void; // Function to handle the click event for the button
  language: { [key: string]: any }; // Object to hold language-specific text
  whatsappIcon: string;
}

// Functional component definition with destructured props

// Functional component definition with destructured props
const Footer = ({
  onClickNeedMoreHelp,
  language,
  whatsappIcon,
}: FAQFooterProps) => {
  // Creating a decorator with WhatsApp SVG image
  const startDecorator = (
    <img src={whatsappIcon} alt="Whatsapp Icon" loading="eager" />
  );

  return (
    <Box className={Style.footerContainer}>
      <Button
        variant="outlined" // Button variant
        startDecorator={startDecorator} // Adding the WhatsApp SVG image as a decorator
        className={Style.whatsappButton} // Applying CSS module style to Button component
        sx={{ height: 40, borderRadius: 8 }} // Additional inline styling for Button component
        onClick={onClickNeedMoreHelp} // Handling click event to trigger the provided function
        id="faqQuestionScreenOnClickNeedMoreHelpButtonId" // Setting an ID for the button for testing or identification
        data-testid='faqQuestionScreenOnClickNeedMoreHelpButtonId'
      >
        {language?.needMoreHelp}
      </Button>
    </Box>
  );
};

export default Footer; // Exporting the Footer component as the default export
