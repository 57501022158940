import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiEndPoints } from '@mono-farmart-web/farmart-web-common/apis/endpoints/index.js';
import useAuthenticationChecker from '@mono-farmart-web/farmart-web-common/utils/authCheckerV2/index.js';
import type { BahiKhataSearchProps, KhataListData } from './types.js';
import { trackEvent } from '@mono-farmart-web/farmart-web-common/utils/moengage/index';
import Events from '@mono-farmart-web/farmart-web-common/constant/MoengageEvents/index'; 
import useAppLanguage from '@mono-farmart-web/farmart-web-common/utils/applanguage/index.js';
import { languageUrls } from '@mono-farmart-web/farmart-web-common/constant/languageUrls/index';
import getLang from '@mono-farmart-web/farmart-web-common/languages/bahiKhataSearch.js';
import { getBahiKhataSearchAssets } from '@mono-farmart-web/farmart-web-common/constant/imageUrls';

export default function BahiKhataSearch(): BahiKhataSearchProps {
  const navigate = useNavigate();

  const { AuthenticationCheckerApiCaller } = useAuthenticationChecker();

  const { language } = useAppLanguage(
    languageUrls?.bahiKhataSearchLangUrl,
    getLang,
  );

  const langId = JSON.parse(localStorage.getItem('userLanguage'));
  const bahiKhataSearchAssets = getBahiKhataSearchAssets(langId?.langCode);

  const [khataList, setKhataList] = useState<KhataListData[]>([]);
  const [initialLoading, setInitialLoading] = useState(false);
  const [searchedValue, setSearchedValue] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  async function getKhataList(name: string) {
    setInitialLoading(true);

    try {
      const fetchMyFarmersParams = {
        page_no: currentPage,
        limit: 50,
        search_param: name,
      };
      const farmersResp = await AuthenticationCheckerApiCaller({
        payload: fetchMyFarmersParams,
        uri: apiEndPoints.fetchBahiKhataList,
        requestType: 'get',
      });

      if (farmersResp?.data?.status) {
        setKhataList(farmersResp?.data.data.transaction_list.results);
        setTotalCount(farmersResp.data.data.transaction_list.count);
      }
    } catch (error) {
      console.log('Error in fetching My Farmers', error);
    } finally {
      setInitialLoading(false);
    }
  }

  // Function to handle back button press
  function onPressBack() {
    navigate(-1);
  }

  const onClickKhata = (farmerId: number) => {
    navigate(`/bahiKhata/${farmerId}`);
  };

  function onSearchBuyer(name: string) {
    setSearchedValue(name);
    if (name?.length > 0) {
      getKhataList(name);
    }
  }

  function onClickRemoveSearch() {
    const onClickRemoveBuyerSearch = {
      screenName: 'Buyer Search',
    };
    trackEvent(Events?.onBuyerPriceCrossButtonClick, onClickRemoveBuyerSearch);
    setSearchedValue('');
    setKhataList([]);
  }

  return {
    onPressBack,
    language,
    khataList,
    initialLoading,
    onClickKhata,
    onClickRemoveSearch,
    onSearchBuyer,
    searchedValue,
    bahiKhataSearchAssets,
  };
}
