import { Box } from '@mui/joy';

import TransactionCard from './transactionCard';
import InfiniteScroll from '@mono-farmart-web/farmart-web-common/Components/InfinityScrollV2';
import FilterContainer from './FilterContainer';
import { KhataListData } from '../../../hooks/BahiKhataLanding/types';
import timeAgoLang from '@mono-farmart-web/farmart-web-common/utils/timeAgo/date/Language.js';
import useAppLanguage from '@mono-farmart-web/farmart-web-common/utils/applanguage/index.js';
import { languageUrls } from '@mono-farmart-web/farmart-web-common/constant/languageUrls/index';
import NoTransectionContainer from '../NoTransactionContainer';

interface TransactionDataProps {
  khataList: KhataListData[];
  loadMoreFarmers: () => void;
  featureLanguage: { [key: string]: string };
  onClickKhata: (id: number) => void;
  onClickSeachKhata: () => void;
  toggleFilterModal: () => void;
  toggleSortingModal: () => void;
  selectedSorting: number;
  selectedFiler: number;
  bahiKhataLandingAssets: { [key: string]: string };
  totalCount: number;
}

export default function TransactionList(props: TransactionDataProps) {
  const {
    khataList,
    loadMoreFarmers,
    featureLanguage,
    onClickKhata,
    onClickSeachKhata,
    toggleFilterModal,
    toggleSortingModal,
    selectedSorting,
    selectedFiler,
    bahiKhataLandingAssets,
    totalCount,
  } = props;

  const { language } = useAppLanguage(languageUrls?.timeAgo, timeAgoLang);

  const list = khataList?.map((item, index) => (
    <Box key={index} margin={'0px 16px'}>
      <TransactionCard
        index={index}
        item={item}
        timeAgoLang={language}
        onClickKhata={onClickKhata}
        language={featureLanguage}
      />
    </Box>
  ));
  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        boxSizing: 'border-box',
        marginBottom: '16px',
        marginTop: '8px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <FilterContainer
        language={featureLanguage}
        onClickSeachKhata={onClickSeachKhata}
        toggleFilterModal={toggleFilterModal}
        toggleSortingModal={toggleSortingModal}
        selectedSorting={selectedSorting}
        selectedFiler={selectedFiler}
      />
      {khataList.length === 0 ? (
        <NoTransectionContainer
          language={featureLanguage}
          bahiKhataLandingAssets={bahiKhataLandingAssets}
        />
      ) : (
        <Box
          sx={{
            flex: 1,
            overflow: 'auto',
            scrollbarWidth: 'none',
          }}
          id="landingListContainer"
        >
          <InfiniteScroll
            dataLength={khataList?.length || 0}
            next={loadMoreFarmers}
            hasMore={khataList?.length < totalCount}
            scrollableTarget="landingListContainer"
            loader={<></>}
            style={{
              height: '100%',
              width: '100%',
              overflowY: 'auto',
              paddingBottom: '16px',
            }}
          >
            {list}
          </InfiniteScroll>
        </Box>
      )}
    </Box>
  );
}
