import { useEffect, useState } from "react";
import type { EditProfileScreenProps, ErrorEditProfileProps } from "./types";
import { getUserProfileFromStorage } from "@mono-farmart-web/farmart-web-common/modal/auth/auth.model";
import { useNavigate } from "react-router-dom";
import useCaptureAndCompressImage, {
	MediaSourceEnums,
} from "@mono-farmart-web/farmart-web-common/utils/captureMedia";
import PlatForms from "@mono-farmart-web/farmart-web-common/constant/platFormEnums";
import { usePlatfromData } from "@mono-farmart-web/farmart-web-common/context/CrossPlatformActionHandler/platformChecker";
import { createBridge } from "@mono-farmart-web/rpc-bridge";
import bridgeFunctions from "@mono-farmart-web/rpc-bridge/utility";
import useAuthenticationChecker from "@mono-farmart-web/farmart-web-common/utils/authCheckerV2";
import { apiEndPoints } from "@mono-farmart-web/farmart-web-common/apis/endpoints";
import type {Profile} from "@mono-farmart-web/farmart-web-common//modal/auth/types.ts"
import { trackEvent } from "@mono-farmart-web/farmart-web-common/utils/moengage";
import { userType } from "@mono-farmart-web/farmart-web-common/constant/enums/userType";
import MoengageEvents from "@mono-farmart-web/farmart-web-common/constant/MoengageEvents/index";
import { fetchLanguage } from "@mono-farmart-web/farmart-web-common/utils/languageApi/fetchLanguage";
import { languageUrls } from "@mono-farmart-web/farmart-web-common/constant/languageUrls";
import { useLanguage } from "@mono-farmart-web/farmart-web-common/context/language";

// Custom hook for handling the edit profile functionality
export default function UseEditProfile(): EditProfileScreenProps {
	// Hooks and utilities initialization
	const startLoadTime = new Date().getTime();
	const { languageData } = useLanguage();
	const { AuthenticationCheckerApiCaller } = useAuthenticationChecker();
	const { captureAndCompressImage } = useCaptureAndCompressImage();
	const bridge = createBridge();
	const plaform = usePlatfromData();
	const navigate = useNavigate();
	const [languageFile, setLanguageFile] = useState({});
	/**
	 * Get the active system language?.
	 */
	const activeSystemLang = Intl.DateTimeFormat().resolvedOptions().locale;
	/**
	 * Determine the system language based on the active system language?.
	 */
	const systemLanguage = activeSystemLang?.includes("en")
		? languageData && languageData?.[1]?.langCode
		: languageData && languageData?.[0]?.langCode;

	/**
	 * Retrieve the language code from localStorage.
	 */
	const langId = JSON.parse(localStorage.getItem("userLanguage"));

	const language =
		languageFile[langId?.langCode ? langId?.langCode : systemLanguage];

	const merchnatProfile = getUserProfileFromStorage() || null;
	const occupation = merchnatProfile?.merchant_occupation_id || null;

	// State variables
	const [merchantName, setMerchantName] = useState<string>(
		merchnatProfile?.merchant_name || "",
	);
	const [merchantShopName, setMerchantShopName] = useState<string>(
		merchnatProfile?.business_name || "",
	);
	const [merchantImage, setMerchantImage] = useState<string>(
		merchnatProfile?.profile_pic_url || "",
	);
	const [showEditPhotoModal, setShowEditPhotoModal] = useState(false);
	const [error, setError] = useState<ErrorEditProfileProps>({
		status: false,
		message: "",
	});
	const [loading, setLoading] = useState<boolean>(false);
	const [userInformation, setInformation] = useState<Profile>({
		...merchnatProfile,
	});
	const [ediProfilePictureClicked, seteditProfilePictureClicked] =
		useState<boolean>(false);
	const [merchantNameClicked, setMerchantNameClicked] =
		useState<boolean>(false);
	const [merchantShopNameClicked, setMerchantShopNameClicked] =
		useState<boolean>(false);

	// Navigate back to the previous screen
	function onClickBackEditProfile() {
		navigate(-1);
	}

	// Show the edit photo modal
	function onClickEditProfilePic() {
		seteditProfilePictureClicked(true);
		setShowEditPhotoModal(true);
	}

	// Update merchant name state
	function onChangeName(name: string) {
		setMerchantName(name);
	}

	// Update merchant shop name state
	function onChangeShopName(name: string) {
		setMerchantShopName(name);
	}

	// Check if the string is a base64 image
	function isBase64Image(str: string) {
		const regex = /^data:image\/[a-zA-Z]+;base64,[A-Za-z0-9+/]+={0,2}$/;
		return regex.test(str);
	}

	// Submit the edit profile form
	async function submitEditProfile() {
		trackEvent(MoengageEvents?.onEditProfileSubmitButtonClick, {
			"Time Spent": new Date().getTime() - startLoadTime,
			"Edit Profile Picture Clicked":
				ediProfilePictureClicked && "Yes Button Clicked",
			"Shop Name Input Box Clicked":
				merchantShopNameClicked && "Yes Button Clicked",
			"Name Input Box Clicked": merchantNameClicked && "Yes Button Clicked",
		});
		const formCheckResult = checkForm();
		if (!formCheckResult.status) {
			setError(formCheckResult);
		} else {
			setLoading(true);
			try {
				let image = merchantImage;
				// Upload the profile image
				if (isBase64Image(merchantImage)) {
					const uploadImageResponse = await AuthenticationCheckerApiCaller({
						uri: apiEndPoints?.uploadImage,
						payload: { image: merchantImage },
						requestType: "post",
					});
					if (uploadImageResponse?.data?.status) {
						setMerchantImage(uploadImageResponse?.data?.data);
						setInformation({
							...userInformation,
							profile_pic_url: uploadImageResponse?.data?.data,
						});
						image = uploadImageResponse?.data?.data;
					}
				}
				// Update merchant name and shop name
				const updateBussinesName = await AuthenticationCheckerApiCaller({
					uri: apiEndPoints?.updateMerchantName,
					payload: {
						...userInformation,
						merchant_name: removeEmojis(merchantName),
						business_name: removeEmojis(merchantShopName),
						merchant_prof_pic: image,
					},
					requestType: "put",
				});
				if (updateBussinesName?.data?.status) {
					// Handle platform-specific profile update
					if (plaform?.platform === PlatForms?.get("website")) {
						localStorage.setItem(
							"userProfile",
							JSON.stringify({
								...userInformation,
								merchant_name: removeEmojis(merchantName),
								business_name: removeEmojis(merchantShopName),
								profile_pic_url: image,
							}),
						);
					} else {
						bridge.sendRequestToNative(
							"updateUserProfile",
							{
								...userInformation,
								merchant_name: removeEmojis(merchantName),
								business_name: removeEmojis(merchantShopName),
								profile_pic_url: image,
							},
							(response: any) => {
								bridgeFunctions.PrintLog(response);
							},
						);
						localStorage.setItem(
							"userProfile",
							JSON.stringify({
								...userInformation,
								merchant_name: removeEmojis(merchantName),
								business_name: removeEmojis(merchantShopName),
								profile_pic_url: image,
							}),
						);
					}
				}
				setLoading(false);
				navigate("/myProfile");
			} catch (error) {
				setLoading(false);
				setError({
					status: false,
					message: language?.requestFailErrorMessage,
				});
			}
		}
	}

	// Remove emojis from the string
	function removeEmojis(string) {
		const regex =
			/([#0-9]\u20E3)|[\xA9\xAE\u203C\u2047-\u2049\u2122\u2139\u3030\u303D\u3297\u3299][\uFE00-\uFEFF]?|[\u2190-\u21FF][\uFE00-\uFEFF]?|[\u2300-\u23FF][\uFE00-\uFEFF]?|[\u2460-\u24FF][\uFE00-\uFEFF]?|[\u25A0-\u25FF][\uFE00-\uFEFF]?|[\u2600-\u27BF][\uFE00-\uFEFF]?|[\u2900-\u297F][\uFE00-\uFEFF]?|[\u2B00-\u2BF0][\uFE00-\uFEFF]?|(?:\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDEFF])[\uFE00-\uFEFF]?/g;
		return string.replace(regex, "");
	}

	// Check if the form is valid
	function checkForm() {
		if (
			merchantName === "" &&
			merchantShopName === "" &&
			occupation !== userType["farmer"]
		) {
			return {
				status: false,
				message: language?.notbothShopAndUserName,
			};
		} else if (merchantName === "") {
			return {
				status: false,
				message: language?.notEnterUserName,
			};
		} else if (merchantShopName === "" && occupation !== userType["farmer"]) {
			return {
				status: false,
				message: language?.notEnterShopName,
			};
		}

		return {
			status: true,
			message: "",
		};
	}

	// Close the edit photo modal
	function onCloseEditModal() {
		setShowEditPhotoModal(false);
	}

	// Open the camera to capture a new profile picture
	async function openCamera() {
		try {
			if (PlatForms?.get("website") === plaform?.platform) {
				const image = await captureAndCompressImage(
					MediaSourceEnums.CAMERA,
					325,
					180,
				);
				setMerchantImage(image);
			} else {
				bridge.sendRequestToNative(
					"handleUserImage",
					{
						type: MediaSourceEnums.CAMERA,
					},
					(response: any) => {
						bridgeFunctions.PrintLog(response);
					},
				);
				(window as any).listenMessagesFromApp = (request: any) => {
					const data = JSON.parse(request);
					if (data?.data) {
						setMerchantImage(data?.data);
					}
				};
			}
		} catch (error) {
			console.log(error);
		}
		setShowEditPhotoModal(false);
	}

	// Open the gallery to select a new profile picture
	async function openGallery() {
		try {
			if (PlatForms?.get("website") === plaform?.platform) {
				const image = await captureAndCompressImage(
					MediaSourceEnums.FILE,
					325,
					180,
				);
				setMerchantImage(image);
			} else {
				bridge.sendRequestToNative(
					"handleUserImage",
					{
						type: MediaSourceEnums.FILE,
					},
					(response: any) => {
						bridgeFunctions.PrintLog(response);
					},
				);
				(window as any).listenMessagesFromApp = (request: any) => {
					const data = JSON.parse(request);
					if (data?.data) {
						setMerchantImage(data?.data);
					}
				};
			}
		} catch (error) {
			console.log(error);
		}
		setShowEditPhotoModal(false);
	}

	function onClickMerchantName() {
		setMerchantShopNameClicked(true);
	}

	function onClickMerchantShopName() {
		setMerchantNameClicked(true);
	}

	async function getLanguageJson() {
		try {
			const url = languageUrls?.editProfileScreenLangageUrl;
			const result = await fetchLanguage(url);
			setLanguageFile(result);
		} catch (error) {
			console.log("error::: (get language json)", error);
		} finally {
		}
	}

	useEffect(() => {
		getLanguageJson();
	}, []);


	// Return the hook's functions and state variables
	return {
		onClickBackEditProfile,
		submitEditProfile,
		onClickEditProfilePic,
		onChangeName,
		onChangeShopName,
		showEditPhotoModal,
		setShowEditPhotoModal,
		onCloseEditModal,
		openCamera,
		openGallery,
		language,
		merchantName,
		merchantShopName,
		merchantImage,
		error,
		occupation,
		onClickMerchantName,
		onClickMerchantShopName,
		loading,
	};
}
