import * as AppLanguages from '../constant/appLanguages';
//language will be added here
const languages = {
  [AppLanguages.appLanguagesIndex.english]: {
    title: "Record transaction with",
    inputNameHeading: "Name",
    inputNameHeadingPlaceholder: "Eg: Rajender Kumar",
    inputContactHeading: "Contact Number",
    inputContactHeadingPlaceholder: "Number",
    continueButton: "Continue"
  },
  [AppLanguages.appLanguagesIndex.hindi]: {
    title: "लेनदेन रिकॉर्ड करें",
    inputNameHeading: "नाम",
    inputNameHeadingPlaceholder: "उदाहरण: राजेंद्र कुमार",
    inputContactHeading: "संपर्क नंबर",
    inputContactHeadingPlaceholder: "नंबर",
    continueButton: "जारी रखें"
  },
};

export default (language) => languages[language];
