import * as AppLanguages from '../constant/appLanguages';
//language will be added here
const languages = {
  [AppLanguages.appLanguagesIndex.english]: {
    givingCredit: 'Giving credit to {(variable1)}',
    receivingCredit: 'Money received from {(variable1)}',
    enterAmountLent: 'Enter amount lent',
    enterAmountReceived: 'Enter amount received',
    notePlaceholder: 'Add Note...',
    saveButton: 'Save',
    attachOnePicture: 'You can only attach 1 picture',
    replaceOldImageButton: 'Replace old Image',
    noButton: 'No',
    deleteImageButton: 'Delete Image',
    sendSmsTo: 'Send SMS to {(variable1)}',
    remindCreditButton: 'Remind Credit',
    sendLaterButton: 'Send Later',
    templateStr:
      'प्रिय {(variable1)}, आपके द्वारा {(variable2)} से की खरीदारी का कुल रु {(variable3)} शेष है | आपकी सेवा में {(variable4)} FARMART',

    progressiveSignUpModalHeading: 'To Proceed, Please add the details',
    businessNameInputTitle: 'Business Name',
    nameInputTitle: 'Your Name',
    businessNameInputPlaceholder: 'Eg. Singh Traders',
    nameInputTitlePlaceholder: 'Eg. Arvind Singh',
    progressiveModalProceed: 'Proceed',
    shopNameInputTitle: 'Shop Name',
    shopNameInputPlaceholder: 'Eg. Singh Beej Bhandar',
    cameraPermissionHeading:'Give Permission',
    cameraPermissionDesc:'To capture photos, allow Farmart access to your camera. Tap Settings > Permissions, and turn Camera on.',
    notNow:'Not now',
    goToSettings:'Settings'

  },
  [AppLanguages.appLanguagesIndex.hindi]: {
    givingCredit: '{(variable1)} को क्रेडिट दे रहे हैं',
    receivingCredit: '{(variable1)} से पैसे प्राप्त हुए',
    enterAmountLent: 'दिया गया राशि दर्ज करें',
    enterAmountReceived: 'प्राप्त की गई राशि दर्ज करें',
    notePlaceholder: 'नोट जोड़ें...',
    saveButton: 'सेव करें',
    attachOnePicture: 'आप केवल 1 फोटो जोड़ सकते हैं',
    replaceOldImageButton: 'पुरानी फोटो बदलें',
    noButton: 'नहीं',
    deleteImageButton: 'फोटो हटाएँ',
    sendSmsTo: '{(variable1)} को SMS भेजें ',
    remindCreditButton: 'क्रेडिट याद दिलायें ',
    sendLaterButton: 'बाद में भेजें',
    templateStr:
      'प्रिय {(variable1)}, आपके द्वारा {(variable2)} से की खरीदारी का कुल रु {(variable3)} शेष है | आपकी सेवा में {(variable4)} FARMART',

    progressiveSignUpModalHeading: 'आगे बढ़ने के लिए, कृपया विवरण जोड़ें',
    businessNameInputTitle: 'दूकान का नाम',
    nameInputTitle: 'आपका नाम',
    businessNameInputPlaceholder: 'जैसे. सिंह ट्रेडर्स',
    nameInputTitlePlaceholder: 'जैसे. अरविन्द सिंह',
    progressiveModalProceed: 'आगे बड़े',
    shopNameInputTitle: 'दूकान का नाम',
    shopNameInputPlaceholder: 'जैसे. सिंह बीज भंडार',
    cameraPermissionHeading:'अनुमति दें।',
    cameraPermissionDesc:'फोटो लेने के लिए, FarMart को आपके कैमरा उपयोग करने की अनुमति दें। सेटिंग्स > अनुमतियाँ पर जाएं और कैमरा चालू करें।',
    notNow:'अभी नहीं',
    goToSettings:'सेटिंग्स'
  },
};

export default (language) => languages[language];
