import { Box } from '@mui/joy';
import Styles from './style.module.css';
import Header from './Header';
import BalanceContainer from './BalanceContainer';
import TransactionContainer from './TransactionContainer';
import SortingModal from './SortingModal';
import FilterModal from './FilterModal';
import { BahiKhataLandingProps } from '../../hooks/BahiKhataLanding/types';
import BahiKhataGuide from './BahiKhataGuide';
import ErrorScreen from '@mono-farmart-web/farmart-web-common/ComponentsV4/CommonErrorState';

export default function BahiKhataTransactionHistoryView(
  props: BahiKhataLandingProps,
) {
  const {
    khataList,
    currentPage,
    initialLoading,
    language,
    loadMoreFarmers,
    onClicApplySorting,
    onClickApplyFilter,
    onClickKhata,
    onClickSeachKhata,
    toggleFilterModal,
    toggleSortingModal,
    totalCount,
    userBalance,
    balanceLoading,
    onClickCloseGuide,
    userGuideVisible,
    filterModalVisible,
    sortingModalVisible,
    filterValues,
    selectedFiler,
    selectedSorting,
    bahiKhataLandingAssets,
    onClickRecordNewTransaction,
    bahiKhataError,
    onClickRetry,
    onContactUs,
    initialCount,
    alert,
  } = props;
  return (
    <Box>
      {bahiKhataError ? (
        <Box className={Styles.container}>
          <Header
            language={language}
            bahiKhataLandingAssets={bahiKhataLandingAssets}
            onContactUs={onContactUs}
            alert={alert}
          />
          <ErrorScreen onPressRetry={onClickRetry} onPressHelp={onContactUs} />
        </Box>
      ) : (
        <Box className={Styles.container}>
          <Header
            language={language}
            bahiKhataLandingAssets={bahiKhataLandingAssets}
            onContactUs={onContactUs}
            alert={alert}
          />
          <BalanceContainer
            balanceLoading={balanceLoading}
            language={language}
            userBalance={userBalance}
            onClickRecordNewTransaction={onClickRecordNewTransaction}
          />
          <TransactionContainer
            khataList={khataList}
            language={language}
            onClickKhata={onClickKhata}
            onClickSeachKhata={onClickSeachKhata}
            toggleFilterModal={toggleFilterModal}
            toggleSortingModal={toggleSortingModal}
            loadMoreFarmers={loadMoreFarmers}
            initialLoading={initialLoading}
            selectedSorting={selectedSorting}
            bahiKhataLandingAssets={bahiKhataLandingAssets}
            selectedFiler={selectedFiler}
            initialCount={initialCount}
            totalCount={totalCount} 
          />
          <BahiKhataGuide
            language={language}
            onClickCloseGuide={onClickCloseGuide}
            userGuideVisible={userGuideVisible}
            bahiKhataLandingAssets={bahiKhataLandingAssets}
          />
          <SortingModal
            onClicApplySorting={onClicApplySorting}
            language={language}
            sortingModalVisible={sortingModalVisible}
            toggleSortingModal={toggleSortingModal}
            selectedSorting={selectedSorting}
          />
          <FilterModal
            onClickApplyFilter={onClickApplyFilter}
            language={language}
            filterModalVisible={filterModalVisible}
            toggleFilterModal={toggleFilterModal}
            filterValues={filterValues}
            selectedFiler={selectedFiler}
          />
        </Box>
      )}
    </Box>
  );
}
