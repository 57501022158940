import { useState, useEffect } from "react";
import type { MobileInputProps, LanguageParams } from "./types";
import getLang from "@mono-farmart-web/farmart-web-common/languages/login";
import encryptAsAES from "@mono-farmart-web/farmart-web-common/utils/encryption/aes";
import CreateTextForEncryption from "@mono-farmart-web/farmart-web-common/utils/mobileEncryption";
import { useNavigate, useLocation } from "react-router-dom";
import MoengageEvents from "@mono-farmart-web/farmart-web-common/constant/MoengageEvents/index";
import { trackEvent } from "@mono-farmart-web/farmart-web-common/utils/moengage";
import { usePlatfromData } from "@mono-farmart-web/farmart-web-common/context/CrossPlatformActionHandler/platformChecker";
import { createBridge } from "@mono-farmart-web/rpc-bridge";
import bridgeFunctions from "@mono-farmart-web/rpc-bridge/utility";
import CountryCode from "@mono-farmart-web/farmart-web-common/constant/CountryCodeEnums";
import PlatForm from "@mono-farmart-web/farmart-web-common/constant/platFormEnums";
import PostRequest from "@mono-farmart-web/farmart-web-common/utils/apiCaller/postRequest";
import { apiEndPoints } from "@mono-farmart-web/farmart-web-common/apis/endpoints";
import { helpLineNumber } from "@mono-farmart-web/farmart-web-common/constant/HelpLineNumber";
import { getLoginScreenAssets } from "@mono-farmart-web/farmart-web-common/constant/imageUrls";
import { fetchLanguage } from "@mono-farmart-web/farmart-web-common/utils/languageApi/fetchLanguage";
import { languageUrls } from "@mono-farmart-web/farmart-web-common/constant/languageUrls";
import { DEFAULT_LANGUAGE } from "@mono-farmart-web/farmart-web-common/constant/language";
import { useLanguage } from "@mono-farmart-web/farmart-web-common/context/language";
import isAndroid from "@mono-farmart-web/farmart-web-common/utils/operatingSystemType";

export default function MobileSubmitHook(): MobileInputProps {
	const platformData = usePlatfromData();
	const navigate = useNavigate();
	const startLoadTime = new Date().getTime();
	const { languageData } = useLanguage();
	const bridge = createBridge();
	useEffect(() => {
		(window as any).listenMessagesFromApp = (request: any) => {
			bridge.listenMessagesFromNative(request);
		};
	}, []);

	const [initialScreenLoading, setInitialScreenLoading] = useState<boolean>(true);
	const [languageFile, setLanguageFile] = useState([]);

	useEffect(() => {
		getLanguageJson();
	}, []);

	async function getLanguageJson() {
		try {
			const url = languageUrls?.loginScreenLangageUrl;
			const result = await fetchLanguage(url);
			setLanguageFile(result);
		} catch (error) {
			// console.log("object");
		} finally {
			setInitialScreenLoading(false);
		}
	}

	const activeSystemLang = Intl.DateTimeFormat().resolvedOptions().locale;
	const systemLanguage = activeSystemLang?.includes("en")
		? languageData && languageData?.[1]?.langCode
		: languageData && languageData?.[0]?.langCode;
	const systemLanguageId = activeSystemLang?.includes("en")
		? languageData && languageData?.[1]?.backendLanguageCode
		: languageData && languageData?.[0]?.backendLanguageCode;

	// Retrieving language code from localStorage
	const langId = JSON.parse(localStorage.getItem("userLanguage"));

	// Getting location state
	const params = useLocation()?.state;

	// Getting language based on language code
	const language = languageFile
		? languageFile[langId?.langCode ? langId?.langCode : systemLanguage]
		: getLang(DEFAULT_LANGUAGE);

	const loginScreenAssets = getLoginScreenAssets(langId?.langCode);

	// Initializing current language
	const currentLanguage = langId || {
		langCode: systemLanguage,
		langId: systemLanguageId,
	};

	// State variables
	const [isLanguageModalOpen, toggleLanguageModal] = useState<boolean>(false);
	const [isLanguageSelectedInProgress, setLanguageSelectedInProgeress] =
		useState<boolean>(false);
	const [selectedLanguage, setSelectedLanguage] = useState(currentLanguage);
	const [userBlocked, setUserBlocked] = useState<boolean>(false);
	const [userMobileNumber, setUserMobileNumber] = useState<string>(
		params?.userMobileNumber || "",
	);
	const [error, setError] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);

	// Function to handle language selection
	function handleLanguageSelect(languageItem: LanguageParams) {
		trackEvent(MoengageEvents?.onChangeLanguageModalLanguageButtonClick, {
			'Language_Selected': languageItem.langCode,
		});
		setSelectedLanguage(languageItem);
	}

	// Function to handle language change
	function handleLanguageChange() {
		// Handling language change
		trackEvent(MoengageEvents?.onChangeLanguageModalSubmitButtonClick, {});
		if (localStorage.getItem("userLanguage") !== selectedLanguage) {
			setLanguageSelectedInProgeress(!isLanguageSelectedInProgress);
			localStorage.setItem("userLanguage", JSON.stringify(selectedLanguage));
			toggleLanguageModal(!isLanguageModalOpen);
			setLanguageSelectedInProgeress(false);
		} else {
			toggleLanguageModal(!isLanguageModalOpen);
		}
	}

	// Function to toggle language modal
	function onLanguageModalToggle() {
		if (!isLanguageModalOpen) {
			trackEvent(MoengageEvents?.onChangeLanguageButtonClick, {});
		}
		setSelectedLanguage(
			langId || {
				langCode: systemLanguage,
				langId: systemLanguageId,
			},
		);
		toggleLanguageModal(!isLanguageModalOpen);
	}

	// Function to handle login proceed
	function onClickLoginProceed() {
		if (userMobileNumber?.length < 10) {
			return setError(true);
		}
		getOtp();
	}

	// Function to get OTP
	async function getOtp() {
		setLoading(true);
		const encryptedText = await encryptAsAES(
			CreateTextForEncryption(userMobileNumber),
		);
		try {
			// Making API call to get OTP

			const getOtpData = await PostRequest({
				payload: {
					mobile_number: encryptedText,
					country_code: CountryCode.get("india"),
					message_id: "",
				},
				uri: apiEndPoints.getOTP,
			});

			if (getOtpData.data.status) {
				// Tracking MoEngage events
				trackEvent(MoengageEvents?.onLoginScreenContinueButtonClick, {
					mobile_number: userMobileNumber,
					"Time taken from clicking on submit button to enter the OTP":
						new Date().getTime() - startLoadTime,
					api_faluire: false,
				});
				setError(false);

				navigate("/otpSubmit", {
					state: {
						userMobileNumber: userMobileNumber,
					},
				});
			}
		} catch (error) {
			// Handling API error
			if (error?.code === 404) {
				setUserBlocked(true);
			}
			trackEvent(MoengageEvents?.onSGNMobileNumberSubmittedButtonClick, {
				mobile_number: userMobileNumber,
				"Time taken from clicking on submit button to enter the OTP":
					new Date().getTime() - startLoadTime,
				api_faluire: true,
			});
			setLoading(false);
			return setError(true);
		} finally {
			setLoading(false);
		}
	}

	// Function to get mobile number
	const getMobileNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
		  // Remove any non-digit characters (like spaces, dashes, etc.)
		  let cleanedNumber = event.target.value.replace(/\D/g, ''); // Check if the number starts with the country code +91
		  if (cleanedNumber.startsWith('91') && cleanedNumber.length > 10) {
			// Remove the country code
			cleanedNumber = cleanedNumber.slice(2);
		} // Get the last 10 digits
		else if (cleanedNumber.startsWith('0')) {
			cleanedNumber = cleanedNumber.slice(1);
		}
		const newNumber = cleanedNumber.slice(0, 10);
		onChangeNumber(newNumber)
	};

	function onChangeNumber(number:string){
		setUserBlocked(false);
		setUserMobileNumber(number);
		if (error) {
			setError(false);
		}
	}



	// Function to handle privacy policy and terms conditions click
	function onClickPrivacyPolicy(item: string) {
		return () => {
			if (item === language?.privacyPolicy) {
				trackEvent(MoengageEvents?.onPrivacyPolicyButtonClick, {});
			} else {
				trackEvent(MoengageEvents?.onTermsConditionsButtonClick, {});
			}
		};
	}

	// Function to handle help button
	function onContactUs() {
		trackEvent(MoengageEvents?.onHomeScreenHelpButtonClick, {
			screen_name:'Login'
		});
		const url = `https://wa.me/${helpLineNumber}?text=नमस्कार जी, मुझे आपसे कुछ सहायता चाहिये | मैने अभी FarMart एप डाउनलोड किया है ।`;
		if (platformData.platform === PlatForm.get("website")) {
			const win = window?.open(url, "_blank");
			win?.focus();
		} else {
			const text = `नमस्कार जी, मुझे आपसे कुछ सहायता चाहिये | मैने अभी FarMart एप डाउनलोड किया है । &phone=${helpLineNumber}`;
			const link = `whatsapp://send?text=${text}`;

			bridge.sendRequestToNative(
				"OpenUrl",
				{
					link: link,
				},
				(response) => {
					bridgeFunctions.PrintLog(response);
				},
			);
		}
	}

  function onClickBack() {
    if(!isAndroid()){
      navigate("/");
    }
  }

	/* This `useEffect` hook is setting up an event listener for the `popstate` event on the `window`
  object. When the user navigates back in the browser history, the `popstate` event is triggered. */
	useEffect(() => {

		window.addEventListener("popstate", onClickBack);

		return () => {
			window.removeEventListener("popstate", onClickBack);
		};
	}, [navigate]);

	useEffect(() => {
		setSelectedLanguage(
			langId || {
				langCode: systemLanguage,
				langId: systemLanguageId,
			},
		);
	}, [localStorage.getItem("userLanguage")]);


	const useKeyboardStatus = () => {
		const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);

		useEffect(() => {
		  const handleResize = () => {
			console.log('alert');

			const isOpen = window.innerHeight < 600; // Adjust this threshold based on your needs
			setIsKeyboardOpen(isOpen);

			// Scroll up if the keyboard is open
			if (isOpen) {
			  window.scrollTo({
				top: 100, // Adjust this value to scroll up by a specific amount
				behavior: 'smooth', // Smooth scrolling
			  });
			}
		  };

		  window.addEventListener('resize', handleResize);
		  return () => {
			window.removeEventListener('resize', handleResize);
		  };
		}, []);

		return isKeyboardOpen;
	  };

    function onClickInputClear(){
      setUserMobileNumber("");
    }

	// Returning props
	return {
		getMobileNumber,
		userBlocked,
		userMobileNumber,
		error,
		onClickLoginProceed,
		loading,
		initialScreenLoading,
		handleLanguageSelect,
		onLanguageModalToggle,
		handleLanguageChange,
		selectedLanguage,
		isLanguageModalOpen,
		isLanguageSelectedInProgress,
		onClickPrivacyPolicy,
		language,
		onContactUs,
		loginScreenAssets,
		languageData,
    	onClickBack,
		useKeyboardStatus,
    onClickInputClear
	};
}
