import { Box, Checkbox } from "@mui/joy";

import MuiTypography from "@mono-farmart-web/farmart-web-common/ComponentV3/Typography";
import type { UserInterestData } from "../../../hooks/UserInterest/types";

interface UserInterestCardProps {
	item: UserInterestData;
	handleSelectCard: (id: number) => void;
	selectedIds: number[];
	allSeleted: boolean;
}

export default function userInterestCard(props: UserInterestCardProps) {
	const { item, handleSelectCard, selectedIds, allSeleted } = props;
	return (
		<Box
			onClick={() => handleSelectCard(item.id)}
			height={"70px"}
			display={"flex"}
			border={selectedIds.includes(item.id) || allSeleted ? 1.5 : 1}
			bgcolor={
				selectedIds.includes(item.id) || allSeleted
					? "primary.softBg"
					: "background.body"
			}
			borderRadius={"8px"}
			borderColor={
				selectedIds.includes(item.id) || allSeleted
					? "primary.outlinedBorder"
					: "neutral.outlinedDisabledBorder"
			}
			padding={1}
			textOverflow={"ellipsis"}
			id={`Explore${item?.id}`}
			data-testid={`Explore${item?.id}`}
			content-desc={`Explore${item?.id}`}
		>
			<Box display={"flex"} alignItems={"center"} gap={3}>
				<Box
					display={"flex"}
					justifyContent={"center"}
					alignItems={"center"}
					marginLeft={"16px"}
				>
					<img
						src={item.icon_url}
						width={"47px"}
						height={"47px"}
						alt="feature"
					/>
				</Box>
				<Box display={"flex"} overflow={"hidden"}>
					<MuiTypography level="title-sm" textColor={"text.primary"}>
						{item.name}
					</MuiTypography>
				</Box>
			</Box>
			{selectedIds.includes(item.id) || allSeleted ? (
				<Box
					display={"flex"}
					justifyContent={"flex-end"}
					marginTop={"4px"}
					marginRight={"4px"}
					flex={1}
					id={`Tick${item?.id}`}
          data-testid={`Tick${item?.id}`}
					content-desc={`Tick${item?.id}`}
				>
					<Checkbox checked={true} />
				</Box>
			) : (
				<></>
			)}
		</Box>
	);
}
