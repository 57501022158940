import React, { type Dispatch, type SetStateAction, useRef } from "react";
import Styles from "./style.module.css";
import Button from "@mono-farmart-web/farmart-web-common/ComponentV3/MuiButton";
import OtpInput from "react-otp-input";
import CountDownTimer, {
	type CountdownTimerRef,
} from "@mono-farmart-web/farmart-web-common/utils/countDownTimer";
import MuiTypography from "@mono-farmart-web/farmart-web-common/ComponentV3/Typography";
import { Alert, Box } from "@mui/joy";
import ErrorOutlineRounded from "@mui/icons-material/ErrorOutlineRounded";

interface InputContainerProps {
	userOtp: string;
	getUserOtpFromInput: (value: string) => void;
	disableResend: boolean;
	setDisableResend: Dispatch<SetStateAction<boolean>>;
	resendOtp: () => void;
	userMobileNumber: string;
	error: boolean;
	onPressBack: (from: string) => () => void;
	language: { [key: string]: any };
	onContactUs: () => void;
	whatsappIcon: string;
}

export default function InputContainer(props: InputContainerProps) {
	const {
		userOtp,
		getUserOtpFromInput,
		disableResend,
		setDisableResend,
		resendOtp,
		userMobileNumber,
		error,
		onPressBack,
		language,
		onContactUs,
		whatsappIcon,
	} = props;
	const countdownTimerRef = useRef<CountdownTimerRef>(null);
	const handleResetClick = () => {
		if (countdownTimerRef.current) {
			setDisableResend(true);
			countdownTimerRef.current.reset();
			resendOtp();
		}
	};
	const renderTextWithColors = (text) => {
		if (!text) {
			return;
		}
		const splitText = text?.split(/{|}/);

		return splitText.map((item, index) => {
			if (item.startsWith("(") && item.endsWith(")")) {
				return (
					<MuiTypography
						className={Styles.OtpSentNumberStyle}
						key={"otpInputContainerMobileNumberText" + index}
					>
						{userMobileNumber}
					</MuiTypography>
				);
			}
			return (
				<MuiTypography
					key={index + "userMobileNumberText"}
					className={Styles.OtpSentNumberTitle}
				>
					{item}
				</MuiTypography>
			);
		});
	};
	return (
		<div>
			<div className={Styles.mainContainer}>
				<MuiTypography level="title-md">{language?.otpTitle}</MuiTypography>
				<div className={Styles.otpResendContainer}>
					<MuiTypography level="body-sm" className={Styles.OtpSentNumberTitle}>
						{language?.otpSentOnMobile &&
							renderTextWithColors(language?.otpSentOnMobile)}
					</MuiTypography>
					<div className={Styles.resendButtonContainer}>
						<Button
							size="sm"
							color="primary"
							variant="plain"
							className={Styles.changeNumberStyle}
							onClick={onPressBack("chnageNumber")}
							id="ChangeNumber"
							data-testid="ChangeNumber"
							content-desc="ChangeNumber"
						>
							{language?.changeNumber}
						</Button>
					</div>
				</div>
				<OtpInput
					value={userOtp}
					onChange={getUserOtpFromInput}
					numInputs={6}
					renderInput={(props, index) => (
						<input
							{...props}
							className={error ? Styles.errorOtpBox : Styles.otpBox}
							type="tel"
							id={"otpInputBox" + index}
						/>
					)}
					containerStyle={Styles.otpBoxInputConatiner}
					skipDefaultStyles={true}
					inputType="tel"
				/>
				{error && (
					<div className={Styles?.alert}>
						<Alert
							color="danger"
							size="md"
							startDecorator={<ErrorOutlineRounded />}
						>
							{language?.wrongOtp}
						</Alert>
					</div>
				)}
				<div className={Styles.resendContainer}>
					<MuiTypography level="body-xs" className={Styles.otpNotRecievedLabel}>
						{language?.didNotGetOtp}
					</MuiTypography>

					<Box
						flexDirection={"row"}
						display={"flex"}
						justifyContent={"center"}
						alignItems={"center"}
					>
						<button
							disabled={disableResend}
							className={
								disableResend
									? Styles.resendAgainTextDisable
									: Styles.resendAgainText
							}
							onClick={handleResetClick}
							id="ResendOtpAgainButton"
						>
							{language?.sendAgain}
						</button>
						<CountDownTimer
							initialTime={30}
							className={Styles.timeOutText}
							ref={countdownTimerRef}
							onTimeChange={(isInRange) => {
								// Handle the timer range changes here
								setDisableResend(isInRange);
							}}
						/>
					</Box>
				</div>
			</div>
			<div className={Styles?.helpButtonWrapper}>
				<div className={Styles.helpButtonContainer} onClick={onContactUs}>
					<img
						className={Styles.whatsAppIconStyle}
						src={whatsappIcon}
						alt="WhatsApp Icon"
						height={16}
						width={16}
						loading="eager"
					/>
					<MuiTypography level="title-sm" textColor={"#9A5B13"}>
						{language?.help}
					</MuiTypography>
				</div>
			</div>
		</div>
	);
}
