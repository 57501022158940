import React, { Suspense, lazy } from 'react';
import { Box } from '@mui/joy';
import { SMSLandingScreenProps } from '../../hooks/SMSLandingScreen/types';
import { bannerSkeleton, templateGridSkeleton } from './SMSLandingSkeleton';
import Header from './Header';
import SMSBalanceContainer from './SMSBalanceContainer';
import PromotionalMediaCard from './PromotionalMediaCard';
import PromotionalMediaModal from './PromotionalMediaModal';
import SupportContainer from './SupportContainer';
import ShopPromotionModal from '@mono-farmart-web/farmart-web-common/ComponentV2/ShopPromotionModal';
import ProgressiveSignup from './ProgressiveSignup'; 

import Styles from './style.module.css';

// Lazy load components
const ShopPromotionBanner = lazy(() => import('./ShopPromotionBanner'));
const SMSTemplateListContainer = lazy(
  () => import('./SMSTemplateListContainer'),
);

export default function SMSLandingScreen({
  language,
  loading,
  totalSMSCredit,
  noOfDays,
  noOfSMSSent,
  promotionalMedia,
  smsLandingScreenAssets,
  onClickViewSMSHistory,
  smsTemplateTypes,
  showShopPromotionModal,
  onCloseModal,
  onClickBackSMSLanding,
  onClickHelpSMSLanding,
  onClickInfoSMSLanding,
  onClickSMSTemplateCard,
  onClickPromtionalMediaCard,
  showMediaModal,
  onCloseMediaModal,
  onClickContactSupport,
  isRetailer,
  merchantDetail,
  onChangeInput,
  onCloseProgressiveModal,
  onCompleteUserInfo,
  progressiveModalVisible,
  merchantData,
}: SMSLandingScreenProps) {
  const date = new Date();
  const currentMonthName = date.toLocaleString('default', { month: 'long' });

  return (
    <Box className={Styles.container}>
      <Header
        language={language}
        onClickBack={onClickBackSMSLanding}
        onClickInfo={onClickInfoSMSLanding}
        onClickHelp={onClickHelpSMSLanding}
        isRetailer={isRetailer}
      />
      {loading ? (
        <>
          {bannerSkeleton()}
          {templateGridSkeleton()}
        </>
      ) : (
        <>
          <Suspense fallback={bannerSkeleton()}>
            {noOfSMSSent === 0 && <ShopPromotionBanner smsLandingScreenAssets={smsLandingScreenAssets} />}
          </Suspense>
          <SMSBalanceContainer
            currentMonth={currentMonthName}
            noOfDays={noOfDays}
            noOfSMSSent={noOfSMSSent}
            totalSMSCredit={totalSMSCredit}
            onClickViewSMSHistory={onClickViewSMSHistory}
            language={language}
          />
          <Suspense fallback={templateGridSkeleton()}>
            <SMSTemplateListContainer
              smsTemplateTypes={smsTemplateTypes}
              language={language}
              onClickSMSTemplateCard={onClickSMSTemplateCard}
            />
          </Suspense>

          <PromotionalMediaCard
            promotionalMedia={promotionalMedia}
            onClickPromtionalMediaCard={onClickPromtionalMediaCard}
            language={language}
          />
          <ShopPromotionModal
            openModal={showShopPromotionModal}
            onClose={onCloseModal}
          />
          <PromotionalMediaModal
            promotionalMedia={promotionalMedia}
            showMediaModal={showMediaModal}
            onCloseMediaModal={onCloseMediaModal}
            language={language}
          />
          <ProgressiveSignup
            merchantDetail={merchantDetail}
            onChangeInput={onChangeInput}
            onCloseProgressiveModal={onCloseProgressiveModal}
            onCompleteUserInfo={onCompleteUserInfo}
            progressiveModalVisible={progressiveModalVisible}
            merchantData={merchantData}
            language={language}
          />
          <SupportContainer
            onClickContactSupport={onClickContactSupport}
            language={language}
          />
        </>
      )}
    </Box>
  );
}
