import React from 'react';
import { Box } from '@mui/material'; // MUI Box component for layout

import { Avatar } from '@mui/joy'; // MUI Avatar component
import MuiTypography from '@mono-farmart-web/farmart-web-common/ComponentV3/Typography'; // Custom Typography component

import { farmerCardData } from '../../../../hooks/SmsHistoryHookDetailHook/types'; // TypeScript type for farmer card data

interface FarmerListProp {
  item: farmerCardData; // Prop type for the component
}

export default function ContactList(props: FarmerListProp) {
  const { item } = props; // Destructure item from props

  const avatarName = checkAvatarName(); // Get avatar initials

  // Function to generate avatar initials
  function checkAvatarName() {
    const words = item.name.split(' '); // Split name into words
    const firstName = words[0]; // Get first name
    const lastName = words[words.length - 1]; // Get last name

    // Generate initials based on name parts
    const initials =
      words.length > 1
        ? `${firstName.charAt(0).toUpperCase()}${lastName
            .charAt(0)
            .toUpperCase()}`
        : `${firstName.charAt(0).toUpperCase()}`;
    return initials; // Return initials
  }

  return (
    <Box
      padding="12px" // Padding for the box
      bgcolor={'background.level1'} // Background color
      display={'flex'} // Flexbox layout
      height={35} // Height of the box
      borderRadius={8} // Border radius for rounded corners
      marginBottom={'8px'} // Margin bottom
      overflow={'hidden'}
    >
      <Box display={'flex'} alignItems={'center'} gap={'12px'} flex={1} overflow={'hidden'}>
        <Avatar variant="outlined" size="sm">
          {avatarName} {/* Display avatar initials */}
        </Avatar>
        <Box
          display={'flex'}
          flexDirection={'column'}
          gap={'2px'}
          overflow={'hidden'}
        >
          <MuiTypography
            level="title-sm"
            textColor={'text.secondary'}
            overflow={'hidden'}
            whiteSpace={'nowrap'}
            textOverflow={'ellipsis'}
          >
            {item.name} {/* Display farmer's name */}
          </MuiTypography>
          <MuiTypography level="label-sm" textColor={'text.tertiary'}>
            {item.mobile_number} {/* Display farmer's mobile number */}
          </MuiTypography>
        </Box>
      </Box>
    </Box>
  );
}
