import { Box, Drawer } from '@mui/joy';
import useBottomDrawer from '../../../hooks/BottomDrawer/useBottomDrawer';
import MuiButton from '@mono-farmart-web/farmart-web-common/ComponentV3/MuiButton';
import { trackEvent } from '@mono-farmart-web/farmart-web-common/utils/moengage';
import Events from '@mono-farmart-web/farmart-web-common/constant/MoengageEvents/index';
import { Text } from '@mono-farmart-web/farmart-web-common/MuiComponents/atoms/typography';
import { Row } from '@mono-farmart-web/farmart-web-common/MuiComponents/atoms/layout';

export default function ReplaceImageModal({
  /*   deleteTransactionModalOpenClose,
  onClickDeleteTransactionModal,
  deleteTransaction, */
  language,
  replaceImageModalVisible,
  onClickReplaceImage,
  toggleReplaceImageModal,
}) {
  const { drawerProps } = useBottomDrawer({});
  return (
    <Drawer
      {...drawerProps}
      sx={{ zIndex: 10000 }}
      slotProps={{
        backdrop: {
          sx: {
            backdropFilter: 'blur(0.1px)', // Optional blur effect
          },
        },
        content: {
          sx: {
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius:
              'var(--radius-xl, 16px) var(--radius-xl, 16px) 0px 0px',
            background: 'var(--background-level1, #F0F4F8)',
            height: 'auto',
            boxSizing: 'border-box',
            '@media (min-width: 1025px) and (max-width: 1280px)': {
              width: '30vw',
              margin: '0vw 35vw',
            },
            '@media (min-width: 1281px)': {
              width: '30vw',
              margin: '0vw 35vw',
            },
          },
        },
      }}
      open={replaceImageModalVisible}
      onClose={toggleReplaceImageModal}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          height: '100%',
          width: '100%',
          boxSizing: 'border-box',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            padding: 'var(--4, 16px) 0px',
            flexDirection: 'column',
            alignItems: 'flex-start',
            background: 'var(--background-body, #FFF)',
            width: '100%',
            boxSizing: 'border-box',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              padding: 'var(--none, 0px) var(--4, 16px)',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: 'var(--3, 12px)',
              width: '100%',
              boxSizing: 'border-box',
            }}
          >
            <Row
              paddingTop={2}
              paddingLeft={4}
              paddingRight={4}
              paddingBottom={4}
              justifyContent={"center"}
              alignItems={"center"}
              width={"100%"}
              boxSizing={"border-box"}>
              <Text
                level={"title-sm"}
                fontFamily={"Noto Sans"}
                fontWeight={600}
                textColor={"text.secondary"}
                textAlign={"center"}>
                {language?.replaceImageModalTitle}
              </Text>
            </Row>
            <MuiButton
              color="primary"
              variant="solid"
              size="md"
              onClick={onClickReplaceImage}
            >
              {language?.replaceImageModalButton}
            </MuiButton>
            <MuiButton
              color="neutral"
              variant="soft"
              size="md"
              onClick={()=>toggleReplaceImageModal('')}
              onClickCapture={()=>trackEvent(Events?.onBahiKhataImagePreviewConfirmationButtonClick,{
                Button_Name:"No"
              })}
            >
              {language?.no}
            </MuiButton>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
}
