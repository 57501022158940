import React, { useEffect, useState } from 'react';
import {
  Crop,
  CropVariety,
  Location,
} from '@mono-farmart-web/farmart-web-common/modal/Traders/types';
import getCropList from '@mono-farmart-web/farmart-web-common/modal/Traders/models/CropList';
import getCropVarietyList from '@mono-farmart-web/farmart-web-common/modal/Traders/models/CropVarietyList';
import getBuyerLocationList from '@mono-farmart-web/farmart-web-common/modal/Traders/models/BuyerLocationList';
import { FilterType } from './types';
import { trackEvent } from '@mono-farmart-web/farmart-web-common/utils/moengage';
import Events from '@mono-farmart-web/farmart-web-common/constant/MoengageEvents/index';
import { usePlatfromData } from '@mono-farmart-web/farmart-web-common/context/CrossPlatformActionHandler/platformChecker';
import { unVerifiedRoutes } from '@mono-farmart-web/farmart-web-common/constant/unVerifiedRoutes';
import { loginAsGuest } from '@mono-farmart-web/farmart-web-common/modal/auth/auth.model';
import { useAuth } from '@mono-farmart-web/farmart-web-common/context/login';

export default function UseFilterValues(
  setTradeListPageNumber,
  setBuyerList,
  setActiveTradeList,
  setExpiredTradeList,
  setTotalRecords,
): FilterType {
  const [guestLoginAttempts, setGuestLoginAttempts] = useState<number>(0);
  const platformData = usePlatfromData();
  const auth = useAuth();
  const [cropList, setCropList] = useState<Crop[]>([]);

  const [cropVarietyList, setCropVarietyList] = useState<CropVariety[]>([]);

  const [locationList, setLocationList] = useState<Location[]>([]);

  const [cropListSearchParam, setCropListSearchParam] = useState<string>('');

  const [cropVarietyListCropId, setCropVarietyListCropId] =
    useState<number>(null);
  const [cropVarietyListSearchParam, setCropVarietyListSearchParam] =
    useState<string>('');
  const [cropVarietyListPage, setVarietyCropListPage] = useState<number>(1);
  const [cropVarietyListLimit, setCropVarietyListLimit] = useState<number>(10);

  const [buyerLocationListSearchParam, setBuyerLocationListSearchParam] =
    useState<string>('');
  const [buyerLocationListPage, setBuyerLocationListPage] = useState<number>(1);

  const [cropListModal, setCropListModal] = useState<boolean>(false);

  const [activeCrop, setActiveCrop] = useState<Crop>({
    id: null,
    crop_name: 'Crop',
  });
  const [activeVariety, setActiveVariety] = useState<number[]>([]);
  const [activeLocationName, setActiveLocationName] = useState<string[]>([]);

  const [activeVarietyName, setActiveVarietyName] = useState<string[]>([]);

  const [activeLocations, setActiveLocations] = useState<string[]>([null]);
  const [cropLoading, setCropLoading] = useState<boolean>(false);
  const [cropVarietyLoading, setCropVarietyLoading] = useState<boolean>(false);
  const [locationLoading, setLocationLoading] = useState<boolean>(false);
  const [cropVarietyListModal, setCropVarietyListModal] =
    useState<boolean>(false);
  const [buyerLocationListModal, setBuyerLocationListModal] =
    useState<boolean>(false);
  const [applyFilterRefresh, setApplyFilterRefresh] = useState<boolean>(false);
  const [locationListCount, setLocationListCount] = useState<number>(0);
  const [isModalErrorVisible, setIsModalErrorVisible] =
    useState<boolean>(false);

  const buyerLocationListLimit = 10;
  const [locationFilterApplied, setLocationFilterApplied] =
    useState<boolean>(false);
  const [variertFilterApplied, setVariertFilterApplied] =
    useState<boolean>(false);
  const [reloadRefresh, setReloadRefresh] = useState<boolean>(false);
  const handleCropListModal = () => {
    setIsModalErrorVisible(false);
    if (!cropListModal) {
      trackEvent(Events.onFilterTagButtonClick, {
        filterName: 'Crop',
      });

      getCropFilterValues();
    } else {
      if (isModalErrorVisible) {
        trackEvent(Events.onAppBottomFailureModalCrossButtonClick, {});
      }
      trackEvent(Events.onCropFilterCloseButtonClick, {});
    }
    setCropListModal(!cropListModal);
    setCropList([]);
    setCropListSearchParam('');
  };

  const handleCropVarietyListModal = () => {
    setIsModalErrorVisible(false);
    if (!cropVarietyListModal) {
      trackEvent(Events.onFilterTagButtonClick, {
        filterName: 'Variety',
      });
      getCropVarietyValues();
    } else {
      if (isModalErrorVisible) {
        trackEvent(Events.onAppBottomFailureModalCrossButtonClick, {});
      }
      trackEvent(Events.onVarityFilterCloseButtonClick, {});
    }

    setCropVarietyListModal(!cropVarietyListModal);
    setCropVarietyList([]);
    setCropVarietyListSearchParam('');
  };

  const handleBuyerLocationListModal = () => {
    setIsModalErrorVisible(false);
    if (buyerLocationListModal) {
      if (isModalErrorVisible) {
        trackEvent(Events.onAppBottomFailureModalCrossButtonClick, {});
      }
      trackEvent(Events.onSelectLocationCloseFilterButtonClick, {});
    } else {
      trackEvent(Events.onFilterTagButtonClick, {
        filterName: 'Location',
      });
    }
    setLocationList([]);
    setBuyerLocationListPage(1);
    setBuyerLocationListModal(!buyerLocationListModal);
    setBuyerLocationListSearchParam('');
    setLocationListCount(0);
    if(!locationFilterApplied){
      setActiveLocations([null]);
    }
  };

  const handleCropSelection = (cropId: Crop) => {
    if (cropId?.id === null || cropId.id !== activeCrop?.id) {
      setActiveVariety([]);
    }
    trackEvent(Events.onSelectCrop, {
      Crop_selected: cropId.crop_name,
    });
    setTotalRecords(0);

    setTradeListPageNumber(1);

    setBuyerList((prevState) => []);
    setExpiredTradeList((prevState) => []);
    setActiveTradeList((prevState) => []);
    setActiveCrop(cropId);
    setCropListModal(false);
    setCropVarietyList((prevState) => []);
    setCropList((prevState) => []);
    setCropListSearchParam('');
    setActiveVariety([]);
    setVariertFilterApplied(false);
    setActiveVarietyName([]);
  };

  const handleVarietySelection = (
    cropVarietyId: number,
    cropVarietyName: string,
  ) => {
    if (cropVarietyId) {
      if (!activeVariety.includes(cropVarietyId)) {
        setActiveVarietyName([...activeVarietyName, cropVarietyName]);
        setActiveVariety([...activeVariety, cropVarietyId]);
      } else {
        setActiveVariety(
          activeVariety.filter((element) => element !== cropVarietyId),
        );
        setActiveVarietyName(
          activeVarietyName.filter((element) => element !== cropVarietyName),
        );
      }
    } else {
      setActiveVariety([]);
    }
  };

  const handleLocationSelection = (
    locationId: string,
    districtName: string,
  ) => {
    trackEvent(Events.onSelectLocation, {});

    if (locationId) {
      if (activeLocations.includes(null)) {
        setActiveLocations(
          activeLocations
            .filter((element) => element !== null)
            .concat(locationId),
        );

        setActiveLocationName([...activeLocationName, districtName]);
      } else {
        setActiveLocationName(
          activeLocationName.includes(districtName)
            ? activeLocationName?.length === 1
              ? activeLocationName.filter((element) => element !== districtName)
              : activeLocationName.filter((element) => element !== districtName)
            : [...activeLocationName, districtName],
        );

        setActiveLocations(
          activeLocations.includes(locationId)
            ? activeLocations?.length === 1
              ? activeLocations
                  .filter((element) => element !== locationId)
                  .concat(null)
              : activeLocations.filter((element) => element !== locationId)
            : [...activeLocations, locationId],
        );
      }
    } else {
      setActiveLocations([null]);
    }
  };

  const handleCropSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value: string = event.target.value;

    setCropList([]);
    setCropListSearchParam(value);
  };

  const handleCropVarietySearch = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value: string = event.target.value;
    setCropVarietyList([]);
    setCropVarietyListSearchParam(value);
  };

  const handleLocationSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value: string = event.target.value;
    if (value) {
      setLocationList([]);
      setLocationListCount(0);
      setBuyerLocationListPage(1);
      setBuyerLocationListSearchParam(value);
    } else {
      setLocationList([]);
      setLocationListCount(0);
      setBuyerLocationListPage(1);
      setBuyerLocationListSearchParam(value);
    }
  };

  const getCropFilterValues = async () => {
    setCropLoading(true);
    try {
      const cropListResponse = await getCropList({
        search_param: cropListSearchParam,
        page: 1,
      });
      setCropList([...cropList, ...cropListResponse?.crops]);
      setCropLoading(false);
      setGuestLoginAttempts(0);
      setIsModalErrorVisible(false);
    } catch (error) {
      setCropLoading(false);
      if (error?.code === 401 || error?.code === 426) {
        if (platformData.platform === 'WEB') {
          if (unVerifiedRoutes?.guestLogin?.includes(error?.path)) {
            if (guestLoginAttempts < 3) {
              await loginAsGuest();
              setGuestLoginAttempts((lastValue) => {
                return lastValue + 1;
              });
            }
          } else {
            auth.setMobileNumberModalVisible(true);
          }
        }
      } else {
        trackEvent(Events.onStartAppBottomFailureModal, {});

        setIsModalErrorVisible(true);
      }
    }
  };

  const getCropVarietyValues = async () => {
    setCropVarietyLoading(true);
    try {
      const cropVerietyResponse = await getCropVarietyList({
        crop_id: activeCrop.id,
        search_param: cropVarietyListSearchParam,
        page: cropVarietyListPage,
      });
      setGuestLoginAttempts(0);
      setCropVarietyList([
        ...cropVarietyList,
        ...cropVerietyResponse?.crop_varieties,
      ]);
      setCropVarietyLoading(false);
      setIsModalErrorVisible(false);
    } catch (error) {
      setCropVarietyLoading(false);
      if (error?.code === 401 || error?.code === 426) {
        if (platformData.platform === 'WEB') {
          if (unVerifiedRoutes?.guestLogin?.includes(error?.path)) {
            if (guestLoginAttempts < 3) {
              await loginAsGuest();
              setGuestLoginAttempts((lastValue) => {
                return lastValue + 1;
              });
            }
          } else {
            auth.setMobileNumberModalVisible(true);
          }
        }
      } else {
        trackEvent(Events.onStartAppBottomFailureModal, {});

        setIsModalErrorVisible(true);
      }
      console.log(error);
    }
  };

  const getBuyerLocationValues = async () => {
    setLocationLoading(true);
    try {
      const buyerLocationResponse = await getBuyerLocationList({
        page_no: buyerLocationListPage,
        limit: buyerLocationListLimit,
        search_param: buyerLocationListSearchParam,
      });
      setGuestLoginAttempts(0);
      setLocationList([
        ...locationList,
        ...buyerLocationResponse?.location_list,
      ]);
      setLocationListCount(buyerLocationResponse?.total_records);
      setLocationLoading(false);
      setIsModalErrorVisible(false);
    } catch (error) {
      setLocationLoading(false);
      if (error?.code === 401 || error?.code === 426) {
        if (platformData.platform === 'WEB') {
          if (unVerifiedRoutes?.guestLogin?.includes(error?.path)) {
            if (guestLoginAttempts < 3) {
              await loginAsGuest();
              setGuestLoginAttempts((lastValue) => {
                return lastValue + 1;
              });
            }
          } else {
            auth.setMobileNumberModalVisible(true);
          }
        }
      } else {
        trackEvent(Events.onStartAppBottomFailureModal, {});

        setIsModalErrorVisible(true);
      }
    }
  };

  function onClickLocationFilterApply() {
    setBuyerLocationListPage(1);
    setTradeListPageNumber(1);
    setTotalRecords(0);

    setBuyerList([]);
    setExpiredTradeList([]);
    setActiveTradeList([]);
    setBuyerLocationListModal(false);

    setApplyFilterRefresh(!applyFilterRefresh);
    if (!activeLocations.includes(null)) {
      trackEvent(Events.onLocationFilterApplyButtonClick, {
        Lcation_selected: activeLocationName,
      });

      setLocationFilterApplied(true);
    } else {
      setLocationFilterApplied(false);
    }
  }

  function onClickVarietyFilterApply() {
    setTradeListPageNumber(1);
    setTotalRecords(0);

    setBuyerList([]);
    setExpiredTradeList([]);
    setActiveTradeList([]);
    setCropVarietyListModal(false);

    setApplyFilterRefresh(!applyFilterRefresh);
    if (activeVariety.length !== 0) {
      trackEvent(Events.onSelectVarity, {
        Varity_selected: activeVarietyName,
      });

      setVariertFilterApplied(true);
    } else {
      setVariertFilterApplied(false);
    }
  }

  const loadMoreLocationList = () => {
    if (locationListCount > (locationList?.length ?? 0)) {
      setBuyerLocationListPage((lastValue) => {
        return lastValue + 1;
      });
    }
  };

  useEffect(
    function () {
      cropListModal && getCropFilterValues();
    },
    [cropListSearchParam, guestLoginAttempts],
  );

  useEffect(
    function () {
      cropVarietyListModal && activeCrop?.id && getCropVarietyValues();
    },
    [cropVarietyListSearchParam, guestLoginAttempts],
  );

  useEffect(
    function () {
      buyerLocationListModal && getBuyerLocationValues();
    },
    [
      buyerLocationListSearchParam,
      buyerLocationListPage,
      buyerLocationListModal,
      guestLoginAttempts,
    ],
  );

  function onClickRemoveCropFilter() {
    setCropListSearchParam('');
    setActiveCrop({
      id: null,
      crop_name: 'Crop',
    });
    setActiveVariety([]);
    setTradeListPageNumber(1);
    setTotalRecords(0);

    setBuyerList([]);
    setExpiredTradeList([]);
    setActiveTradeList([]);
    setVariertFilterApplied(false);
  }

  function onClickRemoveVarietyFilter() {
    setActiveVarietyName([]);
    setActiveVariety([]);
    setTradeListPageNumber(1);
    setTotalRecords(0);

    setBuyerList([]);
    setExpiredTradeList([]);
    setActiveTradeList([]);
    setVariertFilterApplied(false);
    setApplyFilterRefresh(!applyFilterRefresh);
  }

  function onClickRemoveLocationFilter() {
    setActiveLocationName([]);
    setActiveLocations([null]);
    setTradeListPageNumber(1);
    setTotalRecords(0);

    setBuyerList([]);
    setExpiredTradeList([]);
    setActiveTradeList([]);
    setLocationFilterApplied(false);
    setApplyFilterRefresh(!applyFilterRefresh);
  }

  function onRefreshCropList() {
    trackEvent(Events.onAppBottomFailureModalRetryButtonClick, {});

    getCropFilterValues();
  }

  function onRefreshCropVarietyList() {
    trackEvent(Events.onAppBottomFailureModalRetryButtonClick, {});

    getCropVarietyValues();
  }

  function onRefreshLocationList() {
    trackEvent(Events.onAppBottomFailureModalRetryButtonClick, {});

    getBuyerLocationValues();
  }

  function resetFilters() {
    setBuyerList([]);
    setExpiredTradeList([]);
    setActiveTradeList([]);
    setTradeListPageNumber(1);
    setTotalRecords(0);
    setReloadRefresh(true);
    setCropListSearchParam('');
    setActiveCrop({
      id: null,
      crop_name: 'Crop',
    });
    setActiveVariety([]);
    setVariertFilterApplied(false);
    setActiveVarietyName([]);
    setApplyFilterRefresh(false);
    setActiveLocationName([]);
    setActiveLocations([null]);
    setLocationFilterApplied(false);
  }

  return {
    cropList,
    cropVarietyList,
    locationList,
    cropListSearchParam,
    setCropListSearchParam,
    cropVarietyListCropId,
    setCropVarietyListCropId,
    cropVarietyListSearchParam,
    setCropVarietyListSearchParam,
    cropVarietyListPage,
    setVarietyCropListPage,
    cropVarietyListLimit,
    setCropVarietyListLimit,
    buyerLocationListSearchParam,
    setBuyerLocationListSearchParam,
    buyerLocationListPage,
    setBuyerLocationListPage,
    cropListModal,
    handleCropListModal,
    cropVarietyListModal,
    handleCropVarietyListModal,
    buyerLocationListModal,
    handleBuyerLocationListModal,
    activeVariety,
    activeCrop,
    handleCropSelection,
    handleVarietySelection,
    cropLoading,
    cropVarietyLoading,
    activeLocations,
    handleLocationSelection,
    locationLoading,
    handleCropSearch,
    onClickLocationFilterApply,
    handleCropVarietySearch,
    handleLocationSearch,
    applyFilterRefresh,
    loadMoreLocationList,
    onClickRemoveCropFilter,
    onClickRemoveVarietyFilter,
    onClickRemoveLocationFilter,
    locationFilterApplied,
    isModalErrorVisible,
    onRefreshCropList,
    onRefreshCropVarietyList,
    onRefreshLocationList,
    onClickVarietyFilterApply,
    variertFilterApplied,
    resetFilters,
    reloadRefresh,
    setReloadRefresh,
    locationListCount,
  };
}
