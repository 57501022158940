import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import BahiKhataAcknowledgementScreen from '../../Pages/BahiKhataAcknowledgementScreen';
import { Column } from '@mono-farmart-web/farmart-web-common/MuiComponents/atoms/layout';
import { languageUrls } from '@mono-farmart-web/farmart-web-common/constant/languageUrls/index';
import getLang from '@mono-farmart-web/farmart-web-common/languages/bahiKhataAcknowledgement';
import useAppLanguage from '@mono-farmart-web/farmart-web-common/utils/applanguage';

const BahiKhataAcknowledgement = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { language } = useAppLanguage(
    languageUrls?.bahiKhataAcknowledgementScreenLangUr,
    getLang,
  );

  const {
    state: { username, amount, farmer_detail_id, transactionId },
  } = location;
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(`/bahiKhata/${farmer_detail_id}`,{
        replace: true,
      });
    }, 3000);

    // Cleanup function to clear the timeout
    return () => {
      clearTimeout(timeoutId);
      console.log('Timeout cleared');
    };
  }, []);

  function onClickBack() {
    navigate(`/bahiKhata/${farmer_detail_id}`,{
      replace: true,
    });
  }

  /* This `useEffect` hook is setting up an event listener for the `popstate` event on the `window`
    object. When the user navigates back in the browser history, the `popstate` event is triggered. */
  useEffect(() => {
    window.addEventListener('popstate', onClickBack);

    return () => {
      window.removeEventListener('popstate', onClickBack);
    };
  }, [navigate]);

  return (
    <Column
      width={'100vw'}
      height={'100dvh'}
      bgcolor={'background.body'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <BahiKhataAcknowledgementScreen
        transactionId={transactionId}
        language={language}
        username={username}
        amount={amount}
      />
    </Column>
  );
};

export default BahiKhataAcknowledgement;
