import { Box } from '@mui/joy';
import MuiTypography from '@mono-farmart-web/farmart-web-common/ComponentV3/Typography';
import React from 'react';
import {
  ExpandMoreRounded,
  PeopleOutlineRounded,
  SearchRounded,
  SortRounded,
} from '@mui/icons-material';
import bahiKhataFilterLangEnums from '@mono-farmart-web/farmart-web-common/constant/enums/bahiKhataFilterLangEnums';

interface FilterContainerProps {
  language: { [key: string]: string };
  onClickSeachKhata: () => void;
  toggleFilterModal: () => void;
  toggleSortingModal: () => void;
  selectedSorting: number;
  selectedFiler: number;
}

export default function FilterContainer(props: FilterContainerProps) {
  const {
    language,
    onClickSeachKhata,
    toggleFilterModal,
    toggleSortingModal,
    selectedSorting,
    selectedFiler,
  } = props;

  return (
    <Box>
      <Box>
        <Box
          border={1}
          height={40}
          display={'flex'}
          margin={'0px 16px'}
          marginTop={'16px'}
          borderRadius={'8px'}
          borderColor={'neutral.outlinedBorder'}
          bgcolor={'background.surface'}
          alignItems={'center'}
          gap={'8px'}
          onClick={onClickSeachKhata}
        >
          <Box display={'flex'} alignItems={'center'} marginLeft={'12px'}>
            <SearchRounded
              style={{
                height: '24px',
                width: '24px',
              }}
            />
          </Box>

          <Box>
            <MuiTypography textColor={'text.tertiary'}>
              {language?.searchKhata}
            </MuiTypography>
          </Box>
        </Box>
      </Box>

      <Box display={'flex'} margin={'16px 16px 0px 16px'}>
        <Box display={'flex'} gap={'8px'} alignItems={'center'} flex={1}>
          <PeopleOutlineRounded
            sx={{ height: '18px', width: '18px', color: '#9FA6AD' }}
          />
          <Box
            display={'flex'}
            alignItems={'center'}
            gap={'4px'}
            onClick={toggleFilterModal}
          >
            <MuiTypography level="body-xs" textColor={'text.secondary'}>
              {language[bahiKhataFilterLangEnums?.get(selectedFiler)]}
            </MuiTypography>
            <ExpandMoreRounded sx={{ height: '20px', width: '20px' }} />
          </Box>
        </Box>
        <Box
          display={'flex'}
          gap={'8px'}
          alignItems={'center'}
          onClick={toggleSortingModal}
        >
          {selectedSorting === 2 && (
            <Box
              height={8}
              width={8}
              position={'relative'}
              borderRadius={100}
              right={-28}
              bgcolor={'primary.solidBg'}
              bottom={5}
            />
          )}

          <SortRounded sx={{ height: '20px', width: '20px' }} />
        </Box>
      </Box>
    </Box>
  );
}
