import { Box, Drawer, Textarea } from '@mui/joy';
import useBottomDrawer from '../../../hooks/BottomDrawer/useBottomDrawer';
import MuiTypography from '@mono-farmart-web/farmart-web-common/ComponentV3/Typography';
import { CloseRounded } from '@mui/icons-material';
import MuiButton from '@mono-farmart-web/farmart-web-common/ComponentV3/MuiButton';

export default function AddNotetModal({
  noteModalOpenClose,
  onClicknoteModal,
  note,
  onChangeNote,
  noteCreateUpdate,
  language
}) {
  const { drawerProps } = useBottomDrawer();
  return (
    <Drawer
      {...drawerProps}
      sx={{ zIndex: 10000 }}
      slotProps={{
        backdrop: {
          sx: {
            backdropFilter: 'blur(0.1px)', // Optional blur effect
          },
        },
        content: {
          sx: {
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            alignItems: 'center',
            borderRadius:
              'var(--radius-xl, 16px) var(--radius-xl, 16px) 0px 0px',
            background: 'var(--background-body, #FFF)',
            height: 'auto',
            boxSizing: 'border-box',
            '@media (min-width: 1025px) and (max-width: 1280px)': {
              width: '30vw',
              margin: '0vw 35vw',
            },
            '@media (min-width: 1281px)': {
              width: '30vw',
              margin: '0vw 35vw',
            },
          },
        },
      }}
      open={noteModalOpenClose}
      onClose={onClicknoteModal}
    >
      <Box
        sx={{
          display: 'flex',
          padding: 'var(--4, 16px)',
          justifyContent: 'space-between',
          alignItems: 'center',
          alignSelf: 'stretch',
          borderBottom:
            '1px solid var(--neutral-outlined-DisabledBorder, #DDE7EE)',
          boxSizing: 'border-box',
        }}
      >
        <MuiTypography level="title-md" sx={{ color: '#171A1C' }}>
        {language?.addNote}
        </MuiTypography>
        <CloseRounded
          onClick={onClicknoteModal}
          sx={{ height: '24px', width: '24px', color: '#636B74' }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          padding: 'var(--4, 16px)',
          flexDirection: 'column',
          alignItems: 'flex-end',
          gap: 'var(--1, 4px)',
          alignSelf: 'stretch',
        }}
      >
        <Textarea
          sx={{
            display: 'flex',
            height: '120px',
            flexDirection: 'column',
            alignItems: 'flex-start',
            alignSelf: 'stretch',
            padding: '8px 12px',
            gap: '8px',
            borderRadius: 'var(--radius-md, 8px)',
            border: '1px solid var(--neutral-outlined-Border, #CDD7E1)',
            background: 'var(--background-surface, #FBFCFE)',
            boxShadow: '0px 1px 2px 0px rgba(187, 187, 187, 0.12)',
          }}
          slotProps={{
            textarea: {
              sx: {
                height: '120px',
                width: '100%',
                ':focus': {
                  border: 'none',
                  outline: 'none',
                },
              },
              maxLength:250
            },
          }}
          size="md"
          color="neutral"
          placeholder="Type note here"
          value={note}
          onChange={(e) => onChangeNote(e.target.value)}
        />
        <MuiTypography level="body-xxs" sx={{color:"#555E68"}} >{`${note?.length}/250`}</MuiTypography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          padding: 'var(--4, 16px)',
          alignItems: 'flex-start',
          gap: 'var(--4, 16px)',
          alignSelf: 'stretch',
        }}
      >
        <MuiButton size="lg" onClick={noteCreateUpdate}>
          {language?.save}
        </MuiButton>
      </Box>
    </Drawer>
  );
}
