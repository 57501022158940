import { Box, Drawer, Textarea } from '@mui/joy';
import useBottomDrawer from '../../../hooks/BottomDrawer/useBottomDrawer';
import MuiTypography from '@mono-farmart-web/farmart-web-common/ComponentV3/Typography';
import MuiButton from '@mono-farmart-web/farmart-web-common/ComponentV3/MuiButton';
import stringSubstitution from '@mono-farmart-web/farmart-web-common/utils/stringSubstitution';
import { trackEvent } from '@mono-farmart-web/farmart-web-common/utils/moengage';
import Events from '@mono-farmart-web/farmart-web-common/constant/MoengageEvents/index';

export default function ReminderModal({
  onClickReminderBell,
  reminderModalOpenClose,
  remindeUserBalance,
  khataName,
  balance,
  language,
  createdAt,
  mobileNumber,
  selectedTransactionDetailId,
}) {
  const { drawerProps } = useBottomDrawer();
  const replacedTemplate = function getTemplateDataArray() {
    const longDateIN = new Date(createdAt).toLocaleString('en-IN', {
      timeZone: 'Asia/Kolkata',
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });
    if (selectedTransactionDetailId) {
      return stringSubstitution(language?.remindKhataMessage || '', [
        khataName,
        longDateIN,
        balance,
        mobileNumber,
      ]);
    } else {
      return stringSubstitution(language?.remindKhataTotalMessage || '', [
        khataName,
        balance,
      ]);
    }
  };
  return (
    <Drawer
      {...drawerProps}
      sx={{ zIndex: 10000 }}
      slotProps={{
        backdrop: {
          sx: {
            backdropFilter: 'blur(0.1px)', // Optional blur effect
          },
        },
        content: {
          sx: {
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            alignItems: 'center',
            borderRadius:
              'var(--radius-xl, 16px) var(--radius-xl, 16px) 0px 0px',
            background: 'var(--background-body, #FFF)',
            height: 'auto',
            boxSizing: 'border-box',
            '@media (min-width: 1025px) and (max-width: 1280px)': {
              width: '30vw',
              margin: '0vw 35vw',
            },
            '@media (min-width: 1281px)': {
              width: '30vw',
              margin: '0vw 35vw',
            },
            zIndex: 1,
          },
        },
      }}
      open={reminderModalOpenClose}
      onClose={onClickReminderBell}
    >
      <Box
        sx={{
          display: 'flex',
          padding: 'var(--7, 32px) var(--4, 16px) var(--1, 4px) var(--4, 16px)',
          flexDirection: 'column',
          alignItems: 'center',
          alignSelf: 'stretch',
        }}
      >
        <MuiTypography level="title-sm" sx={{ color: '#171A1C' }}>
          {stringSubstitution(language?.remindKhataTitle, [khataName])}
        </MuiTypography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          padding: 'var(--4, 16px)',
          flexDirection: 'column',
          alignItems: 'flex-end',
          gap: 'var(--1, 4px)',
          alignSelf: 'stretch',
        }}
      >
        <Textarea
          disabled={true}
          sx={{
            display: 'flex',
            height: '120px',
            flexDirection: 'column',
            alignItems: 'flex-start',
            alignSelf: 'stretch',
            padding: '8px 12px',
            gap: '8px',
            borderRadius: 'var(--radius-md, 8px)',
            border: '1px solid var(--neutral-outlined-Border, #CDD7E1)',
            background: 'var(--background-surface, #FBFCFE)',
            boxShadow: '0px 1px 2px 0px rgba(187, 187, 187, 0.12)',
          }}
          slotProps={{
            textarea: {
              sx: {
                height: '120px',
                width: '100%',
                ':focus': {
                  border: 'none',
                  outline: 'none',
                },
              },
            },
          }}
          size="md"
          color="neutral"
          value={replacedTemplate()}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          padding: 'var(--4, 16px)',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: 'var(--3, 12px)',
          alignSelf: 'stretch',
        }}
      >
        <MuiButton size="md" color="primary" onClick={remindeUserBalance}>
          {language?.remindCredit}
        </MuiButton>
        <MuiButton
          onClick={onClickReminderBell}
          size="md"
          color="neutral"
          sx={{
            backgroundColor: '#F0F4F8',
            color: '#32383E',
            '&:hover': { backgroundColor: '#F0F4F8', color: '#32383E' },
          }}
          onClickCapture={() =>
            trackEvent(Events?.onSendReminderModalConfirmationButtonClick, {
              Button_Name: 'Cancel',
            })
          }
        >
          {language?.sendLater}
        </MuiButton>
      </Box>
    </Drawer>
  );
}
