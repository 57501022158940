import { Box, Chip, Divider } from '@mui/joy';

export default function TransactionDateContainer({
  date,
  bahiKhataDetailLoading,
  language,
}) {
  function formatDate(date) {
    const inputDate = new Date(date);
    const today = new Date();

    // Check if the input date is today
    const isToday =
      inputDate?.getDate() === today?.getDate() &&
      inputDate?.getMonth() === today?.getMonth() &&
      inputDate?.getFullYear() === today?.getFullYear();

    if (isToday) {
      return language?.today;
    }

    // Format date if it's not today
    return inputDate.toLocaleString('en-IN', {
      timeZone: 'Asia/Kolkata',
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });
  }
  return (
    <Box
      display={'flex'}
      padding={'var(--3, 12px) var(--4, 16px)'}
      alignItems={'center'}
      gap={'var(--2, 8px)'}
      justifyContent={'center'}
      boxSizing={'border-box'}
    >
      <Divider
        sx={{
          opacity: 0.4,
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          alignItems: 'flex-start',
          backgroundColor: 'var(--divider, rgba(99, 107, 116, 0.30))',
          alignSelf: 'center',
        }}
      />
      <Chip
        color="neutral"
        size="sm"
        variant="plain"
        sx={{ opacity: 0.4, background: 'var(--background-surface, #FBFCFE)' }}
      >
        {formatDate(date)}
      </Chip>
      <Divider
        sx={{
          opacity: 0.4,
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          alignItems: 'flex-start',
          backgroundColor: 'var(--divider, rgba(99, 107, 116, 0.30))',
          alignSelf: 'center',
        }}
      />
    </Box>
  );
}
