import MuiButton from '@mono-farmart-web/farmart-web-common/ComponentV3/MuiButton';
import AddFilledIcon from '@mono-farmart-web/farmart-web-common/assets/svgComponet/AddFilledIcon';
import { Box } from '@mui/joy';
import styles from './style.module.css';
import PlatForms from '@mono-farmart-web/farmart-web-common/constant/platFormEnums';
import { usePlatfromData } from '@mono-farmart-web/farmart-web-common/context/CrossPlatformActionHandler/platformChecker';

/* The `interface AddFarmerProps` is defining the structure of the props that the `AddFarmer` component
expects to receive. It consists of two properties: */
interface AddFarmerProps {
  language: { [key: string]: string };
  onClickAddFarmer: () => void;
}
export default function AddFarmer(props: AddFarmerProps) {
  const { language, onClickAddFarmer } = props;
  const platform = usePlatfromData();
  const bottomSpace =
    platform.platform === PlatForms?.get('application') ? 24 : 80;

  return (
    <Box className={styles.mainContainer} bottom={bottomSpace} zIndex={100}>
      <MuiButton
        sx={{
          minHeight: '40px',
          padding: '4px 16px 4px 12px',
          alignContent: 'center',
          justifyContent: 'center',
          borderRadius: '8px',
          width: 'auto', // Ensure width is set to auto
          whiteSpace: 'nowrap', // Prevents text from wrapping if it's too long
          marginRight: '16px',
        }}
        size="md"
        color="primary"
        startDecorator={<AddFilledIcon />}
        onClick={onClickAddFarmer}
        id="addFarmarPwa"
      >
        {language?.addNewFarmerButton}
      </MuiButton>
    </Box>
  );
}
