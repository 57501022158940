import { Box } from '@mui/joy';
import MuiButton from '@mono-farmart-web/farmart-web-common/ComponentsV3/MuiButton';
import { ArrowDownwardRounded, ArrowUpwardRounded } from '@mui/icons-material';
import TransactionType from '@mono-farmart-web/farmart-web-common/constant/enums/transactionType';

export default function MoneyTransferOptionContainer(props: any) {
  const { handleCredit, language } = props;
  return (
    <Box
      display={'flex'}
      padding={'var(--4, 16px)'}
      alignItems={'flex-start'}
      gap={'var(--4, 16px)'}
      borderTop={'1px solid var(--neutral-outlined-DisabledBorder, #DDE7EE)'}
      bgcolor={'var(--background-body, #FFF)'}
    >
      <MuiButton
        startDecorator={<ArrowDownwardRounded />}
        onClick={() => handleCredit(TransactionType?.debit)}
        id="bahikhata_receiveMoneybutton"
        data-testid="bahikhata_receiveMoneybutton"
      >
        {language?.recieveMoney}
      </MuiButton>
      <MuiButton
        startDecorator={<ArrowUpwardRounded />}
        onClick={() => handleCredit(TransactionType?.credit)}
        id="bahikhata_giveMoneybutton"
        data-testid="bahikhata_giveMoneybutton"
      >
        {language?.giveCredit}
      </MuiButton>
    </Box>
  );
}
