import CommunityHome from "../viewmodal/community/communityViewModel";
import PostDetails from "../viewmodal/postDetails/postDetailsViewModel";
import type React from "react";
import { Route } from "react-router-dom";
import LoginScreen from "../viewmodal/Login/loginScreen";
import OtpScreen from "../viewmodal/OTPScreen/otpScreen";
import UserRegistration from "../viewmodal/UserRegistration/userRegistration";
import HomeScreen from "../viewmodal/homeScreen/homeScreen";
import FaqQuestions from "../viewmodal/faqQuestions/faqQuestions";
import FaqAnswers from "../viewmodal/faqAnswers/faqAnswers";
import EditProfile from "../viewmodal/editProfile/editProfile";
import RakeList from "../viewmodal/rakeList/rakeList";
import PinCode from "../viewmodal/Pincode/pinCode";
import RakeDetail from "../viewmodal/RakeDetail/RakeDetail";
import AddFarmerScreen from "../viewmodal/AddFarmer/addFarmerScreen";
import AcknowledgeMentScreen from "../viewmodal/AcknowledgeMentScreen/acknowledgeMentScreen";
import MyFarmers from "../viewmodal/MyFarmer/myFarmerScreen";
import MyProfileMain from "../viewmodal/MyProfle/MyProfile";
import SmsHistory from "../viewmodal/SmsHistoryScreen/smsHistoryScreen";
import SmsHistoryScreenDetail from "../viewmodal/SmsHistoryScreenDetailScreen/smsHistoryDetailScreen";
import SmsFarmers from "../viewmodal/SmsFarmers/smsFarmersScreen";
import SmsAcknowledgeMentScreen from "../viewmodal/SmsAcknowledgeMentScreen/smsAcknowledgeMentScreen";
import SMSLandingScreen from "../viewmodal/SMSLandingScreen/SMSLandingScreen";
import SMSTemplate from "../viewmodal/SMSTemplateScreen/SMSTemplateScreen";
import CreditEntry from "../viewmodal/CreditEntry";
import TradeRoutes from "@mono-farmart-web/trade/routes";
import { TripConsentRoutes } from "@mono-farmart-web/track-consent";
import BahiKhataAcknowledgementScreen from "../viewmodal/BahiKhataAcknowledgementScreen";
import AddNewKhata from "../viewmodal/AddNewKhata";
import ContactList from "../viewmodal/ContactList";
import BahiKhataDetail from '../viewmodal/BahiKhataDetail';
import BahiKhataLanding from '../viewmodal/BahiKhataLanding';
import BahiKhataSearch from '../viewmodal/BahiKhataSearch';
import AppWalkthrough  from "../viewmodal/AppWalkthrough";
import UserInterest from '../viewmodal/UserInterest';
import Distributor from "../viewmodal/Distributor";
import TripTracking from "../viewmodal/TripTracking";

interface Route {
  path: string;
  component: React.ComponentType;
  exact: boolean;
  routes?: Route[];
}

export function getApplicationRoutes(): Route[] {
  const applicationRoutes: Route[] = [
    {
      path: '/',
      component: HomeScreen,
      exact: true,
    },
    {
      path: '/community',
      component: CommunityHome,
      exact: true,
    },
    {
      path: '/login',
      component: LoginScreen,
      exact: true,
    },
    {
      path: '/userRegistration',
      component: UserRegistration,
      exact: true,
    },
    {
      path: '/otpSubmit',
      component: OtpScreen,
      exact: true,
    },
    {
      path: '/community/:id/:heading',
      component: PostDetails,
      exact: true,
    },

    {
      path: '/rake',
      component: RakeList,
      exact: true,
    },
    {
      path: '/manualLocation',
      component: PinCode,
      exact: true,
    },
    {
      path: '/faq/questions',
      component: FaqQuestions,
      exact: true,
    },
    {
      path: '/faq/answer/:questionId',
      component: FaqAnswers,
      exact: true,
    },
    {
      path: '/rakeDetail',
      component: RakeDetail,
      exact: true,
    },
    {
      path: '/addFarmerScreen',
      component: AddFarmerScreen,
      exact: true,
    },
    {
      path: '/success',
      component: AcknowledgeMentScreen,
      exact: true,
    },
    {
      path: '/myFarmers',
      component: MyFarmers,
      exact: true,
    },
    {
      path: '/myProfile',
      component: MyProfileMain,
      exact: true,
    },
    {
      path: '/editProfile',
      component: EditProfile,
      exact: true,
    },
    {
      path: '/smsHistory',
      component: SmsHistory,
      exact: true,
    },
    {
      path: '/smsHistoryDetail/:smsId',
      component: SmsHistoryScreenDetail,
      exact: true,
    },
    {
      path: '/smsFarmers/:smsId',
      component: SmsFarmers,
      exact: true,
    },
    {
      path: '/smsSuccess',
      component: SmsAcknowledgeMentScreen,
      exact: true,
    },
    {
      path: '/shopPromotion',
      component: SMSLandingScreen,
      exact: true,
    },
    {
      path: '/smsTemplate/:smsTypeId',
      component: SMSTemplate,
      exact: true,
    },
    {
      path: '/bahiKhata/:userId',
      component: BahiKhataDetail,
      exact: true,
    },
    {
      path: '/bahiKhata',
      component: BahiKhataLanding,
      exact: true,
    },
    { path: '/searchKhata', component: BahiKhataSearch, exact: true },
    {
      path: "/bahiKhata/creditEntry",
      component: CreditEntry,
      exact: true
    },
    {
      path: "/bahiKhata/creditEntry/acknowledgement",
      component: BahiKhataAcknowledgementScreen,
      exact: true
    },
    {
      path: "/bahiKhata/recordTransaction/addNewKhata",
      component: AddNewKhata,
      exact: true,
    },
    {
      path: "/bahiKhata/contacts",
      component: ContactList,
      exact: true
    },
    {
			path: "/appGuide",
			component: AppWalkthrough,
			exact: true,
		},
    {
			path: "/userInterest",
			component: UserInterest,
			exact: true,
		},
    {
			path: "/triptracking/:tripId/*",
			component: TripTracking,
			exact: true,
		},
    {
			path: "/distributor",
			component: Distributor,
			exact: true,
		},
  ];
  const registerRoutes = (routes: Route[]) => {
     applicationRoutes.push(...routes);
  }

  registerRoutes(TradeRoutes);
  registerRoutes(TripConsentRoutes);

  // push not found route at the end to handle this in router
  applicationRoutes.push(getNotFoundRoute());

  return applicationRoutes;
}

export default getApplicationRoutes();

function getNotFoundRoute(): Route {
  return {
    path: '*',
    component: HomeScreen,
    exact: false,
  };
}
