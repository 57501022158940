import React from 'react';
import { Box } from '@mui/material';
import Styles from './style.module.css';
import SearchRounded from '@mui/icons-material/SearchRounded';

interface SearchPinCodeProps {
  onSearchPinCode: (event: React.ChangeEvent<HTMLInputElement>) => void;
  pinCodeSearchValue: string;
  language: { [key: string]: string };
}

export default function Search(props: SearchPinCodeProps) {
  const { onSearchPinCode, pinCodeSearchValue, language } = props;

  return (
    <Box
      bgcolor={'primary.500'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <Box margin={'0px 0px'} display={'flex'} flex={1}>
        <Box display={'flex'} height={40} flex={1}>
          <Box
            display={'flex'}
            position={'absolute'}
            alignItems={'center'}
            alignSelf={'stretch'}
            height={40}
            justifyContent={'center'}
            marginLeft={'12px'}
          >
            <SearchRounded />
          </Box>

          <input
            onChange={onSearchPinCode}
            className={Styles.inputBoxContainer}
            placeholder={language?.searchPlaceHolder}
            style={{
              alignItems:'center',
              fontFamily: 'NotoSansDevanagari',
              fontSize: 14,
              fontWeight: 400,
              lineHeight: 24,
              color: '#555E68',
            }}
            value={pinCodeSearchValue}
          />
        </Box>
      </Box>
    </Box>
  );
}
