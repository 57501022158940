import { useState } from 'react';
import {
  Box,
  Column,
  Row,
} from '@mono-farmart-web/farmart-web-common/MuiComponents/atoms/layout';
import { ExpandLessRounded, ExpandMoreRounded } from '@mui/icons-material';
import { getBahiKhataLandingAssets } from '@mono-farmart-web/farmart-web-common/constant/imageUrls';
import { Button } from '@mui/joy';
import { Text } from '@mono-farmart-web/farmart-web-common/MuiComponents/atoms/typography';
import Calendar from 'react-calendar';
import TransactionEnum from '@mono-farmart-web/farmart-web-common/constant/enums/transactionType';
import getTranslatedLanguage from '@mono-farmart-web/farmart-web-common/utils/stringSubstitution';
import 'react-calendar/dist/Calendar.css';

interface UserDetailProp {
  userName: string;
  creditDate: Date;
  handleCreditDate: (date: Date) => void;
  transactionId: number;
  language: any;
  handleDateSelectionEvent: () => void;
  isOpenCalender: boolean;
}

type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];

const formatDate = (date) => {
  const months = [
    'Jan',
    'Feb',
    'March',
    'April',
    'May',
    'June',
    'July',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ];

  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  return `${day} ${month} ${year}`;
};

const UserDetails = (props: UserDetailProp) => {
  const {
    userName,
    creditDate,
    handleCreditDate,
    transactionId,
    language,
    handleDateSelectionEvent,
    isOpenCalender,
  } = props;

  const langId = JSON.parse(localStorage.getItem('userLanguage'));
  const bahiKhataLandingAssets = getBahiKhataLandingAssets(langId?.langCode);

  const iconColor =
    TransactionEnum?.['credit'] === transactionId ? '#FFC1BA' : '#A1E8A1';
  const titleText =
    TransactionEnum?.['credit'] === transactionId
      ? getTranslatedLanguage(language?.givingCredit, [userName])
      : getTranslatedLanguage(language?.receivingCredit, [userName]);

  return (
    // Main Container
    <Column
      gap={3}
      paddingLeft={4}
      paddingRight={4}
      minWidth={'360px'}
      width={'auto'}
      height={'120px'}
      boxSizing={'border-box'}
    >
      {/* TOP CONTAINER */}
      <Column justifyContent={'center'} alignItems={'center'} gap={2}>
        <Row
          width={'48px'}
          height={'48px'}
          bgcolor={iconColor}
          borderRadius={'9.22px'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <img
            src={bahiKhataLandingAssets?.bahiKhataWalletImg}
            width={'31px'}
            height={'31px'}
            alt="wallet-ico"
          />
        </Row>

        <Row
          gap={1}
          justifyContent={'center'}
          alignItems={'center'}
          overflow={'hidden'}
          width={'100%'}
        >
          <Text
            level={'body-sm'}
            fontWeight={400}
            textColor={'text.secondary'}
            whiteSpace={'nowrap'}
            overflow={'hidden'}
            textOverflow={'ellipsis'}
          >
            {titleText}
          </Text>
        </Row>
      </Column>

      {/* BOTTOM CONTAINER */}
      <Box
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        position={'relative'}
        id="bahikhata_dateChange"
        data-testid="bahikhata_dateChange"
        content-desc="bahikhata_dateChange"
      >
        <Button
          color="neutral"
          size="sm"
          onClick={handleDateSelectionEvent}
          endDecorator={
            !isOpenCalender ? (
              <ExpandMoreRounded
                sx={{
                  width: '20px',
                  height: '20px',
                  color: '#32383E',
                }}
              />
            ) : (
              <ExpandLessRounded
                sx={{
                  width: '20px',
                  height: '20px',
                  color: '#32383E',
                }}
              />
            )
          }
          sx={{
            minWidth: '125px',
            width: 'auto',
            height: '32px',
            backgroundColor: '#F0F4F8',
            padding: '2px 8px 2px 12px',
            '&:active': {
              background: '#F0F4F8',
            },
          }}
        >
          <Text
            fontFamily={'Noto Sans'}
            fontWeight={'600'}
            fontSize={'14px'}
            textColor={'neutral.outlinedColor'}
          >
            {formatDate(creditDate)}
          </Text>
        </Button>
        {isOpenCalender && (
          <Box zIndex={5} marginTop={'20px'}>
            <Calendar onChange={handleCreditDate} value={creditDate} />
          </Box>
        )}
      </Box>
    </Column>
  );
};

export default UserDetails;
