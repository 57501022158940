import React from 'react';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Sheet from '@mui/joy/Sheet';
import MuiTypography from '@mono-farmart-web/farmart-web-common/ComponentV3/Typography';
import MuiButton from '@mono-farmart-web/farmart-web-common/ComponentV3/MuiButton';
import { Box } from '@mui/joy';

interface ImagePermissionModalProps {
  isCameraPermissionModalVisible: boolean;
  handleClosePermissionModal: () => void;
  language: { [key: string]: string };
  onClickOpenSetting: () => void;
}

export const ImagePermissionModal: React.FC<ImagePermissionModalProps> = ({
  isCameraPermissionModalVisible,
  handleClosePermissionModal,
  language,
  onClickOpenSetting
}) => {
  return (
    <Modal
      aria-labelledby="permission-modal-title"
      aria-describedby="permission-modal-desc"
      open={isCameraPermissionModalVisible}
      onClose={handleClosePermissionModal}
      sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center',
        '& .MuiModal-backdrop': {
          backgroundColor: 'rgba(0, 0, 0, 0.5)'
        }
      }}
    >
      <Sheet
        variant="plain"
        sx={{
          maxWidth: 500,
          borderRadius: 'md',
          p: 0,
          boxShadow: 'none',
          backgroundColor: 'white',
          margin: '0px 16px',
          border: '1px solid #E0E0E0',
          '&:focus-visible': {
            outline: 'none'
          }
        }}
      >
        {/* Header section with title and close button */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',

            borderBottom: '1px solid #E0E0E0',
            margin: '14px 16px',

          }}
        >
            <Box
            display={'flex'}
            alignSelf={'stretch'}
            flex={1}
            alignItems={'center'}
            justifyContent={'space-between'}
            marginBottom={'4px'}
            >
          <MuiTypography level="title-md">{language?.cameraPermissionHeading}</MuiTypography>
          <ModalClose
            variant="plain"
            sx={{ position: 'relative', top: 0, right: 0 }}
          />
          </Box>
        </Box>

        {/* Content section */}
        <Box sx={{ margin: '0px 16px' }}>
          <MuiTypography level="body-sm" id="permission-modal-desc">
          {language?.cameraPermissionDesc}
          </MuiTypography>
        </Box>

        {/* Button section */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '12px',
            margin: '16px 16px',
            paddingTop: '16px',
          }}
        >
          <MuiButton 
            variant="plain" 
            onClick={handleClosePermissionModal} 
            size="md"
            sx={{ 
              width: 'fit-content',
              minWidth: 'unset'
            }}
          >
            {language?.notNow}
          </MuiButton>
          <MuiButton 
            variant="solid" 
            onClick={onClickOpenSetting} 
            size="md"
            sx={{ 
              width: 'fit-content',
              minWidth: 'unset'
            }}
          >
            {language?.goToSettings}
          </MuiButton>
        </Box>
      </Sheet>
    </Modal>
  );
};

export default ImagePermissionModal;
