import React from "react";
import { Column, Row } from "../../../atoms/layout";
import { Text } from "../../../atoms/typography";
import { Input } from "@mui/joy";
import "./style.css";

const FormField = ({ title, placeholder, value, handleInput, fieldType, name, disabled, id }) => {
  const phoneNumFileRef = React.createRef<HTMLInputElement>();
  const nameFileRef = React.createRef<HTMLInputElement>();
  const isPhone = name === "phone";

  const [isPhoneInputClicked, setIsPhoneInputClicked] = React.useState(false);
  const [isNameInputClicked, setIsNameInputClicked] = React.useState(false);

  return (
    <Column>
      <Text
        level={"title-sm"}
        textColor={"text.primary"}
        fontWeight={500}
        marginBottom={"6px"}>
        {title}
      </Text>
      {
        isPhone ? (
          <Row
            borderRadius={"8px"}
            border={isPhoneInputClicked ? "2px solid" : "1px solid"}
            borderColor={isPhoneInputClicked ? "primary.500" : "neutral.outlinedBorder"}
            py={2}
            px={3}
            justifyContent={"space-between"}
            alignItems={"center"}
            gap={2}
            onMouseOut={() => setIsPhoneInputClicked(false)}
            onClick={() => {
              phoneNumFileRef.current.focus()
              setIsPhoneInputClicked(true);
            }}
            bgcolor={"background.body"}>
            <Text
              level={"body-md"}
              fontWeight={400}
              textColor="text.secondary">{"+91"}</Text>
            <input
              ref={phoneNumFileRef}
              id={id}
              data-testid={id}
              content-desc={id}
              className="input-box"
              style={{
                border: "none",
                outline: "none",
                flex: 1,
                padding: 0,
                backgroundColor: "white",
                boxShadow: "none",
                height: "24px",
                fontSize: "16px",
                fontWeight: 400,
              }}
              disabled={disabled}
              type={fieldType}
              placeholder={placeholder}
              value={value}
              onChange={handleInput}/>
          </Row>
        ) : (
          <Row
            borderRadius={"8px"}
            border={isNameInputClicked ? "2px solid" : "1px solid"}
            borderColor={isNameInputClicked ? "primary.500" : "neutral.outlinedBorder"}
            py={2}
            px={3}
            justifyContent={"space-between"}
            alignItems={"center"}
            onMouseOut={() => setIsNameInputClicked(false)}
            gap={2}
            onClick={() => {
              nameFileRef.current.focus()
              setIsNameInputClicked(true);
            }}
            bgcolor={"background.body"}>
            <input
              ref={nameFileRef}
              className="input-box"
              id={id}
              data-testid={id}
              content-desc={id}
              style={{
                flex: 1,
                padding: 0,
                border: "none",
                outline: "none",
                backgroundColor: "white",
                boxShadow: "none",
                height: "24px",
                fontSize: "16px",
                fontWeight: 400,
              }}
              type={fieldType}
              disabled={disabled}
              placeholder={placeholder}
              value={value}
              onChange={(event) => {
                setIsNameInputClicked(true);
                handleInput(event);
              }}/>
          </Row>
        )
      }
    </Column>
  )
}

export default FormField;
