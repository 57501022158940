import { Box, Skeleton } from '@mui/joy';
import MuiTypography from '@mono-farmart-web/farmart-web-common/ComponentV3/Typography';

export default function BalanceContainerLoading() {
  return (
    <Box
      display={'flex'}
      padding={'var(--4, 16px)'}
      flexDirection={'column'}
      alignItems={'flex-end'}
      gap={'var(--1, 4px)'}
      bgcolor={'var(--background-surface, ##F0F4F8)'}
    >
      <Box
        display={'flex'}
        padding={'var(--3, 12px) var(--3, 12px) var(--2, 8px) var(--3, 12px)'}
        flexDirection={'column'}
        alignItems={'flex-start'}
        borderRadius={
          'var(--radius-lg, 12px) var(--radius-lg, 12px) var(--radius-sm, 4px) var(--radius-lg, 12px)'
        }
        bgcolor={'#FBFCFE'}
      >
        <Box
          display={'flex'}
          flexDirection={'column'}
          alignItems={'flex-start'}
          gap={'var(--3, 12px)'}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: 'var(--05, 2px)',
              alignSelf: 'stretch',
            }}
          >
            <Skeleton
              sx={{
                position: 'relative',
                height: '20px',
                width: '81px',
                borderRadius: 'var(--radius-sm, 4px)',
              }}
            />
            <Skeleton
              sx={{
                position: 'relative',
                height: '13px',
                width: '58px',
                borderRadius: 'var(--radius-sm, 4px)',
              }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: 'var(--2, 8px)',
              alignSelf: 'stretch',
            }}
          >
            <Skeleton
              sx={{
                position: 'relative',
                height: '136px',
                width: '136px',
                borderRadius: 'var(--radius-sm, 4px)',
              }}
            />
            <Skeleton
              sx={{
                position: 'relative',
                height: '20px',
                width: '129px',
                borderRadius: 'var(--radius-sm, 4px)',
              }}
            />
          </Box>
        </Box>
      </Box>
      <MuiTypography level="label-sm" sx={{ color: '#555E68' }}>
        ../..
      </MuiTypography>
    </Box>
  );
}
