import * as devAssets from './dev-assets.js';
import * as prodAssets from './prod-assets.js';
import * as stageAssets from './stage-assets.js';

const assets = getEnvBasedAssets();

function getEnvBasedAssets() {
  switch (import.meta.env.VITE_ENV) {
    case 'staging':
      return stageAssets;
    case 'production':
      return prodAssets;
    case 'development':
    default:
      return devAssets;
  }
}

export function getPincodeAssets(langCode) {
  return getAssets(langCode, assets.pincodeAssets);
}

export function getMyProfileAssets(langCode) {
  return getAssets(langCode, assets.myProfileAssets);
}

export function getCommunityAssets(langCode) {
  return getAssets(langCode, assets.communityAssets);
}

export function getSaudaDetailsAssets(langCode) {
  return getAssets(langCode, assets.saudaDetailsAssets);
}

export function getUploadDocAssets(langCode) {
  return getAssets(langCode, assets.uploadDocAssets);
}

export function getRakeDetailAssets(langCode) {
  return getAssets(langCode, assets.rakeDetailAssets);
}

export function getFaqAssets(langCode) {
  return getAssets(langCode, assets.faqAssets);
}

export function getMySaudaAssets(langCode) {
  return getAssets(langCode, assets.mySaudaAssets);
}

export function getBuyerDetailAssets(langCode) {
  return getAssets(langCode, assets.buyerDetailAssets);
}

export function getTradeScreenAssets(langCode) {
  return getAssets(langCode, assets.tradeScreenAssets);
}

export function getLoginScreenAssets(langCode) {
  return getAssets(langCode, assets.loginScreenAssets);
}

export function getHomeScreenAssets(langCode) {
  return getAssets(langCode, assets.homeScreenAssets);
}

export function getErrorStateAssets(langCode) {
  return getAssets(langCode, assets?.errorStateAssets);
}

export function getTrackConsentScreenAssets(langCode = 'EN') {
  return getAssets(langCode, assets?.trackConsentAssets);
}

export function paymentScreenAssets(langCode) {
  return getAssets(langCode, assets?.paymentAssets);
}

export function getBuyerSearchAssets(langCode) {
  return getAssets(langCode, assets.buyerSearchAssets);
}

export function getBahiKhataLandingAssets(langCode) {
  return getAssets(langCode, assets.bahiKhataLandingAssets);
}

export function getBahiKhataSearchAssets(langCode) {
  return getAssets(langCode, assets.bahiKhataSearchAssets);
}

export function getAssets(langCode, assetMap) {
  const selectedLangCode = langCode || assets.defaultLanguageCode;
  return assetMap.get(selectedLangCode);
}

export function bahiKhataDetailAssets(langCode) {
  return getAssets(langCode, assets?.bahiKhataDetails);
}

export function getWalkthroughAssets(langCode) {
  return getAssets(langCode, assets.walkthroughAssets);
}

export function getUserInterestAssets(langCode) {
  return getAssets(langCode, assets.userInterestAssets);
}

export function getSMSLandingScreenAssets(langCode){
  return getAssets(langCode, assets.shopPromotionBannerAssets);
}

export function getCommentInputAssets(langCode) {
  return getAssets(langCode, assets.commentInputAssets);
}

export function getMyFarmerAssets(langCode) {
  return getAssets(langCode, assets.myFarmerAssets);
}

export function getPostDetailsAssets(langCode) {
  return getAssets(langCode, assets.postDetailsAssets);
}

export function getRakeListAssets(langCode) {
  return getAssets(langCode, assets.rakeListAssets);
}

export function getSmallCardAssets(langCode) {
  return getAssets(langCode, assets.smallCardAssets);
}

export function getCommentCardAssets(langCode) {
  return getAssets(langCode, assets.commentCardAssets);
}

export function getDistributorAssets(langCode){
  return getAssets(langCode, assets.distributorAssets);
};
