import { useLanguage } from '../../context/language';
import { DEFAULT_LANGUAGE } from '../../constant/language';
import { useCallback, useEffect, useState } from 'react';

export default function useAppLanguage(
  languageUrl: string,
  fallbackLanguage: (lang: string) => any,
) {
  const { languageData } = useLanguage();
  const selectedLanguage = JSON.parse(
    localStorage?.getItem('userLanguage') || '{}',
  )?.langCode;
  const [language, setLanguage] = useState(fallbackLanguage(DEFAULT_LANGUAGE));
  const activeSystemLang = Intl.DateTimeFormat().resolvedOptions().locale;
  const fallbackValue = [
    {
      langCode: 'HI',
      languageNameGeneral: 'hindi',
      languageNameInHindi: 'हिंदी',
      languageNameInEnglish: 'Hindi',
      languageAlphabet: 'आ',
      backendLanguageCode: 1,
    },
  ];

  async function fetchLanguage(url: string) {
    try {
      const response = await fetch(`${url}`);
      return await response.json();
    } catch (error) {
      console.error('Error fetching data:========', error);
      throw error; // Re-throw the error for handling in the calling function
    }
  }

  async function getLanguageService() {
    const getLanguageJson = await fetchLanguage(languageUrl);

    const systemLanguage = systemLanguageDecider(languageData);
    const strigifiedLanguage=localStorage.getItem('userLanguage') || '';
    const langId = strigifiedLanguage && JSON.parse(strigifiedLanguage);

    const language =
      getLanguageJson[langId?.langCode || systemLanguage || DEFAULT_LANGUAGE];

    return language;
  }

  function systemLanguageDecider(value: any) {
    const matchedLang = value?.find((lang) =>
      activeSystemLang?.includes(lang.langCode.toLowerCase()),
    );
    const data = matchedLang?.langCode || fallbackValue[0]?.langCode; // Default to "HI" if no match is found
    const langId =
      matchedLang?.backendLanguageCode || fallbackValue[0]?.backendLanguageCode; // Default to null if no match is found
    const strigifiedLanguage=localStorage.getItem('userLanguage') || '';
    const localStorageLanguage = strigifiedLanguage && JSON.parse(strigifiedLanguage);
    if (!localStorageLanguage) {
      localStorage.setItem(
        'userLanguage',
        JSON.stringify({
          langCode: data,
          langId: langId,
        }),
      );
    }
    return matchedLang?.langCode;
  }

  const fetchData = useCallback(async () => {
    try {
      const data = await getLanguageService();
      setLanguage(data);
    } catch (error) {
      console.error('Error fetching language data:', error);
    }
  }, [languageData, selectedLanguage]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    language,
  };
}
