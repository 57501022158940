import { appLanguagesIndex, defaultLanguageCode } from '../appLanguages';

const homeScreenAssets = new Map([
  [
    'HI',
    {
      farmartLogo2:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/1fcbcf09-5619-4dd0-a6db-58f469883ae3_FarMartLogo2.svg',
      distributorImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/69e52505-899e-49fe-ab74-d2be1407de17_distributors.png',
      bahiKhataImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/b9a48776-a447-44fd-850f-b471964c3939_bahi%20khata.png',
      cropAdvisoryImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/2ed4cd3d-1f93-49a8-9ddb-aa596f217f3f_CropAdvisory.svg',
      dukaanPrachaarImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/999487a9-54be-49e7-9386-d6262deed263_DukaanPrachar.svg',
      rakeImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/db717c74-37d9-4a4c-a9ae-051ba933ee3e_Rake.svg',
      quizImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/73f30f27-ad19-4abf-9d8b-4f4a5bbfb663_quiz.png',
      backgroundImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/fab2daba-6012-42f2-a00e-668064aa5e4f_Background.png',
      distributorCardImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/20ae20d0-418a-4e5d-948c-38abce2007e3_DistribuitorsCard.svg',
      bahiKhataCardImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/e325503f-3794-4d01-a17f-cc60370a0aa7_BahiKhataCardImage.svg',
      cropAdvisoryCardImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/9f1ec0ec-30c2-408e-9e98-fe9ca7945ac4_CropAdvisoryCard.svg',
      dukaanPrachaarCardImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/7216d660-2ed1-49c0-845f-2722762183a6_DukaanPracharCard.svg',
      rakeCardImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/7e440c2d-8616-4f96-94f7-b2df019a4011_RakeCard.svg',
      quizCardImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/ca3a5462-c42f-443a-b0db-7e6601547a1a_QuizCard.svg',
      foodChainLogo:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/2ba5a0f4-fd2e-4f0d-9fa2-36cf5d650e34_FoodChainLogo.svg',
      startDecorationLine:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/835344ed-ea04-40fe-9567-72d9d5748c4f_StartDecorationLine.svg',
      endDecorationLine:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/059aaca0-89e0-4e1a-8e49-a095038d4428_EndDecorationLine.svg',
      whatsappIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/179ec8d4-fbb4-4288-a368-f0d7d5ad7d8f_Whatsapp.svg',
      addFilledIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/c4ce4bd8-78c9-4f39-8428-8960aa4cb53f_AddFilledIcon.svg',
      locationIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/e731f021-e9ba-4332-ba89-8b7217de73df_LocationSvg.svg',
      companyProfileIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/e33f7117-a108-42ff-8d40-74681a8dea9c_companyProfile.svg',
      clapIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/11/87c163ec-9a21-4e50-b7e0-51d9fb98171c_clap.png',
      clapDoneIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/11/d3d5f692-0eeb-495a-b5d5-da85f4aaac4b_clapDone.png',
    },
  ],
  [
    'EN',
    {
      farmartLogo2:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/1fcbcf09-5619-4dd0-a6db-58f469883ae3_FarMartLogo2.svg',
      distributorImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/69e52505-899e-49fe-ab74-d2be1407de17_distributors.png',
      bahiKhataImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/b9a48776-a447-44fd-850f-b471964c3939_bahi%20khata.png',
      cropAdvisoryImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/2ed4cd3d-1f93-49a8-9ddb-aa596f217f3f_CropAdvisory.svg',
      dukaanPrachaarImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/999487a9-54be-49e7-9386-d6262deed263_DukaanPrachar.svg',
      rakeImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/db717c74-37d9-4a4c-a9ae-051ba933ee3e_Rake.svg',
      quizImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/73f30f27-ad19-4abf-9d8b-4f4a5bbfb663_quiz.png',
      backgroundImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/fab2daba-6012-42f2-a00e-668064aa5e4f_Background.png',
      distributorCardImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/20ae20d0-418a-4e5d-948c-38abce2007e3_DistribuitorsCard.svg',
      bahiKhataCardImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/e325503f-3794-4d01-a17f-cc60370a0aa7_BahiKhataCardImage.svg',
      cropAdvisoryCardImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/9f1ec0ec-30c2-408e-9e98-fe9ca7945ac4_CropAdvisoryCard.svg',
      dukaanPrachaarCardImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/7216d660-2ed1-49c0-845f-2722762183a6_DukaanPracharCard.svg',
      rakeCardImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/7e440c2d-8616-4f96-94f7-b2df019a4011_RakeCard.svg',
      quizCardImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/ca3a5462-c42f-443a-b0db-7e6601547a1a_QuizCard.svg',
      foodChainLogo:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/2ba5a0f4-fd2e-4f0d-9fa2-36cf5d650e34_FoodChainLogo.svg',
      startDecorationLine:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/835344ed-ea04-40fe-9567-72d9d5748c4f_StartDecorationLine.svg',
      endDecorationLine:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/059aaca0-89e0-4e1a-8e49-a095038d4428_EndDecorationLine.svg',
      whatsappIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/179ec8d4-fbb4-4288-a368-f0d7d5ad7d8f_Whatsapp.svg',
      addFilledIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/c4ce4bd8-78c9-4f39-8428-8960aa4cb53f_AddFilledIcon.svg',
      locationIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/e731f021-e9ba-4332-ba89-8b7217de73df_LocationSvg.svg',
      companyProfileIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/e33f7117-a108-42ff-8d40-74681a8dea9c_companyProfile.svg',
      clapIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/11/87c163ec-9a21-4e50-b7e0-51d9fb98171c_clap.png',
      clapDoneIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/11/d3d5f692-0eeb-495a-b5d5-da85f4aaac4b_clapDone.png',
    },
  ],
  [
    'MR',
    {
      farmartLogo2:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/1fcbcf09-5619-4dd0-a6db-58f469883ae3_FarMartLogo2.svg',
      distributorImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/69e52505-899e-49fe-ab74-d2be1407de17_distributors.png',
      bahiKhataImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/b9a48776-a447-44fd-850f-b471964c3939_bahi%20khata.png',
      cropAdvisoryImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/2ed4cd3d-1f93-49a8-9ddb-aa596f217f3f_CropAdvisory.svg',
      dukaanPrachaarImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/999487a9-54be-49e7-9386-d6262deed263_DukaanPrachar.svg',
      rakeImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/db717c74-37d9-4a4c-a9ae-051ba933ee3e_Rake.svg',
      quizImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/73f30f27-ad19-4abf-9d8b-4f4a5bbfb663_quiz.png',
      backgroundImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/fab2daba-6012-42f2-a00e-668064aa5e4f_Background.png',
      distributorCardImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/20ae20d0-418a-4e5d-948c-38abce2007e3_DistribuitorsCard.svg',
      bahiKhataCardImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/e325503f-3794-4d01-a17f-cc60370a0aa7_BahiKhataCardImage.svg',
      cropAdvisoryCardImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/9f1ec0ec-30c2-408e-9e98-fe9ca7945ac4_CropAdvisoryCard.svg',
      dukaanPrachaarCardImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/7216d660-2ed1-49c0-845f-2722762183a6_DukaanPracharCard.svg',
      rakeCardImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/7e440c2d-8616-4f96-94f7-b2df019a4011_RakeCard.svg',
      quizCardImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/ca3a5462-c42f-443a-b0db-7e6601547a1a_QuizCard.svg',
      foodChainLogo:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/2ba5a0f4-fd2e-4f0d-9fa2-36cf5d650e34_FoodChainLogo.svg',
      startDecorationLine:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/835344ed-ea04-40fe-9567-72d9d5748c4f_StartDecorationLine.svg',
      endDecorationLine:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/059aaca0-89e0-4e1a-8e49-a095038d4428_EndDecorationLine.svg',
      whatsappIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/179ec8d4-fbb4-4288-a368-f0d7d5ad7d8f_Whatsapp.svg',
      addFilledIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/c4ce4bd8-78c9-4f39-8428-8960aa4cb53f_AddFilledIcon.svg',
      locationIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/e731f021-e9ba-4332-ba89-8b7217de73df_LocationSvg.svg',
      companyProfileIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/e33f7117-a108-42ff-8d40-74681a8dea9c_companyProfile.svg',
      clapIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/11/87c163ec-9a21-4e50-b7e0-51d9fb98171c_clap.png',
      clapDoneIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/11/d3d5f692-0eeb-495a-b5d5-da85f4aaac4b_clapDone.png',
    },
  ],
]);

const loginScreenAssets = new Map([
  [
    'HI',
    {
      whatsappIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/179ec8d4-fbb4-4288-a368-f0d7d5ad7d8f_Whatsapp.svg',
      farmartLogo:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/11/440fd623-6f6a-45a8-b4ce-06093cb4a741_fmtLogo.png',
      languageSelection:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/9278acc5-3bbc-487a-8acb-2a427320a149_LanguageSelectIcon.svg',
      backIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/53bbe6c3-4431-4e2b-8aa1-9245b51b0745_Back.svg',
      farmartLogoWhite:
        'https://d1fcioetl6if2w.cloudfront.net/fmtapp/asset/2025/2/19/c4eb07a9-3e08-4893-9808-505c86e6cb0a_farmartLogoWhite.png',
      languageSelectionWhite: 'https://d1fcioetl6if2w.cloudfront.net/fmtapp/asset/2025/2/19/df67c99e-3174-49c3-b36e-9b8d29edc531_change_language.png',
      loginHeaderBanner: 'https://d1fcioetl6if2w.cloudfront.net/fmtapp/asset/2025/2/19/2fd39c2c-3b59-47d6-8123-a0ae03cc54a4_login_banner.png',
      horizontalLine: 'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/12/5/2756d802-0587-4311-a7b0-8cae9d516e3f_Horizontal.png',
    },
  ],
  [
    'EN',
    {
      whatsappIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/179ec8d4-fbb4-4288-a368-f0d7d5ad7d8f_Whatsapp.svg',
      farmartLogo:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/11/440fd623-6f6a-45a8-b4ce-06093cb4a741_fmtLogo.png',
      languageSelection:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/9278acc5-3bbc-487a-8acb-2a427320a149_LanguageSelectIcon.svg',
      backIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/53bbe6c3-4431-4e2b-8aa1-9245b51b0745_Back.svg',
      farmartLogoWhite:
        'https://d1fcioetl6if2w.cloudfront.net/fmtapp/asset/2025/2/19/c4eb07a9-3e08-4893-9808-505c86e6cb0a_farmartLogoWhite.png',
      languageSelectionWhite: 'https://d1fcioetl6if2w.cloudfront.net/fmtapp/asset/2025/2/19/df67c99e-3174-49c3-b36e-9b8d29edc531_change_language.png',
      loginHeaderBanner: 'https://d1fcioetl6if2w.cloudfront.net/fmtapp/asset/2025/2/19/2fd39c2c-3b59-47d6-8123-a0ae03cc54a4_login_banner.png',
      horizontalLine: 'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/12/5/2756d802-0587-4311-a7b0-8cae9d516e3f_Horizontal.png',
    },
  ],
  [
    'MR',
    {
      whatsappIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/179ec8d4-fbb4-4288-a368-f0d7d5ad7d8f_Whatsapp.svg',
      farmartLogo:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/11/440fd623-6f6a-45a8-b4ce-06093cb4a741_fmtLogo.png',
      languageSelection:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/9278acc5-3bbc-487a-8acb-2a427320a149_LanguageSelectIcon.svg',
      backIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/53bbe6c3-4431-4e2b-8aa1-9245b51b0745_Back.svg',
      farmartLogoWhite:
        'https://d1fcioetl6if2w.cloudfront.net/fmtapp/asset/2025/2/19/c4eb07a9-3e08-4893-9808-505c86e6cb0a_farmartLogoWhite.png',
      languageSelectionWhite: 'https://d1fcioetl6if2w.cloudfront.net/fmtapp/asset/2025/2/19/df67c99e-3174-49c3-b36e-9b8d29edc531_change_language.png',
      loginHeaderBanner: 'https://d1fcioetl6if2w.cloudfront.net/fmtapp/asset/2025/2/19/2fd39c2c-3b59-47d6-8123-a0ae03cc54a4_login_banner.png',
      horizontalLine: 'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/12/5/2756d802-0587-4311-a7b0-8cae9d516e3f_Horizontal.png',
    },
  ],
]);

const tradeScreenAssets = new Map([
  [
    'HI',
    {
      whatsappIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/179ec8d4-fbb4-4288-a368-f0d7d5ad7d8f_Whatsapp.svg',
      farmartLogo:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/11/440fd623-6f6a-45a8-b4ce-06093cb4a741_fmtLogo.png',
      languageSelection:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/9278acc5-3bbc-487a-8acb-2a427320a149_LanguageSelectIcon.svg',
      backIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/53bbe6c3-4431-4e2b-8aa1-9245b51b0745_Back.svg',
      activeSvg:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/bb3499cb-06c1-4b07-a100-9a2f92ceffe2_CheckboxActive.svg',
      inactiveSvg:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/f9ed0f34-8f2b-471a-9bf3-5450faec68fe_CheckboxInactive.svg',
      searchIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/b9c58c60-444c-4bbb-b60b-ae920b35a337_searchIcon.svg',
      tradeComingSoon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/5c399665-07be-4629-9a8c-bf0a8aefe79f_TradeComingSoonHI.svg',
      noBuyerList:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/3dd776d9-7f6c-4af6-a50c-1fddbf555f08_NoBuyerList.svg',
      noExpiredList:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/fc3e8dac-f52e-45f4-842d-5c705a8be53e_NoExpiredList.svg',
      locationIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/e731f021-e9ba-4332-ba89-8b7217de73df_LocationSvg.svg',
      discountCardBgSmall:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/fd95a4cf-c2c1-4621-b03c-e2b263dc5340_DiscountCardBackgroundSmall.svg',
      startDecorationLine:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2025/1/6/7424c6ae-c51e-49de-8383-3e884ec1f8a8_Horizontal.png',
      endDecorationLine:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2025/1/6/7424c6ae-c51e-49de-8383-3e884ec1f8a8_Horizontal.png',
    },
  ],
  [
    'EN',
    {
      whatsappIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/179ec8d4-fbb4-4288-a368-f0d7d5ad7d8f_Whatsapp.svg',
      farmartLogo:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/11/440fd623-6f6a-45a8-b4ce-06093cb4a741_fmtLogo.png',
      languageSelection:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/9278acc5-3bbc-487a-8acb-2a427320a149_LanguageSelectIcon.svg',
      backIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/53bbe6c3-4431-4e2b-8aa1-9245b51b0745_Back.svg',
      activeSvg:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/bb3499cb-06c1-4b07-a100-9a2f92ceffe2_CheckboxActive.svg',
      inactiveSvg:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/f9ed0f34-8f2b-471a-9bf3-5450faec68fe_CheckboxInactive.svg',
      searchIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/b9c58c60-444c-4bbb-b60b-ae920b35a337_searchIcon.svg',
      tradeComingSoon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/51eb38cc-6c01-4bb7-857d-662f6800dcb7_TradeComingSoonEN.svg',
      noBuyerList:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/3dd776d9-7f6c-4af6-a50c-1fddbf555f08_NoBuyerList.svg',
      noExpiredList:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/fc3e8dac-f52e-45f4-842d-5c705a8be53e_NoExpiredList.svg',
      locationIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/e731f021-e9ba-4332-ba89-8b7217de73df_LocationSvg.svg',
      discountCardBgSmall:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/fd95a4cf-c2c1-4621-b03c-e2b263dc5340_DiscountCardBackgroundSmall.svg',
      startDecorationLine:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2025/1/6/7424c6ae-c51e-49de-8383-3e884ec1f8a8_Horizontal.png',
      endDecorationLine:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2025/1/6/7424c6ae-c51e-49de-8383-3e884ec1f8a8_Horizontal.png',
    },
  ],
  [
    'MR',
    {
      whatsappIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/179ec8d4-fbb4-4288-a368-f0d7d5ad7d8f_Whatsapp.svg',
      farmartLogo:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/11/440fd623-6f6a-45a8-b4ce-06093cb4a741_fmtLogo.png',
      languageSelection:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/9278acc5-3bbc-487a-8acb-2a427320a149_LanguageSelectIcon.svg',
      backIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/53bbe6c3-4431-4e2b-8aa1-9245b51b0745_Back.svg',
      activeSvg:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/bb3499cb-06c1-4b07-a100-9a2f92ceffe2_CheckboxActive.svg',
      inactiveSvg:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/f9ed0f34-8f2b-471a-9bf3-5450faec68fe_CheckboxInactive.svg',
      searchIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/b9c58c60-444c-4bbb-b60b-ae920b35a337_searchIcon.svg',
      tradeComingSoon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/11/27/bbc85642-c088-43a0-98e9-2abb33cfc406_Marathi.png',
      noBuyerList:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/3dd776d9-7f6c-4af6-a50c-1fddbf555f08_NoBuyerList.svg',
      noExpiredList:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/fc3e8dac-f52e-45f4-842d-5c705a8be53e_NoExpiredList.svg',
      locationIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/e731f021-e9ba-4332-ba89-8b7217de73df_LocationSvg.svg',
      discountCardBgSmall:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/fd95a4cf-c2c1-4621-b03c-e2b263dc5340_DiscountCardBackgroundSmall.svg',
      startDecorationLine:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2025/1/6/7424c6ae-c51e-49de-8383-3e884ec1f8a8_Horizontal.png',
      endDecorationLine:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2025/1/6/7424c6ae-c51e-49de-8383-3e884ec1f8a8_Horizontal.png',
    },
  ],
]);

const buyerDetailAssets = new Map([
  [
    'HI',
    {
      discountCardBgBig:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/ec83dcf5-1ff7-417a-9e61-c9db7bec343f_DiscountCardBackgroundBig.svg',
      discountFeeSmiley:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/b7f39a12-1676-44ae-b5a1-4f447ee67ab7_discountFeeSmiley.svg',
      magicIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/8701c2ff-6b80-42a9-9763-6f0b5b8560e1_magicIcon.svg',
    },
  ],
  [
    'EN',
    {
      discountCardBgBig:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/ec83dcf5-1ff7-417a-9e61-c9db7bec343f_DiscountCardBackgroundBig.svg',
      discountFeeSmiley:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/b7f39a12-1676-44ae-b5a1-4f447ee67ab7_discountFeeSmiley.svg',
      magicIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/8701c2ff-6b80-42a9-9763-6f0b5b8560e1_magicIcon.svg',
    },
  ],
  [
    'MR',
    {
      discountCardBgBig:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/ec83dcf5-1ff7-417a-9e61-c9db7bec343f_DiscountCardBackgroundBig.svg',
      discountFeeSmiley:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/b7f39a12-1676-44ae-b5a1-4f447ee67ab7_discountFeeSmiley.svg',
      magicIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/8701c2ff-6b80-42a9-9763-6f0b5b8560e1_magicIcon.svg',
    },
  ],
]);

const mySaudaAssets = new Map([
  [
    'HI',
    {
      noSaudaFound:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/3dd776d9-7f6c-4af6-a50c-1fddbf555f08_NoBuyerList.svg',
    },
  ],
  [
    'EN',
    {
      noSaudaFound:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/3dd776d9-7f6c-4af6-a50c-1fddbf555f08_NoBuyerList.svg',
    },
  ],
  [
    'MR',
    {
      noSaudaFound:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/3dd776d9-7f6c-4af6-a50c-1fddbf555f08_NoBuyerList.svg',
    },
  ],
]);

const faqAssets = new Map([
  [
    'HI',
    {
      whatsappIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/179ec8d4-fbb4-4288-a368-f0d7d5ad7d8f_Whatsapp.svg',
    },
  ],
  [
    'EN',
    {
      whatsappIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/179ec8d4-fbb4-4288-a368-f0d7d5ad7d8f_Whatsapp.svg',
    },
  ],
  [
    'MR',
    {
      whatsappIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/179ec8d4-fbb4-4288-a368-f0d7d5ad7d8f_Whatsapp.svg',
    },
  ],
]);

const rakeDetailAssets = new Map([
  [
    'HI',
    {
      noRake:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/afec88ce-6ad6-4acb-b3a0-7a3c7073df8a_no%20rake.png',
    },
  ],
  [
    'EN',
    {
      noRake:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/afec88ce-6ad6-4acb-b3a0-7a3c7073df8a_no%20rake.png',
    },
  ],
  [
    'MR',
    {
      noRake:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/afec88ce-6ad6-4acb-b3a0-7a3c7073df8a_no%20rake.png',
    },
  ],
]);

const uploadDocAssets = new Map([
  [
    'HI',
    {
      invoiceDoc:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/dca5e032-be4d-4464-946b-93deb55389d5_Invoice%20Doc%20Empty%20State.png',
      weightSlip:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/c02b9910-8585-4516-9bb1-9789b16e4737_Weight%20Slip%20Empty%20State.png',
    },
  ],
  [
    'EN',
    {
      invoiceDoc:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/dca5e032-be4d-4464-946b-93deb55389d5_Invoice%20Doc%20Empty%20State.png',
      weightSlip:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/c02b9910-8585-4516-9bb1-9789b16e4737_Weight%20Slip%20Empty%20State.png',
    },
  ],
  [
    'MR',
    {
      invoiceDoc:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/dca5e032-be4d-4464-946b-93deb55389d5_Invoice%20Doc%20Empty%20State.png',
      weightSlip:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/c02b9910-8585-4516-9bb1-9789b16e4737_Weight%20Slip%20Empty%20State.png',
    },
  ],
]);

const saudaDetailsAssets = new Map([
  [
    'HI',
    {
      saudaDetailsImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/bf66ee29-9da6-44d7-800b-0172ff5deb7f_saudaDetailImage.png',
      whatsappIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/179ec8d4-fbb4-4288-a368-f0d7d5ad7d8f_Whatsapp.svg',
      activeSvg:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/bb3499cb-06c1-4b07-a100-9a2f92ceffe2_CheckboxActive.svg',
      inactiveSvg:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/f9ed0f34-8f2b-471a-9bf3-5450faec68fe_CheckboxInactive.svg',
      onTimeIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/a59624a2-558e-4818-8808-e518cfab115f_onTimeIcon2.svg',
      magicIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/8701c2ff-6b80-42a9-9763-6f0b5b8560e1_magicIcon.svg',
      arrowLeftIcon:
        'https://d1fcioetl6if2w.cloudfront.net/fmtapp/asset/2025/2/27/e40996d2-924e-469a-9aad-3c1745c38b4c_ArrowLeft.svg',
      arrowRightIcon:
        'https://d1fcioetl6if2w.cloudfront.net/fmtapp/asset/2025/2/27/6084a309-a7e3-4aa3-a103-fdaa40565346_ArrowRight.svg',
    },
  ],
  [
    'EN',
    {
      saudaDetailsImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/bf66ee29-9da6-44d7-800b-0172ff5deb7f_saudaDetailImage.png',
      whatsappIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/179ec8d4-fbb4-4288-a368-f0d7d5ad7d8f_Whatsapp.svg',
      activeSvg:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/bb3499cb-06c1-4b07-a100-9a2f92ceffe2_CheckboxActive.svg',
      inactiveSvg:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/f9ed0f34-8f2b-471a-9bf3-5450faec68fe_CheckboxInactive.svg',
      onTimeIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/a59624a2-558e-4818-8808-e518cfab115f_onTimeIcon2.svg',
      magicIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/8701c2ff-6b80-42a9-9763-6f0b5b8560e1_magicIcon.svg',
      arrowLeftIcon:
        'https://d1fcioetl6if2w.cloudfront.net/fmtapp/asset/2025/2/27/e40996d2-924e-469a-9aad-3c1745c38b4c_ArrowLeft.svg',
      arrowRightIcon:
        'https://d1fcioetl6if2w.cloudfront.net/fmtapp/asset/2025/2/27/6084a309-a7e3-4aa3-a103-fdaa40565346_ArrowRight.svg',
    },
  ],
  [
    'MR',
    {
      saudaDetailsImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/bf66ee29-9da6-44d7-800b-0172ff5deb7f_saudaDetailImage.png',
      whatsappIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/179ec8d4-fbb4-4288-a368-f0d7d5ad7d8f_Whatsapp.svg',
      activeSvg:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/bb3499cb-06c1-4b07-a100-9a2f92ceffe2_CheckboxActive.svg',
      inactiveSvg:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/f9ed0f34-8f2b-471a-9bf3-5450faec68fe_CheckboxInactive.svg',
      onTimeIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/a59624a2-558e-4818-8808-e518cfab115f_onTimeIcon2.svg',
      magicIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/8701c2ff-6b80-42a9-9763-6f0b5b8560e1_magicIcon.svg',
      arrowLeftIcon:
        'https://d1fcioetl6if2w.cloudfront.net/fmtapp/asset/2025/2/27/e40996d2-924e-469a-9aad-3c1745c38b4c_ArrowLeft.svg',
      arrowRightIcon:
        'https://d1fcioetl6if2w.cloudfront.net/fmtapp/asset/2025/2/27/6084a309-a7e3-4aa3-a103-fdaa40565346_ArrowRight.svg',
    },
  ],
]);

const communityAssets = new Map([
  [
    'HI',
    {
      avatarIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/01c36823-20de-46bf-b9a9-59e28fc07bc4_avatar.svg',
      addToHomeImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/1dd5b8df-5f7a-42c2-8be8-a192faf00061_farmartAddHome.png',
      farmartLogo:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/12c699c5-2924-43ed-845b-775fb44f041d_farmart.svg',
      companyProfileIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/e33f7117-a108-42ff-8d40-74681a8dea9c_companyProfile.svg',
      userProfileIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/b8226359-73bf-4d21-85ab-b65a877d37a7_userProfile.svg',
      whatsappIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/11/d5fad9b3-4f2b-49fe-bd06-a4dd75128cea_whatsapp.png',
      clapIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/11/87c163ec-9a21-4e50-b7e0-51d9fb98171c_clap.png',
      clapDoneIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/11/d3d5f692-0eeb-495a-b5d5-da85f4aaac4b_clapDone.png',
      chat: 'https://d1fcioetl6if2w.cloudfront.net/fmtapp/asset/2025/3/18/663cd45a-d6ec-480f-8d96-43c6d465465a_chat.png'
    },
  ],
  [
    'EN',
    {
      avatarIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/01c36823-20de-46bf-b9a9-59e28fc07bc4_avatar.svg',
      addToHomeImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/1dd5b8df-5f7a-42c2-8be8-a192faf00061_farmartAddHome.png',
      farmartLogo:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/12c699c5-2924-43ed-845b-775fb44f041d_farmart.svg',
      companyProfileIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/e33f7117-a108-42ff-8d40-74681a8dea9c_companyProfile.svg',
      userProfileIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/b8226359-73bf-4d21-85ab-b65a877d37a7_userProfile.svg',
      whatsappIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/11/d5fad9b3-4f2b-49fe-bd06-a4dd75128cea_whatsapp.png',
      clapIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/11/87c163ec-9a21-4e50-b7e0-51d9fb98171c_clap.png',
      clapDoneIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/11/d3d5f692-0eeb-495a-b5d5-da85f4aaac4b_clapDone.png',
      chat: 'https://d1fcioetl6if2w.cloudfront.net/fmtapp/asset/2025/3/18/663cd45a-d6ec-480f-8d96-43c6d465465a_chat.png'
    },
  ],
  [
    'MR',
    {
      avatarIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/01c36823-20de-46bf-b9a9-59e28fc07bc4_avatar.svg',
      addToHomeImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/1dd5b8df-5f7a-42c2-8be8-a192faf00061_farmartAddHome.png',
      farmartLogo:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/12c699c5-2924-43ed-845b-775fb44f041d_farmart.svg',
      companyProfileIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/e33f7117-a108-42ff-8d40-74681a8dea9c_companyProfile.svg',
      userProfileIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/b8226359-73bf-4d21-85ab-b65a877d37a7_userProfile.svg',
      whatsappIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/11/d5fad9b3-4f2b-49fe-bd06-a4dd75128cea_whatsapp.png',
      clapIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/11/87c163ec-9a21-4e50-b7e0-51d9fb98171c_clap.png',
      clapDoneIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/11/d3d5f692-0eeb-495a-b5d5-da85f4aaac4b_clapDone.png',
      chat: 'https://d1fcioetl6if2w.cloudfront.net/fmtapp/asset/2025/3/18/663cd45a-d6ec-480f-8d96-43c6d465465a_chat.png'
    },
  ],
]);
const myProfileAssets = new Map([
  [
    'HI',
    {
      languageToggleIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/e42e8a0d-ec10-4d59-9b72-934c99c9d360_languageSelection.svg',
    },
  ],
  [
    'EN',
    {
      languageToggleIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/e42e8a0d-ec10-4d59-9b72-934c99c9d360_languageSelection.svg',
    },
  ],
  [
    'MR',
    {
      languageToggleIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/e42e8a0d-ec10-4d59-9b72-934c99c9d360_languageSelection.svg',
    },
  ],
]);
const pincodeAssets = new Map([
  [
    'HI',
    {
      noLocationImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/8/07bf655f-5ef5-4aef-a98b-798e90745143_noLocationFound.png',
    },
  ],
  [
    'EN',
    {
      noLocationImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/8/07bf655f-5ef5-4aef-a98b-798e90745143_noLocationFound.png',
    },
  ],
  [
    'MR',
    {
      noLocationImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/8/07bf655f-5ef5-4aef-a98b-798e90745143_noLocationFound.png',
    },
  ],
]);
const errorStateAssets = new Map([
  [
    'HI',
    {
      errorStateImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/12/19/bc1cddce-777e-41e0-b5f2-a2d1af529ea7_ErrorImage.png',
    },
  ],
  [
    'EN',
    {
      errorStateImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/12/19/bc1cddce-777e-41e0-b5f2-a2d1af529ea7_ErrorImage.png',
    },
  ],
  [
    'MR',
    {
      errorStateImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/12/19/bc1cddce-777e-41e0-b5f2-a2d1af529ea7_ErrorImage.png',
    },
  ],
]);

const trackConsentAssets = new Map([
  [
    'HI',
    {
      TruckDriverIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/12/19/fd64ddff-f57d-40fc-9bf0-2b2b75f47c32_driverIcon.png',
      trackingMobile:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/12/19/7c1123e7-0958-46df-b0ab-fd90f4bbce0b_trackingMobikle.png',
      jio: 'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/12/19/74bc5174-9bb3-44f5-928b-12e07b98a18f_jio.png',
      airtel:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/12/19/91c8cfa3-6f55-42e0-9778-d4f747504877_airtel.png',
      bsnl: 'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/12/19/6a528209-f22b-4d7e-bee0-7268dec5168c_bsnl.png',
      vi: 'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/12/19/0fc94505-26f3-404e-b63a-c9bdfd2e47ae_vi.png',
      truckIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/12/19/4925dbd2-7b8b-4258-95aa-c6d7841662ca_truck.png',
    },
  ],
  [
    'EN',
    {
      TruckDriverIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/12/19/fd64ddff-f57d-40fc-9bf0-2b2b75f47c32_driverIcon.png',
      trackingMobile:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/12/19/7c1123e7-0958-46df-b0ab-fd90f4bbce0b_trackingMobikle.png',
      jio: 'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/12/19/74bc5174-9bb3-44f5-928b-12e07b98a18f_jio.png',
      airtel:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/12/19/91c8cfa3-6f55-42e0-9778-d4f747504877_airtel.png',
      bsnl: 'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/12/19/6a528209-f22b-4d7e-bee0-7268dec5168c_bsnl.png',
      vi: 'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/12/19/0fc94505-26f3-404e-b63a-c9bdfd2e47ae_vi.png',
      truckIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/12/19/4925dbd2-7b8b-4258-95aa-c6d7841662ca_truck.png',
    },
  ],
  [
    'MR',
    {
      TruckDriverIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/12/19/fd64ddff-f57d-40fc-9bf0-2b2b75f47c32_driverIcon.png',
      trackingMobile:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/12/19/7c1123e7-0958-46df-b0ab-fd90f4bbce0b_trackingMobikle.png',
      jio: 'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/12/19/74bc5174-9bb3-44f5-928b-12e07b98a18f_jio.png',
      airtel:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/12/19/91c8cfa3-6f55-42e0-9778-d4f747504877_airtel.png',
      bsnl: 'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/12/19/6a528209-f22b-4d7e-bee0-7268dec5168c_bsnl.png',
      vi: 'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/12/19/0fc94505-26f3-404e-b63a-c9bdfd2e47ae_vi.png',
      truckIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/12/19/4925dbd2-7b8b-4258-95aa-c6d7841662ca_truck.png',
    },
  ],
]);

const paymentAssets = new Map([
  [
    'HI',
    {
      farmartLogo:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/12/23/db2ea07b-7b84-4559-8b65-8a0eb44f7f69_Farmart%20Logo%20(1).png',
      trustLogo:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/12/19/d7a9f7c8-94b8-4710-97f4-95cec297b2c6_padlock.png',
      moneyRiv:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/12/10/724d0cea-907f-4bf9-8e6c-55e2c1745b90_ruppee-trade.riv',
      background:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/12/19/68566a13-6fac-43a7-a98c-bebe4093abe1_image_payment.png',
      confetti:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/12/10/f218d1dc-cc17-43b1-abac-36b72877f4ce_confetti.riv',
    },
  ],
  [
    'EN',
    {
      farmartLogo:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/12/23/db2ea07b-7b84-4559-8b65-8a0eb44f7f69_Farmart%20Logo%20(1).png',
      trustLogo:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/12/19/d7a9f7c8-94b8-4710-97f4-95cec297b2c6_padlock.png',
      moneyRiv:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/12/10/724d0cea-907f-4bf9-8e6c-55e2c1745b90_ruppee-trade.riv',
      background:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/12/19/68566a13-6fac-43a7-a98c-bebe4093abe1_image_payment.png',
      confetti:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/12/10/f218d1dc-cc17-43b1-abac-36b72877f4ce_confetti.riv',
    },
  ],
  [
    'MR',
    {
      farmartLogo:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/12/23/db2ea07b-7b84-4559-8b65-8a0eb44f7f69_Farmart%20Logo%20(1).png',
      trustLogo:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/12/19/d7a9f7c8-94b8-4710-97f4-95cec297b2c6_padlock.png',
      moneyRiv:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/12/10/724d0cea-907f-4bf9-8e6c-55e2c1745b90_ruppee-trade.riv',
      background:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/12/19/68566a13-6fac-43a7-a98c-bebe4093abe1_image_payment.png',
      confetti:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/12/10/f218d1dc-cc17-43b1-abac-36b72877f4ce_confetti.riv',
    },
  ],
]);

const buyerSearchAssets = new Map([
  [
    'HI',
    {
      buyerSearch:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/12/19/b8c1b394-c2e3-452d-a756-ce9dd66c27bc_searchBuyer2.png',
      noSearchedBuyerFound:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/12/19/416ab5ec-5ec9-48c8-84a4-4c131de0285d_NoBuyerFound2.png',
    },
  ],
  [
    'EN',
    {
      buyerSearch:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/12/19/b8c1b394-c2e3-452d-a756-ce9dd66c27bc_searchBuyer2.png',
      noSearchedBuyerFound:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/12/19/416ab5ec-5ec9-48c8-84a4-4c131de0285d_NoBuyerFound2.png',
    },
  ],
  [
    'MR',
    {
      buyerSearch:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/12/19/b8c1b394-c2e3-452d-a756-ce9dd66c27bc_searchBuyer2.png',
      noSearchedBuyerFound:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/12/19/416ab5ec-5ec9-48c8-84a4-4c131de0285d_NoBuyerFound2.png',
    },
  ],
]);

const bahiKhataDetails = new Map([
  [
    'HI',
    {
      cash: "https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2025/1/30/2acc395a-27c3-4c9c-b667-3819e26ea0c9_paisa.png",
      wallet: "https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2025/1/30/5b9db6d1-e28a-4248-8048-e2d76bfad881_walley.png"
    },
  ],
  [
    'EN',
    {
      cash: "https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2025/1/30/2acc395a-27c3-4c9c-b667-3819e26ea0c9_paisa.png",
      wallet: "https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2025/1/30/5b9db6d1-e28a-4248-8048-e2d76bfad881_walley.png"
    },
  ],
  [
    'MR',
    {
      cash: "https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2025/1/30/2acc395a-27c3-4c9c-b667-3819e26ea0c9_paisa.png",
      wallet: "https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2025/1/30/5b9db6d1-e28a-4248-8048-e2d76bfad881_walley.png"
    },
  ],
]);

const bahiKhataLandingAssets = new Map([
  [
    'HI',
    {
      noTransactionImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2025/1/30/2acc395a-27c3-4c9c-b667-3819e26ea0c9_paisa.png',
      bahiKhataSecureIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2025/1/30/dd913893-aded-432b-89f4-194bb2d7e9cc_secure.png',
      bahiKhataFeatureImg:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2025/1/30/0f8112c6-c7bb-4ed3-99b6-2e0b88312eab_5c001d21-62d9-477d-bfc9-11b69cf2e6ea_bahi_khata.png',
      bahiKhataWalletImg:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2025/1/30/5b9db6d1-e28a-4248-8048-e2d76bfad881_walley.png',
      bahiKhataContactImg:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2025/1/30/d40608f3-bce6-46d9-9634-f8aec83d3a44_contact_permission.png',
    },
  ],
  [
    'EN',
    {
      noTransactionImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2025/1/30/2acc395a-27c3-4c9c-b667-3819e26ea0c9_paisa.png',
      bahiKhataSecureIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2025/1/30/dd913893-aded-432b-89f4-194bb2d7e9cc_secure.png',
      bahiKhataFeatureImg:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2025/1/30/0f8112c6-c7bb-4ed3-99b6-2e0b88312eab_5c001d21-62d9-477d-bfc9-11b69cf2e6ea_bahi_khata.png',
      bahiKhataWalletImg:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2025/1/30/5b9db6d1-e28a-4248-8048-e2d76bfad881_walley.png',
      bahiKhataContactImg:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2025/1/30/d40608f3-bce6-46d9-9634-f8aec83d3a44_contact_permission.png',
    },
  ],
  [
    'MR',
    {
      noTransactionImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2025/1/30/2acc395a-27c3-4c9c-b667-3819e26ea0c9_paisa.png',
      bahiKhataSecureIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2025/1/30/dd913893-aded-432b-89f4-194bb2d7e9cc_secure.png',
      bahiKhataFeatureImg:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2025/1/30/0f8112c6-c7bb-4ed3-99b6-2e0b88312eab_5c001d21-62d9-477d-bfc9-11b69cf2e6ea_bahi_khata.png',
      bahiKhataWalletImg:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2025/1/30/5b9db6d1-e28a-4248-8048-e2d76bfad881_walley.png',
      bahiKhataContactImg:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2025/1/30/d40608f3-bce6-46d9-9634-f8aec83d3a44_contact_permission.png',
    },
  ],
]);

const bahiKhataSearchAssets = new Map([
  [
    'HI',
    {
      noKhataFoundImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2025/1/30/6dd26ea6-c4be-42cd-a7b5-55cd8fbbcc29_noKhata.png',
      bahiKhataFeatureImg:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2025/1/30/0f8112c6-c7bb-4ed3-99b6-2e0b88312eab_5c001d21-62d9-477d-bfc9-11b69cf2e6ea_bahi_khata.png',
    },
  ],
  [
    'EN',
    {
      noKhataFoundImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2025/1/30/6dd26ea6-c4be-42cd-a7b5-55cd8fbbcc29_noKhata.png',

      bahiKhataFeatureImg:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2025/1/30/0f8112c6-c7bb-4ed3-99b6-2e0b88312eab_5c001d21-62d9-477d-bfc9-11b69cf2e6ea_bahi_khata.png',
    },
  ],
  [
    'MR',
    {
      noKhataFoundImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2025/1/30/6dd26ea6-c4be-42cd-a7b5-55cd8fbbcc29_noKhata.png',
      bahiKhataFeatureImg:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2025/1/30/0f8112c6-c7bb-4ed3-99b6-2e0b88312eab_5c001d21-62d9-477d-bfc9-11b69cf2e6ea_bahi_khata.png',
    },
  ],
]);

const walkthroughAssets = new Map([
  [
    'HI',
    {
      languageChangeIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2025/2/6/4f0584f1-4465-4373-ae82-0705e4c8e4a3_languageSelectionGreen.png',
    },
  ],
  [
    'EN',
    {
      languageChangeIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2025/2/6/4f0584f1-4465-4373-ae82-0705e4c8e4a3_languageSelectionGreen.png',
    },
  ],
  [
    'MR',
    {
      languageChangeIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2025/2/6/4f0584f1-4465-4373-ae82-0705e4c8e4a3_languageSelectionGreen.png',
    },
  ],
]);

const userInterestAssets = new Map([
  [
    'HI',
    {
      languageSelection:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/9278acc5-3bbc-487a-8acb-2a427320a149_LanguageSelectIcon.svg',
      farmartLogo:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/11/440fd623-6f6a-45a8-b4ce-06093cb4a741_fmtLogo.png',
    },
  ],
  [
    'EN',
    {
      languageSelection:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/9278acc5-3bbc-487a-8acb-2a427320a149_LanguageSelectIcon.svg',
      farmartLogo:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/11/440fd623-6f6a-45a8-b4ce-06093cb4a741_fmtLogo.png',
    },
  ],
  [
    'MR',
    {
      languageSelection:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/9278acc5-3bbc-487a-8acb-2a427320a149_LanguageSelectIcon.svg',
      farmartLogo:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/11/440fd623-6f6a-45a8-b4ce-06093cb4a741_fmtLogo.png',
    },
  ],
]);

const shopPromotionBannerAssets = new Map([
  [
    'HI',
    {
      shopPromotionBanner: 'https://d1fcioetl6if2w.cloudfront.net/fmtapp/asset/2025/2/27/1ca6f54a-9a9f-44ca-a158-b387a175d5f9_ShopPromotionBannerHindi.png',
    },
  ],
  [
    'EN',
    {
      shopPromotionBanner: 'https://d1fcioetl6if2w.cloudfront.net/fmtapp/asset/2025/2/27/995fd1cd-beac-48d0-a765-f8cf6970c0d8_ShopPromotionBannerEnglish.png',
    },
  ],
  [
    'MR',
    {
      shopPromotionBanner: 'https://d1fcioetl6if2w.cloudfront.net/fmtapp/asset/2025/2/27/b62a2c81-57a2-4e47-8f4a-2c116024e01a_ShopPromotionBannerMarathi.png',
    },
  ],
]);

const commentInputAssets = new Map([
  [
    'HI',
    {
      sendIcon: 'https://d1fcioetl6if2w.cloudfront.net/fmtapp/asset/2025/2/27/784e7c95-634d-41ae-984b-910167dab207_send.svg',
      avatarIcon: 'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/01c36823-20de-46bf-b9a9-59e28fc07bc4_avatar.svg',
    }
  ],
  [
    'EN',
    {
      sendIcon: 'https://d1fcioetl6if2w.cloudfront.net/fmtapp/asset/2025/2/27/784e7c95-634d-41ae-984b-910167dab207_send.svg',
      avatarIcon: 'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/01c36823-20de-46bf-b9a9-59e28fc07bc4_avatar.svg',
    }
  ],
  [
    'MR',
    {
      sendIcon: 'https://d1fcioetl6if2w.cloudfront.net/fmtapp/asset/2025/2/27/784e7c95-634d-41ae-984b-910167dab207_send.svg',
      avatarIcon: 'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/01c36823-20de-46bf-b9a9-59e28fc07bc4_avatar.svg',
    }
  ]
]);

const myFarmerAssets = new Map([
  [
    'HI',
    {
      emptyStateIcon: 'https://d1fcioetl6if2w.cloudfront.net/fmtapp/asset/2025/2/27/6a9f71f6-285c-4b42-8dfb-0f449f18c6cb_MyFarmerEmptyState.svg',
    }
  ],
  [
    'EN',
    {
      emptyStateIcon: 'https://d1fcioetl6if2w.cloudfront.net/fmtapp/asset/2025/2/27/6a9f71f6-285c-4b42-8dfb-0f449f18c6cb_MyFarmerEmptyState.svg',
    }
  ],
  [
    'MR',
    {
      emptyStateIcon: 'https://d1fcioetl6if2w.cloudfront.net/fmtapp/asset/2025/2/27/6a9f71f6-285c-4b42-8dfb-0f449f18c6cb_MyFarmerEmptyState.svg',
    }
  ]
]);

const postDetailsAssets = new Map([
  [
    'HI',
    {
      downloadAppIcon: 'https://d1fcioetl6if2w.cloudfront.net/fmtapp/asset/2025/2/27/4a5506c9-db86-4756-8003-e83f52c8ae17_downloadImage.png',
    }
  ],
  [
    'EN',
    {
      downloadAppIcon: 'https://d1fcioetl6if2w.cloudfront.net/fmtapp/asset/2025/2/27/4a5506c9-db86-4756-8003-e83f52c8ae17_downloadImage.png',
    }
  ],
  [
    'MR',
    {
      downloadAppIcon: 'https://d1fcioetl6if2w.cloudfront.net/fmtapp/asset/2025/2/27/4a5506c9-db86-4756-8003-e83f52c8ae17_downloadImage.png',
    }
  ]
]);

const rakeListAssets = new Map([
  [
    'HI',
    {
      currentRakeIcon: 'https://d1fcioetl6if2w.cloudfront.net/fmtapp/asset/2025/2/27/7b7200d9-f549-4fcb-bd6d-4697cbcccded_CurrentRake.png',
    }
  ],
  [
    'EN',
    {
      currentRakeIcon: 'https://d1fcioetl6if2w.cloudfront.net/fmtapp/asset/2025/2/27/7b7200d9-f549-4fcb-bd6d-4697cbcccded_CurrentRake.png',
    }
  ],
  [
    'MR',
    {
      currentRakeIcon: 'https://d1fcioetl6if2w.cloudfront.net/fmtapp/asset/2025/2/27/7b7200d9-f549-4fcb-bd6d-4697cbcccded_CurrentRake.png',
    }
  ]
]);

const smallCardAssets = new Map([
  [
    'HI',
    {
      cowIcon: 'https://d1fcioetl6if2w.cloudfront.net/fmtapp/asset/2025/2/27/9b991030-09b6-4573-9587-0cef58e2c8d6_Cow.svg',
      callIcon: 'https://d1fcioetl6if2w.cloudfront.net/fmtapp/asset/2025/2/27/5b13cdfe-d2a9-4c9f-9da8-b9f777f4dbf1_Call.svg',
    }
  ],
  [
    'EN',
    {
      cowIcon: 'https://d1fcioetl6if2w.cloudfront.net/fmtapp/asset/2025/2/27/9b991030-09b6-4573-9587-0cef58e2c8d6_Cow.svg',
      callIcon: 'https://d1fcioetl6if2w.cloudfront.net/fmtapp/asset/2025/2/27/5b13cdfe-d2a9-4c9f-9da8-b9f777f4dbf1_Call.svg',
    }
  ],
  [
    'MR',
    {
      cowIcon: 'https://d1fcioetl6if2w.cloudfront.net/fmtapp/asset/2025/2/27/9b991030-09b6-4573-9587-0cef58e2c8d6_Cow.svg',
      callIcon: 'https://d1fcioetl6if2w.cloudfront.net/fmtapp/asset/2025/2/27/5b13cdfe-d2a9-4c9f-9da8-b9f777f4dbf1_Call.svg',
    }
  ]
]);

const commentCardAssets = new Map([
  [
    'HI',
    {
      avatarIcon: 'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/01c36823-20de-46bf-b9a9-59e28fc07bc4_avatar.svg',
      pinIcon: 'https://d1fcioetl6if2w.cloudfront.net/fmtapp/asset/2025/2/27/cbc57f2e-2ab4-4562-abc2-44e289c8138d_pin.svg',
      doneIcon: 'https://d1fcioetl6if2w.cloudfront.net/fmtapp/asset/2025/2/27/5bfd6c37-2141-4ecb-a32d-56036e6d790b_done.svg',
      clapIcon: 'https://d1fcioetl6if2w.cloudfront.net/fmtapp/asset/2025/2/27/76bb8c56-9098-41a3-9158-30233e668750_clap.svg'
    }
  ],
  [
    'EN',
    {
      avatarIcon: 'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/01c36823-20de-46bf-b9a9-59e28fc07bc4_avatar.svg',
      pinIcon: 'https://d1fcioetl6if2w.cloudfront.net/fmtapp/asset/2025/2/27/cbc57f2e-2ab4-4562-abc2-44e289c8138d_pin.svg',
      doneIcon: 'https://d1fcioetl6if2w.cloudfront.net/fmtapp/asset/2025/2/27/5bfd6c37-2141-4ecb-a32d-56036e6d790b_done.svg',
      clapIcon: 'https://d1fcioetl6if2w.cloudfront.net/fmtapp/asset/2025/2/27/76bb8c56-9098-41a3-9158-30233e668750_clap.svg'
    }
  ],
  [
    'MR',
    {
      avatarIcon: 'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/01c36823-20de-46bf-b9a9-59e28fc07bc4_avatar.svg',
      pinIcon: 'https://d1fcioetl6if2w.cloudfront.net/fmtapp/asset/2025/2/27/cbc57f2e-2ab4-4562-abc2-44e289c8138d_pin.svg',
      doneIcon: 'https://d1fcioetl6if2w.cloudfront.net/fmtapp/asset/2025/2/27/5bfd6c37-2141-4ecb-a32d-56036e6d790b_done.svg',
      clapIcon: 'https://d1fcioetl6if2w.cloudfront.net/fmtapp/asset/2025/2/27/76bb8c56-9098-41a3-9158-30233e668750_clap.svg'
    }
  ]
]);

const distributorAssets = new Map([
  [
    'HI',
    {
      fertiliserIcon:
        'https://d1fcioetl6if2w.cloudfront.net/compressed_images/fmtapp/asset/2025/3/13/23020f14-e332-40db-b76d-8f5259f8a0c1_fertiliser.png',
      seedIcon:
        'https://d1fcioetl6if2w.cloudfront.net/compressed_images/fmtapp/asset/2025/3/13/c35ed0c0-df47-4783-a0a5-8c585c18f471_seed.png',
      pesticideIcon:
        'https://d1fcioetl6if2w.cloudfront.net/compressed_images/fmtapp/asset/2025/3/13/47bf7ecf-1fa5-44e6-8e1b-ec35f140584d_medicine.png',
      noDistributorImg:
        'https://d1fcioetl6if2w.cloudfront.net/compressed_images/fmtapp/asset/2025/3/13/ffca5bd4-2cc9-424e-872a-eaa1617900e1_empty_distributor.png',
      noProductTypeModalImg:
        'https://d1fcioetl6if2w.cloudfront.net/compressed_images/fmtapp/asset/2025/3/13/ce162f77-88e6-4c6b-844a-a59bcc252e4e_empty_product.png',
    },
  ],
  [
    'EN',
    {
      fertiliserIcon:
        'https://d1fcioetl6if2w.cloudfront.net/compressed_images/fmtapp/asset/2025/3/13/23020f14-e332-40db-b76d-8f5259f8a0c1_fertiliser.png',
      seedIcon:
        'https://d1fcioetl6if2w.cloudfront.net/compressed_images/fmtapp/asset/2025/3/13/c35ed0c0-df47-4783-a0a5-8c585c18f471_seed.png',
      pesticideIcon:
        'https://d1fcioetl6if2w.cloudfront.net/compressed_images/fmtapp/asset/2025/3/13/47bf7ecf-1fa5-44e6-8e1b-ec35f140584d_medicine.png',
      noDistributorImg:
        'https://d1fcioetl6if2w.cloudfront.net/compressed_images/fmtapp/asset/2025/3/13/ffca5bd4-2cc9-424e-872a-eaa1617900e1_empty_distributor.png',
      noProductTypeModalImg:
        'https://d1fcioetl6if2w.cloudfront.net/compressed_images/fmtapp/asset/2025/3/13/ce162f77-88e6-4c6b-844a-a59bcc252e4e_empty_product.png',
    },
  ],
  [
    'MR',
    {
      fertiliserIcon:
        'https://d1fcioetl6if2w.cloudfront.net/compressed_images/fmtapp/asset/2025/3/13/23020f14-e332-40db-b76d-8f5259f8a0c1_fertiliser.png',
      seedIcon:
        'https://d1fcioetl6if2w.cloudfront.net/compressed_images/fmtapp/asset/2025/3/13/c35ed0c0-df47-4783-a0a5-8c585c18f471_seed.png',
      pesticideIcon:
        'https://d1fcioetl6if2w.cloudfront.net/compressed_images/fmtapp/asset/2025/3/13/47bf7ecf-1fa5-44e6-8e1b-ec35f140584d_medicine.png',
      noDistributorImg:
        'https://d1fcioetl6if2w.cloudfront.net/compressed_images/fmtapp/asset/2025/3/13/ffca5bd4-2cc9-424e-872a-eaa1617900e1_empty_distributor.png',
      noProductTypeModalImg:
        'https://d1fcioetl6if2w.cloudfront.net/compressed_images/fmtapp/asset/2025/3/13/ce162f77-88e6-4c6b-844a-a59bcc252e4e_empty_product.png',
    },
  ],
]);


export {
  pincodeAssets,
  myProfileAssets,
  communityAssets,
  saudaDetailsAssets,
  uploadDocAssets,
  rakeDetailAssets,
  faqAssets,
  mySaudaAssets,
  buyerDetailAssets,
  tradeScreenAssets,
  loginScreenAssets,
  homeScreenAssets,
  defaultLanguageCode,
  errorStateAssets,
  paymentAssets,
  trackConsentAssets,
  buyerSearchAssets,
  bahiKhataDetails,
  bahiKhataLandingAssets,
  bahiKhataSearchAssets,
  walkthroughAssets,
  userInterestAssets,
  shopPromotionBannerAssets,
  commentInputAssets,
  myFarmerAssets,
  postDetailsAssets,
  rakeListAssets,
  smallCardAssets,
  commentCardAssets,
  distributorAssets,
};
