import React from 'react';
import {
  Box,
  Drawer,
  Dropdown,
  IconButton,
  Menu,
  MenuItem,
  MenuButton,
  Textarea,
} from '@mui/joy';
import useBottomDrawer from '../../../hooks/BottomDrawer/useBottomDrawer';
import MuiTypography from '@mono-farmart-web/farmart-web-common/ComponentV3/Typography';
import MuiButton from '@mono-farmart-web/farmart-web-common/ComponentV3/MuiButton';
import ReplaceImageModal from '../ReplaceImageModal';
import {
  CloseRounded,
  DeleteRounded,
  ImageRounded,
  EditRounded,
  SmsRounded,
  CurrencyRupeeRounded,
  StickyNote2Rounded,
} from '@mui/icons-material';
import formatDateTime from '@mono-farmart-web/farmart-web-common/utils/DateAndTimeGB';
import stringSubstitute from '@mono-farmart-web/farmart-web-common/utils/stringSubstituteWithStyles';
import { trackEvent } from '@mono-farmart-web/farmart-web-common/utils/moengage';
import Events from '@mono-farmart-web/farmart-web-common/constant/MoengageEvents/index';
import transactionType from '@mono-farmart-web/farmart-web-common/constant/enums/transactionType';
import useCameraPermissionHook from '../../../hooks/CreditEntryHooks/checkCameraHooks'
import isAndroid from "@mono-farmart-web/farmart-web-common/utils/operatingSystemType";
import ImagePermissionModal from '../../CreditEntry/ImagePermission';

export default function PaymentDetailModal({
  onClickPaymentDetailModal,
  paymentDetailModalOpen,
  slectedTransactionDetail,
  onClickReminderBell,
  onClickDeleteTransactionModal,
  language,
  bahiKhataDetailAsset,
  onClickEditAmountModal,
  onClicknoteModal,
  handleOpenService,
  uploadedImage,
  preViewDocument,
  toggleDeleteImageModal,
  toggleReplaceImageModal,
  toggleReplaceImageModalWithFlag,
  openReplaceImageModal,
  handleCameraPermission
}) {
  const { drawerProps } = useBottomDrawer();
  const imageRef = React.createRef<HTMLInputElement>();
  const {
    handleClosePermissionModal,
    runUploadImageFlow,
    isCameraPermissionModalVisible,
    requestCameraPermission,
    onClickOpenSetting
    } = useCameraPermissionHook(
      imageRef
    );
  return (
    <Drawer
      {...drawerProps}
      slotProps={{
        backdrop: {
          sx: {
            backdropFilter: 'blur(0.1px)', // Optional blur effect
            backgroundColor: 'inherit',
          },
        },
        content: {
          sx: {
            padding: 'var(--2, 8px)',
            display: 'inline-flex',
            width: '100%',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            background: 'var(--Base-Base-25, #FFF)',
            height: '100%',
            boxSizing: 'border-box',
            '@media (min-width: 1025px) and (max-width: 1280px)': {
              width: '30vw',
              margin: '0vw 35vw',
            },
            '@media (min-width: 1281px)': {
              width: '30vw',
              margin: '0vw 35vw',
            },
          },
        },
      }}
      open={paymentDetailModalOpen}
      onClose={onClickPaymentDetailModal}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          padding: 'var(--2, 8px)',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <IconButton
          color="neutral"
          size="md"
          variant="plain"
          onClick={onClickPaymentDetailModal}
        >
          <CloseRounded />
        </IconButton>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            gap: 'var(--3, 12px)',
          }}
        >
          <IconButton
            color="neutral"
            size="md"
            variant="outlined"
            sx={{ borderRadius: '8px' }}
            onClick={onClickDeleteTransactionModal}
            id="bahikhata_deleteTransactionIcon"
            data-testid="bahikhata_deleteTransactionIcon"
            onClickCapture={() =>
              trackEvent(
                Events?.onDetailedTransactionScreenDeleteButtonClick,
                {},
              )
            }
          >
            <DeleteRounded sx={{ color: '#32383E' }} />
          </IconButton>
          <IconButton
            color="neutral"
            size="md"
            variant="outlined"
            sx={{
              borderRadius: '8px',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            id="bahikhata_EditImageIcon"
            data-testid="bahikhata_EditImageIcon"
            onClick={
              !isAndroid() || runUploadImageFlow
                ? (event) => {
                    event.stopPropagation();
                    if (
                      !slectedTransactionDetail?.image_proof_url &&
                      !uploadedImage?.data
                    ) {
                      imageRef.current.click();
                    } else {
                      toggleReplaceImageModalWithFlag(true);
                    }
                    trackEvent(
                      Events?.onDetailedTransactionScreenUploadImageButtonClick,
                      {},
                    );
                  }
                : requestCameraPermission()
            }
          >
            <ImageRounded sx={{ color: '#32383E' }} />
            {!isAndroid()|| runUploadImageFlow  ?<input
              ref={imageRef}
              style={{ display: 'none' }}
              type="file"
              className="photo-input"
              accept="image/*"
              onChange={(event) => {
                handleOpenService(event, 'image');
                if(slectedTransactionDetail?.image_proof_url || uploadedImage?.data) {
                  toggleReplaceImageModal();
                }
              }}
            />:<></>}
          </IconButton>

          <Box display={'flex'} gap="28px" alignItems={'center'}>
            <Dropdown>
              <MenuButton
                sx={{ padding: '0px', borderRadius: '8px', border: 'none' }}
              >
                <IconButton
                  color="neutral"
                  size="md"
                  variant="outlined"
                  sx={{ borderRadius: '8px' }}
                  id="bahikhata_EditTransactionIcon"
                  data-testid="bahikhata_EditTransactionIcon"
                >
                  <EditRounded sx={{ color: '#32383E' }} />
                </IconButton>
              </MenuButton>
              <Menu
                sx={{
                  zIndex: '10000',
                  display: 'inline-flex',
                  padding: '6px 0px',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  borderRadius: '8px',
                  background: 'var(--neutral-soft-Bg, #F0F4F8)',
                  boxShadow: `
                    0.3px 0.8px 1.1px 0px rgba(187, 187, 187, 0.11),
                    0.5px 1.3px 1.8px -0.6px rgba(187, 187, 187, 0.18),
                    1.1px 2.7px 3.8px -1.2px rgba(187, 187, 187, 0.26)
                  `,
                }}
                color="neutral"
                size="md"
                variant="soft"
                placement="bottom-end"
              >
                <MenuItem
                  sx={{
                    display: 'flex',
                    minHeight: '36px',
                    padding: '4px 12px',
                    alignItems: 'center',
                    gap: 'var(--2, 8px)',
                    backgroundColor: 'inherit',
                  }}
                  onClick={onClickEditAmountModal}
                  id="bahikhata_EditAmountButton"
                  data-testid="bahikhata_EditAmountButton"
                  onClickCapture={() =>
                    trackEvent(
                      Events?.onDetailedTransactionScreenEditButtonClick,
                      { Option_selected: 'Edit amount' },
                    )
                  }
                >
                  <CurrencyRupeeRounded
                    sx={{ height: '24px', width: '24px', color: '#636B74' }}
                  />

                  <MuiTypography
                    level="title-md"
                    color="neutral"
                    sx={{ color: '#32383E' }}
                  >
                    {language?.editAmount}
                  </MuiTypography>
                </MenuItem>
                <MenuItem
                  sx={{
                    display: 'flex',
                    minHeight: '36px',
                    padding: '4px 12px',
                    alignItems: 'center',
                    gap: 'var(--2, 8px)',
                    backgroundColor: 'inherit',
                  }}
                  onClick={onClicknoteModal}
                  id="bahikhata_EditNoteButton"
                  data-testid="bahikhata_EditNoteButton"
                  onClickCapture={() =>
                    trackEvent(
                      Events?.onDetailedTransactionScreenEditButtonClick,
                      { Option_selected: 'Edit note' },
                    )
                  }
                >
                  <StickyNote2Rounded
                    sx={{ height: '24px', width: '24px', color: '#636B74' }}
                  />
                  <MuiTypography level="title-md" sx={{ color: '#32383E' }}>
                    {language?.editNote}
                  </MuiTypography>
                </MenuItem>
              </Menu>
            </Dropdown>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            padding:
              'var(--6, 24px) var(--4, 16px) var(--2, 8px) var(--4, 16px)',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 'var(--2, 8px)',
            alignSelf: 'stretch',
          }}
        >
          <Box
            sx={{
              width: '48px',
              height: '48px',
              flexShrink: 0,
              borderRadius: '9.216px',
              background: '#FFC1BA',
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
            }}
          >
            <img
              src={bahiKhataDetailAsset?.wallet}
              style={{
                height: '30.72px',
                width: '30.72px',
                alignSelf: 'center',
              }}
              alt="wallet"
              loading="eager"
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-start',
              gap: 'var(--1, 4px)',
              alignSelf: 'stretch',
              overflow: 'hidden',
              width: '100%',
              boxSizing: 'border-box',
            }}
          >
            <MuiTypography
              level="body-sm"
              sx={{ color: '#555E68', fontWeight: '400' }}
              whiteSpace={'nowrap'}
              overflow={'hidden'}
              textOverflow={'ellipsis'}
            >
              {stringSubstitute(
                language?.giveCreditTo,
                [slectedTransactionDetail?.farmer_name],
                { fontWeight: '700' },
              )}
            </MuiTypography>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            padding: 'var(--4, 16px)',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: 'var(--3, 12px)',
            alignSelf: 'stretch',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              padding: 'var(--6, 24px) var(--5, 20px)',
              flexDirection: 'column',
              alignItems: 'flex-start',
              alignSelf: 'stretch',
              borderRadius: 'var(--radius-lg, 12px)',
              border:
                '1px solid var(--neutral-outlined-DisabledBorder, #DDE7EE)',
              background: 'var(--background-surface, #FBFCFE)',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 'var(--5, 20px)',
                alignSelf: 'stretch',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  gap: 'var(--2, 8px)',
                  alignSelf: 'stretch',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 'var(--1, 4px)',
                    alignSelf: 'stretch',
                  }}
                >
                  <MuiTypography
                    level="h2"
                    sx={{
                      color: '#171A1C',
                      fontWeight: '700',
                      fontFamily: 'NotoSans',
                    }}
                  >
                    ₹{slectedTransactionDetail?.amount?.toLocaleString('en-IN')}
                  </MuiTypography>
                </Box>
                <MuiTypography
                  level="body-xxs"
                  sx={{
                    color: '#555E68',
                    fontWeight: '500',
                    fontFamily: 'NotoSans',
                    alignSelf: 'center',
                  }}
                >
                  {formatDateTime(slectedTransactionDetail?.transaction_date)}
                </MuiTypography>
              </Box>
            </Box>
            {slectedTransactionDetail?.comment ? (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 'var(--3, 12px)',
                  alignSelf: 'stretch',
                  width: 'auto',
                }}
              >
                <Textarea
                  value={slectedTransactionDetail?.comment}
                  disabled={true}
                  sx={{
                    marginTop: '20px',
                    borderRadius: '8px',
                    padding: '6px 8px',
                    gap: '8px',
                    minHeight: '32px',
                    width: "100%",
                  }}
                  size="sm"
                  color="neutral"
                  slotProps={{
                    textarea: {
                      sx: {
                        ':focus': {
                          border: 'none',
                          outline: 'none',
                        },
                      },
                    },
                  }}
                />
              </Box>
            ) : null}
          </Box>
        </Box>
        <Box display={'flex'} alignSelf={'stretch'} padding={'0px 16px'}>
          {slectedTransactionDetail?.image_proof_url || uploadedImage?.data ? (
            <Box
              padding={2}
              gap={3}
              bgcolor={'primary.softDisabledBg'}
              border={'1px solid'}
              borderColor={'#DDE7EE'}
              borderRadius={'8px'}
              display={'flex'}
              alignSelf={'stretch'}
              flex={1}
              onClick={() =>
                preViewDocument(
                  slectedTransactionDetail?.image_proof_url ||
                    uploadedImage?.data,
                )
              }

              /* onClick={() =>
              navigate('/bahiKhata/creditEntry/viewDoc', {
              state: {
                fileUri: uploadedImage?.data,
                fileName: uploadedImage?.title,
              },})} */
            >
              {/* IMAGE */}
              <img
                src={
                  slectedTransactionDetail?.image_proof_url ||
                  uploadedImage?.data
                }
                alt="creditDocImage"
                style={{ width: '50px', height: '50px', objectFit: 'fill' }}
              />

              {/* IMAGE INFO */}
              <Box alignItems={'center'} justifyContent={'flex-start'} flex={1}>
                <Box gap={2}>
                  <MuiTypography
                    level={'title-sm'}
                    fontWeight={600}
                    textColor="text.primary"
                  >
                    {uploadedImage?.title||language?.image}
                  </MuiTypography>
                  <MuiTypography
                    level="body-xxs"
                    fontWeight={500}
                    textColor={'text.secondary'}
                  >
                    {uploadedImage?.size}
                  </MuiTypography>
                </Box>
              </Box>

              {/* CLOSE ICON */}
              <Box alignItems={'center'} justifyContent={'center'}>
                <Box
                  width={'32px'}
                  height={'32px'}
                  alignContent={'center'}
                  justifyContent={'center'}
                  flexWrap={'wrap'}
                >
                  <CloseRounded
                    id="bahikhata_imageDeleteIcon"
                    data-testid="bahikhata_imageDeleteIcon"
                    content-desc="bahikhata_imageDeleteIcon"
                    sx={{ width: '20px', height: '20px', color: '#32383E' }}
                    onClick={(event) => {
                      event?.stopPropagation();
                      toggleDeleteImageModal('cross');
                    }}
                  />
                </Box>
              </Box>
            </Box>
          ) : null}
        </Box>
      </Box>
      <ReplaceImageModal
          language={language}
          replaceImageModalVisible={openReplaceImageModal}
          onClickReplaceImage={() => {
            imageRef.current.click();
          }}
          toggleReplaceImageModal={toggleReplaceImageModal} />
      {slectedTransactionDetail?.transaction_type ===
      transactionType?.credit ? (
        <Box
          sx={{
            display: 'flex',
            padding: 'var(--6, 24px) var(--4, 16px)',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 'var(--5, 20px)',
          }}
        >
          <MuiButton
            size="md"
            color="neutral"
            variant="solid"
            startDecorator={<SmsRounded />}
            onClick={onClickReminderBell}
            id="bahikhata_RemindButton"
            data-testid="bahikhata_RemindButton"
            onClickCapture={() =>
              trackEvent(
                Events?.onDetailedTransactionScreenSendReminderButtonClick,
                {},
              )
            }
          >
            {language?.sendSmsReminder}
          </MuiButton>
        </Box>
      ) : null}
      <ImagePermissionModal
        isCameraPermissionModalVisible={isCameraPermissionModalVisible}
        handleClosePermissionModal={handleClosePermissionModal}
        language={language}
        onClickOpenSetting={onClickOpenSetting}
      />
    </Drawer>
  );
}
