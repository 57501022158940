import type React from "react";
import Style from "./style.module.css";

interface LoaderProps {
	color?: string;
  width?: string;
  height?: string
}

const Loader: React.FC<LoaderProps> = ({ color, width="10px", height="10px" }) => {
	const loaderStyle = {
		borderTop: `2px solid ${color}`,
		borderRight: `2px solid ${color}`,
		width,
		height
	};

	return <div className={Style.loader} style={loaderStyle} />
};

export default Loader;
