import BahiKhataLandingView from '../../Pages/BahiKhataLanding';
import BahiKhataLanding from '../../hooks/BahiKhataLanding';

export default function BahiKhataTransactionHistoryMain() {
  const {
    onPressBack,
    language,
    onContactUs,
    initialLoading,
    currentPage,
    loadMoreFarmers,
    khataList,
    onClickKhata,
    totalCount,
    onClicApplySorting,
    onClickApplyFilter,
    onClickSeachKhata,
    toggleFilterModal,
    toggleSortingModal,
    userBalance,
    balanceLoading,
    onClickCloseGuide,
    userGuideVisible,
    filterModalVisible,
    sortingModalVisible,
    filterValues,
    selectedFiler,
    selectedSorting,
    bahiKhataLandingAssets,
    onClickRecordNewTransaction,
    bahiKhataError,
    onClickRetry,
    initialCount,
    alert,
  } = BahiKhataLanding();

  return (
    <BahiKhataLandingView
      onPressBack={onPressBack}
      language={language}
      onContactUs={onContactUs}
      initialLoading={initialLoading}
      currentPage={currentPage}
      loadMoreFarmers={loadMoreFarmers}
      khataList={khataList}
      onClickKhata={onClickKhata}
      totalCount={totalCount}
      onClicApplySorting={onClicApplySorting}
      onClickApplyFilter={onClickApplyFilter}
      onClickSeachKhata={onClickSeachKhata}
      toggleFilterModal={toggleFilterModal}
      toggleSortingModal={toggleSortingModal}
      userBalance={userBalance}
      balanceLoading={balanceLoading}
      onClickCloseGuide={onClickCloseGuide}
      userGuideVisible={userGuideVisible}
      filterModalVisible={filterModalVisible}
      sortingModalVisible={sortingModalVisible}
      filterValues={filterValues}
      selectedFiler={selectedFiler}
      selectedSorting={selectedSorting}
      bahiKhataLandingAssets={bahiKhataLandingAssets}
      onClickRecordNewTransaction={onClickRecordNewTransaction}
      bahiKhataError={bahiKhataError}
      onClickRetry={onClickRetry}
      initialCount={initialCount}
      alert={alert}
    />
  );
}
