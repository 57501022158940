import { Box } from '@mui/joy';
import Styles from './style.module.css';
import BahiKhataDetailHeader from './Header';
import BalanceContainer from './BalanceContainer';
import MoneyTransferOptionContainer from './MoneyTransferOptionContainer';
import TransactionContainer from './TransactionContainer';
import DeleteTransactionModal from './DeleteTransactionModal';
import EditProfileModal from './EditProfileModal';
import DeleteKahtaModal from './DeleteKhataModal';
import ReminderModal from './ReminderModal';
import EditAmountModal from './EditAmountModal';
import EditNotetModal from './EditNoteModal';
import AddNotetModal from './AddNoteModal';
import type { UseBahiKhataInterface } from '../../hooks/BahiKhataDetail/types';
import PaymentDetailModal from './PyamentDetailModal';
import DocumentViewer from '@mono-farmart-web/farmart-web-common/ComponentsV3/UploadDocument/viewer/DocumentViewer';
import { DocumentProvider } from '@mono-farmart-web/farmart-web-common/ComponentsV3/UploadDocument/DocumentContext';
import DeleteImageModal from './DeleteImageModal';
import DeleteNoteModal from './DeleteNoteModal';
import CommonErrorState from '@mono-farmart-web/farmart-web-common/ComponentsV4/CommonErrorState';

export default function BahiKhataDetail({
  bahiKhataDetailLoading,
  calluser,
  deleteKhataModalOpenClose,
  editProfileModalOpenClose,
  onClickBack,
  onClickDeleteKhata,
  onClickEditProfile,
  onClickReminderBell,
  reminderModalOpenClose,
  transactionHistory,
  remindeUserBalance,
  deleteKhata,
  onchangeFarmerName,
  editFarmerName,
  UpdateFarmerName,
  onClickTransactionLongPress,
  trasactionDeleteData,
  unSelectLongPress,
  deleteTransaction,
  onClickDeleteTransactionModal,
  deleteTransactionModalOpenClose,
  loadMoreTransaction,
  moreTrasactionLoading,
  onClickPaymentDetailModal,
  paymentDetailModalOpen,
  slectedTransactionDetail,
  alert,
  language,
  bahiKhataDetailAsset,
  onHoverTransaction,
  onClickMenuButton,
  handleCredit,
  onClickEditAmountModal,
  editAmountModalOpenClose,
  editAmount,
  onChangeAmount,
  saveEditedAmount,
  noteModalOpenClose,
  onClicknoteModal,
  note,
  onChangeNote,
  noteCreateUpdate,
  deleteNoteOpenClose,
  onClickDeleteNoteModal,
  deleteNoteModal,
  handleOpenService,
  uploadedImage,
  preViewDocument,
  selectedDocument,
  onPressClosePreview,
  deleteImageModalVisible,
  onClickDeleteImage,
  toggleDeleteImageModal,
  onClickEditProfileInputs,
  onClickAmountModalInput,
  onClickInputEditNote,
  error,
  onRetryScreen,
  onPressHelp,
  disableDeleteTransactionButton,
  disableDeleteKhataButton,
  toggleReplaceImageModal,
  toggleReplaceImageModalWithFlag,
  openReplaceImageModal,
  handleCameraPermission
}: UseBahiKhataInterface) {
  return (
    <Box>
      {error ? (
        <CommonErrorState
          onPressRetry={onRetryScreen}
          onPressHelp={onPressHelp}
          onPressHelpTestId="bahiKhataDetailHelpTestId"
          onPressRetryTestId="bahiKhataDetailRetryTestId"
        />
      ) : selectedDocument ? (
        <Box>
          <DocumentProvider
            onPressClosePreview={onPressClosePreview}
            initialDocuments={[
              {
                uri: selectedDocument,
                fileName: '',
              },
            ]}
            label={uploadedImage?.title||language?.image}
            deleteVisible={true}
            downloadVisible={false}
            removeDocument={() => toggleDeleteImageModal('previewDelete')}
          >
            <DocumentViewer />
          </DocumentProvider>
          <DeleteImageModal
            language={language}
            deleteImageModalVisible={deleteImageModalVisible}
            onClickDeleteImage={onClickDeleteImage}
            toggleDeleteImageModal={toggleDeleteImageModal}
          />
        </Box>
      ) : (
        <Box className={Styles.container}>
          <BahiKhataDetailHeader
            calluser={calluser}
            onClickBack={trasactionDeleteData ? unSelectLongPress : onClickBack}
            onClickEditProfile={onClickEditProfile}
            onClickDeleteKhata={onClickDeleteKhata}
            profileName={transactionHistory?.farmer_name}
            trasactionDeleteData={trasactionDeleteData}
            onClickDeleteTransactionModal={onClickDeleteTransactionModal}
            alert={alert}
            language={language}
            onClickMenuButton={onClickMenuButton}
            farmerMobileNumber={transactionHistory?.farmer_mobile_number}
          />
          <BalanceContainer
            onClickReminderBell={onClickReminderBell}
            balance={transactionHistory?.balance}
            bahiKhataDetailLoading={bahiKhataDetailLoading}
            khataDate={transactionHistory?.khata_creation_date}
            language={language}
          />
          <TransactionContainer
            transactionHistory={transactionHistory}
            bahiKhataDetailLoading={bahiKhataDetailLoading}
            onClickTransactionLongPress={onClickTransactionLongPress}
            trasactionDeleteData={trasactionDeleteData}
            loadMoreTransaction={loadMoreTransaction}
            moreTrasactionLoading={moreTrasactionLoading}
            onClickPaymentDetailModal={onClickPaymentDetailModal}
            language={language}
            bahiKhataDetailAsset={bahiKhataDetailAsset}
            onHoverTransaction={onHoverTransaction}
            onClickDeleteTransactionModal={onClickDeleteTransactionModal}
            deleteTransactionModalOpenClose={deleteTransactionModalOpenClose}
            unSelectLongPress={unSelectLongPress}
          />
          <MoneyTransferOptionContainer
            language={language}
            handleCredit={handleCredit}
          />
          <DeleteTransactionModal
            deleteTransactionModalOpenClose={deleteTransactionModalOpenClose}
            onClickDeleteTransactionModal={onClickDeleteTransactionModal}
            deleteTransaction={deleteTransaction}
            disableDeleteTransactionButton={disableDeleteTransactionButton}
            language={language}
          />
          <DeleteImageModal
            language={language}
            deleteImageModalVisible={deleteImageModalVisible}
            onClickDeleteImage={onClickDeleteImage}
            toggleDeleteImageModal={toggleDeleteImageModal}
          />
          <EditProfileModal
            onClickEditProfile={onClickEditProfile}
            editProfileModalOpenClose={editProfileModalOpenClose}
            onchangeFarmerName={onchangeFarmerName}
            editFarmerName={editFarmerName}
            profileName={transactionHistory?.farmer_name}
            UpdateFarmerName={UpdateFarmerName}
            farmerMobileNumber={transactionHistory?.farmer_mobile_number}
            language={language}
            onClickEditProfileInputs={onClickEditProfileInputs}
          />
          <DeleteKahtaModal
            onClickDeleteKhata={onClickDeleteKhata}
            deleteKhataModalOpenClose={deleteKhataModalOpenClose}
            deleteKhata={deleteKhata}
            profileName={transactionHistory?.farmer_name}
            disableDeleteKhataButton={disableDeleteKhataButton}
            language={language}
          />
          <ReminderModal
            onClickReminderBell={onClickReminderBell}
            reminderModalOpenClose={reminderModalOpenClose}
            remindeUserBalance={remindeUserBalance}
            khataName={transactionHistory?.farmer_name}
            balance={
              slectedTransactionDetail?.amount || transactionHistory?.balance
            }
            language={language}
            createdAt={slectedTransactionDetail?.created_at}
            mobileNumber={transactionHistory?.farmer_mobile_number}
            selectedTransactionDetailId={slectedTransactionDetail?.id}
          />
          <EditAmountModal
            onClickEditAmountModal={onClickEditAmountModal}
            editAmountModalOpenClose={editAmountModalOpenClose}
            amount={slectedTransactionDetail?.amount}
            editAmount={editAmount}
            onChangeAmount={onChangeAmount}
            saveEditedAmount={saveEditedAmount}
            language={language}
            onClickAmountModalInput={onClickAmountModalInput}
          />
          {slectedTransactionDetail?.comment ? (
            <EditNotetModal
              noteModalOpenClose={noteModalOpenClose}
              onClicknoteModal={onClicknoteModal}
              note={note}
              onChangeNote={onChangeNote}
              comment={slectedTransactionDetail?.comment}
              noteCreateUpdate={noteCreateUpdate}
              onClickDeleteNoteModal={onClickDeleteNoteModal}
              language={language}
              onClickInputEditNote={onClickInputEditNote}
            />
          ) : (
            <AddNotetModal
              noteModalOpenClose={noteModalOpenClose}
              onClicknoteModal={onClicknoteModal}
              note={note}
              onChangeNote={onChangeNote}
              noteCreateUpdate={noteCreateUpdate}
              language={language}
            />
          )}
          <DeleteNoteModal
            deleteNoteOpenClose={deleteNoteOpenClose}
            onClickDeleteNoteModal={onClickDeleteNoteModal}
            deleteNoteModal={deleteNoteModal}
            language={language}
          />
          <PaymentDetailModal
            handleCameraPermission={handleCameraPermission}
            onClickPaymentDetailModal={onClickPaymentDetailModal}
            paymentDetailModalOpen={paymentDetailModalOpen}
            slectedTransactionDetail={slectedTransactionDetail}
            onClickReminderBell={onClickReminderBell}
            onClickDeleteTransactionModal={onClickDeleteTransactionModal}
            language={language}
            bahiKhataDetailAsset={bahiKhataDetailAsset}
            onClickEditAmountModal={onClickEditAmountModal}
            onClicknoteModal={onClicknoteModal}
            handleOpenService={handleOpenService}
            uploadedImage={uploadedImage}
            preViewDocument={preViewDocument}
            toggleDeleteImageModal={toggleDeleteImageModal}
            toggleReplaceImageModal={toggleReplaceImageModal}
            toggleReplaceImageModalWithFlag={toggleReplaceImageModalWithFlag}
            openReplaceImageModal={openReplaceImageModal}
          />
        </Box>
      )}
    </Box>
  );
}
