import { Box } from '@mui/joy';
import NoTransectionContainer from '../NoTransactionContainer';
import TransactionList from './transactionList';
import { KhataListData } from '../../../hooks/BahiKhataLanding/types';
import TransactionLoading from './transactionLoading';

interface TransactionContainerProps {
  khataList: KhataListData[];
  language: { [key: string]: string };
  onClickKhata: (id: number) => void;
  onClickSeachKhata: () => void;
  toggleFilterModal: () => void;
  toggleSortingModal: () => void;
  loadMoreFarmers: () => void;
  initialLoading: boolean;
  selectedSorting: number;
  bahiKhataLandingAssets: { [key: string]: string };
  selectedFiler:number
  initialCount: number
  totalCount: number
}

export default function TransactionContainer(props: TransactionContainerProps) {
  const {
    khataList,
    language,
    onClickKhata,
    onClickSeachKhata,
    toggleFilterModal,
    toggleSortingModal,
    loadMoreFarmers,
    initialLoading,
    selectedSorting,
    bahiKhataLandingAssets,
    selectedFiler,
    initialCount,
    totalCount
  } = props;

  return (
    <Box
      height={'100%'}
      width={'100%'}
      display={'flex'}
      flexDirection={'column'}
      boxSizing={'border-box'}
      overflow={'auto'}
    >
      {initialLoading ? (
        <Box
          gap={8}
          display={'flex'}
          flexDirection={'column'}
          marginTop={'24px'}
        >
          <TransactionLoading />
          <TransactionLoading />
          <TransactionLoading />
          <TransactionLoading />
        </Box>
      ) : initialCount === 0 ? (
        <NoTransectionContainer
          language={language}
          bahiKhataLandingAssets={bahiKhataLandingAssets}
        />
      ) : (
        <TransactionList
          khataList={khataList}
          loadMoreFarmers={loadMoreFarmers}
          onClickSeachKhata={onClickSeachKhata}
          toggleFilterModal={toggleFilterModal}
          toggleSortingModal={toggleSortingModal}
          onClickKhata={onClickKhata}
          featureLanguage={language}
          selectedSorting={selectedSorting}
          selectedFiler={selectedFiler}
          bahiKhataLandingAssets={bahiKhataLandingAssets}
          totalCount={totalCount}
        />
      )}
    </Box>
  );
}
