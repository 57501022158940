import React, { Suspense } from 'react';
import Styles from './style.module.css';
import Header from './Header/Header';
import { SmsFarmerProps } from '../../hooks/SmsFarmersHook/types';
import SearchBar from './SearchBar/SearchBar';
import EmptyStateContent from './EmptyStateContent/EmptyStateContent';
import Loader from '@mono-farmart-web/farmart-web-common/ComponentV2/Loading';
import InfiniteScroll from '@mono-farmart-web/farmart-web-common/Components/InfinityScrollV2';

import { Box } from '@mui/joy';
import AddFarmer from './AddFarmer/index';
import Toast from '@mono-farmart-web/farmart-web-common/ComponentV2/Toast';
import SmsCount from './SmsCount/SmsCount';
import MuiSkeleton from '@mono-farmart-web/farmart-web-common/MuiComponents/Skeleton';
const ContactList = React.lazy(() => import('./ContactList/ContactList'));

export default function MyFarmerView(props: SmsFarmerProps) {
  const {
    onPressBack,
    language,
    onContactUs,
    farmers,
    searchParam,
    setSearchParam,
    toggleFarmerSelection,
    debouncedSetSearchParam,
    initialLoading,
    searchLoading,
    onClickAddFarmer,
    isErrorPopup,
    loadMoreFarmers,
    setCurrentPage,
    contactsIndex,
    selectAll,
    selectAllView,
    initialFarmersCount,
    selectedCount,
    smsBalance,
    saveFarmer,
    saving,
    selectedFarmerCount,
    hasMore,
  } = props;

  const showEmptyState =
    !initialLoading &&
    farmers.length === 0 &&
    searchParam === '' &&
    initialFarmersCount === 0;

  function renderLoader() {
    return (
      <Box className={Styles.loaderContainer}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
          <Loader size={30} color="grey" />
        </Box>
      </Box>
    );
  }

  return (
    <Box className={Styles.container}>
      <div className={Styles.headerSection}>
        <Header
          onContactUs={onContactUs}
          onPressBack={onPressBack}
          language={language}
        />
        <SmsCount
          selectedCount={selectedCount}
          smsBalance={smsBalance}
          language={language}
        />
        <SearchBar
          language={language}
          searchParam={searchParam}
          setSearchParam={setSearchParam}
          debouncedSetSearchParam={debouncedSetSearchParam}
          setCurrentPage={setCurrentPage}
        />
      </div>

      <Box className={Styles.scrollContainer} id="smsFarmersContainer">
        {initialLoading ? (
          // Show full-screen loader during initial load
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            paddingTop={3}
            margin={'0px 16px'}
            flexDirection={'column'}
            gap={4}
          >
            <MuiSkeleton sx={{ width: '30%', height: 24, borderRadius: 8 }} />
            <MuiSkeleton
              sx={{ width: '100%', height: 70, borderRadius: 8 }}
              itemNumber={10}
            />
          </Box>
        ) : (
          <>
            {showEmptyState ? (
              // Show empty state content if no farmers and no search params after initial load
              <EmptyStateContent
                language={language}
                onClickAddFarmer={onClickAddFarmer}
              />
            ) : (
              <>
                {searchLoading && farmers.length === 0 ? (
                  renderLoader()
                ) : (
                  <InfiniteScroll
                    dataLength={farmers.length}
                    next={loadMoreFarmers}
                    hasMore={farmers.length < hasMore}
                    scrollableTarget="smsFarmersContainer"
                    loader={renderLoader()}
                    //style={{ minHeight: '100%' }}
                  >
                    <Suspense
                      fallback={
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          paddingTop={3}
                          margin={'0px 16px'}
                          flexDirection={'column'}
                          gap={4}
                        >
                          <MuiSkeleton
                            sx={{ width: '100%', height: 70, borderRadius: 8 }}
                            itemNumber={10}
                          />
                        </Box>
                      }
                    >
                      <ContactList
                        language={language}
                        farmers={farmers}
                        toggleFarmerSelection={toggleFarmerSelection}
                        contactsIndex={contactsIndex}
                        selectAll={selectAll}
                        selectAllView={selectAllView}
                        selectedCount={selectedCount}
                        selectedFarmerCount={selectedFarmerCount}
                      />
                    </Suspense>
                  </InfiniteScroll>
                )}
              </>
            )}
          </>
        )}
      </Box>

      <AddFarmer
        language={language}
        saveFarmer={saveFarmer}
        saving={saving}
        selectedCount={selectedCount}
        smsBalance={smsBalance}
      />

      <Toast
        isError={true}
        message={language?.apiError}
        isOpen={isErrorPopup}
      />
    </Box>
  );
}
