import Styles from './style.module.css';
import Header from './Header/Header';
import TransactionContainer from './TransactionContainer';
import { BahiKhataSearchProps } from '../../hooks/BahiKhataSearch/types';

import { Box } from '@mui/material';

export default function BahiKhataSearchView(props: BahiKhataSearchProps) {
  const {
    initialLoading,
    khataList,
    language,
    onClickKhata,
    onClickRemoveSearch,
    onPressBack,
    onSearchBuyer,
    searchedValue,
    bahiKhataSearchAssets
  } = props;
  return (
    <Box className={Styles.container} bgcolor={'background.body'}>
      <Header
        language={language}
        onPressBack={onPressBack}
        searchedValue={searchedValue}
        onSearchBuyer={onSearchBuyer}
        onClickRemoveSearch={onClickRemoveSearch}
      />
      <TransactionContainer
        onClickKhata={onClickKhata}
        initialLoading={initialLoading}
        khataList={khataList}
        language={language}
        searchedValue={searchedValue}
        bahiKhataSearchAssets={bahiKhataSearchAssets}
      />
    </Box>
  );
}
