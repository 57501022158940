import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { usePlatfromData } from "@mono-farmart-web/farmart-web-common/context/CrossPlatformActionHandler/platformChecker";
import { helpLineNumber } from "@mono-farmart-web/farmart-web-common/constant/HelpLineNumber";
import { faqWhatsappUrl } from "@mono-farmart-web/farmart-web-common/constant/whatsappUrl";
import type { FAQAnswersScreenProps, LanguageContentType } from "./types";
import getLang from "@mono-farmart-web/farmart-web-common/languages/faqAnswers";
import { DEFAULT_LANGUAGE } from "@mono-farmart-web/farmart-web-common/constant/language";
import Events from "@mono-farmart-web/farmart-web-common/constant/MoengageEvents/index";
import { trackEvent } from "@mono-farmart-web/farmart-web-common/utils/moengage";
import { createBridge } from "@mono-farmart-web/rpc-bridge";
import bridgeFunctions from "@mono-farmart-web/rpc-bridge/utility";
import { getFaqAssets } from "@mono-farmart-web/farmart-web-common/constant/imageUrls";
import { getLanguageService } from "@mono-farmart-web/farmart-web-common/utils/getLanguageService";
import { languageUrls } from "@mono-farmart-web/farmart-web-common/constant/languageUrls/index";

export default function UseFaqAnswers(): FAQAnswersScreenProps {
	const bridge = createBridge(); // Creating an instance of bridge using createBridge function
	const navigate = useNavigate(); // Getting navigation function from React Router
	const platformData = usePlatfromData(); // Getting platform data using custom hook usePlatfromData
	const location = useLocation();
	const faqAnswer = location?.state?.faqAnswer; // Extracting faqAnswer from location state
	const [showFaqAlert, setShowFaqAlert] = useState(false); // State for showing FAQ alert

	const langId = JSON.parse(localStorage.getItem("userLanguage")); // Retrieving language ID from localStorage

	const startLoadTime = new Date().getTime(); // Getting start load time for tracking loading time
	const faqAssets = getFaqAssets(langId?.langCode); // Getting FAQ assets based on langCode

	const [languageFile, setLanguageFile] = useState<LanguageContentType>(null); // Language file state

	useEffect(() => {
		getLanguageJson();
	}, []);

	async function getLanguageJson() {
		try {
			const url = languageUrls?.faqAnswers;
			const result = await getLanguageService(url);
			setLanguageFile(result);
		} catch (error) {
			console.log("object");
		} finally {
			console.log("object");
		}
	}

	const language = languageFile ?? getLang(DEFAULT_LANGUAGE); // Getting language file based on language ID

	// Effect to set up message listener from app to native bridge
	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(window as any).listenMessagesFromApp = (request: any) => {
			bridge.listenMessagesFromNative(request);
		};
	}, []); // Dependency array to ensure effect runs only once on mount

	// Effect to track event when FAQ detailed screen starts

	function onClickBackFaqAnswerScreen() {
		navigate(-1); // Navigating back to previous screen
	}

	function onClickNeedMoreHelp() {
		const url = `${faqWhatsappUrl}`;
		if (platformData.platform === "WEB") {
			// Checking if platform is web
			const win = window?.open(url, "_blank");
			win?.focus();
		} else {
			// For non-web platforms (like mobile)
			const text = `${language?.whatsAppMessageText}&phone=${helpLineNumber}`;
			const link = `whatsapp://send?text=${text}`;

			bridge.sendRequestToNative(
				// Sending request to native using bridge
				"OpenUrl",
				{
					link: link,
					show_toast: true,
					toast_message: language?.noWhatsAppAccountFound,
				},
				(response) => {
					bridgeFunctions.PrintLog(response);
				},
			);
		}
		trackEvent(Events?.onHelpWhatsappButtonClick, {}); // Tracking event for WhatsApp button click
	}

	return {
		faqAnswer,
		onClickBackFaqAnswerScreen,
		onClickNeedMoreHelp,
		showFaqAlert,
		setShowFaqAlert,
		language,
		faqAssets,
	};
}
