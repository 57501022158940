import React from 'react';
import { Box } from '@mui/material';
import MuiTypography from '@mono-farmart-web/farmart-web-common/ComponentV3/Typography';
import { ChevronRightRounded } from '@mui/icons-material';
import { PinCodeDataObj } from '../../../hooks/PinCode/types';

interface LocationCardProps {
  key: number;
  index: number;
  item: PinCodeDataObj;
  onClickLocation?: (selectedLocation: PinCodeDataObj) => void;
}

export default function LocationCard(props: LocationCardProps) {
  const { item, key, index, onClickLocation } = props;
  return (
    <Box
      key={key}
      borderBottom={'1px solid black'}
      padding={'16px 0px'}
      margin={'0px 16px'}
      display={'flex'}
      borderColor={'neutral.outlinedBorder'}
      justifyContent={'space-between'}
      alignItems={'center'}
      id={`LocationCard${index}`}
      onClick={() => onClickLocation(item)}
    >
      <Box display={'flex'} flexDirection={'column'} gap={'4px'}>
        <MuiTypography level="title-sm" textColor={'text.primary'}>
          {item?.title}
        </MuiTypography>
        <MuiTypography level="body-xxs" textColor={'text.tertiary'}>
          {item?.subtitle}
        </MuiTypography>
      </Box>
      <ChevronRightRounded
        style={{
          fontSize: 24,
        }}
      />
    </Box>
  );
}
