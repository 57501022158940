import Events from "@mono-farmart-web/farmart-web-common/constant/MoengageEvents/index";
import { trackEvent } from "@mono-farmart-web/farmart-web-common/utils/moengage";
import { useCallback, useState } from "react";

interface Props {
	URL: string; // URL for the video, right now it doesnt support offline/local videos.
}

export default function useDemoVideo(props: Props) {
	const { URL } = props || {};
	const [videoURL, setVideoUrlState] = useState<string>(URL);
	const [showVideoModal, setShowVideoModal] = useState(false);

	const toggleDisplayVideoModal = useCallback(() => {
		setShowVideoModal(prev => {
			if (prev) {
				trackEvent(Events.ondemovideoopened, {});
			} else {
				trackEvent(Events.ondemovideoclosed, {});
			}

			return !prev;
		});
	}, []);

	const setVideoURL = useCallback((url: string) => {
		setVideoUrlState(url);
	}, []);

	return {
		toggleDisplayVideoModal,
		showVideoModal,
		setVideoURL,
		videoURL,
	};
}
