import { ArrowBackRounded } from "@mui/icons-material";
import IconButtonC from "@mono-farmart-web/farmart-web-common/MuiComponents/atoms/button/IconButton";
import { Row } from "@mono-farmart-web/farmart-web-common/MuiComponents/atoms/layout";
import { useNavigate } from "react-router-dom";


const CreditEntryHeader = () => {
  const navigate = useNavigate();

  return (
    <Row
      sx={{
        minWidth: "360px",
        width: "auto",
        height: "56px",
        padding: "8px 8px 8px 8px",
        display: "flex",
        alignItems: "center",
      }}>
      <IconButtonC
        sx={{
          width: "40px",
          height: "40px",
          borderRadius: "8px"
        }}
        onClick={() => navigate(-1)} >
        <ArrowBackRounded
          sx={{
            width: "24px",
            height: "24px",
            color: "neutral.plainColor"
          }} />
      </IconButtonC>
    </Row>
  )
}

export default CreditEntryHeader;
