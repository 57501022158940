import React from 'react';
import Styles from './style.module.css';
import { Box, Typography } from '@mui/joy';
import { ArrowBackRounded } from '@mui/icons-material';
import isAndroid from '@mono-farmart-web/farmart-web-common/utils/operatingSystemType';
import MuiTypography from '@mono-farmart-web/farmart-web-common/ComponentV3/Typography';

interface HeaderProps {
  onLanguageModalToggle: () => void;
  language: { [key: string]: any };
  farmartLogo: string;
  languageSelectionIcon: string;
  onClickBack: () => void;
  loginHeaderBanner: string;
}
const Header = (props: HeaderProps) => {
  const {
    onLanguageModalToggle,
    language,
    farmartLogo,
    languageSelectionIcon,
    onClickBack,
    loginHeaderBanner,
  } = props;

  return (
    <Box
      className={Styles.mainContainer}
      bgcolor={'background.surface'}
      sx={{
        display: 'flex',
        height: '323px',
        maxWidth: '100%',
        flexDirection: 'column',
        boxSizing: 'border-box',
        backgroundImage: `url(${loginHeaderBanner})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
      id="LoginImage"
    >
      <Box display={'flex'} justifyContent={'space-between'} height={'32px'}>
        {!isAndroid() ? (
          <ArrowBackRounded
            onClick={onClickBack}
            sx={{
              fontSize: '24px',
              color: '#fff',
            }}
          />
        ) : null}
        <Box
          onClick={onLanguageModalToggle}
          display={'flex'}
          flex={1}
          justifyContent={'flex-end'}
          id='ChangeLanguageButton'
        >
          <img
            className={Styles.languageIconStyle}
            src={languageSelectionIcon}
            alt="Language Icon"
            height={20}
            width={20}
            loading="eager"
            id='ChangeLanguageButton'
          />
        </Box>
      </Box>

      <Box
        display={'flex'}
        marginTop={4}
        justifyContent={'center'}
        alignItems={'center'}
        flexDirection={'column'}
        gap={2}
      >
        <img
          className={Styles.languageIconStyle}
          src={farmartLogo}
          alt="Language Icon"
          height={25}
          loading="eager"
        />
        <MuiTypography level={'title-lg'} textColor={'warning.200'}>
          {language?.farmartMotoLabel}
        </MuiTypography>
      </Box>
    </Box>
  );
};

export default Header;
