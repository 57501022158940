import { Box } from '@mui/joy';
import MuiTypography from '@mono-farmart-web/farmart-web-common/ComponentV3/Typography';

export default function NoTransactionContainer({
  language,
  bahiKhataDetailAsset,
}) {
  return (
    <Box
      display={'flex'}
      padding={'0px var(--4, 16px)'}
      flexDirection={'column'}
      alignItems={'center'}
      justifyContent={'center'}
      gap={'var(--4, 16px)'}
      alignSelf={'center'}
      height={'100%'}
      width={'100%'}
      boxSizing={'border-box'}
    >
      <img
        src={bahiKhataDetailAsset?.cash}
        height={'48px'}
        width={'64px'}
        alt="Cash"
        loading="eager"
      />
      <MuiTypography level="body-xs">{language?.noTransaction}</MuiTypography>
    </Box>
  );
}
