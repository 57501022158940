import { Box } from '@mui/joy';
import NoTransactionContainer from './../NoLocation';
import TransactionList from './transactionList';
import { KhataListData } from '../../../hooks/BahiKhataLanding/types';
import TransactionLoading from './transactionLoading';

interface TransactionContainerProps {
  khataList: KhataListData[];
  language: { [key: string]: string };
  onClickKhata: (id: number) => void;
  initialLoading: boolean;
  searchedValue: string;
  bahiKhataSearchAssets: { [key: string]: string };
}

export default function TransactionContainer(props: TransactionContainerProps) {
  const {
    khataList,
    language,
    onClickKhata,
    initialLoading,
    searchedValue,
    bahiKhataSearchAssets,
  } = props;

  return (
    <Box
      height={'100%'}
      width={'100%'}
      display={'flex'}
      flexDirection={'column'}
      boxSizing={'border-box'}
      overflow={'auto'}
    >
      {initialLoading ? (
        <Box
          gap={8}
          marginTop={'24px'}
          display={'flex'}
          flexDirection={'column'}
        >
          <TransactionLoading />
          <TransactionLoading />
          <TransactionLoading />
          <TransactionLoading />
          <TransactionLoading />
        </Box>
      ) : khataList.length === 0 && searchedValue.length > 0 ? (
        <NoTransactionContainer
          language={language?.noKhataFound}
          noLocationImage={bahiKhataSearchAssets?.noKhataFoundImage}
        />
      ) : searchedValue.length === 0 ? (
        <NoTransactionContainer
          language={language?.searchKhataLable}
          noLocationImage={bahiKhataSearchAssets?.bahiKhataFeatureImg}
        />
      ) : (
        <TransactionList
          khataList={khataList}
          onClickKhata={onClickKhata}
          featureLanguage={language}
        />
      )}
    </Box>
  );
}
