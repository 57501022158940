import { Box } from "@mui/joy";
import MuiTypography from "@mono-farmart-web/farmart-web-common/ComponentV3/Typography";
import React from "react";
import UserInterestList from "./userInterestList";

import MuiButton from "@mono-farmart-web/farmart-web-common/ComponentV3/MuiButton";
import type { UserInterestData } from "../../../hooks/UserInterest/types";
import styles from "./style.module.css";
interface UserInterestProps {
	userInterestList: UserInterestData[];
	handleSelectCard: (id: number) => void;
	selectedIds: number[];
	language: { [key: string]: string };
	submitUserInterest: () => void;
	allSeleted: boolean;
	userInterestLoading: boolean;
}

export default function index(props: UserInterestProps) {
	const {
		userInterestList,
		handleSelectCard,
		selectedIds,
		language,
		submitUserInterest,
		allSeleted,
		userInterestLoading,
	} = props;
	return (
		<Box display={"flex"} padding={4} flexDirection={"column"} gap={"24px"}>
			<Box
				display={"flex"}
				flex={1}
				alignItems={"center"}
				flexDirection={"column"}
			>
				<MuiTypography level="title-md">{language?.heading}</MuiTypography>
				<MuiTypography level="body-xs" textAlign={"center"}>
					{language?.subHeading}
				</MuiTypography>
			</Box>
			<Box display={"flex"} marginBottom={selectedIds?.length ? "80px" : "0px"}>
				<UserInterestList
					userInterestList={userInterestList}
					handleSelectCard={handleSelectCard}
					selectedIds={selectedIds}
					allSeleted={allSeleted}
				/>
			</Box>
			{selectedIds.length > 0 && (
				<Box
					display={"flex"}
					marginTop={"16px"}
					bgcolor={"warning.50"}
					className={styles.wrapper}
				>
					<MuiButton
						variant="solid"
						size="lg"
						onClick={submitUserInterest}
						loading={userInterestLoading}
						id="ProceedButton"
						data-testid="ProceedButton"
						content-desc="ProceedButton"
					>
						{language?.proceed}
					</MuiButton>
				</Box>
			)}
		</Box>
	);
}
