import * as AppLanguages from '../constant/appLanguages';
//language will be added here
const languages = {
  [AppLanguages.appLanguagesIndex.hindi]: {
    changeLanguage: 'भाषा बदलें',
    selectLanguage: 'भाषा चुने',
    help: 'सहायता',
    skip: 'छोड़ें',
    gotIt: 'समझ गया',
    next: 'आगे',
  },
  [AppLanguages.appLanguagesIndex.english]: {
    changeLanguage: 'Change Language',
    selectLanguage: 'Select language',
    help: 'Help',
    skip: 'Skip',
    gotIt: 'Got it',
    next: 'Next',
  },
};

export default (language) => languages[language];
