import { Box } from '@mui/joy';
import NoTransectionContainer from '../NoTransactionContainer';
import TransectionList from './transactionList';

export default function TransactionContainer({
  transactionHistory,
  bahiKhataDetailLoading,
  onClickTransactionLongPress,
  trasactionDeleteData,
  loadMoreTransaction,
  moreTrasactionLoading,
  onClickPaymentDetailModal,
  language,
  bahiKhataDetailAsset,
  onHoverTransaction,
  onClickDeleteTransactionModal,
  deleteTransactionModalOpenClose,
  unSelectLongPress
}) {
  return (
    <Box
      height={'100%'}
      width={'100%'}
      display={'flex'}
      flexDirection={'column'}
      boxSizing={'border-box'}
      overflow={'auto'}
    >
      {!transactionHistory?.count && !bahiKhataDetailLoading ? (
        <NoTransectionContainer
          language={language}
          bahiKhataDetailAsset={bahiKhataDetailAsset}
        />
      ) : (
        <TransectionList
          transactionHistory={transactionHistory}
          bahiKhataDetailLoading={bahiKhataDetailLoading}
          onClickTransactionLongPress={onClickTransactionLongPress}
          trasactionDeleteData={trasactionDeleteData}
          loadMoreTransaction={loadMoreTransaction}
          moreTrasactionLoading={moreTrasactionLoading}
          onClickPaymentDetailModal={onClickPaymentDetailModal}
          language={language}
          onHoverTransaction={onHoverTransaction}
          onClickDeleteTransactionModal={onClickDeleteTransactionModal}
          deleteTransactionModalOpenClose={deleteTransactionModalOpenClose}
          unSelectLongPress={unSelectLongPress}
        />
      )}
    </Box>
  );
}
