import { Box, Chip } from '@mui/joy';
import MuiTypography from '@mono-farmart-web/farmart-web-common/ComponentV3/Typography';
import {
  farmerCardData,
  smsData,
} from '../../../hooks/SmsHistoryHookDetailHook/types';
import smsTypeIdEnums from '@mono-farmart-web/farmart-web-common/constant/enums/smsTypeIdEnums';

// Interface for SmsDetailCard component props
interface SmsCardProp {
  smsHistoryData:
    | {
        message: string;
        farmers: farmerCardData[];
        sms_type: number;
        status: number;
        comment: string;
      }
    | undefined;
  smsHistoryDetailData: smsData;
  language: { [key: string]: string };
}

export default function SmsDetailCard(props: SmsCardProp) {
  const { smsHistoryData, smsHistoryDetailData, language } = props;

  const longDateIN = new Date(smsHistoryDetailData?.created_at).toLocaleString(
    'en-IN',
    {
      timeZone: 'Asia/Kolkata',
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    },
  );

  const smsDeducted =
    smsHistoryData.sms_type === smsTypeIdEnums.get('customMessage')
      ? smsHistoryData.farmers.length * 3
      : smsHistoryData.farmers.length;

  return (
    <Box
      margin={'16px'}
      bgcolor={'background.level1'}
      borderRadius={8}
      padding={'12px'}
      display={'flex'}
    >
      <Box gap={'8px'} display={'flex'} flex={1}>
        <Box
          padding={'6px'}
          bgcolor={'background.level2'}
          borderRadius={4}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          height={36}
          width={36}
        >
          <img
            src={smsHistoryDetailData?.icon}
            height={28}
            width={28}
            loading="eager"
          />
        </Box>
        <Box
          display={'flex'}
          gap={'2px'}
          flexDirection={'column'}
          justifyContent={'center'}
        >
          <MuiTypography level="body-xxs" textColor={'text.primary'}>
            {smsHistoryDetailData?.heading}
          </MuiTypography>
          <Chip
            variant="outlined"
            size="sm"
            color="neutral"
            sx={{ borderRadius: 4 }}
          >
            {language?.smsUsed} {smsDeducted} {/* Display SMS used count */}
          </Chip>
        </Box>
      </Box>
      <Box
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'center'}
        marginLeft={'16px'}
        gap={'6px'}
      >
        <MuiTypography level="label-sm" textColor={'text.tertiary'}>
          {language?.smsSent} {/* Display SMS sent label */}
        </MuiTypography>
        <MuiTypography level="label-md" textColor={'text.primary'}>
          {longDateIN}
        </MuiTypography>
      </Box>
    </Box>
  );
}
