import React from 'react';

export default function getTranslatedLanguage(
  template: string,
  values: (string | null | undefined)[],
  variableStyle?: React.CSSProperties,
  textStyle?: React.CSSProperties
): React.ReactNode[] {
  if (typeof template !== 'string') {
    throw new Error('Template must be a string');
  }
  if (!Array.isArray(values)) {
    throw new Error('Values must be an array');
  }
  const pattern = /\{\(variable(\d+)\)\}/g;
  const parts = template.split(pattern);
  const result: React.ReactNode[] = [];

  for (let i = 0; i < parts.length; i++) {
    if (i % 2 === 0) {
      result.push(<span style={textStyle} key={`text-${i}`}>{parts[i]}</span>);
    } else {
      const arrayIndex = Number.parseInt(parts[i]) - 1;
      if (arrayIndex >= 0 && arrayIndex < values.length) {
        result.push(<span style={variableStyle} key={`variable-${i}`}>{values[arrayIndex] ?? ''}</span>);
      } else {
        result.push(<span style={textStyle} key={`text-${i}`}>{`{(variable${parts[i]})}`}</span>);
      }
    }
  }

  return result;
}
