import { useEffect, useState } from "react";
import type { LanguageParams, myProfileHook } from "./types";
import useAuthenticationChecker from "@mono-farmart-web/farmart-web-common/utils/authCheckerV2";
import { apiEndPoints } from "@mono-farmart-web/farmart-web-common/apis/endpoints";
import {
	getUserProfileFromStorage,
	setProfileToStorage,
} from "@mono-farmart-web/farmart-web-common/modal/auth/auth.model";
import PlatForms from "@mono-farmart-web/farmart-web-common/constant/platFormEnums";
import { usePlatfromData } from "@mono-farmart-web/farmart-web-common/context/CrossPlatformActionHandler/platformChecker";
import { createBridge } from "@mono-farmart-web/rpc-bridge";
import bridgeFunctions from "@mono-farmart-web/rpc-bridge/utility";
import type {Profile} from "@mono-farmart-web/farmart-web-common//modal/auth/types.ts"
import { useNavigate } from "react-router-dom";
import { DEFAULT_LANGUAGE } from "@mono-farmart-web/farmart-web-common/constant/language";
import { trackEvent } from "@mono-farmart-web/farmart-web-common/utils/moengage";
import MoengageEvents from "@mono-farmart-web/farmart-web-common/constant/MoengageEvents/index";
import { PrivacyPolicy } from "@mono-farmart-web/farmart-web-common/constant/PrivacyPolicyUrl";
import { PlayStoreUrl } from "@mono-farmart-web/farmart-web-common/constant/PlayStoreUrl";
import compareObjects from "@mono-farmart-web/farmart-web-common/utils/compareObjects"; //compare objects
import { getMyProfileAssets } from "@mono-farmart-web/farmart-web-common/constant/imageUrls";
import { WEB_APP_VERSION } from "@mono-farmart-web/farmart-web-common/constant/versions";
import { fetchLanguage } from "@mono-farmart-web/farmart-web-common/utils/languageApi/fetchLanguage";
import { languageUrls } from "@mono-farmart-web/farmart-web-common/constant/languageUrls";
import { useLanguage } from "@mono-farmart-web/farmart-web-common/context/language";

export default function useMyProfilehook(): myProfileHook {
	const { languageData } = useLanguage();
	const { AuthenticationCheckerApiCaller } = useAuthenticationChecker();
	const navigate = useNavigate();
	const platform = usePlatfromData();
	const bridge = createBridge();

	/**
	 * Get the active system language?.
	 */
	const activeSystemLang = Intl.DateTimeFormat().resolvedOptions().locale;

	/**
	 * Determine the system language based on the active system language?.
	 */
	const systemLanguage = activeSystemLang?.includes("en")
		? languageData && languageData?.[1]?.langCode
		: languageData && languageData?.[0]?.langCode;

	/**
	 * Determine the system language ID based on the active system language?.
	 */
	const systemLanguageId = activeSystemLang?.includes("en")
		? languageData && languageData?.[1]?.backendLanguageCode
		: languageData && languageData?.[0]?.backendLanguageCode;

	/**
	 * Retrieve the language code from localStorage.
	 */
	const langId = JSON.parse(localStorage.getItem("userLanguage"));

	/**
	 * Initialize the current language?.
	 */
	const currentLanguage = langId || systemLanguage || DEFAULT_LANGUAGE;

	const myProfileAssets = getMyProfileAssets(langId?.langCode);

	/**
	 * Get the merchant profile from storage.
	 */
	const merchnatProfile = getUserProfileFromStorage() || null;

	const [languageFile, setLanguageFile] = useState({});

	/**
	 * Get the language based on the language code.
	 */
	const language =
		languageFile[langId?.langCode ? langId?.langCode : systemLanguage];

	/**
	 * State variable for storing the user's profile.
	 */
	const [profile, setProfile] = useState<Profile>(merchnatProfile || null);

	/**
	 * State variable for storing the selected language?.
	 */
	const [selectedLanguage, setSelectedLanguage] = useState(currentLanguage);

	/**
	 * State variable for toggling the language modal.
	 */
	const [isLanguageModalOpen, toggleLanguageModal] = useState<boolean>(false);

	/**
	 * State variable for indicating if the language selection is in progress.
	 */
	const [isLanguageSelectedInProgress, setLanguageSelectedInProgeress] =
		useState<boolean>(false);

	/**
	 * State variable for storing the app version.
	 */
	const [appVersion, setAppVersion] = useState<string>("");

	/**
	 * State variable for storing the logout modal visible.
	 */
	const [logOutModalVisible, setLogOutModalVisible] = useState<boolean>(false);

	/**
	 * State variable for storing the loading of page.
	 */
	const [loading, setLoading] = useState<boolean>(false);

	// Get the merchant occupation ID
	const occupation = merchnatProfile?.merchant_occupation_id || null;

	const [error, setError] = useState<boolean>(false); // State variable for storing the error status

	async function getLanguageJson() {
		try {
			const url = languageUrls?.myProfileScreenLangageUrl;
			const result = await fetchLanguage(url);
			setLanguageFile(result);
		} catch (error) {
		} finally {
		}
	}

	/**
	 * Fetch the user's profile from the API.
	 */
	async function fetchMyProfile() {
		setLoading(true);
		try {
			const queryParameter = {};
			const response = await AuthenticationCheckerApiCaller({
				uri: apiEndPoints?.getMerchantProfile,
				requestType: "post",
				payload: queryParameter,
			});
			if (response?.data?.status) {
				setProfile(response?.data?.data);
				if (PlatForms.get("application") === platform?.platform) {
					if (
						!compareObjects(merchnatProfile, response?.data?.data, "webViewRef")
					) {
						//need better appraoch to compare objects
						bridge.sendRequestToNative(
							// Sending request to native using bridge
							"updateUserProfile",
							response?.data?.data,
							(response) => {
								bridgeFunctions.PrintLog(response);
							},
						);
					}
				}
				setProfileToStorage(response?.data?.data);

			}
			setLoading(false);
		} catch (error) {

			setError(true);
			setLoading(false);
		}
	}

	/**
	 * Handle the click event for the "My Profile" button.
	 */
	function onClickMyProfile(type: "string") {
		trackEvent(MoengageEvents?.onMyProfileEditProfileButtonClick, {
			from: type,
		});
		navigate("/editProfile");
	}

	/**
	 * Handle the language selection.
	 * @param languageItem The selected language item.
	 */
	function handleLanguageSelect(languageItem: LanguageParams) {
		setSelectedLanguage(languageItem);
	}

	/**
	 * Toggle the language modal.
	 */
	function onLanguageModalToggle() {
		if (isLanguageModalOpen) {
			trackEvent(MoengageEvents?.onChooseLanguageButtonClick, {});
		}
		setSelectedLanguage(
			langId || {
				langCode: systemLanguage,
				langId: systemLanguageId,
			},
		);
		toggleLanguageModal(!isLanguageModalOpen);
	}

	/**
	 * Handle the language change.
	 */
	function handleLanguageChange() {
		// Handling language change
		if (localStorage.getItem("userLanguage") !== selectedLanguage) {
			setLanguageSelectedInProgeress(!isLanguageSelectedInProgress);
			localStorage.setItem("userLanguage", JSON.stringify(selectedLanguage));
			if (platform?.platform === PlatForms.get("application")) {
				bridge.sendRequestToNative(
					"setAppLanguage",
					{
						langCode: selectedLanguage?.langCode,
					},
					(response) => {
						bridgeFunctions.PrintLog(response);
					},
				);
			}
			toggleLanguageModal(!isLanguageModalOpen);
			setLanguageSelectedInProgeress(false);
		} else {
			toggleLanguageModal(!isLanguageModalOpen);
		}
	}

	function redirectToPlayStore() {
		trackEvent(MoengageEvents?.onRateAppButtonClick, {});
		window.open(PlayStoreUrl, "_blank");
	}


	function help() {
		trackEvent(MoengageEvents?.onMyProfileHelpButtonClick, {});
		navigate("/faq/questions");
	}

	/**
	 * Open the privacy policy page in a new tab.
	 */
	function onClickPrivacyPolicy() {
		trackEvent(MoengageEvents?.onPrivacyPolicyButtonClick, {});
		window.open(PrivacyPolicy, "_blank");
	}

	/**
	 * Handle the click event for the logout button.
	 */
	function onClickLogout() {
		trackEvent(MoengageEvents?.onLogoutButtonClick, {});
		setLogOutModalVisible(true);
	}

	/**
	 * Get the app version.
	 */
	async function getAppVersion() {
		if (PlatForms.get("application") === platform?.platform) {
			bridge.sendRequestToNative("getCodePushVersion", null, (response) => {
				bridgeFunctions.PrintLog(response);
			});
			(window as any).listenMessagesFromApp = (request: any) => {
				const data = JSON.parse(request);
				if (data?.appVersion) {
					setAppVersion(data?.appVersion);
				}
			};
		} else {
			setAppVersion(WEB_APP_VERSION);
		}
	}

	/**
	 * Handle the click event for the back button.
	 */
	function onClickBack() {
		navigate("/");
	}

	/**
	 * Handle the click event for the close logout modal button.
	 */
	function onClickCloseLogOutModal() {
		setLogOutModalVisible(false);
	}

	/**
	 * Handle the click event for the confirm logout modal button.
	 */
	function confirmLogout() {
		if (platform?.platform === PlatForms.get("application")) {
			localStorage.clear();
			sessionStorage.clear();
			bridge.sendRequestToNative("logOutFromApp", null, (response) => {
				bridgeFunctions.PrintLog(response);
			});
		} else {
			localStorage.clear();
			sessionStorage.clear();
			navigate("/login");
		}
	}

	function onClickMyFarmer() {
		navigate("/myFarmers", {
      state: {
        returnPath: "/myProfile"
      }
    });
	}

	/**
	 * Fetch the user's profile when the component mounts.
	 */
	useEffect(() => {
		fetchMyProfile();
	}, []);

	/**
	 * Get the app version when the component mounts.
	 */
	useEffect(() => {
		getAppVersion();
	}, []);

	useEffect(() => {
		getLanguageJson();
	}, []);

	useEffect(() => {
		setSelectedLanguage(
			langId || {
				langCode: systemLanguage,
				langId: systemLanguageId,
			},
		);
	}, [localStorage.getItem("userLanguage")]);

	return {
		language,
		profile,
		onClickMyProfile,
		handleLanguageSelect,
		onLanguageModalToggle,
		selectedLanguage,
		isLanguageModalOpen,
		handleLanguageChange,
		isLanguageSelectedInProgress,
		redirectToPlayStore,
		help,
		onClickPrivacyPolicy,
		onClickLogout,
		appVersion,
		onClickBack,
		onClickCloseLogOutModal,
		logOutModalVisible,
		confirmLogout,
		loading,
		onClickMyFarmer,
		occupation,
		error,
		myProfileAssets,
		languageData,
	};
}
