import UseEditProfile from '../../hooks/EditProfile/EditProfile';
import EditProfile from '../../Pages/EditProfile/EditProfile';

export default function editProfile() {
  // Destructuring the values and functions returned from the UseEditProfile hook.
  const {
    onClickBackEditProfile, // Function to navigate back from the edit profile screen.
    submitEditProfile, // Function to submit the profile edit form.
    onClickEditProfilePic, // Function to handle clicks on the profile picture edit button.
    onChangeName, // Function to update the user's name.
    onChangeShopName, // Function to update the user's shop name.
    showEditPhotoModal, // Boolean state to show/hide the edit photo modal.
    setShowEditPhotoModal, // Function to set the visibility of the edit photo modal.
    onCloseEditModal, // Function to handle the closing of the edit modal.
    openCamera, // Function to open the camera interface for taking a new profile picture.
    openGallery, // Function to open the gallery for selecting a new profile picture.
    language, // Object containing localized strings for multilingual support.
    merchantName, // State holding the current merchant's name.
    merchantShopName, // State holding the current merchant's shop name.
    merchantImage, // State holding the URL of the current merchant's profile image.
    error, // State object holding any errors related to the edit profile process.
    occupation, // State holding the merchant's occupation ID.
    onClickMerchantName, // Function to handle clicks on the merchant's name input field.
    onClickMerchantShopName, // Function to handle clicks on the merchant's shop name input field.
    loading, // State indicating whether the form submission is in progress.
  } = UseEditProfile();

  // Rendering the EditProfile component with the props obtained from the UseEditProfile hook.
  return (
    <EditProfile
      onClickBackEditProfile={onClickBackEditProfile}
      submitEditProfile={submitEditProfile}
      onClickEditProfilePic={onClickEditProfilePic}
      onChangeName={onChangeName}
      onChangeShopName={onChangeShopName}
      showEditPhotoModal={showEditPhotoModal}
      setShowEditPhotoModal={setShowEditPhotoModal}
      onCloseEditModal={onCloseEditModal}
      openCamera={openCamera}
      openGallery={openGallery}
      language={language}
      merchantName={merchantName}
      merchantShopName={merchantShopName}
      merchantImage={merchantImage}
      error={error}
      occupation={occupation}
      onClickMerchantName={onClickMerchantName}
      onClickMerchantShopName={onClickMerchantShopName}
      loading={loading}
    />
  );
}
