import React from 'react';
import Styles from './style.module.css';
import Header from './Header/Header';
import LanguageSelection from '@mono-farmart-web/farmart-web-common/ComponentsV4/LanguageSelection';
import { UserInterestProps } from '../../hooks/UserInterest/types';
import { Box } from '@mui/joy';
import UserInterestList from './UserInterestList';
import SkeletonLoading from '@mono-farmart-web/farmart-web-common/ComponentsV4/SkeletonLoading';

export default function Login(props: UserInterestProps) {
  const {
    handleLanguageSelect,
    onLanguageModalToggle,
    handleLanguageChange,
    selectedLanguage,
    isLanguageModalOpen,
    isLanguageSelectedInProgress,
    language,
    userInterestAssets,
    languageData,
    userInterestList,
    handleSelectCard,
    selectedIds,
    submitUserInterest,
    allSeleted,
    userInterestLoading,
    initialScreenLoading
  } = props;

  return (
    <Box className={Styles.container} bgcolor={'warning.50'}>
      {initialScreenLoading ? (
        <SkeletonLoading className={Styles.loading} itemNumber={6} />
      ) : (
        <>
          <Header
            onLanguageModalToggle={onLanguageModalToggle}
            language={language}
            farmartLogo={userInterestAssets.farmartLogo}
            languageSelectionIcon={userInterestAssets.languageSelection}
          />
          <UserInterestList
            userInterestList={userInterestList}
            handleSelectCard={handleSelectCard}
            selectedIds={selectedIds}
            language={language}
            submitUserInterest={submitUserInterest}
            allSeleted={allSeleted}
            userInterestLoading={userInterestLoading}
          />
          <LanguageSelection
            handleLanguageChange={handleLanguageChange}
            selectedLanguage={selectedLanguage}
            isLanguageModalOpen={isLanguageModalOpen}
            isLanguageSelectedInProgress={isLanguageSelectedInProgress}
            handleLanguageSelect={handleLanguageSelect}
            onLanguageModalToggle={onLanguageModalToggle}
            language={language}
            languageData={languageData}
          />
        </>
      )}
    </Box>
  );
}
