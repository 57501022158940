import React from "react";
import type { Dispatch, SetStateAction } from "react";
import Style from "./style.module.css";
import EmptyState from "./EmptyState";
import FileList from "./FileList";
import FileListAlert from "./FileListAlert";
import DocumentUploadHeader from "./DocumentUploadHeader";
import DocumentUploadFooter from "./DocumentUploadFooter";
import DocumentUploadAlert from "./DocumentUploadAlert";
import DocumentViewer from "@mono-farmart-web/farmart-web-common/ComponentsV3/UploadDocument/viewer/DocumentViewer";
import { DocumentProvider } from "@mono-farmart-web/farmart-web-common/ComponentsV3/UploadDocument/DocumentContext";
import Toast from "@mono-farmart-web/farmart-web-common/ComponentV2/Toast";
import type { PreviewObject } from "../../hooks/DocumentUpload/types";
import CancelUploadModal from "./CancelUploadModal";
import DocumentType, { document_types } from "@mono-farmart-web/farmart-web-common/constant/enums/docType";
import {
	LoginView,
	useAuth,
} from "@mono-farmart-web/farmart-web-common/context/login";
import type { SaudaDetailResp } from "@mono-farmart-web/farmart-web-common/modal/Traders/types";
import ErrorMessageToast from "@mono-farmart-web/farmart-web-common/ComponentV2/ErrorMessageToast";
import saudaTypeEnum from "@mono-farmart-web/farmart-web-common/constant/enums/saudaTypeEnum";
import { getUserProfileFromStorage } from "@mono-farmart-web/farmart-web-common/modal/auth/auth.model";
import FileNumberAlert from "./FileNumberAlert";
interface DocumentUploadProps {
	docType?: number;
	onClickBack?: () => void;
	language: { [key: string]: any };
	showDocumentUploadFooter: boolean;
	showDocumentUploadAlert: boolean;
	doneDocumentUpload: () => void;
	handleFileChange?: (
		event: React.ChangeEvent<HTMLInputElement>,
		index?: number,
	) => void;
	handleUploadButtonClick?: (from?: string, index?: number) => void;
	fileInputRef: any;
	documents: any;
	preViewDocument: (documents: any, index?: number) => void;
	selectedDocument: PreviewObject;
	showCancelUploadModal: boolean;
	toggleCancelUploadModal: () => void;
	onCLickCancelUpload: () => void;
	removeDocument: () => void;
	onPressClosePreview: () => void;
	multiFileUpload: boolean;
	reUploadInputRef: any;
	handleReUploadChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	handleReUploadButtonClick: (
		from?: string,
		index?: number,
		file?: any,
	) => void;
	disableDone?: boolean;
	deleteDocVisible?: boolean;
	documentUploadToastPopup: boolean;
	reuploadVisible: boolean;
	currentPreviewIndex?: number;
	fileLengthAlert: boolean;
	fileNumberAlert: boolean;
	setFileLengthAlert: any;
	setFileNumberAlert: any;
	initialPageLoading: boolean;
	saudaDetailData: SaudaDetailResp;
	setIsError: Dispatch<SetStateAction<boolean>>;
	isError: boolean;
	fileSizeExceededToast: boolean;
	fileFormatNotSupported: boolean;
	uploadDocAssets: { [key: string]: string };
}

const DocumentUpload = ({
	docType = 1,
	onClickBack,
	language,
	showDocumentUploadFooter,
	showDocumentUploadAlert,
	doneDocumentUpload,
	handleFileChange,
	handleUploadButtonClick,
	fileInputRef,
	documents,
	preViewDocument,
	selectedDocument,
	showCancelUploadModal,
	toggleCancelUploadModal,
	onCLickCancelUpload,
	removeDocument,
	onPressClosePreview,
	multiFileUpload,
	reUploadInputRef,
	handleReUploadChange,
	handleReUploadButtonClick,
	disableDone,
	deleteDocVisible,
	documentUploadToastPopup,
	reuploadVisible,
	currentPreviewIndex,
	fileLengthAlert,
	fileNumberAlert,
	setFileLengthAlert,
	setFileNumberAlert,
	initialPageLoading,
	saudaDetailData,
	setIsError,
	isError,
	fileSizeExceededToast,
	fileFormatNotSupported,
	uploadDocAssets,
}: DocumentUploadProps) => {
	const userProfile = getUserProfileFromStorage() || null;
	const userlogedIn =
		JSON.parse(localStorage.getItem("loged")) ||
		userProfile?.merchant_detail_id ||
		userProfile?.merchant_occupation_id;

	const documentType = DocumentType.get(docType);
	const fileCount = [document_types.BUYER_GRN, document_types.UNLOADING_WEIGHT_SLIP]?.includes(documentType) ? 1 : 5;
	const isAddMoreDisabled = documents?.length  >= fileCount;


		// (["buyerGRN", "unloadingWeightSlip"]?.includes(documentType) && documents?.length >= 1) ||
		// (["invoice_docs", "weight_slip", "mandi_tax"]?.includes(documentType) &&
		// 	documents?.length >= 5);

	return (
		<div className={Style.container}>
			<FileListAlert
				language={language}
				fileLengthAlert={fileLengthAlert}
				setFileLengthAlert={setFileLengthAlert}
				docType={docType}
			/>
			<FileNumberAlert
				language={language}
				fileNumberAlert={fileNumberAlert}
				setFileNumberAlert={setFileNumberAlert}
				docType={docType}
			/>
			{selectedDocument ? (
				<DocumentProvider
					initialDocuments={[selectedDocument]}
					removeDocument={removeDocument}
					onPressClosePreview={onPressClosePreview}
					label={language[DocumentType.get(docType)]}
					deleteVisible={deleteDocVisible}
					reuploadVisible={reuploadVisible}
					reuploadLabel={language?.reUpload}
					reUploadInputRef={reUploadInputRef}
					handleReUploadChange={handleReUploadChange}
					handleReUploadButtonClick={handleReUploadButtonClick}
					currentPreviewIndex={currentPreviewIndex}
				>
					<DocumentViewer />
				</DocumentProvider>
			) : (
				<>
					<div>
						<DocumentUploadHeader
							onClickBack={onClickBack}
							docType={docType}
							language={language}
						/>
						{documents?.length > 0 ? (
							<div className={Style.content}>
								{showDocumentUploadAlert &&
									saudaDetailData?.sauda_creation_type !==
										saudaTypeEnum?.offline && (
										<DocumentUploadAlert
											text={language?.reUploadRejectedDocuments}
										/>
									)}
								<FileList
									docType={docType}
									preViewDocument={preViewDocument}
									documents={documents}
									language={language}
									fileInputRef={fileInputRef}
									handleFileChange={handleFileChange}
									handleUploadButtonClick={handleUploadButtonClick}
									multiFileUpload={multiFileUpload}
									reUploadInputRef={reUploadInputRef}
									handleReUploadChange={handleReUploadChange}
									handleReUploadButtonClick={handleReUploadButtonClick}
									initialPageLoading={initialPageLoading}
									saudaType={saudaDetailData?.sauda_creation_type}
								/>
							</div>
						) : (
							<EmptyState
								docType={docType}
								language={language}
								fileCount={fileCount}
								fileInputRef={fileInputRef}
								handleFileChange={handleFileChange}
								handleUploadButtonClick={handleUploadButtonClick}
								saudaDetailData={saudaDetailData}
								invoiceImage={uploadDocAssets.invoiceDoc}
								weightSlipImage={uploadDocAssets.weightSlip}
							/>
						)}
					</div>
					{documents?.length > 0 &&
						showDocumentUploadFooter &&
						(saudaDetailData?.sauda_creation_type !== saudaTypeEnum?.offline ||
							DocumentType.get(docType) === "buyerGRN" ||
							DocumentType.get(docType) === "unloadingWeightSlip") && (
							<DocumentUploadFooter
								fileCount={fileCount}
								fileInputRef={fileInputRef}
								isAddMoreDisabled={isAddMoreDisabled}
								handleFileChange={handleFileChange}
								handleUploadButtonClick={handleUploadButtonClick}
								doneDocumentUpload={doneDocumentUpload}
								language={language}
								disableDone={disableDone}
							/>
						)}
					<Toast
						isError={true}
						message={language?.errorMessage}
						isOpen={documentUploadToastPopup}
					/>
				</>
			)}
			<CancelUploadModal
				showCancelUploadModal={showCancelUploadModal}
				toggleCancelUploadModal={toggleCancelUploadModal}
				onCLickCancelUpload={onCLickCancelUpload}
				language={language}
				docType={docType}
			/>
			{!userlogedIn && <LoginView />}
			<ErrorMessageToast
				message={language?.errorMessage}
				isError={isError}
				setIsError={setIsError}
				ErrorToastType={true}
			/>
			<Toast
				isError={true}
				message={language?.fileSizeExceeded}
				isOpen={fileSizeExceededToast}
			/>
			<Toast
				isError={true}
				message={language?.fileFormatNotAccpetable}
				isOpen={fileFormatNotSupported}
			/>
		</div>
	);
};

export default DocumentUpload;
