import { Box, Skeleton } from '@mui/joy';
import MuiSkeleton from '@mono-farmart-web/farmart-web-common/MuiComponents/Skeleton';

export default function BalanceContainerLoading() {
  return (
    <Box display={'flex'} flexDirection={'row'} gap={3} marginLeft={'16px'}>
      <Box display={'flex'} width={'40px'} height={'40px'}>
        <MuiSkeleton
          itemNumber={1}
          variant="circular"
          sx={{
            height: '40px',
            width: '40px', // This will take full width of the Box
          }}
        />
      </Box>
      <Box
        justifyContent="space-between" // Center horizontally
        flexDirection="column"
        flex={10}
        justifyItems={'center'}
        display={'flex'}
      >
        <MuiSkeleton
          itemNumber={1}
          variant="rectangular"
          sx={{
            height: '16px',
            width: '40%', // This will take full width of the Box
          }}
        />
        <MuiSkeleton
          itemNumber={1}
          variant="rectangular"
          sx={{
            height: '16px',
            width: '60%', // This will take full width of the Box
          }}
        />
      </Box>
    </Box>
  );
}
