import React, { useState, useEffect } from 'react';
import Styles from './style.module.css';
import TradeListCard from '../TradeListCard';
import {
  ActiveTrade,
  ExpiredTrade,
  PromotionalMediaList,
} from '@mono-farmart-web/farmart-web-common/modal/Traders/types';
import InfiniteScroll from '@mono-farmart-web/farmart-web-common/Components/InfinityScrollV2';
import promotionalTradeBannerenum from '@mono-farmart-web/farmart-web-common/constant/enums/promotionalTradeBannerenum';
import { Box } from '@mui/joy';
import MuiTypography from '@mono-farmart-web/farmart-web-common/ComponentV3/Typography';
import TradeSkeleton from '@mono-farmart-web/farmart-web-common/ComponentsV4/TradeCardSkeleton';


interface TradeListProps {
  buyersList: ActiveTrade[];
  totalRecords: number;
  getMoreTradeListData: () => void;
  buyersListLoading: boolean;
  language: { [key: string]: string };
  currentLanguageId: string;
  onCLickViewDetail: (
    id: string,
    cropName: string,
    buyerName: string,
    requestSauda: boolean,
  ) => void;
  handleNumberCopyToast: (
    phoneNumber: number,
    cropName: string,
    buyerName: string,
  ) => void;
  activeTradeList: ActiveTrade[];
  expiredTradeList: ExpiredTrade[];
  serviciability: boolean;
  tradeListPageNumber: number;
  saudaRequestToastPopup: boolean;
  handleSaudaRequestToastPopup: () => void;
  promotionalMediaList: PromotionalMediaList[];
  noBuyerListImage: string;
  noExpiredListImage: string;
  discountCardBgSmall: string;
  startDecorationLine: string;
  endDecorationLine: string;
  serviciableLoading: boolean;
  activePriceCount: number;
  reloadTrades: () => Promise<void>;
  timeLanguage: { [key: string]: string };
}

const TradeList = (props: TradeListProps) => {
  const {
    buyersList,
    getMoreTradeListData,
    buyersListLoading,
    language,
    currentLanguageId,
    onCLickViewDetail,
    handleNumberCopyToast,
    activeTradeList,
    tradeListPageNumber,
    saudaRequestToastPopup,
    handleSaudaRequestToastPopup,
    promotionalMediaList,
    noBuyerListImage,
    discountCardBgSmall,
    endDecorationLine,
    startDecorationLine,
    totalRecords,
    serviciableLoading,
    activePriceCount,
    reloadTrades,
    timeLanguage,
  } = props;

  const [firstExpiredItemIndex, setFirstExpiredItemIndex] = useState<
    number | null
  >(null);
  useEffect(() => {
    // Find the index of the first expired item
    const expiredIndex = buyersList.findIndex((item) => item.is_expire === 1);
    setFirstExpiredItemIndex(expiredIndex !== -1 ? expiredIndex : null);
  }, [buyersList]);

  const buyers = (
    <div className={Styles?.tradeListContainer}>
      {buyersList?.length !== 0 &&
        activeTradeList?.length === 0 &&
        !buyersListLoading &&
        !serviciableLoading && (
          <div
            className={
              !firstExpiredItemIndex
                ? Styles.noDataTradeListCOntainer
                : Styles.noDataContainer
            }
          >
            <div className={Styles.noDataImageWrapper}>
              <img
                className={Styles.whatsAppIcon}
                src={noBuyerListImage}
                alt="NoBuyer List"
                height={54}
                width={54}
                loading="eager"
              />
            </div>
            <div>
              <div className={Styles.noImageHeadingText}>
                {language?.noActiveBuyerHeading}
              </div>
              <div className={Styles.noImageSubHeadingText}>
                {language?.noActiveBuyerSubHeading}
              </div>
            </div>
          </div>
        )}
      {buyersList?.length > 0
        ? buyersList?.map((items, index) => {
            const isExpired = items?.is_expire;

            // Show the label only for the first expired item
            const renderLabel = isExpired && index === firstExpiredItemIndex;
            return (
              <React.Fragment key={'tradeList' + index}>
                {renderLabel ? (
                  <div className={Styles.headerTextStyle}>
                    {language?.expiredPrice}
                  </div>
                ) : (
                  <></>
                )}
                {isExpired === 0 ? (
                  <>
                    {items?.show_banner ===
                      promotionalTradeBannerenum?.activeBanner &&
                      promotionalMediaList?.length > 0 &&
                      promotionalMediaList?.map((item, index) => {
                        return (
                          <img
                            src={item?.media_url}
                            className={Styles?.banner}
                            key={'promotionaTradeListBanner' + index}
                            loading="lazy"
                            alt="Promotional Banner"
                          />
                        );
                      })}
                    <TradeListCard
                      onCLickViewDetail={onCLickViewDetail}
                      key={index + 'ActiveTradeListCard'}
                      items={items}
                      language={language}
                      currentLanguageId={currentLanguageId}
                      handleNumberCopyToast={handleNumberCopyToast}
                      saudaRequestToastPopup={saudaRequestToastPopup}
                      handleSaudaRequestToastPopup={
                        handleSaudaRequestToastPopup
                      }
                      discountCardBgSmall={discountCardBgSmall}
                      timeLanguage={timeLanguage}
                    />
                  </>
                ) : (
                  <TradeListCard
                    onCLickViewDetail={onCLickViewDetail}
                    key={index + 'ExpiredTradeListCard'}
                    items={items}
                    language={language}
                    currentLanguageId={currentLanguageId}
                    handleNumberCopyToast={handleNumberCopyToast}
                    saudaRequestToastPopup={saudaRequestToastPopup}
                    handleSaudaRequestToastPopup={handleSaudaRequestToastPopup}
                    discountCardBgSmall={discountCardBgSmall}
                    timeLanguage={timeLanguage}
                  />
                )}
              </React.Fragment>
            );
          })
        : !buyersListLoading &&
          totalRecords === 0 &&
          !serviciableLoading && (
            <div className={Styles.noDataContainer}>
              <div className={Styles.noDataImageWrapper}>
                <img
                  className={Styles.whatsAppIcon}
                  src={noBuyerListImage as unknown as string}
                  alt="No Buyer List"
                  loading="eager"
                />
              </div>
              <div>
                <div className={Styles.noImageHeadingText}>
                  {language?.noActiveBuyerHeading}
                </div>
                <div className={Styles.noImageSubHeadingText}>
                  {language?.noActiveBuyerSubHeading}
                </div>
              </div>
            </div>
          )}
    </div>
  );

  return (
    <div className={Styles.tradeListContainer} id="tradeListContainer">
      {!buyersListLoading && !serviciableLoading && activePriceCount > 0 && (
        <Box
          display={'flex'}
          alignItems={'center'}
          boxSizing={'border-box'}
          margin={'0px 16px'}
          marginBottom={2}
          marginTop={4}
          gap={4}
        >
          <Box sx={{ flex: 1 }}>
            <img
              src={startDecorationLine}
              width="100%"
              loading="lazy"
              alt="startDecorationLine"
              height={2}
            />
          </Box>
          <Box
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
            }}
            marginTop={'4px'}
            id="totalactivebuyers_text_tradescreen"
            data-testid="totalactivebuyers_text_tradescreen"
          >
            <MuiTypography
              level="body-xxs"
              id="totalactivebuyers_text_tradescreen"
              data-testid="totalactivebuyers_text_tradescreen"
            >
              {activePriceCount} {language?.activeBuyerPrices}
            </MuiTypography>
          </Box>
          <Box sx={{ flex: 1 }}>
            <img
              src={endDecorationLine}
              width="100%"
              loading="lazy"
              alt="endDecorationLine"
              height={2}
            />
          </Box>
        </Box>
      )}

      <InfiniteScroll
        dataLength={buyersList?.length || 0}
        next={getMoreTradeListData}
        hasMore={buyersList?.length < totalRecords}
        scrollableTarget="tradeListContainer"
        loader={<TradeSkeleton count={6} />}
      >
        {buyers}
      </InfiniteScroll>
    </div>
  );
};

export default TradeList;
