import React, { useState } from 'react';
import Style from './style.module.css';
import Button from '@mui/joy/Button';
import { UploadRounded, Receipt ,LocationOn, DescriptionRounded} from '@mui/icons-material';
import { SaudaDetailResp } from '@mono-farmart-web/farmart-web-common/modal/Traders/types';
import { Box } from '@mui/joy';
import MuiTypography from '@mono-farmart-web/farmart-web-common/ComponentV3/Typography';
import { noop } from '@mono-farmart-web/farmart-web-common/utils/common';
interface EmptyStateProps {
	docType?: number;
	language: { [key: string]: any };
	handleFileChange?: (
		event: React.ChangeEvent<HTMLInputElement>,
		index?: number,
    count?: number
	) => void;
	handleUploadButtonClick?: (from: string, index?: number) => void;
	fileInputRef: any;
	saudaDetailData?: SaudaDetailResp;
	invoiceImage: string;
	weightSlipImage: string;
  fileCount: number;
}

const EmptyState = ({
	docType=1,
	language,
	handleFileChange=noop,
	handleUploadButtonClick=noop,
	fileInputRef,
	saudaDetailData,
	invoiceImage,
	weightSlipImage,
  fileCount,
}: EmptyStateProps) => {
  const [id, setId] = useState(docType); // use id to select the type of doc invoice_documents : 1 ,weight_slip_documents : 2 , mandi_documents : 3
  const invoice = (
    <div>
      <div className={Style.imageContainer}>
        <img
          src={invoiceImage}
          height={254}
          width={154}
          style={{ maxWidth: 154, maxHeight: 254 }}
          alt="invoice Empty State"
          loading="eager"
        />
      </div>
      <Box className={Style.infoContainerBorder}>
        <Box className={Style.infoSubContainer}>
          <Receipt sx={{ height: 20, width: 20, color: '#9FA6AD' }} />
          <MuiTypography level="body-xs" sx={{ color: '#171A1C' }}>
            {language?.billToTitle}:{' '}
            <MuiTypography level="body-xs" sx={{ color: '#555E68' }}>
              {language?.billTo}
            </MuiTypography>
          </MuiTypography>
        </Box>
        <Box className={Style.infoSubContainer}>
          <LocationOn sx={{ height: 20, width: 20, color: '#9FA6AD' }} />
          <MuiTypography level="body-xs" sx={{ color: '#171A1C' }}>
            {language?.addressTitle}:{' '}
            <MuiTypography level="body-xs" sx={{ color: '#555E68' }}>
              {saudaDetailData?.buyer_district_name}, {saudaDetailData?.buyer_state_name}
            </MuiTypography>
          </MuiTypography>
        </Box>
        <Box className={Style.infoSubContainer}>
          <DescriptionRounded sx={{ height: 20, width: 20, color: '#9FA6AD' }} />
          <MuiTypography level="body-xs" sx={{ color: '#171A1C' }}>
            {language?.gstTitle}:{' '}
            <MuiTypography level="body-xs" sx={{ color: '#555E68' }}>
            {saudaDetailData?.farmart_gst_no}
            </MuiTypography>
          </MuiTypography>
        </Box>
      </Box>
    </div>
  );
  const weightSlip = (
    <div>
      <div className={Style.imageContainer}>
        <img
          src={weightSlipImage}
          width={227}
          height={250}
          style={{ maxWidth: 227 }}
          alt="WeightSlip Empty State"
          loading="eager"
        />
      </div>
    </div>
  );

	const getSectionUI = () => {
		if (id === 1) {
			return invoice;
		} else if (id == 2) {
			return weightSlip;
		} else {
			return;
		}
	};

	return (
		<div className={Style.uploadContainer}>
			{getSectionUI()}
			<div className={Style.uploadContainerBorder}>
				<input
					type="file"
					accept=".pdf, .jpg, .jpeg, .png"
					multiple={(fileCount > 1 )}
					onChange={(event) => {
            handleFileChange(event, fileCount)
          }}
					style={{ display: "none" }}
					ref={fileInputRef}
				/>
				<Button
					sx={{
						backgroundColor: "#B2E0D8",
						color: "#007B65",
						borderRadius: "8px",
            "&:hover": {
              backgroundColor: '#81CCBF',
            },
            "&:disabled": {
              backgroundColor: "#FBFCFE",
              color: "#9FA6AD"
            }
					}}
					className={Style.custom}
					onClick={() => {
						handleUploadButtonClick("emptyState");
					}}
					size={"lg"}
					id="onClickUploadButtonEmptyState"
				>
					<UploadRounded />
					{language?.add_document}
				</Button>
			</div>
			<label className={Style.uploadContainerText}>
				{language?.supportedFormatsText}
			</label>
		</div>
	);
};

export default EmptyState;
