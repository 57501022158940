import { useEffect, useState } from "react";
import fetchRecentKhataList from "@mono-farmart-web/farmart-web-common/modal/bahiKhata/models/fetchRecentKhataList";

const useRecentKhataList = () => {
  const [recentKhataList, setRecentKhataList] = useState({
    count: 0,
    results: []
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchDataList = async () => {
    const queryParams = {
      limit: "8",
      page_no: "1",
      sort_by: "1",
      filter: "1",
      search_param: ""
    };

    try {
      setLoading(true);
      const response = await fetchRecentKhataList(queryParams);
      setRecentKhataList(response.data.transaction_list?.results);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    fetchDataList();
  }, []);

  return {
    recentKhataList,
    loading,
    error
  };
};

export default useRecentKhataList;
