import {
  Column,
  Row,
} from '@mono-farmart-web/farmart-web-common/MuiComponents/atoms/layout';
import Styles from './style.module.css';
// import SuccessAnimation from "@mono-farmart-web/farmart-web-common/MuiComponents/organisms/request/SuccessAnimation";
import SuccessFlower from '@mono-farmart-web/farmart-web-common/assets/svgComponet/SuccessFlower';
import { Text } from '@mono-farmart-web/farmart-web-common/MuiComponents/atoms/typography';
import DataSecuredSvg from '@mono-farmart-web/farmart-web-common/assets/svgComponet/DataSecured';
import getTranslatedLanguage from '@mono-farmart-web/farmart-web-common/utils/stringSubstitution';
import TransactionEnum from '@mono-farmart-web/farmart-web-common/constant/enums/transactionType';

const BahiKhataAcknowledgementScreen = ({ username, amount, language, transactionId }) => {

    const noticeLine = TransactionEnum?.["credit"] === transactionId ? language?.paymentNoticeLine : language?.receivedNoticeLine;

    return (
    <Column
      justifyContent={'center'}
      alignItems={'center'}
      height={'100vh'}
      className={Styles.container}
    >
      <Column
        width={'280px'}
        minHeight={'186px'}
        height={'auto'}
        gap={4}
        alignItems={'center'}
        justifyContent={'center'}
      >
        {/* <SuccessAnimation id="credit_acknowledgement" /> */}
        <SuccessFlower
          data-testid="bahikhata_transactionConfirm"
          content-desc="bahikhata_transactionConfirm" />
        <Column gap={1} alignItems={'center'} justifyContent={'center'}>
          <Text level="title-lg" fontWeight="600" textColor="text.primary">
            {language?.title}
          </Text>

          <Text
            level="body-xxs"
            fontWeight={500}
            textColor={'text.secondary'}
            width={'100%'}
            overflow={'hidden'}
            whiteSpace={'nowrap'}
            textOverflow={'ellipsis'}
            textAlign={'center'}
          >
            {getTranslatedLanguage(noticeLine, [
              amount,
              username,
            ])}
          </Text>
        </Column>
      </Column>

      <Row padding={4} justifyContent={'center'} alignItems={'center'}>
        <DataSecuredSvg />
      </Row>
    </Column>
  );
};

export default BahiKhataAcknowledgementScreen;
