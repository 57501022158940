import useDistributor from '../../hooks/Distributor/useDistributor';
import DistributorView from '../../Pages/Distributor';

export default function DistributorMain() {
  const {
    distributorListData,
    distributorListCount,
    onClickChangeModal,
    onClickChangeLocation,
    locationModalLoading,
    onClickGetGpsLocation,
    isLocationModal,
    language,
    currentLocation,
    handleCategoryModalOpenClose,
    categoryModalOpenClose,
    submitCategory,
    categoryData,
    currentCategory,
    onChangeCategory,
    distributorAssets,
    categoryNameFilter,
    productTypeModalOpenClose,
    handleProductTypeModalOpenClose,
    currentProductType,
    productTypeData,
    onChangeProductType,
    fetchMoreProductData,
    productTypeLoading,
    tempCurrentCategory,
    clearCurrentCatgory,
    clearCurrentDistributor,
    handleSearchProductType,
    onClickFaq,
    distributorLoading,
    loadMoreDistributors,
    onClickBack,
    cearAllSlectedProductType,
    tempCurrentProductType,
    submitProductType,
    productTypeSearchParam,
    handleCallClick,
    productTypeCount,
  } = useDistributor();

  return (
    <DistributorView
      distributorListData={distributorListData}
      distributorListCount={distributorListCount}
      onClickChangeModal={onClickChangeModal}
      onClickChangeLocation={onClickChangeLocation}
      locationModalLoading={locationModalLoading}
      onClickGetGpsLocation={onClickGetGpsLocation}
      isLocationModal={isLocationModal}
      language={language}
      currentLocation={currentLocation}
      handleCategoryModalOpenClose={handleCategoryModalOpenClose}
      categoryModalOpenClose={categoryModalOpenClose}
      submitCategory={submitCategory}
      categoryData={categoryData}
      currentCategory={currentCategory}
      onChangeCategory={onChangeCategory}
      distributorAssets={distributorAssets}
      categoryNameFilter={categoryNameFilter}
      productTypeModalOpenClose={productTypeModalOpenClose}
      handleProductTypeModalOpenClose={handleProductTypeModalOpenClose}
      currentProductType={currentProductType}
      productTypeData={productTypeData}
      onChangeProductType={onChangeProductType}
      fetchMoreProductData={fetchMoreProductData}
      productTypeLoading={productTypeLoading}
      tempCurrentCategory={tempCurrentCategory}
      clearCurrentCatgory={clearCurrentCatgory}
      clearCurrentDistributor={clearCurrentDistributor}
      handleSearchProductType={handleSearchProductType}
      onClickFaq={onClickFaq}
      distributorLoading={distributorLoading}
      loadMoreDistributors={loadMoreDistributors}
      onClickBack={onClickBack}
      cearAllSlectedProductType={cearAllSlectedProductType}
      tempCurrentProductType={tempCurrentProductType}
      submitProductType={submitProductType}
      productTypeSearchParam={productTypeSearchParam}
      handleCallClick={handleCallClick}
      productTypeCount={productTypeCount}
    />
  );
}
