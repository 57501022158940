import { Box } from '@mui/joy';
import Header from './Header';
import FilterSection from './FilterSection';
import DistributorList from './DistributorList';
import CategoryModal from './CategoryModal';
import ProductTypeModal from './ProductTypeModal';
import LocationModal from './LocationAccess/LocationModal';
import Location from './Location';
import Styles from './style.module.css';

export default function Distributor({
  onClickChangeModal,
  onClickChangeLocation,
  locationModalLoading,
  onClickGetGpsLocation,
  isLocationModal,
  language,
  currentLocation,
  handleCategoryModalOpenClose,
  categoryModalOpenClose,
  submitCategory,
  categoryData,
  currentCategory,
  onChangeCategory,
  distributorAssets,
  categoryNameFilter,
  productTypeModalOpenClose,
  handleProductTypeModalOpenClose,
  currentProductType,
  productTypeData,
  onChangeProductType,
  fetchMoreProductData,
  productTypeLoading,
  tempCurrentCategory,
  clearCurrentCatgory,
  clearCurrentDistributor,
  handleSearchProductType,
  distributorListData,
  distributorListCount,
  onClickFaq,
  distributorLoading,
  loadMoreDistributors,
  onClickBack,
  cearAllSlectedProductType,
  tempCurrentProductType,
  submitProductType,
  productTypeSearchParam,
  handleCallClick,
  productTypeCount,
}) {
  return (
    <Box className={Styles.container}>
      <Header
        onClickChangeModal={onClickChangeModal}
        currentLocation={currentLocation}
        language={language}
        onClickFaq={onClickFaq}
        onClickBack={onClickBack}
      />

      <Location
        onClickChangeModal={onClickChangeModal}
        currentLocation={currentLocation}
        language={language}
      />

      <FilterSection
        language={language}
        handleCategoryModalOpenClose={handleCategoryModalOpenClose}
        currentCategory={currentCategory}
        categoryNameFilter={categoryNameFilter}
        handleProductTypeModalOpenClose={handleProductTypeModalOpenClose}
        clearCurrentCatgory={clearCurrentCatgory}
        clearCurrentDistributor={clearCurrentDistributor}
        currentProductType={currentProductType}
      />

      <CategoryModal
        categoryModalOpenClose={categoryModalOpenClose}
        handleCategoryModalOpenClose={handleCategoryModalOpenClose}
        submitCategory={submitCategory}
        language={language}
        categoryData={categoryData}
        currentCategory={currentCategory}
        onChangeCategory={onChangeCategory}
        distributorAssets={distributorAssets}
        categoryNameFilter={categoryNameFilter}
        tempCurrentCategory={tempCurrentCategory}
      />

      <ProductTypeModal
        language={language}
        productTypeModalOpenClose={productTypeModalOpenClose}
        handleProductTypeModalOpenClose={handleProductTypeModalOpenClose}
        productTypeData={productTypeData}
        currentCategory={currentCategory}
        onChangeProductType={onChangeProductType}
        fetchMoreProductData={fetchMoreProductData}
        productTypeLoading={productTypeLoading}
        handleSearchProductType={handleSearchProductType}
        cearAllSlectedProductType={cearAllSlectedProductType}
        tempCurrentProductType={tempCurrentProductType}
        submitProductType={submitProductType}
        productTypeSearchParam={productTypeSearchParam}
        distributorAssets={distributorAssets}
        productTypeCount={productTypeCount}
      />

      <DistributorList
        distributors={distributorListData}
        totalCount={distributorListCount}
        language={language}
        categoryNameFilter={categoryNameFilter}
        distributorLoading={distributorLoading}
        loadMoreDistributors={loadMoreDistributors}
        distributorAssets={distributorAssets}
        handleCallClick={handleCallClick}
      />

      <LocationModal
        language={language}
        isLocationModal={isLocationModal}
        onClickGetGpsLocation={onClickGetGpsLocation}
        locationModalLoading={locationModalLoading}
        onClickChangeModal={onClickChangeModal}
        onClickChangeLocation={onClickChangeLocation}
      />
    </Box>
  );
}

