import React from 'react';
import { Box } from '@mui/joy';
import UserInterestCard from './userInterestCard';
import { UserInterestData } from '../../../hooks/UserInterest/types';

interface UserInterestListProps {
  userInterestList: UserInterestData[];
  handleSelectCard: (id: number) => void;
  selectedIds: number[];
  allSeleted: boolean;
}

export default function userInterestList(props: UserInterestListProps) {
  const { userInterestList, handleSelectCard, selectedIds, allSeleted } = props;
  return (
    <Box display={'flex'} flexDirection={'column'} gap={4} flex={1}>
      {userInterestList.map((item) => (
        <UserInterestCard
          item={item}
          handleSelectCard={handleSelectCard}
          selectedIds={selectedIds}
          allSeleted={allSeleted}
        />
      ))}
    </Box>
  );
}
