import LocationOnIcon from '@mui/icons-material/LocationOn';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MuiTypography from '@mono-farmart-web/farmart-web-common/ComponentV3/Typography';
import Styles from './style.module.css';
import { Box } from '@mui/joy';

export default function Location({
  onClickChangeModal,
  currentLocation,
  language,
}) {
  return (
    <Box
      bgcolor={'background.level1'}
      sx={{
        borderRadius: '0px 0px 16px 16px',
      }}
    >
      <Box className={Styles.locationContainer} onClick={onClickChangeModal}>
        <Box display={'flex'} alignItems={'center'} gap={'8px'}>
          <LocationOnIcon
            id="DistributorLocationIcon2"
            data-testid="DistributorLocationIcon2"
            sx={{ color: 'text.secondary' }}
          />
          <MuiTypography level="body-md" textColor={'text.primary'}>
            {currentLocation?.master_district_name},{' '}
            {currentLocation?.master_state_name}
          </MuiTypography>
        </Box>
        <Box
          id="DistributorLocation"
          data-testid="DistributorLocation"
          display={'flex'}
          alignItems={'center'}
          gap={'4px'}
        >
          <MuiTypography level="body-sm" textColor={'primary.plainColor'}>
            {language.changeLocationText}
          </MuiTypography>
          <KeyboardArrowDownIcon color={'primary'}
          />
        </Box>
      </Box>
    </Box>
  );
}
