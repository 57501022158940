import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import FeatureLocation from '@mono-farmart-web/farmart-web-common/utils/FeatureLocation/FeatureLcation';
import FeatureLocationId from '@mono-farmart-web/farmart-web-common/constant/enums/FeatureLocationId';
import { apiEndPoints } from '@mono-farmart-web/farmart-web-common/apis/endpoints/index';
import GetIpLocation from '@mono-farmart-web/farmart-web-common/modal/Traders/models/GetIpLocation';
import useAuthenticationChecker from '@mono-farmart-web/farmart-web-common/utils/authCheckerV2';
import { CategoryInterface, LocationInterface } from './types';
import useAppLanguage from '@mono-farmart-web/farmart-web-common/utils/applanguage/index';
import { languageUrls } from '@mono-farmart-web/farmart-web-common/constant/languageUrls/index';
import getLang from '@mono-farmart-web/farmart-web-common/languages/distributor';
import { getDistributorAssets } from '@mono-farmart-web/farmart-web-common/constant/imageUrls';
import DistributorType from '@mono-farmart-web/farmart-web-common/constant/enums/distributorType';
import { trackEvent } from '@mono-farmart-web/farmart-web-common/utils/moengage';
import Events from '@mono-farmart-web/farmart-web-common/constant/MoengageEvents/index';
import Dialer from "@mono-farmart-web/farmart-web-common/utils/dialer";

export default function useDistributor() {
  const navigate = useNavigate();
  const { pathname, state } = useLocation();
  const { AuthenticationCheckerApiCaller } = useAuthenticationChecker();
  const locationChanged = state?.locationChanged;
  const { language } = useAppLanguage(
    languageUrls?.distributorLanguageUrl,
    getLang,
  );
  const previousLocationRef = useRef<LocationInterface>(null);
  const [distributorListData, setDistributorListData] = useState<any>([]);
  const [distributorListPageNo, setDistributorListPageNo] = useState<number>(1);
  const [distributorListCount, setDistributorListCount] = useState<number>(0);
  const [distributorLoading,setDistibutorLoading] = useState<boolean>(false);

  const [isLocationModal, setLocationModal] = useState<boolean>(false);
  const [locationModalLoading, setLocationModalLoading] =
    useState<boolean>(false);
  const [currentLocation, setCurrentLocation] = useState<LocationInterface>(
    state?.changedLocation || {},
  );

  const [currentCategory, setCurrentCategory] = useState<number>(null);
  const [categoryModalOpenClose, setCategoryModalOpenClose] =
    useState<boolean>(false);
  const [categoryData, setCategoryData] = useState<CategoryInterface[]>();
  const [tempCurrentCategory, setTempCurrentCategory] = useState<number>(null);

  const [productTypeData, setProductTypeData] = useState<any>([]);
  const [productTypeModalOpenClose, setProductTypeModalOpenClose] =
    useState<boolean>(false);
  const [currentProductType, setCurrentProductType] = useState<number[]>([]);
  const [tempCurrentProductType, setTempCurrentProductType] = useState<number[]>([]);
  const [productTypePageNo, setProductTypePageNo] = useState<number>(1);
  const [productTypeSearchParam, setProductTypeSearchParam] =
    useState<string>('');
  const [productTypeLoading, setProductTypeLoading] = useState<boolean>(false);
  const [productTypeCount, setProductTypeCount] = useState<number>(0);

  const langId = JSON.parse(localStorage.getItem('userLanguage')); // Getting user's selected language
  const distributorAssets = getDistributorAssets(langId?.langCode || 'HI');

  async function handleProductTypeModalOpenClose() {
    setProductTypePageNo(1);
    setProductTypeData([]);
    setProductTypeSearchParam('');
    setProductTypeModalOpenClose(!productTypeModalOpenClose);
    
    if (!productTypeModalOpenClose) {
      setTempCurrentProductType(currentProductType);  
      getDistributorProductType();
    } else {
      setTempCurrentProductType([]); 
    }

    trackEvent(Events.onProductTypeToggleClick, {
      action: !productTypeModalOpenClose ? 'open' : 'close'
    });
  }

  function onChangeProductType(id: number) {
    return () => {
      setTempCurrentProductType((prevTypes) => {
        if (prevTypes.includes(id)) {
          return prevTypes.filter((typeId) => typeId !== id);
        } else {
          return [...prevTypes, id];
        }
      });
    };
  }

  function trimDecimalValues(value: number, trimUpto: number) {
    return Number(value.toFixed(trimUpto));
  }

  function onClickChangeModal() {
    trackEvent(Events.onlocationinformationbottomsheetclick, {
      state: currentLocation?.master_state_name,
      district: currentLocation?.master_district_name,
      action: 'manual_change'
    });
    setLocationModal(!isLocationModal);
  }

  function onClickChangeLocation() {
    trackEvent(Events.onLocationChangeToggleClick, {
      state: currentLocation?.master_state_name,
      district: currentLocation?.master_district_name
    });
    navigate(`/manualLocation`, {
      state: { prevPath: pathname },
    });
  }

  async function handleCategoryModalOpenClose() {
    setProductTypePageNo(1);
    setCategoryModalOpenClose(!categoryModalOpenClose);
    
    if (!categoryModalOpenClose) {
      setTempCurrentCategory(currentCategory);
      await getDistributorCategory();
    } else {
      setTempCurrentCategory(null);
    }
    
    trackEvent(Events.onCategoryToggleClick, {
      action: !categoryModalOpenClose ? 'open' : 'close'
    });
  }

  function submitCategory() {
    if(currentCategory !== tempCurrentCategory){
          setDistributorListPageNo(1);
          setDistributorListData([]);
          setDistributorListCount(0);
          setTempCurrentProductType([]);
          setCurrentProductType([]);
    }
    setCurrentCategory(tempCurrentCategory);
    setTempCurrentCategory(null);
    setCategoryModalOpenClose(!categoryModalOpenClose);
  }

  function clearCurrentCatgory(e: React.ChangeEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setCurrentCategory(null);
    setCurrentProductType([]);
    setDistributorListData([]);
  }

  function clearCurrentDistributor(e: React.ChangeEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setCurrentProductType([]);
    setDistributorListData([]);
  }

  // 2. Distributor List API log
  async function getDistributorList(pageNo?: number) {
    setDistibutorLoading(true);
    try {
      const payload = {
        limit: 25,
        page_no: pageNo||distributorListPageNo,
        state_id: currentLocation?.master_state_id,
        district_id: currentLocation?.master_district_id,
        product_type: currentProductType?.length>0 ? currentProductType: null,
        category: currentCategory,
      };

      const response = await AuthenticationCheckerApiCaller({
        payload,
        requestType: 'get',
        uri: apiEndPoints.getDistributorList,
      });


      if (response?.data?.status) {
          setDistributorListData([
            ...distributorListData,
            ...(response?.data?.data?.results ?? []),
          ]);
          setDistributorListPageNo((prevPageNo) => prevPageNo + 1);
          setDistributorListCount(response?.data?.data?.count);

        // Check if the location has changed
        const previousLocation = previousLocationRef.current;
        if (
          !previousLocation ||
          previousLocation.master_state_id !== currentLocation.master_state_id ||
          previousLocation.master_district_id !== currentLocation.master_district_id
        ) {
          // Update feature location
          const updateFeatureLocationParams = {
            feature_id: FeatureLocationId?.distributer_list,
            state_id: currentLocation.master_state_id,
            district_id: currentLocation.master_district_id,
          };

          await AuthenticationCheckerApiCaller({
            payload: updateFeatureLocationParams,
            uri: apiEndPoints.updateUserFeatureLocation,
            requestType: 'post',
          });

          // Update the previous location
          previousLocationRef.current = currentLocation;
        }
      }
      setDistibutorLoading(false);
    } catch (error) {
      setDistibutorLoading(false);
      console.error(' Distributor List Error:', error);
    }
  }

  function loadMoreDistributors() {
    if(distributorListData?.length < distributorListCount) {
      getDistributorList();
    }
  }

  async function checkLocation() {
    if (!locationChanged) {
      const { initialLoactionCheck } = FeatureLocation();
      const distributorLocation = await initialLoactionCheck();
      setCurrentLocation(distributorLocation);
    }
  }

  function onClickGetGpsLocation() {
    trackEvent(Events.onlocationchangesearchclick, {
      state: currentLocation?.master_state_name,
      district: currentLocation?.master_district_name,
      method: 'gps'
    });
    setLocationModalLoading(true);
    const options = {
      enableHighAccuracy: true, // Request high accuracy
      timeout: 6000, // Maximum time (in milliseconds) to wait for location data
      maximumAge: 0, // Maximum age (in milliseconds) of a possible cached position
    };
    if (navigator?.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          try {
            const reverseGeoParams = {
              lat: JSON.stringify(
                trimDecimalValues(position.coords.latitude, 4),
              ),
              long: JSON.stringify(
                trimDecimalValues(position.coords.longitude, 4),
              ),
            };
            const stateDistrict = await AuthenticationCheckerApiCaller({
              payload: reverseGeoParams,
              uri: apiEndPoints?.fetchStateDistrictsReverseGeoCode,
              requestType: 'get',
            });
            setCurrentLocation(stateDistrict?.data?.data?.reverse_geocode);
            setLocationModal(false);
            setLocationModalLoading(false);
          } catch (error) {
            console.log(error);
          }
        },
        (error) => {
          console.log(error);
          getIpLocationGlobalFun();
          setLocationModal(false);
          setLocationModalLoading(false);
        },
        options, // Pass the options object
      );
    } else {
      getIpLocationGlobalFun();
      setLocationModal(false);
      setLocationModalLoading(false);
    }
  }

  async function getIpLocationGlobalFun() {
    try {
      const IpLocationLatLong = await GetIpLocation();
      const reverseGeoParams = {
        lat: JSON.stringify(
          trimDecimalValues(IpLocationLatLong?.location?.lat, 4),
        ),
        long: JSON.stringify(
          trimDecimalValues(IpLocationLatLong?.location?.lng, 4),
        ),
      };
      const stateDistrict = await AuthenticationCheckerApiCaller({
        payload: reverseGeoParams,
        uri: apiEndPoints?.fetchStateDistrictsReverseGeoCode,
        requestType: 'get',
      });
      setCurrentLocation(stateDistrict?.data?.data?.reverse_geocode);
      setLocationModalLoading(false);
      setLocationModal(false);
    } catch (error) {
      if (error?.code === 401 || error?.code === 426) {
        setLocationModalLoading(false);
        setLocationModal(false);
      }
      console.log(error);
    }
  }

  async function getDistributorProductType() {
    setProductTypeLoading(true);
    try {
      const payload = {
        category: currentCategory,
        page_no: productTypePageNo,
        limit: 10,
        search_param: productTypeSearchParam,
      };

      const productTypeResponse = await AuthenticationCheckerApiCaller({
        payload,
        requestType: 'get',
        uri: apiEndPoints?.getDistributorProductType,
      });

      if (productTypeResponse?.data.status) {
        if (productTypeSearchParam && productTypeResponse?.data?.data?.count > 10) {
          setProductTypeCount(0);
          setProductTypeLoading(false);
          return;
        }
        
        if (productTypeSearchParam || productTypePageNo === 1) {
          setProductTypeData(productTypeResponse?.data?.data?.results);
        } else {
          // If loading more pages, append data
          setProductTypeData([
            ...productTypeData,
            ...(productTypeResponse?.data?.data?.results ?? []),
          ]);
        }
        setProductTypeCount(productTypeResponse?.data?.data?.count);
      }
      setProductTypeLoading(false);
    } catch (error) {
      console.error('Product Type Error:', error);
      setProductTypeLoading(false);
    }
  }

  // Keep this function for pagination
  async function fetchMoreProductData() {
    if (productTypeData?.length < productTypeCount) {
      setProductTypePageNo((lastValue) => {
        return lastValue + 1;
      });
    }
  }

  function handleSearchProductType(query: string) {
    setProductTypeSearchParam(query);
    setProductTypePageNo(1);
    setProductTypeCount(0);
    setProductTypeData([]);
    trackEvent(Events.onProductTypeSearch, {
      search_query: query
    });
  }

  // 3. Category API log
  async function getDistributorCategory() {
    try {
      const catergoryResponse = await AuthenticationCheckerApiCaller({
        payload: {
          state_id: currentLocation?.master_state_id,
          district_id: currentLocation?.master_district_id,
        },
        requestType: 'get',
        uri: apiEndPoints?.getDistributorCategory,
      });


      if (catergoryResponse?.data?.status) {
        setCategoryData(catergoryResponse?.data?.data);
      }
    } catch (error) {
      console.log(error);
      console.error('Category Error:', error);
    }
  }

  function onChangeCategory(id: number) {
    return () => {
      if (tempCurrentCategory === id) return;
      setTempCurrentCategory(id);
    };
  }

  function categoryNameFilter(id: number) {
    if (DistributorType?.fertiliser === id) {
      return language?.fertilisers;
    } else if (DistributorType?.seed === id) {
      return language?.seeds;
    } else if (DistributorType?.pesticide === id) {
      return language.pesticides;
    } else {
      return language?.language?.category;
    }
  }

  function onClickFaq(){
    navigate('/faq/questions');
  }

  function onClickBack() {
      navigate(-1)
   }

  async function resetData(){
    setDistributorListData([]);
    setDistributorListPageNo(1);
    setDistributorListCount(0);
/*     setCategoryData([]);
    setCurrentCategory(null);
    setProductTypeData([]);
    setCurrentProductType([]);
    setProductTypePageNo(1);
    setProductTypeCount(0); */
  }

  function cearAllSlectedProductType(){
    setDistributorListData([]);
    setTempCurrentProductType([]);
    setCurrentProductType([]);
  }

  function submitProductType(){
    setDistributorListData([]);
    setCurrentProductType(tempCurrentProductType);
    setTempCurrentProductType([]);
    setProductTypeModalOpenClose(!productTypeModalOpenClose);
    setProductTypeCount(0);
    setProductTypePageNo(1);
    setProductTypeData([]);
    setProductTypeSearchParam('');
  }

  function handleCallClick(name: string, address: string, category: string, mobileNumber: number) {
    trackEvent(Events.onCardClickWithinTheList, {
      distributor_name: name,
      distributor_location: address,
      category: category,
      mobile_number: mobileNumber
    });
    Dialer(mobileNumber);
  }

  useEffect(() => {
    if (currentCategory && productTypeModalOpenClose) {
      getDistributorProductType();
    }
  }, [productTypeSearchParam,productTypePageNo]);

  useEffect(() => {
    if(currentLocation?.master_district_id && currentLocation?.master_state_id){
      resetData().then(() => getDistributorList(1));
    }
  }, [currentLocation?.master_district_id, currentProductType, currentCategory]);

  useEffect(function () {
    checkLocation();
  }, []);

  return {
    distributorListData,
    distributorListCount,
    onClickChangeModal,
    onClickChangeLocation,
    locationModalLoading,
    onClickGetGpsLocation,
    isLocationModal,
    language,
    currentLocation,
    handleCategoryModalOpenClose,
    categoryModalOpenClose,
    submitCategory,
    categoryData,
    currentCategory,
    onChangeCategory,
    distributorAssets,
    categoryNameFilter,
    productTypeModalOpenClose,
    handleProductTypeModalOpenClose,
    currentProductType,
    productTypeData,
    onChangeProductType,
    fetchMoreProductData,
    productTypeLoading,
    tempCurrentCategory,
    clearCurrentCatgory,
    clearCurrentDistributor,
    handleSearchProductType,
    productTypeSearchParam,
    onClickFaq,
    distributorLoading,
    loadMoreDistributors,
    onClickBack,
    cearAllSlectedProductType,
    tempCurrentProductType,
    submitProductType,
    handleCallClick,
    productTypeCount
  };
}
