import { Box } from '@mui/joy';
import React from 'react';
import MuiButton from '@mono-farmart-web/farmart-web-common/ComponentV3/MuiButton';
import Styles from './style.module.css';
import ChevronRightRounded from '@mui/icons-material/ChevronRightRounded';
import MuiTypography from '@mono-farmart-web/farmart-web-common/ComponentV3/Typography';
import { FeatureListProps } from '../../../hooks/MyProfile/types';
import SkeletonLoading from '@mono-farmart-web/farmart-web-common/ComponentsV4/SkeletonLoading';
import { userType } from '@mono-farmart-web/farmart-web-common/constant/enums/userType';
import SupervisorAccountRoundedIcon from '@mui/icons-material/SupervisorAccountRounded';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import PrivacyTipRoundedIcon from '@mui/icons-material/PrivacyTipRounded';

export default function index(props: FeatureListProps) {
  const {
    language,
    onLanguageModalToggle,
    redirectToPlayStore,
    help,
    onClickPrivacyPolicy,
    onClickLogout,
    appVersion,
    loading,
    onClickMyFarmer,
    occupation,
    languageToggleIcon,
  } = props;
  const loadingMap = [{}, {}, {}, {}, {}];
  const skeletonLoadingRendering = loadingMap.map((item, index) => {
    return (
      <SkeletonLoading
        className={Styles?.skeleton}
        key={'myProfileFeatureListSkeletonLoading' + index}
      />
    );
  });
  return loading ? (
    skeletonLoadingRendering
  ) : (
    <Box
      display={'flex'}
      flexDirection={'column'}
      alignItems={'flex-start'}
      gap={'36px'}
      marginTop={'24px'}
      padding={'0px 16px'}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'flex-start'}
        gap={'var(--4, 16px)'}
        alignSelf={'stretch'}
      >
        {occupation !== userType['farmer'] && (
          <MuiButton
            className={Styles?.card}
            variant="outlined"
            color="neutral"
            onClick={onClickMyFarmer}
            id="myProfileMyFarmerButtonId"
            data-testid={'myProfileMyFarmerTestId'}
          >
            <div className={Styles?.titleContainer}>
              <SupervisorAccountRoundedIcon
                sx={{ height: 24, width: 24, fill: '#555E68' }}
              />
              <MuiTypography level="body-xs" textColor={'text.primary'}>
                {language?.myFarmer}
              </MuiTypography>
            </div>
            <ChevronRightRounded />
          </MuiButton>
        )}
        <MuiButton
          className={Styles?.card}
          variant="outlined"
          color="neutral"
          onClick={onLanguageModalToggle}
          id="myProfileChangeLanguageButtonId"
          data-testid={'myProfileChangeLanguageButtonTestId'}
        >
          <div className={Styles?.titleContainer}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M21.1 12H19.1C18.5 12 18.1 12.4 18.1 13C18.1 13.6 18.5 14 19.1 14V16H17.9C18 15.7 18.1 15.3 18.1 15C18.1 13.3 16.8 12 15.1 12C14 12 13 12.6 12.5 13.5C12.2 14 12.4 14.6 12.9 14.9C13.4 15.2 14 15 14.3 14.5C14.5 14.2 14.8 14 15.2 14C15.8 14 16.2 14.4 16.2 15C16.2 15.6 15.8 16 15.2 16C14.6 16 14.2 16.4 14.2 17C14.2 17.6 14.6 18 15.2 18C15.8 18 16.2 18.4 16.2 19C16.2 19.6 15.8 20 15.2 20C14.8 20 14.5 19.8 14.3 19.5C14 19 13.4 18.9 12.9 19.1C12.4 19.3 12.3 20 12.5 20.5C13.3 21.9 15.2 22.4 16.6 21.6C17.5 21.1 18.1 20.1 18.1 19C18.1 18.7 18 18.3 17.9 18H19.1V21C19.1 21.6 19.5 22 20.1 22C20.7 22 21.1 21.6 21.1 21V14C21.7 14 22.1 13.6 22.1 13C22.1 12.4 21.6 12 21.1 12ZM14.1 7H15.1C15.7 7 16.1 7.4 16.1 8V9C16.1 9.6 16.5 10 17.1 10C17.7 10 18.1 9.6 18.1 9V8C18.1 6.3 16.8 5 15.1 5H14.1C13.5 5 13.1 5.4 13.1 6C13.1 6.6 13.5 7 14.1 7ZM9.1 11.2C9.2 11.7 9.8 12.1 10.3 11.9C10.8 11.7 11.2 11.2 11 10.7L9.2 3.7C9 2.5 7.7 1.8 6.5 2.1C5.7 2.3 5 2.9 4.8 3.7L3 10.7C3 11.3 3.3 11.8 3.8 12C4.3 12.1 4.9 11.8 5 11.3L5.6 9H8.5L9.1 11.2ZM6.1 7L6.8 4.2C6.8 4.1 7 4 7.2 4C7.3 4 7.3 4.1 7.4 4.2L8 7H6.1ZM10.1 16H9.1C8.5 16 8.1 15.6 8.1 15V14C8.1 13.4 7.7 13 7.1 13C6.5 13 6.1 13.4 6.1 14V15C6.1 16.7 7.4 18 9.1 18H10.1C10.7 18 11.1 17.6 11.1 17C11.1 16.4 10.6 16 10.1 16Z"
                fill="#555E68"
              />
            </svg>
            <MuiTypography level="body-xs" textColor={'text.primary'}>
              {language?.changeLanguage}
            </MuiTypography>
          </div>
          <ChevronRightRounded />
        </MuiButton>
        <MuiButton
          className={Styles?.card}
          variant="outlined"
          color="neutral"
          onClick={redirectToPlayStore}
          id="myProfileFeedbackButtonId"
          data-testid={'myProfileFeedbackButtonTestId'}
        >
          <div className={Styles?.titleContainer}>
            <StarRoundedIcon sx={{ height: 24, width: 24, fill: '#555E68' }} />
            <MuiTypography level="body-xs" textColor={'text.primary'}>
              {language?.feedback}
            </MuiTypography>
          </div>
          <ChevronRightRounded />
        </MuiButton>
        <MuiButton
          className={Styles?.card}
          variant="outlined"
          color="neutral"
          onClick={help}
          id="myProfileHelpButtonId"
          data-testid="myProfileFeatureHelpButtonTestId"
        >
          <div className={Styles?.titleContainer}>
            <HelpRoundedIcon sx={{ height: 24, width: 24, fill: '#555E68' }} />
            <MuiTypography level="body-xs" textColor={'text.primary'}>
              {language?.helpText}
            </MuiTypography>
          </div>
          <ChevronRightRounded />
        </MuiButton>
        <MuiButton
          className={Styles?.card}
          variant="outlined"
          color="neutral"
          onClick={onClickPrivacyPolicy}
          id="myProfilePrivacyPolicyButtonId"
        >
          <div className={Styles?.titleContainer}>
            <PrivacyTipRoundedIcon
              sx={{ height: 24, width: 24, fill: '#555E68' }}
            />
            <MuiTypography level="body-xs" textColor={'text.primary'}>
              {language?.privacyPolicy}
            </MuiTypography>
          </div>
          <ChevronRightRounded />
        </MuiButton>
      </Box>
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'flex-start'}
        gap={'var(--3, 16px)'}
        alignSelf={'stretch'}
      >
        <MuiButton
          onClick={onClickLogout}
          size="md"
          color="danger"
          variant="outlined"
          sx={{
            minHeight: '40px',
            padding: '4px 16px',
            borderRadius: 'var(--radius-md, 8px)',
          }}
          id="myProfileLogoutButtonId"
        >
          {language?.logout}
        </MuiButton>
        <MuiTypography
          textColor={'text.tertiary'}
          level="label-sm"
          alignSelf={'center'}
        >
          {language?.appVersion} {appVersion}
        </MuiTypography>
      </Box>
    </Box>
  );
}
