/**
 * The function `updateDispatchApi` sends a PUT request to a specified URI with authentication and
 * language headers, handling errors and returning the response data.
 * @param {getRequestQueryParameter} props - The `props` parameter in the `updateDispatchApi` function
 * is an object with two properties:
 * @returns The `updateDispatchApi` function is returning a Promise that resolves to a
 * `UnifiedResponse` object. The `UnifiedResponse` object contains two properties:
 * 1. `data`: This property contains the data returned from the API call, which is obtained by calling
 * `response.json()` on the API response.
 * 2. `response`: This property contains the full API response object.
 */
import getConfigByKeys from "../../config/config";
import { configKeys } from "../../config/types";
import handleHttpError from "../api/httpErrorMapping";
import HttpStatusCode from "../../apis/types/httpStatusCodesEnums.type";
import type UnifiedResponse from "../../apis/types/unifiedResponse.type";
import { getHeaders } from "./fetchHeaders";

const { API_URL } = getConfigByKeys([configKeys.API_URL]);

interface getRequestQueryParameter {
  payload: any;
  uri?: string;
  customUri?: string;
  signal?: AbortSignal; // Add signal parameter
}

export default async function PatchRequest(
	props: getRequestQueryParameter,
): Promise<UnifiedResponse> {
	const { payload, uri, customUri, signal } = props;
  const headers = getHeaders();

  try {
    const response = await fetch(`${API_URL}${customUri ? customUri : uri}`, {
      method: "PATCH",
      headers: headers,
      body: JSON.stringify(payload),
      signal
    });
    if (response.status !== HttpStatusCode.Ok) {
      throw handleHttpError(response.status);
    }

    const data = await response.json();
    return { data, response };
  } catch(error) {
    console.log("Error::: (while updating the resource)", error);
  }
}
