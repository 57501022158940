/**
 * The above TypeScript code defines functions for guest login, storing and retrieving authentication
 * information from local storage.
 * @returns The code snippet provided contains several functions related to handling authentication
 * information in a web application. Here is a summary of what each function does:
 */
import { v4 as uuid } from "uuid";
import loginAsGuestApi from "../../../apis/auth/guestLogin";
import getConfigByKeys from "../../../config/config";
import { configKeys } from "../../../config/types";
import encryptAsAES from "../../../utils/encryption/aes";
import dateToObject from "../../../utils/date/dateToObject";
import type { AuthInfoToStorage, Profile } from "../types";

let loginAsGuestPromise: Promise<AuthInfoToStorage> | null = null;

export async function loginAsGuest(): Promise<AuthInfoToStorage | null> {

  if(import.meta.env.VITE_PLATFORM === "saudabook-pwa") {
    return Promise.resolve(null);
  }

	if (loginAsGuestPromise) {
		return loginAsGuestPromise; // If there's already a promise, return it
	}


	loginAsGuestPromise = (async () => {
		const { AES_KEY } = getConfigByKeys([configKeys.AES_KEY]);

		/* `const currentDate = dateToObject(new Date());` is creating a new Date object representing the
    current date and time, and then converting it into an object format using the `dateToObject`
    function. This allows for easier manipulation and extraction of specific date components such as
    day and month for further use in the code. */
		const currentDate = dateToObject(new Date());
		const uuidString = uuid();
		const uniqueId = currentDate.day + uuidString + currentDate.month;
		const token = await encryptAsAES(uniqueId, AES_KEY);

		try {
			const result = await loginAsGuestApi({ uuid: token });
			storeAuthInfoToStorage(result.data.data);
			localStorage.removeItem("profileSelected");
			return result.data.data;
		} catch (error) {
			console.log(error);
			throw error; // Ensure errors are propagated correctly
		} finally {
			loginAsGuestPromise = null; // Reset the promise after it's done
		}
	})();

	return loginAsGuestPromise;
}

export function storeAuthInfoToStorage(data: AuthInfoToStorage) {
	localStorage.setItem("authToken", data?.token || "null");
	localStorage.setItem("userProfile", JSON?.stringify(data?.profile || null));
	localStorage.setItem("userLanguage", JSON?.stringify(data?.language || null));
	localStorage.setItem(
		"appLocationPermission",
		JSON?.stringify(data?.appLocationPermission || null),
	);
	localStorage.setItem(
		"appRoutesData",
		JSON?.stringify(data?.appLocationPermission || null),
	);
	localStorage.setItem("navBarRemoved", JSON?.stringify(data?.navBarRemoved || null));
}

export function getAuthTokenFromStorage(): string {
	const token = localStorage.getItem("authToken");

	return token || "";
}

export function getUserProfileFromStorage(): AuthInfoToStorage["profile"] {
	const userProfile = localStorage.getItem("userProfile");

	return userProfile !== undefined && userProfile !== null
		? JSON?.parse(userProfile)
		: "";
}


export function getUserLanguageFromStorage(): AuthInfoToStorage["language"] {
	const userLanguage = localStorage.getItem("userLanguage");

	return userLanguage !== undefined ? JSON?.parse(userLanguage) : "";
}

export function setAuthTokenToStorage(token: string) {
  const tempToken = token || getAuthTokenFromStorage();
	localStorage.setItem(
		"authToken",
		tempToken,
	);
}

export function setProfileToStorage(profile: Profile) {
	localStorage.setItem(
		"userProfile",
		JSON?.stringify(profile || getUserProfileFromStorage()),
	);
}
