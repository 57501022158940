import React from 'react';
import Styles from './style.module.css';
import Modal from '@mono-farmart-web/farmart-web-common/ComponentV2/Modal';
import LocationSvg from '@mono-farmart-web/farmart-web-common/Images/LocationSvg.svg';
import { Box } from '@mui/joy';
import MuiTypography from '@mono-farmart-web/farmart-web-common/ComponentV3/Typography';
import MuiButton from '@mono-farmart-web/farmart-web-common/ComponentV3/MuiButton';

interface LocationModalProps {
  language: { [key: string]: string };
  onClickChangeLocation: () => void;
  onClickChangeModal: () => void;
  locationModalLoading: boolean;
  onClickGetGpsLocation: () => void;
  isLocationModal: boolean;
}
const LocationModal = (props: LocationModalProps) => {
  const {
    language,
    isLocationModal,
    locationModalLoading,
    onClickChangeLocation,
    onClickChangeModal,
    onClickGetGpsLocation,
  } = props;
  const locationModalData = (
    <Box margin={'16px'}>
      <Box
        display={'flex'}
        justifyContent={'center'}
        margin={'16px 0px 12px 0px'}
      >
        <img
          className={Styles.locationImage}
          src={LocationSvg as unknown as string}
          alt="locationImage"
          loading="eager"
        />
      </Box>
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        gap={'4px'}
        marginBottom={'16px'}
      >
        <MuiTypography
          level="title-sm"
          textColor={'text.primary'}
          marginBottom={'2px'}
        >
          {language?.locationModalGpsButton}
        </MuiTypography>
        <MuiTypography
          level="label-sm"
          textAlign={'center'}
          textColor={'text.secondary'}
          sx={{ lineHeight: '16px' }}
        >
          {language?.locationModalDescpSubHeading}
        </MuiTypography>
      </Box>
      <Box>
        <MuiButton
          onClick={onClickGetGpsLocation}
          loading={locationModalLoading}
          variant="solid"
          size="md"
          color="primary"
          data-testid={'get-gps-location-button'}
        >
          {language?.locationModalGpsButton}
        </MuiButton>
      </Box>
      <Box
        display={'flex'}
        alignItems={'center'}
        marginTop={'16px'}
        justifyContent={'center'}
      >
        <Box
          border={'1px solid black'}
          display={'flex'}
          flex={1}
          borderColor={'neutral.outlinedBorder'}
        ></Box>
        <MuiTypography
          level="title-sm"
          textColor={'text.tertiary'}
          margin={'0px 8px'}
        >
          {language?.locationModalDividerText}
        </MuiTypography>
        <Box
          border={'1px solid black'}
          display={'flex'}
          borderColor={'neutral.outlinedBorder'}
          flex={1}
        ></Box>
      </Box>
      <Box display={'flex'} flexDirection={'column'} marginTop={'12px'}>
        <MuiTypography
          level="title-sm"
          textColor={'text.primary'}
          marginBottom={'8px'}
        >
          {language?.pinCodeButtonHeading} 
        </MuiTypography>
        <Box
          display={'flex'}
          height={40}
          border={'1px solid black'}
          borderColor={'neutral.outlinedBorder'}
          borderRadius={8}
          bgcolor={'background.surface'}
          alignItems={'center'}
          paddingLeft={'12px'}
          id={'rakeListScreenLocationModalPincodeInputId'}
          data-testid="rakeListScreenLocationModalPincodeInputId"
          onClick={onClickChangeLocation}
        >
          <MuiTypography
            level="body-md"
            textColor={'text.tertiary'}
            textAlign={'center'}
          >
            {language?.pinCodeButtonTitle}
          </MuiTypography>
        </Box>
      </Box>
    </Box>
  );
  return (
    <Modal
      isOpen={isLocationModal}
      // eslint-disable-next-line react/no-children-prop
      children={locationModalData}
      title={language?.locationModalHeading}
      id={'rakeListScreenLocationModalCloseIconId'}
      isCloseButtonOption={true}
      onClose={onClickChangeModal}
      data-testid={'rakeListScreenLocationModalCloseIconId'}
    />
  );
};

export default LocationModal;
