import React, { Dispatch, SetStateAction } from 'react';
import Style from './style.module.css';
import SaudaAcknowledgementHeader from './Header'; // Importing the SaudaAcknowledgementHeader component
import DiscountCard from './DiscountCard'; // Importing the DiscountCard component
import CompulsoryDocCard from './CompulsoryDocCard'; // Importing the CompulsoryDocCard component
import BrokerCode from './BrokerCode'; // Importing the BrokerCode component
import AlertInfo from './Alert'; // Importing the Alert component
import Action from './Action'; // Importing the Action component
import PayAtLoadingCard from './PayAtLoadingCard'; // Importing the PayAtLoadingCard component
import { PopDataInterface } from '../../hooks/SaudaAcknowledgement/types';
import CommonErrorState from '@mono-farmart-web/farmart-web-common/ComponentsV4/CommonErrorState';
// Interface to define the props for the SaudaAcknowledgement component
interface SaudaAcknowledgementProps {
  quantity: number; // Quantity of the crop
  quantityUnit: string; // Unit of the quantity (e.g., kg, tons)
  cropName: string; // Name of the crop
  paymentTerms: string; // Payment terms for the transaction
  buyerName: string; // Name of the buyer
  buyerAddress: string; // Address of the buyer
  priceBreakup: any; // Detailed price breakup information
  netPrice: number; // Net price of the crop
  netPriceUnit: string; // Unit of the net price (e.g., INR, USD)
  confirmSaudaRequest: () => void; // Function to confirm the Sauda request
  confirmSaudaLoading: boolean; // Loading state for the confirm Sauda request
  language: { [key: string]: any }; // Language settings for localization
  onClickHelpSaudaAcknowledgement: () => void; // Function to handle help click
  onClickBackSaudaAcknowledgement: () => void; // Function to handle back click
  districtName: string; // Name of the district
  stateName: string; // Name of the state
  isError: boolean; // Error state
  setIsError: Dispatch<SetStateAction<boolean>>; // Function to set error state
  brokerCode?: string; // Optional broker code
  brokerFirmName?: string; // Optional broker firm name
  brokerCodeButtonLoading?: boolean; // Optional loading state for broker code button
  brokerCodeVerified?: boolean; // Optional verification state for broker code
  brokerCodeState?: number; // Optional state for broker code
  handleBrokerCodeInputChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => void; // Function to handle broker code input change
  verifyBrokerCode?: () => void; // Function to verify broker code
  showBrokerCodeInputText?: boolean; // Flag to show broker code input text
  clearBrokerCodeInput?: () => void; // Function to clear broker code input
  showInvalidBrokeCodeAlert?: boolean; // Flag to show invalid broker code alert
  setShowInvalidBrokerCodeAlert?: (flag: boolean) => void; // Function to set invalid broker code alert
  handleOnClickBrokerCodeInput?: () => void; // Function to handle broker code input click
  onClickPopUpDisable: (id?: number) => void; // Function to handle popup disable
  popupVisible: boolean; // Flag to show popup
  popupData: PopDataInterface; // PopDataInterface
  saudaDetailsAssets: { [key: string]: string };
}

// Main functional component
const SaudaAcknowledgement = (props: SaudaAcknowledgementProps) => {
  // Destructuring props
  const {
    quantity,
    quantityUnit,
    cropName,
    paymentTerms,
    buyerName,
    buyerAddress,
    priceBreakup,
    netPrice,
    netPriceUnit,
    confirmSaudaRequest,
    confirmSaudaLoading,
    language,
    onClickHelpSaudaAcknowledgement,
    onClickBackSaudaAcknowledgement,
    districtName,
    stateName,
    isError,
    setIsError,
    brokerCode,
    brokerFirmName,
    brokerCodeButtonLoading,
    brokerCodeState,
    brokerCodeVerified,
    handleBrokerCodeInputChange,
    verifyBrokerCode,
    showBrokerCodeInputText,
    clearBrokerCodeInput,
    showInvalidBrokeCodeAlert,
    setShowInvalidBrokerCodeAlert,
    handleOnClickBrokerCodeInput,
    onClickPopUpDisable,
    popupVisible,
    popupData,
    saudaDetailsAssets,
  } = props;

  return (
    <div className={Style.container}>
      {isError ? (
        <CommonErrorState onPressRetry={confirmSaudaRequest} />
      ) : (
        <div className={Style.wrapper}>
          {/* Header section */}
          <SaudaAcknowledgementHeader
            onClickBackSaudaAcknowledgement={onClickBackSaudaAcknowledgement}
            language={language}
            onClickHelpSaudaAcknowledgement={onClickHelpSaudaAcknowledgement}
            saudaDetailsAssets={saudaDetailsAssets}
          />
          <div className={Style.body}>
            <div className={Style.content}>
              {/* Pay at loading card section */}
              <PayAtLoadingCard
                priceBreakup={
                  paymentTerms === 'buyers-term'
                    ? priceBreakup?.price_breakup_buyer
                    : priceBreakup?.price_breakup_grn
                }
                quantityUnit={quantityUnit}
                cropName={cropName}
                buyerName={buyerName}
                buyerAddress={buyerAddress}
                quantity={quantity}
                language={language}
                onClickPopUpDisable={onClickPopUpDisable}
                popupVisible={popupVisible}
                popupData={popupData}
                paymentTerms={paymentTerms}
              />
              {/* Discount card section */}
              <DiscountCard
                quantity={quantity}
                netPrice={netPrice}
                paymentTerms={paymentTerms}
                priceBreakup={priceBreakup}
                language={language}
              />
              {/* Compulsory document card section */}
              <CompulsoryDocCard language={language} />
              {/* Broker code section */}
              <BrokerCode
                language={language}
                showBrokerCodeInputText={showBrokerCodeInputText}
                brokerCode={brokerCode}
                handleBrokerCodeInputChange={handleBrokerCodeInputChange}
                handleOnClickBrokerCodeInput={handleOnClickBrokerCodeInput}
                verifyBrokerCode={verifyBrokerCode}
                brokerCodeButtonLoading={brokerCodeButtonLoading}
                brokerCodeState={brokerCodeState}
                brokerFirmName={brokerFirmName}
                clearBrokerCodeInput={clearBrokerCodeInput}
                saudaDetailsAssets={saudaDetailsAssets}
              />
            </div>
            {/* Footer section with alert */}
            <AlertInfo
              language={language}
              setShowInvalidBrokerCodeAlert={setShowInvalidBrokerCodeAlert}
              showInvalidBrokeCodeAlert={showInvalidBrokeCodeAlert}
            />
          </div>
          {/* Action section with confirm button */}
          <Action
            confirmSaudaLoading={confirmSaudaLoading}
            confirmSaudaRequest={confirmSaudaRequest}
            language={language}
          />
        </div>
      )}
      {/* Error message toast component */}
      {/*  <ErrorMessageToast
        message={language?.errorMessage}
        isError={isError}
        setIsError={setIsError}
        ErrorToastType={true}
      /> */}
    </div>
  );
};

export default SaudaAcknowledgement;