import { createBridge } from "@mono-farmart-web/rpc-bridge";
import bridgeFunctions from "@mono-farmart-web/rpc-bridge/utility";
import isAndroid from "../../utils/operatingSystemType";
// Function to dial a mobile number
export default function Dialer(mobileNumber: number) {
  try {
    // Create a bridge instance
    const bridge = createBridge();
    // Check if the operating system is Android
    if (isAndroid()) {
      // Send a request to the native Android app to dial the number
      bridge.sendRequestToNative(
        "DialNumber",
        {
          number: mobileNumber,
        },
        (response) => {
          // Log the response from the native app
          bridgeFunctions.PrintLog(response);
        },
      );
    } else {
      // For non-Android devices, use the tel: URL scheme to open the dialer
      window.location.href = `tel:${mobileNumber}`;
    }
  } catch (error) {
    // Log any errors that occur during the process
    console.error("Error in Dialer function:", error);
  }
}
