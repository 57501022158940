import * as AppLanguages from '../constant/appLanguages';
//language will be added here
const languages = {
  [AppLanguages.appLanguagesIndex.english]: {
    title: "New Transaction",
    addKhataButton: "Add",
    savedKhataHeading: "Saved Khata",
    recentKhataHeading: "Recent Khata",
    searchPlaceholder: "Search khata name",
    enableContactPermission: "Enable contact permission",
    enableContactPermissionInfo: "Give permission to access contact book to create khata directly from your contacts.",
    downloadAppInfo: "To add farmers directly from the your Contact Book, please download our app.",
    downloadAppButton: "Download FarMart app"
  },
  [AppLanguages.appLanguagesIndex.hindi]: {
    title: "नया लेनदेन",
    addKhataButton: "जोड़ें",
    savedKhataHeading: "सेव किया हुआ खाता",
    recentKhataHeading: "हाल के खाते",
    searchPlaceholder: "खाता नाम खोजें",
    enableContactPermission: "संपर्क सूची की अनुमति दें ",
    enableContactPermissionInfo: "खाता सीधे अपने संपर्कों से बनाने के लिए संपर्क सूची की अनुमति दें।",
    downloadAppInfo: "किसानों को सीधे अपनी संपर्क सूची से जोड़ने के लिए, कृपया ऐप डाउनलोड करें।",
    downloadAppButton: "FarMart ऐप डाउनलोड करें"
  },
};

export default (language) => languages[language];
