import {
	Column,
	Row,
} from "@mono-farmart-web/farmart-web-common/MuiComponents/atoms/layout";
import getTranslatedLanguage from "@mono-farmart-web/farmart-web-common/utils/stringSubstitution";
import { Text } from "@mono-farmart-web/farmart-web-common/MuiComponents/atoms/typography";
import ButtonC from "@mono-farmart-web/farmart-web-common/MuiComponents/atoms/button/Button";

const getRemindCreditDialogJSX = (
  userDetails: { userName: string, amountValue: string },
  close: () => void,
  navigate: any,
  clearCreditDoc: (close: () => void) => void,
  handleCreditDetailSubmission,
  language: any,
  getTemplateDataArray: () => any,
  isTransactionCreated: boolean
) =>
{
	const dataArr = getTemplateDataArray();
	return (
    <Column
      bgcolor={"background.body"}
      boxSizing={"border-box"}>
      {/* HEADER */}
      <Row
        paddingTop={7}
        paddingRight={4}
        paddingBottom={1}
        paddingLeft={4}
        alignItems={"center"}
        justifyContent={"center"}>
        <Text
          level="title-sm"
          fontWeight={600}
          textColor="text.primary">
          {/* Send SMS to {userDetails?.userName} */}
          {getTranslatedLanguage(language?.sendSmsTo, [userDetails?.userName])}
        </Text>
      </Row>

      {/* BODY */}
      <Row
        padding={4}
        height={"152"}
        width={"auto"}>
        <Row
          paddingTop={"8px"}
          paddingRight={"12px"}
          paddingBottom={"8px"}
          paddingLeft={"12px"}
          bgcolor={"background.surface"}
          border={"1px solid"}
          borderColor={"neutral.outlinedBorder"}
          width={"100%"}
          height={"120px"}
          borderRadius={"8px"}>
          <Text
            level="body-md"
            fontWeight={400}
            textColor="text.primnary">
            {getTranslatedLanguage(language?.templateStr, dataArr)}
          </Text>
        </Row>
      </Row>

      {/* BOTTOM BUTTONS */}
      <Column
          padding={4}
          gap={3}
          bgcolor={"background.body"}
          boxSizing={"border-box"}>
          <ButtonC
            disabled={isTransactionCreated}
            id="bahikhata_RemindSMSConfirmbutton"
            data-testid="bahikhata_RemindSMSConfirmbutton"
            content-desc="bahikhata_RemindSMSConfirmbutton"
            color="primary"
            onClick={() => {
              handleCreditDetailSubmission(true);
              // navigate("/bahiKhata/creditEntry/acknowledgement", {
              //   state: {
              //     username: userDetails?.userName,
              //     amount: userDetails?.amountValue,
              //   }
              // })
              clearCreditDoc(close);
            }}
            sx={{
              fontWeight: "600",
              fontSize: "14px",
              borderRadius: "8px"
            }}>
            {language?.remindCreditButton}
          </ButtonC>

          <ButtonC
            disabled={isTransactionCreated}
            id="bahikhata_RemindSMSLaterbutton"
            data-testid="bahikhata_RemindSMSLaterbutton"
            content-desc="bahikhata_RemindSMSLaterbutton"
            onClick={() => {
              handleCreditDetailSubmission(false);
              // navigate("/bahiKhata/creditEntry/acknowledgement", {
              //   state: {
              //     username: userDetails?.userName,
              //     amount: userDetails?.amountValue,
              //   }
              // });
              clearCreditDoc(close);
            }}
            sx={{
              fontWeight: "600",
              fontSize: "14px",
              background: "#F0F4F8",
              borderRadius: "8px",
              color: "#32383E",
              "&:hover": {
                background: "#F0F4F8",
                color: "#32383E"
              },
              "&:active": {
                background: "#F0F4F8",
                color: "#32383E"
              }
            }}>
           {language?.sendLaterButton}
          </ButtonC>
        </Column>
    </Column>
  )
}

export default getRemindCreditDialogJSX;
