import React from 'react';
import Styles from './style.module.css';
import Header from './Header/Header';
import LoginInput from './LoginInput/Login';
import Actionbutton from './BottomContainer/Actionbutton';

import { MobileInputProps } from'../../hooks/Login/types';
import { Box } from '@mui/joy';
import LanguageSelection from '@mono-farmart-web/farmart-web-common/ComponentsV4/LanguageSelection'
import SkeletonLoading from '@mono-farmart-web/farmart-web-common/ComponentsV4/SkeletonLoading';

export default function Login(props: MobileInputProps) {
  const {
    error,
    getMobileNumber,
    userBlocked,
    userMobileNumber,
    onClickLoginProceed,
    loading,
    initialScreenLoading,
    handleLanguageSelect,
    onLanguageModalToggle,
    handleLanguageChange,
    selectedLanguage,
    isLanguageModalOpen,
    isLanguageSelectedInProgress,
    onClickPrivacyPolicy,
    language,
    onContactUs,
    loginScreenAssets,
    languageData,
    onClickBack,
    useKeyboardStatus,
    onClickInputClear
  } = props;
  const isKeyboardOpen = useKeyboardStatus();

  return (
    <Box className={Styles.container} bgcolor={'background.surface'}>
      {initialScreenLoading ? (
        <SkeletonLoading className={Styles.loading} itemNumber={6} />
      ) : (
        <>
          <Header
            onLanguageModalToggle={onLanguageModalToggle}
            language={language}
            farmartLogo={loginScreenAssets.farmartLogoWhite}
            languageSelectionIcon={loginScreenAssets.languageSelectionWhite}
            onClickBack={onClickBack}
            loginHeaderBanner={loginScreenAssets.loginHeaderBanner}
          />
          <LoginInput
            error={error}
            getMobileNumber={getMobileNumber}
            userBlocked={userBlocked}
            userMobileNumber={userMobileNumber}
            language={language}
            isKeyboardOpen={isKeyboardOpen}
            horizontalLine={loginScreenAssets.horizontalLine}
            onClickInputClear={onClickInputClear}
          />
          <Actionbutton
            onClickLoginProceed={onClickLoginProceed}
            loading={loading}
            userMobileNumber={userMobileNumber}
            onClickPrivacyPolicy={onClickPrivacyPolicy}
            language={language}
            onContactUs={onContactUs}
            whatsAppIconUrl={loginScreenAssets.whatsappIcon}
            isKeyboardOpen={isKeyboardOpen}
          />
          <LanguageSelection
            handleLanguageChange={handleLanguageChange}
            selectedLanguage={selectedLanguage}
            isLanguageModalOpen={isLanguageModalOpen}
            isLanguageSelectedInProgress={isLanguageSelectedInProgress}
            handleLanguageSelect={handleLanguageSelect}
            onLanguageModalToggle={onLanguageModalToggle}
            language={language}
            languageData={languageData}
          />
        </>
      )}
    </Box>
  );
}
