import { apiEndPoints } from "@mono-farmart-web/farmart-web-common/apis/endpoints";
import { useState, useEffect } from "react";
import getLang from "@mono-farmart-web/farmart-web-common/languages/creditEntry.js";
import getBahiKhataLang from "@mono-farmart-web/farmart-web-common/languages/bahiKhataDetail";
import { languageUrls } from "@mono-farmart-web/farmart-web-common/constant/languageUrls/index";
import useAppLanguage from "@mono-farmart-web/farmart-web-common/utils/applanguage/index.js";
import { useNavigate } from "react-router-dom";
import GenerateImageURL from "@mono-farmart-web/farmart-web-common/modal/bahiKhata/models/generateImageUrl.js";
import { trackEvent } from "@mono-farmart-web/farmart-web-common/utils/moengage/index.js";
import Events from "@mono-farmart-web/farmart-web-common/constant/MoengageEvents/index.js";
import useAuthenticationChecker from '@mono-farmart-web/farmart-web-common/utils/authCheckerV2';
import { userType } from '@mono-farmart-web/farmart-web-common/constant/enums/userType';
import { removeCommas } from "@mono-farmart-web/farmart-web-common/utils/FormatAmountValue";

const useLentCredit: any = ({ transactionId, username, farmer_detail_id, mobile_number }) => {
	const navigate = useNavigate();
	const [userName, setUserName] = useState(username);
	const [creditDate, setCreditDate] = useState(new Date());
  const [isImagePreview, setIsImagePreview] = useState(false);
  const [deleteImageModalVisible, setDeleteImageModalVisible] = useState(false);
	const [noteValue, setNoteValue] = useState("");
	const [creditAmount, setCreditAmount] = useState("");
  const [isActiveParent, setIsActiveParent] = useState(false);
  const [isImageReUpload, setIsImageReUpload] = useState(false);
  const [isOpenCalender, setIsOpenCalender] = useState(false);
  const [isCreditReminderModalVisible, setIsCreditReminderModalVisible] = useState(false);
  const [isTransactionCreated, setIsTransactionCreated] = useState(false);
  const [disableSaveButton, setDisableSaveButton] = useState(false);

	const [uploadedImage, setUploadedImage] = useState<any>({
		title: "",
		size: 0,
		type: "",
		data: "",
	});

  const { AuthenticationCheckerApiCaller } = useAuthenticationChecker();
  const [progressiveModalVisible, setProgressiveModalVisible] = useState(false);
  const [merchantData, setMerchantData] = useState<any>({});
  const [merchantDetail, setMerchantDetail] = useState({
    merchantName: '',
    merchantShopName: merchantData?.business_name || '',
  });
  const [isRetailer, setIsRetailer] = useState(false);

	const { language } = useAppLanguage(languageUrls?.bahiKhataCreditEntryLangUrl, getLang);
  const { language: deleteModalLang } = useAppLanguage(languageUrls.bahiKhataDetail, getBahiKhataLang);

	const handleCreditAmount = (event) => {
    const newValue = event.target.value;

    // Handle backspace when there's only one digit
    if (!newValue) {
      setCreditAmount('');
      return;
    }

    if(removeCommas(newValue) <= 10000000) {
      setCreditAmount(newValue);
    }
  };

  const handleKeyDownCreditAmount = (event) => {
    // Allow backspace to clear the last digit
    // if (event.key === 'Backspace') {
    //   setCreditAmount(prev => prev.slice(0, -1));
    // }
  };

	const handleNoteValue = (event: any) => {
		setNoteValue(event?.target?.value);
	};

	const handleCreditDate = (date: Date) => {
		setCreditDate(date);
    setIsOpenCalender(false);
	};

  function onPressClosePreview() {
		setIsImagePreview(false);
	}

  async function onClickDeleteImage() {
		try {

			setUploadedImage({
				title: "Image",
				size: null,
				type: "",
				data: "",
			});

      sessionStorage?.removeItem("creditDoc");
			setDeleteImageModalVisible(!deleteImageModalVisible);
			setIsImagePreview(false)
		} catch (error) {
			console.log("error in saving edited amount", error);
		}
	}

  function toggleDeleteImageModal(from: string) {
		setDeleteImageModalVisible(!deleteImageModalVisible);
		if (from === "cross") {
			trackEvent(Events?.onImageAttachedScreenCrossButtonClick, {});
		} else if (from === "previewDelete") {
			trackEvent(Events?.onBahiKhataImagePreviewScreenDeleteButtonClick, {});
		}
	}

  const handleCalenderToggle = () => {
    setIsOpenCalender(c => !c);
  }

  const viewImagePreview = () => {
    setIsImagePreview(true);
  }

  const handleDateSelectionEvent = () => {
    trackEvent(Events?.onRecordTransactionScreenDateSelectionButtonClick, {})
    handleCalenderToggle();
  }

  function removeEmojis(string) {
    const regex =
      /([#0-9]\u20E3)|[\xA9\xAE\u203C\u2047-\u2049\u2122\u2139\u3030\u303D\u3297\u3299][\uFE00-\uFEFF]?|[\u2190-\u21FF][\uFE00-\uFEFF]?|[\u2300-\u23FF][\uFE00-\uFEFF]?|[\u2460-\u24FF][\uFE00-\uFEFF]?|[\u25A0-\u25FF][\uFE00-\uFEFF]?|[\u2600-\u27BF][\uFE00-\uFEFF]?|[\u2900-\u297F][\uFE00-\uFEFF]?|[\u2B00-\u2BF0][\uFE00-\uFEFF]?|(?:\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDEFF])[\uFE00-\uFEFF]?/g;
    return string.replace(regex, '');
  }

  async function onCompleteUserInfo() {
    const updateMercahntprofileParam = {
      business_name: removeEmojis(merchantDetail.merchantShopName),
      merchant_name: removeEmojis(merchantDetail.merchantName),
      occupation_id: merchantData.merchant_occupation_id,
    };
    try {
      const response = await AuthenticationCheckerApiCaller({
        uri: apiEndPoints?.updateMerchantName,
        requestType: 'put',
        payload: updateMercahntprofileParam,
      });
      if (response?.data?.status) {
        setProgressiveModalVisible(false);
        handleOnClickSaveButton(false);
      }
    } catch (error) {
      console.log(error);
    }
  }

	function fileToDataURI(file: File) {
		if (!file) return;

		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = (e) => resolve(e.target.result);
			reader.onerror = (e) => reject(e);
			reader.readAsDataURL(file);
		});
	}

	const handleCreditDetailSubmission = async (send_acknowledgement) => {
		try {
      setIsTransactionCreated(true);
      setDisableSaveButton(true);
			const fileData = JSON.parse(sessionStorage?.getItem("creditDoc") || "{}");

			const payload = {
				farmer_detail_id: farmer_detail_id,
				amount: removeCommas(creditAmount),
				transaction_type: transactionId,
				image_proof_url: fileData?.data,
				transaction_date: creditDate,
				comment: noteValue,
				send_acknowledgement,
			};

      const response = await AuthenticationCheckerApiCaller({
        uri: apiEndPoints?.addNewTransaction,
        requestType: 'post',
        payload: payload,
      });

      if (response?.data?.data?.passbook) {
        const responseData = response?.data?.data?.passbook;
        sessionStorage?.removeItem("creditDoc");
        setDisableSaveButton(false);
        navigate("/bahiKhata/creditEntry/acknowledgement", {
          state: {
            username: userName?.trim() || mobile_number,
            amount: responseData?.amount,
            farmer_detail_id: responseData?.farmer_detail_id,
            transactionId: transactionId
          },
          replace: true,
        });
      }
		} catch (error) {} finally {
      setIsTransactionCreated(false);
      setDisableSaveButton(false);
    }
	};

  const handleReuploadState = (flag?: boolean) => {
    if(flag) {
      setIsImageReUpload(flag);
    } else {
      setIsImageReUpload(c => !c);
    }
  }

  const handleOpenService = async (event: any, type: string) => {
		const file = event?.target?.files[0];
		setUploadedImage({
			title: file.name,
			size: file.size,
			type: file.type,
			data: "",
		});
		fileToDataURI(file)
			.then((dataURI) => {
				// SAVING THE DATA IN SESSION STORAGE FOR LATER USAGE;
				const payload = { image: dataURI as string };
				GenerateImageURL(payload)
					.then((url) => {
						// SAVING THE GENERATED URL INSIDE THE SESSION STORAGE;
						// WHILE KEEPING THE OLD URI IN STATE;
						// FOR THE SAKE OF DOCUMENT VIEWER;
						sessionStorage?.setItem(
							"creditDoc",
							JSON.stringify({
								title: file.name,
								size: file.size,
								type: file.type,
								data: url,
							}),
						);
						setUploadedImage((file) => ({
							...file,
							data: dataURI as string,
						}));
					})
					.catch((error) => {
						console.error("Error:", error);
					});
			})
			.catch((error) => console.error("Error converting file:", error));
	};

  async function fetchMyProfile() {
    try {
      const response = await AuthenticationCheckerApiCaller({
        uri: apiEndPoints?.getMerchantProfile,
        requestType: 'post',
        payload: {},
      });
      if (response?.data?.status) {
        setIsRetailer(
          response?.data?.data?.merchant_occupation_id === userType['retailer'],
        );
        setMerchantData(response?.data?.data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const getTemplateDataArray = () => {
    const longDateIN = new Date(creditDate).toLocaleString(
      'en-IN',
      {
        timeZone: 'Asia/Kolkata',
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      },
    );

    const farmer_name = userName;
    const date = longDateIN;
    const shop_name = creditAmount;
    const phone_number = merchantData?.mobile_number;

    return [farmer_name, date, shop_name, phone_number];
  }

  function onChangeInput(value: string, type: string) {
    setMerchantDetail({ ...merchantDetail, [type]: value });
  }
  function onCloseProgressiveModal() {
    setProgressiveModalVisible(false);
  }

  const handleAmountInputClickEvent = () => {
    trackEvent(Events?.onRecordTransactionScreenAmountInputBoxClick, {});
  }

  const handleNoteInputClickEvent = () => {
    trackEvent(Events?.onRecordTransactionScreenNoteInputBoxClick, {})
  }

  const handleDeleteIconClickEvent = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    event?.stopPropagation();
    trackEvent(Events?.onImageAttachedScreenCrossButtonClick, {});
  }

  const handleOnClickSaveButton = (checkName) => {
    trackEvent(Events?.onRecordTransactionScreenSaveButtonClick, {});
    if (checkName) {
      if (
        !merchantData?.merchant_name &&
        merchantData.merchant_occupation_id === 5
      ) {
        return setProgressiveModalVisible(true);
      }

      if (!merchantData?.business_name || !merchantData?.merchant_name) {
        return setProgressiveModalVisible(true);
      }
    }

    setIsCreditReminderModalVisible(!isCreditReminderModalVisible);
  }

  const handleCreditImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    event?.stopPropagation();
    trackEvent(Events?.onRecordTransactionScreenUploadImageButtonClick, {});
    setIsImageReUpload(false);
    handleOpenService(event, "camera")
  }

  useEffect(() => {
    if(!creditAmount) {
      sessionStorage?.removeItem("creditDoc");
    }
  }, []);

  const handleActiveParentToggle = (toggleValue: boolean) => {
    setIsActiveParent(toggleValue);
    trackEvent(Events?.onRecordTransactionScreenAmountInputBoxClick, {});

  }

	const clearCreditDoc = (close: () => void) => {
		// RESETTING THE IMAGE DATA AND CLOSING THE BOTTOM DRAWER;
		sessionStorage?.removeItem("creditDoc");
		setUploadedImage({ title: "", size: 0, type: "", data: "" });
		close();
	};

	useEffect(() => {
		const creditDoc = sessionStorage?.getItem("creditDoc");

		if (!creditDoc) {
			setUploadedImage({ title: "", size: 0, type: "", data: "" });
			return;
		}

		if (creditDoc) {
			const parsedCreditDoc = JSON.parse(creditDoc);
			setUploadedImage(parsedCreditDoc);
		}
	}, []);

  useEffect(() => {
    fetchMyProfile();
  }, []);

	return {
		userName,
		creditDate,
		noteValue,
		creditAmount,
		handleCreditAmount,
		handleNoteValue,
		handleCreditDate,
		handleOpenService,
		uploadedImage,
		clearCreditDoc,
		handleCreditDetailSubmission,
		language,
    handleReuploadState,
    isImageReUpload,
    handleAmountInputClickEvent,
    handleNoteInputClickEvent,
    handleCreditImageUpload,
    handleActiveParentToggle,
    handleDeleteIconClickEvent,
    handleOnClickSaveButton,
    handleDateSelectionEvent,
    handleKeyDownCreditAmount,
    isOpenCalender,
    isActiveParent,
    onChangeInput,
    onCompleteUserInfo,
    onCloseProgressiveModal,
    progressiveModalVisible,
    merchantData,
    merchantDetail,
    isRetailer,
    isCreditReminderModalVisible,
    getTemplateDataArray,

    onPressClosePreview,
    toggleDeleteImageModal,
    deleteImageModalVisible,
    onClickDeleteImage,
    viewImagePreview,
    isImagePreview,
    deleteModalLang,
    isTransactionCreated,
    disableSaveButton
	};
};

export default useLentCredit;
