import React, { Suspense } from 'react';
import Style from './style.module.css';
import type { MySauda } from '../../viewmodal/mySauda/types';
import SaudaOpenCloseEnum from '@mono-farmart-web/farmart-web-common/constant/enums/saudaOpenCloseEnum';
import MuiSkeleton from '@mono-farmart-web/farmart-web-common/MuiComponents/Skeleton';
import CommonErrorState from '@mono-farmart-web/farmart-web-common/ComponentsV4/CommonErrorState';
import { getUserProfileFromStorage } from '@mono-farmart-web/farmart-web-common/modal/auth/auth.model';
// Lazy load components
const Header = React.lazy(
  () => import('@mono-farmart-web/farmart-web-common/ComponentV2/Header'),
);
const Tabs = React.lazy(
  () => import('@mono-farmart-web/farmart-web-common/ComponentV2/Tabs'),
);
const MySaudaList = React.lazy(() => import('./mySaudaList'));
const FilterModal = React.lazy(() => import('./MySaudaFilterModal'));
const LoginView = React.lazy(() =>
  import('@mono-farmart-web/farmart-web-common/context/login').then(
    (module) => ({
      default: module.LoginView,
    }),
  ),
);

export default function MySaudaView(props: MySauda) {
  const {
    onCloseSaudaStatusModal,
    saudaModalVisible,
    onSelectFilterStatus,
    selectedFilterStatus,
    mySaudaList,
    fetchMoreSaudaList,
    mySaudaLoading,
    onClickOpenCloseTab,
    selectedOpenCloseTab,
    onClickAcceptPo,
    onClickBack,
    onClickSaudaOrderCard,
    language,
    isServiceAvailable, // check if trade service is available or not
    mySaudaAssets,
    error,
    onClickRetry,
    onClickHelp,
    onContactUs,
    mySaudaTotalRecords,
  } = props;

  const saudaOpenCloseEnum = SaudaOpenCloseEnum.get('openClose');
  const userProfile = getUserProfileFromStorage() || null;
  const userlogedIn =
    JSON.parse(localStorage.getItem('loged')) ||
    userProfile?.merchant_detail_id ||
    userProfile?.merchant_occupation_id;
  return (
    <div className={Style.container}>
      <Suspense
        fallback={
          <MuiSkeleton
            sx={{ height: 50, width: '100%', borderRadius: 8, marginBottom: 4 }}
          />
        }
      >
        <Header
          title={language?.mySauda}
          onClickBackIcon={onClickBack}
          backButtonVisible={false}
        />
      </Suspense>
      {error && !mySaudaLoading && localStorage.getItem('loged') ? (
        <CommonErrorState
          onPressHelp={onClickHelp}
          onPressRetry={onClickRetry}
        />
      ) : (
        <>
          <Suspense
            fallback={
              <MuiSkeleton
                sx={{
                  height: 50,
                  width: '100%',
                  borderRadius: 8,
                  marginBottom: 4,
                }}
              />
            }
          >
            <Tabs
              onClickTab={onClickOpenCloseTab}
              selctedTab={selectedOpenCloseTab.id}
              tabData={saudaOpenCloseEnum}
              language={language}
            />
          </Suspense>
          <Suspense
            fallback={
              <MuiSkeleton
                itemNumber={3}
                sx={{
                  height: 100,
                  width: '100%',
                  borderRadius: 8,
                  marginBottom: 4,
                }}
              />
            }
          >
            <div className={Style.scrollableContainer}>
              <MySaudaList
                onCloseSaudaStatusModal={onCloseSaudaStatusModal}
                mySaudaList={mySaudaList}
                fetchMoreSaudaList={fetchMoreSaudaList}
                mySaudaLoading={mySaudaLoading}
                selectedOpenCloseTab={selectedOpenCloseTab}
                onClickAcceptPo={onClickAcceptPo}
                onClickBack={onClickBack}
                selectedFilterStatus={selectedFilterStatus}
                onClickSaudaOrderCard={onClickSaudaOrderCard}
                language={language}
                isServiceAvailable={isServiceAvailable}
                noSaudaImage={mySaudaAssets.noSaudaFound}
                onContactUs={onContactUs}
                mySaudaTotalRecords={mySaudaTotalRecords}
              />
            </div>
          </Suspense>
        </>
      )}
      <Suspense
        fallback={
          <MuiSkeleton
            sx={{
              height: 100,
              width: '100%',
              borderRadius: 8,
              marginBottom: 4,
            }}
          />
        }
      >
        <FilterModal
          onCloseSaudaStatusModal={onCloseSaudaStatusModal}
          saudaModalVisible={saudaModalVisible}
          onSelectFilterStatus={onSelectFilterStatus}
          selectedFilterStatus={selectedFilterStatus}
          language={language}
        />
      </Suspense>
      <Suspense
        fallback={
          <MuiSkeleton
            sx={{
              height: 100,
              width: '100%',
              borderRadius: 8,
              marginBottom: 4,
            }}
          />
        }
      >
        {!userlogedIn && <LoginView />}
      </Suspense>
    </div>
  );
}
