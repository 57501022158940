import type React from "react";
import { Box, Row } from "../../../atoms/layout";
import { Text } from "@mono-farmart-web/farmart-web-common/MuiComponents/atoms/typography";
// import AndroidSettings from "../AndroidSettings";

interface HeaderProps extends React.ComponentProps<typeof Box> {
	leftIcon?: React.ReactNode;
	title: string;
	rightIcon?: React.ReactNode;
}

const Header = (props: HeaderProps) => {
	const { leftIcon, title , rightIcon } = props;

  return (
		<Box

      {...props}
      padding={2}
      borderBottom={"1px solid"}
      borderColor={"neutral.outlinedDisabledBorder"}>
			<Row
        alignItems={"center"}
        justifyContent={"space-between"}>
        <Row
          alignItems={"center"}
          justifyContent={"center"}>
          <Row
            width={"40px"}
            height={"40px"}
            justifyContent={"center"}
            alignItems={"center"}>{leftIcon && leftIcon}</Row>
          <Text
            level="title-md"
            fontWeight="600"
            textColor="text.primary">{title}</Text>
        </Row>
        <Row
          width={"40px"}
          height={"40px"}
          justifyContent={"center"}
          alignItems={"center"}>{rightIcon && rightIcon}</Row>
      </Row>
		</Box>
	);
};

export default Header;
