import { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import type { PageList } from "@mono-farmart-web/farmart-web-common/modal/community/communityModel";
import type { RootState } from "@mono-farmart-web/farmart-web-common/store";
import { trackEvent } from "@mono-farmart-web/farmart-web-common/utils/moengage";
import Events from "@mono-farmart-web/farmart-web-common/constant/MoengageEvents/index";

import {
	setPages,
	setCurrentPageNumber as setPageNo,
	setSelectedPageId as setSelectedPage,
	resetPostList,
	setCurrentPostPageNumber,
} from "@mono-farmart-web/farmart-web-common/modal/community/slice/community.slice";
import useAuthenticationChecker from "@mono-farmart-web/farmart-web-common/utils/authCheckerV2";
import { apiEndPoints } from "@mono-farmart-web/farmart-web-common/apis/endpoints";

interface UsePages {
	pages: PageList;
	isFetchingAllPages: boolean;
	onClickPage: (id: number, name?: string) => () => void;
	selectedPage: number;
	fetchMorePages: () => void;
}

export default function usePages(): UsePages {
	const { AuthenticationCheckerApiCaller } = useAuthenticationChecker();
	const dispatch = useDispatch();

	const isFirstTimeScreenOpen = useRef(false);

	const { pages, pageNo, selectedPage } = useSelector((state: RootState) => {
		return {
			pages: state.community.pages,
			pageNo: state.community.pages.currentPageNumber,
			selectedPage: state.community.pages.selectedPageId,
		};
	});

	const [isFetchingAllPages, setIsFetchingAllPages] = useState(false);

	useEffect(() => {
		if (!isFirstTimeScreenOpen.current) {
			isFirstTimeScreenOpen.current = true;
			if (pages.pages.length) {
				return;
			}
		}

		fetchAllPages();
	}, [pageNo]);

	async function fetchAllPages() {
		setIsFetchingAllPages(true);

		try {
			const pageResult = await AuthenticationCheckerApiCaller({
				uri: apiEndPoints?.fetchPages,
				payload: {
					page_no: pageNo,
					limit: 10,
				},
				requestType: "get",
			});

			pageResult?.data?.data && dispatch(setPages(pageResult?.data?.data));
			setIsFetchingAllPages(false);
		} catch (error) {
			// Handle other errors
			console.log(error);
			setIsFetchingAllPages(false);
			// somethign went wrong
		} finally {
			setIsFetchingAllPages(false);
		}
	}

	function onClickPage(id: number, name?: string): () => void {
		return () => {
			trackEvent(Events?.onCommunityCategoryPageButtonClick, {
				From: "COMMUNITY_HOME_SCREEN",
				Page_name: name,
			});

			if (selectedPage !== id) {
				dispatch(resetPostList({ count: 0, rows: [] }));
			}
			dispatch(setSelectedPage(id));
			dispatch(setCurrentPostPageNumber(1));
		};
	}

	function fetchMorePages() {
		pages.count > pages.pages.length && dispatch(setPageNo(pageNo + 1));
	}

	return {
		pages: pages,
		isFetchingAllPages,
		onClickPage,
		selectedPage,
		fetchMorePages,
	};
}
