import RakeDetailView from '../../Pages/RakeDetail/RakeDetail';
import { useRakeDetailHook } from '../../hooks/RakeDetail/RakeDetailHook';

export default function RakeDetail() {
  /**
   * Destructures constants from the custom hook `useRakeDetailHook` to manage state and logic
   * related to the Rake detail view within the `RakeDetail` function component.
   * These constants represent various aspects of the Rake detail feature, including loading states,
   * data arrays, location details, callback functions, and language settings.
   */
  const {
    /**
     * Indicates whether nearby rake data is currently being loaded.
     * Type: boolean
     */
    nearRakeLoading,
    /**
     * Array of nearby rake data objects.
     * Type: NearRakeDataProps[]
     */
    nearRakeData,
    /**
     * Total count of nearby rake data items.
     * Type: number
     */
    nearRakeCount,
    /**
     * Array of current rake data objects.
     * Type: CurrentRakeDataProps[]
     */
    currentRakeData,
    /**
     * Total count of current rake data items.
     * Type: number
     */
    currentRakeCount,
    /**
     * Current location details.
     * Type: NearRakeDataProps
     */
    currentLocation,
    /**
     * Callback function to load more nearby rake data.
     * Type: () => void
     */
    onLoadMoreNearRake,
    /**
     * Indicates whether more rake data is being loaded when loading more.
     * Type: boolean
     */
    loadMoreRakeLoading,
    /**
     * Indicates whether current rake data is currently being loaded.
     * Type: boolean
     */
    currentRakeLoading,
    /**
     * Callback function to handle click on rake details.
     * Type: (selectedLocation: NearRakeDataProps) => () => void
     */
    onClickRakeDetails,
    /**
     * Callback function to handle press back action.
     * Type: () => void
     */
    onPressBack,
    /**
     * Callback function to handle click on help action.
     * Type: () => void
     */
    onClickHelp,
    /**
     * Language object containing key-value pairs for localized strings.
     * Type: { [key: string]: string }
     */
    language,
    /**
     * Unique key used for components requiring remounting.
     * Type: number
     */
    uniqueKey,
    rakeDetailAssets,
  } = useRakeDetailHook();

  return (
    <RakeDetailView
      nearRakeLoading={nearRakeLoading}
      nearRakeData={nearRakeData}
      nearRakeCount={nearRakeCount}
      currentRakeData={currentRakeData}
      currentRakeCount={currentRakeCount}
      currentLocation={currentLocation}
      onLoadMoreNearRake={onLoadMoreNearRake}
      loadMoreRakeLoading={loadMoreRakeLoading}
      currentRakeLoading={currentRakeLoading}
      onClickRakeDetails={onClickRakeDetails}
      onPressBack={onPressBack}
      onClickHelp={onClickHelp}
      language={language}
      uniqueKey={uniqueKey}
      rakeDetailAssets={rakeDetailAssets}
    />
  );
}
