import { defaultLanguageCode } from '../appLanguages';

const homeScreenAssets = new Map([
  [
    'HI',
    {
      farmartLogo2:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/6/18bcf225-8a86-45f2-9715-4222deaf63f2_FarMartLogo2.svg',
      distributorImage:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/10/7/049e98e3-e1d4-414e-bf8d-94ed7a6eb1b3_distributors.png',
      bahiKhataImage:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/10/7/0ba75e86-39dc-48a2-9d35-a982e6da6cf6_bahi%20khata.png',
      cropAdvisoryImage:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/6/4bbc4fb2-ea24-4fc9-bd36-7cddecb62dec_CropAdvisory.svg',
      dukaanPrachaarImage:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/6/2e19486d-0d42-4686-b1dc-8107bec71103_DukaanPrachar.svg',
      rakeImage:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/6/3e9d6456-9430-4437-8cec-ebe665136c60_Rake.svg',
      quizImage:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/10/7/9ce20d14-ba83-4594-8b93-e78dd9dce2ad_quiz.png',
      backgroundImage:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/6/4e689502-7469-48fb-bb64-ebd46d1e3257_Background.png',
      distributorCardImage:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/6/18ba1393-1870-4ccd-b219-f0bc432bbaaf_DistribuitorsCard.svg',
      bahiKhataCardImage:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/6/68057fec-b690-4164-9270-f97bf96390cf_BahiKhataCardImage.svg',
      cropAdvisoryCardImage:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/6/c87a2327-328a-45d9-a77e-d7cd573b83ca_CropAdvisoryCard.svg',
      dukaanPrachaarCardImage:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/6/8149a59f-f6b5-4bf6-8bcf-521803bfc357_DukaanPracharCard.svg',
      rakeCardImage:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/6/4fd1e7aa-9780-4ab7-8af0-f9c4688ae95d_RakeCard.svg',
      quizCardImage:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/6/b40b0536-3598-43ec-84ad-ca6b19eae3ce_QuizCard.svg',
      foodChainLogo:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/6/17c51dc5-5157-4191-af6a-72b0dcffd678_FoodChainLogo.svg',
      startDecorationLine:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/6/594ebb6b-2c29-4415-a504-5fb8f6406cc0_StartDecorationLine.svg',
      endDecorationLine:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/6/34b1b230-2e8f-4fa3-9d8e-b0dd610f1af2_EndDecorationLine.svg',
      whatsappIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/6/e9496da0-2146-43f4-b5c7-8c91fe95252b_Whatsapp.svg',
      addFilledIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/6/3cc14091-42b4-4248-a4e1-940c1e7405c0_AddFilledIcon.svg',
      locationIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/6/6d33a9ee-05f8-48ba-9121-33ff86da4f99_LocationSvg.svg',
      companyProfileIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/26/16a9030c-4301-4802-90a1-d8517d24900e_companyProfile.svg',
      clapIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/10/11/d820045e-e1b4-45fa-b584-010e5e41c7b4_clap.png',
      clapDoneIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/10/11/375d9c8c-ca16-402b-9e6e-90ccf75fc2e7_clapDone.png',
    },
  ],
  [
    'EN',
    {
      farmartLogo2:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/6/18bcf225-8a86-45f2-9715-4222deaf63f2_FarMartLogo2.svg',
      distributorImage:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/10/7/049e98e3-e1d4-414e-bf8d-94ed7a6eb1b3_distributors.png',
      bahiKhataImage:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/10/7/0ba75e86-39dc-48a2-9d35-a982e6da6cf6_bahi%20khata.png',
      cropAdvisoryImage:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/6/4bbc4fb2-ea24-4fc9-bd36-7cddecb62dec_CropAdvisory.svg',
      dukaanPrachaarImage:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/6/2e19486d-0d42-4686-b1dc-8107bec71103_DukaanPrachar.svg',
      rakeImage:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/6/3e9d6456-9430-4437-8cec-ebe665136c60_Rake.svg',
      quizImage:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/10/7/9ce20d14-ba83-4594-8b93-e78dd9dce2ad_quiz.png',
      backgroundImage:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/6/4e689502-7469-48fb-bb64-ebd46d1e3257_Background.png',
      distributorCardImage:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/6/18ba1393-1870-4ccd-b219-f0bc432bbaaf_DistribuitorsCard.svg',
      bahiKhataCardImage:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/6/68057fec-b690-4164-9270-f97bf96390cf_BahiKhataCardImage.svg',
      cropAdvisoryCardImage:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/6/c87a2327-328a-45d9-a77e-d7cd573b83ca_CropAdvisoryCard.svg',
      dukaanPrachaarCardImage:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/6/8149a59f-f6b5-4bf6-8bcf-521803bfc357_DukaanPracharCard.svg',
      rakeCardImage:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/6/4fd1e7aa-9780-4ab7-8af0-f9c4688ae95d_RakeCard.svg',
      quizCardImage:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/6/b40b0536-3598-43ec-84ad-ca6b19eae3ce_QuizCard.svg',
      foodChainLogo:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/6/17c51dc5-5157-4191-af6a-72b0dcffd678_FoodChainLogo.svg',
      startDecorationLine:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/6/594ebb6b-2c29-4415-a504-5fb8f6406cc0_StartDecorationLine.svg',
      endDecorationLine:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/6/34b1b230-2e8f-4fa3-9d8e-b0dd610f1af2_EndDecorationLine.svg',
      whatsappIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/6/e9496da0-2146-43f4-b5c7-8c91fe95252b_Whatsapp.svg',
      addFilledIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/6/3cc14091-42b4-4248-a4e1-940c1e7405c0_AddFilledIcon.svg',
      locationIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/6/6d33a9ee-05f8-48ba-9121-33ff86da4f99_LocationSvg.svg',
      companyProfileIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/26/16a9030c-4301-4802-90a1-d8517d24900e_companyProfile.svg',
      clapIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/10/11/d820045e-e1b4-45fa-b584-010e5e41c7b4_clap.png',
      clapDoneIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/10/11/375d9c8c-ca16-402b-9e6e-90ccf75fc2e7_clapDone.png',
    },
  ],
  [
    'MR',
    {
      farmartLogo2:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/6/18bcf225-8a86-45f2-9715-4222deaf63f2_FarMartLogo2.svg',
      distributorImage:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/10/7/049e98e3-e1d4-414e-bf8d-94ed7a6eb1b3_distributors.png',
      bahiKhataImage:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/10/7/0ba75e86-39dc-48a2-9d35-a982e6da6cf6_bahi%20khata.png',
      cropAdvisoryImage:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/6/4bbc4fb2-ea24-4fc9-bd36-7cddecb62dec_CropAdvisory.svg',
      dukaanPrachaarImage:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/6/2e19486d-0d42-4686-b1dc-8107bec71103_DukaanPrachar.svg',
      rakeImage:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/6/3e9d6456-9430-4437-8cec-ebe665136c60_Rake.svg',
      quizImage:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/10/7/9ce20d14-ba83-4594-8b93-e78dd9dce2ad_quiz.png',
      backgroundImage:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/6/4e689502-7469-48fb-bb64-ebd46d1e3257_Background.png',
      distributorCardImage:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/6/18ba1393-1870-4ccd-b219-f0bc432bbaaf_DistribuitorsCard.svg',
      bahiKhataCardImage:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/6/68057fec-b690-4164-9270-f97bf96390cf_BahiKhataCardImage.svg',
      cropAdvisoryCardImage:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/6/c87a2327-328a-45d9-a77e-d7cd573b83ca_CropAdvisoryCard.svg',
      dukaanPrachaarCardImage:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/6/8149a59f-f6b5-4bf6-8bcf-521803bfc357_DukaanPracharCard.svg',
      rakeCardImage:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/6/4fd1e7aa-9780-4ab7-8af0-f9c4688ae95d_RakeCard.svg',
      quizCardImage:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/6/b40b0536-3598-43ec-84ad-ca6b19eae3ce_QuizCard.svg',
      foodChainLogo:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/6/17c51dc5-5157-4191-af6a-72b0dcffd678_FoodChainLogo.svg',
      startDecorationLine:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/6/594ebb6b-2c29-4415-a504-5fb8f6406cc0_StartDecorationLine.svg',
      endDecorationLine:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/6/34b1b230-2e8f-4fa3-9d8e-b0dd610f1af2_EndDecorationLine.svg',
      whatsappIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/6/e9496da0-2146-43f4-b5c7-8c91fe95252b_Whatsapp.svg',
      addFilledIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/6/3cc14091-42b4-4248-a4e1-940c1e7405c0_AddFilledIcon.svg',
      locationIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/6/6d33a9ee-05f8-48ba-9121-33ff86da4f99_LocationSvg.svg',
      companyProfileIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/26/16a9030c-4301-4802-90a1-d8517d24900e_companyProfile.svg',
      clapIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/10/11/d820045e-e1b4-45fa-b584-010e5e41c7b4_clap.png',
      clapDoneIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/10/11/375d9c8c-ca16-402b-9e6e-90ccf75fc2e7_clapDone.png',
    },
  ]
]);

const loginScreenAssets = new Map([
  [
    'HI',
    {
      whatsappIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/6/e9496da0-2146-43f4-b5c7-8c91fe95252b_Whatsapp.svg',
      farmartLogo:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/10/11/7f8a37a8-cd56-41c5-af8f-d92f35b09003_fmtLogo.png',
      languageSelection:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/17/fd0059d4-8c02-4a13-982f-e38659b01a31_LanguageSelectIcon.svg',
      backIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/18/65232b43-9928-4f55-8520-524f92fb2c06_Back.svg',
      languageSelectionWhite:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/1/29/3e39c9e4-2cc8-4b90-8a5f-d7b09a45b38e_change_language.png',
      farmartLogoWhite: 'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/2/3/9159d48f-e118-4a44-9dee-54f316a078b4_farmartLogoWhite.png',
      loginHeaderBanner: 'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/1/28/c6e99680-8b8c-464a-9eed-d89660858272_login_banner.png',
      horizontalLine: 'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/12/5/2756d802-0587-4311-a7b0-8cae9d516e3f_Horizontal.png',
    },
  ],
  [
    'EN',
    {
      whatsappIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/6/e9496da0-2146-43f4-b5c7-8c91fe95252b_Whatsapp.svg',
      farmartLogo:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/10/11/7f8a37a8-cd56-41c5-af8f-d92f35b09003_fmtLogo.png',
      languageSelection:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/17/fd0059d4-8c02-4a13-982f-e38659b01a31_LanguageSelectIcon.svg',
      backIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/18/65232b43-9928-4f55-8520-524f92fb2c06_Back.svg',
      languageSelectionWhite:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/1/29/3e39c9e4-2cc8-4b90-8a5f-d7b09a45b38e_change_language.png',
      farmartLogoWhite: 'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/2/3/9159d48f-e118-4a44-9dee-54f316a078b4_farmartLogoWhite.png',
      loginHeaderBanner: 'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/1/28/c6e99680-8b8c-464a-9eed-d89660858272_login_banner.png',
      horizontalLine: 'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/12/5/2756d802-0587-4311-a7b0-8cae9d516e3f_Horizontal.png',
    },
  ],
  [
    'MR',
    {
      whatsappIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/6/e9496da0-2146-43f4-b5c7-8c91fe95252b_Whatsapp.svg',
      farmartLogo:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/10/11/7f8a37a8-cd56-41c5-af8f-d92f35b09003_fmtLogo.png',
      languageSelection:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/17/fd0059d4-8c02-4a13-982f-e38659b01a31_LanguageSelectIcon.svg',
      backIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/18/65232b43-9928-4f55-8520-524f92fb2c06_Back.svg',
      languageSelectionWhite:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/1/29/3e39c9e4-2cc8-4b90-8a5f-d7b09a45b38e_change_language.png',
      farmartLogoWhite: 'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/2/3/9159d48f-e118-4a44-9dee-54f316a078b4_farmartLogoWhite.png',
      loginHeaderBanner: 'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/1/28/c6e99680-8b8c-464a-9eed-d89660858272_login_banner.png',
      horizontalLine: 'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/12/5/2756d802-0587-4311-a7b0-8cae9d516e3f_Horizontal.png',
    },
  ]
]);

const tradeScreenAssets = new Map([
  [
    'HI',
    {
      whatsappIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/6/e9496da0-2146-43f4-b5c7-8c91fe95252b_Whatsapp.svg',
      farmartLogo:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/10/11/7f8a37a8-cd56-41c5-af8f-d92f35b09003_fmtLogo.png',
      languageSelection:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/17/fd0059d4-8c02-4a13-982f-e38659b01a31_LanguageSelectIcon.svg',
      backIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/18/65232b43-9928-4f55-8520-524f92fb2c06_Back.svg',
      activeSvg:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/18/9a33618d-a85f-495f-a819-b183af94662a_active.svg',
      inactiveSvg:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/18/58fdbbb8-2983-40be-976a-37cf240f0265_inactive.svg',
      searchIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/18/ea1f2bc9-1cc1-4223-97ce-de7190623135_searchIcon.svg',
      tradeComingSoon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/18/b4b14b3d-3edd-42b4-8d9e-4088d77cb505_TradeComingSoonHI.svg',
      noBuyerList:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/18/ad8f7c15-391e-4d0a-85c7-c9d0812e44e8_NoBuyerList.svg',
      noExpiredList:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/18/bd86cf87-ff62-4844-a40d-fd91f7248493_NoExpiredList.svg',
      locationIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/6/6d33a9ee-05f8-48ba-9121-33ff86da4f99_LocationSvg.svg',
      discountCardBgSmall:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/18/7f2b2704-220d-4685-8a49-a8f2c42f7925_DiscountCardBackgroundSmall.svg',
      startDecorationLine:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/1/6/86181d0c-01cb-40fe-8f80-51e68c0d4d91_Horizontal.png',
      endDecorationLine:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/1/6/86181d0c-01cb-40fe-8f80-51e68c0d4d91_Horizontal.png',
      buyerSearch:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/12/16/82f14767-884f-47ec-b5a0-7b953d3afeaa_searchBuyer2.png',
      noSearchedBuyerFound:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/12/16/c87ca1a9-8faa-413e-ba50-28cb41bcf3bd_NoBuyerFound2.png',
    },
  ],
  [
    'EN',
    {
      whatsappIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/6/e9496da0-2146-43f4-b5c7-8c91fe95252b_Whatsapp.svg',
      farmartLogo:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/10/11/7f8a37a8-cd56-41c5-af8f-d92f35b09003_fmtLogo.png',
      languageSelection:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/17/fd0059d4-8c02-4a13-982f-e38659b01a31_LanguageSelectIcon.svg',
      backIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/18/65232b43-9928-4f55-8520-524f92fb2c06_Back.svg',
      activeSvg:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/18/9a33618d-a85f-495f-a819-b183af94662a_active.svg',
      inactiveSvg:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/18/58fdbbb8-2983-40be-976a-37cf240f0265_inactive.svg',
      searchIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/18/ea1f2bc9-1cc1-4223-97ce-de7190623135_searchIcon.svg',
      tradeComingSoon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/18/886347a4-a154-4446-9edf-f903cb988451_TradeComingSoonEN.svg',
      noBuyerList:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/18/ad8f7c15-391e-4d0a-85c7-c9d0812e44e8_NoBuyerList.svg',
      noExpiredList:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/18/bd86cf87-ff62-4844-a40d-fd91f7248493_NoExpiredList.svg',
      locationIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/6/6d33a9ee-05f8-48ba-9121-33ff86da4f99_LocationSvg.svg',
      discountCardBgSmall:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/18/7f2b2704-220d-4685-8a49-a8f2c42f7925_DiscountCardBackgroundSmall.svg',
      startDecorationLine:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/1/6/86181d0c-01cb-40fe-8f80-51e68c0d4d91_Horizontal.png',
      endDecorationLine:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/1/6/86181d0c-01cb-40fe-8f80-51e68c0d4d91_Horizontal.png',
      buyerSearch:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/12/16/82f14767-884f-47ec-b5a0-7b953d3afeaa_searchBuyer2.png',
      noSearchedBuyerFound:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/12/16/c87ca1a9-8faa-413e-ba50-28cb41bcf3bd_NoBuyerFound2.png',
    },
  ],
  [
    'MR',
    {
      whatsappIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/6/e9496da0-2146-43f4-b5c7-8c91fe95252b_Whatsapp.svg',
      farmartLogo:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/10/11/7f8a37a8-cd56-41c5-af8f-d92f35b09003_fmtLogo.png',
      languageSelection:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/17/fd0059d4-8c02-4a13-982f-e38659b01a31_LanguageSelectIcon.svg',
      backIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/18/65232b43-9928-4f55-8520-524f92fb2c06_Back.svg',
      activeSvg:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/18/9a33618d-a85f-495f-a819-b183af94662a_active.svg',
      inactiveSvg:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/18/58fdbbb8-2983-40be-976a-37cf240f0265_inactive.svg',
      searchIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/18/ea1f2bc9-1cc1-4223-97ce-de7190623135_searchIcon.svg',
      tradeComingSoon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/11/18/1fb618f3-59a8-4305-b4da-69adcf6faa59_Marathi.png',
      noBuyerList:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/18/ad8f7c15-391e-4d0a-85c7-c9d0812e44e8_NoBuyerList.svg',
      noExpiredList:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/18/bd86cf87-ff62-4844-a40d-fd91f7248493_NoExpiredList.svg',
      locationIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/6/6d33a9ee-05f8-48ba-9121-33ff86da4f99_LocationSvg.svg',
      discountCardBgSmall:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/18/7f2b2704-220d-4685-8a49-a8f2c42f7925_DiscountCardBackgroundSmall.svg',
      startDecorationLine:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/1/6/86181d0c-01cb-40fe-8f80-51e68c0d4d91_Horizontal.png',
      endDecorationLine:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/1/6/86181d0c-01cb-40fe-8f80-51e68c0d4d91_Horizontal.png',
      buyerSearch:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/12/16/82f14767-884f-47ec-b5a0-7b953d3afeaa_searchBuyer2.png',
      noSearchedBuyerFound:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/12/16/c87ca1a9-8faa-413e-ba50-28cb41bcf3bd_NoBuyerFound2.png',
    },
  ],
]);

const buyerDetailAssets = new Map([
  [
    'HI',
    {
      discountCardBgBig:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/19/031146f2-1019-4074-b7c1-753e6ba63ff4_DiscountCardBackgroundBig.svg',
      discountFeeSmiley:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/19/7dd98b62-0860-4bdd-b821-bacf17403f0e_discountFeeSmiley.svg',
      magicIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/19/c4ced6e8-73b6-4c70-9d79-ae470b861292_magicIcon.svg',
    },
  ],
  [
    'EN',
    {
      discountCardBgBig:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/19/031146f2-1019-4074-b7c1-753e6ba63ff4_DiscountCardBackgroundBig.svg',
      discountFeeSmiley:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/19/7dd98b62-0860-4bdd-b821-bacf17403f0e_discountFeeSmiley.svg',
      magicIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/19/c4ced6e8-73b6-4c70-9d79-ae470b861292_magicIcon.svg',
    },
  ],
  [
    'MR',
    {
      discountCardBgBig:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/19/031146f2-1019-4074-b7c1-753e6ba63ff4_DiscountCardBackgroundBig.svg',
      discountFeeSmiley:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/19/7dd98b62-0860-4bdd-b821-bacf17403f0e_discountFeeSmiley.svg',
      magicIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/19/c4ced6e8-73b6-4c70-9d79-ae470b861292_magicIcon.svg',
    },
  ]
]);

const mySaudaAssets = new Map([
  [
    'HI',
    {
      noSaudaFound:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/18/ad8f7c15-391e-4d0a-85c7-c9d0812e44e8_NoBuyerList.svg',
    },
  ],
  [
    'EN',
    {
      noSaudaFound:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/18/ad8f7c15-391e-4d0a-85c7-c9d0812e44e8_NoBuyerList.svg',
    },
  ],
  [
    'MR',
    {
      noSaudaFound:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/18/ad8f7c15-391e-4d0a-85c7-c9d0812e44e8_NoBuyerList.svg',
    },
  ]
]);

const faqAssets = new Map([
  [
    'HI',
    {
      whatsappIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/6/e9496da0-2146-43f4-b5c7-8c91fe95252b_Whatsapp.svg',
    },
  ],
  [
    'EN',
    {
      whatsappIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/6/e9496da0-2146-43f4-b5c7-8c91fe95252b_Whatsapp.svg',
    },
  ],
  [
    'MR',
    {
      whatsappIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/6/e9496da0-2146-43f4-b5c7-8c91fe95252b_Whatsapp.svg',
    },
  ]
]);

const rakeDetailAssets = new Map([
  [
    'HI',
    {
      noRake:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/21/a665da4b-6dc1-4e9b-8ef1-abd8ae41068d_noRake.svg',
    },
  ],
  [
    'EN',
    {
      noRake:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/21/a665da4b-6dc1-4e9b-8ef1-abd8ae41068d_noRake.svg',
    },
  ],
  [
    'MR',
    {
      noRake:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/21/a665da4b-6dc1-4e9b-8ef1-abd8ae41068d_noRake.svg',
    },
  ]
]);

const uploadDocAssets = new Map([
  [
    'HI',
    {
      invoiceDoc:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/23/f0bd2610-7ca9-4121-bd5f-d8fb2d12059c_Invoice%20Doc%20Empty%20State.png',
      weightSlip:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/23/82576f8b-89df-49c8-9e61-83afb46ae3aa_Weight%20Slip%20Empty%20State.png',
    },
  ],
  [
    'EN',
    {
      invoiceDoc:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/23/f0bd2610-7ca9-4121-bd5f-d8fb2d12059c_Invoice%20Doc%20Empty%20State.png',
      weightSlip:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/23/82576f8b-89df-49c8-9e61-83afb46ae3aa_Weight%20Slip%20Empty%20State.png',
    },
  ],
  [
    'MR',
    {
      invoiceDoc:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/23/f0bd2610-7ca9-4121-bd5f-d8fb2d12059c_Invoice%20Doc%20Empty%20State.png',
      weightSlip:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/23/82576f8b-89df-49c8-9e61-83afb46ae3aa_Weight%20Slip%20Empty%20State.png',
    },
  ]
]);

const saudaDetailsAssets = new Map([
  [
    'HI',
    {
      saudaDetailsImage:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/23/0b4f1015-3fc9-4273-9e39-0e307aa12f46_saudaDetailImage.png',
      whatsappIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/6/e9496da0-2146-43f4-b5c7-8c91fe95252b_Whatsapp.svg',
      activeSvg:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/18/9a33618d-a85f-495f-a819-b183af94662a_active.svg',
      inactiveSvg:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/18/58fdbbb8-2983-40be-976a-37cf240f0265_inactive.svg',
      onTimeIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/26/1fafd3f5-dbfe-445a-b708-557343005752_onTimeIcon2.svg',
      magicIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/19/c4ced6e8-73b6-4c70-9d79-ae470b861292_magicIcon.svg',
      arrowLeftIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/2/27/062a93b3-56f7-4a41-bc2f-f8ad8d9ce98b_ArrowLeft.svg',
      arrowRightIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/2/27/0fded9ba-e4e7-4507-b505-4e264bd36449_ArrowRight.svg',
    },
  ],
  [
    'EN',
    {
      saudaDetailsImage:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/23/0b4f1015-3fc9-4273-9e39-0e307aa12f46_saudaDetailImage.png',
      whatsappIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/6/e9496da0-2146-43f4-b5c7-8c91fe95252b_Whatsapp.svg',
      activeSvg:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/18/9a33618d-a85f-495f-a819-b183af94662a_active.svg',
      inactiveSvg:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/18/58fdbbb8-2983-40be-976a-37cf240f0265_inactive.svg',
      onTimeIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/26/1fafd3f5-dbfe-445a-b708-557343005752_onTimeIcon2.svg',
      magicIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/19/c4ced6e8-73b6-4c70-9d79-ae470b861292_magicIcon.svg',
      arrowLeftIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/2/27/062a93b3-56f7-4a41-bc2f-f8ad8d9ce98b_ArrowLeft.svg',
      arrowRightIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/2/27/0fded9ba-e4e7-4507-b505-4e264bd36449_ArrowRight.svg',
    },
  ],
  [
    'MR',
    {
      saudaDetailsImage:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/23/0b4f1015-3fc9-4273-9e39-0e307aa12f46_saudaDetailImage.png',
      whatsappIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/6/e9496da0-2146-43f4-b5c7-8c91fe95252b_Whatsapp.svg',
      activeSvg:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/18/9a33618d-a85f-495f-a819-b183af94662a_active.svg',
      inactiveSvg:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/18/58fdbbb8-2983-40be-976a-37cf240f0265_inactive.svg',
      onTimeIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/26/1fafd3f5-dbfe-445a-b708-557343005752_onTimeIcon2.svg',
      magicIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/19/c4ced6e8-73b6-4c70-9d79-ae470b861292_magicIcon.svg',
      arrowLeftIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/2/27/062a93b3-56f7-4a41-bc2f-f8ad8d9ce98b_ArrowLeft.svg',
      arrowRightIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/2/27/0fded9ba-e4e7-4507-b505-4e264bd36449_ArrowRight.svg',
    },
  ]
]);

const communityAssets = new Map([
  [
    'HI',
    {
      avatarIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/26/80046d66-758a-4ef7-8bc8-677a3f13577d_avatar.svg',
      addToHomeImage:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/26/cd1fd45d-a2f0-493b-b927-994d11943478_farmartAddHome.png',
      farmartLogo:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/26/577c192d-6764-4052-ba62-e625e631d232_farmart.svg',
      companyProfileIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/26/16a9030c-4301-4802-90a1-d8517d24900e_companyProfile.svg',
      userProfileIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/26/6c86068d-349d-4fec-9fe0-aebce8823746_userProfile.svg',
      whatsappIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/10/11/9f081edb-8918-460b-a440-4795a507d311_whatsapp.png',
      clapIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/10/11/d820045e-e1b4-45fa-b584-010e5e41c7b4_clap.png',
      clapDoneIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/10/11/375d9c8c-ca16-402b-9e6e-90ccf75fc2e7_clapDone.png',
      chat: 'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/3/18/cb645fc7-d347-46fa-b7c2-05f32ca4d1e5_chat.png'
    },
  ],
  [
    'EN',
    {
      avatarIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/26/80046d66-758a-4ef7-8bc8-677a3f13577d_avatar.svg',
      addToHomeImage:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/26/cd1fd45d-a2f0-493b-b927-994d11943478_farmartAddHome.png',
      farmartLogo:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/26/577c192d-6764-4052-ba62-e625e631d232_farmart.svg',
      companyProfileIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/26/16a9030c-4301-4802-90a1-d8517d24900e_companyProfile.svg',
      userProfileIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/26/6c86068d-349d-4fec-9fe0-aebce8823746_userProfile.svg',
      whatsappIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/10/11/9f081edb-8918-460b-a440-4795a507d311_whatsapp.png',
      clapIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/10/11/d820045e-e1b4-45fa-b584-010e5e41c7b4_clap.png',
      clapDoneIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/10/11/375d9c8c-ca16-402b-9e6e-90ccf75fc2e7_clapDone.png',
      chat: 'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/3/18/cb645fc7-d347-46fa-b7c2-05f32ca4d1e5_chat.png'
    },
  ],
  [
    'MR',
    {
      avatarIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/26/80046d66-758a-4ef7-8bc8-677a3f13577d_avatar.svg',
      addToHomeImage:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/26/cd1fd45d-a2f0-493b-b927-994d11943478_farmartAddHome.png',
      farmartLogo:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/26/577c192d-6764-4052-ba62-e625e631d232_farmart.svg',
      companyProfileIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/26/16a9030c-4301-4802-90a1-d8517d24900e_companyProfile.svg',
      userProfileIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/26/6c86068d-349d-4fec-9fe0-aebce8823746_userProfile.svg',
      whatsappIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/10/11/9f081edb-8918-460b-a440-4795a507d311_whatsapp.png',
      clapIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/10/11/d820045e-e1b4-45fa-b584-010e5e41c7b4_clap.png',
      clapDoneIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/10/11/375d9c8c-ca16-402b-9e6e-90ccf75fc2e7_clapDone.png',
      chat: 'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/3/18/cb645fc7-d347-46fa-b7c2-05f32ca4d1e5_chat.png'
    },
  ]
]);
const myProfileAssets = new Map([
  [
    'HI',
    {
      languageToggleIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/10/4/151b9dff-c106-4cce-8d3c-859cab7ce9a3_languageSelection.svg',
    },
  ],
  [
    'EN',
    {
      languageToggleIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/10/4/151b9dff-c106-4cce-8d3c-859cab7ce9a3_languageSelection.svg',
    },
  ],
  [
    'MR',
    {
      languageToggleIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/10/4/151b9dff-c106-4cce-8d3c-859cab7ce9a3_languageSelection.svg',
    },
  ]
]);
const pincodeAssets = new Map([
  [
    'HI',
    {
      noLocationImage:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/10/8/35f8601b-7eac-4e3e-ba16-9a74f9331b60_noLocationFound.png',
    },
  ],
  [
    'EN',
    {
      noLocationImage:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/10/8/35f8601b-7eac-4e3e-ba16-9a74f9331b60_noLocationFound.png',
    },
  ],
  [
    'MR',
    {
      noLocationImage:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/10/8/35f8601b-7eac-4e3e-ba16-9a74f9331b60_noLocationFound.png',
    },
  ]
]);
const errorStateAssets = new Map([
  [
    'HI',
    {
      errorStateImage:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/11/29/57cc2c8e-8311-45a2-8833-41f0041d1288_ErrorImage.png',
    },
  ],
  [
    'EN',
    {
      errorStateImage:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/11/29/57cc2c8e-8311-45a2-8833-41f0041d1288_ErrorImage.png',
    },
  ],
  [
    'MR',
    {
      errorStateImage:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/11/29/57cc2c8e-8311-45a2-8833-41f0041d1288_ErrorImage.png',
    },
  ],
]);

const trackConsentAssets = new Map([
  [
    'HI',
    {
      TruckDriverIcon: "https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/12/3/8694de45-b260-4757-8ace-04defc1f9a4c_driverIcon.png",
      trackingMobile: "https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/12/3/2c4a2ffe-9695-4161-96dd-3e522158e920_trackingMobikle.png",
      jio: "https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/12/3/340ba74e-6d41-4b9d-a320-0a847fc8749f_jio.png",
      airtel: "https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/12/3/9b900358-cffb-4eab-afdc-8e6c642770d2_airtel.png",
      bsnl: "https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/12/3/33a3fdaa-7378-44cc-b862-5f309dcb331e_bsnl.png",
      vi: "https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/12/3/feb3830b-0ebb-4755-b50d-272969208deb_vi.png",
      truckIcon: "https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/12/3/99f383bd-53e4-4560-b030-b2679d80d5de_truck.png",
    }
  ],
  [
    'EN',
    {
      TruckDriverIcon: "https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/12/3/8694de45-b260-4757-8ace-04defc1f9a4c_driverIcon.png",
      trackingMobile: "https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/12/3/2c4a2ffe-9695-4161-96dd-3e522158e920_trackingMobikle.png",
      jio: "https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/12/3/340ba74e-6d41-4b9d-a320-0a847fc8749f_jio.png",
      airtel: "https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/12/3/9b900358-cffb-4eab-afdc-8e6c642770d2_airtel.png",
      bsnl: "https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/12/3/33a3fdaa-7378-44cc-b862-5f309dcb331e_bsnl.png",
      vi: "https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/12/3/feb3830b-0ebb-4755-b50d-272969208deb_vi.png",
      truckIcon: "https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/12/3/99f383bd-53e4-4560-b030-b2679d80d5de_truck.png",
    }
  ],
  [
    'MR',
    {
      TruckDriverIcon: "https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/12/3/8694de45-b260-4757-8ace-04defc1f9a4c_driverIcon.png",
      trackingMobile: "https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/12/3/2c4a2ffe-9695-4161-96dd-3e522158e920_trackingMobikle.png",
      jio: "https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/12/3/340ba74e-6d41-4b9d-a320-0a847fc8749f_jio.png",
      airtel: "https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/12/3/9b900358-cffb-4eab-afdc-8e6c642770d2_airtel.png",
      bsnl: "https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/12/3/33a3fdaa-7378-44cc-b862-5f309dcb331e_bsnl.png",
      vi: "https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/12/3/feb3830b-0ebb-4755-b50d-272969208deb_vi.png",
      truckIcon: "https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/12/3/99f383bd-53e4-4560-b030-b2679d80d5de_truck.png",
    }
  ]
])

const paymentAssets = new Map([
  [
    'HI',
    {
      farmartLogo:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/12/6/dc243f00-3d25-4fcd-891c-7ee715e70dbf_Farmart%20Logo%20(1).png',
      trustLogo: "https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/12/9/6d5082a8-410d-4641-8751-e4af941b8d58_padlock.png",
      moneyRiv: 'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/12/10/724d0cea-907f-4bf9-8e6c-55e2c1745b90_ruppee-trade.riv',
      background: "https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/12/13/693ea48f-e11a-4a4c-a6f4-1f5db01b1477_image_payment.png",
      confetti: "https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/12/10/f218d1dc-cc17-43b1-abac-36b72877f4ce_confetti.riv"
    },
  ],
  [
    'EN',
    {
      farmartLogo:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/12/6/dc243f00-3d25-4fcd-891c-7ee715e70dbf_Farmart%20Logo%20(1).png',
      trustLogo: "https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/12/9/6d5082a8-410d-4641-8751-e4af941b8d58_padlock.png",
      moneyRiv: 'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/12/10/724d0cea-907f-4bf9-8e6c-55e2c1745b90_ruppee-trade.riv',
      background: "https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/12/13/693ea48f-e11a-4a4c-a6f4-1f5db01b1477_image_payment.png",
      confetti: "https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/12/10/f218d1dc-cc17-43b1-abac-36b72877f4ce_confetti.riv"
    },
  ],
  [
    'MR',
    {
      farmartLogo:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/12/6/dc243f00-3d25-4fcd-891c-7ee715e70dbf_Farmart%20Logo%20(1).png',
      trustLogo: "https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/12/9/6d5082a8-410d-4641-8751-e4af941b8d58_padlock.png",
      moneyRiv: 'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/12/10/724d0cea-907f-4bf9-8e6c-55e2c1745b90_ruppee-trade.riv',
      background: "https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/12/13/693ea48f-e11a-4a4c-a6f4-1f5db01b1477_image_payment.png",
      confetti: "https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/12/10/f218d1dc-cc17-43b1-abac-36b72877f4ce_confetti.riv"
    },
  ],
]);

const buyerSearchAssets = new Map([
  [
    'HI',
    {
      buyerSearch:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/12/16/82f14767-884f-47ec-b5a0-7b953d3afeaa_searchBuyer2.png',
      noSearchedBuyerFound:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/12/16/c87ca1a9-8faa-413e-ba50-28cb41bcf3bd_NoBuyerFound2.png',
    },
  ],
  [
    'EN',
    {
      buyerSearch:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/12/16/82f14767-884f-47ec-b5a0-7b953d3afeaa_searchBuyer2.png',
      noSearchedBuyerFound:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/12/16/c87ca1a9-8faa-413e-ba50-28cb41bcf3bd_NoBuyerFound2.png',
    },
  ],
  [
    'MR',
    {
      buyerSearch:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/12/16/82f14767-884f-47ec-b5a0-7b953d3afeaa_searchBuyer2.png',
      noSearchedBuyerFound:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/12/16/c87ca1a9-8faa-413e-ba50-28cb41bcf3bd_NoBuyerFound2.png',
    },
  ],
]);

const bahiKhataDetails = new Map([
  [
    'HI',
    {
      cash: "https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/1/14/7ee578aa-6177-4b78-ab19-5f50f7db3296_paisa.png",
      wallet: "https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/1/15/bf53fde3-ec11-46f0-9297-ff2affc292cf_walley.png"
    },
  ],
  [
    'EN',
    {
      cash: "https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/1/14/7ee578aa-6177-4b78-ab19-5f50f7db3296_paisa.png",
      wallet: "https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/1/15/bf53fde3-ec11-46f0-9297-ff2affc292cf_walley.png"
    },
  ],
  [
    'MR',
    {
      cash: "https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/1/14/7ee578aa-6177-4b78-ab19-5f50f7db3296_paisa.png",
      wallet: "https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/1/15/bf53fde3-ec11-46f0-9297-ff2affc292cf_walley.png"
    },
  ],
]);

const bahiKhataLandingAssets = new Map([
  [
    'HI',
    {
      noTransactionImage:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/1/14/7ee578aa-6177-4b78-ab19-5f50f7db3296_paisa.png',
      bahiKhataSecureIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/1/8/3669a014-5c1b-49ab-979f-536d7da6d6e5_secure.png',
      bahiKhataFeatureImg:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/1/15/5c001d21-62d9-477d-bfc9-11b69cf2e6ea_bahi_khata.png',
      bahiKhataWalletImg:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/1/15/bf53fde3-ec11-46f0-9297-ff2affc292cf_walley.png',
      bahiKhataContactImg:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/1/14/333f4db9-197d-4ad4-968a-9ee472613f66_contactPermission.png',
    },
  ],
  [
    'EN',
    {
      noTransactionImage:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/1/14/7ee578aa-6177-4b78-ab19-5f50f7db3296_paisa.png',
      bahiKhataSecureIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/1/8/3669a014-5c1b-49ab-979f-536d7da6d6e5_secure.png',
      bahiKhataFeatureImg:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/1/15/5c001d21-62d9-477d-bfc9-11b69cf2e6ea_bahi_khata.png',
      bahiKhataWalletImg:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/1/15/bf53fde3-ec11-46f0-9297-ff2affc292cf_walley.png',
      bahiKhataContactImg:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/1/14/333f4db9-197d-4ad4-968a-9ee472613f66_contactPermission.png',
    },
  ],
  [
    'MR',
    {
      noTransactionImage:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/1/14/7ee578aa-6177-4b78-ab19-5f50f7db3296_paisa.png',
      bahiKhataSecureIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/1/8/3669a014-5c1b-49ab-979f-536d7da6d6e5_secure.png',
      bahiKhataFeatureImg:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/1/15/5c001d21-62d9-477d-bfc9-11b69cf2e6ea_bahi_khata.png',
      bahiKhataWalletImg:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/1/15/bf53fde3-ec11-46f0-9297-ff2affc292cf_walley.png',
      bahiKhataContactImg:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/1/14/333f4db9-197d-4ad4-968a-9ee472613f66_contactPermission.png',
    },
  ],
]);

const bahiKhataSearchAssets = new Map([
  [
    'HI',
    {
      noKhataFoundImage:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/1/16/5cd80758-81ec-431b-a626-52073fed5454_noKhata.png',
      bahiKhataFeatureImg:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/1/15/5c001d21-62d9-477d-bfc9-11b69cf2e6ea_bahi_khata.png',
    },
  ],
  [
    'EN',
    {
      noKhataFoundImage:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/1/16/5cd80758-81ec-431b-a626-52073fed5454_noKhata.png',

      bahiKhataFeatureImg:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/1/15/5c001d21-62d9-477d-bfc9-11b69cf2e6ea_bahi_khata.png',
    },
  ],
  [
    'MR',
    {
      noKhataFoundImage:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/1/16/5cd80758-81ec-431b-a626-52073fed5454_noKhata.png',
      bahiKhataFeatureImg:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/1/15/5c001d21-62d9-477d-bfc9-11b69cf2e6ea_bahi_khata.png',
    },
  ],
]);

const walkthroughAssets = new Map([
  [
    'HI',
    {
      languageChangeIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/2/6/4f0584f1-4465-4373-ae82-0705e4c8e4a3_languageSelectionGreen.png',
    },
  ],
  [
    'EN',
    {
      languageChangeIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/2/6/4f0584f1-4465-4373-ae82-0705e4c8e4a3_languageSelectionGreen.png',
    },
  ],
  [
    'MR',
    {
      languageChangeIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/2/6/4f0584f1-4465-4373-ae82-0705e4c8e4a3_languageSelectionGreen.png',
    },
  ],
]);

const userInterestAssets = new Map([
  [
    'HI',
    {
      languageSelection:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/17/fd0059d4-8c02-4a13-982f-e38659b01a31_LanguageSelectIcon.svg',
      farmartLogo:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/10/11/7f8a37a8-cd56-41c5-af8f-d92f35b09003_fmtLogo.png',
    },
  ],
  [
    'EN',
    {
      languageSelection:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/17/fd0059d4-8c02-4a13-982f-e38659b01a31_LanguageSelectIcon.svg',
      farmartLogo:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/10/11/7f8a37a8-cd56-41c5-af8f-d92f35b09003_fmtLogo.png',
    },
  ],
  [
    'MR',
    {
      languageSelection:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/17/fd0059d4-8c02-4a13-982f-e38659b01a31_LanguageSelectIcon.svg',
      farmartLogo:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/10/11/7f8a37a8-cd56-41c5-af8f-d92f35b09003_fmtLogo.png',
    },
  ],
]);

const shopPromotionBannerAssets = new Map([
  [
    'HI',
    {
      shopPromotionBanner: 'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/2/27/58b8f506-4458-4e7a-a7d0-1efb069bf810_ShopPromotionBannerHindi.png',
    },
  ],
  [
    'EN',
    {
      shopPromotionBanner: 'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/2/27/a732c883-73df-4067-8916-fb89c7657327_ShopPromotionBannerEnglish.png',
    },
  ],
  [
    'MR',
    {
      shopPromotionBanner: 'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/2/27/79d24acc-b73a-4063-9bc9-10f796a9cdc4_ShopPromotionBannerMarathi.png',
    },
  ],
]);

const commentInputAssets = new Map([
  [
    'HI',
    {
      sendIcon: 'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/2/27/6b2f5531-7422-4877-9d57-54ddd01c4d19_send.svg',
      avatarIcon: 'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/26/80046d66-758a-4ef7-8bc8-677a3f13577d_avatar.svg',
    }
  ],
  [
    'EN',
    {
      sendIcon: 'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/2/27/6b2f5531-7422-4877-9d57-54ddd01c4d19_send.svg',
      avatarIcon: 'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/26/80046d66-758a-4ef7-8bc8-677a3f13577d_avatar.svg',
    }
  ],
  [
    'MR',
    {
      sendIcon: 'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/2/27/6b2f5531-7422-4877-9d57-54ddd01c4d19_send.svg',
      avatarIcon: 'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/26/80046d66-758a-4ef7-8bc8-677a3f13577d_avatar.svg',
    }
  ]
]);

const myFarmerAssets = new Map([
  [
    'HI',
    {
      emptyStateIcon: 'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/2/27/296ac3af-9e00-48a7-8f9e-286b058e8fa6_MyFarmerEmptyState.svg',
    }
  ],
  [
    'EN',
    {
      emptyStateIcon: 'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/2/27/296ac3af-9e00-48a7-8f9e-286b058e8fa6_MyFarmerEmptyState.svg',
    }
  ],
  [
    'MR',
    {
      emptyStateIcon: 'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/2/27/296ac3af-9e00-48a7-8f9e-286b058e8fa6_MyFarmerEmptyState.svg',
    }
  ]
]);

const postDetailsAssets = new Map([
  [
    'HI',
    {
      downloadAppIcon: 'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/2/27/f1af0546-b41b-47e8-b203-07f976779378_downloadImage.png',
    }
  ],
  [
    'EN',
    {
      downloadAppIcon: 'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/2/27/f1af0546-b41b-47e8-b203-07f976779378_downloadImage.png',
    }
  ],
  [
    'MR',
    {
      downloadAppIcon: 'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/2/27/f1af0546-b41b-47e8-b203-07f976779378_downloadImage.png',
    }
  ]
]);

const rakeListAssets = new Map([
  [
    'HI',
    {
      currentRakeIcon: 'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/2/27/059a3882-a952-49c7-b05b-e8df66a264f6_CurrentRake.png',
    }
  ],
  [
    'EN',
    {
      currentRakeIcon: 'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/2/27/059a3882-a952-49c7-b05b-e8df66a264f6_CurrentRake.png',
    }
  ],
  [
    'MR',
    {
      currentRakeIcon: 'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/2/27/059a3882-a952-49c7-b05b-e8df66a264f6_CurrentRake.png',
    }
  ]
]);

const smallCardAssets = new Map([
  [
    'HI',
    {
      cowIcon: 'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/2/27/d80162cf-34df-4129-b680-9f81105ac65d_Cow.svg',
      callIcon: 'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/2/27/2e40cd86-65f7-4b68-a688-03b5c3968e4b_Call.svg',
    }
  ],
  [
    'EN',
    {
      cowIcon: 'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/2/27/d80162cf-34df-4129-b680-9f81105ac65d_Cow.svg',
      callIcon: 'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/2/27/2e40cd86-65f7-4b68-a688-03b5c3968e4b_Call.svg',
    }
  ],
  [
    'MR',
    {
      cowIcon: 'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/2/27/d80162cf-34df-4129-b680-9f81105ac65d_Cow.svg',
      callIcon: 'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/2/27/2e40cd86-65f7-4b68-a688-03b5c3968e4b_Call.svg',
    }
  ]
]);

const commentCardAssets = new Map([
  [
    'HI',
    {
      avatarIcon: 'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/26/80046d66-758a-4ef7-8bc8-677a3f13577d_avatar.svg',
      pinIcon: 'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/2/27/32e52bc8-8070-4d01-a6a3-3c25507a35d9_pin.svg',
      doneIcon: 'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/2/27/d595b6e5-fb04-473c-8e6b-04c89cbabc5b_done.svg',
      clapIcon: 'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/2/27/1e870435-4c94-477c-8152-a436f1e1dafd_clap.svg'
    }
  ],
  [
    'EN',
    {
      avatarIcon: 'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/26/80046d66-758a-4ef7-8bc8-677a3f13577d_avatar.svg',
      pinIcon: 'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/2/27/32e52bc8-8070-4d01-a6a3-3c25507a35d9_pin.svg',
      doneIcon: 'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/2/27/d595b6e5-fb04-473c-8e6b-04c89cbabc5b_done.svg',
      clapIcon: 'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/2/27/1e870435-4c94-477c-8152-a436f1e1dafd_clap.svg'
    }
  ],
  [
    'MR',
    {
      avatarIcon: 'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/9/26/80046d66-758a-4ef7-8bc8-677a3f13577d_avatar.svg',
      pinIcon: 'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/2/27/32e52bc8-8070-4d01-a6a3-3c25507a35d9_pin.svg',
      doneIcon: 'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/2/27/d595b6e5-fb04-473c-8e6b-04c89cbabc5b_done.svg',
      clapIcon: 'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/2/27/1e870435-4c94-477c-8152-a436f1e1dafd_clap.svg'
    }
  ]
]);


const distributorAssets = new Map([
  [
    'HI',
    {
      fertiliserIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/3/3/10fb95c9-a2e3-4607-9391-f5b1e4304482_fertiliser.png',
      seedIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/3/3/42cd186d-89b6-4e78-9008-e9a73337497b_seed.png',
      pesticideIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/3/3/4484370b-0bb7-4616-9536-6622fe51d36c_medicine.png',
      noDistributorImg:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/3/3/b4ac1e9a-ee74-4a34-bcd8-9a91718b2ba9_empty_distributor.png',
      noProductTypeModalImg:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/3/3/6b88f050-6e9b-4e61-a4a5-dddc47839ef0_empty_product.png',
    },
  ],
  [
    'EN',
    {
      fertiliserIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/3/3/10fb95c9-a2e3-4607-9391-f5b1e4304482_fertiliser.png',
      seedIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/3/3/42cd186d-89b6-4e78-9008-e9a73337497b_seed.png',
      pesticideIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/3/3/4484370b-0bb7-4616-9536-6622fe51d36c_medicine.png',
      noDistributorImg:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/3/3/b4ac1e9a-ee74-4a34-bcd8-9a91718b2ba9_empty_distributor.png',
      noProductTypeModalImg:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/3/3/6b88f050-6e9b-4e61-a4a5-dddc47839ef0_empty_product.png',
    },
  ],
  [
    'MR',
    {
      fertiliserIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/3/3/10fb95c9-a2e3-4607-9391-f5b1e4304482_fertiliser.png',
      seedIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/3/3/42cd186d-89b6-4e78-9008-e9a73337497b_seed.png',
      pesticideIcon:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/3/3/4484370b-0bb7-4616-9536-6622fe51d36c_medicine.png',
      noDistributorImg:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/3/3/b4ac1e9a-ee74-4a34-bcd8-9a91718b2ba9_empty_distributor.png',
      noProductTypeModalImg:
        'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2025/3/3/6b88f050-6e9b-4e61-a4a5-dddc47839ef0_empty_product.png',
    },
  ],
]);

export {
  pincodeAssets,
  myProfileAssets,
  communityAssets,
  saudaDetailsAssets,
  uploadDocAssets,
  rakeDetailAssets,
  faqAssets,
  mySaudaAssets,
  buyerDetailAssets,
  tradeScreenAssets,
  loginScreenAssets,
  homeScreenAssets,
  defaultLanguageCode,
  errorStateAssets,
  paymentAssets,
  buyerSearchAssets,
  trackConsentAssets,
  bahiKhataDetails,
  bahiKhataLandingAssets,
  bahiKhataSearchAssets,
  walkthroughAssets,
  userInterestAssets,
  shopPromotionBannerAssets,
  commentInputAssets,
  myFarmerAssets,
  postDetailsAssets,
  rakeListAssets,
  smallCardAssets,
  commentCardAssets,
  distributorAssets,
};
