// Importing language translation function
import getLang from "@mono-farmart-web/farmart-web-common/languages/SmsHistoryDetail.js";
import { useNavigate, useParams, useLocation } from "react-router-dom"; // React Router hooks for navigation and URL parameters
import type { SmsHisoryProps, farmerCardData } from "./types"; // TypeScript types for SMS History properties
import { useEffect, useState } from "react"; // React hooks for state and lifecycle management
import useAuthenticationChecker from "@mono-farmart-web/farmart-web-common/utils/authCheckerV2/index"; // Custom hook for authentication
import { apiEndPoints } from "@mono-farmart-web/farmart-web-common/apis/endpoints"; // API endpoints constants
import { trackEvent } from "@mono-farmart-web/farmart-web-common/utils/moengage/index"; // Tracking functions for analytics
import Events from "@mono-farmart-web/farmart-web-common/constant/MoengageEvents/index"; // Tracking events constants
import { languageUrls } from "@mono-farmart-web/farmart-web-common/constant/languageUrls/index";
import useAppLanguage from "@mono-farmart-web/farmart-web-common/utils/applanguage";

export default function AddFarmerHook(): SmsHisoryProps {
	const { smsId } = useParams(); // Get SMS ID from URL params
	const { state } = useLocation(); // Get location state

	// Create a bridge for communication with native code
	const navigate = useNavigate(); // Navigation hook
	const [initialLoading, setInitialLoading] = useState(true); // State for initial loading
	const [smsHistoryData, setSmsHistroyData] = useState<
		| {
				message: string;
				farmers: farmerCardData[];
				sms_type: number;
				status: number;
				comment: string;
		  }
		| undefined
	>(undefined); // State for SMS history data

	const [showShopPromotionModal, setShowShopPromotionModal] = useState(false); // State for shop promotion modal visibility

	const { language } = useAppLanguage(
		languageUrls?.smsHistoryDetailScreenLangageUrl,
		getLang,
	);
	// Get the authentication checker and API caller functions
	const { AuthenticationCheckerApiCaller } = useAuthenticationChecker();

	// Function to handle back button press
	function onPressBack() {
		navigate(-1); // Navigate back
	}

	/**
	 * The function `onContactUs` navigates to the "/faq/questions" page.
	 */
	function onContactUs() {
		navigate("/faq/questions"); // Navigate to FAQ page
	}

	useEffect(() => {
		getSmsHistoryDetail(); // Fetch SMS history detail on component mount
	}, []);

	/**
	 * Fetches the SMS history detail from the API.
	 */
	async function getSmsHistoryDetail() {
		setInitialLoading(true); // Set initial loading state to true

		try {
			const fetchSmsPassbookParams = {
				sms_sent_by_merchant_id: smsId, // SMS ID parameter
			};
			const smsHistoryResp = await AuthenticationCheckerApiCaller({
				payload: fetchSmsPassbookParams, // Request payload
				uri: apiEndPoints.smsHistoryDetail, // API endpoint for SMS history detail
				requestType: "post", // HTTP POST request
			});

			if (smsHistoryResp?.data?.status) {
				setSmsHistroyData(smsHistoryResp.data.data); // Set SMS history data
			}
		} catch (error) {
			console.log("Error in fetching My Farmers", error); // Log error
		} finally {
			setInitialLoading(false); // Set initial loading state to false
		}
	}

	/**
	 * Handles the click event for the info button.
	 */
	function onClickInfo() {
		setShowShopPromotionModal(true); // Show shop promotion modal
		trackEvent(Events?.onHomeScreenSmsFeatureIButtonClick, {
			from: "Shop_Promtion_Screen",
		}); // Track event
	}

	/**
	 * Handles the close event for the modal.
	 */
	function onCloseModal() {
		setShowShopPromotionModal(false); // Hide shop promotion modal
		trackEvent(Events?.onHomeScreenSmsFeatureIButtonModalClosedButtonClick, {
			from: "Shop_Promtion_Screen",
		}); // Track event
	}

	return {
		onPressBack,
		language,
		onContactUs,
		smsHistoryData,
		onClickInfo,
		initialLoading,
		smsHistoryDetailData: state?.smsHistoryDetailData, // SMS history detail data from state
		showShopPromotionModal,
		onCloseModal,
	};
}
