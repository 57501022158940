import getConfigByKeys from "../../config/config";
import { configKeys } from "../../config/types";
import handleHttpError from "../api/httpErrorMapping";
import HttpStatusCode from "../../apis/types/httpStatusCodesEnums.type";
import type UnifiedResponse from "../../apis/types/unifiedResponse.type";
import { getHeaders } from "./fetchHeaders";

const { API_URL } = getConfigByKeys([configKeys.API_URL]);

interface deleteRequestQueryParameter {
  uri?: string;
  payload?: any;
  customUri?: string;
  signal?: AbortSignal; // Add signal parameter
}

export default async function DeleteRequest(
	props: deleteRequestQueryParameter,
): Promise<UnifiedResponse> {
	const { uri, payload, customUri, signal } = props;
  const headers = getHeaders();

  try {
    const response = await fetch(`${API_URL}${customUri ? customUri : uri}`, {
      method: "DELETE",
      headers: headers,
      body: JSON.stringify(payload),
      signal
    });

    if (response.status !== HttpStatusCode.Ok) {
      throw handleHttpError(response.status);
    }

    const data = await response.json();
    return { data, response };
  } catch(error) {
    console.log("Error::: (while deleting the resource)", error);
  }
}
