import AddFarmerView from '../../Pages/AddFarmer/AddFarmerView';
import AddFarmerHook from '../../hooks/AddFarmer/addFarmerHook';

export default function AddFarmerScreen() {
  const {
    onPressBack,
    language,
    onContactUs,
    contactList,
    contactLoading,
    contactPermission,
    currentPlatform,
    handleCheckboxChange,
    onSearch,
    error,
    farmerDetails,
    isModalVisible,
    onChangeInput,
    onToggleManualModal,
    saveFarmerManual,
    contactsIndex,
    savingLoading,
    saveFarmer,
    selectedCount,
    onClickDownloadApp,
    onClickOpenSetting,
    isErrorPopup,
    sortedContacts,
    pageLoading,
    currentPage,
    handleSetCurrentPage,
  } = AddFarmerHook();
  return (
    <AddFarmerView
      onPressBack={onPressBack}
      language={language}
      onContactUs={onContactUs}
      contactList={contactList}
      contactLoading={contactLoading}
      contactPermission={contactPermission}
      currentPlatform={currentPlatform}
      handleCheckboxChange={handleCheckboxChange}
      onSearch={onSearch}
      error={error}
      farmerDetails={farmerDetails}
      isModalVisible={isModalVisible}
      onChangeInput={onChangeInput}
      onToggleManualModal={onToggleManualModal}
      saveFarmerManual={saveFarmerManual}
      contactsIndex={contactsIndex}
      saveFarmer={saveFarmer}
      savingLoading={savingLoading}
      selectedCount={selectedCount}
      onClickDownloadApp={onClickDownloadApp}
      onClickOpenSetting={onClickOpenSetting}
      isErrorPopup={isErrorPopup}
      sortedContacts={sortedContacts}
      pageLoading={pageLoading}
      currentPage={currentPage}
      handleSetCurrentPage={handleSetCurrentPage}
    />
  );
}
