export default function formatDateTime(dateString: string): string {
  const date = new Date(dateString);

  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'long' });
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours % 12 || 12; // Convert 24-hour to 12-hour format
  const formattedMinutes = minutes < 10 ? '0' + minutes : minutes; // Add leading zero if necessary

  return `${day} ${month} ${year}, ${formattedHours}:${formattedMinutes} ${ampm}`;
}
