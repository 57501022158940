import React from 'react';
import replaceVariableInString from '@mono-farmart-web/farmart-web-common/utils/replacevariableInLanguage';
import Style from './style.module.css';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';
import { qtlToTon } from '@mono-farmart-web/farmart-web-common/utils/conversions';

interface AcknowldegeDiscountCardProps {
  quantity: number; // Quantity of the crop
  netPrice: number; // Net price of the crop
  paymentTerms: string; // Payment terms for the transaction
  priceBreakup: any; // Detailed price breakup information
  language: { [key: string]: any }; // Language settings for localization
}

export default function DiscountCard({
  quantity,
  netPrice,
  paymentTerms,
  priceBreakup,
  language,
}: AcknowldegeDiscountCardProps) {

  if (paymentTerms !== 'grns-term') return null;

  if (!priceBreakup?.price_breakup_grn?.discounted_fee) return null;

  const netDiscount = qtlToTon(priceBreakup?.price_breakup_grn?.net_discount) * Number(quantity);

  return <div className={Style.discountCardContainer}>
    <div className={Style.saudaDetailContainer}>
      <div className={Style.saudaDetailIcon}>
        <DescriptionRoundedIcon />
      </div>

      <div className={Style.saudaDetailContent}>
        <label className={Style.saudavalueText}>
          {replaceVariableInString(
            (qtlToTon(quantity) * netPrice).toFixed(2).toString(),
            language?.totalSaudaValue,
          )}
        </label>
        <div className={Style.discountChip}>
          <label className={Style.discountChipText}>
            {replaceVariableInString(
              netDiscount.toFixed(2),
              language?.amountSaved,
            )}
          </label>
        </div>
      </div>
    </div>
    <div className={Style.infoContainer}>
      <div className={Style.saudaDetailInfoIcon}>
        <InfoOutlined fontSize="small" sx={{ color: '#535F77' }} />
      </div>
      <div>
        <label className={Style.saudaPriceText}>
          {language?.saudaPriceAndQuantity}
        </label>
      </div>
    </div>
  </div>
}