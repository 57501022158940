import { Box, Drawer } from '@mui/joy';
import useBottomDrawer from '../../../hooks/BottomDrawer/useBottomDrawer';
import MuiTypography from '@mono-farmart-web/farmart-web-common/ComponentV3/Typography';
import MuiButton from '@mono-farmart-web/farmart-web-common/ComponentV3/MuiButton';;

interface BahiKhataGuideProps {
  language: { [key: string]: string };
  onClickCloseGuide: () => void;
  userGuideVisible: boolean;
  bahiKhataLandingAssets: { [key: string]: string };
}

export default function DeleteKhataModal(props: BahiKhataGuideProps) {
  const { drawerProps } = useBottomDrawer();

  const {
    language,
    onClickCloseGuide,
    userGuideVisible,
    bahiKhataLandingAssets,
  } = props;

  return (
    <Drawer
      {...drawerProps}
      slotProps={{
        backdrop: {
          sx: {
            backdropFilter: 'blur(0.1px)', // Optional blur effect
          },
        },
        content: {
          sx: {
            display: 'inline-flex',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius:
              'var(--radius-xl, 16px) var(--radius-xl, 16px) 0px 0px',
            background: 'var(--background-level1, #F0F4F8)',
            height: 'auto',
            boxSizing: 'border-box',
            '@media (min-width: 1025px) and (max-width: 1280px)': {
              width: '30vw',
              margin: '0vw 35vw',
            },
            '@media (min-width: 1281px)': {
              width: '30vw',
              margin: '0vw 35vw',
            },
          },
        },
      }}
      open={userGuideVisible}
      onClose={onClickCloseGuide}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        boxSizing="border-box"
        bgcolor="background.body"
        paddingBottom={'16px'}
        width={'100%'}
      >
        <Box
          sx={{
            display: 'flex',
            padding: '24px 24px 20px 24px',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            boxSizing: 'border-box',
          }}
          borderColor={'black'}
        >
          <Box
            height={'170px'}
            display={'flex'}
            flex={1}
            bgcolor={'warning.300'}
            borderRadius={12}
            alignItems={'center'}
            flexDirection={'column'}
          >
            <Box
              display={'flex'}
              sx={{ backgroundColor: '#FFF3E4' }}
              height={'82px'}
              width={'82px'}
              marginTop={'24px'}
              borderRadius={10}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <img
                src={bahiKhataLandingAssets?.bahiKhataFeatureImg}
                alt="bahiKhataGuideIcon"
                style={{ width: '65px', height: '65px' }}
              />
            </Box>
            <MuiTypography
              marginTop={'12px'}
              level="h3"
              fontFamily={'NotoSans'}
              textColor={'warning.solidHoverBg'}
            >
              {language?.heading}
            </MuiTypography>
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          boxSizing="border-box"
          width="100%"
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              boxSizing: 'border-box',
              padding: '0px 24px 28px 24px',
            }}
          >
            <MuiTypography
              textColor={'text.primary'}
              level="title-md"
              textAlign={'center'}
            >
              {language?.guideModalDescription}
            </MuiTypography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              width: '100%',
              boxSizing: 'border-box',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                padding: 'var(--none, 0px) var(--4, 16px)',
                flexDirection: 'column',
                alignItems: 'flex-start',
                width: '100%',
                boxSizing: 'border-box',
              }}
              id="bahikhata_info_okay"
              data-testid="bahikhata_info_okay"
              content-desc="bahikhata_info_okay"
            >
              <MuiButton
                color="primary"
                variant="solid"
                size="lg"
                onClick={onClickCloseGuide}
                id="bahikhata_info_okay"
                data-testid="bahikhata_info_okay"
              >
                {language?.guideModalButton}
              </MuiButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
}
