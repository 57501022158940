import { Box, Drawer } from '@mui/joy';
import useBottomDrawer from '../../../hooks/BottomDrawer/useBottomDrawer';
import MuiTypography from '@mono-farmart-web/farmart-web-common/ComponentV3/Typography';
import { CloseRounded } from '@mui/icons-material';
import MuiButton from '@mono-farmart-web/farmart-web-common/ComponentV3/MuiButton';
import stringSubstitution from '@mono-farmart-web/farmart-web-common/utils/stringSubstitution';

export default function DeleteKhataModal({
  onClickDeleteKhata,
  deleteKhataModalOpenClose,
  deleteKhata,
  profileName,
  language,
  disableDeleteKhataButton
}) {
  const { drawerProps } = useBottomDrawer();
  return (
    <Drawer
      {...drawerProps}
      slotProps={{
        backdrop: {
          sx: {
            backdropFilter: 'blur(0.1px)', // Optional blur effect
          },
        },
        content: {
          sx: {
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius:
              'var(--radius-xl, 16px) var(--radius-xl, 16px) 0px 0px',
            background: 'var(--background-level1, #F0F4F8)',
            height: 'auto',
            boxSizing: 'border-box',
            '@media (min-width: 1025px) and (max-width: 1280px)': {
              width: '30vw',
              margin: '0vw 35vw',
            },
            '@media (min-width: 1281px)': {
              width: '30vw',
              margin: '0vw 35vw',
            },
          },
        },
      }}
      open={deleteKhataModalOpenClose}
      onClose={onClickDeleteKhata}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          height: '100%',
          width: '100%',
          boxSizing: 'border-box',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            padding: 'var(--4, 16px)',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom:
              '1px solid var(--neutral-outlined-DisabledBorder, #DDE7EE)',
            background: 'var(--background-body, #FFF)',
            width: '100%',
            boxSizing: 'border-box',
          }}
        >
          <MuiTypography level="title-md" sx={{ color: '#171A1C' }}>
            {stringSubstitution(language?.deleteKhataTitle, [profileName])}
          </MuiTypography>
          <CloseRounded
            onClick={onClickDeleteKhata}
            sx={{ height: '24px', width: '24px', color: '#636B74' }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: 'var(--none, 0px)',
            boxSizing: 'border-box',
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              padding:
                'var(--6, 24px) var(--4, 16px) var(--4, 16px) var(--4, 16px)',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'var(--background-body, #FFF)',
              width: '100%',
              boxSizing: 'border-box',
            }}
          >
            <MuiTypography sx={{ color: '#555E68' }} level="title-sm">
              {stringSubstitution(language?.deleteKhataMessage, [profileName])}
            </MuiTypography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              padding: 'var(--4, 16px) 0px',
              flexDirection: 'column',
              alignItems: 'flex-start',
              background: 'var(--background-body, #FFF)',
              width: '100%',
              boxSizing: 'border-box',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                padding: 'var(--none, 0px) var(--4, 16px)',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: 'var(--3, 12px)',
                width: '100%',
                boxSizing: 'border-box',
              }}
            >
              <MuiButton
                color="danger"
                variant="solid"
                size="md"
                disabled={disableDeleteKhataButton}
                onClick={deleteKhata}
              >
                {stringSubstitution(language?.deleteKhataTitle, [profileName])}
              </MuiButton>
              <MuiButton
                onClick={onClickDeleteKhata}
                color="neutral"
                variant="soft"
                size="md"
              >
                {language?.no}
              </MuiButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
}
