import {
	Column,
	Row,
} from "@mono-farmart-web/farmart-web-common/MuiComponents/atoms/layout";
import { Text } from "@mono-farmart-web/farmart-web-common/MuiComponents/atoms/typography";
import ButtonC from "@mono-farmart-web/farmart-web-common/MuiComponents/atoms/button/Button";

const getCreditImageOptionJSX = (
	handleOpenService: (event: any, type: string) => void,
	clearCreditDoc: (close: () => void) => void,
	handleReuploadState: (flag?: boolean) => void,
	close: () => void,
	isImageReUpload: boolean,
	language: any,
  fileInputRef: any
) => {
	if (isImageReUpload === true) {
		return (
			<Column>
				{/* HEADER */}
				<Row
					paddingTop={6}
					paddingRight={4}
					paddingBottom={4}
					paddingLeft={4}
					alignItems={"center"}
					justifyContent={"center"}
					bgcolor={"background.body"}
				>
					<Text level="title-sm" fontWeight={600} textColor={"text.secondary"}>
						{language?.attachOnePicture}
					</Text>
				</Row>

				{/* BODY */}
				<Column
					padding={4}
					gap={3}
					bgcolor={"background.body"}
					boxSizing={"border-box"}
				>
					<ButtonC
						color="primary"
            id="bahikhata_imageReUploadbutton"
            data-testid="bahikhata_imageReUploadbutton"
						content-desc="bahikhata_imageReUploadbutton"
						onClick={() => fileInputRef?.current?.click()}
						sx={{
							fontWeight: "600",
							fontSize: "14px",
							borderRadius: "8px",
						}}
					>
						<input
							ref={fileInputRef}
							type="file"
							className="photo-input"
							accept="image/*"
							onChange={(event) => {
								handleOpenService(event, "camera");

								// RESETS THE REUPLOAD STATE AND CLOSES THE BOTTOM DRAWER;
								handleReuploadState();
								close();
							}}
						/>
						{language?.replaceOldImageButton}
					</ButtonC>

					<ButtonC
            id="bahikhata_imageReUploadNobutton"
            data-testid="bahikhata_imageReUploadNobutton"
						content-desc="bahikhata_imageReUploadNobutton"
						onClick={() => {
							// RESETS THE REUPLOAD STATE AND CLOSES THE BOTTOM DRAWER;
							handleReuploadState();
							close();
						}}
						sx={{
							fontWeight: "600",
							fontSize: "14px",
							background: "#F0F4F8",
							borderRadius: "8px",
							color: "#32383E",
						}}
					>
						{language?.noButton}
					</ButtonC>
				</Column>
			</Column>
		);
	}

	return (
		<Column
			padding={4}
			gap={3}
			bgcolor={"background.body"}
			boxSizing={"border-box"}
		>
			<ButtonC
        id="bahikhata_imageDeleteConfirmbutton"
        data-testid="bahikhata_imageDeleteConfirmbutton"
				content-desc="bahikhata_imageDeleteConfirmbutton"
				color="danger"
				onClick={() => clearCreditDoc(close)}
				sx={{
					fontWeight: "600",
					fontSize: "14px",
					borderRadius: "8px",
				}}
			>
				{language?.deleteImageButton}
			</ButtonC>

			<ButtonC
        id="bahikhata_imageDeleteNobutton"
        data-testid="bahikhata_imageDeleteNobutton"
				content-desc="bahikhata_imageDeleteNobutton"
				onClick={() => close()}
				sx={{
					fontWeight: "600",
					fontSize: "14px",
					background: "#F0F4F8",
					borderRadius: "8px",
					color: "#32383E",
				}}
			>
				{language?.noButton}
			</ButtonC>
		</Column>
	);
};

export default getCreditImageOptionJSX;
