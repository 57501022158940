import { useState } from "react";
import Styles from "./style.module.css";
import { Box, Column, Row } from "@mono-farmart-web/farmart-web-common/MuiComponents/atoms/layout";
import Header from "@mono-farmart-web/farmart-web-common/MuiComponents/pages/common/Header";
import { ArrowBackRounded } from "@mui/icons-material";
import ButtonC from "@mono-farmart-web/farmart-web-common/MuiComponents/atoms/button/Button";
import { useNavigate } from "react-router-dom";
import FormField from "@mono-farmart-web/farmart-web-common/MuiComponents/pages/common/FormField";
import Toast from '@mono-farmart-web/farmart-web-common/ComponentV2/Toast';

interface AddNewKhataProps {
  userDetails: any;
  handleNameChange: (event: any) => void;
  handleContactChange: (event: any) => void;
  handleCreateNewTransaction: () => void;
  isSavingDetails: boolean;
  isErrorToast: boolean;
  language: any
}

const isValidIndianMobile = (number: string): boolean => {
  return number && ['6', '7', '8', '9'].includes(number[0]) && number?.length === 10;
};

const AddNewKhataView = (props: AddNewKhataProps) => {
  const navigate = useNavigate();
  const {
    userDetails: {
      username: name,
      contactNumber: contact
    },
    handleNameChange: handleName,
    handleContactChange: handleContact,
    handleCreateNewTransaction,
    isSavingDetails,
    isErrorToast,
    language
  } = props;

  const isSubmitDisabled = !name?.trim()?.length || !isValidIndianMobile(contact);

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      height={"100dvh"}
      className={Styles.container}>
      {/* HEADER */}

      <Toast
        isError={true}
        message={"Farmer has already been added."}
        isOpen={isErrorToast}
      />

      <Header
        leftIcon={
          <ArrowBackRounded
            content-dec="bahikhata_backButton"
            sx={{
              width: "24px",
              height: "24px"
            }}
        onClick={() => navigate(-1)} />
          }
        title={language?.title} />

      <Column
        flex={1}
        justifyContent={"space-between"}>
        <Column
          py={5}
          px={4}
          boxSizing={"border-box"}
          gap={6}
          height={"100%"}>

          <FormField
            title={language?.inputNameHeading}
            placeholder={language?.inputNameHeadingPlaceholder}
            fieldType={"text"}
            name={"username"}
            id="bahikhata_nameInput"
            value={name}
            handleInput={handleName}
            disabled={isSavingDetails} />

          <FormField
            title={language?.inputContactHeading}
            placeholder={language?.inputContactHeadingPlaceholder}
            fieldType={"number"}
            name={"phone"}
            id="bahikhata_numberInput"
            value={contact}
            handleInput={handleContact}
            disabled={isSavingDetails} />
        </Column>

        <Column
          padding={4}
          boxSizing={"border-box"}>
          <ButtonC
            id="bahikhata_continue"
            data-testid="bahikhata_continue"
            content-desc="bahikhata_continue"
            disabled={isSubmitDisabled}
            onClick={handleCreateNewTransaction}
            loading={isSavingDetails}>
              {language?.continueButton}
          </ButtonC>
        </Column>
      </Column>
    </Box>
  )
}

export default AddNewKhataView
