import { Box, Button, Input, FormControl, FormLabel } from "@mui/joy";
import Modal from "@mono-farmart-web/farmart-web-common/ComponentV2/Modal";
import type {
	ProgressiveInputProps,
	MerchantData,
} from "../../../hooks/SMSLandingScreen/types";

interface ProgressiveSignUpProps {
	onChangeInput: (value: string, type: string) => void;
	onCompleteUserInfo: () => void;
	onCloseProgressiveModal: () => void;
	progressiveModalVisible: boolean;
	merchantData?: MerchantData;
	merchantDetail: ProgressiveInputProps;
	language: { [key: string]: string };
}

export default function ProgressiveModal(props: ProgressiveSignUpProps) {
	const {
		merchantDetail,
		onChangeInput,
		onCloseProgressiveModal,
		onCompleteUserInfo,
		progressiveModalVisible,
		merchantData,
		language,
	} = props;

	const modalContent = (
		<Box padding={4}>
			<Box>
				{merchantData?.merchant_occupation_id !== 5 && (
					<Box marginBottom={"20px"}>
						<FormControl>
							<FormLabel>
								{merchantData?.merchant_occupation_id == 1
									? language?.shopNameInputTitle
									: language?.businessNameInputTitle}
							</FormLabel>
							<Input
								placeholder={
									merchantData?.merchant_occupation_id == 1
										? language?.shopNameInputPlaceholder
										: language?.businessNameInputPlaceholder
								}
								value={merchantDetail?.merchantShopName}
								onChange={(event) =>
									onChangeInput(event?.target?.value, "merchantShopName")
								}
								sx={{
									input: {
										"&:focus": {
											border: "none",
										},
										//placeholder text style
										"&::placeholder": {
											opacity: 0.64,
											color: "#9FA6AD",
										},
									},
									width: "100%",
									borderRadius: 8,
								}}
								id={"progressiveSignupShopNameId"}
								data-testid={"progressiveSignupShopNameTestId"}
							/>
						</FormControl>
					</Box>
				)}

				<FormControl>
					<FormLabel>{language?.nameInputTitle}</FormLabel>
					<Input
						placeholder={language?.nameInputTitlePlaceholder}
						value={merchantDetail?.merchantName}
						onChange={(event) =>
							onChangeInput(event?.target?.value, "merchantName")
						}
						sx={{
							input: {
								"&:focus": {
									border: "none",
								},
								//placeholder text style
								"&::placeholder": {
									opacity: 0.64,
									color: "#9FA6AD",
								},
							},
							width: "100%",
							borderRadius: 8,
						}}
						id={"progressiveSignUpNameInputId"}
						data-testid={"progressiveSignUpNameInputId"}
						variant="outlined"
					/>
				</FormControl>
			</Box>
			<Box paddingTop={4} marginTop={"24px"}>
				<Button
					fullWidth
					size={"lg"}
					variant="solid"
					sx={{
						borderRadius: "6px",
					}}
					onClick={onCompleteUserInfo}
					id="progressiveModalButtonId"
					data-testid="progressiveModalButtonTestId"
					disabled={
						merchantDetail?.merchantName.length === 0 ||
						(merchantData?.merchant_occupation_id !== 5 &&
							merchantDetail?.merchantShopName.length === 0)
					}
				>
					{language?.progressiveModalProceed}
				</Button>
			</Box>
		</Box>
	);
	return (
		<Modal
			isOpen={progressiveModalVisible}
			// eslint-disable-next-line react/no-children-prop
			children={modalContent}
			title={language?.progressiveSignUpModalHeading}
			id={"rateModalSMSTemplateId"}
			isCloseButtonOption={true}
			onClose={onCloseProgressiveModal}
		/>
	);
}
