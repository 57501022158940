import { useState, useEffect } from 'react';
import type { WalkthroughProps, LanguageParams, Slide } from './types.js'; // Importing necessary types
import getLang from '@mono-farmart-web/farmart-web-common/languages/walkthrough.js'; // Importing language translation function
import { useNavigate, useLocation } from 'react-router-dom'; // Importing hooks for navigation
import { languageUrls } from '@mono-farmart-web/farmart-web-common/constant/languageUrls/index';
import { useLanguage } from '@mono-farmart-web/farmart-web-common/context/language';
import useAppLanguage from '@mono-farmart-web/farmart-web-common/utils/applanguage/index.js';
import { getWalkthroughAssets } from '@mono-farmart-web/farmart-web-common/constant/imageUrls';
import { apiEndPoints } from '@mono-farmart-web/farmart-web-common/apis/endpoints/index.js';
import guideIdEnums from '@mono-farmart-web/farmart-web-common/constant/enums/guideIdEnums';
import { trackEvent } from '@mono-farmart-web/farmart-web-common/utils/moengage/index.js';
import Events from '@mono-farmart-web/farmart-web-common/constant/MoengageEvents/index.js'; // Importing constant events
import GetRequest from '@mono-farmart-web/farmart-web-common/utils/apiCaller/getRequest.js';
import PostRequest from '@mono-farmart-web/farmart-web-common/utils/apiCaller/postRequest.js';
import PlatForms from '@mono-farmart-web/farmart-web-common/constant/platFormEnums.js';
import { usePlatfromData } from '@mono-farmart-web/farmart-web-common/context/CrossPlatformActionHandler/platformChecker.js';
import bridgeFunctions from '@mono-farmart-web/rpc-bridge/utility.js';
import { createBridge } from '@mono-farmart-web/rpc-bridge';

export default function useWalkthroughHooks(): WalkthroughProps {
  const bridge = createBridge();
  const { state } = useLocation();
  const { languageData } = useLanguage();
  const navigate = useNavigate(); // Navigation function

  const [slidedData, setSlidedData] = useState<Slide[]>([]); // State to store current slide index
  const { language } = useAppLanguage(languageUrls?.walkthroughScreen, getLang);
  const platformData = usePlatfromData(); // Getting platform data
  const activeSystemLang = Intl.DateTimeFormat().resolvedOptions().locale;
  const systemLanguage = activeSystemLang?.includes('en')
    ? languageData && languageData[1]?.langCode
    : languageData && languageData[0]?.langCode;
  const systemLanguageId = activeSystemLang?.includes('en')
    ? languageData && languageData[1]?.backendLanguageCode
    : languageData && languageData[0]?.backendLanguageCode;
  const token = localStorage.getItem('authToken');
  const langId = JSON.parse(localStorage.getItem('userLanguage')); // Getting user's selected language

  const walkthroughAssets = getWalkthroughAssets(langId?.langCode || 'HI');

  const [loading, setLoading] = useState<boolean>(false); // State to track loading status
  const [initialScreenLoading, setInitialScreenLoading] = useState<boolean>(true);

  // Storing current language or defaulting to Hindi
  const currentLanguage = langId || {
    langCode: systemLanguage,
    langId: systemLanguageId,
  };
  const [isLanguageModalOpen, toggleLanguageModal] = useState<boolean>(false); // State to toggle language modal
  const [isLanguageSelectedInProgress, setLanguageSelectedInProgeress] =
    useState<boolean>(false); // State to track if language selection is in progress

  const [selectedLanguage, setSelectedLanguage] = useState(currentLanguage); // State to store selected language

  // Function to handle language selection in language modal
  function handleLanguageSelect(languageItem: LanguageParams) {
    trackEvent(Events?.onChangeLanguageModalLanguageButtonClick, {
      Language_Selected: languageItem.langCode,
    });
    setSelectedLanguage(languageItem);
  }

  // Function to handle language change
  function handleLanguageChange() {
    trackEvent(Events?.onChangeLanguageModalSubmitButtonClick, {});
    if (localStorage.getItem('userLanguage') !== selectedLanguage) {
      setLanguageSelectedInProgeress(!isLanguageSelectedInProgress);
      localStorage.setItem('userLanguage', JSON.stringify(selectedLanguage));
      toggleLanguageModal(!isLanguageModalOpen);
      setLanguageSelectedInProgeress(false);
      getAppBanner();
    } else {
      toggleLanguageModal(!isLanguageModalOpen);
    }
  }

  // Function to toggle language modal
  function onLanguageModalToggle() {
    setSelectedLanguage(
      langId || {
        langCode: systemLanguage,
        langId: systemLanguageId,
      },
    );
    toggleLanguageModal(!isLanguageModalOpen);
  }

  // Function to handle back button press
  function onPressBack() {
    navigate(-1);
  }

  /* The `useEffect` hook you provided is responsible for setting the selected language based on the
user's preference stored in the local storage. It runs whenever the value returned by
`localStorage.getItem('userLanguage')` changes. */
  useEffect(() => {
    setSelectedLanguage(
      langId || {
        langCode: systemLanguage,
        langId: systemLanguageId,
      },
    );
  }, [localStorage.getItem('userLanguage')]);

  /* The `useEffect(() => { getAppBanner(); }, []);` hook is used to call the `getAppBanner()` function
when the component is mounted for the first time. */
  useEffect(() => {
    getAppBanner();
  }, []);

  /**
   * The function `logUserPurpose` sends a POST request to a specific API endpoint with a payload
   * containing guide information.
   */
  async function logUserPupose() {
    try {
      const payload = {
        guide_id: guideIdEnums.get('appGuide'),
        is_completed: true,
      };
      await PostRequest({
        payload: payload,
        uri: apiEndPoints?.userGuide,
      });
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * The function `onClickGotIt` tracks events based on user interaction and navigates to different
   * screens depending on the user's interest.
   */
  function onClickGotIt(from: string) {
    if (from === 'skip') {
      trackEvent(Events.onWalkthroughScreenSkipButtonClick, {});
    } else {
      trackEvent(Events.onWalkthroughScreenGotItButtonClick, {});
    }
    logUserPupose();
    if (state?.showUserInterest === true) {
      navigate('/userInterest');
    } else {
      
      if (platformData?.platform === PlatForms?.get('application')) {
        if (state?.merchantData) {  
        bridge.sendRequestToNative(
          'SetToken',
          {
            token: token,
            profile: state?.merchantData,
          },
          (response) => {
            bridgeFunctions.PrintLog(response);
          },
        );
        bridge.sendRequestToNative(
          'setAppLanguage',
          {
            langCode: selectedLanguage?.langCode,
          },
          (response) => {
            bridgeFunctions.PrintLog(response);
          },
        );
      } 
      else{
        navigate('/',{
          replace:true
        });
      }
    }
      else {
        if (state?.merchantData) {
          localStorage.setItem('profileSelected', state?.merchantData?.merchant_occupation_id || null);
          localStorage.setItem('loged', state?.merchantData?.merchant_occupation_id ?'true':'false'); 
        }
        navigate('/',{
          replace:true
        });
      }
    }
  }

  /**
   * The `fetchBanners` function makes an asynchronous API request to retrieve banner data and returns
   * the result.
   * @returns The `fetchBanners` function is returning the `bannerDataFromApi` fetched from the API
   * endpoint `apiEndPoints.walkthroughBanner`.
   */
  async function fetchBanners() {
    try {
      const bannerDataFromApi = await GetRequest({
        payload: {},
        uri: apiEndPoints.walkthroughBanner,
      });
      return bannerDataFromApi;
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * The function `getAppBanner` fetches banner data and sets it to be displayed in a slider if the data
   * status is valid.
   */
  async function getAppBanner() {
    try {
      const bannerResp = await fetchBanners();
      if (bannerResp?.data?.status) {
        console.log(bannerResp.data);
        setSlidedData(bannerResp.data.data);
      }
    } catch (error) {
      
    } finally {
      setInitialScreenLoading(false);  // Set loading to false after API completes
    }
  }

  return {
    handleLanguageSelect,
    onLanguageModalToggle,
    handleLanguageChange,
    selectedLanguage,
    isLanguageModalOpen,
    isLanguageSelectedInProgress,
    onPressBack,
    language,
    languageData,
    loading,
    onClickGotIt,
    walkthroughAssets,
    slidedData,
    initialScreenLoading,
  };
}
