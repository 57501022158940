import { useState } from "react";
import { useNavigate } from "react-router-dom";
import getLang from "@mono-farmart-web/farmart-web-common/languages/buyerSearch";
import { getBuyerSearchAssets } from "@mono-farmart-web/farmart-web-common/constant/imageUrls";
import type { BuyerSearchProps, BuyerDataProps } from "./types";
import useAuthenticationCheckerHook from "@mono-farmart-web/farmart-web-common/utils/authCheckerV2";
import { apiEndpoints } from "../../apis/farmart-web/index";
import { languageUrls } from "@mono-farmart-web/farmart-web-common/constant/languageUrls/index";
import useAppLanguage from "@mono-farmart-web/farmart-web-common/utils/applanguage";
import Events from "@mono-farmart-web/farmart-web-common/constant/MoengageEvents/index";
import {
	trackEvent,
	addUniqueId,
} from "@mono-farmart-web/farmart-web-common/utils/moengage";

export default function useBuyerSearch(): BuyerSearchProps {
	const navigate = useNavigate(); // Navigation function
	const { AuthenticationCheckerApiCaller } = useAuthenticationCheckerHook();
	const [searchedValue, setSearchedValue] = useState("");
	const [loading, setLoading] = useState(false);
	const [buyerList, setBuyerList] = useState<BuyerDataProps[]>([]);
	const langId = JSON.parse(localStorage.getItem("userLanguage"));
	const buyerSearchScreenAssets = getBuyerSearchAssets(langId?.langCode);
	const { language } = useAppLanguage(languageUrls?.buyerSearch, getLang);

	function onPressBack() {
		trackEvent(Events?.onBuyerPriceBackButtonClick, {});

		navigate(-1);
	}

	function onSearchBuyer(name: string) {
		setSearchedValue(name);
		if (name?.length > 0) {
			getSearchedBuyerList(name);
		}
	}

	function onContactUs() {
		navigate("/faq/questions");
	}

	async function getSearchedBuyerList(searchParam: string) {
		try {
			setLoading(true);
			const farmersNumberResp = await AuthenticationCheckerApiCaller({
				payload: { page_no: 1, limit: 10, search_param: searchParam },
				uri: apiEndpoints?.fetchBuyerList,
				requestType: "get",
			});
			if (farmersNumberResp?.data?.status) {
				setBuyerList(farmersNumberResp?.data?.data?.buyer_list);
				setLoading(false);
			}
		} catch (error) {
			setLoading(false);
			console.log(error);
		}
	}

	function onClickRemoveSearch() {
		const onClickRemoveBuyerSearch = {
			screenName: "Buyer Search",
		};
		trackEvent(Events?.onBuyerPriceCrossButtonClick, onClickRemoveBuyerSearch);
		setSearchedValue("");
		setBuyerList([]);
	}

	function onClickBuyerName(buyerDetail: BuyerDataProps) {
		navigate("/trades", {
			state: { searchedBuyerDetail: buyerDetail },
		});
	}

	return {
		onPressBack,
		language,
		onContactUs,
		buyerSearchScreenAssets,
		buyerList,
		onSearchBuyer,
		searchedValue,
		loading,
		onClickRemoveSearch,
		onClickBuyerName,
	};
}
