import { Box, Skeleton } from '@mui/joy';

export default function BalanceContainerLoading() {
  return (
    <Box
      display={'flex'}
      padding={'var(--4, 16px)'}
      flexDirection={'column'}
      alignItems={'flex-start'}
      gap={'16px'}
      alignSelf={'stretch'}
      borderRadius={'var(--radius-lg, 12px)'}
      boxShadow={
        '0.3px 0.8px 1.1px 0px rgba(187, 187, 187, 0.12), 1.1px 2.8px 3.9px -0.4px rgba(187, 187, 187, 0.17), 2.4px 6.1px 8.6px -0.8px rgba(187, 187, 187, 0.23), 5.3px 13.3px 18.8px -1.2px rgba(187, 187, 187, 0.29)'
      }
      bgcolor={'#FBFCFE'}
      boxSizing={'border-box'}
    >
      <Skeleton
        sx={{
          position: 'relative',
          height: '20px',
          width: '129px',
          borderRadius: 'var(--radius-sm, 4px)',
        }}
      />
      <Skeleton
        sx={{
          position: 'relative',
          height: '20px',
          width: '100%',
          borderRadius: 'var(--radius-sm, 4px)',
        }}
      />
    </Box>
  );
}
