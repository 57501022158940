import getConfigByKeys from "../../config/config";
import { configKeys } from "../../config/types";
import handleHttpError from "../../utils/api/httpErrorMapping";
import HttpStatusCode from "../types/httpStatusCodesEnums.type";
// import type UnifiedResponse from "../types/unifiedResponse.type";

import { DEFAULT_LANGUAGE_ID } from "../../constant/language";

import {
	getAuthTokenFromStorage,
	getUserLanguageFromStorage,
} from "../../modal/auth/auth.model";
import { apiEndPoints } from "../endpoints";

const { API_URL } = getConfigByKeys([configKeys.API_URL]);

export default async function fetchRecentKhataList(queryParams): Promise<any> {
	const authToken = getAuthTokenFromStorage();
	let userLanguage = getUserLanguageFromStorage()?.langId || null;
	// thrwong error because at this point of time
	// the user should be logged in
	if (!authToken) throw new Error("Auth token not found");

	if (!userLanguage) {
		userLanguage = DEFAULT_LANGUAGE_ID;
	}
	const path = apiEndPoints.fetchRecentKhataList;

  const url = new URL(`${API_URL}${apiEndPoints?.fetchRecentKhataList}`);
  for (const key of Object.keys(queryParams)) {
    url.searchParams.append(key, queryParams[key]);
  }

	const response = await fetch(
		`${url}`,
		{
			method: "get",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				authorization: `Bearer ${authToken}`,
				language: userLanguage.toString(),
			},
		},
	);

	if (response.status !== HttpStatusCode.Ok) {
		throw handleHttpError(response.status, path);
	}

	const data = await response.json();
	return { data, response };
}
