import React from 'react';
import { Box } from '@mui/joy';

export default function MoneyBar  ({ moneyGiven, moneyReceived })  {
  // Base styles for the bar
  const barStyle = {
    display: 'flex',
    width: '100%',
    height: '16px',
    borderRadius: '100px',
    overflow: 'hidden',
    backgroundColor: (moneyGiven === 0 && moneyReceived === 0) ? 'white' : 'transparent',
  };

  // Handle the case where both amounts are zero
  if (moneyGiven === 0 && moneyReceived === 0) {
    return <Box style={barStyle}></Box>;
  }

  // Handle the case where moneyGiven is zero but moneyReceived is greater than zero
  if (moneyGiven === 0) {
    return (
      <Box style={barStyle}>
        <Box width="100%" bgcolor="success.outlinedBorder"></Box>
      </Box>
    );
  }

  // Handle the case where moneyReceived is zero
  if (moneyReceived === 0) {
    return (
      <Box style={barStyle}>
        <Box width="100%" bgcolor="danger.outlinedBorder"></Box>
      </Box>
    );
  }

  // Calculate percentages when moneyGiven is greater than zero

  const totalMoney = moneyGiven + moneyReceived;
  const givenPercentage = (moneyGiven / totalMoney) * 100;
  const receivedPercentage = (moneyReceived / totalMoney) * 100;

  // If both amounts are equal and not zero, set both to 50%
  if (moneyGiven === moneyReceived) {
    return (
      <Box style={barStyle}>
        <Box width="50%" bgcolor="success.outlinedBorder"></Box>
        <Box width="50%" bgcolor={'danger.outlinedBorder'}></Box>
      </Box>
    );
  }

  return (
    <Box style={barStyle}>
      <Box width={`${receivedPercentage}%`} bgcolor={'success.outlinedBorder'}></Box>
      <Box width={`${givenPercentage}%`} bgcolor={'danger.outlinedBorder'}></Box>
    </Box>
  );
};

