import BahiKhataSearchView from '../../Pages/BahiKhataSearch';
import BahiKhataSearchProps from '../../hooks/BahiKhataSearch';

export default function BahiKhataSearch() {
  const {
    initialLoading,
    khataList,
    language,
    onClickKhata,
    onClickRemoveSearch,
    onPressBack,
    onSearchBuyer,
    searchedValue,
    bahiKhataSearchAssets
  } = BahiKhataSearchProps();
  return (
    <BahiKhataSearchView
      initialLoading={initialLoading}
      khataList={khataList}
      language={language}
      onClickKhata={onClickKhata}
      onClickRemoveSearch={onClickRemoveSearch}
      onPressBack={onPressBack}
      onSearchBuyer={onSearchBuyer}
      searchedValue={searchedValue}
      bahiKhataSearchAssets={bahiKhataSearchAssets}
    />
  );
}
