import { useState, useEffect, useRef } from "react";
import type { OTPInputProps, LanguageParams } from "./types.js"; // Importing necessary types
import getLang from "@mono-farmart-web/farmart-web-common/languages/otp.js"; // Importing language translation function
import { addUniqueId, trackEvent } from "@mono-farmart-web/farmart-web-common/utils/moengage/index.js"; // Importing tracking functions
import Events from "@mono-farmart-web/farmart-web-common/constant/MoengageEvents/index.js"; // Importing constant events
import encryptAsAES from "@mono-farmart-web/farmart-web-common/utils/encryption/aes.js"; // Importing encryption function
import CreateTextForEncryption from "@mono-farmart-web/farmart-web-common/utils/mobileEncryption/index.js"; // Importing function to create text for encryption
import { useNavigate, useLocation } from "react-router-dom"; // Importing hooks for navigation
import { usePlatfromData } from "@mono-farmart-web/farmart-web-common/context/CrossPlatformActionHandler/platformChecker.js"; // Importing hook to get platform data
import { createBridge } from "@mono-farmart-web/rpc-bridge";
import PlatForm from "@mono-farmart-web/farmart-web-common/constant/platFormEnums.js";
import PostRequest from "@mono-farmart-web/farmart-web-common/utils/apiCaller/postRequest.js";
import GetRequest from "@mono-farmart-web/farmart-web-common/utils/apiCaller/getRequest.js";
import { apiEndPoints } from "@mono-farmart-web/farmart-web-common/apis/endpoints/index.js";
import CountryCode from "@mono-farmart-web/farmart-web-common/constant/CountryCodeEnums.js";
import { helpLineNumber } from "@mono-farmart-web/farmart-web-common/constant/HelpLineNumber.js";
import { getLoginScreenAssets } from "@mono-farmart-web/farmart-web-common/constant/imageUrls/index.js";
import { fetchLanguage } from "@mono-farmart-web/farmart-web-common/utils/languageApi/fetchLanguage.js";
import { languageUrls } from "@mono-farmart-web/farmart-web-common/constant/languageUrls/index.js";
import { DEFAULT_LANGUAGE } from "@mono-farmart-web/farmart-web-common/constant/language.js";
import { useLanguage } from "@mono-farmart-web/farmart-web-common/context/language/index.js";
import bridgeFunctions from "@mono-farmart-web/rpc-bridge/utility";
import isAndroid from "@mono-farmart-web/farmart-web-common/utils/operatingSystemType/index.js";
import { setProfileToStorage } from "@mono-farmart-web/farmart-web-common/modal/auth/auth.model.js";

export default function OtpSubmitHook(): OTPInputProps {
	const bridge = createBridge();
	const { languageData } = useLanguage();
	const params = useLocation()?.state; // Getting location state
	const navigate = useNavigate(); // Navigation function
	const platformData = usePlatfromData(); // Getting platform data

	const [languageFile, setLanguageFile] = useState([]);
	const inputEventTriggered = useRef(false);
	const [initialScreenLoading, setInitialScreenLoading] = useState<boolean>(true);

	useEffect(() => {
		getLanguageJson();
	}, []);

	async function getLanguageJson() {
		try {
			const url = languageUrls.otpScreenLangageUrl;
			const result = await fetchLanguage(url);
			setLanguageFile(result);
		} catch (error) {
			setError(error);
		} finally {
			setLoading(false);
			setInitialScreenLoading(false);
		}
	}

	const activeSystemLang = Intl.DateTimeFormat().resolvedOptions().locale;
	const systemLanguage = activeSystemLang?.includes("en")
		? languageData && languageData?.[1]?.langCode
		: languageData && languageData?.[0]?.langCode;
	const systemLanguageId = activeSystemLang?.includes("en")
		? languageData && languageData?.[1]?.backendLanguageCode
		: languageData && languageData?.[0]?.backendLanguageCode;

	const startLoadTime = new Date().getTime(); // Start time for tracking

	const langId = JSON.parse(localStorage.getItem("userLanguage")); // Getting user's selected language
	const [userBlocked, setUserBlocked] = useState<boolean>(false); // State to track if user is blocked
	const [userMobileNumber, setUserMobileNumber] = useState<string>(
		params?.userMobileNumber, // State to store user's mobile number
	);
	const [error, setError] = useState<boolean>(false); // State to track error status
	const [loading, setLoading] = useState<boolean>(false); // State to track loading status
	const [userOtp, setUserOtp] = useState<string>(""); // State to store user's entered OTP
	const [disableResend, setDisableResend] = useState<boolean>(true); // State to disable resend button

	const language = languageFile
		? languageFile[langId?.langCode ? langId?.langCode : systemLanguage]
		: getLang(DEFAULT_LANGUAGE);

	const otpScreenAssets = getLoginScreenAssets(langId?.langCode);

	useEffect(() => {
		const intervalId = setInterval(() => {
			// Call your function here

			(window as any).listenMessagesFromApp = (request: any) => {
				const parsed = JSON.parse(request);
				if (parsed?.otp) {
					const newStr = parsed?.otp.join("");
					setUserOtp(newStr);
					validateOtp(newStr);
				}
			};
		}, 1000);

		// Cleanup function to clear the interval when the component unmounts
		return () => clearInterval(intervalId);
	}, []);

	// Storing current language or defaulting to Hindi
	const currentLanguage = langId || {
		langCode: systemLanguage,
		langId: systemLanguageId,
	};
	const [isLanguageModalOpen, toggleLanguageModal] = useState<boolean>(false); // State to toggle language modal
	const [isLanguageSelectedInProgress, setLanguageSelectedInProgeress] =
		useState<boolean>(false); // State to track if language selection is in progress

	const [selectedLanguage, setSelectedLanguage] = useState(currentLanguage); // State to store selected language

	useEffect(() => {
		getOtpFromNative();
	}, [userOtp]);

	const getOtpFromNative = () => {
		bridge.sendRequestToNative("startOtpListener", null, (response: any) => {
			bridgeFunctions.PrintLog(response);
		});
	};

	// Function to handle language selection in language modal
	function handleLanguageSelect(languageItem: LanguageParams) {
		trackEvent(Events?.onChangeLanguageModalLanguageButtonClick, {
			'Language_Selected': languageItem.langCode,
		});
		setSelectedLanguage(languageItem);
	}

	// Function to handle language change
	function handleLanguageChange() {
		trackEvent(Events?.onChangeLanguageModalSubmitButtonClick, {});
		if (localStorage.getItem("userLanguage") !== selectedLanguage) {
			setLanguageSelectedInProgeress(!isLanguageSelectedInProgress);
			localStorage.setItem("userLanguage", JSON.stringify(selectedLanguage));
			toggleLanguageModal(!isLanguageModalOpen);
			setLanguageSelectedInProgeress(false);
		} else {
			toggleLanguageModal(!isLanguageModalOpen);
		}
	}

	// Function to toggle language modal
	function onLanguageModalToggle() {
		setSelectedLanguage(
			langId || {
				langCode: systemLanguage,
				langId: systemLanguageId,
			},
		);
		toggleLanguageModal(!isLanguageModalOpen);
	}

	// Function to validate OTP. It will navigate to Profile selection screen for new users or navigate to home screen for old users.
	/**
	 * The function `validateOtp` handles the validation of OTP, sets authentication token in local
	 * storage, and performs additional actions based on the response.
	 * @returns The `validateOtp` function returns either `true` or `false` based on the success or failure
	 * of the OTP validation process. If the OTP validation is successful and a token is received, it
	 * returns `false`. If there is an error during the process, it returns `true`.
	 */
	async function validateOtp(autoReadOtp) {
		setLoading(true);
		try {
			const otpValidateresponse = await PostRequest({
				payload: {
					mobile_number: userMobileNumber,
					otp: autoReadOtp ? autoReadOtp : userOtp,
					role_id: 6,
				},
				uri: apiEndPoints.validateOTP,
			});

			if (typeof otpValidateresponse?.data?.data?.token !== "string") {
				return setError(true);
			}
			setError(false);
			addUniqueId(userMobileNumber);
			localStorage.setItem("authToken", otpValidateresponse?.data?.data?.token);
			getGuideData(otpValidateresponse?.data?.data?.token)


		} catch (error) {
			setLoading(false);
			trackEvent(Events?.onSGNOTPSubmittedButtonClick, {
				time: new Date().getTime() - startLoadTime,
				api_faluire: true,
				is_otp: false,
			});
			return setError(true);
		} finally {
			setLoading(false);
		}
	}

	// Function to get OTP
	async function getOtp() {
		setLoading(true);
		const encryptedText = await encryptAsAES(
			CreateTextForEncryption(userMobileNumber),
		);
		try {
			const getOtpData = await PostRequest({
				payload: {
					mobile_number: encryptedText,
					country_code: CountryCode.get("india"),
					message_id: "",
				},
				uri: apiEndPoints.getOTP,
			});
			if (getOtpData?.data?.status) {
				setError(false);
				trackEvent(Events.SGN_OTP_SUBMITTED, { api_faluire: true });
			}
		} catch (error) {
			if (error?.code === 404) {
				setUserBlocked(true);
			}
			trackEvent(Events.SGN_OTP_SUBMITTED, { api_faluire: false });
			setLoading(false);
			return setError(true);
		} finally {
			setLoading(false);
		}
	}

	// Function to handle back button press
	function onPressBack(from: string) {
		return () => {
			if (from === "backButton") {
				trackEvent(Events?.onLoginScreenBackButtonClick, {
					FROM: "Back Button",
				});
			} else {
				trackEvent(Events?.onLoginScreenChangeNumberButtonClick, {
					FROM: "Change Number",
				});
			}
			navigate("/login", {
				state: {
					userMobileNumber: userMobileNumber,
				},
			});
		};
	}

	// Function to get user's OTP from input
	const getUserOtpFromInput = (newOtp: string) => {
		if (!inputEventTriggered.current) {
			inputEventTriggered.current = true;
			trackEvent(Events.onLoginScreenOTPInputBoxClick, {});
		}
		setUserOtp(newOtp);
		if (error) {
			return setError(false);
		}
	};

	// Function to resend OTP
	const resendOtp = () => {
		trackEvent(Events.onLoginScreenResendOTPButtonClick, {});
		getOtp();
	};

	// Function to proceed on OTP validation
	function onClickValidateOtpProceed() {
		if (userOtp?.length < 6) {
			return setError(true);
		}
		validateOtp("");
	}

	// Function to handle contact us action
	function onContactUs() {
		const url = `https://wa.me/${helpLineNumber}?text=नमस्कार जी, मुझे आपसे कुछ सहायता चाहिये | मैने अभी FarMart एप डाउनलोड किया है ।`;
		if (platformData.platform === PlatForm.get("website")) {
			const win = window?.open(url, "_blank");
			win?.focus();
		} else {
			const text = `नमस्कार जी, मुझे आपसे कुछ सहायता चाहिये | मैने अभी FarMart एप डाउनलोड किया है । &phone=${helpLineNumber}`;
			const link = `whatsapp://send?text=${text}`;
			bridge.sendRequestToNative(
				"OpenUrl",
				{
					link: link,
				},
				(response) => {
					bridgeFunctions.PrintLog(response);
				},
			);
		}
	}

	useEffect(() => {
		setSelectedLanguage(
			langId || {
				langCode: systemLanguage,
				langId: systemLanguageId,
			},
		);
	}, [localStorage.getItem("userLanguage")]);


	/**
	 * Asynchronously fetches guide data and handles navigation based on the completion status of guides.
	 *
	 * This function performs the following steps:
	 * 1. Tracks the OTP submission event.
	 * 2. Makes a POST request to fetch the merchant profile.
	 * 3. Makes a GET request to fetch the guide status.
	 * 4. Filters the guide data for specific guide IDs (3 and 4).
	 * 5. Checks the completion status of the guides and navigates accordingly.
	 * 6. Sets the token and profile in the native application or local storage based on the platform.
	 *
	 * @throws Will log an error message if any of the asynchronous operations fail.
	 */
  async function getGuideData(token: string) {
    try {
      trackEvent(Events?.onSGNOTPSubmittedButtonClick, {
        time: new Date().getTime() - startLoadTime,
        otp_submission: 'manual',
        api_faluire: false,
        is_new_user: false,
        is_otp: true,
      });
      /*It is making an asynchronous POST request to fetch the merchant profile of user. */
      const merchantProfile = await PostRequest({
        payload: {},
        uri: apiEndPoints?.fetchMerchantProfile,
      });

      setProfileToStorage(merchantProfile?.data?.data)

      const payload = {
        guide_ids: '3,4',
      };
      const farmersResp = await GetRequest({
        payload: payload,
        uri: apiEndPoints?.appGuideStatus,
      });
      if (farmersResp?.data?.status && farmersResp?.data?.data) {
        const guideId3 = farmersResp?.data?.data?.filter(
          (item) => item.guide_id === 3,
        );
        const guideId4 = farmersResp?.data?.data?.filter(
          (item) => item.guide_id === 4,
        );

        if (guideId3.length > 0 && guideId4.length > 0) {
          const guideId3completed = guideId3[0]?.is_completed;
          const guideId4completed = guideId4[0]?.is_completed;
          if (guideId3completed && guideId4completed) {
            if (merchantProfile?.data?.data?.merchant_occupation_id) {
              if (platformData?.platform === PlatForm?.get('application')) {
                bridge.sendRequestToNative(
                  'SetToken',
                  {
                    token: token,
                    profile: merchantProfile.data.data,
                  },
                  (response) => {
                    bridgeFunctions.PrintLog(response);
                  },
                );
                bridge.sendRequestToNative(
                  'setAppLanguage',
                  {
                    langCode: selectedLanguage?.langCode,
                  },
                  (response) => {
                    bridgeFunctions.PrintLog(response);
                  },
                );
              } else {
                localStorage.setItem(
                  'profileSelected',
                  merchantProfile?.data?.data?.merchant_occupation_id,
                );
                localStorage.setItem('loged', 'true');
                navigate('/');
              }
            }
          } else {
            if (guideId3completed && !guideId4completed) {
              navigate('/userInterest');
            } else if (!guideId3completed && guideId4completed) {
              if (isAndroid()) {
                navigate('/appGuide', {
                  state: {
                    showUserInterest: false,
                    merchantData: merchantProfile?.data?.data,
                  },
                });
              } else {
                localStorage.setItem(
                  'profileSelected',
                  merchantProfile?.data?.data?.merchant_occupation_id,
                );
                localStorage.setItem('loged', 'true');
                navigate('/');
              }
            } else {
              if (isAndroid()) {
                navigate('/appGuide', {
                  state: {
                    showUserInterest: true,
                    merchantData: merchantProfile?.data?.data,
                  },
                });
              } else {
                navigate('/userInterest');
              }
            }
          }
        }
      }
    } catch (error) {
      console.log('Error', error);
    }
  }

	// Returning necessary functions and states
	return {
		handleLanguageSelect,
		onLanguageModalToggle,
		handleLanguageChange,
		selectedLanguage,
		isLanguageModalOpen,
		isLanguageSelectedInProgress,
		userOtp,
		getUserOtpFromInput,
		disableResend,
		setDisableResend,
		resendOtp,
		userMobileNumber,
		onClickValidateOtpProceed,
		error,
		onPressBack,
		language,
		onContactUs,
		loading,
		otpScreenAssets,
		languageData,
		initialScreenLoading,
	};
}
