import { Box } from '@mui/joy';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import MuiTypography from '@mono-farmart-web/farmart-web-common/ComponentV3/Typography';
import MuiButton from '@mono-farmart-web/farmart-web-common/ComponentV3/MuiButton';
import Styles from './style.module.css';

export default function Header({
  onClickChangeModal,
  currentLocation,
  language,
  onClickFaq,
  onClickBack
}) {
  return (
    <Box className={Styles.mainContainer}>
      <Box display={'flex'} alignItems={'center'} gap={'8px'}>
        <Box
          component="span"
          id="DistributorBack"
          data-testid="DistributorBack"
          sx={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            height: '24px',
          }}
        >
          <ArrowBackRoundedIcon
            onClick={onClickBack}
            sx={{ color: '#171A1C' }}
          />
        </Box>
        <Box>
          <MuiTypography level="title-md" textColor={'text.primary'}>
            {language.heading}
          </MuiTypography>
        </Box>
      </Box>
      <Box>
        <MuiButton
          id="DistributorHelpButton"
          data-testid="DistributorHelpButton"
          size="sm"
          variant="soft"
          color="warning"
          sx={{
            bgcolor: '#FDF0E1',
            color: '#492B08',
          }}
          onClick={onClickFaq}
        >
          {language.helpText}
        </MuiButton>
      </Box>
    </Box>
  );
}

