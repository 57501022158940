import React from 'react';
import { Box } from '@mui/joy';
import { RefreshRounded, WhatsApp } from '@mui/icons-material';
import MuiButton from '../../ComponentsV3/MuiButton';
import MuiTypography from '../../ComponentV3/Typography';
import getLang from '../../languages/commonErrorState';
import { getErrorStateAssets } from '../../constant/imageUrls/index';
import { languageUrls } from '../../constant/languageUrls/index';
import useAppLanguage from '../../utils/applanguage';
import { DEFAULT_LANGUAGE } from '../../constant/language';
import { trackEvent } from '../../utils/moengage';
import Events from '../../constant/MoengageEvents/index';

interface CommonErrorStateProps {
  onPressRetry?: () => void;
  onPressHelp?: () => void;
  type?: 'screen' | 'modal'; // Define the union type
  customPrimaryButtonTitle?: string;
  customSecondaryButtonTitle?: string;
  onClickCustomPrimaryButton?: () => void;
  onClickCustomSecondaryButton?: () => void;
  onPressRetryTestId?: string;
  onPressHelpTestId?: string;
  customPrimaryButtonTestId?: string;
  customSecondaryButtonTestId?: string;
  imageId?: string;
  headingTextId?: string;
  subHeadingTextId?: string;
  hideHelpButton?: boolean
}

const CommonErrorState = ({
  onPressRetry,
  onPressHelp,
  type = 'screen', // Default to 'screen'
  customPrimaryButtonTitle = '',
  customSecondaryButtonTitle = '',
  onClickCustomPrimaryButton,
  onClickCustomSecondaryButton,
  onPressRetryTestId = '',
  onPressHelpTestId = '',
  customPrimaryButtonTestId = '',
  customSecondaryButtonTestId = '',
  headingTextId = '',
  subHeadingTextId = '',
  imageId = '',
  hideHelpButton = false
}: CommonErrorStateProps) => {
  const { language } = useAppLanguage(languageUrls?.commonErrorState, getLang);
  const languageFromLocalStorage = localStorage.getItem('userLanguage');
  const parsedLanguage =
    languageFromLocalStorage && JSON.parse(languageFromLocalStorage);
  const slectedLanguage =
    (parsedLanguage && parsedLanguage?.langCode) || DEFAULT_LANGUAGE;
  const errorStateAssets = getErrorStateAssets(slectedLanguage);

  return (
    <Box
      height={type === 'screen' ? '100vh' : 'auto'} // Adjust height based on type
      display={'flex'}
      flexDirection={'column'}
      paddingX={10}
      justifyContent={'center'}
      bgcolor={'white'}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        textAlign={'center'}
        marginBottom={12}
      >
        <img
          src={errorStateAssets.errorStateImage}
          style={{ height: '80px', width: '90px', marginBottom: '32px' }}
          alt="Error Screen"
          loading="eager"
          id={imageId}
          data-testid={imageId}
        />
        <MuiTypography
          level="title-md"
          id={headingTextId}
          data-testid={headingTextId}
        >
          {language?.errorStateHeading}
        </MuiTypography>
        <MuiTypography
          level={'body-xs'}
          id={subHeadingTextId}
          data-testid={subHeadingTextId}
        >
          {language?.errorStateSubHeading}
        </MuiTypography>
      </Box>
      <Box gap={3}>
        {customPrimaryButtonTitle ? (
          <MuiButton
            onClick={onClickCustomPrimaryButton}
            size={'md'}
            id={customPrimaryButtonTestId}
          >
            {customPrimaryButtonTitle}
          </MuiButton>
        ) : (
          <MuiButton
            onClick={onPressRetry}
            startDecorator={<RefreshRounded />}
            size={'md'}
            id={onPressRetryTestId}
            onClickCapture={()=>trackEvent(Events.onAppFailureScreenRetryButtonClick,{})}
          >
            {language?.errorStatePrimaryButton}
          </MuiButton>
        )}
        {customSecondaryButtonTitle ? (
          <MuiButton
            onClick={onClickCustomSecondaryButton}
            color="warning"
            variant="soft"
            size={'md'}
            style={{ marginTop: '12px' }}
            id={customSecondaryButtonTestId}
          >
            {customSecondaryButtonTitle}
          </MuiButton>
        ) : (
          <>
            {
              !hideHelpButton &&
              <MuiButton
                onClick={onPressHelp}
                startDecorator={<WhatsApp />}
                color="warning"
                variant="soft"
                size={'md'}
                style={{ marginTop: '12px' }}
                id={onPressHelpTestId}
                onClickCapture={()=>trackEvent(Events.onAppFailureScreenHelpButtonClick,{})}
              >
                {language?.errorStateSecondaryButton}
              </MuiButton>
            }
          </>
        )}
      </Box>
    </Box>
  );
};

export default CommonErrorState;
