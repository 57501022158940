import React from 'react';
import { Box } from '@mui/material';
import Styles from './style.module.css';
import CloseRounded from '@mui/icons-material/CloseRounded';

interface SearchBuyerProps {
  onSearchBuyer: (name: string) => void;
  searchedValue: string;
  onClickRemoveSearch: () => void;
  language: { [key: string]: string };
}

export default function Search(props: SearchBuyerProps) {
  const { searchedValue, onSearchBuyer, onClickRemoveSearch, language } = props;
  return (
    <Box
      justifyContent={'center'}
      alignItems={'center'}
      alignSelf={'stretch'}
      display={'flex'}
      flex={1}
    >
      <Box margin={'0px 0px'} display={'flex'} flex={1}>
        <Box display={'flex'} height={40} flex={1}>
          <input
            onChange={(event) => {
              onSearchBuyer(event?.target?.value);
            }}
            className={Styles.inputBoxContainer}
            placeholder={language?.searchKhataPlaceholder}
            style={{
              fontFamily: 'NotoSansDevanagari',
              fontSize: 14,
              fontWeight: 400,
              lineHeight: 24,
              color: '#555E68',
            }}
            value={searchedValue}
            ref={(input) => input && input.focus()}
          />
          {searchedValue && (
            <Box
              display={'flex'}
              position={'absolute'}
              alignItems={'center'}
              alignSelf={'stretch'}
              height={40}
              justifyContent={'center'}
              right={28}
              onClick={onClickRemoveSearch}
            >
              <CloseRounded
                style={{
                  fontSize: 24,
                }}
                onClick={onClickRemoveSearch}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}
