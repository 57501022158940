/**
 * The function `GetRequest` sends a GET request to a specified URI with provided payload and handles
 * authentication, language settings, and error responses.
 * @param {getRequestQueryParameter} props - The `props` parameter in the `GetRequest` function is an
 * object with two properties:
 * @returns The GetRequest function returns a Promise that resolves to a UnifiedResponse object. The
 * UnifiedResponse object contains two properties: "data" which holds the data fetched from the API
 * response, and "response" which holds the entire API response object.
 */
import getConfigByKeys from '../../config/config';
import { configKeys } from '../../config/types';
import handleHttpError from '../api/httpErrorMapping';
import HttpStatusCode from '../../apis/types/httpStatusCodesEnums.type';
import type UnifiedResponse from '../../apis/types/unifiedResponse.type';

import { getHeaders } from './fetchHeaders';

const { API_URL } = getConfigByKeys([configKeys.API_URL]);

interface getRequestQueryParameter {
  payload: any;
  uri?: string;
  customUri?: string;
  signal?: AbortSignal; // Add signal parameter
}

export default async function GetRequest(
  props: getRequestQueryParameter,
): Promise<UnifiedResponse> {
  const { payload, uri, customUri, signal } = props;
  const headers = getHeaders();

  try {
    const response = await fetch(
      `${API_URL}${customUri ? customUri : uri}?${new URLSearchParams(payload)}`,
      {
        method: 'GET',
        headers: headers,
        signal
      },
    );
    if (response.status !== HttpStatusCode.Ok) {
      throw handleHttpError(response.status);
    }

    const data = await response.json();
    return { data, response };
  } catch(error) {
    console.log("Error::: (while fetching the resource)", error);
  }
}
