import { Box } from '@mui/joy';
import { Chip } from '@mui/joy';
import MuiTypography from '@mono-farmart-web/farmart-web-common/ComponentV3/Typography';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import Styles from './style.module.css';

interface DistributorCardProps {
  agency_name: string;
  address: string;
  categories: {
    name: string;
    background: string;
  }[];
  index: number;
  mobileNumber: number;
  handleCallClick: (name: string, address: string, category: string, mobileNumber: number) => void;
}

export default function DistributorCard({ agency_name, address, categories, index, mobileNumber, handleCallClick }: DistributorCardProps) {
  return (
    <Box
      className={Styles.container}
      id={`DistributorDiv${index + 1}`}
      data-testid={`DistributorDiv${index + 1}`}
      sx={{
        display: 'flex',
        padding: '12px',
        flexDirection: 'column',
        gap: '12px',
        borderRadius: '8px',
        background: '#FBFCFE',
        border: '1px solid #CDD7E1',
      }}
    >
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'flex-start'}
        sx={{ width: '100%' }}
      >
        <MuiTypography
          level="title-sm"
          sx={{
            fontSize: '16px',
            lineHeight: '24px',
            fontWeight: 600,
            color: '#171A1C',
            flex: 1,
            marginRight: '12px',
          }}
        >
          {agency_name}
        </MuiTypography>
        <Box
          className={Styles.callButton}
          onClick={() =>
            handleCallClick(
              agency_name,
              address,
              categories[0]?.name,
              mobileNumber,
            )
          }
          id={`CallButton${index + 1}`}
          data-testid={`CallButton${index + 1}`}
          sx={{
            bgcolor: 'var(--neutral-soft-Bg, #F0F4F8)',
            width: '40px',
            height: '40px',
            borderRadius: '8px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexShrink: 0,
            '&:hover': {
              bgcolor: 'var(--Light-Neutrals-N-50, #F8FAFC)',
            },
          }}
        >
          <CallOutlinedIcon sx={{ color: '#475569', fontSize: '24px' }} />
        </Box>
      </Box>

      <Box display={'flex'} flexDirection={'column'} gap={'16px'}>
        <Box display={'flex'} gap={'8px'} flexWrap={'wrap'}>
          {categories.map((category, index) => (
            <Chip
              key={`${category.name}-${index}`}
              size="sm"
              variant="soft"
              sx={{
                bgcolor: category.background,
                borderRadius: '4px',
                fontSize: '14px',
                fontWeight: 600,
                height: '24px',
                px: 1.5,
              }}
            >
              {category.name}
            </Chip>
          ))}
        </Box>
        <Box sx={{ height: '1px', bgcolor: '#E2E8F0', width: '100%' }} />
      </Box>

      <Box display={'flex'} alignItems={'flex-start'} gap={'8px'}>
        <LocationOnIcon
          sx={{
            color: '#64748B',
            fontSize: '20px',
            marginTop: '2px',
          }}
        />
        <MuiTypography
          level="body-sm"
          sx={{
            color: '#475569',
            fontSize: '14px',
            lineHeight: '20px',
          }}
        >
          {address}
        </MuiTypography>
      </Box>
    </Box>
  );
}
