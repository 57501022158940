import React, { useCallback } from "react";
import Styles from "./style.module.css";
import Modal from "../../ComponentV2/Modal";
import { Box, Button } from "@mui/joy";
import RadioButtonCheckedRoundedIcon from "@mui/icons-material/RadioButtonCheckedRounded";
import RadioButtonUncheckedRoundedIcon from "@mui/icons-material/RadioButtonUncheckedRounded";
import MuiTypography from "@mono-farmart-web/farmart-web-common/ComponentV3/Typography";
import MuiButton from "@mono-farmart-web/farmart-web-common/ComponentV3/MuiButton";

interface LanguageParams {
	langCode: string;
	langId: number;
}
interface PayloadType {
	handleLanguageSelect: (props: LanguageParams) => void;
	onLanguageModalToggle: () => void;
	handleLanguageChange: () => void;
	selectedLanguage: string;
	isLanguageModalOpen: boolean;
	isLanguageSelectedInProgress: boolean;
	language: { [key: string]: any };
	languageData: any;
}

function LanguageCard({ item, selectedLanguage, handleLanguageSelect, index }) {
	const handleSelect = useCallback(() => {
		handleLanguageSelect({
			langCode: item?.langCode,
			langId: item?.backendLanguageCode,
		});
	}, [item.langCode]);
	return (
		<Box
			id={"loginScreenSelectLangugeCardTestId" + index}
			onClick={handleSelect}
			className={
				item?.langCode === selectedLanguage?.langCode
					? Styles.activeCardMainContainer
					: Styles.inActiveCardMainContainer
			}
		>
			<Box display={"flex"} justifyContent={"space-between"} flex={1}>
				<Box display={"flex"} flexDirection={"column"}>
					<MuiTypography level="body-lg" textColor={"text.primary"}>
						{item?.languageNameInHindi}
					</MuiTypography>
					<MuiTypography level="label-sm">
						{item?.languageNameInEnglish}
					</MuiTypography>
				</Box>
				<Box>
					{item?.langCode === selectedLanguage?.langCode ? (
						<RadioButtonCheckedRoundedIcon
							htmlColor="#008B74"
							sx={{ height: 28, width: 28 }}
						/>
					) : (
						<RadioButtonUncheckedRoundedIcon
							htmlColor="#C2C7CF"
							sx={{ height: 28, width: 28 }}
						/>
					)}
				</Box>
			</Box>
		</Box>
	);
}

const SelectCropModal = ({
	handleLanguageSelect,
	onLanguageModalToggle,
	handleLanguageChange,
	selectedLanguage,
	isLanguageModalOpen,
	isLanguageSelectedInProgress,
	language,
	languageData,
}: PayloadType) => {
	const appLanguages = languageData && languageData;
	const UiModal = (
		<Box
			bgcolor={"background.body"}
			paddingBottom={4}
			display={"flex"}
			flexDirection={"column"}
		>
			<Box
				display={"grid"}
				marginTop={4}
				flexDirection={"row"}
				gridTemplateColumns={"repeat(2, 1fr)"}
				margin={4}
				gap={4}
			>
				{appLanguages?.map((item, index) => (
					<LanguageCard
						item={item}
						selectedLanguage={selectedLanguage}
						handleLanguageSelect={handleLanguageSelect}
						index={index}
						key={`Language${index}`}
						data-testid={`LanguageCard${index}`}
						content-desc={`LanguageCard${index}`}
					/>
				))}
			</Box>
			<Box display={"flex"} margin={"0px 16px"}>
				<MuiButton
					size="lg"
					fullWidth={true}
					onClick={handleLanguageChange}
					loading={isLanguageSelectedInProgress}
					id="LanguageSubmit"
          data-testid="LanguageSubmit"
					content-desc="LanguageSubmit"
				>
					{language?.changeLanguage}
				</MuiButton>
			</Box>
		</Box>
	);

	return (
		<Modal
			id="loginScreenCloseModalTestId"
			onClose={onLanguageModalToggle}
			isCloseButtonOption={true}
			title={language?.selectLanguage}
			isOpen={isLanguageModalOpen}
			children={UiModal}
		/>
	);
};

export default SelectCropModal;
