import React, { useState } from 'react';
import {
  Box,
  Column,
  Row,
} from "@mono-farmart-web/farmart-web-common/MuiComponents/atoms/layout";
import {
  ArrowBackRounded,
  PersonAddAlt1Rounded,
  SearchRounded,
} from "@mui/icons-material";
import Styles from "./style.module.css";
import Header from "@mono-farmart-web/farmart-web-common/MuiComponents/pages/common/Header";
import { useNavigate } from "react-router-dom";
import ButtonC from "@mono-farmart-web/farmart-web-common/MuiComponents/atoms/button/Button";
import { Text } from "@mono-farmart-web/farmart-web-common/MuiComponents/atoms/typography";
import isAndroid from '@mono-farmart-web/farmart-web-common/utils/operatingSystemType';
import PersonIcon from '@mui/icons-material/Person';
import Loading from '@mono-farmart-web/farmart-web-common/ComponentV2/Loading';
import AddFilledIcon from '@mono-farmart-web/farmart-web-common/assets/svgComponet/AddFilledIcon';
import DynamicContactList from './DynamicContactList';
import { PlayStoreUrl } from '@mono-farmart-web/farmart-web-common/constant/PlayStoreUrl';
import getTranslatedLanguage from '@mono-farmart-web/farmart-web-common/utils/stringSubstitution';
import { trackEvent } from '@mono-farmart-web/farmart-web-common/utils/moengage';
import Events from '@mono-farmart-web/farmart-web-common/constant/MoengageEvents/index.js';

function uniqueItems(items) {
  const uniqueMap = new Map();

  for (const item of items) {

    const key = item.mobile_number;

    if (!uniqueMap.has(key)) {
      uniqueMap.set(key, item);
    }
  }
  return Array.from(uniqueMap.values());
}

const ContactList = (props) => {
  const {
    contacts,
    loading,
    hasMore,
    searchQuery,
    handleSearchChange,
    loadMoreItems,
    totalCount,
    handleSettingsPermission,
    contactPermission,
    language,
    recentKhataList,
    handleContactPermissionButtonClickEvent,
    handleAddUserClick,
    handleCreateNewTransaction,
    downloadAppEvent,
    nativeContacts=[],
    isLoadingMore
  } = props;

  const navigate = useNavigate();
  const isAndroidDevice = isAndroid();
  const isWeb = !isAndroidDevice;

  const colors = [
    "danger.softActiveBg",
    "success.softActiveBg",
    "primary.softActiveBg",
    "warning.softActiveBg",
  ];

  let cPermission = typeof contactPermission === "undefined" ?
    sessionStorage?.getItem("contactPermission") : contactPermission;
  if(typeof cPermission === "string") {
    cPermission = JSON.parse(contactPermission);
  }

  const baseActionContainer = isWeb
    ? {
        titleText: language?.downloadAppInfo,
        buttonText: language?.downloadAppButton,
        id: "bahikhata_downloadFarMartApp"
      }
    : {
        titleText: language?.enableContactPermissionInfo,
        buttonText: language?.enableContactPermission,
        id: "bahikhata_contactPermission"
      };

  const getAllItems = () => {
    if (!searchQuery) {
      return contacts;
    }

    const filteredNativeContacts = (nativeContacts || []).filter(contact => {
      if (!searchQuery) return true;
      const query = searchQuery.toLowerCase();
      return (
        (contact?.farmer_name?.toLowerCase().includes(query)) ||
        (contact?.mobile_number?.toString().includes(query))
      );
    });

    const nativeResult = uniqueItems([...contacts, ...filteredNativeContacts]);

    const searched = searchQuery
    ? nativeResult
    : [...contacts, ...filteredNativeContacts];

    return searched;
  };

  const renderItem = ({ index, style }) => {
    const items = getAllItems();
    const item = items[index];

    if (!item) return null;

    return (
      <Row
        key={item?.id || `native-${item?.mobile_number}`}
        style={{
          ...style,
          padding: '16px 0px',
          width: '100%',
          boxSizing: 'border-box',
          margin: 0
        }}
        id={`bahikhata_list${item?.id}`}
        data-testid={`bahikhata_list${item?.id}`}
        content-desc={`bahikhata_list${item?.id}`}
        justifyContent="space-between"
        alignItems="center"
        onClick={(event) => {
          if(!item?.nativeContact) {
            navigate(`/bahiKhata/${item?.id}`)
          } else {
            handleCreateNewTransaction(event, {
              farmer_name: item?.farmer_name,
              mobile_number: item?.mobile_number
            })
          }
        }}
      >
        <Row
          flex={1}
          alignItems="center"
          justifyContent="flex-start"
          gap={3}
          minWidth={0}
          sx={{ overflow: 'hidden' }}
        >
          <Row
            width="40px"
            height="40px"
            bgcolor={colors[index % colors.length]}
            justifyContent="center"
            alignItems="center"
            color="primary.solidColor"
            borderRadius="50%"
            flexShrink={0}
          >
            {item?.farmer_name ? item?.farmer_name[0]: item?.mobile_number?.toString()?.[0]}
          </Row>
          <Text
            level="body-sm"
            fontWeight={400}
            textColor="text.primary"
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              flex: 1,
              minWidth: 0
            }}
          >
            {item?.farmer_name ? item?.farmer_name : item?.mobile_number}
          </Text>
        </Row>
        {!isWeb && item?.nativeContact && (
          <Box flexShrink={0}>
            <ButtonC
              id={`bahikhata_add_list${item?.id}`}
              data-testid={`bahikhata_add_list${item?.id}`}
              content-desc={`bahikhata_add_list${item?.id}`}
              sx={{
                background: "white",
                color: "#019881",
                "&:active": {
                  background: "white",
                  color: "#019881",
                }
              }}
              startDecorator={
                <AddFilledIcon
                  height="20"
                  width="20"
                  fillColor="#019881"
                />
              }
            >
              {language?.addKhataButton}
            </ButtonC>
          </Box>
        )}
      </Row>
    );
  };

  const handleScroll = ({ scrollOffset }) => {
    if (scrollOffset > (getAllItems().length * 72 - 800)) {
      if (hasMore && !loading && !isLoadingMore) {
        loadMoreItems();
      }
    }
  };

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateRows: 'auto auto 1fr auto',
        height: '100dvh',
        overflow: 'hidden'
      }}
      className={Styles?.container}
    >
      <Header
        leftIcon={
          <ArrowBackRounded
            id="bahikhata_backButton"
            data-testid="bahikhata_backButton"
            content-desc="bahikhata_backButton"
            sx={{ width: "24px", height: "24px" }}
            onClick={() => navigate("/bahiKhata")}
          />
        }
        title={language?.title}
        rightIcon={
          <PersonAddAlt1Rounded
            id="bahikhata_addmanually2"
            data-testid="bahikhata_addmanually2"
            content-desc="bahikhata_addmanually2"
            sx={{ width: "24px", height: "24px" }}
            onClick={handleAddUserClick}
          />
        }
      />

      <Box padding={4} width="100%" boxSizing="border-box">
        <Row
          py="8px"
          px="12px"
          border="1px solid"
          borderRadius="8px"
          borderColor="neutral.outlinedBorder"
          gap="8px"
          width="100%"
          boxSizing="border-box"
        >
          <SearchRounded
            id="bahikhata_searchicon"
            data-testid="bahikhata_searchicon"
            content-desc="bahikhata_searchicon" sx={{ width: "24px", height: "24px" }} />
          <input
          onFocus={() =>
            trackEvent(Events?.onSearchBoxClick, {})
          }
            type="text"
            placeholder={language?.searchPlaceholder}
            value={searchQuery}
            id="bahikhata_savedKhataSearchbar"
            data-testid="bahikhata_savedKhataSearchbar"
            content-desc="bahikhata_savedKhataSearchbar"
            onChange={(e) => handleSearchChange(e.target.value)}
            style={{
              outline: "none",
              border: 0,
              width: "100%",
              fontSize: "16px",
              fontWeight: "400",
              color: "#171a1c",
            }}
          />
        </Row>
      </Box>

      <Box
        sx={{
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
          '&::-webkit-scrollbar': {
            display: 'none'
          },
          '-ms-overflow-style': 'none',
          'scrollbarWidth': 'none'
        }}
      >

        <Box px={4} width="100%" boxSizing="border-box">

          {!loading && searchQuery && !getAllItems()?.length && (
            <Box width="100%">
              <ButtonC
                sx={{
                  border: "1px solid",
                  borderColor: "primary.outlinedBorder",
                  borderRadius: "8px",
                  bgcolor: "background.body",
                  padding: "6px 24px",
                  minWidth: "328px",
                  color: "#019881",
                  fontSize: "16px",
                  fontWeight: "600",
                  gap: "12px",
                  width: "100%"
                }}
                onClick={() => navigate("/bahiKhata/recordTransaction/addNewKhata", {
                  state: {
                    username: searchQuery
                  }
                })}
                startDecorator={
                  <Row
                    width="32px"
                    height="32px"
                    borderRadius="50%"
                    alignItems="center"
                    justifyContent="center"
                    bgcolor="primary.softBg"
                  >
                    <PersonIcon sx={{
                      color: "#007B65",
                      margin: 0
                    }} />
                  </Row>
                }
              >
                {getTranslatedLanguage(language?.addSearchNewKhata, [searchQuery])}
              </ButtonC>
            </Box>
          )}

          <Box
            width="100%"
            flex={1}
            sx={{
              '& > div': {
                '&::-webkit-scrollbar': {
                  display: 'none'
                },
                '-ms-overflow-style': 'none',
                'scrollbarWidth': 'none'
              }
            }}>

            <DynamicContactList
              items={getAllItems()}
              renderItem={renderItem}
              onScroll={handleScroll}
              isWeb={isWeb}
              cPermission={cPermission}
              searchQuery={searchQuery}
              bottomActionHeight={125}
              headerHeight={64}
              searchBarHeight={72}
              recentKhataList={recentKhataList}
              colors={colors}
              navigate={navigate}
              language={language}
            />
          </Box>

          {isLoadingMore && (
            <Box py={4} textAlign="center" width="100%">
              <Text level="body-sm" textColor="text.secondary">
                <Row alignItems="center" justifyContent="center">
                  <Loading size={25} />
                </Row>
              </Text>
            </Box>
          )}
        </Box>
      </Box>

      {(isWeb || (!isWeb && !cPermission)) && (
        <Column
          padding={4}
          boxSizing="border-box"
          gap={3}
          width="100%"
          bgcolor="background.body"
          borderTop="1px solid"
          borderColor="neutral.outlinedBorder"
        >
          <Text
            level="body-xs"
            fontWeight={600}
            textColor="text.secondary"
            textAlign="center"
          >
            {baseActionContainer.titleText}
          </Text>
          <ButtonC
            color="primary"
            sx={{
              borderRadius: "8px",
              padding: "6px 24px",
              width: "100%"
            }}
            id={baseActionContainer.id}
            data-testid={baseActionContainer.id}
            content-desc={baseActionContainer.id}
            size="lg"
            loading={false}
            onClick={() => {
              if(!isWeb) {
                handleContactPermissionButtonClickEvent();
                handleSettingsPermission();
                navigate("/bahiKhata");
              } else{
                window.open(PlayStoreUrl, "_blank");
                downloadAppEvent();
              }
            }}
          >
            {baseActionContainer.buttonText}
          </ButtonC>
        </Column>
      )}
    </Box>
  );
};

export default ContactList;
