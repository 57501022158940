import { createRef, useRef } from "react";
import {
	Column,
	Row,
} from "@mono-farmart-web/farmart-web-common/MuiComponents/atoms/layout";
import {
	CloseRounded,
	ImageRounded,
} from "@mui/icons-material";
import { Text } from "@mono-farmart-web/farmart-web-common/MuiComponents/atoms/typography";
import { Drawer } from "@mui/joy";
import useBottomDrawer from "../../../hooks/BottomDrawer/useBottomDrawer";
import { useNavigate } from "react-router-dom";
import TransactionEnum from "@mono-farmart-web/farmart-web-common/constant/enums/transactionType";
import { getCreditImageOptionJSX } from "../modalGenerators"
import "./style.css";
import { displayWithCommas } from "@mono-farmart-web/farmart-web-common/utils/FormatAmountValue";
import ImagePermissionModal from '../ImagePermission'
import useCameraHooks from './../../../hooks/CreditEntryHooks/checkCameraHooks'
import isAndroid from "@mono-farmart-web/farmart-web-common/utils/operatingSystemType";

interface CardDetailProps {
	amountValue: string;
	noteValue: string;
	handleNoteValue: (event: any) => void;
	handleCreditAmount: (event: any) => void;
	handleOpenService: (event: any, type: string) => void;
	clearCreditDoc: (close: () => void) => void;
	uploadedImage: any;
	transactionId: number;
	language: any;
	handleAmountInputClickEvent: () => void;
	handleNoteInputClickEvent: () => void;
	handleCreditImageUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
	handleReuploadState: (flag?: boolean) => void;
	handleActiveParentToggle: (toggleValue: boolean) => void;
	handleDeleteIconClickEvent: (
		event: React.MouseEvent<SVGSVGElement, MouseEvent>,
	) => void;
  handleKeyDownCreditAmount: (event: any) => void;
	isActiveParent: boolean;
	isImageReUpload: boolean;
  viewImagePreview: () => void;

}

const CreditDetails = (props: CardDetailProps) => {
  const fileInputRef = createRef<HTMLInputElement>();
  const imageInputRef = useRef<HTMLInputElement>();
  const amountInputRef = createRef<HTMLInputElement>();
	const { open, close, drawerProps } = useBottomDrawer({
		onOpen: () => console.log("Drawer opened"),
		onClose: () => console.log("Drawer closed"),
	});

	const {
		handleClosePermissionModal,
		runUploadImageFlow,
		isCameraPermissionModalVisible,
		requestCameraPermission,
		onClickOpenSetting
	  } = useCameraHooks(
		imageInputRef
	  );

	const navigate = useNavigate();
	const {
		amountValue,
		noteValue,
		handleCreditAmount,
		handleNoteValue,
		handleOpenService,
		uploadedImage,
		clearCreditDoc,
		language,
		handleCreditImageUpload,
		handleAmountInputClickEvent,
		handleNoteInputClickEvent,
		handleReuploadState,
		handleActiveParentToggle,
		handleDeleteIconClickEvent,
		isActiveParent,
		isImageReUpload,
    handleKeyDownCreditAmount,
    viewImagePreview,
	} = props;
	const children = getCreditImageOptionJSX(
		handleOpenService,
		clearCreditDoc,
		handleReuploadState,
		close,
		isImageReUpload,
		language,
    	fileInputRef
	);

	const amountTitle =
		TransactionEnum?.["credit"] === props?.transactionId
			? language?.enterAmountLent
			: language?.enterAmountReceived;

	return (
		<Column
			paddingTop={6}
			paddingRight={4}
			paddingBottom={6}
			paddingLeft={4}
			boxSizing={"border-box"}
			minWidth={"360px"}
			width={"auto"}
			gap={3}
		>
			<Column
				paddingTop={4}
				paddingRight={6}
				paddingBottom={5}
				paddingLeft={6}
				borderRadius={"12px"}
				boxSizing={"border-box"}
				border={"1px solid #DDE7EE"}
				minWidth={"328px"}
				width={"auto"}
				gap={4}
			>
				{/* INNER AMOUNT INOUT */}
				<Column gap={"6px"}>
					<Text
						level={"body-sm"}
						textColor={"text.primary"}
						fontSize={"14px"}
						fontWeight={500}
						textAlign={"center"}
					>
						{amountTitle}
					</Text>

					{/* AMOUNT INPUT FIELD */}
					{/* AMOUNT INPUT FIELD */}
					<Row
						alignItems={"center"}
						justifyContent={"center"}
						paddingTop={3}
						paddingRight={4}
						paddingBottom={3}
						paddingLeft={4}
						gap={1}
						border={"2px solid"}
						borderRadius={"8px"}
            onClick={() => amountInputRef.current?.focus()}
						borderColor={isActiveParent ? "primary.500" : "#CDD7E1"}
					>
						<Text
							textAlign={"right"}
							level={"body-md"}
							textColor={
								Number.parseInt(amountValue) > 0
									? "neutral.outlinedColor"
									: "neutral.outlinedDisabledColor"
							}
							fontSize={"16px"}
							fontWeight={400}
						>
							₹
						</Text>

						{/* AMOUNT WOULD BE SHOWN HERE */}
						<input
              ref={amountInputRef}
              type="text"
							value={displayWithCommas(amountValue)}
              id="bahikhata_giveMoneyinput"
              data-testid="bahikhata_giveMoneyinput"
							content-desc="bahikhata_giveMoneyinput"
							className="credit_amount_input"
							onFocus={() => handleActiveParentToggle(true)}
              onKeyDown={handleKeyDownCreditAmount}
							onChange={(event) => {
								handleCreditAmount(event);
							}}
							placeholder={`${!amountValue ? "0" : ""}`}
							onBlur={() => handleActiveParentToggle(false)}
							style={{
								opacity: "64%",
								color: `${Number.parseInt(amountValue) > 0 ? "neutral.outlinedDisabledColor" : "neutral.outlinedColor"}`,
								fontSize: "18px",
								width: `${amountValue?.length * 10 + 10}px`,
								fontWeight: `${Number.parseInt(amountValue) > 0 ? 700 : 400}`,
								border: "0px",
								padding: 0,
								background: "white",
								outline: "none",
							}}
						/>
					</Row>
				</Column>

				{/* IMAGE UPLOAD + NOTE INPUT */}
          <Row
            px={"6px"}
            py={"8px"}
            borderRadius={"8px"}
            minWidth={"280px"}
            width={"auto"}
            height="32px"
            gap={"8px"}
            bgcolor={"#F0F4F8"}
            alignItems={"center"}>
            <input
              id="bahikhata_note"
              data-testid="bahikhata_note"
              content-desc="bahikhata_note"
              style={{
                flex: 1,
                border: 0,
                outline: 0,
                fontWeight: 400,
                fontSize: "14px",
                opacity: 6.4,
                background: "#F0F4F8",
                lineHeight: "20px",
                color: "#32383E"
              }}
              value={noteValue}
              onFocus={handleNoteInputClickEvent}
              onChange={(event) => {
                handleNoteValue(event);
              }}
              placeholder={language?.notePlaceholder}
            />
            {
              uploadedImage?.data ? (
                <ImageRounded
                  sx={{
                    width:"24px",
                    height: "24px"
                  }}
                  onClick={() => {
                    handleReuploadState(true);
                    open();
                  }}
                />
              ) : (
                <label className="photo-input-label">
                  <ImageRounded
				  onClick={
					!isAndroid()||runUploadImageFlow ?  () => {} : requestCameraPermission()
				}
                    id="bahikhata_imageUploadIcon"
                    data-testid="bahikhata_imageUploadIcon"
                    content-desc="bahikhata_imageUploadIcon"
                    sx={{
                      width: "24px",
                      height: "24px"
                    }}
                  />
			{!isAndroid()|| runUploadImageFlow  ?
				       <input
					   type="file"
					   className="photo-inputs"
					   accept="image/*"
					   onChange={handleCreditImageUpload}
					   ref={imageInputRef}
					   style={{display: 'none'}}
					 />
					 :<></>
					}
                </label>
              )
            }
        </Row>
			</Column>

			{/* IMAGE VIEWER */}
			{uploadedImage?.data ? (
				<Row
					padding={2}
					gap={3}
					bgcolor={"primary.softDisabledBg"}
					border={"1px solid"}
					borderColor={"#DDE7EE"}
					borderRadius={"8px"}
					onClick={() =>
						// navigate("/bahiKhata/creditEntry/viewDoc", {
						// 	state: {
						// 		fileUri: uploadedImage?.data,
						// 		fileName: uploadedImage?.title,
						// 	},
						// })
            viewImagePreview()
					}
				>
					{/* IMAGE */}
					<img
						src={uploadedImage?.data}
						alt="creditDocImage"
						style={{ width: "50px", height: "50px", objectFit: "fill" }}
					/>

					{/* IMAGE INFO */}
					<Row alignItems={"center"} justifyContent={"flex-start"} flex={1}>
						<Column gap={2}>
							<Text
								level={"title-sm"}
								fontWeight={600}
								textColor="text.primary"
							>
								{uploadedImage?.title}
							</Text>
							<Text
								level="body-xxs"
								fontWeight={500}
								textColor={"text.secondary"}
							>
								{uploadedImage?.size}
							</Text>
						</Column>
					</Row>

					{/* CLOSE ICON */}
					<Row alignItems={"center"} justifyContent={"center"}>
						<Row
							width={"32px"}
							height={"32px"}
							alignContent={"center"}
							justifyContent={"center"}
							flexWrap={"wrap"}
						>
							<CloseRounded
                id="bahikhata_imageDeleteIcon"
                data-testid="bahikhata_imageDeleteIcon"
								content-desc="bahikhata_imageDeleteIcon"
								sx={{ width: "20px", height: "20px", color: "#32383E" }}
								onClick={(event) => {
									handleDeleteIconClickEvent(event);
									open();
								}}
							/>
						</Row>
					</Row>
				</Row>
			) : null}

			<Drawer
				{...drawerProps}
        onClose={() => {
          handleReuploadState(false)
          close();
        }}
				slotProps={{
					content: {
						sx: {
							borderTopLeftRadius: "24px",
							borderTopRightRadius: "24px",
							// height: "auto",
              display: 'inline-flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius:
                  'var(--radius-xl, 16px) var(--radius-xl, 16px) 0px 0px',
                background: 'var(--background-level1, #F0F4F8)',
                height: 'auto',
                boxSizing: 'border-box',
                '@media (min-width: 1025px) and (max-width: 1280px)': {
                  width: '30vw',
                  margin: '0vw 35vw',
                },
                '@media (min-width: 1281px)': {
                  width: '30vw',
                  margin: '0vw 35vw',
                },
						},
					},
				}}
			>
				{children}
			</Drawer>

			<ImagePermissionModal
				isCameraPermissionModalVisible={isCameraPermissionModalVisible}
				handleClosePermissionModal={handleClosePermissionModal}
				language={language}
				onClickOpenSetting={onClickOpenSetting}
			/>
		</Column>
	);
};

export default CreditDetails;
