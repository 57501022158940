import { Box } from '@mui/joy';
import Styles from './style.module.css';
import SearchBar from '../SearchBar/SearchBar';
import { ArrowBackRounded } from '@mui/icons-material';

interface HeaderProps {
  onPressBack: () => void;
  language: { [key: string]: string };
  onSearchBuyer: (name: string) => void;
  searchedValue: string;
  onClickRemoveSearch: () => void;
}

export default function Header(props: HeaderProps) {
  const {
    language,
    onPressBack,
    searchedValue,
    onSearchBuyer,
    onClickRemoveSearch,
  } = props;

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      alignItems={'flex-start'}
      position={'sticky'}
      className={Styles?.mainContainer}
    >
      <Box
        justifyContent={'space-between'}
        alignItems={'center'}
        alignSelf={'stretch'}
        display={'flex'}
        gap={4}
      >
        <Box onClick={onPressBack}>
          <ArrowBackRounded
            sx={{ color: '#171A1C', fontSize: '24px' }}
            id="backarrowtab_searchscreen"
            data-testid="backarrowtab_searchscreen"
          />
        </Box>
        <SearchBar
          searchedValue={searchedValue}
          onSearchBuyer={onSearchBuyer}
          onClickRemoveSearch={onClickRemoveSearch}
          language={language}
        />
      </Box>
    </Box>
  );
}
