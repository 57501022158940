import * as AppLanguages from '../constant/appLanguages';
//language will be added here
const languages = {
  [AppLanguages.appLanguagesIndex.english]: {
    heading: 'Distributor List',
    helpText: 'Help',
    changeLocationText: 'Change',
    locationModalHeading: 'Location Information',
    locationModalDescpSubHeading:
      'We need your location to show you the correct information, please share your location with us',
    locationModalGpsButton: 'Allow location',
    locationModalDividerText: 'Or',
    pinCodeButtonHeading: 'Search Location',
    pinCodeButtonTitle: 'Enter District name or Pincode',
    noDistributorFound: 'No distributors found',
    category: 'Category',
    productType: 'Product Type',
    distributorFound: 'Distributor Found',
    seeds: 'Seeds',
    pesticides: 'Pesticides',
    fertilisers: 'Fertilisers',
    selectCategory: 'Select Category',
    selectProductType: 'Select Product Type',
    apply: 'Apply',
    clearAll: 'Clear All',
    searchByFertilizerName: 'Search by Fertilizer Name',
    noFertiliserTypeFound: 'No fertiliser type found',
  },
  [AppLanguages.appLanguagesIndex.hindi]: {
    heading: 'डिस्ट्रीब्यूटर लिस्ट',
    helpText: 'सहायता',
    changeLocationText: 'बदलें',
    locationModalHeading: 'लोकेशन की जानकारी',
    locationModalDescpSubHeading:
      'आपको सही जानकारी दिखाने के लिए हमें आपकी लोकेशन की आवश्यकता है, कृपया अपनी लोकेशन हमसे साझा करें',
    locationModalGpsButton: 'लोकेशन की अनुमति दें',
    locationModalDividerText: 'या फिर',
    pinCodeButtonHeading: 'लोकेशन खोजें',
    pinCodeButtonTitle: 'जिला का नाम या पिन कोड बताएं',
    noDistributorFound: 'कोई डिस्ट्रीब्यूटर नहीं मिला',
    category: 'कैटेगरी',
    productType: 'उत्पाद का प्रकार',
    distributorFound: 'डिस्ट्रीब्यूटर मिला',
    seeds: 'बीज',
    pesticides: 'कीटनाशक',
    fertilisers: 'खाद',
    selectCategory: 'कैटेगरी चुनें',
    selectProductType: 'उत्पाद का प्रकार चुनें',
    apply: 'लागू करें',
    clearAll: 'सभी को हटाएं',
    searchByFertilizerName: 'खाद के नाम से खोजें',
    noFertiliserTypeFound: 'कोई खाद प्रकार नहीं मिला',
  },
};
export default (language) => languages[language];
