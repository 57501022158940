import { Container } from "@mono-farmart-web/farmart-web-common/MuiComponents/atoms/layout";
import Header from "./Header";
import Styles from "./style.module.css";
import CreditDetails from "./CreditDetails";
import UserDetails from "./UserDetails";
import SaveButton from "./SaveButton";
import useBottomDrawer from "../../hooks/BottomDrawer/useBottomDrawer";
import { Box, Drawer } from "@mui/joy";
import { useNavigate } from "react-router-dom";
import { getRemindCreditDialogJSX } from "./modalGenerators";
import ProgressiveSignup from "./ProgressiveSignup";
import TransactionType from "@mono-farmart-web/farmart-web-common/constant/enums/transactionType";
import DocumentViewer from '@mono-farmart-web/farmart-web-common/ComponentsV3/UploadDocument/viewer/DocumentViewer';
import { DocumentProvider } from '@mono-farmart-web/farmart-web-common/ComponentsV3/UploadDocument/DocumentContext';
import DeleteImageModal from "./DeleteImageModal";

type CreditEntryProps = {
	userName: string;
	creditDate: Date;
	amountValue: string;
	noteValue: string;
	handleNoteValue: (event: any) => void;
	handleCreditAmount: (event: any) => void;
	handleCreditDate: (date: Date) => void;
	handleOpenService: (event: any, type: string) => void;
	clearCreditDoc: (close: () => void) => void;
	uploadedImage: any;
	transactionId: number;
	handleCreditDetailSubmission: (send_acknowledgement: boolean) => void;
	language: any;
	handleCreditImageUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
	handleAmountInputClickEvent: () => void;
	handleNoteInputClickEvent: () => void;
	handleReuploadState: (flag?: boolean) => void;
	handleActiveParentToggle: (toggleValue: boolean) => void;
	handleDeleteIconClickEvent: (
		event: React.MouseEvent<SVGSVGElement, MouseEvent>,
	) => void;
	handleDateSelectionEvent: () => void;
	onChangeInput: (value: string, type: string) => void;
	onCompleteUserInfo: () => void;
	onCloseProgressiveModal: () => void;
	handleOnClickSaveButton: (checkName: boolean) => void;
	getTemplateDataArray: () => any;
  handleKeyDownCreditAmount: (event: any) => any;
	progressiveModalVisible: boolean;
	merchantData: any;
	merchantDetail: any;
	isRetailer: boolean;
	isOpenCalender: boolean;
	isActiveParent: boolean;
	isImageReUpload: boolean;
	isCreditReminderModalVisible: boolean;
  onPressClosePreview: any;
  toggleDeleteImageModal: any;
  deleteImageModalVisible: any;
  onClickDeleteImage: any;
  viewImagePreview: () => void;
  isImagePreview: boolean;
  deleteModalLang: any;
  isTransactionCreated: boolean;
  disableSaveButton: boolean;
};

const CreditEntry = (props: CreditEntryProps) => {
	const {
		userName,
		creditDate,
		amountValue,
		noteValue,
		handleCreditAmount,
		handleNoteValue,
		handleCreditDate,
		handleOpenService,
		uploadedImage,
		clearCreditDoc,
		transactionId,
		handleCreditDetailSubmission,
		language,
		handleCreditImageUpload,
		handleAmountInputClickEvent,
		handleNoteInputClickEvent,
		handleReuploadState,
		handleActiveParentToggle,
		handleDeleteIconClickEvent,
		handleDateSelectionEvent,
		isOpenCalender,
		isActiveParent,
		isImageReUpload,
		handleOnClickSaveButton,
		isCreditReminderModalVisible,
		isRetailer,
		merchantData,
		getTemplateDataArray,
		merchantDetail,
		onChangeInput,
		onCloseProgressiveModal,
		onCompleteUserInfo,
		progressiveModalVisible,
    handleKeyDownCreditAmount,
    onPressClosePreview,
    toggleDeleteImageModal,
    deleteImageModalVisible,
    onClickDeleteImage,
    viewImagePreview,
    isImagePreview,
    deleteModalLang,
    isTransactionCreated,
    disableSaveButton
	} = props;

	const navigate = useNavigate();
	const { open, close, drawerProps } = useBottomDrawer({
		onOpen: () => console.log("Drawer opened"),
		onClose: () => console.log("Drawer closed"),
	});

	const children = getRemindCreditDialogJSX(
		{
			userName,
			amountValue,
		},
		close,
		navigate,
		clearCreditDoc,
		handleCreditDetailSubmission,
		language,
		getTemplateDataArray,
    isTransactionCreated
	);

	const disableButton =
		Number.parseInt(amountValue) === 0 || !Number.parseInt(amountValue) || disableSaveButton;

  if(isImagePreview) {
    return (
      <Box>
        <DocumentProvider
        onPressClosePreview={onPressClosePreview}
        initialDocuments={[
          {
            uri: uploadedImage?.data,
            fileName: '',
            },
          ]}
          label={uploadedImage?.title}
          deleteVisible={true}
          downloadVisible={false}
          removeDocument={()=>toggleDeleteImageModal('previewDelete')}
        >
          <DocumentViewer />
        </DocumentProvider>
        <DeleteImageModal
            language={deleteModalLang}
            deleteImageModalVisible={deleteImageModalVisible}
            onClickDeleteImage={onClickDeleteImage}
            toggleDeleteImageModal={toggleDeleteImageModal}
          />
      </Box>
    )
  }

	return (
		<Container
			height={"100dvh"}
			overflow={"hidden"}
			position={"relative"}
			bgcolor={"white"}
			sx={{
				// minWidth: "360px",
				// width: "100vw",
				background: "white",
			}}
			className={Styles.container}
		>
			<Header />

			<UserDetails
				handleDateSelectionEvent={handleDateSelectionEvent}
				isOpenCalender={isOpenCalender}
				language={language}
				transactionId={transactionId}
				userName={userName}
				creditDate={creditDate}
				handleCreditDate={handleCreditDate}
			/>

			<CreditDetails
        viewImagePreview={viewImagePreview}
				handleDeleteIconClickEvent={handleDeleteIconClickEvent}
        handleKeyDownCreditAmount={handleKeyDownCreditAmount}
				handleActiveParentToggle={handleActiveParentToggle}
				isActiveParent={isActiveParent}
				handleReuploadState={handleReuploadState}
				isImageReUpload={isImageReUpload}
				handleCreditImageUpload={handleCreditImageUpload}
				handleNoteInputClickEvent={handleNoteInputClickEvent}
				handleAmountInputClickEvent={handleAmountInputClickEvent}
				language={language}
				transactionId={transactionId}
				amountValue={amountValue}
				noteValue={noteValue}
				handleNoteValue={handleNoteValue}
				handleCreditAmount={handleCreditAmount}
				handleOpenService={handleOpenService}
				uploadedImage={uploadedImage}
				clearCreditDoc={clearCreditDoc}
			/>

			<ProgressiveSignup
				language={language}
				onCompleteUserInfo={onCompleteUserInfo}
				onCloseProgressiveModal={onCloseProgressiveModal}
				progressiveModalVisible={progressiveModalVisible}
				merchantData={merchantData}
				merchantDetail={merchantDetail}
				onChangeInput={onChangeInput}
			/>

			<SaveButton
				content-sec="bahikhata_saveTransactionbutton"
				title={language?.saveButton}
				disabled={disableButton}
				onClick={() => {
					const isDebit = TransactionType?.debit === transactionId;
					if(
            !isDebit
          ) {
            handleOnClickSaveButton(true)
          } else {
            handleCreditDetailSubmission(false);
          }
				}}
			/>

			<Drawer
				{...drawerProps}
        // className={Styles.container}
				open={isCreditReminderModalVisible}
				onClose={() => handleOnClickSaveButton(false)}
				slotProps={{
					content: {
						sx: {
							borderTopLeftRadius: "24px",
							borderTopRightRadius: "24px",
							// height: "auto",
              display: 'inline-flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius:
                'var(--radius-xl, 16px) var(--radius-xl, 16px) 0px 0px',
              background: 'var(--background-level1, #F0F4F8)',
              height: 'auto',
              boxSizing: 'border-box',
              '@media (min-width: 1025px) and (max-width: 1280px)': {
                width: '30vw',
                margin: '0vw 35vw',
              },
              '@media (min-width: 1281px)': {
                width: '30vw',
                margin: '0vw 35vw',
              },
						},
					},
				}}
			>
				{children}
			</Drawer>
		</Container>
	);
};

export default CreditEntry;
