import * as AppLanguages from '../constant/appLanguages';
//language will be added here
const languages = {
  [AppLanguages.appLanguagesIndex.english]: {
    heading: 'Bahi Khata',
    guideModalDescription:
      'Keep track of all your credit transactions at one place securely!',
    guideModalButton: 'Okay',
    headerSecureMsg: '100% Secure',
    youWillGive: 'You will give',
    youWillGet: 'You will get',
    recordNewTransaction: 'Record New Transaction',
    emptyStateMsg: 'You have not recorded any transaction yet!',
    searchKhata: 'Search Khata',
    allKhata: 'All Khata',
    filterLable: 'Filter',
    sortLable: 'Sort by',
    recentFirst: 'Recent first',
    alphabeticallyOrder: 'Alphabetically',
    allSettled: 'All Settled',
    noBalance: 'No Balance',
    userKhataCreatedDate: 'From <date> till now...',
    help: 'Help',
    khataDeleted : 'deleted!',
  },
  [AppLanguages.appLanguagesIndex.hindi]: {
    heading: 'बही खाता',
    guideModalDescription:
      'सभी लेन-देन को एक ही जगह सुरक्षित रूप से ट्रैक करें!',
    guideModalButton: 'ठीक है',
    headerSecureMsg: '100% सुरक्षित',
    youWillGive: 'आप देंगे',
    youWillGet: 'आपको मिलेगा',
    recordNewTransaction: 'नया लेन-देन रिकॉर्ड करें',
    emptyStateMsg: 'आपने अभी तक कोई लेन-देन रिकॉर्ड नहीं किया है!',
    searchKhata: 'खाता खोजें',
    allKhata: 'सभी खाते',
    filterLable: 'फ़िल्टर',
    sortLable: 'शॉर्ट करें',
    recentFirst: 'हाल ही में',
    alphabeticallyOrder: 'क्रमानुसार ',
    allSettled: 'सब ठीक है',
    noBalance: 'कोई बैलेंस नहीं',
    userKhataCreatedDate: '<date> से अब तक...',
    help: 'सहायता',
    khataDeleted : 'हटा दिया गया!',
  },
};

export default (language) => languages[language];
