import { Box } from '@mui/joy';
import TransectionDateContainer from '../TransectionDateContainer';
import TransactionRightCard from './transactionRightCard';
import InfiniteScroll from '@mono-farmart-web/farmart-web-common/Components/InfinityScrollV2/InverseScroll';
import TransactionLeftCard from './transactionLeftCard';
import TransactionLoading from './transactionLoading';
import TransactionType from '@mono-farmart-web/farmart-web-common/constant/enums/transactionType';

export default function TransectionList({
  transactionHistory,
  bahiKhataDetailLoading,
  onClickTransactionLongPress,
  trasactionDeleteData,
  loadMoreTransaction,
  moreTrasactionLoading,
  onClickPaymentDetailModal,
  language,
  onHoverTransaction,
  onClickDeleteTransactionModal,
  deleteTransactionModalOpenClose,
  unSelectLongPress
}) {
  const list = bahiKhataDetailLoading ? (
    <TransactionLoading />
  ) : (
    transactionHistory?.transactions?.map((item, index) => (
      <Box key={index}>
        {item?.show_date && (
          <TransectionDateContainer
            date={item?.created_at}
            bahiKhataDetailLoading={bahiKhataDetailLoading}
            language={language}
          />
        )}
        {TransactionType?.credit === item?.transaction_type ? (
          <TransactionRightCard
            item={item}
            onClickTransactionLongPress={onClickTransactionLongPress}
            trasactionDeleteData={trasactionDeleteData}
            onClickPaymentDetailModal={onClickPaymentDetailModal}
            language={language}
            onHoverTransaction={onHoverTransaction}
            onClickDeleteTransactionModal={onClickDeleteTransactionModal}
            deleteTransactionModalOpenClose={deleteTransactionModalOpenClose}
            unSelectLongPress={unSelectLongPress}
          />
        ) : (
          <TransactionLeftCard
            item={item}
            onClickTransactionLongPress={onClickTransactionLongPress}
            trasactionDeleteData={trasactionDeleteData}
            language={language}
            onHoverTransaction={onHoverTransaction}
            onClickDeleteTransactionModal={onClickDeleteTransactionModal}
            deleteTransactionModalOpenClose={deleteTransactionModalOpenClose}
            onClickPaymentDetailModal={onClickPaymentDetailModal}
            unSelectLongPress={unSelectLongPress}
          />
        )}
      </Box>
    ))
  );
  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        boxSizing: 'border-box',
        overflow: 'auto',
      }}
    >
      <InfiniteScroll
        vertical={true}
        children={list}
        onScrollToEnd={loadMoreTransaction}
        loadingElement={<TransactionLoading />}
        loading={bahiKhataDetailLoading || moreTrasactionLoading}
        style={{
          height: '100%',
          width: '100%',
          boxSizing: 'border-box',
          paddingBottom: '16px',
          scrollbarWidth: 'none',
        }}
        inverse={true}
      />
    </Box>
  );
}
