import { appLanguages } from '../../constant/appLanguages';
import { DEFAULT_LANGUAGE } from '../../constant/language';

// Get the language code from system or localStorage
export default function getLanguage(getLang: any) {
  /**
   * Retrieve the language code from localStorage.
   */
  const langId = JSON.parse(localStorage.getItem('userLanguage')); // get the language code from localStorage
  try {
    const activeSystemLang = Intl.DateTimeFormat()?.resolvedOptions()?.locale;
    const systemLanguage = activeSystemLang?.includes('en')
      ? appLanguages?.[1]?.langCode
      : appLanguages?.[0]?.langCode;
    const language = getLang(
      langId?.langCode || systemLanguage || DEFAULT_LANGUAGE,
    );
    return language;
  } catch (error) {
    console.error('Error getting language:', error);
    return getLang(DEFAULT_LANGUAGE); // fallback to default language
  }
}
