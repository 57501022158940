import type React from "react";
import Button from "@mui/joy/Button";
import type { ButtonProps } from "@mui/joy/Button";
import type { ReactNode } from "react";

interface IButtonWrapperProps extends ButtonProps {
	children?: ReactNode;
	onClick?: () => void;
	event?: string;
	id?: string;
	eventParams?: any;
	// You can define additional props here if needed
}

const ButtonC: React.FC<IButtonWrapperProps> = ({
	children,
	onClick = () => {},
	event,
	...props
}) => {
	function onClickButton() {
		onClick();
	}

	return (
		<Button
			id={props?.id}
			onClick={onClickButton}
			sx={{ borderRadius: "8px" }}
			{...props}
		>
			{children}
		</Button>
	);
};

export default ButtonC;
