import React, { useState, useRef } from 'react';
import Slider from 'react-slick';
import styles from './style.module.css'; // Import the CSS module
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Box } from '@mui/joy';
import MuiTypography from '@mono-farmart-web/farmart-web-common/ComponentV3/Typography';
import MuiButton from '@mono-farmart-web/farmart-web-common/ComponentV3/MuiButton';
import { Slide } from '../../../hooks/AppWalkthroghHooks/types';
import { trackEvent } from '@mono-farmart-web/farmart-web-common/utils/moengage/index.js';
import Events from "@mono-farmart-web/farmart-web-common/constant/MoengageEvents/index.js"; // Importing constant events

interface WalkthroughBannersProps {
  onLanguageModalToggle: () => void;
  onClickGotIt: (from: string) => void;
  walkthroughAssets: { [key: string]: string };
  language: { [key: string]: string };
  slidedData: Slide[];
}

export default function WalkthroughBanners(props: WalkthroughBannersProps) {
  const {
    onLanguageModalToggle,
    onClickGotIt,
    walkthroughAssets,
    language,
    slidedData,
  } = props;
  const [currentSlide, setCurrentSlide] = useState(0); // Track the active slide
  const sliderRef = useRef(null); // Create a ref for the slider

  const settings = {
    dots: false, // Disable default dots
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (newIndex) => setCurrentSlide(newIndex),
  };

  const handleNext = () => {
    trackEvent(Events.onWalkthroughScreenNextButtonClick,{});
    if (sliderRef.current) {
      sliderRef.current.slickNext(); // Move to the next slide
    }
  };

  const handleDotClick = (index) => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(index); // Move to the clicked dot's slide
    }
  };

  return (
    <Box className={styles.carouselcontainer} flex={1}>
      <Box
        display={'flex'}
        flex={1}
        justifyContent={'flex-end'}
        alignItems={'center'}
        position={'absolute'}
        top={0}
        left={0}
        margin={2}
        width={'calc(100% - 32px)'}
        zIndex={10}
        onClick={onLanguageModalToggle}
      >
        <img
          height={'35px'}
          width={'40px'}
          alt="Language Select Icon"
          src={walkthroughAssets?.languageChangeIcon}
        />
      </Box>

      <Slider ref={sliderRef} {...settings}
      infinite={false}
      >
        {slidedData.map((slide, index) => (
          <Box
            key={index}
            className={styles.slide}
            sx={{
              outline: 'none',
              background: 'transparent',
            }}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Box
              alignItems={'center'}
              position={'absolute'}
              zIndex={10}
              alignSelf={'center'}
              display={'flex'}
              className={styles.labelContainer}
              marginTop={'70px'}
              justifyContent={'center'}
              flexDirection={'column'}
            >
              <MuiTypography level="title-md" textColor={'warning.plainColor'}>
                {slide.heading}
              </MuiTypography>
              <MuiTypography
                level="h4"
                textColor={'text.primary'}
                sx={{ fontFamily: 'Notosans', marginTop: '10px' }}
              >
                {slide.sub_heading}
              </MuiTypography>
            </Box>
            <img
              src={slide.banner_url}
              style={{ width: '100%', height: '95dvh' }}
              alt={slide.heading}
              id={`WalkthroughImage${index+1}`}
            />
          </Box>
        ))}
      </Slider>
      <Box
        display={'flex'}
        flex={1}
        justifyContent={'space-between'}
        alignItems={'center'}
        position={'absolute'}
        bottom={0}
        left={0}
        margin={'28px'}
        marginBottom={6}
        width={'calc(100% - 56px)'}
        zIndex={10}
      >
        <Box>
          {currentSlide !== slidedData.length - 1 ? (
            <MuiButton
              variant="plain"
              color="neutral"
              onClick={() => onClickGotIt('skip')}
              id="WalkthroughSkipButton"
            >
              {language?.skip}
            </MuiButton>
          ) : (
            <Box padding={'0px 32px'}></Box>
          )}
        </Box>
        <Box
          display={'flex'}
          height={'10px'}
          justifyContent={'center'}
          alignItems={'center'}
          gap={2}
          flex={1}
        >
          {slidedData.map((_, index) => (
            <div
              key={index}
              className={`${styles.dot} ${
                currentSlide === index ? styles.active : ''
              }`}
              onClick={() => handleDotClick(index)}
            />
          ))}
        </Box>
        <Box>
          {currentSlide === slidedData.length - 1 ? (
            <MuiButton
              variant="plain"
              color="primary"
              onClick={() => onClickGotIt('gotIt')}
              id='WalkthroughGotItButton'
            >
              {language?.gotIt}
            </MuiButton>
          ) : (
            <MuiButton variant="plain" color="primary" onClick={handleNext} id='WalkthroughNextButton'>
              {language?.next}
            </MuiButton>
          )}
        </Box>
      </Box>
    </Box>
  );
}
