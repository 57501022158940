import { Box } from '@mui/joy';
import MuiTypography from '@mono-farmart-web/farmart-web-common/ComponentV3/Typography';
import BalanceContainerLoading from './balanceContainerLoading';
import MoneyControlBar from './moneyControlBar';
import MuiButton from '@mono-farmart-web/farmart-web-common/ComponentV3/MuiButton';
import AddFilled from '@mui/icons-material/Add';
import { BahiKhataBalanceProps } from '../../../hooks/BahiKhataLanding/types';

interface BalanceContainerProps {
  balanceLoading: boolean;
  language: { [key: string]: string };
  userBalance: BahiKhataBalanceProps;
  onClickRecordNewTransaction: () => void;
}

export default function BalanceContainer(props: BalanceContainerProps) {
  const { balanceLoading, language, userBalance, onClickRecordNewTransaction } =
    props;

  const longDateIN = new Date(userBalance.khatas_created_since).toLocaleString(
    'en-IN',
    {
      timeZone: 'Asia/Kolkata',
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    },
  );

  const template = language?.userKhataCreatedDate;
  const values = {
    date: longDateIN,
  };

  const replacePlaceholders = ({ template, values }) => {
    return template.split(/(<[^>]+>)/).map((part, index) =>
      part.startsWith('<') && part.endsWith('>') ? (
        <span key={index} style={{ fontWeight: 'bold', fontStyle: 'normal' }}>
          {values[part.replace(/<|>/g, '')]}
        </span>
      ) : (
        <span key={index}>{part}</span>
      ),
    );
  };

  return (
    <Box
      padding={'16px'}
      alignItems={'center'}
      justifyContent={'center'}
      bgcolor={'var(--background-level1, #F0F4F8)'}
      boxSizing={'border-box'}
    >
      {balanceLoading ? (
        <BalanceContainerLoading />
      ) : (
        <Box
          display={'flex'}
          padding={'var(--4, 16px)'}
          flexDirection={'column'}
          alignItems={'flex-start'}
          gap={'10px'}
          alignSelf={'stretch'}
          borderRadius={'var(--radius-lg, 12px)'}
          boxShadow={
            '0.3px 0.8px 1.1px 0px rgba(187, 187, 187, 0.12), 1.1px 2.8px 3.9px -0.4px rgba(187, 187, 187, 0.17), 2.4px 6.1px 8.6px -0.8px rgba(187, 187, 187, 0.23), 5.3px 13.3px 18.8px -1.2px rgba(187, 187, 187, 0.29)'
          }
          sx={{
            background: 'linear-gradient(90deg, #32383E 0%, #4D4C4C 100%)',
          }}
        >
          <Box
            display={'flex'}
            alignSelf={'stretch'}
            justifyContent={'space-between'}
          >
            <Box>
              <MuiTypography
                level="h3"
                sx={{ color: '#FFFFFF', fontFamily: 'NotoSans' }}
              >
                ₹{userBalance?.amount_to_take?.toLocaleString('en-IN')}
              </MuiTypography>
              <MuiTypography
                level="body-xs"
                sx={{ opacity: 0.6, color: '#FFFFFF', fontFamily: 'NotoSans' }}
              >
                {language?.youWillGet}
              </MuiTypography>
            </Box>
            <Box
              display={'flex'}
              flexDirection={'column'}
              alignItems={'flex-end'}
            >
              <MuiTypography
                level="h3"
                sx={{ color: '#FFFFFF', fontFamily: 'NotoSans' }}
              >
                 ₹{userBalance?.amount_to_give?.toLocaleString('en-IN')} 

              </MuiTypography>
              <MuiTypography
                level="body-xs"
                sx={{ opacity: 0.6, color: '#FFFFFF', fontFamily: 'NotoSans' }}
              >
                {language?.youWillGive}
              </MuiTypography>
            </Box>
          </Box>
          <MoneyControlBar
            moneyGiven={userBalance.amount_to_give}
            moneyReceived={userBalance.amount_to_take}
            key={'moneyControlBar'}
          />
          <MuiTypography
            level="label-md"
            sx={{
              opacity: 0.6,
              color: '#FFFFFF',
              fontFamily: 'NotoSans',
              fontStyle: 'italic',
            }}
          >
            {userBalance.khatas_created_since
              ? replacePlaceholders({ template, values })
              : language?.noBalance}
          </MuiTypography>
        </Box>
      )}
      <Box
        marginTop={'12px'}
        onClick={onClickRecordNewTransaction}
        id="bahikhata_newTransaction"
        data-testid="bahikhata_newTransaction"
        content-desc="bahikhata_newTransaction"
      >
        <MuiButton size="md" startDecorator={<AddFilled />}>
          {language?.recordNewTransaction}
        </MuiButton>
      </Box>
    </Box>
  );
}
