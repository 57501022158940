import AddFarmerView from '../../Pages/AcknowledgementScreen/AcknowledgementView';
import AcknowledgementHooks from '../../hooks/AcknowledgementHooks/acknowledgementHooks';

export default function otpScreen() {
  const { onPressBack, language, onContactUs } = AcknowledgementHooks();
  return (
    <AddFarmerView
      onPressBack={onPressBack}
      language={language}
      onContactUs={onContactUs}
    />
  );
}
