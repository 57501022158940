import React from 'react';
import Modal from '@mono-farmart-web/farmart-web-common/ComponentV2/Modal';
import { Box } from '@mui/joy';
import MuiTypography from '@mono-farmart-web/farmart-web-common/ComponentV3/Typography';
import { AccessTimeRounded, SortRounded } from '@mui/icons-material';

interface SortingModalProps {
  language: { [key: string]: string };
  onClicApplySorting: (id: number) => void;
  sortingModalVisible: boolean;
  toggleSortingModal: () => void;
  selectedSorting: number;
}

export default function SortingModal(props: SortingModalProps) {
  const {
    language,
    onClicApplySorting,
    sortingModalVisible,
    toggleSortingModal,
    selectedSorting,
  } = props;
  const items = [
    { language: language?.recentFirst, id: 1 },
    { language: language?.alphabeticallyOrder, id: 2 },
  ];

  const getIcon = (id: number, selected: boolean) => {
    const color = selected ? '#171A1C' : '#555E68';
    return id === 1 ? (
      <SortRounded htmlColor={color} />
    ) : (
      <AccessTimeRounded htmlColor={color} />
    );
  };

  const modalContent = (
    <Box>
      {items.map(({ language, id }, index) => (
        <Box key={id} padding={'12px'} onClick={() => onClicApplySorting(id)}>
          <Box
            display="flex"
            alignItems="center"
            padding="12px"
            bgcolor={
              id === selectedSorting ? 'primary.softBg' : 'background.level1'
            }
            borderRadius={'8px'}
            border={1}
            borderColor={
              id === selectedSorting
                ? 'primary.outlinedBorder'
                : 'neutral.outlinedDisabledBorder'
            }
          >
            <Box display={'flex'} gap={'8px'} alignItems={'center'}>
              {getIcon(id, id === selectedSorting)}
              <MuiTypography
                level="body-xs"
                textColor={
                  id === selectedSorting ? 'text.primary' : 'text.secondary'
                }
              >
                {language}
              </MuiTypography>
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  );

  return (
    <Modal
      isOpen={sortingModalVisible} // Flag to determine if delete modal is open
      children={modalContent} // Pass modal content as children
      title={'Sorting'} // Display delete modal title
      isCloseButtonOption={true} // Enable close button option
      onClose={toggleSortingModal}
    />
  );
}
