import React from 'react';
import TraderList from '../../Pages/Traders/TraderList';
import UseLocationFetch from '../../hooks/useUserLocation';
import UseGetOtp from '../../hooks/useGetOtp';
import UseFilterValues from '../../hooks/getFilterValues';
import UseUpdatePageNumber from '../../hooks/tradeListPageHook';

export default function trader() {
  const {
    setTradeListPageNumber,
    tradeListPageNumber,
    buyersList,
    setBuyerList,
    totalRecords,
    setTotalRecords,
    setActiveTradeList,
    activeTradeList,
    setExpiredTradeList,
    expiredTradeList,
    setPromotionalMediaList,
    promotionalMediaList,
  } = UseUpdatePageNumber();
  const {
    cropList,
    cropVarietyList,
    locationList,
    cropListSearchParam,
    setCropListSearchParam,
    cropVarietyListCropId,
    setCropVarietyListCropId,
    cropVarietyListSearchParam,
    setCropVarietyListSearchParam,
    cropVarietyListPage,
    setVarietyCropListPage,
    cropVarietyListLimit,
    setCropVarietyListLimit,
    buyerLocationListSearchParam,
    setBuyerLocationListSearchParam,
    buyerLocationListPage,
    setBuyerLocationListPage,
    cropListModal,
    handleCropListModal,
    cropVarietyListModal,
    handleCropVarietyListModal,
    buyerLocationListModal,
    handleBuyerLocationListModal,
    activeVariety,
    activeCrop,
    handleCropSelection,
    handleVarietySelection,
    handleLocationSelection,
    cropLoading,
    cropVarietyLoading,
    activeLocations,
    locationLoading,
    handleCropSearch,
    onClickLocationFilterApply,
    handleCropVarietySearch,
    handleLocationSearch,
    applyFilterRefresh,
    loadMoreLocationList,
    onClickRemoveCropFilter,
    onClickRemoveVarietyFilter,
    onClickRemoveLocationFilter,
    locationFilterApplied,
    isModalErrorVisible,
    onRefreshCropList,
    onRefreshCropVarietyList,
    onRefreshLocationList,
    onClickVarietyFilterApply,
    variertFilterApplied,
    resetFilters,
    reloadRefresh,
    setReloadRefresh,
    locationListCount,
  } = UseFilterValues(
    setTradeListPageNumber,
    setBuyerList,
    setActiveTradeList,
    setExpiredTradeList,
    setTotalRecords,
  );
  const {
    otpModalVisible,
    mobileNumberModalVisible,
    onClickLoginProceed,
    getMobileNumber,
    getUserOtpFromInput,
    userOtp,
    onClickValidateOtpProceed,
    changeMobileNumber,
    personaModalVisible,
    onClickPersonaCard,
    userSelectedPersona,
    onClickProceedPersona,
    resendOtp,
    setMobileNumberModalVisible,
    error,
    loading,
    userMobileNumber,
    language,
    isLocationModal,
    setLocationModal,
    userBlocked,
    currentLanguageId,
    disableResend,
    setDisableResend,
    askForPermission,
    timeLanguage,
  } = UseGetOtp();
  const {
    onClickAllowLocationModal,
    serviciableLoading,
    serviciability,
    getMoreTradeListData,
    locationModalLoading,
    locationToastPopup,
    handleLocationToastPopup,
    saudaRequestToastPopup,
    handleSaudaRequestToastPopup,
    numberCopyToast,
    handleNumberCopyToast,
    onContactUs,
    onClickHelp,
    buyersListLoading,
    onCLickViewDetail,
    onClickMySaudaCard,
    masterStateDistrict,
    onChangeLocation,
    isLocationPermission,
    tradeScreenAssets,
    isErrorScreenVisible,
    onRefreshScreen,
    searchedBuyerDetail,
    onClickBuyerSearch,
    onClickRemoveBuyerSearch,
    reloadTrades,
    activePriceCount,
  } = UseLocationFetch(
    setMobileNumberModalVisible,
    setLocationModal,
    activeLocations,
    tradeListPageNumber,
    setTradeListPageNumber,
    activeCrop,
    activeVariety,
    setBuyerList,
    buyersList,
    applyFilterRefresh,
    askForPermission,
    setTotalRecords,
    totalRecords,
    setActiveTradeList,
    activeTradeList,
    setExpiredTradeList,
    expiredTradeList,
    setPromotionalMediaList,
    resetFilters,
    reloadRefresh,
    setReloadRefresh,
  );
  return (
    <TraderList
      serviciableLoading={serviciableLoading}
      serviciability={serviciability}
      isLocationModal={isLocationModal}
      onClickAllowLocationModal={onClickAllowLocationModal}
      otpModalVisible={otpModalVisible}
      mobileNumberModalVisible={mobileNumberModalVisible}
      onClickLoginProceed={onClickLoginProceed}
      getMobileNumber={getMobileNumber}
      getUserOtpFromInput={getUserOtpFromInput}
      userOtp={userOtp}
      onClickValidateOtpProceed={onClickValidateOtpProceed}
      changeMobileNumber={changeMobileNumber}
      personaModalVisible={personaModalVisible}
      onClickPersonaCard={onClickPersonaCard}
      userSelectedPersona={userSelectedPersona}
      onClickProceedPersona={onClickProceedPersona}
      resendOtp={resendOtp}
      buyersList={buyersList}
      totalRecords={totalRecords}
      getMoreTradeListData={getMoreTradeListData}
      error={error}
      loading={loading}
      userMobileNumber={userMobileNumber}
      language={language}
      locationModalLoading={locationModalLoading}
      cropList={cropList}
      cropVarietyList={cropVarietyList}
      locationList={locationList}
      cropListSearchParam={cropListSearchParam}
      setCropListSearchParam={setCropListSearchParam}
      cropVarietyListCropId={cropVarietyListCropId}
      setCropVarietyListCropId={setCropVarietyListCropId}
      cropVarietyListSearchParam={cropVarietyListSearchParam}
      setCropVarietyListSearchParam={setCropVarietyListSearchParam}
      cropVarietyListPage={cropVarietyListPage}
      setVarietyCropListPage={setVarietyCropListPage}
      cropVarietyListLimit={cropVarietyListLimit}
      setCropVarietyListLimit={setCropVarietyListLimit}
      buyerLocationListSearchParam={buyerLocationListSearchParam}
      setBuyerLocationListSearchParam={setBuyerLocationListSearchParam}
      buyerLocationListPage={buyerLocationListPage}
      setBuyerLocationListPage={setBuyerLocationListPage}
      cropListModal={cropListModal}
      handleCropListModal={handleCropListModal}
      cropVarietyListModal={cropVarietyListModal}
      handleCropVarietyListModal={handleCropVarietyListModal}
      buyerLocationListModal={buyerLocationListModal}
      handleBuyerLocationListModal={handleBuyerLocationListModal}
      activeVariety={activeVariety}
      activeCrop={activeCrop}
      handleCropSelection={handleCropSelection}
      handleVarietySelection={handleVarietySelection}
      handleLocationSelection={handleLocationSelection}
      cropLoading={cropLoading}
      cropVarietyLoading={cropVarietyLoading}
      activeLocations={activeLocations}
      locationLoading={locationLoading}
      handleCropSearch={handleCropSearch}
      locationToastPopup={locationToastPopup}
      handleLocationToastPopup={handleLocationToastPopup}
      numberCopyToast={numberCopyToast}
      handleNumberCopyToast={handleNumberCopyToast}
      saudaRequestToastPopup={saudaRequestToastPopup}
      handleSaudaRequestToastPopup={handleSaudaRequestToastPopup}
      userBlocked={userBlocked}
      onContactUs={onContactUs}
      onClickHelp={onClickHelp}
      onClickLocationFilterApply={onClickLocationFilterApply}
      buyersListLoading={buyersListLoading}
      currentLanguageId={currentLanguageId}
      handleCropVarietySearch={handleCropVarietySearch}
      onCLickViewDetail={onCLickViewDetail}
      handleLocationSearch={handleLocationSearch}
      loadMoreLocationList={loadMoreLocationList}
      disableResend={disableResend}
      setDisableResend={setDisableResend}
      activeTradeList={activeTradeList}
      expiredTradeList={expiredTradeList}
      onClickMySaudaCard={onClickMySaudaCard}
      tradeListPageNumber={tradeListPageNumber}
      masterStateDistrict={masterStateDistrict}
      onChangeLocation={onChangeLocation}
      isLocationPermission={isLocationPermission}
      promotionalMediaList={promotionalMediaList}
      tradeScreenAssets={tradeScreenAssets}
      onClickRemoveCropFilter={onClickRemoveCropFilter}
      onClickRemoveVarietyFilter={onClickRemoveVarietyFilter}
      onClickRemoveLocationFilter={onClickRemoveLocationFilter}
      locationFilterApplied={locationFilterApplied}
      isModalErrorVisible={isModalErrorVisible}
      onRefreshCropList={onRefreshCropList}
      onRefreshCropVarietyList={onRefreshCropVarietyList}
      onRefreshLocationList={onRefreshLocationList}
      isErrorScreenVisible={isErrorScreenVisible}
      onRefreshScreen={onRefreshScreen}
      variertFilterApplied={variertFilterApplied}
      onClickVarietyFilterApply={onClickVarietyFilterApply}
      searchedBuyerDetail={searchedBuyerDetail}
      onClickBuyerSearch={onClickBuyerSearch}
      onClickRemoveBuyerSearch={onClickRemoveBuyerSearch}
      reloadTrades={reloadTrades}
      activePriceCount={activePriceCount}
      timeLanguage={timeLanguage}
      locationListCount={locationListCount}
    />
  );
}
