import Button from "@mono-farmart-web/farmart-web-common/ComponentV2/Button";
import { Box, Column } from "@mono-farmart-web/farmart-web-common/MuiComponents/atoms/layout";

interface SaveButtonProps {
  title: string;
  disabled: boolean;
  onClick: () => void;
}

const SaveButton = (props: SaveButtonProps) => {
  const { title, disabled, onClick } = props;

  return (
    <Box
      sx={{
        flex: 1,
        padding: "16px",
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-end",
        justifyContent: "center"
      }}>
      <Button
          sx={{
            height: "48px",
            color: "white",
            fontWeight: 600
          }}
          onClick={onClick}
          title={title}
          disabled={disabled}
      />
    </Box>
  )
}

export default SaveButton;
