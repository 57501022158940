import RakeListView from '../../Pages/RakeList/RakeView';
import RakeListHook from '../../hooks/RakeList/rakeListHook';

export default function rakeList() {
  const {
    onPressBack,
    language,
    onContactUs,
    onLoadMoreNearRake,
    rakeLoading,
    loadMoreRakeLoading,
    currentRakeCount,
    nearRakeCount,
    nearRakeData,
    onClickChangeLocation,
    onClickRakeCard,
    langCode,
    currentLocation,
    onClickChangeModal,
    locationModalLoading,
    onClickGetGpsLocation,
    isLocationModal,
    rakeListAssets,
  } = RakeListHook();
  return (
    <RakeListView
      onPressBack={onPressBack}
      language={language}
      onContactUs={onContactUs}
      onLoadMoreNearRake={onLoadMoreNearRake}
      rakeLoading={rakeLoading}
      loadMoreRakeLoading={loadMoreRakeLoading}
      currentRakeCount={currentRakeCount}
      nearRakeCount={nearRakeCount}
      nearRakeData={nearRakeData}
      onClickChangeLocation={onClickChangeLocation}
      onClickRakeCard={onClickRakeCard}
      langCode={langCode}
      currentLocation={currentLocation}
      onClickChangeModal={onClickChangeModal}
      locationModalLoading={locationModalLoading}
      onClickGetGpsLocation={onClickGetGpsLocation}
      isLocationModal={isLocationModal}
      rakeListAssets={rakeListAssets}
    />
  );
}
