import ScreenType from '@mono-farmart-web/farmart-web-common/constant/screenEnums';
import createStack from '@mono-farmart-web/farmart-web-common/utils/dataStructure/stack';
import { useEffect } from 'react';

const visitedPageIdHistory = createStack<number>();

interface Props {
  screen?: number;
}

export default function useVisitedPostHistory(props?: Props) {
  const { screen } = props || {};

  useEffect(function () {
    try {
      if (screen && ScreenType.get('homeScreen')) {
        const postId = getPreviousPostId();

        if (postId) {
          document.getElementById(`post-${postId}`)?.scrollIntoView();
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  function onPostClick(id: number): void {
    visitedPageIdHistory.push(id);
  }

  function getPreviousPostId(): number {
    return visitedPageIdHistory.pop();
  }

  return {
    onPostClick,
    getPreviousPostId,
  };
}
