import { useState, useEffect } from "react";
import { paymentScreenAssets } from "@mono-farmart-web/farmart-web-common/constant/imageUrls";
import useAppLanguage from "@mono-farmart-web/farmart-web-common/utils/applanguage";
import getLang from "@mono-farmart-web/farmart-web-common/languages/paymentScreen";
import { languageUrls } from "@mono-farmart-web/farmart-web-common/constant/languageUrls";
import { DEFAULT_LANGUAGE } from "@mono-farmart-web/farmart-web-common/constant/language";
import type { PaymentDataInterface } from "./types";
import useAuthenticationChecker from "@mono-farmart-web/farmart-web-common/utils/authCheckerV2";
import { apiEndpoints } from "@mono-farmart-web/farmart-web-common/apis/endpoints/farmart-web";
import { useNavigate, useParams } from "react-router-dom";
import { trackEvent } from "@mono-farmart-web/farmart-web-common/utils/moengage";
import MoengageEvents from "@mono-farmart-web/farmart-web-common/constant/MoengageEvents/index";
import PlatForms from '@mono-farmart-web/farmart-web-common/constant/platFormEnums';


export default function usePaymentScreen() {
	const navigate = useNavigate();
	const { paymentId, saudaId, modalName, modalId } = useParams();
	const { language } = useAppLanguage(
		languageUrls.paymentScreenLangageUrl,
		getLang,
	);
	const languageFromLocalStorage = localStorage.getItem("userLanguage");
	const languageId = languageFromLocalStorage
		? JSON.parse(languageFromLocalStorage)?.langCode
		: DEFAULT_LANGUAGE;
	const paymentAssets = paymentScreenAssets(languageId);
	const { AuthenticationCheckerApiCaller } = useAuthenticationChecker();
	const [paymentData, setPaymentData] = useState<PaymentDataInterface | null>(
		null,
	);
	const [error, setError] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	async function getPaymentData() {
		setLoading(true);
		try {
			const payload = {
				payment_id: paymentId,
			};
			const response = await AuthenticationCheckerApiCaller({
				requestType: "get",
				payload: payload,
				uri: apiEndpoints.getTruckPaymentDetails,
			});
			if (response?.data?.status) {
				setPaymentData(response?.data?.data);
				setError(false);
				setLoading(false);
			}
		} catch (error) {
			setLoading(false);
			setError(true);
			console.log(error);
		}
	}
	function onViewSaudaDetails() {
		if (modalName && modalId) {
			navigate(`/trades/mySauda/${saudaId}/${modalName}/${modalId}`);
		} else {
			navigate(`/trades/mySauda/${saudaId}`);
		}
		trackEvent(MoengageEvents.onPaymentDialogueBoxViewSaudaDetailsClick, {
      "Sauda ID": saudaId,
      "Payment ID": paymentId,
    });
	}
	function onClickBack() {
		navigate("/");
		trackEvent(MoengageEvents.onPaymentDialogueBoxCrossButtonClick, {});
	}
	useEffect(() => {
		getPaymentData();

		trackEvent(
			MoengageEvents.onStartPaymentScreen, 
			{
				"Sauda ID": saudaId,
				"Payment ID": paymentId,
			},
			PlatForms?.get('application')
		);
	}, []);
	return {
		language,
		paymentAssets,
		paymentData,
		onViewSaudaDetails,
		onClickBack,
		error,
		loading,
	};
}
