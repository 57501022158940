import React from 'react';

const AddFilledIcon = ({ height="24", width="24", fillColor="#FCFCFD" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={height}
      height={width}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" fill={fillColor} />
    </svg>
  );
};

export default AddFilledIcon;
