import React from 'react';
import Styles from './style.module.css'; // Importing CSS module for styling.
import { Box } from '@mui/joy'; // Importing Box component from MUI Joy for layout.
import { EditProfileFooterProps } from '../../../hooks/EditProfile/types'; // Importing type definitions for component props.
import MuiButton from '@mono-farmart-web/farmart-web-common/ComponentV3/MuiButton'; // Importing a custom MUI Button component.

// Footer component for the Edit Profile screen.
// It displays a submit button that triggers the profile update process.
const Footer = ({ submitEditProfile, language }: EditProfileFooterProps) => {
  return (
    <Box className={Styles.wrapper}>
      {' '}
      {/* Container for the footer, styled with CSS modules. */}
      <MuiButton
        size={'lg'} // Setting the size of the button to large.
        className={Styles.submitButton} // Applying custom styling to the button.
        onClick={submitEditProfile} // Assigning the function to call when the button is clicked.
        id={'onClickSubmitButtonEditProfileId'} // Providing an ID for potential testing purposes.
        data-testid="onClickSubmitButtonEditProfileTestId"
      >
        {language?.submit} {/* Displaying the localized text for the button. */}
      </MuiButton>
    </Box>
  );
};

export default Footer; // Exporting the Footer component for use in other parts of the application.
