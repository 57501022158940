import { useLocation } from "react-router-dom";
import AddNewKhataView from "../../Pages/AddNewKhata";
import { useAddNewKhata } from "../../hooks/AddNewKhata";

const AddNewKhata = () => {
  const location = useLocation();
  const { state } = location;
  const {
    userDetails,
    handleNameChange,
    handleContactChange,
    handleCreateNewTransaction,
    isSavingDetails,
    isErrorToast,
    language
  } = useAddNewKhata(state?.username);

  return (
    <AddNewKhataView
      isErrorToast={isErrorToast}
      userDetails={userDetails}
      handleNameChange={handleNameChange}
      handleContactChange={handleContactChange}
      handleCreateNewTransaction={handleCreateNewTransaction}
      isSavingDetails={isSavingDetails}
      language={language} />
  )
}

export default AddNewKhata;
