export default function GetIndianTimeOnly(isoDate: string) {
  // Convert the ISO date to a Date object
  const date = new Date(isoDate);

  // Convert to local time and format to 4:00 PM
  const options = {
    hour: 'numeric' as const,
    minute: 'numeric' as const,
    hour12: true,
  };
  const formattedTime = new Intl.DateTimeFormat('en-US', options).format(date);
  return formattedTime;
}
